import SelectComponent from './Select'

import './InputTextWithTitle.sass'

interface SelectWithTitleProps {
  value: string
  setValue: (value: string) => void
  label: string
  width: number
  options: string[]
}

export default function SelectWithTitle({ value, setValue, label, width, options }: SelectWithTitleProps) {
  return (
    <div
      className='input-text-title select'
      style={{ width: `${width}px`, minWidth: `${width}px` }}
    >
      <span className="label">{label}</span>

      <SelectComponent
        options={options.map((option) => ({
          span: option,
          value: option
        }))}
        selectedOption={value}
        onChange={(value) => setValue(value as string)}
      />
    </div>
  )
}
