import { useEffect, useState } from 'react'
import classNames from 'classnames'

import Icon from '../../components/Icon'
import Checkbox from '../../components/Checkbox'
import UnitTable from './UnitTable'

import { httpClientUpdate } from '../../funcs'
import { useAppSelector } from '../../store/hooks'

import { DataProps } from './LineItems'
import { UnitProps } from '../Jobs/ItemTech'

interface AddServiceProps {
  setPage: (value: string) => void
  data: DataProps
  setData: (value: DataProps) => void
  handleSave: (name: string, comment: string, amount: number, quantity: number, unit_id: string[], is_save: boolean, id: string) => void
  units: UnitProps[]
}

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: DataReportProps
}

interface DataReportProps {
  list_services: ListProps[]
}

interface ListProps {
  description: string
  list_service_id: string
  name: string
  price: string
  is_favorite: boolean
  is_recent: boolean
}

interface ServiceProps {
  name: string,
  comment: string,
  amount: number,
  quantity: number,
  unit_id: string[],
  is_save: boolean,
  id: string
}

export default function AddService({ setPage, handleSave, units }: AddServiceProps) {
  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [search, setSearch] = useState('')
  const [tab, setTab] = useState<'all' | 'favorite'>('all')

  const [addService, setAddService] = useState<ServiceProps>({ name: '', comment: '', amount: 0, quantity: 1, unit_id: [], is_save: false, id: '' })
  const [isAddAmount, setIsAddAmount] = useState(false)
  const [isAddNewAmount, setIsAddNewAmount] = useState(false)

  const [report, setReport] = useState<DataReportProps | null>(null)

  const [services, setServices] = useState<ListProps[]>([])
  const [servicesUsed, setServicesUsed] = useState<ListProps[]>([])

  useEffect(() => {
    if (report) {
      let used: ListProps[] = []
      let elseServices: ListProps[] = []

      report.list_services.forEach(item => {
        if (item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
          if (item.is_recent) {
            if (used.length < 10) {
              used.push(item)
            } else {
              elseServices.push(item)
            }
          } else {
            elseServices.push(item)
          }
        }
      })

      setServices(elseServices)
      setServicesUsed(used)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  useEffect(() => {
    if (report) {
      if (tab === 'all') {
        let used: ListProps[] = []
        let elseServices: ListProps[] = []

        report.list_services.forEach(item => {
          if (item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
            if (item.is_recent) {
              if (used.length < 10) {
                used.push(item)
              } else {
                elseServices.push(item)
              }
            } else {
              elseServices.push(item)
            }
          }
        })

        setServices(elseServices)
        setServicesUsed(used)
      } else {
        let used: ListProps[] = []
        let elseServices: ListProps[] = []

        report.list_services.forEach(item => {
          if (item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) && item.is_favorite) {
            if (item.is_recent) {
              if (used.length < 10) {
                used.push(item)
              } else {
                elseServices.push(item)
              }
            } else {
              elseServices.push(item)
            }
          }
        })

        setServices(elseServices)
        setServicesUsed(used)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab])

  useEffect(() => {
    getStore()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getStore() {
    try {
      // https://2022back4.artemiudintsev.com/api/technician/services/report?account_id=88888
      const { data: { data: reportData, success } } = (await httpClientUpdate.get(`/technician/services/report`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        },
        params: {
          account_id: activeAccountId
        }
      })) as { data: HttpClientUpdateReport }
      if (success) {
        let used: ListProps[] = []
        let elseServices: ListProps[] = []

        reportData.list_services.forEach(item => {
          if (item.is_recent) {
            if (used.length < 10) {
              used.push(item)
            } else {
              elseServices.push(item)
            }
          } else {
            elseServices.push(item)
          }
        })
        setReport(reportData)

        setServices(elseServices)
        setServicesUsed(used)
      }

    }
    catch (error) { }
  }

  async function handleFavoriteService(id: string, is_favorite: boolean) {
    try {
      // service-material-users/use
      const response = await httpClientUpdate.post(`/service-material-users/use`, {
        account_id: activeAccountId,
        action: 'favorite',
        relatable_type: 'service',
        relatable_id: id,
        use: is_favorite ? 0 : 1,
      })

      if (response.data.success) {
        getStore()
      } else {
      }
    } catch (error) { }
  }

  async function handleSaveNewService() {
    try {
      if (addService.is_save) {
        // /api/technician/services
        const response = await httpClientUpdate.post(`/technician/services`, {
          account_id: activeAccountId,
          name: addService.name,
          description: addService.comment,
          price: Number(addService.amount).toFixed(2),
        })

        if (response.data.success) {
          handleSave(addService.name, addService.comment, addService.amount, addService.quantity, addService.unit_id, addService.is_save, addService.id)
          setAddService({ name: '', comment: '', amount: 0, quantity: 1, unit_id: [], is_save: false, id: '' })
          setIsAddNewAmount(false)
        } else {
        }
      } else {
        handleSave(addService.name, addService.comment, addService.amount, addService.quantity, addService.unit_id, addService.is_save, addService.id)
        setAddService({ name: '', comment: '', amount: 0, quantity: 1, unit_id: [], is_save: false, id: '' })
        setIsAddNewAmount(false)
      }
    } catch (error) { }
  }

  function handleAddService(name: string, comment: string, amount: number, id: string) {
    setAddService({ name: name, comment: comment, amount: amount, quantity: 1, unit_id: [], is_save: false, id: id })
    setIsAddAmount(true)
  }

  function handleCancelAddService() {
    setAddService({ name: '', comment: '', amount: 0, quantity: 1, unit_id: [], is_save: false, id: '' })
    setIsAddAmount(false)
    setIsAddNewAmount(false)
  }

  function handleSaveService() {
    handleSave(addService.name, addService.comment, addService.amount, addService.quantity, addService.unit_id, addService.is_save, addService.id)

    setAddService({ name: '', comment: '', amount: 0, quantity: 1, unit_id: [], is_save: false, id: '' })
    setIsAddAmount(false)
  }

  function handleJobFocus(event: any) {
    const target = event.currentTarget;

    target.type = 'text';
    target.setSelectionRange(target.value.length, target.value.length);
    target.type = 'number';
    target && target.focus()
  }

  function handleJobChangeItem(event: any, name: string) {
    let price = event.target.value

    let numberWithoutDot = price.split('.')

    if (Number(price) >= 0) {
      if (numberWithoutDot[1] && numberWithoutDot[1].toString().length > 2) {
        let newNumber = `${numberWithoutDot[0]}${numberWithoutDot[1].toString()[0]}.${numberWithoutDot[1].toString()[1]}${price[price.length - 1]}`
        if (name === 'amount') {
          setAddService({ ...addService, amount: Number(newNumber) })
        } else {
          setAddService({ ...addService, quantity: Number(newNumber) })
        }
      } else {
        let newNumber = price

        if (name === 'amount') {
          setAddService({ ...addService, amount: Number(newNumber) })
        } else {
          setAddService({ ...addService, quantity: Number(newNumber) })
        }
      }
    }
  }

  function handleJobChangeItemBackspace(event: any, name: string) {
    if (event.keyCode === 8) {
      event.preventDefault()
      let price = event.target.value
      let numberWithoutDot = `${price}`.split('.')
      if (numberWithoutDot[0].length > 1) {
        let newNumber = `${numberWithoutDot[0].slice(0, -1)}.${numberWithoutDot[0][numberWithoutDot[0].length - 1]}${numberWithoutDot[1][0]}`
        if (name === 'amount') {
          setAddService({ ...addService, amount: Number(newNumber) })
        } else {
          setAddService({ ...addService, quantity: Number(newNumber) })
        }
      } else {
        let newNumber = `0.${numberWithoutDot[0][0]}${numberWithoutDot[1][0]}`
        if (name === 'amount') {
          setAddService({ ...addService, amount: Number(newNumber) })
        } else {
          setAddService({ ...addService, quantity: Number(newNumber) })
        }
      }
    }
  }

  return (
    <div className="EstimatePage_Add entity-edit">
      {
        !isAddAmount && !isAddNewAmount &&
        <div className="wrapper flex-container sb">
          <div className='row'>
            <button
              style={{ background: 'inherit' }}
              className="_wa"
              onClick={() => setPage('editing')}
            >
              <Icon
                style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#005eb5' }}
                viewBox="0 0 24 24"
                icon="arrow-25"
              />
            </button>

            <h1 style={{ whiteSpace: 'nowrap', marginTop: '0', color: '#005eb5' }}>New Estimate</h1>
          </div>

          <div className='row'>
            <button
              className="_zeroed _iconed _blue"
              style={{ color: '#005eb5', fontSize: '24px' }}
              onClick={() => setIsAddNewAmount(true)}
            >
              +
            </button>
          </div>
        </div>
      }

      {
        isAddAmount && !isAddNewAmount &&
        <div className="wrapper flex-container sb editing">
          <div>
            <button
              onClick={handleCancelAddService}
              className="button-cancel"
            >
              Cancel
            </button>
          </div>

          <div>
            <h1 style={{ whiteSpace: 'nowrap', marginTop: '0', color: '#393939' }}>Services</h1>
          </div>

          <div>
            <button
              className={classNames('button-save-disabled', {
                'button-save': addService.amount !== 0 && addService.unit_id.length !== 0
              })}
              onClick={() => addService.amount !== 0 && addService.unit_id.length !== 0 && handleSaveService()}
            >
              Add
            </button>
          </div>
        </div>
      }

      {
        isAddNewAmount &&
        <div className="wrapper flex-container sb editing">
          <div>
            <button
              onClick={handleCancelAddService}
              className="button-cancel"
            >
              Cancel
            </button>
          </div>

          <div>
            <h1 style={{ whiteSpace: 'nowrap', marginTop: '0', color: '#393939' }}>New Service</h1>
          </div>

          <div>
            <button
              className={classNames('button-save-disabled', {
                'button-save': addService.amount !== 0 && addService.name !== '' && addService.unit_id.length !== 0
              })}
              onClick={() => addService.amount !== 0 && addService.name !== '' && addService.unit_id.length !== 0 && handleSaveNewService()}
            >
              Add
            </button>
          </div>
        </div>
      }

      {
        isAddAmount && !isAddNewAmount &&
        <div className='line-items-content service'>
          <div className='line-items-row'>
            <div className='name'>
              Name
            </div>

            <div className='name grey input-name'>
              <div className='input-view'>
                {addService.name}
              </div>
            </div>
          </div>

          <div className='line-items-row'>
            <div className='name'>
              Description
            </div>

            <div className='name grey input-name'>
              <div className='input-view'>
                {addService.comment}
              </div>
            </div>
          </div>

          <div className='line-items-row'>
            <div className='name'>
              Price
            </div>

            <div className='name grey'>
              <input
                className="appearance"
                type='number'
                value={`${Number(addService.amount).toFixed(2)}`}
                step={0.01}
                onFocus={(event) => handleJobFocus(event)}
                onChange={(event) => handleJobChangeItem(event, 'amount')}
                onKeyDown={(event) => handleJobChangeItemBackspace(event, 'amount')}
              />
            </div>
          </div>

          <div className='line-items-row'>
            <div className='name'>
              Quantity
            </div>

            <div className='name grey'>
              <input
                className="appearance"
                type='number'
                min={1}
                value={addService.quantity}
                step={1}
                onChange={(event) => setAddService({ ...addService, quantity: +event.target.value })}
              />
            </div>
          </div>

          <UnitTable
            units={units}
            unit_id={addService.unit_id}
            addUnit={(value) => setAddService({ ...addService, unit_id: value })}
          />

          <span className='note'> Service name appears on invoice </span>
        </div>
      }

      {
        isAddNewAmount &&
        <div className='line-items-content service'>
          <div className='line-items-row'>
            <div className='name'>
              Name
            </div>

            <div className='name grey input-name'>
              <input
                type="text"
                placeholder='Service name'
                value={addService.name}
                onChange={(event) => setAddService({ ...addService, name: event.target.value })}
              />
            </div>
          </div>

          <div className='line-items-row'>
            <div className='name'>
              Description
            </div>

            <div className='name grey input-name'>
              <textarea
                placeholder='Description'
                rows={2}
                value={addService.comment}
                onChange={(event) => setAddService({ ...addService, comment: event.target.value })}
              />
            </div>
          </div>

          <div className='line-items-row'>
            <div className='name'>
              Price
            </div>

            <div className='name grey'>
              <input
                className="appearance"
                type='number'
                value={`${Number(addService.amount).toFixed(2)}`}
                step={0.01}
                onFocus={(event) => handleJobFocus(event)}
                onChange={(event) => handleJobChangeItem(event, 'amount')}
                onKeyDown={(event) => handleJobChangeItemBackspace(event, 'amount')}
              />
            </div>
          </div>

          <div className='line-items-row'>
            <div className='name'>
              Quantity
            </div>

            <div className='name grey'>
              <input
                className="appearance"
                type='number'
                min={1}
                value={addService.quantity}
                step={1}
                onChange={(event) => setAddService({ ...addService, quantity: +event.target.value })}
              />
            </div>
          </div>

          <UnitTable
            units={units}
            unit_id={addService.unit_id}
            addUnit={(value) => setAddService({ ...addService, unit_id: value })}
          />

          <div className='line-items-row'>
            <div className='name nowrap'>
              Save to price book
            </div>

            <Checkbox
              contents=""
              value={addService.is_save}
              onChange={(value) => setAddService({ ...addService, is_save: value })}
            />
          </div>

          <span className='note'> Service name appears on invoice </span>
        </div>
      }

      {
        !isAddAmount && !isAddNewAmount &&
        <div className='line-items-content service'>
          <div className='line-items-col'>
            <div className='line-items-title bold'>
              Services
            </div>

            <input
              type="text"
              placeholder='Search'
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />
          </div>

          <div className='line-items-tabs'>
            <div
              className={classNames('line-items-tab', { active: tab === 'all' })}
              onClick={() => setTab('all')}
            >
              All services
            </div>

            <div
              className={classNames('line-items-tab', { active: tab === 'favorite' })}
              onClick={() => setTab('favorite')}
            >
              Favorites
            </div>
          </div>

          <div className='line-items-col max-height'>
            <div className='line-items-title'>
              Frequently used items
            </div>

            {
              servicesUsed
                .map(item => (
                  <div
                    key={item.name}
                    className="services-item"
                    onClick={() => handleAddService(item.name, item.description, Number(item.price), item.list_service_id)}
                  >
                    <div className='services-item-info'>
                      <div className='services-item-info-name'>
                        {item.name}
                      </div>

                      <div className='services-item-info-comment'>
                        {item.description}
                      </div>
                    </div>

                    <div className='services-item-info-price'>
                      <div
                        onClick={(event) => {
                          event.stopPropagation()
                          handleFavoriteService(item.list_service_id, item.is_favorite)
                        }}
                      >
                        {
                          item.is_favorite ?
                            <Icon
                              style={{ width: '20px', height: '20px', fill: 'rgba(0,95,181,255)' }}
                              viewBox="0 0 1024 1024"
                              icon="star-87"
                            /> :
                            <Icon
                              style={{ width: '20px', height: '20px', fill: 'rgba(0,95,181,255)' }}
                              viewBox="0 0 1024 1024"
                              icon="star-108"
                            />
                        }
                      </div>

                      <div className='services-item-info-comment'>
                        ${item.price}
                      </div>
                    </div>
                  </div>
                ))
            }
          </div>

          <div
            className='line-items-col'
            style={{ border: 'none' }}
          >
            <div className='line-items-title'>
              Services
            </div>

            <div
              className="services-item"
              onClick={() => setIsAddNewAmount(true)}
            >
              <div> Custom Services </div>

              <Icon
                style={{ width: '16px', height: '16px', fill: '#ccc' }}
                viewBox="0 0 24 24"
                icon="arrow-25"
              />
            </div>

            {
              services
                .map(item => (
                  <div
                    key={item.name}
                    className="services-item"
                    onClick={() => handleAddService(item.name, item.description, Number(item.price), item.list_service_id)}
                  >
                    <div className='services-item-info'>
                      <div className='services-item-info-name'>
                        {item.name}
                      </div>

                      <div className='services-item-info-comment'>
                        {item.description}
                      </div>
                    </div>

                    <div className='services-item-info-price'>
                      <div
                        onClick={(event) => {
                          event.stopPropagation()
                          handleFavoriteService(item.list_service_id, item.is_favorite)
                        }}
                      >
                        {
                          item.is_favorite ?
                            <Icon
                              style={{ width: '20px', height: '20px', fill: 'rgba(0,95,181,255)' }}
                              viewBox="0 0 1024 1024"
                              icon="star-87"
                            /> :
                            <Icon
                              style={{ width: '20px', height: '20px', fill: 'rgba(0,95,181,255)' }}
                              viewBox="0 0 1024 1024"
                              icon="star-108"
                            />
                        }
                      </div>

                      <div className='services-item-info-comment'>
                        ${item.price}
                      </div>
                    </div>
                  </div>
                ))
            }
          </div>
        </div>
      }
    </div>
  )
}
