import { memo } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';

import CheckoutForm from './CheckoutForm';

interface StripeComponentProps {
  tokenStripe: string
  handleSaveStripePay: (value: string) => void
  loading: boolean
  setLoading: (value: boolean) => void
}

function StripeComponent({
  tokenStripe,
  handleSaveStripePay,
  loading,
  setLoading,
}: StripeComponentProps) {
  loadStripe.setLoadParameters({ advancedFraudSignals: false });
  const stripePromise = loadStripe(tokenStripe);

  const options = {
    mode: 'setup',
    currency: 'usd',
  };

  return (
    // @ts-ignore
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm
        handleSaveStripePay={handleSaveStripePay}
        loading={loading}
        setLoading={setLoading}
      />
    </Elements>
  );
};

export default memo(StripeComponent)