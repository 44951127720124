import { useEffect, useState } from 'react'
import classNames from 'classnames'

import Checkbox from '../../components/Checkbox'
import UnitTable from './UnitTable'

import { httpClientUpdate } from '../../funcs'
import { useAppSelector } from '../../store/hooks'

import { NewMaterialProps } from './AddMaterials'
import { UnitProps } from '../Jobs/ItemTech'

interface EditMaterialProps {
  setPage: (value: string) => void
  service: NewMaterialProps
  index: number
  handleSave: (value: NewMaterialProps, index: number) => void
  units: UnitProps[]
}

interface InitialNewMaterial {
  name: string,
  description: string,
  part_number: number,
  price_per_unit: number,
  quantity: number,
  unit_id: string[],
  taxable: boolean,
  is_save_price_book: boolean,
  id: string
}

const initialNewMaterial = { name: '', description: '', part_number: 0, price_per_unit: 0, quantity: 1, unit_id: [], taxable: false, is_save_price_book: false, id: '' }

export default function EditMaterial({ setPage, service, index, handleSave, units }: EditMaterialProps) {
  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [data, setData] = useState<InitialNewMaterial>(initialNewMaterial)

  useEffect(() => {
    setData(service)
  }, [service])

  async function handleSaveService() {
    try {
      if (data.is_save_price_book) {
        // /api/technician/services
        const response = await httpClientUpdate.post(`/technician/materials`, {
          account_id: activeAccountId,
          name: data.name,
          description: data.description,
          price: Number(data.price_per_unit).toFixed(2),
        })

        if (response.data.success) {
          handleSave(data, index)
          setData(initialNewMaterial)
        } else {
        }
      } else {
        handleSave(data, index)
        setData(initialNewMaterial)
      }
    } catch (error) { }
  }

  function handleJobFocus(event: any) {
    const target = event.currentTarget;

    target.type = 'text';
    target.setSelectionRange(target.value.length, target.value.length);
    target.type = 'number';
    target && target.focus()
  }

  function handleJobChangeItem(event: any) {
    let price = event.target.value

    let numberWithoutDot = price.split('.')

    if (Number(price) >= 0) {
      if (numberWithoutDot[1] && numberWithoutDot[1].toString().length > 2) {
        let newNumber = `${numberWithoutDot[0]}${numberWithoutDot[1].toString()[0]}.${numberWithoutDot[1].toString()[1]}${price[price.length - 1]}`
        setData({ ...data, price_per_unit: Number(newNumber) })
      } else {
        let newNumber = price

        setData({ ...data, price_per_unit: Number(newNumber) })
      }
    }
  }

  function handleJobChangeItemBackspace(event: any) {
    if (event.keyCode === 8) {
      event.preventDefault()
      let price = event.target.value
      let numberWithoutDot = `${price}`.split('.')
      if (numberWithoutDot[0].length > 1) {
        let newNumber = `${numberWithoutDot[0].slice(0, -1)}.${numberWithoutDot[0][numberWithoutDot[0].length - 1]}${numberWithoutDot[1][0]}`
        setData({ ...data, price_per_unit: Number(newNumber) })
      } else {
        let newNumber = `0.${numberWithoutDot[0][0]}${numberWithoutDot[1][0]}`
        setData({ ...data, price_per_unit: Number(newNumber) })
      }
    }
  }

  return (
    <div className="EstimatePage_Add entity-edit">
      <div className="wrapper flex-container sb editing">
        <div>
          <button
            onClick={() => setPage('editing')}
            className="button-cancel"
          >
            Cancel
          </button>
        </div>

        <div>
          <h1 style={{ whiteSpace: 'nowrap', marginTop: '0', color: '#393939' }}>Edit Material</h1>
        </div>

        <div>
          <button
            className={classNames('button-save-disabled', {
              'button-save': data.price_per_unit !== 0 && data.name !== ''
            })}
            onClick={() => data.price_per_unit !== 0 && data.name !== '' && handleSaveService()}
          >
            Save
          </button>
        </div>
      </div>

      {
        (data.is_save_price_book || data.id) &&
        <div className='line-items-content service'>
          <div className='line-items-row'>
            <div className='name'>
              Name
            </div>

            <div className='name grey input-name'>
              <div className='input-view'>
                {data.name}
              </div>
            </div>
          </div>

          <div className='line-items-row'>
            <div className='name'>
              Description
            </div>

            <div className='name grey input-name'>
              <div className='input-view'>
                {data.description}
              </div>
            </div>
          </div>

          <div className='line-items-row'>
            <div className='name'>
              Price
            </div>

            <div className='name grey'>
              <input
                className="appearance"
                type='number'
                value={`${Number(data.price_per_unit).toFixed(2)}`}
                step={0.01}
                onFocus={(event) => handleJobFocus(event)}
                onChange={(event) => handleJobChangeItem(event)}
                onKeyDown={(event) => handleJobChangeItemBackspace(event)}
              />
            </div>
          </div>

          <div className='line-items-row'>
            <div className='name'>
              Quantity
            </div>

            <div className='name grey'>
              <input
                className="appearance"
                type='number'
                min={1}
                value={data.quantity}
                step={1}
                onChange={(event) => setData({ ...data, quantity: +event.target.value })}
              />
            </div>
          </div>

          <UnitTable
            units={units}
            unit_id={data.unit_id}
            addUnit={(value) => setData({ ...data, unit_id: value })}
          />

          <span className='note'> Material name appears on invoice </span>
        </div>
      }

      {
        !data.is_save_price_book && data.id === '' &&
        <div className='line-items-content service'>
          <div className='line-items-row'>
            <div className='name'>
              Name
            </div>

            <div className='name grey input-name'>
              <input
                type="text"
                placeholder='Service name'
                value={data.name}
                onChange={(event) => setData({ ...data, name: event.target.value })}
              />
            </div>
          </div>

          <div className='line-items-row'>
            <div className='name'>
              Description
            </div>

            <div className='name grey input-name'>
              <textarea
                placeholder='Description'
                rows={2}
                value={data.description}
                onChange={(event) => setData({ ...data, description: event.target.value })}
              />
            </div>
          </div>

          <div className='line-items-row'>
            <div className='name'>
              Price
            </div>

            <div className='name grey'>
              <input
                className="appearance"
                type='number'
                value={`${Number(data.price_per_unit).toFixed(2)}`}
                step={0.01}
                onFocus={(event) => handleJobFocus(event)}
                onChange={(event) => handleJobChangeItem(event)}
                onKeyDown={(event) => handleJobChangeItemBackspace(event)}
              />
            </div>
          </div>

          <div className='line-items-row'>
            <div className='name'>
              Quantity
            </div>

            <div className='name grey'>
              <input
                className="appearance"
                type='number'
                min={1}
                value={data.quantity}
                step={1}
                onChange={(event) => setData({ ...data, quantity: +event.target.value })}
              />
            </div>
          </div>

          <UnitTable
            units={units}
            unit_id={data.unit_id}
            addUnit={(value) => setData({ ...data, unit_id: value })}
          />

          <div className='line-items-row'>
            <div className='name nowrap'>
              Save to price book
            </div>

            <Checkbox
              contents=""
              value={data.is_save_price_book}
              onChange={(value) => setData({ ...data, is_save_price_book: value })}
            />
          </div>

          <span className='note'> Material name appears on invoice </span>
        </div>
      }
    </div>
  )
}
