import { memo, useState, useEffect } from 'react'
import moment from 'moment'
import classNames from "classnames"

import Icon from '../../components/Icon'
import Select from '../../components/Select'

import { httpClientUpdate, updatedDateToReqServer, usePrevious, useOuterClick } from '../../funcs'
import { useAppSelector } from '../../store/hooks'

import { checkAvailableDataProps, SelectedAvailabelTimeProps, ServiceEditProps, SettingsProps } from './Models'
import { SelectedMenualTime } from '../AppointmentReschedule/Models'
import { HttpReserveReport } from './Booking'

const timeOptions = [{ span: '12:00AM', value: '00:00:00' }, { span: '01:00AM', value: '01:00:00' }, { span: '02:00AM', value: '02:00:00' }, { span: '03:00AM', value: '03:00:00' }, { span: '04:00AM', value: '04:00:00' }, { span: '05:00AM', value: '05:00:00' }, { span: '06:00AM', value: '06:00:00' }, { span: '07:00AM', value: '07:00:00' }, { span: '08:00AM', value: '08:00:00' }, { span: '09:00AM', value: '09:00:00' }, { span: '10:00AM', value: '10:00:00' }, { span: '11:00AM', value: '11:00:00' }, { span: '12:00PM', value: '12:00:00' }, { span: '01:00PM', value: '13:00:00' }, { span: '02:00PM', value: '14:00:00' }, { span: '03:00PM', value: '15:00:00' }, { span: '04:00PM', value: '16:00:00' }, { span: '05:00PM', value: '17:00:00' }, { span: '06:00PM', value: '18:00:00' }, { span: '07:00PM', value: '19:00:00' }, { span: '08:00PM', value: '20:00:00' }, { span: '09:00PM', value: '21:00:00' }, { span: '10:00PM', value: '22:00:00' }, { span: '11:00PM', value: '23:00:00' }, { span: '12:00AM', value: '24:00:00' }]

interface AvalTimeProps {
  checkAvailable: checkAvailableDataProps
  show_service: boolean
  setSelectedAvailabelTime: (value: SelectedAvailabelTimeProps | null) => void
  selectedAvailabelTime: SelectedAvailabelTimeProps | null
  startReserve: boolean
  reserveAvalTime: (
    start: string,
    end: string,
    service_resource_id: string,
    type: string,
    time_zone: string,
    duration?: number) => void
  serviceOptions: ServiceEditProps[]
  doubleSelected: string
  setDoubleSelected: (value: string) => void
  availability: boolean
  on_date: Date
  menualSettings: SelectedMenualTime
  setMenualSettings: (value: SelectedMenualTime) => void
  selectedMenualTime: boolean
  setSelectedMenualTime: (value: boolean) => void
  cancelChangeTime: boolean
  appointment_reserve_time: boolean
  isSaveClick: boolean
  isAvalableOneService: string[]
  timeDuration: number
  setSettings: (value: SettingsProps) => void
  settings: SettingsProps
  appointment_reserve_time_set_duration: boolean | undefined
}

interface SelectedAvailabelTimeReserveProps {
  start: string
  end: string
  id: string
  time_zone: string
}

function AvalTime({
  checkAvailable,
  show_service,
  setSelectedAvailabelTime,
  selectedAvailabelTime,
  startReserve,
  reserveAvalTime,
  serviceOptions,
  doubleSelected,
  setDoubleSelected,
  availability,
  on_date,
  menualSettings,
  setMenualSettings,
  selectedMenualTime,
  setSelectedMenualTime,
  cancelChangeTime,
  appointment_reserve_time,
  isSaveClick,
  isAvalableOneService,
  timeDuration,
  setSettings,
  settings,
  appointment_reserve_time_set_duration,
}: AvalTimeProps) {
  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [selected, setSelected] = useState('')

  const [showPopup, setShowPopup] = useState('')

  const [selectedAvalTime, setSelectedAvalTime] = useState<SelectedAvailabelTimeReserveProps | null>(null)

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const [durationSettingsOpen, setDurationSettingsOpen] = useState('')
  const [duration, setDuration] = useState<number | null>(null)
  const pervDuration = usePrevious(duration)

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          startReserve && selectedAvalTime && reserveAvalTime(selectedAvalTime.start, selectedAvalTime.end, selectedAvalTime.id, 'unblock', selectedAvalTime.time_zone)
          setSelectedAvailabelTime(null)
          setDoubleSelected('')
          setDuration(null)
          clearInterval(myInterval)
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000)
    return () => {
      clearInterval(myInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds])

  useEffect(() => {
    if (startReserve) {
      setMinutes(4)
      setSeconds(59)
    } else {
      setMinutes(0)
      setSeconds(0)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startReserve])

  useEffect(() => {
    if (duration) {
      if (pervDuration) {
        setMinutes(duration + minutes - pervDuration)
      } else {
        setMinutes(duration + minutes - 5)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration])

  useEffect(() => {
    if (availability) {
      setMenualSettings({
        start: '',
        end: '',
        service_resource_id: '',
        service_resource_name: '',
        time_zone: ''
      })
    } else {
      setMinutes(0)
      setSeconds(0)
    }
    setSelected('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availability])

  useEffect(() => {
    setSelected('')
  }, [checkAvailable])

  useEffect(() => {
    if (cancelChangeTime) {
      selectedAvalTime && reserveAvalTime(selectedAvalTime.start, selectedAvalTime.end, selectedAvalTime.id, 'unblock', selectedAvalTime.time_zone)
      setDuration(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelChangeTime])

  useEffect(() => {
    setSelectedMenualTime(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menualSettings])

  function handleSelected(event: any, selectTime: string, id: string, start: string, end: string, technician: string, time_slot_start: string, time_slot_finish: string, time_zone: string) {
    event.preventDefault()
    event.stopPropagation()

    if (event.type === 'contextmenu') {
      setDurationSettingsOpen(selectTime)
    } else {
      if (selected === '') {
        setSelected(selectTime)
        setDoubleSelected('')
      } else if (selected !== selectTime) {
        setSelected(selectTime)
        doubleSelected && selectedAvalTime && reserveAvalTime(selectedAvalTime.start, selectedAvalTime.end, selectedAvalTime.id, 'unblock', time_zone)
        setSelectedAvailabelTime(null)
        setDoubleSelected('')
        setDuration(null)
      } else {
        if (appointment_reserve_time) {
          if (doubleSelected === '') {
            setDoubleSelected(selectTime)
            reserveAvalTime(start, end, id, 'block', time_zone)
            setSelectedAvalTime({
              start,
              end,
              id,
              time_zone
            })
            setSelectedAvailabelTime({
              service_resource_id: id,
              time_slot_start: start,
              time_slot_finish: end,
              technician: technician,
              time_zone: time_zone,
            })
          } else {
            doubleSelected && selectedAvalTime && reserveAvalTime(selectedAvalTime.start, selectedAvalTime.end, selectedAvalTime.id, 'unblock', time_zone)
            setSelectedAvailabelTime(null)
            setDoubleSelected('')
            setDuration(null)
          }
        } else {
          setSelected('')
        }
      }
    }
  }

  function handleChangeDuration(event: any, duration: number) {
    event.stopPropagation()
    setDurationSettingsOpen('')
    if (selectedAvailabelTime) {
      reserveAvalTimeWithDuration(selectedAvailabelTime.time_slot_start, selectedAvailabelTime.time_slot_finish, selectedAvailabelTime.service_resource_id, 'block', selectedAvailabelTime.time_zone, duration)
    }
  }

  async function reserveAvalTimeWithDuration(start: string, end: string, service_resource_id: string, type: string, time_zone: string, duration: number) {
    try {

      // https://2022back4.artemiudintsev.com/api/bookings/appointments/reserve
      const { data: { data: reserveData, success } } = await httpClientUpdate.post('/bookings/appointments/reserve', {
        account_id: activeAccountId,
        start: updatedDateToReqServer('yyyy-MM-dd hh:mm:ss', time_zone, start as string),
        end: updatedDateToReqServer('yyyy-MM-dd hh:mm:ss', time_zone, end as string),
        service_resource_id,
        type,
        duration
      }) as { data: HttpReserveReport }
      if (success) {
        if (reserveData.reserved_at) {
          setDuration(duration)
        }
      }
    }
    catch (error) { }
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setDurationSettingsOpen('')
  });

  return (
    <div className='call-avaltime'>
      <div className='block-content'>
        {
          availability &&
          <div style={{
            position: 'absolute',
            top: '20px',
            color: '#FF0000'
          }} >
            {
              minutes === 0 && seconds === 0
                ? null
                : <>{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</>
            }

          </div>
        }

        {
          availability ?
            <div className='block-content-title'>
              <div className='block-title'>
                Available Time
              </div>

              {
                !selectedAvailabelTime ?
                  <div className='btn-marking attention'>
                    !
                  </div> :
                  <div className='btn-marking saved'>
                    <Icon style={{ fill: 'white', width: '65%' }} icon="check-mark-1" />
                  </div>
              }
            </div> :
            <div className='block-content-title'>
              <div className='block-title'>
                Manual schedule
              </div>

              {
                !selectedMenualTime ?
                  <div className='btn-marking attention'>
                    !
                  </div> :
                  <div className='btn-marking saved'>
                    <Icon style={{ fill: 'white', width: '65%' }} icon="check-mark-1" />
                  </div>
              }
            </div>
        }
      </div>

      {
        availability ? checkAvailable.available && checkAvailable.available.map((item, idx) => (
          <div
            key={item.date}
            className='block-content'
            style={{ paddingTop: '0' }}
          >
            <div className='call-avaltime_block'>
              <div className='call-avaltime_date'>
                <p> {moment(item.date, 'YYYY/MM/DD').format('YYYY-MM-DD ddd')} </p>
              </div>

              <div className='call-avaltime_hours'>
                {
                  item.service_resource.length ? item.service_resource.map((service, index) => (
                    <div key={index} className='call-avaltime_hour'>
                      {
                        isAvalableOneService.includes(service.service_resource_id) ?
                          <div
                            className={classNames('data-border call-avaltime_indicator', {
                              isOne: isAvalableOneService.includes(service.service_resource_id),
                            })}
                          ></div> :
                          service.priority < 50 ?
                            <Icon
                              viewBox='0 0 1024 1024'
                              style={{
                                fill: isAvalableOneService.includes(service.service_resource_id) ? '#01B41F' : '#FF0000',
                                width: '13px',
                                minWidth: '13px',
                                marginRight: '4px',
                              }}
                              icon="arrow-205"
                            /> :
                            <div
                              className={classNames('data-border call-avaltime_indicator', {
                                isOne: isAvalableOneService.includes(service.service_resource_id),
                              })}
                            ></div>
                      }

                      <div
                        className={classNames('call-avaltime_time', {
                          selected: selected === `${service.service_resource_id}_${idx}_${index}`,
                          doubleSelected: doubleSelected === `${service.service_resource_id}_${idx}_${index}`,
                          isOne: isAvalableOneService.includes(service.service_resource_id),
                        })}
                        onClick={(event) => {
                          !isSaveClick &&
                            handleSelected(
                              event,
                              `${service.service_resource_id}_${idx}_${index}`,
                              service.service_resource_id,
                              `${item.date.replaceAll('/', '-')} ${service.time_slot_start}`,
                              `${item.date.replaceAll('/', '-')} ${service.time_slot_finish}`,
                              service.service_resource_code ? `${service.service_resource}(${service.service_resource_code})` : service.service_resource,
                              service.time_slot_start,
                              service.time_slot_finish,
                              service.service_resource_time_zone
                            )
                        }}
                        onContextMenu={(event) => {
                          !isSaveClick &&
                            selectedAvailabelTime &&
                            appointment_reserve_time_set_duration &&
                            handleSelected(
                              event,
                              `${service.service_resource_id}_${idx}_${index}`,
                              service.service_resource_id,
                              `${item.date.replaceAll('/', '-')} ${service.time_slot_start}`,
                              `${item.date.replaceAll('/', '-')} ${service.time_slot_finish}`,
                              service.service_resource_code ? `${service.service_resource}(${service.service_resource_code})` : service.service_resource,
                              service.time_slot_start,
                              service.time_slot_finish,
                              service.service_resource_time_zone
                            )
                        }}
                      >
                        {
                          durationSettingsOpen === `${service.service_resource_id}_${idx}_${index}` &&
                          <div
                            className='settings-reserve-time'
                            ref={contextMenuRef}
                          >
                            <button
                              className={classNames({
                                active: duration === 10,
                              })}
                              onClick={(event) => handleChangeDuration(
                                event,
                                10,
                              )}
                            >
                              10 min
                            </button>

                            <button
                              className={classNames({
                                active: duration === 20,
                              })}
                              onClick={(event) => handleChangeDuration(
                                event,
                                20,
                              )}
                            >
                              20 min

                            </button>

                            <button
                              className={classNames({
                                active: duration === 30,
                              })}
                              onClick={(event) => handleChangeDuration(
                                event,
                                30,
                              )}
                            >
                              30 min
                            </button>

                            <button onClick={(event) => {
                              event.stopPropagation()
                              setDurationSettingsOpen('')
                            }}
                            >
                              Close
                            </button>
                          </div>
                        }
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '10%'
                          }}
                        >
                          {
                            service.note_no &&
                            <div
                              className='btn-marking attention'
                              style={{
                                marginLeft: '0',
                                width: '16px',
                                height: '16px'
                              }}
                              onMouseEnter={() => setShowPopup(`${service.service_resource_id}_${idx}_${index}`)}
                              onMouseLeave={() => setShowPopup('')}
                            >
                              !
                            </div>
                          }
                        </div>

                        <div style={{ width: '80%' }} >
                          {
                            show_service ?
                              <p>
                                {moment(service.time_slot_start, 'HH:mm:ss').format('h:mmA')} - {moment(service.time_slot_finish, 'HH:mm:ss').format('h:mmA')} / {service.service_resource_code ? `${service.service_resource}(${service.service_resource_code})` : service.service_resource}
                              </p> :
                              <p> 
                                {moment(service.time_slot_start, 'HH:mm:ss').format('h:mmA')} - {moment(service.time_slot_finish, 'HH:mm:ss').format('h:mmA')} 
                                </p>
                          }
                        </div>

                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '10%'
                          }}
                        >
                          {
                            service.note_yes &&
                            <div
                              className='btn-marking saved'
                              style={{
                                marginLeft: '0',
                                width: '16px',
                                height: '16px'
                              }}
                              onMouseEnter={() => setShowPopup(`${service.service_resource_id}_${idx}_${index}`)}
                              onMouseLeave={() => setShowPopup('')}
                            >
                              !
                            </div>
                          }
                        </div>

                        {
                          showPopup === `${service.service_resource_id}_${idx}_${index}` &&

                          <div className='popup-aval-time'>
                            {
                              service.note_yes &&
                              <div className='popup-item'>
                                <div
                                  className='btn-marking saved'
                                  title={service.note_yes ? service.note_yes : ''}
                                  style={{
                                    marginLeft: '0',
                                    width: '16px',
                                    height: '16px'
                                  }}
                                >
                                  !
                                </div>
                                <p>
                                  {service.note_yes}
                                </p>
                              </div>
                            }

                            {
                              service.note_yes && service.note_no &&
                              <div className='separator'></div>
                            }

                            {
                              service.note_no &&
                              <div className='popup-item'>
                                <div
                                  className='btn-marking attention'
                                  title={service.note_no ? service.note_no : ''}
                                  style={{
                                    marginLeft: '0',
                                    width: '16px',
                                    height: '16px'
                                  }}
                                >
                                  !
                                </div>
                                <p>
                                  {service.note_no}
                                </p>
                              </div>
                            }
                          </div>
                        }
                      </div>
                    </div>
                  )) :
                    <div>
                      <p
                        style={{
                          fontSize: '12px',
                          color: '#FF0000',
                          fontWeight: '300'
                        }}
                      >
                        There are no available timeslots for this day. Please check another date.
                      </p>
                    </div>
                }
              </div>
            </div>
          </div>
        )) :
          <div style={{ paddingTop: '0' }} className='block-content'>
            <div className='call-avaltime_block'>
              <div className='call-avaltime_date'>
                <p> {moment(on_date).format('YYYY-MM-DD')} </p>
              </div>
            </div>
            <div
              className='call-bookingSettings_row call-bookingSettings_ontime'
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '14px',
              }}
            >
              <div
                className='call-bookingSettings_listBlock call-bookingSettings_ontime-listBlock selected-time'
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <div style={{ width: '100%' }}>
                  <Select options={timeOptions.map((time) => ({
                    span: time.span,
                    value: time.value
                  }))}
                    selectedOption={menualSettings.start}
                    onChange={(value: string) => setMenualSettings({ ...menualSettings, start: value })}
                  />
                </div>
                <p style={{ width: '30px', textAlign: 'center' }}>-</p>
                <div style={{ width: '100%' }}>
                  <Select options={timeOptions.filter(item => item.value >= menualSettings.start).map((time) => ({
                    span: time.span,
                    value: time.value
                  }))}
                    selectedOption={menualSettings.end}
                    onChange={(value: string) => setMenualSettings({ ...menualSettings, end: value })}
                  />
                </div>
              </div>
            </div>
            {
              serviceOptions &&
              <div
                className='call-bookingSettings_row call-bookingSettings_ontime'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  marginBottom: '14px',
                }}
              >
                <div
                  className='call-bookingSettings_listBlock call-bookingSettings_ontime-listBlock'
                  style={{ width: '100%' }}
                >
                  <Select options={serviceOptions.sort((a, b) => a.code - b.code).map((service) => ({
                    span: service.code ? `${service.name} (${service.code})` : service.name,
                    value: service.service_resource_id
                  }))}
                    selectedOption={menualSettings.service_resource_id}
                    onChange={(value: string, span: string) => setMenualSettings({ ...menualSettings, service_resource_id: value, service_resource_name: span })}
                  />
                </div>
              </div>
            }
            <div
              className='call-bookingSettings_row call-bookingSettings_ontime'
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '14px',
              }}
            >
              <div
                className='call-bookingSettings_listBlock call-bookingSettings_ontime-listBlock'
                style={{ width: '80%', justifyContent: 'center' }}
              >
                <button
                  className={classNames('btn', 'btn-apply', {
                    'btn-selected': selectedMenualTime,
                  })}
                  style={{ width: '100%', flex: 'inherit' }}
                  disabled={menualSettings.service_resource_id === '' || menualSettings.start === '' || menualSettings.end === ''}
                  onClick={() => setSelectedMenualTime(true)}
                >
                  Set Up
                </button>
              </div>
            </div>
          </div>
      }
    </div >
  )
}

export default memo(AvalTime)
