import { useEffect, useState } from 'react'
import { useRoute } from 'react-router5'
import { DateTime } from 'luxon';
import classNames from 'classnames';

import ReportTableControls from '../../components/reports/TableControls';
import SelectComponent from '../../components/Select';

import { getSchuduleTime, httpClientUpdate, nErrorUpdate } from '../../funcs'
import { useAppSelector } from '../../store/hooks'
import { getDateRangeByPreset } from '../../funcs/reports';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";
import { DateRangePreset } from '../../models/Misc';

import './List.sass'
import Checkbox from '../../components/Checkbox';

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: JobsListData
}

interface JobsListData {
  interface: {
    max_pages: number,
    rows_start: number,
    rows_end: number,
    rows_all: number,

    min_date: Date,
    max_date: Date
  },

  edit: EditOptions[]

  permissions: {
    add_jobs_to_ignore_list: boolean
    technician_job_report_show: boolean
    technician_job_report_show_own: boolean
  },

  jobs: JobsItem[]
}

interface EditOptions {
  code: number
  nickname: string
  service_resource_id: string
}

interface JobsItem {
  appointments: AppointmentsProps[]
  area: string
  created_at: string
  invoices: InvoicesProps[]
  job_id: string
  name: string
  paid: number
  parts: PartsProps[]
  property_type: string
  service_resource_names: string
  status: string
  total: number
  total_item: string
}

interface AppointmentsProps {
  appointment_id: string
  contact_id: string
  created_at: string
  is_payout: boolean
  job_id: string
  name: string
  note: string
  schedule_time_finish: string
  schedule_time_start: string
  service_resource_id: string
  status: string
  status_id: number
  type: string
  units: UnitsProps[]
}

interface UnitsProps {
  appliance_id: string
  brand_id: string
  model_number: string
  other_appliance: string
  other_brand: string
  pivot: { appointment_id: string, unit_id: string }
  serial_number: string
  unit_id: string
  unit_type: string
}

interface InvoicesProps {
  comment: string
  created_at: string
  deposit_amount: string
  deposit_due_date: string
  estimate_id: string
  expiration_date: string
  invoice_id: string
  job_id: string
  name: string
  payment_items: PaymentItemsProps[]
  service_resource_id: string
  status: string
  status_id: number
  total: number
}

interface PaymentItemsProps {
  contact_id: string
  created_at: string
  invoice_id: string
  job_id: string
  note: string
  payment_id: string
  payment_item_id: string
  total_item: string
}

interface PartsProps {
  created_at: string
  invoice_id: string
  job_id: string
  name: string
  other_warehouse: string
  owner: string
  part_id: string
  part_number: string
  price: string
  quantity: number
  service_resource_id: string
  status: string
  status_id: number
  unit_id: string
  warehouse_id: string
}

export default function List({ updated }: { updated: number }) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const maxRows = useAppSelector((store) => store.reportsMaxRows)
  const user = useAppSelector((store) => store.user)

  const timeZone = user?.time_zone

  const [$updater, $setUpdater] = useState<any>(Math.random())

  const [report, setReport] = useState<JobsListData | null>(null)
  const [options, setOptions] = useState<EditOptions[]>([])
  const [selectedService, setSelectedService] = useState('')
  const [mode, setMode] = useState('payout')
  const [selectedJob, setSelectedJob] = useState<string[]>([])

  const [isSaving, setIsSaving] = useState(false)

  const [localInterface, setLocalInterface] = useState(
    {
      search: '',
      jobSharePopup: false,
      dateRangeCalendarShown: false,
      dateRangePreset: "today" as DateRangePreset,
      ...(getDateRangeByPreset(user, 'today', DateTime.now().setZone(timeZone).startOf('day').toJSDate(), DateTime.now().setZone(timeZone).endOf('day').toJSDate())),
      page: 1,
      sort: {
        field: 'created_at',
        direction: 'down' as 'down' | 'up'
      },
      sortFields: [{
        span: 'Date',
        value: 'created_at'
      }, {
        span: 'Name',
        value: 'name'
      }, {
        span: 'Status',
        value: 'name'
      }, {
        span: 'Property type',
        value: 'property_type'
      }, {
        span: 'Area',
        value: 'area'
      }, {
        span: 'Source',
        value: 'source'
      }, {
        span: 'Status',
        value: 'status'
      }, {
        span: 'Created by',
        value: 'created_by'
      }, {
        span: 'Created by Dispatcher Code',
        value: 'created_by_dispatcher_code'
      }, {
        span: 'Appointments',
        value: 'appointments'
      }, {
        span: 'Service resource',
        value: 'service_resource_names'
      }, {
        span: 'Service resource Code',
        value: 'first_service_resource_code'
      }, {
        span: 'Total',
        value: 'total'
      }, {
        span: 'Paid',
        value: 'paid'
      }]
    })

  // Load jobs function
  async function loadJobs() {
    try {
      // api/technician/jobs/report?account_id=88888&mode=payout&service_resource_id=88888010ty7gjfyh91&limit_rows=100&page=1
      const { data: { data: jobs, success, error } } = (await httpClientUpdate.get('/technician/jobs/report', {
        params: {
          account_id: activeAccountId,
          mode: mode,
          limit_rows: maxRows,
          page: 1,
        }
      })) as { data: HttpClientUpdateReport }

      if (success) {
        setReport(jobs)
        if (jobs.permissions.technician_job_report_show) {
          setOptions(jobs.edit)
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load jobs function
  async function loadJobsWithService() {
    try {
      // api/technician/jobs/report?account_id=88888&mode=payout&service_resource_id=88888010ty7gjfyh91&limit_rows=100&page=1
      const { data: { data: jobs, success, error } } = (await httpClientUpdate.get('/technician/jobs/report', {
        params: {
          account_id: activeAccountId,
          mode: mode,
          service_resource_id: selectedService,
          limit_rows: maxRows,
          page: 1,
        }
      })) as { data: HttpClientUpdateReport }

      if (success) {
        setReport(jobs)
        if (jobs.permissions.technician_job_report_show) {
          setOptions(jobs.edit)
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function handleIgnorList() {
    try {
      setIsSaving(true)
      // https://2022back4.artemiudintsev.com/api/technician/jobs/ignore
      const response = await httpClientUpdate.post(`/technician/jobs/ignore`, {
        account_id: activeAccountId,
        job_ids: selectedJob,
      })
      if (response.data.success) {
        setSelectedJob([])
        setIsSaving(false)
        if (!!selectedService) {
          setReport(null)
          loadJobsWithService()
        } else {
          setReport(null)
          loadJobs()
        }
      } else {
        setSelectedJob([])
        setIsSaving(false)
      }
    } catch (error: Error | AxiosError | unknown) {
      setSelectedJob([])
      setIsSaving(false)
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }


  // Load jobs on page mount
  useEffect(() => {
    loadJobs()

    // unmount page
    return () => {
      // setReportData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$updater])

  useEffect(() => {
    if (!!selectedService) {
      setReport(null)
      loadJobsWithService()
    }
  }, [selectedService])

  function handleSelectedJob(id: string) {
    let updated = selectedJob.map(item => item)

    if (updated.includes(id)) {
      setSelectedJob(updated.filter(item => item !== id))
    } else {
      updated.push(id)
      setSelectedJob(updated)
    }
  }

  return (
    <>
      {
        report &&
        <div className="JobsPage_List">
          <div className="page-header">
            <h1>Jobs List</h1>
          </div>

          <div className="ReportFilters">
            <div className="flex-container">
              {
                report.permissions.technician_job_report_show &&
                <div className="search-select">
                  {
                    <SelectComponent
                      options={options.map((item) => ({
                        span: item.code ? `${item.nickname} (${item.code})` : item.nickname,
                        value: item.service_resource_id
                      }))}
                      selectedOption={selectedService as string}
                      onChange={(value) => {
                        setSelectedService(value as string)
                      }}
                    />
                  }
                </div>
              }

              <div className="search-select">
                {
                  <SelectComponent
                    options={[{ span: 'payout', value: 'payout' }].map((item) => ({
                      span: item.span,
                      value: item.value
                    }))}
                    selectedOption={mode as string}
                    onChange={(value) => {
                      setMode(value as string)
                    }}
                  />
                }
              </div>

              {
                report.permissions.add_jobs_to_ignore_list &&
                <div className="search-select">
                  <button
                    className="_bordered _blue"
                    disabled={selectedJob.length === 0 || isSaving}
                    onClick={handleIgnorList}
                  >
                    Ignore
                  </button>
                </div>
              }
            </div>
          </div>

          <ReportTableControls
            zIndex={5}


            onMaxRowsChange={(value) => {
              $setUpdater(Math.random())
            }}

            amount={{
              total: report?.interface?.rows_all ? report.interface.rows_all : 0,
              start: report?.interface?.rows_start ? report.interface.rows_start : 0,
              end: report?.interface?.rows_end ? report.interface.rows_end : 0
            }}

            page={localInterface.page}
            maxPages={report?.interface?.max_pages ? report.interface.max_pages : 1}
            onPagesStart={() => {
              setLocalInterface({ ...localInterface, page: 1 })
              $setUpdater(Math.random())
            }}
            onPrevPage={() => {
              setLocalInterface({ ...localInterface, page: localInterface.page - 1 })
              $setUpdater(Math.random())
            }}
            onNextPage={() => {
              setLocalInterface({ ...localInterface, page: localInterface.page + 1 })
              $setUpdater(Math.random())
            }}
            onPagesEnd={() => {
              setLocalInterface({ ...localInterface, page: report?.interface?.max_pages ? report.interface.max_pages : 1 })
              $setUpdater(Math.random())
            }}

            sort={localInterface.sort}
            sortFields={localInterface.sortFields}
            onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
            onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
            onSortFire={() => $setUpdater(Math.random())}
          />

          <div className="contents-jobs-list">
            {
              report.jobs.map(item => (
                <div
                  key={item.job_id}
                  className="job-item"
                >
                  <div className='job-info'>
                    {
                      report.permissions.add_jobs_to_ignore_list &&
                      <Checkbox
                        contents={""}
                        value={selectedJob.includes(item.job_id)}
                        onChange={() => handleSelectedJob(item.job_id)}
                      />
                    }

                    <span className='name'> Job: {item.name} </span>

                    <span className='paid'>Paid: ${item.paid ? item?.paid?.toFixed(2) : '0.00'} </span>

                    <span className='paid'>Total: ${item.total ? item.total.toFixed(2) : '0.00'} </span>

                    <span
                      className={classNames('status', {
                        'red-text': item?.status?.toLowerCase() === 'canceled',
                        'green-text': item?.status?.toLowerCase() === 'closed',
                      })}
                    > {item.status} </span>
                  </div>

                  {
                    item.appointments.map(appoint_item => (
                      <div key={appoint_item.appointment_id} className='appointments'>
                        <div className='row'>
                          <div className='name'>Appointment: </div>
                          <div
                            className={classNames('type',
                              {
                                SC: appoint_item.type && appoint_item.type === 'SC',
                                RC: appoint_item.type && appoint_item.type === 'RC',
                                FU: appoint_item.type && appoint_item.type === 'FU',
                              }
                            )}
                          >
                            {appoint_item.type}
                          </div>

                          <div className='time'>
                            {getSchuduleTime(appoint_item.schedule_time_start, appoint_item.schedule_time_finish, timeZone as string)}
                          </div>
                        </div>

                        {
                          appoint_item.units.map(unit_item => (
                            <div className='unit' key={unit_item.unit_id}>
                              <div className='job-tech-content-item-text'>
                                <span className='name'> Unit: </span>
                                <span> {unit_item.unit_type} </span>

                                <span> {unit_item.serial_number} </span>
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    ))
                  }

                  {
                    item.invoices.map(invoice_item => (
                      <div className='appointments invoice' key={invoice_item.invoice_id}>
                        <div className='row'>
                          <div className='name'>Invoice: </div>

                          <div> {invoice_item.name} </div>

                          <div> Total: ${invoice_item.total.toFixed(2)} </div>

                          <div> Status: {invoice_item.status} </div>
                        </div>

                        {
                          invoice_item.payment_items.map(pay_item => (
                            <div className='unit' key={pay_item.payment_item_id}>
                              <div className='job-tech-content-item-text'>
                                <span className='name'> Payment: </span>
                                <span> ${pay_item.total_item} </span>
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    ))
                  }

                  {
                    item.parts.map(part_item => (
                      <div className='appointments' key={part_item.part_id}>
                        <div className='row'>
                          <div className='name'>Part: </div>

                          <div> {part_item.name} </div>

                          <div> {part_item.status} </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              ))
            }
          </div>
        </div>
      }
    </>
  )
}
