import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import classNames from "classnames"
import moment, { Moment } from "moment";
import Datetime from 'react-datetime'

import Icon from "../../components/Icon"
import Select from "../../components/Select"
import Checkbox from "../../components/Checkbox"

import Appointment from "../../models/Appointment"
import Estimate from "../../models/Estimate"
import Invoice from "../../models/Invoice"
import Payment from "../../models/Payments"
import Client from "../../models/Client"
import Job from "../../models/Job"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import { formatPhoneNumber, httpClientUpdate, nErrorUpdate, useOuterClick, getSchuduleTime, dateToInfoBlock } from "../../funcs"
import { useAppSelector } from '../../store/hooks'

import "../../styles/pages/common/entity-edit.sass"
import "../../styles/pages/common/redirect-menu.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: InvoiceData
}

interface InvoiceData {
  permissions: {
    invoice_add: boolean
    invoice_delete: boolean
    invoice_edit: boolean
    invoice_report_show: boolean
    invoice_show: boolean

    appointment_show: boolean
    client_show: boolean
    estimate_show: boolean
    job_show: boolean
    payment_show: boolean
  },

  invoice: Invoice[],
  clients: Client[],
  appointments: Appointment[],
  estimates: Estimate[],
  payments: Payment[],
  jobs: Job[],

  edit: {
    status: {
      id: number
      name: string
    }[]
  }
}

function InvoicePage_Item() {
  const $router = useRoute()
  // const contextMenuRef = useRef()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  const [isSaving, setIsSaving] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)

  const [editing, setEditing] = useState(false)
  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)

  const [invoiceData, setInvoiceData] = useState<InvoiceData | null>(null)

  const [newInvoiceData, setNewInvoiceData] = useState<Partial<Invoice>>({
    status: '',
  })

  function handleCancelEdit() {
    setEditing(false)

    invoiceData && setNewInvoiceData(invoiceData.invoice[0])
  }

  // remove document
  async function handleRemoveItem() {
    try {
      setIsSaving(true)
      const response = await httpClientUpdate.delete(`/invoices/${$router.router.getState().params.invoicesId}`, {
        data: {
          account_id: activeAccountId,
        }
      })
      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('invoices', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // update document
  async function handleSave() {
    try {
      setIsSaving(true)
      const response = await httpClientUpdate.put(`/invoices/${$router.router.getState().params.invoicesId}`, {
        account_id: activeAccountId,
        name: newInvoiceData.name,
        status: newInvoiceData.status_id,
        total: newInvoiceData.total
      })
      if (response.data.success) {
        setIsSaving(false)
        setEditing(false)
        loadInfo()
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      const { data: { data: invoiceData, success, error } } = await httpClientUpdate.get('/invoices/' + $router.router.getState().params.invoicesId, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpClientUpdateReport }
      if (success) {
        if (invoiceData.permissions.invoice_show) {
          setInvoiceData(invoiceData)
          setNewInvoiceData({
            ...invoiceData.invoice[0],
            data: [{
              name: '',
              price: 0,
              type: 'part',
            }],
            payments: [{
              date: moment(),
              status: '',
              type: '',
              number: '0',
              total: 0,
            }]
          })
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load job data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setReadyToSave(true), [
    newInvoiceData
  ])

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  function handleChange(value: string | number, name: string, index: number) {
    let updateData = newInvoiceData.data && newInvoiceData.data.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          [name]: value
        }
      } else {
        return { ...item }
      }
    })
    setNewInvoiceData({ ...newInvoiceData, data: updateData })
  }

  function handleChangePayments(value: string | number | Moment, name: string, index: number) {
    let updateData = newInvoiceData.payments && newInvoiceData.payments.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          [name]: value
        }
      } else {
        return { ...item }
      }
    })
    setNewInvoiceData({ ...newInvoiceData, payments: updateData })
  }

  function handleRemoveUnit(index: number) {
    let updateData = newInvoiceData.data && newInvoiceData.data.filter((item, idx) => idx !== index)
    setNewInvoiceData({ ...newInvoiceData, data: updateData })
  }

  function handleAddUnit() {
    let updateData = newInvoiceData.data ? newInvoiceData.data.map(item => item) : []
    updateData.push({
      name: '', price: 0, type: 'part'
    })
    setNewInvoiceData({ ...newInvoiceData, data: updateData })
  }

  function handleAddPayment() {
    let updateData = newInvoiceData.payments ? newInvoiceData.payments.map(item => item) : []
    updateData.push({
      date: moment(),
      status: '',
      type: '',
      number: '0',
      total: 0,
    })
    setNewInvoiceData({ ...newInvoiceData, payments: updateData })
  }

  function handleChangePrice(event: any, index: number) {
    let price = event.target.value
    let numberWithoutDot = price.split('.')
    if (numberWithoutDot[1].toString().length > 2) {
      let newNumber = `${numberWithoutDot[0]}${numberWithoutDot[1].toString()[0]}.${numberWithoutDot[1].toString()[1]}${price[price.length - 1]}`
      let updateData = newInvoiceData.data && newInvoiceData.data.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            price: +newNumber
          }
        } else {
          return { ...item }
        }
      })
      setNewInvoiceData({ ...newInvoiceData, data: updateData })
    }
  }

  function handleChangePriceBackspace(event: any, index: number) {
    if (event.keyCode === 8) {
      event.preventDefault()
      let price = event.target.value
      let numberWithoutDot = `${price}`.split('.')
      if (numberWithoutDot[0].length > 1) {
        let newNumber = `${numberWithoutDot[0].slice(0, -1)}.${numberWithoutDot[0][numberWithoutDot[0].length - 1]}${numberWithoutDot[1][0]}`
        let updateData = newInvoiceData.data && newInvoiceData.data.map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              price: +newNumber
            }
          } else {
            return { ...item }
          }
        })
        setNewInvoiceData({ ...newInvoiceData, data: updateData })
      } else {
        let newNumber = `0.${numberWithoutDot[0][0]}${numberWithoutDot[1][0]}`
        let updateData = newInvoiceData.data && newInvoiceData.data.map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              price: +newNumber
            }
          } else {
            return { ...item }
          }
        })
        setNewInvoiceData({ ...newInvoiceData, data: updateData })
      }
    }
  }

  function handleChangeTotal(event: any, index: number) {
    let price = event.target.value
    let numberWithoutDot = price.split('.')
    if (numberWithoutDot[1].toString().length > 2) {
      let newNumber = `${numberWithoutDot[0]}${numberWithoutDot[1].toString()[0]}.${numberWithoutDot[1].toString()[1]}${price[price.length - 1]}`
      let updateData = newInvoiceData.payments && newInvoiceData.payments.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            total: +newNumber
          }
        } else {
          return { ...item }
        }
      })
      setNewInvoiceData({ ...newInvoiceData, payments: updateData })
    }
  }

  function handleChangeTotalBackspace(event: any, index: number) {
    if (event.keyCode === 8) {
      event.preventDefault()
      let price = event.target.value
      let numberWithoutDot = `${price}`.split('.')
      if (numberWithoutDot[0].length > 1) {
        let newNumber = `${numberWithoutDot[0].slice(0, -1)}.${numberWithoutDot[0][numberWithoutDot[0].length - 1]}${numberWithoutDot[1][0]}`
        let updateData = newInvoiceData.payments && newInvoiceData.payments.map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              total: +newNumber
            }
          } else {
            return { ...item }
          }
        })
        setNewInvoiceData({ ...newInvoiceData, payments: updateData })
      } else {
        let newNumber = `0.${numberWithoutDot[0][0]}${numberWithoutDot[1][0]}`
        let updateData = newInvoiceData.payments && newInvoiceData.payments.map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              total: +newNumber
            }
          } else {
            return { ...item }
          }
        })
        setNewInvoiceData({ ...newInvoiceData, payments: updateData })
      }
    }
  }

  function handleFocus(event: any) {
    const target = event.currentTarget;

    target.type = 'text';
    target.setSelectionRange(target.value.length, target.value.length);
    target.type = 'number';
    target && target.focus()
  }

  // Render function
  return (<>
    {invoiceData &&
      invoiceData.permissions.invoice_show &&
      newInvoiceData.status ? (
      <div className="JobsPage_Item entity-edit">
        <div className="wrapper flex-container sb">
          <h1>Invoice</h1>

          <div style={{ display: 'flex' }} >
            <button
              disabled={editing}
              style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
              className="_wa"
              onClick={() =>
                $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'invoices',
                  {
                    companyId: activeAccountId,
                    [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
            >
              <Icon
                style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                viewBox="0 0 24 24"
                icon="arrow-25"
              />
            </button>

            {
              !editing &&
                invoiceData?.permissions.invoice_edit ? (
                <button className="_wa _green" onClick={() => setEditing(true)}>
                  Edit
                </button>
              ) : null
            }
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Main</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Name:</span>
                <input
                  type="text"
                  value={newInvoiceData.name}
                  disabled={true}
                />
              </div>

              <div className="field">
                <span>Status:</span>
                <Select
                  disabled={!editing}
                  options={invoiceData.edit.status.map((option) => ({
                    span: option.name,
                    value: option.id
                  }))}
                  selectedOption={newInvoiceData.status_id as number}
                  onChange={(value) => setNewInvoiceData({ ...newInvoiceData, status_id: value as number })}
                />
              </div>
            </div>

            <div className="__right">
              <div className="field">
                <span>Service Resource:</span>
                <input
                  type="text"
                  defaultValue={invoiceData?.invoice[0].service_resource_code ? `${invoiceData?.invoice[0].service_resource} (${invoiceData?.invoice[0].service_resource_code})` : invoiceData?.invoice[0].service_resource}
                  disabled={true}
                />
              </div>

              <div className="field">
                <span>Area:</span>
                <input
                  type="text"
                  defaultValue={invoiceData?.invoice[0].area || invoiceData?.invoice[0].area_id}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Financial</div>

          {/* Estimate table */}
          <div className="fields">
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Price</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {
                  newInvoiceData.data && newInvoiceData.data.map((item, index) => (
                    <tr key={index}>
                      <td style={{ width: '55%' }}>
                        <input
                          type="text"
                          value={item.name || ''}
                          onChange={({ target: { value } }) => handleChange(value, 'name', index)}
                          disabled={!editing}
                        />
                      </td>

                      <td style={{ width: '20%' }}>
                        <Select
                          disabled={!editing}
                          options={['part', 'labor'].map((option) => ({
                            span: option,
                            value: option
                          }))}
                          selectedOption={item.type as string}
                          onChange={(value) => handleChange(value as string, 'type', index)}
                        />
                      </td>

                      <td style={{ width: '20%' }}>
                        <input
                          style={{ textAlign: 'right' }}
                          type="number"
                          disabled={!editing}
                          value={item.price.toFixed(2)}
                          onFocus={(event) => handleFocus(event)}
                          onChange={(event) => handleChangePrice(event, index)}
                          onKeyDown={(event) => handleChangePriceBackspace(event, index)}
                        />
                      </td>

                      <td style={{ width: '5%' }}>
                        {
                          editing &&
                          <button
                            onClick={() => handleRemoveUnit(index)}
                            style={{ background: 'inherit', width: 'fit-content', padding: '0', margin: '0 auto' }}
                          >
                            <Icon
                              style={{ fill: '#FF0000', width: '15px', height: '15px' }}
                              icon="x-mark-1"
                            />
                          </button>
                        }
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>

          <div className="field">
            {
              editing &&
              <div style={{ marginTop: '10px' }} className='add-button'>
                <button
                  className='_green'
                  onClick={() => handleAddUnit()}
                >Add</button>
              </div>
            }
          </div>

          {/* Payments table */}
          <div className="fields">
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Type</th>
                  <th>Number</th>
                  <th>Total</th>
                </tr>
              </thead>

              <tbody>
                {
                  newInvoiceData.payments && newInvoiceData.payments.map((item, index) => (
                    <tr key={index}>
                      <td style={{ width: 'calc(100% / 5)' }}>
                        <Datetime
                          inputProps={{ disabled: !editing }}
                          value={item.date}
                          onChange={(value) => handleChangePayments(value, 'date', index)} />
                      </td>

                      <td style={{ width: 'calc(100% / 5)' }}>
                        <Select
                          disabled={!editing}
                          options={['Pending', 'Complete'].map((option) => ({
                            span: option,
                            value: option
                          }))}
                          selectedOption={item.status as string}
                          onChange={(value) => handleChangePayments(value as string, 'status', index)}
                        />
                      </td>

                      <td style={{ width: 'calc(100% / 5)' }}>
                        <Select
                          disabled={!editing}
                          options={['Card', 'Cash', 'Check'].map((option) => ({
                            span: option,
                            value: option
                          }))}
                          selectedOption={item.type as string}
                          onChange={(value) => handleChangePayments(value as string, 'type', index)}
                        />
                      </td>

                      <td style={{ width: 'calc(100% / 5)' }}>
                        <input
                          type="number"
                          value={item.number || ''}
                          onChange={({ target: { value } }) => handleChangePayments(value, 'number', index)}
                          disabled={!editing}
                        />
                      </td>

                      <td style={{ width: 'calc(100% / 5)' }}>
                        <input
                          style={{ textAlign: 'right' }}
                          type="number"
                          disabled={!editing}
                          value={item.total.toFixed(2)}
                          onFocus={(event) => handleFocus(event)}
                          onChange={(event) => handleChangeTotal(event, index)}
                          onKeyDown={(event) => handleChangeTotalBackspace(event, index)}
                        />
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>

          <div className="field">
            <div style={{ marginTop: '10px' }} className='add-button'>
              <button
                className='_green'
                onClick={() => handleAddPayment()}
              >Add</button>
            </div>
          </div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Total:</span>
                <input
                  type="text"
                  defaultValue={invoiceData?.invoice[0].total}
                  disabled={true}
                />
              </div>

              <div className="field">
                <span>Unpaid:</span>
                <input
                  type="text"
                  defaultValue={invoiceData?.invoice[0].unpaid}
                  disabled={true}
                />
              </div>
            </div>

            <div className="__right">
              <div className="field">
                <span>Paid:</span>
                <input
                  type="text"
                  defaultValue={invoiceData?.invoice[0].paid}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Info</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Date Created:</span>
                <input
                  type="text"
                  defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, invoiceData?.invoice[0].created_at)}
                  disabled={true}
                />
              </div>

              <div className="field">
                <span>Created by:</span>
                <input
                  type="text"
                  defaultValue={invoiceData?.invoice[0].created_by}
                  disabled={true}
                />
              </div>
            </div>

            <div className="__right">
              <div className="field">
                <span>Last Edited:</span>
                <input
                  type="text"
                  defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, invoiceData?.invoice[0].updated_at)}
                  disabled={true}
                />
              </div>
              <div className="field">
                <span>Last Edited by:</span>
                <input
                  type="text"
                  defaultValue={invoiceData?.invoice[0].updated_by}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Clients</div>

          <table
            className={classNames('table', '__show-on-wide', {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            })}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Main Phone</th>
                <th>Source</th>
                <th>Jobs</th>
                <th>Appointments</th>
                <th>Recalls</th>
                <th>Total</th>
                <th>Paid</th>
                <th>Unpaid</th>
              </tr>
            </thead>

            {invoiceData.clients && invoiceData.clients.map((client, i) => (
              <tr
                key={i}
                style={{ cursor: invoiceData.permissions.client_show && client.client_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  invoiceData.permissions.client_show &&
                  client.client_id &&
                  $router.router.navigate('clients.item', {
                    companyId: activeAccountId,
                    clientId: client.client_id,
                    fromPage: 'invoices.item',
                    nameId: 'invoicesId',
                    id: $router.router.getState().params.invoicesId,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true, })}
                onContextMenu={(event) =>
                  invoiceData.permissions.client_show &&
                  client.client_id &&
                  handleChangeMenu(event, client.client_id, 'clients', i)}
              >
                <td>{client.name}</td>
                <td>{formatPhoneNumber(client.main_phone)}</td>
                <td>{client.source}</td>
                <td>{client.jobs}</td>
                <td>{client.appointments}</td>
                <td>{client.recalls}</td>
                <td>{client.total}</td>
                <td>{client.paid}</td>
                <td>
                  {client.unpaid}
                  {
                    invoiceData.permissions.client_show &&
                    showContext === `clients_${client.client_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('clients.item', {
                            companyId: activeAccountId,
                            clientId: client.client_id,
                            fromPage: 'invoices.item',
                            nameId: 'invoicesId',
                            id: $router.router.getState().params.invoicesId,
                            localInterface: $router.router.getState().params.localInterface
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/clients/${client.client_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          <table
            className={classNames('table', '__hide-on-wide', '__hide-on-mobile', {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            })}>
            <tr></tr>
            {invoiceData.clients && invoiceData.clients.map((client, i) => (
              <tr
                key={i}
                style={{ cursor: invoiceData.permissions.client_show && client.client_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  invoiceData.permissions.client_show &&
                  client.client_id &&
                  $router.router.navigate('clients.item', {
                    companyId: activeAccountId,
                    clientId: client.client_id,
                    fromPage: 'invoices.item',
                    nameId: 'invoicesId',
                    id: $router.router.getState().params.invoicesId,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
                onContextMenu={(event) =>
                  invoiceData.permissions.client_show &&
                  client.client_id &&
                  handleChangeMenu(event, client.client_id, 'clients', i)}
              >
                <td>
                  <div>{client.name}</div>
                  <div>{formatPhoneNumber(client.main_phone)}</div>
                </td>
                <td>
                  <div>{client.source}</div>
                  <div>Jobs: {client.jobs}</div>
                </td>
                <td>
                  <div>Appointments: {client.appointments}</div>
                  <div>Recalls: {client.recalls}</div>
                </td>
                <td>
                  <div>Total: {client.total}</div>
                  <div>Paid: {client.paid}</div>
                </td>
                <td>
                  <div>Unpaid: {client.unpaid}
                    {
                      invoiceData.permissions.client_show &&
                      showContext === `clients_${client.client_id}_${i}` &&
                      <div
                        className="redirect-menu"
                        ref={contextMenuRef}
                        style={{ left: `${screenX}px` }}
                      >
                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            $router.router.navigate('clients.item', {
                              companyId: activeAccountId,
                              clientId: client.client_id,
                              fromPage: 'invoices.item',
                              nameId: 'invoicesId',
                              id: $router.router.getState().params.invoicesId,
                              localInterface: $router.router.getState().params.localInterface
                            }, { reload: true, })
                          }
                          }
                        >
                          Open
                        </button>

                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            window.open(`${window.location.origin}/${activeAccountId}/clients/${client.client_id}`, "_blank", 'noopener,noreferrer')
                            setShowContext('')
                          }}
                        >
                          Open in new tab
                        </button>
                      </div>
                    }
                  </div>
                  <div>&nbsp;</div>
                </td>
              </tr>
            ))}
          </table>

          <div className={classNames('mobile-table', '__show-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>

            <div className="mobile-table-items">

              {invoiceData.clients && invoiceData.clients.map((client, i: number) => (
                <div
                  className="item"
                  key={i}
                  onClick={() =>
                    invoiceData.permissions.client_show &&
                    client.client_id &&
                    $router.router.navigate('clients.item', {
                      companyId: activeAccountId,
                      clientId: client.client_id,
                      fromPage: 'invoices.item',
                      nameId: 'invoicesId',
                      id: $router.router.getState().params.invoicesId,
                      localInterface: $router.router.getState().params.localInterface
                    }, { reload: true })}
                >
                  <div className="__top">
                    <div className="__left">
                      <div><b>{client.name}</b></div>
                      <div>
                        {formatPhoneNumber(client.main_phone)}
                      </div>
                    </div>

                    <div className="__right">
                      <div>
                        Total: <b>{client.total}</b>
                      </div>
                      <div>
                        Paid: <b>{client.paid}</b>
                      </div>
                      <div>
                        Unpaid: <b>{client.unpaid}</b>
                      </div>
                    </div>
                  </div>

                  <div className="__bottom">
                    <div className="__left">
                      <div>
                        <span className="gray">Jobs:</span>
                        <span> {client.jobs}</span>
                      </div>
                      <div>
                        <span className="gray">Recalls:</span>
                        <span> {client.recalls}</span>
                      </div>
                      <div>
                        <span className="gray">Appointments:</span>
                        <span> {client.appointments}</span>
                      </div>
                    </div>

                    <div className="__right small">
                      <div>
                        <b>{client.source}</b>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Jobs</div>

          <table
            className={classNames('table', '__show-on-wide', {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            })}>
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Status</th>
                <th>Property type</th>
                <th>Area</th>
                <th>Source</th>
                <th>Created by</th>
                <th>Appointments</th>
                <th>Service resource</th>
                <th>Total</th>
                <th>Paid</th>
              </tr>
            </thead>

            {invoiceData.jobs && invoiceData.jobs.map((job, i) => (
              <tr
                key={i}
                style={{ cursor: invoiceData.permissions.job_show && job.job_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  invoiceData.permissions.job_show &&
                  job.job_id &&
                  $router.router.navigate('jobs.item', {
                    companyId: activeAccountId,
                    jobsId: job.job_id,
                    fromPage: 'invoices.item',
                    nameId: 'invoicesId',
                    id: $router.router.getState().params.invoicesId,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
                onContextMenu={(event) =>
                  invoiceData.permissions.job_show &&
                  job.job_id &&
                  handleChangeMenu(event, job.job_id, 'jobs', i)}
              >
                <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, job.created_at)}</td>
                <td>{job.name}</td>
                <td>{job.status}</td>
                <td>{job.property_type}</td>
                <td>{job.area}</td>
                <td>{job.source}</td>
                <td>{job.created_by}</td>
                <td>{job.appointments}</td>
                <td>{job.service_resource_code ? `${job.service_resource} (${job.service_resource_code})` : job.service_resource}</td>
                <td>{job.total}</td>
                <td>
                  {job.paid}
                  {
                    invoiceData.permissions.job_show &&
                    showContext === `jobs_${job.job_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('jobs.item', {
                            companyId: activeAccountId,
                            jobsId: job.job_id,
                            fromPage: 'invoices.item',
                            nameId: 'invoicesId',
                            id: $router.router.getState().params.invoicesId,
                            localInterface: $router.router.getState().params.localInterface
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/jobs/${job.job_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          {/* Medum screen table */}
          <table
            className={classNames('table', '__hide-on-wide', '__hide-on-mobile', {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            })}>
            <tr></tr>
            {invoiceData.jobs && invoiceData.jobs.map((job, i) => (
              <tr
                key={i}
                style={{ cursor: invoiceData.permissions.job_show && job.job_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  invoiceData.permissions.job_show &&
                  job.job_id &&
                  $router.router.navigate('jobs.item', {
                    companyId: activeAccountId,
                    jobId: job.job_id,
                    fromPage: 'invoices.item',
                    nameId: 'invoicesId',
                    id: $router.router.getState().params.invoicesId,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
                onContextMenu={(event) =>
                  invoiceData.permissions.job_show &&
                  job.job_id &&
                  handleChangeMenu(event, job.job_id, 'jobs', i)}
              >
                <td>
                  <div>{job.name}</div>
                  <div>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, job.created_at)}</div>
                </td>
                <td>
                  <div>{job.created_by}</div>
                  <div>{job.service_resource_code ? `${job.service_resource} (${job.service_resource_code})` : job.service_resource}</div>
                </td>
                <td>
                  <div>{job.area}</div>
                  <div>{job.source}</div>
                </td>
                <td>
                  <div>{job.property_type}</div>
                  <div>{job.status}</div>
                </td>
                <td>
                  <div>Appointments: {job.appointments}</div>
                  <div>&nbsp;</div>
                </td>
                <td>
                  <div>Total: {job.total}</div>
                  <div>Paid: {job.paid}</div>
                  {
                    invoiceData.permissions.job_show &&
                    showContext === `jobs_${job.job_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('jobs.item', {
                            companyId: activeAccountId,
                            jobId: job.job_id,
                            fromPage: 'invoices.item',
                            nameId: 'invoicesId',
                            id: $router.router.getState().params.invoicesId,
                            localInterface: $router.router.getState().params.localInterface
                          }, { reload: true, })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/jobs/${job.job_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          {/* Mobile table items */}
          <div className={classNames('mobile-table-items', '__show-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>

            {invoiceData.jobs && invoiceData.jobs.map((job, i: number) => (
              <div
                className="item"
                key={i}
                onClick={() =>
                  invoiceData.permissions.job_show &&
                  job.job_id &&
                  $router.router.navigate('jobs.item', {
                    companyId: activeAccountId,
                    jobId: job.job_id,
                    fromPage: 'invoices.item',
                    nameId: 'invoicesId',
                    id: $router.router.getState().params.invoicesId,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
              >

                <div className="__top">
                  <div className="__left">
                    <div>
                      <strong>{job.name}</strong>
                    </div>
                    <div>
                      Appointments: {job.appointments}
                    </div>
                  </div>

                  <div className="__right">
                    <div>
                      Paid: <b>{job.paid}</b>
                    </div>
                    <div>
                      Total: <b>{job.total}</b>
                    </div>
                    <div>
                      {job.property_type}
                    </div>
                    <div>
                      <b>{job.status}</b>
                    </div>
                  </div>
                </div>

                <div className="__bottom">
                  <div className="__left">
                    <div>
                      <span className="gray">Area:</span>
                      <span> {job.area}</span>
                    </div>
                    <div>
                      <span className="gray">Source:</span>
                      <span> {job.source}</span>
                    </div>
                    <div>
                      <span className="gray">Service Resourse:</span>
                      <span> {job.service_resource_code ? `${job.service_resource} (${job.service_resource_code})` : job.service_resource}</span>
                    </div>
                  </div>

                  <div className="__right small">
                    <div>
                      {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, job.created_at)}
                    </div>
                    <div>
                      Created by {job.created_by}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Appointments</div>

          <table className={classNames('table', '__show-on-wide', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Type</th>
                <th>Status</th>
                <th>Property type</th>
                <th>Area</th>
                <th>Created by</th>
                <th>Schedule time</th>
                <th>Service resource</th>
              </tr>
            </thead>

            {invoiceData.appointments && invoiceData.appointments.map((appointment, i) => (
              <tr
                key={i}
                style={{ cursor: invoiceData.permissions.appointment_show && appointment.appointment_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  invoiceData.permissions.appointment_show &&
                  appointment.appointment_id &&
                  $router.router.navigate('appointments.item', {
                    companyId: activeAccountId,
                    appointmentId: appointment.appointment_id,
                    fromPage: 'invoices.item',
                    nameId: 'invoicesId',
                    id: $router.router.getState().params.invoicesId,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
                onContextMenu={(event) =>
                  invoiceData.permissions.appointment_show &&
                  appointment.appointment_id &&
                  handleChangeMenu(event, appointment.appointment_id, 'appointments', i)}
              >
                <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, appointment.created_at)}</td>
                <td>{appointment.name}</td>
                <td>
                  <span className={classNames({
                    red: ['Recall', 'RC'].includes(appointment.type),
                    green: ['Service call', 'Service Call', 'SC'].includes(appointment.type),
                    blue: ['Follow up', 'Follow Up', 'FU'].includes(appointment.type),
                  })}
                  >
                    {appointment.type}
                  </span>
                </td>
                <td>{appointment.status}</td>
                <td>{appointment.property_type}</td>
                <td>{appointment.area}</td>
                <td>{appointment.created_by}</td>
                <td>{getSchuduleTime(appointment.schedule_time_start as string, appointment.schedule_time_finish as string, appointment.time_zone)}</td>
                <td>
                  {appointment.service_resource_code ? `${appointment.service_resource} (${appointment.service_resource_code})` : appointment.service_resource}
                  {
                    invoiceData.permissions.appointment_show &&
                    showContext === `appointments_${appointment.appointment_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('appointments.item', {
                            companyId: activeAccountId,
                            appointmentId: appointment.appointment_id,
                            fromPage: 'invoices.item',
                            nameId: 'invoicesId',
                            id: $router.router.getState().params.invoicesId,
                            localInterface: $router.router.getState().params.localInterface
                          }, { reload: true, })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/appointments/${appointment.appointment_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <tr></tr>
            {invoiceData.appointments && invoiceData.appointments.map((appointment, i) => (
              <tr
                key={i}
                style={{ cursor: invoiceData.permissions.appointment_show && appointment.appointment_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  invoiceData.permissions.appointment_show &&
                  appointment.appointment_id &&
                  $router.router.navigate('appointments.item', {
                    companyId: activeAccountId,
                    appointmentId: appointment.appointment_id,
                    fromPage: 'invoices.item',
                    nameId: 'invoicesId',
                    id: $router.router.getState().params.invoicesId,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
                onContextMenu={(event) =>
                  invoiceData.permissions.appointment_show &&
                  appointment.appointment_id &&
                  handleChangeMenu(event, appointment.appointment_id, 'appointments', i)}
              >
                <td>
                  <div>{appointment.name}</div>
                  <div>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, appointment.created_at)}</div>
                </td>
                <td>
                  <div>{appointment.service_resource_code ? `${appointment.service_resource} (${appointment.service_resource_code})` : appointment.service_resource}</div>
                  <div>{appointment.created_by}</div>
                </td>
                <td>
                  <div>{appointment.area}</div>
                  <div>
                    <span className={classNames({
                      red: ['Recall', 'RC'].includes(appointment.type),
                      green: ['Service call', 'Service Call', 'SC'].includes(appointment.type),
                      blue: ['Follow up', 'Follow Up', 'FU'].includes(appointment.type),
                    })}
                    >
                      {appointment.type}
                    </span>
                  </div>
                </td>
                <td>
                  <div>{appointment.property_type}</div>
                  <div>{appointment.status}</div>
                </td>
                <td>
                  <div>{getSchuduleTime(appointment.schedule_time_start as string, appointment.schedule_time_finish as string, appointment.time_zone)}</div>
                  <div>&nbsp;</div>
                  {
                    invoiceData.permissions.appointment_show &&
                    showContext === `appointments_${appointment.appointment_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('appointments.item', {
                            companyId: activeAccountId,
                            appointmentId: appointment.appointment_id,
                            fromPage: 'invoices.item',
                            nameId: 'invoicesId',
                            id: $router.router.getState().params.invoicesId,
                            localInterface: $router.router.getState().params.localInterface
                          }, { reload: true, })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/appointments/${appointment.appointment_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          <div className={classNames('mobile-table', '__show-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>

            <div className="mobile-table-items">

              {invoiceData.appointments && invoiceData.appointments.map((appointment, i: number) => (
                <div
                  className="item"
                  key={i}
                  onClick={() =>
                    invoiceData.permissions.appointment_show &&
                    appointment.appointment_id &&
                    $router.router.navigate('appointments.item', {
                      companyId: activeAccountId,
                      appointmentId: appointment.appointment_id,
                      fromPage: 'invoices.item',
                      nameId: 'invoicesId',
                      id: $router.router.getState().params.invoicesId,
                      localInterface: $router.router.getState().params.localInterface
                    }, { reload: true })}
                >
                  <div className="__top">
                    <div className="__left">
                      <div className="flex-container">
                        <strong>{appointment.name}</strong>
                      </div>
                      <div>
                        {getSchuduleTime(appointment.schedule_time_start as string, appointment.schedule_time_finish as string, appointment.time_zone)}
                      </div>
                    </div>

                    <div className="__right">
                      <div>
                        <span className={classNames({
                          red: ['Recall', 'RC'].includes(appointment.type),
                          green: ['Service call', 'Service Call', 'SC'].includes(appointment.type),
                          blue: ['Follow up', 'Follow Up', 'FU'].includes(appointment.type),
                        })}
                        >
                          {appointment.type}
                        </span>
                      </div>
                      <div>
                        {appointment.property_type}
                      </div>
                      <div>
                        <div className="fw500">{appointment.status}</div>
                      </div>
                    </div>
                  </div>

                  <div className="__bottom">
                    <div className="__left">
                      <div>
                        <span className="gray">Area:</span>
                        <span> {appointment.area}</span>
                      </div>
                      <div>
                        <span className="gray">Service Resourse:</span>
                        <span> {appointment.service_resource_code ? `${appointment.service_resource} (${appointment.service_resource_code})` : appointment.service_resource}</span>
                      </div>
                    </div>

                    <div className="__right small">
                      <div>
                        {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, appointment.created_at)}
                      </div>
                      <div>
                        Created by {appointment.created_by}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Estimates</div>

          <table className={classNames('table', '__show-on-wide', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <thead>
              <tr>
                <th><span>Date</span></th>
                <th><span>Name</span></th>
                <th><span>Status</span></th>
                <th><span>Service Resource</span></th>
                <th><span>Area</span></th>
                <th><span>Total</span></th>
                <th><span>Comment</span></th>
              </tr>
            </thead>

            {invoiceData.estimates && invoiceData.estimates.map((estimate, i) => (
              <tr
                key={i}
                style={{ cursor: invoiceData.permissions.estimate_show && estimate.estimate_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  invoiceData.permissions.estimate_show &&
                  estimate.estimate_id &&
                  $router.router.navigate('estimates.item', {
                    companyId: activeAccountId,
                    estimatesId: estimate.estimate_id,
                    fromPage: 'invoices.item',
                    nameId: 'invoicesId',
                    id: $router.router.getState().params.invoicesId,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
                onContextMenu={(event) =>
                  invoiceData.permissions.estimate_show &&
                  estimate.estimate_id &&
                  handleChangeMenu(event, estimate.estimate_id, 'estimates', i)}
              >
                <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, estimate.created_at)}</td>
                <td>{estimate.name}</td>
                <td>{estimate.status}</td>
                <td>{estimate.service_resource_code ? `${estimate.service_resource} (${estimate.service_resource_code})` : estimate.service_resource}</td>
                <td>{estimate.area}</td>
                <td>{estimate.total}</td>
                <td>
                  {estimate.comment}
                  {
                    invoiceData.permissions.estimate_show &&
                    showContext === `estimates_${estimate.estimate_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('estimates.item', {
                            companyId: activeAccountId,
                            estimatesId: estimate.estimate_id,
                            fromPage: 'invoices.item',
                            nameId: 'invoicesId',
                            id: $router.router.getState().params.invoicesId,
                            localInterface: $router.router.getState().params.localInterface
                          }, { reload: true, })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/estimates/${estimate.estimate_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>

            <tr></tr>

            {invoiceData.estimates && invoiceData.estimates.map((estimate, i) => (
              <tr
                key={i}
                style={{ cursor: invoiceData.permissions.estimate_show && estimate.estimate_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  invoiceData.permissions.estimate_show &&
                  estimate.estimate_id &&
                  $router.router.navigate('estimates.item', {
                    companyId: activeAccountId,
                    estimatesId: estimate.estimate_id,
                    fromPage: 'invoices.item',
                    nameId: 'invoicesId',
                    id: $router.router.getState().params.invoicesId,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
                onContextMenu={(event) =>
                  invoiceData.permissions.estimate_show &&
                  estimate.estimate_id &&
                  handleChangeMenu(event, estimate.estimate_id, 'estimates', i)}
              >
                <td>
                  <div>{estimate.name}</div>
                  <div>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, estimate.created_at)}</div>
                </td>
                <td>
                  <div>{estimate.status}</div>
                  <div>{estimate.service_resource_code ? `${estimate.service_resource} (${estimate.service_resource_code})` : estimate.service_resource}</div>
                </td>
                <td>
                  <div>{estimate.area}</div>
                </td>
                <td>
                  <div>{estimate.total}</div>
                </td>
                <td>
                  <div>{estimate.comment}</div>
                  <div>&nbsp;</div>
                  {
                    invoiceData.permissions.estimate_show &&
                    showContext === `estimates_${estimate.estimate_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('estimates.item', {
                            companyId: activeAccountId,
                            estimatesId: estimate.estimate_id,
                            fromPage: 'invoices.item',
                            nameId: 'invoicesId',
                            id: $router.router.getState().params.invoicesId,
                            localInterface: $router.router.getState().params.localInterface
                          }, { reload: true, })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/estimates/${estimate.estimate_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          <div className={classNames('mobile-table', '__show-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>

            <div className="mobile-table-items">
              {invoiceData.estimates && invoiceData.estimates.map((estimate, i: number) => (
                <div
                  key={i}
                  className="item"
                  onClick={() =>
                    invoiceData.permissions.estimate_show &&
                    estimate.estimate_id &&
                    $router.router.navigate('estimates.item', {
                      companyId: activeAccountId,
                      estimatesId: estimate.estimate_id,
                      fromPage: 'invoices.item',
                      nameId: 'invoicesId',
                      id: $router.router.getState().params.invoicesId,
                      localInterface: $router.router.getState().params.localInterface
                    }, { reload: true })}
                >
                  <div className="__top">
                    <div className="__left">
                      <div>
                        <strong>{estimate.name}</strong>
                      </div>
                      <div>
                        <strong>{estimate.status}</strong>
                      </div>
                    </div>

                    <div className="__right">
                      <div>
                        Total: <b>{estimate.total}</b>
                      </div>
                    </div>
                  </div>

                  <div className="__bottom">
                    <div className="__left">
                      <div>
                        <span className="gray">Area:</span>
                        <span> {estimate.area}</span>
                      </div>
                      <div>
                        <span className="gray">Service Resourse:</span>
                        <span> {estimate.service_resource_code ? `${estimate.service_resource} (${estimate.service_resource_code})` : estimate.service_resource}</span>
                      </div>
                    </div>

                    <div className="__right small">
                      <div>
                        <b>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, estimate.created_at)}</b>
                      </div>
                    </div>
                  </div>

                  <div className="text">
                    {estimate.comment}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Payments</div>

          <table className={classNames('table', '__show-on-wide', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <thead>
              <tr>
                <th><span>Date</span></th>
                <th><span>Type</span></th>
                <th><span>Number</span></th>
                <th><span>Service</span></th>
                <th><span>Status</span></th>
                <th><span>Net</span></th>
                <th><span>Total</span></th>
                <th><span>Fee</span></th>
              </tr>
            </thead>

            {invoiceData.payments && invoiceData.payments.map((payment, i) => (
              <tr
                key={i}
                style={{ cursor: invoiceData.permissions.payment_show && payment.payment_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  invoiceData.permissions.payment_show &&
                  payment.payment_id &&
                  $router.router.navigate('payments.item', {
                    companyId: activeAccountId,
                    paymentsId: payment.payment_id,
                    fromPage: 'invoices.item',
                    nameId: 'invoicesId',
                    id: $router.router.getState().params.invoicesId,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
                onContextMenu={(event) =>
                  invoiceData.permissions.payment_show &&
                  payment.payment_id &&
                  handleChangeMenu(event, payment.payment_id, 'payments', i)}
              >
                <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, payment.created_at)}</td>
                <td>{payment.type}</td>
                <td>{payment.number}</td>
                <td>{payment.service}</td>
                <td>{payment.status}</td>
                <td>{payment.net}</td>
                <td>{payment.total}</td>
                <td>
                  {payment.fee}
                  {
                    invoiceData.permissions.payment_show &&
                    showContext === `payments_${payment.payment_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('payments.item', {
                            companyId: activeAccountId,
                            paymentsId: payment.payment_id,
                            fromPage: 'invoices.item',
                            nameId: 'invoicesId',
                            id: $router.router.getState().params.invoicesId,
                            localInterface: $router.router.getState().params.localInterface
                          }, { reload: true, })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/payments/${payment.payment_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>

            <tr></tr>

            {invoiceData.payments && invoiceData.payments.map((payment, i) => (
              <tr
                key={i}
                style={{ cursor: invoiceData.permissions.payment_show && payment.payment_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  invoiceData.permissions.payment_show &&
                  payment.payment_id &&
                  $router.router.navigate('payments.item', {
                    companyId: activeAccountId,
                    paymentsId: payment.payment_id,
                    fromPage: 'invoices.item',
                    nameId: 'invoicesId',
                    id: $router.router.getState().params.invoicesId,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
                onContextMenu={(event) =>
                  invoiceData.permissions.payment_show &&
                  payment.payment_id &&
                  handleChangeMenu(event, payment.payment_id, 'payments', i)}
              >
                <td>
                  <div>{payment.type}</div>
                  <div>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, payment.created_at)}</div>
                </td>
                <td>
                  <div>{payment.number}</div>
                  <div>{payment.service}</div>
                </td>
                <td>
                  <div>{payment.status}</div>
                </td>
                <td>
                  <div>{payment.net}</div>
                  <div>{payment.fee}</div>
                </td>
                <td>
                  <div>{payment.total}</div>
                  <div>&nbsp;</div>
                  {
                    invoiceData.permissions.payment_show &&
                    showContext === `payments_${payment.payment_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('payments.item', {
                            companyId: activeAccountId,
                            paymentsId: payment.payment_id,
                            fromPage: 'invoices.item',
                            nameId: 'invoicesId',
                            id: $router.router.getState().params.invoicesId,
                            localInterface: $router.router.getState().params.localInterface
                          }, { reload: true, })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/payments/${payment.payment_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          <div className={classNames('mobile-table', '__show-on-mobile', {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          })}>
            <div className="mobile-table-items">
              {invoiceData.payments && invoiceData.payments.map((payment, i: number) => (
                <div
                  key={i}
                  className="item"
                  onClick={() =>
                    invoiceData.permissions.payment_show &&
                    payment.payment_id &&
                    $router.router.navigate('payments.item', {
                      companyId: activeAccountId,
                      paymentsId: payment.payment_id,
                      fromPage: 'invoices.item',
                      nameId: 'invoicesId',
                      id: $router.router.getState().params.invoicesId,
                      localInterface: $router.router.getState().params.localInterface
                    }, { reload: true })}
                >
                  <div className="__top">
                    <div className="__left">
                      <div><strong>{payment.number}</strong></div>
                      <div>{payment.service}</div>
                      <div>
                        <b>{payment.status}</b>
                      </div>
                    </div>

                    <div className="__right">
                      <div>
                        Net: <b>{payment.net}</b>
                      </div>
                      <div>
                        Total: <b>{payment.total}</b>
                      </div>
                      <div>
                        Fee: <b>{payment.fee}</b>
                      </div>
                    </div>
                  </div>

                  <div className="__bottom">
                    <div className="__left">
                    </div>

                    <div className="__right small">
                      <div>
                        <b>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, payment.created_at)}</b>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {editing ? (
          <div className="wrapper flex-container sb editing-buttons">
            <div>
              {
                invoiceData.permissions.invoice_delete &&
                <button className="_red" onClick={() => setDeleting(true)}>Delete Invoice</button>
              }
            </div>
            <div className="buttons">
              <button
                className="_bordered _red"
                onClick={() => handleCancelEdit()}
              >
                Cancel
              </button>
              <button
                disabled={!readyToSave || isSaving}
                className="_bordered _green"
                onClick={() => handleSave()}
              >
                Save
              </button>
            </div>
          </div>
        ) : null}

        {/* Item share popup */}
        {invoiceData.permissions.invoice_delete && deleting ? (
          <div className="item-delete-popup" onClick={() => setDeleting(false)}>
            <div className="wrapper" onClick={(e) => e.stopPropagation()}>
              <div className="title">
                Delete Invoice
              </div>

              <div className="checkbox-wrapper">
                <Checkbox contents="I understand that after deleting the absence it will not be possible to recover." value={readyToDelete} onChange={(value) => setReadyToDelete(value)} />
              </div>

              <div className="buttons">
                <button className="_bordered _green" onClick={() => setDeleting(false)}>
                  Cancel
                </button>

                <button
                  disabled={!readyToDelete || isSaving}
                  className="_bordered _red"
                  onClick={() => handleRemoveItem()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    ) : null
    }
  </>)
}

export default InvoicePage_Item
