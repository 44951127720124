import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';

import RobotoRegular from '../../fonts/Roboto.ttf'
import RobotoBold from '../../fonts/Roboto-Bold.ttf'
import RobotoItalic from '../../fonts/Roboto-Italic.ttf'
import RobotoBoldItalic from '../../fonts/Roboto-BoldItalic.ttf'

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RobotoRegular,
      fontWeight: 400,
    },
    {
      src: RobotoBold,
      fontWeight: 700,
    },
    {
      src: RobotoItalic,
      fontStyle: 'italic',
    },
    {
      src: RobotoBoldItalic,
      fontWeight: 700,
      fontStyle: 'italic'
    },
  ]
})

const styles = StyleSheet.create({
  page: {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    padding: '10px',
    fontFamily: 'Roboto',
  },
  block: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: '22px',
    padding: '7px 12px'
  },
  image_block: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  }
});

interface ApproveDocumentProps {
  approveText: string
  sign: string
}

export default function ApproveDocument({ approveText, sign }: ApproveDocumentProps) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.block}>
          <Text style={styles.title}> Approve for Job </Text>
        </View>

        <View>
          {
            approveText.split('\n').map((item, index) => (
              <Text key={index}> {item} </Text>
            ))
          }
        </View>

        <View style={styles.image_block}>
          <Image
            style={{
              width: '200px',
              height: '100px',
              margin: '20px 50px',
              borderBottom: '1px solid #000'
            }}
            src={sign}
          ></Image>
        </View>
      </Page>
    </Document>
  )
}
