import React, { useState, useEffect } from "react";
import { BaseLink, useRoute } from "react-router5"
import qs from "qs";
import classNames from "classnames"

import Icon from "../../components/Icon"
import InputTextWithTitle from "../../components/InputTextWithTitle";
import TextAreaWithTitle from "../../components/TextAreaWithTitle";

import { useAppSelector } from "../../store/hooks"
import { httpClientUpdate, nErrorUpdate } from "../../funcs";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/lists.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: ListTickets
}

interface PermissionsProps {
  list_ticket_template_add: boolean
  list_ticket_template_delete: boolean
  list_ticket_template_edit: boolean
  list_ticket_template_show: boolean
}

interface TemplateItem {
  template: string
  ticket_template_id: string
  responsibles: string
  type: string
  editing: boolean
}

interface ListTickets {
  ticket_templates: TemplateItem[]
  permissions: PermissionsProps
}

function ListsPage_TicketTemplates() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const accessToken = useAppSelector((store) => store.accessToken)

  const [isSaving, setIsSaving] = useState(false)
  const [errorAdd, setErrorAdd] = useState(false)
  const [errorEdit, setErrorEdit] = useState(false)

  const [newItemForm, setNewItemForm] = useState({
    type: '',
    template: '',
  })

  const [editItemForm, setEditItemForm] = useState({
    type: '',
    responsibles: '',
    template: '',
  })

  const [items, setItems] = useState<TemplateItem[] | []>([])
  const [permissions, setPermissions] = useState({
    list_ticket_template_add: true,
    list_ticket_template_delete: true,
    list_ticket_template_edit: true,
    list_ticket_template_show: true,
  })

  // Load Tickets function
  async function loadTickets() {

    httpClientUpdate.defaults.headers['Authorization'] = `Bearer ${accessToken}`
    // https://2022back4.artemiudintsev.com/api/lists/ticket-templates?account_id=88888
    try {
      const { data: { data: ticketsData, success, error } } = (await httpClientUpdate.get(`/lists/ticket-templates`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        },
        params: {
          account_id: activeAccountId
        }
      })) as { data: HttpClientUpdateReport }
      if (success) {
        setPermissions(ticketsData.permissions)
        setItems(ticketsData.ticket_templates.map(item => {
          return { ...item, editing: false }
        }))
        errorEdit && setErrorEdit(false)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    } catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // edit function
  async function editTicket(id: string, type: string, template: string, responsibles: string) {
    setIsSaving(true)
    try {
      // https://2022back4.artemiudintsev.com/api/lists/ticket-templates/88888083tlyn2mafh7
      const response = await httpClientUpdate.put(`/lists/ticket-templates/${id}`, qs.stringify({
        account_id: activeAccountId,
        type,
        template,
        responsibles
      }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        }
      })
      if (response.data.success) {
        setIsSaving(false)
        return true
      } else {
        setIsSaving(false)
        return false
      }
    } catch (error: Error | AxiosError | unknown) {
      setIsSaving(false)
      let createdError = nErrorUpdate(error)
      if (createdError.content.errorText === "This name is already taken") {
        setErrorEdit(true)
      }
    }
  }

  // add function
  async function addTemplate(type: string, template: string) {
    setIsSaving(true)
    // https://2022back4.artemiudintsev.com/api/lists/ticket-templates
    try {
      const response = await httpClientUpdate.post(`/lists/ticket-templates`, qs.stringify({
        account_id: activeAccountId,
        type: type,
        template: template,
      }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        }
      })
      if (response.data.success) {
        setIsSaving(false)
        setNewItemForm({
          ...newItemForm,
          type: '',
          template: '',
        })
        return loadTickets()
      } else {
        setIsSaving(false)
      }
    } catch (error: Error | AxiosError | unknown) {
      setIsSaving(false)
      let createdError = nErrorUpdate(error)
      if (createdError.content.errorText === "This name is already taken") {
        setErrorAdd(true)
      }
    }
  }

  // remove function
  async function removeTemplate(id: string) {
    setIsSaving(true)
    try {
      // https://2022back4.artemiudintsev.com/api/lists/ticket-templates/88888083tlyn2mafh7?account_id=88888
      const response = await httpClientUpdate.delete(`/lists/ticket-templates/${id}`, {
        data: {
          account_id: activeAccountId,
        }
      })

      if (response.data.success) {
        setIsSaving(false)
        return loadTickets()
      } else {
        setIsSaving(false)
      }
    } catch (error: Error | AxiosError | unknown) {
      setIsSaving(false)
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  useEffect(() => {
    loadTickets()

    // unmount page
    return () => {
      setItems([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAccountId])

  useEffect(() => {
    if (errorAdd && newItemForm.template) {
      setErrorAdd(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newItemForm.template])

  useEffect(() => {
    if (errorEdit && editItemForm.template) {
      setErrorEdit(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editItemForm.template])

  // Add item function
  function addItem() {

    let { type, template } = newItemForm

    addTemplate(type, template)
  }

  // Set editing function
  function setEditing(i: number, flag: boolean) {

    let _items = [...items]
    _items = _items.map((item) => ({ ...item, editing: false }))
    _items[i].editing = flag
    setItems(_items)

    setEditItemForm({
      ...editItemForm,
      type: flag ? _items[i].type : '',
      template: flag ? _items[i].template : '',
      responsibles: flag ? _items[i].responsibles : '',
    })
  }

  // Set item function
  async function setItem(i: number) {

    let _items = [...items]
    _items[i] = {
      type: editItemForm.type,
      template: editItemForm.template,
      responsibles: editItemForm.responsibles,
      editing: false,
      ticket_template_id: _items[i].ticket_template_id
    }

    let update = await editTicket(_items[i].ticket_template_id, _items[i].type, _items[i].template, _items[i].responsibles)
    update && setItems(_items)
  }

  // Render function
  return (
    <div className="ListsPage_Areas">

      {/* Top navigation */}
      <div className="top-nav top-nav-list">
        <div className="top-nav-list-item">
          <BaseLink
            router={$router.router}
            routeName="lists.appliances"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.appliances" })}
          >
            <Icon icon="task-1" />
            <span>Appliances</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.brands"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.brands" })}
          >
            <Icon icon="task-1" />
            <span>Brands</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.areas"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.areas" })}
          >
            <Icon icon="task-1" />
            <span>Areas</span>
          </BaseLink>
        </div>

        <div className="top-nav-list-item">
          <BaseLink
            router={$router.router}
            routeName="lists.sources"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.sources" })}
          >
            <Icon icon="task-1" />
            <span>Sources</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.spam_filters"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.spam_filters" })}
          >
            <Icon icon="task-1" />
            <span>Spam Filters</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.campaigns"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.campaigns" })}
          >
            <Icon icon="task-1" />
            <span>Campaigns</span>
          </BaseLink>
        </div>

        <div className="top-nav-list-item">
          <BaseLink
            router={$router.router}
            routeName="lists.companies"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.companies" })}
          >
            <Icon icon="task-1" />
            <span>Companies</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.payments_sevices"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.payments_sevices" })}
          >
            <Icon icon="task-1" />
            <span>Payment Services</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.warehouses"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.warehouses" })}
          >
            <Icon icon="task-1" />
            <span>Warehouses</span>
          </BaseLink>
        </div>

        <div className="top-nav-list-item">
          <BaseLink
            router={$router.router}
            routeName="lists.services"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.services" })}
          >
            <Icon icon="task-1" />
            <span>Services</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.materials"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.materials" })}
          >
            <Icon icon="task-1" />
            <span>Materials</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.discounts"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.discounts" })}
          >
            <Icon icon="task-1" />
            <span>Discounts</span>
          </BaseLink>
        </div>

        <div className="top-nav-list-item">
          <BaseLink
            router={$router.router}
            routeName="lists.ticket_templates"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.ticket_templates" })}
          >
            <Icon icon="task-1" />
            <span>Ticket Templates</span>
          </BaseLink>
        </div>
      </div>

      {/* Contents */}
      <div className="wrapper">

        <div className="flex-container sb header">
          <h1>List of Ticket Templates</h1>

          {
            (errorAdd || errorEdit) &&
            <span style={{ color: '#FF0000' }}>This template is already taken</span>
          }

          {
            permissions.list_ticket_template_add &&
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gridGap: '15px',
                gap: '15px',
                width: '70%',
              }}
              className="flex-container-select"
            >

              <InputTextWithTitle
                width={150}
                label='Name'
                value={newItemForm.type}
                setValue={(value) => setNewItemForm({ ...newItemForm, type: value })}
              />

              <TextAreaWithTitle
                label='Template'
                value={newItemForm.template}
                setValue={(value) => setNewItemForm({ ...newItemForm, template: value })}
              />

              <button
                className="_wa _green"
                disabled={newItemForm.type.length < 1 || newItemForm.template.length < 1 || isSaving || errorAdd}
                onClick={(e) => { e.preventDefault(); addItem() }}
              >
                Add
              </button>
            </div>
          }
        </div>

        {/* Table */}
        {
          !!items.length &&
          permissions.list_ticket_template_show &&
          <table className="table">
            <thead>
              <tr>
                <th style={{ width: '20%' }}>Name</th>
                <th style={{ width: '30%' }}>Responsibles</th>
                <th style={{ width: '50%' }}>Template</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            {items.map((item, i) => (<React.Fragment key={i}>
              {item.editing ? (
                <tr>
                  <td>
                    <div className="editing-form">
                      <input
                        className="_zeroed"
                        type="text"
                        disabled={!permissions.list_ticket_template_edit}
                        defaultValue={editItemForm.type}
                        onChange={({ target: { value } }) => setEditItemForm({ ...editItemForm, type: value })}
                      />

                      <button
                        className="_zeroed _iconed _red"
                        onClick={() => setEditing(i, false)}
                      >
                        <Icon icon="pencil-16" />
                      </button>
                    </div>
                  </td>

                  <td>
                    <div className="editing-form">
                      <input
                        className="_zeroed"
                        type="text"
                        disabled={!permissions.list_ticket_template_edit}
                        defaultValue={editItemForm.responsibles}
                        onChange={({ target: { value } }) => setEditItemForm({ ...editItemForm, responsibles: value })}
                      />

                      <button
                        className="_zeroed _iconed _red"
                        onClick={() => setEditing(i, false)}
                      >
                        <Icon icon="pencil-16" />
                      </button>
                    </div>
                  </td>

                  <td>
                    <div
                      className="editing-form editing-form-select"
                      style={{ maxWidth: '50vw', position: 'relative' }}
                    >
                      <textarea
                        style={{ resize: 'none', minHeight: '75px', paddingBottom: '15px' }}
                        disabled={!permissions.list_ticket_template_edit}
                        value={editItemForm.template}
                        onChange={({ target: { value } }) => editItemForm.template.length < 3000 && setEditItemForm({ ...editItemForm, template: value })}
                      >
                      </textarea>
                      <span style={{
                        position: 'absolute',
                        bottom: '8px',
                        right: '33px',
                        backgroundColor: 'white',
                        zIndex: '2',
                        color: editItemForm.template.length < 2950 ? 'rgba(0, 0, 0, .6)' : 'rgb(255, 0, 0, .6);',
                        fontSize: '12px',
                      }}>
                        {editItemForm.template.length} / 3000
                      </span>

                      <button
                        className="_zeroed _iconed _red"
                        onClick={() => setEditing(i, false)}
                      >
                        <Icon icon="pencil-16" />
                      </button>
                    </div>
                  </td>

                  <td>
                    <button
                      className="_zeroed _iconed _green"
                      disabled={isSaving || errorEdit}
                      onClick={() => setItem(i)}
                    >
                      <Icon icon="check-mark-1" />
                    </button>
                  </td>
                  <td>
                    <button
                      className="_zeroed _iconed _red"
                      disabled={!permissions.list_ticket_template_delete || isSaving}
                      onClick={() => removeTemplate(item.ticket_template_id)}
                    >
                      <Icon icon="x-mark-1" />
                    </button>
                  </td>
                </tr>
              ) : (
                <tr key={i}>
                  <td>{item.type}</td>
                  <td>{item.responsibles}</td>
                  <td>
                    <p
                      style={{
                        margin: '0',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        padding: '5px',
                        maxWidth: '50vw',
                      }}
                      title={item.template}
                    >
                      {item.template}
                    </p>
                  </td>
                  <td>
                    <button
                      disabled={!permissions.list_ticket_template_edit}
                      className="_zeroed _iconed _blue"
                      onClick={() => setEditing(i, true)}
                    >
                      <Icon icon="pencil-14" />
                    </button>
                  </td>
                  <td>
                    <button
                      className="_zeroed _iconed _red"
                      disabled={!permissions.list_ticket_template_delete || isSaving}
                      onClick={() => removeTemplate(item.ticket_template_id)}
                    >
                      <Icon icon="x-mark-1" />
                    </button>
                  </td>
                </tr>
              )}
            </React.Fragment>))}
          </table>
        }
      </div>
    </div >
  )
}

export default ListsPage_TicketTemplates
