import { useEffect, useState } from 'react'
import { useRoute } from 'react-router5'
import classNames from 'classnames'
import ReactInputMask from 'react-input-mask'
import qs from 'qs'

import Icon from '../../components/Icon'
import Checkbox from '../../components/Checkbox'
import PaymentFields from '../Jobs/PaymentFields'

import { useAppSelector } from '../../store/hooks'
import { formatPhoneNumberToServerString, httpClientUpdate, nErrorUpdate, validateEmail, capitalizeFirstLetter } from '../../funcs'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import '../../styles/pages/jobItemTech.sass'

interface HttpPermissionReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: {
    permissions: PermissionsProps
    service_resource_id: string
    edit: {
      payment_services: PaymentServicesProps[]
    }
  }
}

interface PaymentServicesProps {
  is_not_editable: boolean
  name: string
  payment_services_id: string
}

interface PermissionsProps {
  is_can_cancel_payment_fee_compensation: boolean
  job_search_show: boolean
  job_search_show_own: boolean
  payment_square_make: boolean
  payment_stripe_make: boolean
}

interface SaveReport {
  data: {
    email_notification_details: {
      notify_subject: string
      notify_text: string
    }
    message: string
    payment_id: string
    phone_notification_details: {
      notify_text: string
    }
  }
  success: boolean,
  error: {
    code: number,
    message: string
  },
}

interface HttpReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },

  data: ReportData
}

interface ReportData {
  jobs: JobsProps[]
}

interface JobsProps {
  job_id: string
  name: string
  not_invoice_payment_item_total: string
  contact_name: string
  contact_id: string
  company_id: string
  invoices: IncoicesProps[]
}

interface IncoicesProps {
  comment: string
  created_at: string
  estimate_id: string
  invoice_id: string
  invoice_paid: string
  invoice_status: string
  invoice_total: string
  job_id: string
  name: string
  updated_at: string
}

export interface InvoiceAddProps {
  job_id: string
  invoice_id?: string
  company_id: string
  contact_id: string
  name: string
  paid: string
  total?: string
  contact_name: string
  is_check: boolean
}

interface DataProps {
  is_add_invoice: boolean
  job_id?: string
  invoice_id?: string
  contact_id?: string
  company_id: string
  name: string
  note: string
  id: string | null
  total: number
  paid: number
  amount: number
  is_check: boolean
}

export default function List({ updated }: { updated: number }) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)

  const [search, setSearch] = useState('')
  const [error, setError] = useState('')

  const [serviceResourceId, setServiceResourceId] = useState('')
  const [paymentServices, setPaymentServices] = useState<PaymentServicesProps[]>([])

  const [permissions, setPermissions] = useState<PermissionsProps>({
    is_can_cancel_payment_fee_compensation: false,
    job_search_show: false,
    job_search_show_own: false,
    payment_square_make: false,
    payment_stripe_make: false,
  })

  const [data, setData] = useState<InvoiceAddProps[]>([])
  const [dataControls, setDataControls] = useState<InvoiceAddProps[]>([])
  const [controls, setControls] = useState({
    page: 1,
    maxPages: 1
  })
  const [updetedToken, setUpdetedToken] = useState(Math.random())

  const [selectedData, setSelectedData] = useState<DataProps[]>([])

  const [withFee, setWithFee] = useState(true)
  const [feeCompensation, setFeeCompensation] = useState(0)
  const [permissionsFeeCompensation, setPermissionsFeeCompensation] = useState(false)

  const [showEmailDetails, setShowEmailDetails] = useState(false)
  const [showPhoneDetails, setShowPhoneDetails] = useState(false)

  const [paymentMethod, setPaymentMethod] = useState('')
  const [paymentOptions, setPaymentOptions] = useState<string[]>([])

  const [errorPaymentLimit, setErrorPaymentLimit] = useState(false)
  const [errorPaymentAmount, setErrorPaymentAmount] = useState(false)
  const [limitForPay, setLimitForPay] = useState<null | number>(null)
  const [amountForPay, setAmountForPay] = useState<null | number>(null)
  const [showAmountError, setShowAmountError] = useState(false)

  const [sending, setSending] = useState({
    phone: false,
    email: false,
  })

  const [saveData, setSaveData] = useState({
    isSendCheck: false,
    message: '',
    email_notification_details: {
      notify_subject: '',
      notify_text: '',
    },
    phone_notification_details: {
      notify_text: ''
    },
    payment_id: '',
    email: '',
    is_repeated_email: false,
    is_repeated_phone: false,
    phone: '',
    isErrorSendEmail: false,
    isErrorSendPhone: false,
    message_email: '',
    message_phone: '',
    isSendEmail: false,
    isSendPhone: false,
  })

  function handleClear() {
    setErrorPaymentLimit(false)
    setPaymentMethod(paymentOptions[0])
    setShowPhoneDetails(false)
    setShowEmailDetails(false)
    setSelectedData([])
    setData([])
    setControls({
      page: 1,
      maxPages: 1
    })
    setDataControls([])
    setError('')
    setSearch('')
    setLimitForPay(null)
    setErrorPaymentAmount(false)
    setAmountForPay(null)
    setSending({
      phone: false,
      email: false,
    })

    setSaveData({
      isSendCheck: false,
      message: '',
      email_notification_details: {
        notify_subject: '',
        notify_text: '',
      },
      phone_notification_details: {
        notify_text: ''
      },
      payment_id: '',
      email: '',
      is_repeated_email: false,
      is_repeated_phone: false,
      phone: '',
      isErrorSendEmail: false,
      isErrorSendPhone: false,
      message_email: '',
      message_phone: '',
      isSendEmail: false,
      isSendPhone: false,
    })
  }

  function checkPaymentLimit() {
    let check = false

    if (!!paymentMethod && +getPayByCheck() > 0 && limitForPay !== null) {
      let sum = +getPayByCheck()

      if (sum > limitForPay) {
        check = true
      }
    }
    return check
  }

  useEffect(() => {
    loadPermission()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setShowAmountError(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod])

  async function loadPermission() {

    try {
      // /api/technician/payment-terminal?account_id=88888
      const { data: { data: dataReport, success, error } } = (await httpClientUpdate.get('/technician/payment-terminal', {
        params: {
          account_id: activeAccountId
        }
      }
      )) as { data: HttpPermissionReport }
      if (success) {
        setPermissions(dataReport.permissions)
        setPermissionsFeeCompensation(dataReport.permissions.is_can_cancel_payment_fee_compensation)
        setServiceResourceId(dataReport.service_resource_id)
        setPaymentServices(dataReport.edit.payment_services)

        if (dataReport.permissions.payment_stripe_make && dataReport.permissions.payment_square_make) {
          setPaymentOptions(['stripe', 'square'])
          setPaymentMethod('stripe')
        } else if (dataReport.permissions.payment_stripe_make && !dataReport.permissions.payment_square_make) {
          setPaymentOptions(['stripe'])
          setPaymentMethod('stripe')
        } else if (!dataReport.permissions.payment_stripe_make && dataReport.permissions.payment_square_make) {
          setPaymentOptions(['square'])
          setPaymentMethod('square')
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

    }
    catch (error: Error | AxiosError | unknown) {

      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function getInvoices() {
    try {
      // https://2022back4.artemiudintsev.com/api/payments/invoices
      const { data: { data: report, success } } = await httpClientUpdate.get(`/payments/invoices`, {
        params: {
          account_id: activeAccountId,
          search: search
        }
      }) as { data: HttpReport }
      if (success) {
        if (report.jobs.length) {
          let items: InvoiceAddProps[] = []

          report.jobs.forEach((item) => {
            items.push({
              job_id: item.job_id,
              name: item.name,
              company_id: item.company_id,
              paid: item.not_invoice_payment_item_total ? item.not_invoice_payment_item_total : '0.00',
              contact_id: item.contact_id,
              contact_name: item.contact_name,
              is_check: false,
            })

            if (item.invoices.length) {
              item.invoices.forEach(item_invoice => {
                items.push({
                  job_id: item_invoice.job_id,
                  name: item_invoice.name,
                  company_id: item.company_id,
                  invoice_id: item_invoice.invoice_id,
                  paid: item_invoice.invoice_paid,
                  total: item_invoice.invoice_total,
                  contact_id: item.contact_id,
                  contact_name: item.contact_name,
                  is_check: false,
                })
              })

            }
          })

          setData(items)
          if (!!items.length) {
            setControls({
              page: 1,
              maxPages: Math.floor(items.length / 10)
            })
            setDataControls(items.slice(0, 10))
          }
          setError('')
          setUpdetedToken(Math.random())
        } else {
          setData([])
          setDataControls([])
          setError('Nothing was found!')
        }
      }

    } catch (error) {
      setData([])
      setError('Something went wrong!')
    }
  }

  async function handleSendEmail() {
    setSending({
      ...sending,
      email: true
    })
    try {
      // https://2022back4.artemiudintsev.com/api/payments/{payment_id}/notify
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { data: { data: report, success, error } } = await httpClientUpdate.post(`/payments/${saveData.payment_id}/notify`, qs.stringify({
        account_id: activeAccountId,
        contact: saveData.email,
        type: 'email',
        is_repeated: saveData.is_repeated_email ? 1 : null
      }, { skipNulls: true })) as { data: SaveReport }

      if (success) {
        setSending({
          ...sending,
          email: false
        })
        setSaveData({
          ...saveData,
          isErrorSendEmail: false,
          message_email: 'Success send',
          isSendEmail: true,
        })
      } else {
        setSending({
          ...sending,
          email: false
        })
        setSaveData({
          ...saveData,
          isErrorSendEmail: true,
          message_email: 'The email has not been sent, try again later or change the email',
          isSendEmail: true,
        })
      }
    } catch (error) {
      setSending({
        ...sending,
        email: false
      })
      setSaveData({
        ...saveData,
        isErrorSendEmail: true,
        message_email: 'The email has not been sent, try again later or change the email',
        isSendEmail: true,
      })
    }
  }

  async function handleSendPhone() {
    setSending({
      ...sending,
      phone: true
    })
    try {
      // https://2022back4.artemiudintsev.com/api/payments/{payment_id}/notify
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { data: { data: report, success, error } } = await httpClientUpdate.post(`/payments/${saveData.payment_id}/notify`, qs.stringify({
        account_id: activeAccountId,
        contact: formatPhoneNumberToServerString(saveData.phone),
        type: 'phone',
        is_repeated: saveData.is_repeated_phone ? 1 : null
      }, { skipNulls: true })) as { data: SaveReport }

      if (success) {
        setSending({
          ...sending,
          phone: false
        })
        setSaveData({
          ...saveData,
          isErrorSendPhone: false,
          message_phone: 'Success send',
          isSendPhone: true,
        })
      } else {
        setSending({
          ...sending,
          phone: false
        })
        setSaveData({
          ...saveData,
          isErrorSendPhone: true,
          message_phone: 'The receipt has not been sent, try again later or check the phone number',
          isSendPhone: true,
        })
      }
    } catch (error) {
      setSending({
        ...sending,
        phone: false
      })
      setSaveData({
        ...saveData,
        isErrorSendPhone: true,
        message_phone: 'The receipt has not been sent, try again later or check the phone number',
        isSendPhone: true,
      })
    }
  }

  useEffect(() => {
    if (!!data.length) {
      if (controls.page === 1) {
        setDataControls(data.slice(0, 10))
      } else {
        setDataControls(data.slice(controls.page * 10, (controls.page * 10) + 10))
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controls.page])

  function handleCheck(value: boolean, job_id: string, invoice_id?: string) {
    if (value) {
      let updated = selectedData.map(item => item)

      if (invoice_id) {
        setData(data.map(item => {
          if (item.invoice_id === invoice_id) {
            let total = item.total ? Number(item.total.replace('$', '')) : 0

            updated.push({
              job_id: item.job_id,
              invoice_id: item.invoice_id,
              contact_id: item.contact_id,
              company_id: item.company_id,
              note: '',
              id: null,
              amount: 0,
              name: item.name,
              total: total,
              paid: Number(item.paid),
              is_check: true,
              is_add_invoice: true,
            })

            return { ...item, is_check: true }
          } else {
            return { ...item }
          }
        }))
        setDataControls(dataControls.map(item => {
          if (item.invoice_id === invoice_id) {
            return { ...item, is_check: true }
          } else {
            return { ...item }
          }
        }))
      } else {
        setData(data.map(item => {
          if (item.job_id === job_id && !item.invoice_id) {
            let total = item.total ? Number(item.total.replace('$', '')) : 0

            updated.push({
              job_id: item.job_id,
              invoice_id: item.invoice_id,
              contact_id: item.contact_id,
              company_id: item.company_id,
              note: '',
              id: null,
              amount: 0,
              name: item.name,
              total: total,
              paid: Number(item.paid),
              is_check: true,
              is_add_invoice: true,
            })

            return { ...item, is_check: true }
          } else {
            return { ...item }
          }
        }))

        setDataControls(dataControls.map(item => {
          if (item.job_id === job_id && !item.invoice_id) {
            return { ...item, is_check: true }
          } else {
            return { ...item }
          }
        }))
      }

      setSelectedData(updated)
    } else {
      if (invoice_id) {
        setData(data.map(item => {
          if (item.invoice_id === invoice_id) {
            return { ...item, is_check: false }
          } else {
            return { ...item }
          }
        }))
        setDataControls(dataControls.map(item => {
          if (item.invoice_id === invoice_id) {
            return { ...item, is_check: false }
          } else {
            return { ...item }
          }
        }))
        setSelectedData(selectedData.filter(item => item.invoice_id !== invoice_id))
      } else {
        setData(data.map(item => {
          if (item.job_id === job_id) {
            return { ...item, is_check: false }
          } else {
            return { ...item }
          }
        }))
        setDataControls(dataControls.map(item => {
          if (item.job_id === job_id) {
            return { ...item, is_check: false }
          } else {
            return { ...item }
          }
        }))
        setSelectedData(selectedData.filter(item => item.job_id !== job_id))
      }
    }
  }

  function handleDeleteSelected(job_id?: string, invoice_id?: string) {
    if (invoice_id) {
      setData(data.map(item => {
        if (item.invoice_id === invoice_id) {
          return { ...item, is_check: false }
        } else {
          return { ...item }
        }
      }))
      setDataControls(dataControls.map(item => {
        if (item.invoice_id === invoice_id) {
          return { ...item, is_check: false }
        } else {
          return { ...item }
        }
      }))
      setSelectedData(selectedData.filter(item => item.invoice_id !== invoice_id))
    } else {
      setData(data.map(item => {
        if (item.job_id === job_id && !item.invoice_id) {
          return { ...item, is_check: false }
        } else {
          return { ...item }
        }
      }))
      setDataControls(dataControls.map(item => {
        if (item.job_id === job_id && !item.invoice_id) {
          return { ...item, is_check: false }
        } else {
          return { ...item }
        }
      }))
      setSelectedData(selectedData.filter(item => item.job_id !== job_id))
    }
  }

  function handleChangeNote(value: string, index: number, is_add_data?: boolean) {
    if (is_add_data) {
      setSelectedData(selectedData.map((item, idx) => {
        if (idx === index) {
          return { ...item, note: value }
        } else {
          return { ...item }
        }
      }))
    } else {
      setData(data.map((item, idx) => {
        if (idx === index) {
          return { ...item, note: value }
        } else {
          return { ...item }
        }
      }))
    }
  }

  function handleFocus(event: any) {
    const target = event.currentTarget;

    target.type = 'text';
    target.setSelectionRange(target.value.length, target.value.length);
    target.type = 'number';
    target && target.focus()
  }

  function handleChangeItem(event: any, index: number, is_add_invoice: boolean, is_add_data?: boolean) {
    let price = event.target.value
    if (is_add_data) {
      let numberWithoutDot = price.split('.')

      if (Number(price) >= 0) {
        if (numberWithoutDot[1] && numberWithoutDot[1].toString().length > 2) {
          let newNumber = `${numberWithoutDot[0]}${numberWithoutDot[1].toString()[0]}.${numberWithoutDot[1].toString()[1]}${price[price.length - 1]}`
          setSelectedData(selectedData.map((item, idx) => {
            if (idx === index) {
              return { ...item, amount: Number(newNumber) }
            } else {
              return { ...item }
            }
          }))
        } else {
          let newNumber = price

          setSelectedData(selectedData.map((item, idx) => {
            if (idx === index) {
              return { ...item, amount: newNumber }
            } else {
              return { ...item }
            }
          }))
        }
      }
    } else {
      let item = selectedData[index]

      let total = item.total

      let paid = item.paid

      let max_price = total - paid

      let numberWithoutDot = price.split('.')

      if (Number(price) >= 0 && Number(price) <= max_price) {
        if (numberWithoutDot[1] && numberWithoutDot[1].toString().length > 2) {
          let newNumber = `${numberWithoutDot[0]}${numberWithoutDot[1].toString()[0]}.${numberWithoutDot[1].toString()[1]}${price[price.length - 1]}`
          setSelectedData(selectedData.map((item, idx) => {
            if (idx === index) {
              return { ...item, amount: max_price < Number(newNumber) ? max_price : Number(newNumber) }
            } else {
              return { ...item }
            }
          }))
        } else {
          let newNumber = price

          setSelectedData(selectedData.map((item, idx) => {
            if (idx === index) {
              return { ...item, amount: newNumber }
            } else {
              return { ...item }
            }
          }))
        }
      }
    }
  }

  function handleChangeItemBackspace(event: any, index: number, is_add_data?: boolean) {
    if (event.keyCode === 8) {
      event.preventDefault()
      let price = event.target.value
      let numberWithoutDot = `${price}`.split('.')
      if (numberWithoutDot[0].length > 1) {
        let newNumber = `${numberWithoutDot[0].slice(0, -1)}.${numberWithoutDot[0][numberWithoutDot[0].length - 1]}${numberWithoutDot[1][0]}`
        if (is_add_data) {
          setSelectedData(selectedData.map((item, idx) => {
            if (idx === index) {
              return { ...item, amount: Number(newNumber) }
            } else {
              return { ...item }
            }
          }))
        } else {
          setSelectedData(selectedData.map((item, idx) => {
            if (idx === index) {
              return { ...item, amount: Number(newNumber) }
            } else {
              return { ...item }
            }
          }))
        }

      } else {
        let newNumber = `0.${numberWithoutDot[0][0]}${numberWithoutDot[1][0]}`
        if (is_add_data) {
          setSelectedData(selectedData.map((item, idx) => {
            if (idx === index) {
              return { ...item, amount: Number(newNumber) }
            } else {
              return { ...item }
            }
          }))
        } else {
          setSelectedData(selectedData.map((item, idx) => {
            if (idx === index) {
              return { ...item, amount: Number(newNumber) }
            } else {
              return { ...item }
            }
          }))
        }
      }
    }
  }

  function handleCloseSendInfo() {
    setSaveData({
      isSendCheck: false,
      message: '',
      email_notification_details: {
        notify_subject: '',
        notify_text: '',
      },
      phone_notification_details: {
        notify_text: ''
      },
      payment_id: '',
      is_repeated_email: false,
      is_repeated_phone: false,
      email: '',
      phone: '',
      isErrorSendEmail: false,
      isErrorSendPhone: false,
      message_email: '',
      message_phone: '',
      isSendEmail: false,
      isSendPhone: false,
    })
    setPaymentMethod(paymentOptions[0])
    setShowEmailDetails(false)
    setShowPhoneDetails(false)
    handleClear()
  }

  function getPayByCheck() {
    let pay = 0

    selectedData.forEach(item => {
      if (item.is_check) {
        pay = pay + Number(item.amount)
      }
    })

    return pay
  }

  function checkAmmountError() {
    let check = false
    if (amountForPay !== null && !showAmountError) {
      let sum = getPayByCheck()

      if (sum > amountForPay) {
        check = true
      }
    }

    return check
  }

  function totalIncludeFee() {
    let sum = getPayByCheck()
    let total = 0
    let compensation = 0

    if (withFee) {
      compensation = feeCompensation
    }

    if (sum > 0) {
      total = ((sum / 100) * compensation)
    }

    sum = sum + total

    return sum.toFixed(2)
  }

  function getFee() {
    let sum = getPayByCheck()
    let total = 0
    let compensation = 0

    if (withFee) {
      compensation = feeCompensation
    }

    if (sum > 0) {
      total = ((sum / 100) * compensation)
    }

    return total.toFixed(2)
  }

  return (
    <div
      className='job-tech'
      style={{ padding: '0', minHeight: 'calc(100vh - 100px)', background: 'inherit' }}
    >
      <div
        className={classNames('payment-page job-tech-customer time-tracking mobile',
          {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          }
        )}
      >
        <div className='content-title'>
          <div
            className='content-title-row'
            style={{ alignItems: 'center' }}
          >
            <div></div>

            <div className='mobile-text'>
              Payment Terminal
            </div>

            <div
              className='mobile-text'
              style={{ color: '#FF0000', position: 'relative' }}
              onClick={() => {
                handleClear()
              }}
            >
              <span
                style={{
                  position: 'absolute',
                  top: '-10px',
                  left: '-50px',
                  opacity: (!!selectedData.length || !!data.length) ? '1' : '.6'
                }}
              >
                Clear
              </span>
            </div>
          </div>
        </div>

        {
          !saveData.isSendCheck &&
          <div className='search-invoices'>
            <input
              type="text"
              disabled={!permissions.job_search_show && !permissions.job_search_show_own}
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />

            <button
              disabled={search === ''}
              onClick={getInvoices}
            >
              <Icon viewBox="0 0 70 70" icon="search-job-tech-10" />
            </button>
          </div>
        }

        {
          !!error &&
          <div className='error-message'>
            <p> {error} </p>
          </div>
        }

        {
          !saveData.isSendCheck &&
          <div className='invoices-items'>
            {
              dataControls.map((item, index) => (
                <div
                  key={index}
                  className={classNames('invoices-item')}
                >
                  <div className='invoices-item-job'>
                    <div className='invoices-item-info'>
                      <Checkbox
                        contents={""}
                        value={item.is_check}
                        onChange={(value) => handleCheck(value, item.job_id, item.invoice_id)}
                      />
                    </div>

                    <div className='invoices-item-info'>
                      <div className='bold'>
                        {item.name}
                        {
                          item.total && (Number(item.total.replace('$', '')) - Number(item.paid) === 0 || Number(item.paid) >= Number(item.total.replace('$', ''))) &&
                          <Icon viewBox="0 0 60 60" icon="check-job-tech" />
                        }
                      </div>

                      {
                        item.invoice_id &&
                        <div> Total: {item.total} </div>
                      }
                    </div>
                  </div>

                  <div className='invoices-item-job max-width'>
                    <div className='invoices-item-info'>
                      <div
                        className={classNames('contact_name bold')}
                        style={{ maxWidth: '200px' }}
                      >
                        {item.contact_name}
                      </div>

                      <div> Paid: {item.paid} </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        }

        {
          !saveData.isSendCheck &&
          <div className='table-pages-nav'>
            <button
              className="_zeroed _iconed"
              disabled={controls.page === 1}
              onClick={() => setControls({ ...controls, page: 1 })}
            >
              <Icon className="_rotated-180" icon="arrow-31" />
            </button>
            <button
              className="_zeroed _iconed"
              disabled={controls.page <= 1}
              onClick={() => setControls({ ...controls, page: controls.page - 1 })}
            >
              <Icon className="_rotated-180" icon="arrow-25" />
            </button>
            <button
              className="_zeroed _iconed"
              disabled={controls.page >= controls.maxPages}
              onClick={() => setControls({ ...controls, page: controls.page + 1 })}
            >
              <Icon icon="arrow-25" />
            </button>
            <button
              className="_zeroed _iconed"
              disabled={controls.page >= controls.maxPages}
              onClick={() => setControls({ ...controls, page: controls.maxPages })}
            >
              <Icon icon="arrow-31" />
            </button>
          </div>
        }

        {
          errorPaymentAmount && !showAmountError &&
          <div
            className="item-delete-popup"
            onClick={() => {
              setErrorPaymentAmount(false)
              setShowAmountError(true)
            }}
          >
            <div className="wrapper" onClick={(e) => e.stopPropagation()}>

              <div className="title">
                Confirm that the payment is in the amount of ${getPayByCheck().toFixed(2)}
              </div>

              <div className="buttons">

                <button
                  className="_bordered _green"
                  onClick={() => {
                    setErrorPaymentAmount(false)
                    setShowAmountError(true)
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        }

        {
          errorPaymentLimit &&
          <div className="item-delete-popup" onClick={() => setErrorPaymentLimit(false)}>
            <div className="wrapper" onClick={(e) => e.stopPropagation()}>

              <div className="title">
                The payment amount is more than the limit, contact the administrator to increase the limit
              </div>

              <div className="buttons">

                <button
                  className="_bordered _green"
                  onClick={() => setErrorPaymentLimit(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        }

        {
          saveData.isSendCheck ?
            <>
              <div className='send-check'>
                {
                  <div className='send-check-content'>
                    <p style={{ fontWeight: '700', fontSize: '18px' }}>
                      Payment was successful. {saveData.email_notification_details.notify_text || saveData.phone_notification_details.notify_text ? 'Send a receipt?' : ''}
                    </p>

                    {
                      saveData.email_notification_details.notify_text &&
                      <button
                        className={classNames('_bordered', '_blue', {
                          __show: showEmailDetails
                        })}
                        style={{ width: 'fit-content' }}
                        onClick={() => setShowEmailDetails(!showEmailDetails)}
                      >
                        Send by Email
                      </button>
                    }

                    {
                      showEmailDetails &&
                      <>
                        {
                          saveData.isErrorSendEmail && saveData.isErrorSendEmail &&
                          <p style={{ color: '#FF0000', fontWeight: '700', fontSize: '18px' }}>
                            {saveData.message_email}
                          </p>
                        }

                        {
                          saveData.isSendEmail && !saveData.isErrorSendEmail &&
                          <p style={{ fontWeight: '700', fontSize: '18px', color: '#219653' }}> {saveData.message_email} </p>
                        }

                        <div className='email-label'>
                          <span>Email: </span>
                          <input
                            type="text"
                            value={saveData.email}
                            onChange={(event) => setSaveData({ ...saveData, email: event.target.value })}
                          />
                        </div>

                        <div className='email-label'>
                          <span> Subject: </span>

                          <span className='span-input'>
                            {saveData.email_notification_details.notify_subject}
                          </span>
                        </div>

                        <div className='email-label'>
                          <div
                            className='text-block'
                          >
                            <div dangerouslySetInnerHTML={{ __html: saveData.email_notification_details.notify_text ? saveData.email_notification_details.notify_text : '' }}></div>
                          </div>
                        </div>

                        <div className='btns'>
                          <div
                            className={classNames({
                              __is_repeated: !saveData.isSendEmail
                            })}
                          >
                            <Checkbox
                              contents='Resend?'
                              disabled={!saveData.isSendEmail}
                              value={saveData.is_repeated_email}
                              onChange={(value) => setSaveData({ ...saveData, is_repeated_email: value })}
                            />
                          </div>

                          <button
                            className="_bordered _green"
                            style={{ whiteSpace: 'nowrap' }}
                            disabled={sending.email || saveData.email === '' || !validateEmail(saveData.email) || (saveData.isSendEmail && !saveData.is_repeated_email)}
                            onClick={handleSendEmail}
                          >
                            Send
                          </button>
                        </div>
                      </>
                    }

                    {
                      saveData.phone_notification_details.notify_text &&
                      <button
                        style={{ marginTop: '10px', width: 'fit-content' }}
                        className={classNames('_bordered', '_blue', {
                          __show: showPhoneDetails
                        })}
                        onClick={() => setShowPhoneDetails(!showPhoneDetails)}
                      >
                        Send by SMS
                      </button>
                    }

                    {
                      showPhoneDetails &&
                      <>
                        {
                          saveData.isSendPhone && saveData.isErrorSendPhone &&
                          <p style={{ color: '#FF0000', fontWeight: '700', fontSize: '18px' }}>
                            {saveData.message_phone}
                          </p>
                        }

                        {
                          saveData.isSendPhone && !saveData.isErrorSendPhone &&
                          <p style={{ fontWeight: '700', fontSize: '18px', color: '#219653' }}> {saveData.message_phone} </p>
                        }

                        <div className='email-label'>
                          <span>Phone: </span>

                          <ReactInputMask
                            type="text"
                            mask="+1 (999) 999-9999"
                            value={saveData.phone}
                            onChange={({ target: { value } }) => setSaveData({ ...saveData, phone: value })}
                          />
                        </div>

                        <div className='email-label'>
                          <div
                            className='text-block'
                          >
                            <div dangerouslySetInnerHTML={{ __html: saveData.phone_notification_details.notify_text ? saveData.phone_notification_details.notify_text : '' }}></div>
                          </div>
                        </div>

                        <div className='btns'>
                          <div
                            className={classNames({
                              __is_repeated: !saveData.isSendPhone
                            })}
                          >
                            <Checkbox
                              contents='Resend?'
                              disabled={!saveData.isSendPhone}
                              value={saveData.is_repeated_phone}
                              onChange={(value) => setSaveData({ ...saveData, is_repeated_phone: value })}
                            />
                          </div>

                          <button
                            className="_bordered _green"
                            style={{ whiteSpace: 'nowrap' }}
                            disabled={sending.phone || formatPhoneNumberToServerString(saveData.phone).length !== 12 || (saveData.isSendPhone && !saveData.is_repeated_phone)}
                            onClick={handleSendPhone}
                          >
                            Send
                          </button>
                        </div>
                      </>
                    }

                    <div className='btns'>
                      <button
                        className="_bordered _red"
                        style={{ whiteSpace: 'nowrap' }}
                        onClick={handleCloseSendInfo}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                }
              </div>
            </> :
            <>
              <div className='payment-title'>
                <Icon style={{ width: '20px' }} viewBox="0 0 78 85" icon="group-23" />

                Invoices
              </div>

              <div className='payment-invoices-items'>

                {
                  selectedData.map((item, index) => (
                    <div
                      key={index}
                      className="payment-invoices-item"
                    >
                      <div
                        className='payment-invoices-item-row'
                        style={{ alignItems: 'flex-start' }}
                      >
                        <div
                          className='invoice-info'
                          style={{ flexDirection: 'row' }}
                        >
                          <div className='invoice-info-title'>
                            <span title={item.name}> {item.name} </span>
                          </div>

                          <div
                            className='invoice-info-row'
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                              gap: '10px',
                            }}
                          >
                            {
                              item.invoice_id &&
                              <div style={{ width: 'fit-content', whiteSpace: 'normal', textAlign: 'center' }} className='invoice-info-total'>
                                Total: ${item.total.toFixed(2)}
                              </div>
                            }

                            <div style={{ width: 'fit-content', whiteSpace: 'normal', textAlign: item.invoice_id ? 'center' : 'left' }} className='invoice-info-paid'>
                              Already Paid: ${item.paid.toFixed(2)}
                            </div>
                          </div>
                        </div>

                        <div className='invoice-arrow'>
                          <button
                            onClick={() => handleDeleteSelected(item.job_id, item.invoice_id)}
                          >
                            <Icon style={{ fill: '#FF0000' }} viewBox="0 0 24 24" icon="x-mark-1" />
                          </button>
                        </div>
                      </div>

                      {
                        item.is_check &&
                        <div className='payment-invoices-item-row'>
                          <div className='invoice-data'>
                            <div className='invoice-data-row'>
                              <div className='title'>Note:</div>

                              <div className='input'>
                                <input
                                  type="text"
                                  value={item.note}
                                  onChange={(event) => handleChangeNote(event.target.value, index, true)}
                                />
                              </div>
                            </div>

                            <div className='invoice-data-row'>
                              <div className='title'>Amount:</div>

                              <div className='input'>
                                <input
                                  className="appearance"
                                  type='number'
                                  value={`${Number(item.amount).toFixed(2)}`}
                                  step={0.01}
                                  onFocus={(event) => handleFocus(event)}
                                  onChange={(event) => handleChangeItem(event, index, item.is_add_invoice, true)}
                                  onKeyDown={(event) => handleChangeItemBackspace(event, index, true)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  ))
                }
              </div>

              <div className='empty'></div>

              <div className='payment-card-items'>
                <div className='payment-card-info'>
                  Payment: <span className='sum'>${getPayByCheck().toFixed(2)}</span>
                </div>

                <div className='payment-card-info'>
                  <Checkbox
                    contents=""
                    value={withFee}
                    onChange={() => setWithFee(!withFee)}
                    disabled={!permissionsFeeCompensation}
                  />
                  Processing Fee ({feeCompensation}%): <span className='sum'>${getFee()}</span>
                </div>

                <div className='payment-card-input'>
                  Pay by Card (+Fee): <span className='sum'>${totalIncludeFee()}</span>
                </div>
              </div>

              {
                paymentOptions.length > 1 &&
                <div className='payment-tabs'>
                  {
                    paymentOptions.map(item => (
                      <div
                        className={classNames('payment-tab',
                          {
                            __active: paymentMethod === item,
                          }
                        )}
                        onClick={() => setPaymentMethod(item)}
                      >
                        {capitalizeFirstLetter(item)}
                      </div>
                    ))
                  }
                </div>
              }

              <PaymentFields
                method={paymentMethod}
                setFeeCompensation={setFeeCompensation}
                setPermissionsFeeCompensation={setPermissionsFeeCompensation}
                setPaymentPage={() => handleClear()}
                sum={Number(totalIncludeFee())}
                error_amount={checkAmmountError()}
                error_limit={checkPaymentLimit()}
                setErrorPaymentLimit={setErrorPaymentLimit}
                setErrorPaymentAmount={setErrorPaymentAmount}
                setLimitForPay={setLimitForPay}
                setAmountForPay={setAmountForPay}
                feeCompensation={getFee()}
                data={selectedData}
                jobData={null}
                job_id={''}
                payment_services={paymentServices}
                setSaveData={setSaveData}
                company_id={''}
                service_resource_id={serviceResourceId}
                updetedToken={updetedToken}
              />
            </>
        }
      </div>
    </div>
  )
}
