import { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import ReactInputMask from 'react-input-mask'
import SignatureCanvas from 'react-signature-canvas'
import { PDFDownloadLink } from '@react-pdf/renderer'

import Checkbox from '../../components/Checkbox'
import ApproveDocument from './ApproveDocument'

import { formatPhoneNumberToServerString } from '../../funcs'

import { NavActive } from '../../store/reducer'

interface ApprovalModalProps {
  navActive: NavActive
  phoneCall: boolean
  setModal: (value: boolean) => void
  handleSendLink: (phone: string) => void
  approveText: string
  handleApproveLink: (sign: string, document: string) => void
}

export default function ApprovalModal({
  navActive,
  phoneCall,
  setModal,
  handleSendLink,
  approveText,
  handleApproveLink,
}: ApprovalModalProps) {
  const sigCanvas = useRef(null);

  const [mode, setMode] = useState('')
  const [phone, setPhone] = useState('1')
  const [approve, setApprove] = useState(false)
  const [isSignEnd, setIsSignEnd] = useState(false)

  const [base64Sign, setBase64Sign] = useState('')
  const [blobDoc, setBlobDoc] = useState<Blob | null>(null)

  function handleAgree() {
    if (sigCanvas && sigCanvas.current) {

      //@ts-ignore
      let base64 = sigCanvas.current.toDataURL()
      setBase64Sign(base64)
    }
  }

  useEffect(() => {
    if (blobDoc) {
      let reader = new FileReader();
      reader.readAsDataURL(blobDoc);
      reader.onloadend = function () {
        var base64data = reader.result;
        handleApproveLink(base64Sign, base64data as string)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blobDoc])

  return (
    <div
      className={classNames('link-page payment-page job-tech-customer time-tracking mobile',
        {
          __respectAsidePanel: navActive.is && !phoneCall,
          __phoneCall: phoneCall && !navActive.is,
          __bothOpen: navActive.is && phoneCall,
          __nonePanel: !navActive.is && !phoneCall
        }
      )}
      style={{ margin: 0, padding: 0, zIndex: 1 }}
    >
      {
        mode === '' &&
        <div className='link-menu'>
          <div style={{ background: '#1b2f5f' }} className="title">
            Approval
          </div>

          <div className='approval-btns'>
            <button
              onClick={() => setMode('send_link')}
            >
              Send Link
            </button>

            <button
              onClick={() => setMode('manual')}
            >
              Manual
            </button>
          </div>

          <div className="buttons">
            <button
              className="_bordered _red"
              onClick={() => setModal(false)}
            >
              Close
            </button>
          </div>
        </div>
      }

      {
        mode === 'send_link' &&
        <div className='link-menu'>
          <div style={{ background: '#1b2f5f' }} className="title">
            Send Link
          </div>

          <div className='approval-btns'>
            <ReactInputMask
              style={{ textAlign: 'right' }}
              type="text"
              mask="+1 (999) 999-9999"
              value={phone}
              onChange={({ target: { value } }) => setPhone(value)}
            />
          </div>

          <div className="buttons">
            <button
              className="_bordered _red"
              onClick={() => setMode('')}
            >
              Cancel
            </button>

            <button
              className="_bordered _green"
              disabled={formatPhoneNumberToServerString(phone).length !== 12}
              onClick={() => handleSendLink(formatPhoneNumberToServerString(phone))}
            >
              Send
            </button>
          </div>
        </div>
      }

      {
        mode === 'manual' &&
        <div
          className='link-menu approve-doc'
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          {
            base64Sign ?
              <div className='document'>
                <PDFDownloadLink document={<ApproveDocument approveText={approveText} sign={base64Sign} />} >
                  {({ blob, url, loading, error }) => {
                    blob && setBlobDoc(blob)
                  }}
                </PDFDownloadLink>

              </div> :

              <div className='document'>
                <div className="document-title">
                  Approve for Job
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    marginTop: '20px'
                  }}
                >
                  <div>
                    {
                      approveText.split('\n').map(item => (
                        <p style={{ margin: '2px', whiteSpace: 'break-spaces', wordBreak: 'break-all' }}>{item}</p>
                      ))
                    }
                  </div>
                </div>

                <div className='document-sign'>
                  <div className='link-row-approve'>
                    <Checkbox
                      contents=""
                      value={approve}
                      onChange={(value) => setApprove(value)}
                    />

                    <span>Approve</span>
                  </div>
                  <div className='link-row-approve sign'>
                    <SignatureCanvas
                      ref={sigCanvas}
                      penColor='black'
                      canvasProps={{ width: 200, height: 100 }}
                      onEnd={() => setIsSignEnd(true)}
                    />
                  </div>
                </div>
              </div>
          }

          <div className="buttons">
            <button
              className="_bordered _red"
              onClick={() => {
                setMode('')
                setApprove(false)
                setIsSignEnd(false)
                setBase64Sign('')
              }}
            >
              Cancel
            </button>

            <button
              className="_bordered _green"
              disabled={!approve || !isSignEnd}
              onClick={() => handleAgree()}
            >
              Agree
            </button>
          </div>
        </div>
      }
    </div>
  )
}
