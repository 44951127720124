import { useEffect, useState } from 'react'
import classNames from 'classnames'

import { DataProps } from './LineItems'

interface AddNoteProps {
  setPage: (value: string) => void
  data: DataProps
  setData: (value: DataProps) => void
  handleSave: (note: string) => void
  note: string
}

export default function AddNote({ handleSave, setPage, note }: AddNoteProps) {
  const [data, setData] = useState('')

  useEffect(() => {
    setData(note)
  }, [note])

  return (
    <div className="EstimatePage_Add entity-edit">
      <div className="wrapper flex-container sb editing">
        <div>
          <button
            className="button-cancel"
            onClick={() => setPage('editing')}
          >
            Cancel
          </button>
        </div>

        <div>
          <h1 style={{ whiteSpace: 'nowrap', marginTop: '0', color: '#393939' }}>Note</h1>
        </div>

        <div>
          <button
            className={classNames('button-save-disabled', {
              'button-save': note !== data
            })}
            onClick={() => note !== data && handleSave(data)}
          >
            {
              note === '' ? 'Add' : 'Save'
            }
          </button>
        </div>
      </div>

      <div className='line-items-content service'>
        <div className='line-items-row'>
          <div className='name grey input-name'>
            <textarea
              style={{ minHeight: '50px' }}
              rows={10}
              value={data}
              onChange={(event) => setData(event.target.value)}
            />
          </div>
        </div>

        <span className='note'> Note appears on invoice </span>
      </div>
    </div>
  )
}
