import { useEffect, useState } from "react";
import { useRoute } from "react-router5";

import Icon from "./Icon";
import Checkbox from "./Checkbox";

import { capitalizeFirstLetter } from "../funcs";
import { useAppSelector } from "../store/hooks";

import "../styles/components/notifications-widget.sass"

interface NotificationsWidgetProps {
  notify: NotifyProps
  setActiveNavButton: (value: string | null) => void
  agreeSound: boolean
  handleBell: () => void
}

interface NotifyProps {
  today: number
  yesterday: number
  weekend: number
  booking_unsent_appointments?: number
  booking_unsent_appointments_all?: number
}

function NotificationsWidget({
  notify,
  setActiveNavButton,
  agreeSound,
  handleBell,
}: NotificationsWidgetProps) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [data, setData] = useState(notify)

  useEffect(() => {
    if (notify.booking_unsent_appointments || notify.booking_unsent_appointments_all) {
      let updated: NotifyProps = {
        today: 0,
        yesterday: 0,
        weekend: 0,
      }

      if (notify.booking_unsent_appointments_all) {
        Object.keys(notify).forEach(item => {
          if (item !== 'booking_unsent_appointments') {
            updated[item] = notify[item]
          }
        })
      } else {
        Object.keys(notify).forEach(item => {
          if (item !== 'booking_unsent_appointments_all') {
            updated[item] = notify[item]
          }
        })
      }
      setData(updated)
    } else {
      setData(notify)
    }
  }, [notify])

  // Render function
  return (
    <div className="NotificationsWidget">

      <div className="header">
        <div className="title">
          Notifications
        </div>
      </div>

      <div
        className="notifications-allow"
      >
        <div>Sound Notifications</div>
        <div>
          <Checkbox
            contents=''
            value={agreeSound}
            onChange={() => handleBell()}
          />
        </div>
      </div>

      <div className="notifications-list">
        {
          Object.keys(data).filter(item => data[item]).map((item, i) => (
            <div
              key={i}
              className="notification"
              onClick={(event) => {
                event.stopPropagation()
                event.preventDefault()
                setActiveNavButton(null);
                ['today', 'yesterday', 'weekend'].includes(item) && $router.router.navigate('website_requests', {
                  companyId: activeAccountId,
                  dateRangePreset: item
                }, { reload: true });
                ['need_confirmation', 'need_check'].includes(item) && $router.router.navigate('payout-dispatcher', {
                  companyId: activeAccountId,
                  dateRangePreset: item
                }, { reload: true });
                ['staff_need_check', 'staff_need_confirmation'].includes(item) && $router.router.navigate('payout-staff', {
                  companyId: activeAccountId,
                  dateRangePreset: item
                }, { reload: true });
                ['booking_unsent_appointments', 'booking_unsent_appointments_all'].includes(item) && $router.router.navigate('appointments', {
                  companyId: activeAccountId,
                  dateRangePreset: item
                }, { reload: true });
                item === 'sms_unread' && $router.router.navigate('sms', {
                  companyId: activeAccountId,
                  dateRangePreset: item
                }, { reload: true });
              }
              }>
              <div className="icon">
                <Icon icon="bell-1" />
              </div>

              <div className="contents">

                <div className="title">
                  {
                    item === 'sms_unread' && `Unread SMS`
                  }

                  {
                    ['today', 'yesterday', 'weekend'].includes(item) && `Website Requests / ${capitalizeFirstLetter(item)}`
                  }

                  {
                    ['need_confirmation', 'need_check'].includes(item) && 'Payout Dispatcher'
                  }

                  {
                    ['staff_need_check', 'staff_need_confirmation'].includes(item) && 'Payout Staff'
                  }

                  {
                    ['booking_unsent_appointments', 'booking_unsent_appointments_all'].includes(item) && 'Appointment(s)'
                  }
                </div>

                <div className="timestamp">
                  <Icon icon="speech-bubble-15" />
                  <span>
                    {
                      item === 'sms_unread' && `${data[item]} New SMS`
                    }

                    {
                      ['today', 'yesterday', 'weekend'].includes(item) && `${data[item]} New Website Requests`
                    }

                    {
                      ['need_confirmation', 'staff_need_confirmation'].includes(item) && `${data[item]} Document(s) Needs Confirmation`
                    }

                    {
                      ['need_check', 'staff_need_check'].includes(item) && `${data[item]} Document(s) Needs Checking`
                    }

                    {
                      ['booking_unsent_appointments', 'booking_unsent_appointments_all'].includes(item) && `${data[item]} Unsent Appointment(s)`
                    }
                  </span>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default NotificationsWidget
