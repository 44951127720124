import classNames from 'classnames'

import { NavActive } from '../../store/reducer'

interface ApprovalDocumentModalProps {
  navActive: NavActive
  phoneCall: boolean
  setModal: (value: string) => void
  url: string
}


export default function ApprovalDocumentModal({
  navActive,
  phoneCall,
  setModal,
  url,
}: ApprovalDocumentModalProps) {

  return (
    <div
      className={classNames('link-page payment-page job-tech-customer time-tracking mobile',
        {
          __respectAsidePanel: navActive.is && !phoneCall,
          __phoneCall: phoneCall && !navActive.is,
          __bothOpen: navActive.is && phoneCall,
          __nonePanel: !navActive.is && !phoneCall
        }
      )}
      style={{ zIndex: 1, margin: 0 }}
    >
      <div
        className='link-menu approve-document-modal'
        style={{
          maxWidth: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ width: '100%' }} className='attachment-view approve-document-view'>
          <iframe
            src={`https://docs.google.com/viewer?url=${url}&embedded=true`}
            title="Approval"
          >
          </iframe>
        </div>

        <div className="buttons">
          <button
            className="_bordered _green"
            onClick={() => setModal('')}
          >
            Close
          </button>
        </div>
      </div>
    </div >
  )
}
