import { memo } from 'react'
import classNames from "classnames"

import CallInfo from './CallInfo'
import CallType from './CallType'
import BookingCenterSecondBlock from './BookingCenterSecondBlock'
import BookingCallContact from './BookingCallContact'
import CallUnits from './CallUnits'
import CallNote from './CallNote'

// import Icon from '../../components/Icon'

import {
  ApplianceBrandsProps,
  AppliancesProps,
  CallContactProps,
  CallTypeProps,
  CompaniesProps,
  SourcesProps,
  UnitsListProps,
  PhonesProps,
  AddressProps
} from './Models'

interface BookingCenterProps {
  openLeftSide: boolean
  setOpenLeftSide: (value: boolean) => void

  selectedFromAction: string
  setSelectedFromAction: (value: string) => void
  fromActionOptions: string[]

  selectedLeadSource: string
  setSelectedLeadSource: (value: string) => void
  leadSourceOptions: string[]

  selectedSource: string
  setSelectedSource: (value: string) => void

  callType: string
  setCallType: (value: string) => void
  callTypeSearch: string
  setCallTypeSearch: (value: string) => void
  handleSearchOnCallType: () => void
  callTypeData: CallTypeProps | null

  selectedCompany: string
  setSelectedCompany: (value: string) => void

  mobile?: boolean

  companies: CompaniesProps[] | []
  sources: SourcesProps[] | []
  unitOptions: AppliancesProps[] | []
  brandOptions: ApplianceBrandsProps[] | []

  unitsList: UnitsListProps[] | []
  setUnitsList: (value: UnitsListProps[]) => void

  callContact: CallContactProps
  setCallContact: (value: CallContactProps) => void
  handleSave: () => void
  handleClearBooking: () => void
  checkDisabledSaveButton: () => boolean
  isSaveClick: boolean
  errorTerminal: () => string[]

  checkNewContact: () => void
  checkNewContactError: string
  setCheckNewContactError: (value: string) => void

  phonesList: PhonesProps[] | []
  setPhonesList: (value: PhonesProps[] | []) => void

  addressesList: AddressProps[] | []
  setAddressesList: (value: AddressProps[] | []) => void
  selectedJob: string
  setSelectedJob: (value: string) => void

  client_search_show: boolean

  note: string
  setNote: (value: string) => void

  setIsDifferentZip: (value: boolean) => void
  setChangedZipCode: (value: boolean) => void
  zipCheck: string
}

function BookingCenter({
  openLeftSide,
  setOpenLeftSide,
  selectedFromAction,
  setSelectedFromAction,
  fromActionOptions,
  selectedLeadSource,
  setSelectedLeadSource,
  leadSourceOptions,
  selectedSource,
  setSelectedSource,
  callType,
  setCallType,
  callTypeSearch,
  setCallTypeSearch,
  selectedCompany,
  setSelectedCompany,
  handleSearchOnCallType,
  mobile,
  callTypeData,
  companies,
  sources,
  unitOptions,
  brandOptions,
  unitsList,
  setUnitsList,
  callContact,
  setCallContact,
  handleSave,
  checkDisabledSaveButton,
  isSaveClick,
  errorTerminal,
  checkNewContact,
  checkNewContactError,
  handleClearBooking,
  setCheckNewContactError,
  phonesList,
  setPhonesList,
  addressesList,
  setAddressesList,
  selectedJob,
  setSelectedJob,
  client_search_show,
  note,
  setNote,
  setIsDifferentZip,
  setChangedZipCode,
  zipCheck,
}: BookingCenterProps) {
  return (
    <div
      className={
        classNames('booking_page_center', {
          mobile: mobile
        })
      }
    >
      <div className='booking-center_upper-block'>
        {
          callType === 'Service' &&
          <CallInfo
            selectedFromAction={selectedFromAction}
            setSelectedFromAction={setSelectedFromAction}
            fromActionOptions={fromActionOptions}
            selectedLeadSource={selectedLeadSource}
            setSelectedLeadSource={setSelectedLeadSource}
            leadSourceOptions={leadSourceOptions}
            selectedSource={selectedSource}
            setSelectedSource={setSelectedSource}
            sources={sources}
          />
        }

      </div>

      <CallType
        callType={callType}
        setCallType={setCallType}
        callTypeSearch={callTypeSearch}
        setCallTypeSearch={setCallTypeSearch}
        handleSearchOnCallType={handleSearchOnCallType}
        callTypeData={callTypeData}
        errorTerminal={errorTerminal}
        selectedJob={selectedJob}
        setSelectedJob={setSelectedJob}
        client_search_show={client_search_show}
        isSaveClick={isSaveClick}
      />

      {/* zip code check */}
      {
        callType === 'Service' &&
        <BookingCenterSecondBlock
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
          companies={companies}
        />
      }

      <BookingCallContact
        callContact={callContact}
        setCallContact={setCallContact}
        checkNewContact={checkNewContact}
        checkNewContactError={checkNewContactError}
        setCheckNewContactError={setCheckNewContactError}

        phonesList={phonesList}
        setPhonesList={setPhonesList}

        addressesList={addressesList}
        setAddressesList={setAddressesList}

        callType={callType === 'Service'}
        isSaveClick={isSaveClick}

        setIsDifferentZip={setIsDifferentZip}
        setChangedZipCode={setChangedZipCode}
        zipCheck={zipCheck}
      />

      <CallUnits
        unitOptions={unitOptions}
        brandOptions={brandOptions}
        callContact={callContact}
        setCallContact={setCallContact}
        unitsList={unitsList}
        setUnitsList={setUnitsList}
      />

      <CallNote
        note={note}
        setNote={setNote}
      />


      <div className='call-clearSave'>
        <div className='call-clearSave_block'>
          <button
            className='btn btn-clear'
            onClick={() => handleClearBooking()}
          >
            Clear
          </button>

          <button
            disabled={checkDisabledSaveButton() || isSaveClick}
            className={
              classNames('btn', 'btn-save', {
                inactive: checkDisabledSaveButton() || isSaveClick
              })
            }
            onClick={() => handleSave()}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  )
}

export default memo(BookingCenter)
