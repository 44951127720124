import { useState, useEffect } from 'react'
import { useRoute } from "react-router5"
import classNames from 'classnames'
import qs from 'qs'

import SelectComponent from '../../components/Select'
import Checkbox from '../../components/Checkbox'
import Icon from '../../components/Icon'
import ImageModal from '../Jobs/ImageModal'

import { useAppSelector } from "../../store/hooks"
import { httpClientUpdate, nErrorUpdate } from '../../funcs'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

import "../../styles/pages/common/entity-edit.sass"
import '../Appointments/UnitsBlock.sass'

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: ResponseData
}

interface ResponseData {
  unit: Unit
  permissions: {
    technician_unit_delete: boolean
    technician_unit_edit: boolean
  }
  edit: {
    appliance_brands: {
      appliance_brand: string
      appliance_brand_id: string
    }[]
    appliances: {
      appliance: string
      appliance_id: string
    }[]
    unit_type: string[]
  }
}

export interface Unit {
  appliance: string
  appliance_brand: string
  appliance_brand_id: string
  appliance_id: string
  description: string
  model_number: string
  other_appliance: string
  other_brand: string
  price: string
  serial_number: string
  unit_id: string
  unit_type: string
  attachments: AttachmentsProps[]
}

interface AttachmentsProps {
  document_id: string
  job_attachment_id: string
  type: string
  url: string
}

interface UnitEditProps {
  switchPage: (name: string) => void
  id: string
  jobId: string
  client_id: string
  permission_edit: boolean
  appointment_id: string
}

export default function UnitEdit({
  switchPage,
  id,
  jobId,
  client_id,
  permission_edit,
  appointment_id,
}: UnitEditProps) {
  const $router = useRoute()

  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [editing, setEditing] = useState(false)
  const [readyToSave, setReadyToSave] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)

  const [newUnitData, setNewUnitData] = useState<Partial<Unit>>({})
  const [data, setData] = useState<ResponseData | null>(null)

  const [uploadImage, setUploadImage] = useState<File | null>(null)
  const [uploadImageBase64, setUploadImageBase64] = useState('')

  const [imageModal, setImageModal] = useState<{ url: string, id: string, is_delete: boolean } | null>(null)

  const [mode, setMode] = useState('')
  const [hover, setHover] = useState('')

  async function loadUnit() {
    try {
      const { data: { data: unitData, success, error } } = await httpClientUpdate.get('/technician/units/' + id, {
        params: {
          account_id: activeAccountId,
          appointment_id: appointment_id
        }
      }) as { data: HttpClientUpdateReport }
      if (success) {
        setData(unitData)
        setNewUnitData(unitData.unit)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  function handleCancelEdit() {
    data && setNewUnitData(data.unit)
    setEditing(false)
  }

  async function handleSave() {
    const response = await httpClientUpdate.put(`/technician/units/${id}`, qs.stringify({
      account_id: activeAccountId,
      unit_type: newUnitData.unit_type,
      appliance_id: newUnitData.appliance_id,
      other_appliance: newUnitData.other_appliance,
      appliance_brand_id: newUnitData.appliance_brand_id,
      other_brand: newUnitData.other_brand,
      model_number: newUnitData.model_number,
      serial_number: newUnitData.serial_number,
      price: newUnitData.price,
      description: newUnitData.description,
      appointment_id: appointment_id,
      contact_id: client_id,
      job_id: jobId,
    }))
    if (response.data.success) {
      switchPage('main')
    }
  }

  async function handleRemoveItem() {
    const response = await httpClientUpdate.delete(`/technician/units/${id}`, {
      data: {
        account_id: activeAccountId,
      }
    })
    if (response.data.success) {
      switchPage('main')
    }
  }

  async function handleSaveImages() {
    try {
      // api/technician/jobs/8888821108z6i3wmr7/attachments
      const { data: { success } } = await httpClientUpdate.post(`/technician/jobs/${jobId}/attachments`, {
        account_id: activeAccountId,
        photo: uploadImageBase64,
        type: 'unit',
        document_id: id
      })
      if (success) {
        loadUnit()
        setMode('')
        setUploadImage(null)
        setUploadImageBase64('')
      }
    } catch (error) { }
  }

  async function handleDeleteImage() {
    try {
      if (imageModal) {
        // api/technician/jobs/attachments/888882157iv5e6gtmc?account_id=88888
        const { data: { success } } = await httpClientUpdate.delete(`/technician/jobs/attachments/${imageModal.id}`, {
          params: {
            account_id: activeAccountId,
          }
        })
        if (success) {
          loadUnit()
          setImageModal(null)
          setHover('')
          setMode('')
          setUploadImage(null)
          setUploadImageBase64('')
        }
      }
    } catch (error) { }
  }

  useEffect(() => {
    loadUnit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setReadyToSave(true), [
    newUnitData
  ])

  function handleAddImage(file: File) {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      setUploadImageBase64(reader.result as string)
    });

    reader.readAsDataURL(file);

    setUploadImage(file)
  }

  function checkOtherFieldAppliences() {
    let appliances = data?.edit.appliances.filter(item => item.appliance_id === newUnitData.appliance_id)
    return appliances?.length === 1 ? appliances[0].appliance : ''
  }

  function checkOtherFieldBrand() {
    let brand = data?.edit.appliance_brands.filter(item => item.appliance_brand_id === newUnitData.appliance_brand_id)
    return brand?.length === 1 ? brand[0].appliance_brand : ''
  }

  function checkSave() {
    let check = false

    if (newUnitData.unit_type) {
      if (newUnitData.unit_type === '') {
        check = true
      }
    } else {
      check = true
    }

    if (newUnitData.appliance_id) {
      if (newUnitData.appliance_id === '') {
        check = true
      } else if (checkOtherFieldAppliences() === 'Other') {
        if (newUnitData.other_appliance === '') {
          check = true
        }
      }
    } else {
      check = true
    }

    if (newUnitData.appliance_brand_id) {
      if (newUnitData.appliance_brand_id === '') {
        check = true
      } else if (checkOtherFieldBrand() === 'Other') {
        if (newUnitData.other_brand === '') {
          check = true
        }
      }
    } else {
      check = true
    }

    if (newUnitData.description) {
      if (newUnitData.description === '') {
        check = true
      }
    } else {
      check = true
    }

    return check
  }

  return (
    <>
      {
        data &&
          id ?
          <div className="UnitPage_Edit entity-edit">
            {
              mode === '' &&
              <div className="wrapper flex-container sb">
                <h1>Unit</h1>

                <div style={{ display: 'flex' }} >
                  <button
                    disabled={editing}
                    style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
                    className="_wa"
                    onClick={() => switchPage('main')}
                  >
                    <Icon
                      style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                      viewBox="0 0 24 24"
                      icon="arrow-25"
                    />
                  </button>

                  {
                    !editing &&
                      data.permissions.technician_unit_edit ? (
                      <button className="_wa _green" onClick={() => setEditing(true)}>
                        <Icon
                          style={{ width: '16px', height: '16px' }}
                          viewBox="0 0 16 16"
                          icon="pencil-1"
                        />
                      </button>
                    ) : null
                  }
                </div>
              </div>
            }

            {
              mode === 'attachments_add' &&
              <div className="wrapper flex-container sb">
                <div className="flex-container _gap-narrow">
                  <h1>Add Attachments: </h1>
                </div>

                <div style={{ marginBottom: '0' }} className='row'>
                  <button
                    disabled={editing}
                    style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
                    className="_wa"
                    onClick={() => setMode('')}
                  >
                    <Icon
                      style={{ width: '16px', height: '16px', minWidth: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                      viewBox="0 0 24 24"
                      icon="arrow-25"
                    />
                  </button>

                  <button
                    className={classNames('button-save-disabled', {
                      'button-save': !!uploadImage
                    })}
                    onClick={handleSaveImages}
                  >
                    Save
                  </button>
                </div>
              </div>
            }

            {
              mode === 'attachments_add' &&
              <div className='attachments-col'>
                <div className='add-gallary-photo'>
                  <label
                    htmlFor="filePicker"
                    className='add-image'
                    title='Upload a image'
                  >
                    Add From Gallery
                  </label>
                  <input
                    id="filePicker"
                    style={{ visibility: "hidden" }}
                    type="file"
                    name="myImage"
                    onChange={(event) => {
                      event.target.files && handleAddImage(event.target.files[0])
                    }}
                  />
                </div>

                {
                  uploadImage &&
                  <div className='photo-view'>
                    <img
                      style={{ maxHeight: '400px', objectFit: 'cover' }}
                      alt="not found"
                      src={URL.createObjectURL(uploadImage)}
                    />
                  </div>
                }
              </div>
            }

            {
              mode === '' &&
              <div className="fieldset">
                <div className={classNames('__show-on-wide', {
                  __respectAsidePanel: navActive && !phoneCall,
                  __phoneCall: phoneCall && !navActive,
                  __bothOpen: navActive && phoneCall,
                  __nonePanel: !navActive && !phoneCall
                })}>
                  <div className="item">
                    <div
                      className='row'
                      style={{ justifyContent: 'flex-start' }}
                    >
                      <div
                        className='row unit_type'
                        style={{ width: 'calc(30% - 20px)', marginRight: '20px' }}
                      >
                        <span>Unit type: <span className='requered_field'>*</span></span>

                        <SelectComponent
                          disabled={!editing}
                          options={data.edit.unit_type.sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1).map((type) => ({
                            span: type,
                            value: type
                          }))}
                          selectedOption={newUnitData.unit_type || ''}
                          onChange={(value: string) => setNewUnitData({ ...newUnitData, unit_type: value })}
                        />
                      </div>

                      <div
                        className='row unit_type'
                        style={{ width: 'calc(30% - 20px)', marginRight: '20px' }}
                      >
                        <span>Unit:<span className='requered_field'>*</span></span>

                        <SelectComponent
                          disabled={!editing}
                          options={data.edit.appliances.sort((a, b) => a.appliance.toLowerCase() > b.appliance.toLowerCase() ? 1 : -1).sort((a, b) => a.appliance !== 'Other' ? 1 : -1).map((type) => ({
                            span: type.appliance,
                            value: type.appliance_id
                          }))}
                          selectedOption={newUnitData.appliance_id || '0'}
                          onChange={(value: string) => setNewUnitData({ ...newUnitData, appliance_id: value, other_appliance: '' })}
                        />
                      </div>

                      {
                        editing && checkOtherFieldAppliences() === 'Other' &&
                        <div
                          className="row unit_type"
                          style={{ width: 'calc(30% - 20px)', marginRight: '20px' }}
                        >
                          <span>Other Unit:<span className='requered_field'>*</span></span>

                          <input
                            className='model_number'
                            name='other_appliance'
                            type="text"
                            disabled={!editing}
                            value={newUnitData.other_appliance}
                            onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, other_appliance: value })}
                          />
                        </div>
                      }

                    </div>

                    <div style={{ justifyContent: 'flex-start' }} className='row'>
                      <div
                        className='row unit_type'
                        style={{ width: 'calc(30% - 20px)', marginRight: '20px' }}
                      >
                        <span>Brand:<span className='requered_field'>*</span></span>

                        <SelectComponent
                          disabled={!editing}
                          options={data.edit.appliance_brands.sort((a, b) => a.appliance_brand.toLowerCase() > b.appliance_brand.toLowerCase() ? 1 : -1).sort((a, b) => a.appliance_brand !== 'Other' ? 1 : -1).map((type) => ({
                            span: type.appliance_brand,
                            value: type.appliance_brand_id
                          }))}
                          selectedOption={newUnitData.appliance_brand_id || ''}
                          onChange={(value: string) => setNewUnitData({ ...newUnitData, appliance_brand_id: value, other_brand: '' })}
                        />
                      </div>

                      {
                        editing && checkOtherFieldBrand() === 'Other' &&
                        <div
                          className="row unit_type"
                          style={{ width: 'calc(30% - 20px)', marginRight: '20px' }}
                        >
                          <span>Other Brand:<span className='requered_field'>*</span></span>

                          <input
                            className='model_number'
                            name='other_brand'
                            type="text"
                            disabled={!editing}
                            value={newUnitData.other_brand}
                            onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, other_brand: value })}
                          />
                        </div>
                      }
                    </div>

                    <div style={{ justifyContent: 'flex-start' }} className='row'>
                      <div
                        className="row unit_type"
                        style={{ width: 'calc(30% - 20px)', marginRight: '20px' }}
                      >
                        <span>Model Number:</span>

                        <input
                          className='model_number'
                          name='model_number'
                          type="text"
                          disabled={!editing}
                          value={newUnitData.model_number}
                          onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, model_number: value })}
                        />
                      </div>

                      <div
                        className="row unit_type"
                        style={{ width: 'calc(30% - 20px)', marginRight: '20px' }}
                      >
                        <span>Serial Number:</span>

                        <input
                          className='serial_number'
                          name='serial_number'
                          type="text"
                          disabled={!editing}
                          value={newUnitData.serial_number}
                          onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, serial_number: value })}
                        />
                      </div>
                    </div>

                    <div style={{ justifyContent: 'flex-start' }} className='row'>
                      <span>Description:<span className='requered_field'>*</span></span>

                      <textarea
                        className='description'
                        name='description'
                        style={{ width: '100%' }}
                        disabled={!editing}
                        value={newUnitData.description}
                        onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, description: value })}
                      >

                      </textarea>
                    </div>
                  </div>
                </div>

                {/* tablet */}
                <div className={classNames('__hide-on-wide', '__hide-on-mobile', {
                  __respectAsidePanel: navActive && !phoneCall,
                  __phoneCall: phoneCall && !navActive,
                  __bothOpen: navActive && phoneCall,
                  __nonePanel: !navActive && !phoneCall
                })}>
                  <div className="item">
                    <div className='row unit_type'>
                      <span>Unit type:<span className='requered_field'>*</span></span>

                      <SelectComponent
                        disabled={!editing}
                        options={data.edit.unit_type.sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1).map((type) => ({
                          span: type,
                          value: type
                        }))}
                        selectedOption={newUnitData.unit_type || ''}
                        onChange={(value: string) => setNewUnitData({ ...newUnitData, unit_type: value })}
                      />
                    </div>

                    <div
                      className='row unit_type'
                      style={{ marginBottom: (editing && checkOtherFieldAppliences() === 'Other') ? '10px' : '20px' }}
                    >
                      <span>Unit:<span className='requered_field'>*</span></span>

                      <SelectComponent
                        disabled={!editing}
                        options={data.edit.appliances.sort((a, b) => a.appliance.toLowerCase() > b.appliance.toLowerCase() ? 1 : -1).sort((a, b) => a.appliance !== 'Other' ? 1 : -1).map((type) => ({
                          span: type.appliance,
                          value: type.appliance_id
                        }))}
                        selectedOption={newUnitData.appliance_id || '0'}
                        onChange={(value: string) => setNewUnitData({ ...newUnitData, appliance_id: value, other_appliance: '' })}
                      />
                    </div>

                    {
                      editing && checkOtherFieldAppliences() === 'Other' &&
                      <div className="row unit_type other">
                        <span>Other Unit:<span className='requered_field'>*</span></span>
                        <input
                          className='model_number'
                          name='other_appliance'
                          type="text"
                          disabled={!editing}
                          value={newUnitData.other_appliance}
                          onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, other_appliance: value })}
                        />
                      </div>
                    }

                    <div
                      className='row unit_type'
                      style={{ marginBottom: (editing && checkOtherFieldBrand() === 'Other') ? '10px' : '20px' }}
                    >
                      <span>Brand:<span className='requered_field'>*</span></span>

                      <SelectComponent
                        disabled={!editing}
                        options={data.edit.appliance_brands.sort((a, b) => a.appliance_brand.toLowerCase() > b.appliance_brand.toLowerCase() ? 1 : -1).sort((a, b) => a.appliance_brand !== 'Other' ? 1 : -1).map((type) => ({
                          span: type.appliance_brand,
                          value: type.appliance_brand_id
                        }))}
                        selectedOption={newUnitData.appliance_brand_id || '0'}
                        onChange={(value: string) => setNewUnitData({ ...newUnitData, appliance_brand_id: value, other_brand: '' })}
                      />
                    </div>

                    {
                      editing && checkOtherFieldBrand() === 'Other' &&
                      <div className="row unit_type other">
                        <span>Other Brand:<span className='requered_field'>*</span></span>
                        <input
                          className='model_number'
                          name='other_brand'
                          type="text"
                          disabled={!editing}
                          value={newUnitData.other_brand}
                          onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, other_brand: value })}
                        />
                      </div>
                    }

                    <div className="row">
                      <span>Model Number:</span>
                      <input
                        className='model_number'
                        name='model_number'
                        type="text"
                        disabled={!editing}
                        value={newUnitData.model_number}
                        onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, model_number: value })}
                      />
                    </div>

                    <div className="row">
                      <span>Serial Number:</span>
                      <input
                        className='serial_number'
                        name='serial_number'
                        type="text"
                        disabled={!editing}
                        value={newUnitData.serial_number}
                        onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, serial_number: value })}
                      />
                    </div>

                    <div className="row">
                      <span>Description:<span className='requered_field'>*</span></span>

                      <textarea
                        className='description'
                        name='description'
                        disabled={!editing}
                        value={newUnitData.description}
                        onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, description: value })}
                      >

                      </textarea>
                    </div>
                  </div>
                </div>

                {/* mobile */}
                <div className={classNames('__show-on-mobile', {
                  __respectAsidePanel: navActive && !phoneCall,
                  __phoneCall: phoneCall && !navActive,
                  __bothOpen: navActive && phoneCall,
                  __nonePanel: !navActive && !phoneCall
                })}>
                  <div className="item">
                    <div className='row unit_type'>
                      <span>Unit type:<span className='requered_field'>*</span></span>

                      <SelectComponent
                        disabled={!editing}
                        options={data.edit.unit_type.sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1).map((type) => ({
                          span: type,
                          value: type
                        }))}
                        selectedOption={newUnitData.unit_type || ''}
                        onChange={(value: string) => setNewUnitData({ ...newUnitData, unit_type: value })}
                      />
                    </div>

                    <div
                      className='row unit_type'
                      style={{ marginBottom: (editing && checkOtherFieldAppliences() === 'Other') ? '10px' : '20px' }}
                    >
                      <span>Unit:<span className='requered_field'>*</span></span>

                      <SelectComponent
                        disabled={!editing}
                        options={data.edit.appliances.sort((a, b) => a.appliance.toLowerCase() > b.appliance.toLowerCase() ? 1 : -1).sort((a, b) => a.appliance !== 'Other' ? 1 : -1).map((type) => ({
                          span: type.appliance,
                          value: type.appliance_id
                        }))}
                        selectedOption={newUnitData.appliance_id || '0'}
                        onChange={(value: string) => setNewUnitData({ ...newUnitData, appliance_id: value, other_appliance: '' })}
                      />
                    </div>

                    {
                      editing && checkOtherFieldAppliences() === 'Other' &&
                      <div className="row unit_type other">
                        <span>Other Unit:<span className='requered_field'>*</span></span>
                        <input
                          className='model_number'
                          name='other_appliance'
                          type="text"
                          disabled={!editing}
                          value={newUnitData.other_appliance}
                          onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, other_appliance: value })}
                        />
                      </div>
                    }

                    <div
                      className='row unit_type'
                      style={{ marginBottom: (editing && checkOtherFieldBrand() === 'Other') ? '10px' : '20px' }}
                    >
                      <span>Brand:<span className='requered_field'>*</span></span>

                      <SelectComponent
                        disabled={!editing}
                        options={data.edit.appliance_brands.sort((a, b) => a.appliance_brand.toLowerCase() > b.appliance_brand.toLowerCase() ? 1 : -1).sort((a, b) => a.appliance_brand !== 'Other' ? 1 : -1).map((type) => ({
                          span: type.appliance_brand,
                          value: type.appliance_brand_id
                        }))}
                        selectedOption={newUnitData.appliance_brand_id || '0'}
                        onChange={(value: string) => setNewUnitData({ ...newUnitData, appliance_brand_id: value, other_brand: '' })}
                      />
                    </div>

                    {
                      editing && checkOtherFieldBrand() === 'Other' &&
                      <div className="row unit_type other">
                        <span>Other Brand:<span className='requered_field'>*</span></span>
                        <input
                          className='model_number'
                          name='other_brand'
                          type="text"
                          disabled={!editing}
                          value={newUnitData.other_brand}
                          onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, other_brand: value })}
                        />
                      </div>
                    }

                    <div className="row">
                      <span>Model Number:</span>
                      <input
                        className='model_number'
                        name='model_number'
                        type="text"
                        disabled={!editing}
                        value={newUnitData.model_number}
                        onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, model_number: value })}
                      />
                    </div>

                    <div className="row">
                      <span>Serial Number:</span>
                      <input
                        className='serial_number'
                        name='serial_number'
                        type="text"
                        disabled={!editing}
                        value={newUnitData.serial_number}
                        onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, serial_number: value })}
                      />
                    </div>

                    <div className="row">
                      <span>Description:<span className='requered_field'>*</span></span>

                      <textarea
                        className='description'
                        name='description'
                        disabled={!editing}
                        value={newUnitData.description}
                        onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, description: value })}
                      >

                      </textarea>
                    </div>
                  </div>
                </div>
              </div>
            }

            {editing ? (
              <div className="wrapper flex-container sb editing-buttons">
                <div>
                  {
                    data.permissions.technician_unit_delete &&
                    <button className="_red" onClick={() => setDeleting(true)}>Delete Unit</button>
                  }
                </div>
                <div className="buttons">
                  <button
                    className="_bordered _red"
                    onClick={() => handleCancelEdit()}
                  >
                    Cancel
                  </button>
                  <button
                    disabled={!readyToSave || checkSave()}
                    className="_bordered _green"
                    onClick={() => handleSave()}
                  >
                    Save
                  </button>
                </div>
              </div>
            ) : null}

            {/* Item share popup */}
            {deleting ? (
              <div className="item-delete-popup" onClick={() => setDeleting(false)}>

                <div className="wrapper" onClick={(e) => e.stopPropagation()}>

                  <div className="title">
                    Delete Unit
                  </div>

                  <div className="checkbox-wrapper">
                    <Checkbox contents="I understand that after deleting the unit it will not be possible to recover." value={readyToDelete} onChange={(value) => setReadyToDelete(value)} />
                  </div>

                  <div className="buttons">

                    <button
                      className="_bordered _green"
                      onClick={() => setDeleting(false)}
                    >
                      Cancel
                    </button>

                    <button
                      disabled={!readyToDelete}
                      className="_bordered _red"
                      onClick={() => handleRemoveItem()}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            ) : null}

            {
              !!imageModal &&
              <ImageModal
                navActive={navActive}
                phoneCall={phoneCall}
                setModal={setImageModal}
                modal={imageModal}
                handleDelete={handleDeleteImage}
              />
            }

            {
              mode === '' &&
              <div className="fieldset">
                <div className="row">
                  <span>Attachments:</span>

                  <button
                    className='attachments-button'
                    onClick={() => setMode('attachments_add')}
                  >
                    <Icon viewBox="0 0 60 60" icon="plus-job-tech-1" />
                  </button>
                </div>

                {
                  !!newUnitData?.attachments?.length &&
                  <div
                    className='row'
                    style={{ justifyContent: 'flex-start', gap: '20px' }}
                  >
                    {
                      newUnitData?.attachments && newUnitData?.attachments.map((item) => (
                        <div
                          className='photo-view attachments'
                          key={item.job_attachment_id}
                          onMouseEnter={() => setHover(item.job_attachment_id)}
                          onMouseLeave={() => setHover('')}
                          onClick={() => hover === item.job_attachment_id && setImageModal({ id: item.job_attachment_id, url: item.url, is_delete: false })}
                        >
                          <img
                            style={{ maxHeight: '100px', objectFit: 'cover' }}
                            alt="not found"
                            src={item.url}
                          />

                          {
                            hover === item.job_attachment_id &&
                            <div className='attachments-delete'>Open</div>
                          }
                        </div>
                      ))
                    }
                  </div>
                }
              </div>
            }
          </div> : null
      }
    </>
  )
}
