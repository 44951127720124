import React, { useEffect, useState } from 'react'
import { useRoute } from 'react-router5'
import { PDFDownloadLink } from '@react-pdf/renderer';
import Iframe from 'react-iframe';
import classNames from 'classnames';
import { HexColorPicker } from 'react-colorful';
import Draggable from 'react-draggable';

import DocumentPdf from './DocumentPdf';
import Icon from '../../components/Icon';
import SelectComponent from '../../components/Select';
import Checkbox from '../../components/Checkbox';

import { httpClientUpdate, nErrorUpdate, capitalizeFirstLetter, useOuterClick } from '../../funcs'
import { useAppSelector } from '../../store/hooks'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";
import { CompaniesProps } from './List';

import './Index.sass'

export interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: DataProps
}

interface DataProps {
  permissions: {
    template_attachment_add: boolean
    template_delete: boolean
    template_edit: boolean
  }
  edit: {
    companies: CompaniesProps[]
  }
  template: {
    content: string
    created_at: string
    created_by: string
    document: string
    template_id: string
    type: string
    updated_at: string
    updated_by: string
    company_id: string
    company_name: string
  }
}

export interface BlockProps {
  activeDrags: number,
  deltaPosition: {
    x: number
    y: number
  },
  type: string
  value: string,
  editing: boolean,
  is_bold: boolean,
  font_size: number,
  is_underline: boolean,
  is_italic: boolean,
  color: string,
  align: 'left' | 'center' | 'right',
  image: File | null,
  image_base64: string,
  image_url: null | string,
  image_id: null | string,
  width: number
  height: number
  object_fit: ObjectFit
}

type ObjectFit = 'contain' | 'cover' | 'fill' | 'none' | 'scale-down'

const objectFitOptions = ['contain', 'cover', 'fill', 'none', 'scale-down']

const initialBlock = {
  activeDrags: 0,
  deltaPosition: {
    x: 0, y: 0
  },
  type: 'Label',
  value: 'Block',
  editing: true,
  is_bold: false,
  font_size: 16,
  is_underline: false,
  is_italic: false,
  color: '#000000',
  align: 'left' as 'left' | 'center' | 'right',
  image: null,
  image_base64: '',
  image_url: null,
  image_id: null,
  width: 100,
  height: 100,
  object_fit: 'contain' as ObjectFit
}

export default function Item() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [isSaving, setIsSaving] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)

  const [editing, setEditing] = useState(false)

  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)

  const [reportData, setReportData] = useState<DataProps | null>(null)

  const [selectedCompany, setSelectedCompany] = useState('')
  const [blocks, setBlocks] = useState<BlockProps[]>([])
  const [text, setText] = useState('')
  const [openPicker, setOpenPicker] = useState(false)

  const [canvasOptions, setCanvasOptions] = useState({
    width: 590,
    height: 300
  })

  const [options, setOptions] = useState({
    document: 'Invoice',
    type: 'Template',
  })

  const [mode, setMode] = useState('')
  const [url, setUrl] = useState('')

  // Load absence data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setReadyToSave(true), [
    blocks
  ])

  useEffect(() => setReadyToSave(true), [
    text
  ])

  useEffect(() => setReadyToSave(true), [
    canvasOptions, options, selectedCompany
  ])

  // Load info function
  async function loadInfo() {
    try {
      // api/templates/88888620yb704q31eu?account_id=88888
      const { data: { data: loadData, success, error } } = await httpClientUpdate.get('/templates/' + $router.router.getState().params.templatesId, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpClientUpdateReport }
      if (success) {
        setReportData(loadData)
        if (loadData.template.type === 'text') {
          setText(JSON.parse(loadData.template.content))
        } else {
          let content = JSON.parse(loadData.template.content)
          if (content.width) {
            setCanvasOptions({
              width: content.width,
              height: content.height,
            })
            setBlocks(content.content)
          } else {
            setBlocks(JSON.parse(loadData.template.content))
          }
        }
        setSelectedCompany(loadData.template.company_id)
        setOptions({
          document: capitalizeFirstLetter(loadData.template.document),
          type: capitalizeFirstLetter(loadData.template.type),
        })
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // update document
  async function handleSave() {
    setIsSaving(true)
    try {
      if (options.type === 'Text') {
        const response = await httpClientUpdate.put(`/templates/${$router.router.getState().params.templatesId}`,
          {
            account_id: activeAccountId,
            company_id: selectedCompany,
            content: JSON.stringify(text),
            document: options.document.toLowerCase(),
            type: options.type.toLowerCase(),
          })
        if (response.data.success) {
          setIsSaving(false)
          setEditing(false)
          loadInfo()
        } else {
          setIsSaving(false)
        }
      } else {
        let saveData: BlockProps[] = []

        for (const item of blocks) {
          if (item.type === 'Image' && !item.image_url) {
            let file = item.image_base64.replace('data:image/png;base64,', '').replace('data:image/jpeg;base64,', '')

            // api/templates/attachments
            const response = await httpClientUpdate.post(`/templates/attachments`, {
              account_id: activeAccountId,
              type: 'image/jpeg',
              file,
            })
            if (response.data.success) {
              saveData.push({
                ...item,
                image_url: response.data.data.url,
                image_base64: response.data.data.template_attachment_id,
                editing: false,
              })
            }
          } else {
            saveData.push({
              ...item,
              editing: false,
            })
          }
        }

        const response = await httpClientUpdate.put(`/templates/${$router.router.getState().params.templatesId}`,
          {
            account_id: activeAccountId,
            company_id: selectedCompany,
            content: JSON.stringify({
              width: canvasOptions.width,
              height: canvasOptions.height,
              content: saveData
            }),
            document: options.document.toLowerCase(),
            type: options.type.toLowerCase(),
          })
        if (response.data.success) {
          setIsSaving(false)
          setEditing(false)
          loadInfo()
        } else {
          setIsSaving(false)
        }
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // remove document
  async function handleRemoveItem() {
    try {
      setIsSaving(true)
      // https://2022back4.artemiudintsev.com/api/notify-templates/88888601wgntio547d
      const response = await httpClientUpdate.delete(`/templates/${$router.router.getState().params.templatesId}`, {
        data: {
          account_id: activeAccountId,
        }
      })

      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('templates', {
          companyId: activeAccountId,
          localInterface: $router.router.getState().params.localInterface
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  function handleCancelEdit() {
    if (reportData) {
      setEditing(false)
      if (reportData.template.type === 'text') {
        setText(JSON.parse(reportData.template.content))
      } else {
        let content = JSON.parse(reportData.template.content)

        if (content.width) {
          setCanvasOptions({
            width: content.width,
            height: content.height,
          })
          setBlocks(content.content)
        } else {
          setBlocks(JSON.parse(reportData.template.content))
        }
      }

      setSelectedCompany(reportData.template.company_id)
      setOptions({
        document: capitalizeFirstLetter(reportData.template.document),
        type: capitalizeFirstLetter(reportData.template.type),
      })
    }
  }

  function handlePdf() {
    setBlocks(blocks.map(item => {
      return {
        ...item,
        editing: false
      }
    }))
    setMode('pdf')
  }

  function handleAddBlock(type: string) {
    let updated = blocks.map(item => {
      return {
        ...item,
        editing: false
      }
    })

    updated.push({
      ...initialBlock,
      width: type === 'Text' ? 200 : 100,
      type
    })
    setBlocks(updated)
  }

  function handleDrag(event: any, position: any, index: number) {
    const { x, y } = position

    setBlocks(blocks.map((item, idx) => {
      if (index === idx) {
        return {
          ...item,
          deltaPosition: {
            x,
            y,
          }
        }
      } else {
        return { ...item }
      }
    }))
  }

  function getValue() {
    let value = ''

    blocks.forEach(item => {
      if (item.editing) {
        value = item.value
      }
    })

    return value
  }

  function getType() {
    let type = ''

    blocks.forEach(item => {
      if (item.editing) {
        type = item.type
      }
    })

    return type
  }

  function getBase64() {
    let image_base64 = ''

    blocks.forEach(item => {
      if (item.editing) {
        if (item.image_url) {
          image_base64 = item.image_url
        } else {
          image_base64 = item.image_base64
        }
      }
    })

    return image_base64
  }

  function getWidth() {
    let width = 0

    blocks.forEach(item => {
      if (item.editing) {
        width = item.width
      }
    })

    return width
  }

  function getHeight() {
    let height = 0

    blocks.forEach(item => {
      if (item.editing) {
        height = item.height
      }
    })

    return height
  }

  function getObjectFit() {
    let object_fit = ''

    blocks.forEach(item => {
      if (item.editing) {
        object_fit = item.object_fit
      }
    })

    return object_fit
  }

  function getAlign() {
    let align = ''

    blocks.forEach(item => {
      if (item.editing) {
        align = item.align
      }
    })

    return align
  }

  function isBold() {
    let is_bold = false

    blocks.forEach(item => {
      if (item.editing) {
        is_bold = item.is_bold
      }
    })

    return is_bold
  }

  function isUnderline() {
    let is_underline = false

    blocks.forEach(item => {
      if (item.editing) {
        is_underline = item.is_underline
      }
    })

    return is_underline
  }

  function isItalic() {
    let is_italic = false

    blocks.forEach(item => {
      if (item.editing) {
        is_italic = item.is_italic
      }
    })

    return is_italic
  }

  function getFontSize() {
    let font_size = 0

    blocks.forEach(item => {
      if (item.editing) {
        font_size = item.font_size
      }
    })

    return font_size
  }

  function getColor() {
    let color = ''

    blocks.forEach(item => {
      if (item.editing) {
        color = item.color
      }
    })

    return color
  }

  function handleChangeValue(value: string) {
    setBlocks(blocks.map(item => {
      if (item.editing) {
        return {
          ...item,
          value
        }
      } else {
        return { ...item }
      }
    }))
  }

  function handleChangeWidth(width: number) {
    setBlocks(blocks.map(item => {
      if (item.editing) {
        return {
          ...item,
          width
        }
      } else {
        return { ...item }
      }
    }))
  }

  function handleChangeHeight(height: number) {
    setBlocks(blocks.map(item => {
      if (item.editing) {
        return {
          ...item,
          height
        }
      } else {
        return { ...item }
      }
    }))
  }

  function handleChangeObjectFit(object_fit: ObjectFit) {
    setBlocks(blocks.map(item => {
      if (item.editing) {
        return {
          ...item,
          object_fit
        }
      } else {
        return { ...item }
      }
    }))
  }

  function handleAddImage(file: File) {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      setBlocks(blocks.map(item => {
        if (item.editing) {
          return {
            ...item,
            image: file,
            image_base64: reader.result as string,
            image_id: null,
            image_url: null,
          }
        } else {
          return { ...item }
        }
      }))
    });

    reader.readAsDataURL(file);
  }

  function handleChangeFontSize(font_size: number) {
    setBlocks(blocks.map(item => {
      if (item.editing) {
        return {
          ...item,
          font_size
        }
      } else {
        return { ...item }
      }
    }))
  }

  function handleChangeFontSizeFromButton(name: string) {
    setBlocks(blocks.map(item => {
      if (item.editing) {
        let font_size = item.font_size
        if (name === 'minus') {
          font_size = font_size - 1
        } else {
          font_size = font_size + 1
        }
        return {
          ...item,
          font_size
        }
      } else {
        return { ...item }
      }
    }))
  }

  function handleChangeColor(color: string) {
    setBlocks(blocks.map(item => {
      if (item.editing) {
        return {
          ...item,
          color
        }
      } else {
        return { ...item }
      }
    }))
  }

  function handleBold() {
    setBlocks(blocks.map(item => {
      if (item.editing) {
        return {
          ...item,
          is_bold: !item.is_bold
        }
      } else {
        return { ...item }
      }
    }))
  }

  function handleItalic() {
    setBlocks(blocks.map(item => {
      if (item.editing) {
        return {
          ...item,
          is_italic: !item.is_italic
        }
      } else {
        return { ...item }
      }
    }))
  }

  function handleUnderline() {
    setBlocks(blocks.map(item => {
      if (item.editing) {
        return {
          ...item,
          is_underline: !item.is_underline
        }
      } else {
        return { ...item }
      }
    }))
  }

  function handleAlign(align: 'left' | 'center' | 'right') {
    setBlocks(blocks.map(item => {
      if (item.editing) {
        return {
          ...item,
          align
        }
      } else {
        return { ...item }
      }
    }))
  }

  function handleDelete() {
    setBlocks(blocks.filter(item => !item.editing))
  }

  function handleEditBlock(index: number) {
    setBlocks(blocks.map((item, idx) => {
      if (index === idx) {
        return {
          ...item,
          editing: true
        }
      } else {
        return {
          ...item,
          editing: false
        }
      }
    }))
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setOpenPicker(false)
  });

  return (
    <>
      {
        reportData &&
        <div className="Templates_Item entity-edit">
          <div className="wrapper flex-container sb">
            <h1>Template</h1>

            <div style={{ display: 'flex' }} >
              <button
                disabled={editing}
                style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
                className="_wa"
                onClick={() =>
                  $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'templates',
                    {
                      companyId: activeAccountId,
                      [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                      localInterface: $router.router.getState().params.localInterface
                    }, { reload: true })}
              >
                <Icon
                  style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                  viewBox="0 0 24 24"
                  icon="arrow-25"
                />
              </button>

              {
                !editing &&
                  reportData.permissions.template_edit ? (
                  <button className="_wa _green" onClick={() => setEditing(true)}>
                    Edit
                  </button>
                ) : null
              }
            </div>
          </div>

          {
            options.type === 'Text' &&
            <div className="contents">
              <div className="document-options">
                <div className="select-row company">
                  <span className="company">Company</span>

                  <SelectComponent
                    disabled={!editing}
                    selectedOption={selectedCompany}
                    options={reportData.edit.companies.map(item => {
                      return {
                        span: item.name,
                        value: item.company_id,
                      }
                    })}
                    onChange={(value) => setSelectedCompany(value as string)}
                  />
                </div>
              </div>

              <div className="document-options">
                <div className="select-row">
                  <span>Document</span>

                  <SelectComponent
                    selectedOption={options.document}
                    disabled={!editing}
                    options={['Invoice', 'Estimate', 'Approval'].map(item => {
                      return {
                        span: item,
                        value: item,
                      }
                    })}
                    onChange={(value) => setOptions({ ...options, document: value as string })}
                  />
                </div>

                <div className="select-row">
                  <span>Type</span>

                  <SelectComponent
                    selectedOption={options.type}
                    disabled={!editing}
                    options={['Template', 'Text'].map(item => {
                      return {
                        span: item,
                        value: item,
                      }
                    })}
                    onChange={(value) => {
                      setOptions({ ...options, type: value as string })
                      setText('')
                      setBlocks([])
                    }}
                  />
                </div>
              </div>

              <div className="document-options">
                <textarea
                  style={{ minHeight: '400px', resize: 'none' }}
                  disabled={!editing}
                  value={text}
                  onChange={(event) => setText(event.target.value)}
                ></textarea>
              </div>
            </div>
          }

          {
            options.type === 'Template' &&
            <div className="contents">
              {
                mode === '' &&
                <div className="document-options">
                  <div className="select-row company">
                    <span className="company">Company</span>

                    <SelectComponent
                      disabled={!editing}
                      selectedOption={selectedCompany}
                      options={reportData.edit.companies.map(item => {
                        return {
                          span: item.name,
                          value: item.company_id,
                        }
                      })}
                      onChange={(value) => setSelectedCompany(value as string)}
                    />
                  </div>
                </div>
              }

              {
                mode === '' &&
                <div className="document-options">
                  <div className="select-row">
                    <span>Document</span>

                    <SelectComponent
                      disabled={!editing}
                      selectedOption={options.document}
                      options={['Invoice', 'Estimate', 'Approval'].map(item => {
                        return {
                          span: item,
                          value: item,
                        }
                      })}
                      onChange={(value) => setOptions({ ...options, document: value as string })}
                    />
                  </div>

                  <div className="select-row">
                    <span>Type</span>

                    <SelectComponent
                      disabled={!editing}
                      selectedOption={options.type}
                      options={['Template', 'Text'].map(item => {
                        return {
                          span: item,
                          value: item,
                        }
                      })}
                      onChange={(value) => {
                        setOptions({ ...options, type: value as string })
                        setText('')
                        setBlocks([])
                      }}
                    />
                  </div>

                  <span className="border"></span>

                  <div className="select-row">
                    <span> Width: </span>

                    <input
                      type="number"
                      className="appearance"
                      disabled={!editing}
                      value={canvasOptions.width}
                      onChange={(event) => setCanvasOptions({ ...canvasOptions, width: +event.target.value })}
                    />
                  </div>

                  <div className="select-row">
                    <span> Height: </span>

                    <input
                      type="number"
                      className="appearance"
                      disabled={!editing}
                      value={canvasOptions.height}
                      onChange={(event) => setCanvasOptions({ ...canvasOptions, height: +event.target.value })}
                    />
                  </div>
                </div>
              }

              {
                mode === '' && editing &&
                <div className="document-options">
                  <button
                    className="_wa _green"
                    disabled={isSaving}
                    onClick={() => handleAddBlock('Label')}
                  >
                    Add Label
                  </button>

                  <button
                    className="_wa _green"
                    disabled={isSaving}
                    onClick={() => handleAddBlock('Text')}
                  >
                    Add Text
                  </button>

                  {
                    reportData.permissions.template_attachment_add &&
                    <button
                      className="_wa _green"
                      disabled={isSaving}
                      onClick={() => handleAddBlock('Image')}
                    >
                      Add Image
                    </button>
                  }

                  <button
                    className="_wa _green"
                    disabled={blocks.length === 0 || isSaving}
                    onClick={() => handlePdf()}
                  >
                    View Pdf
                  </button>
                </div>
              }

              {
                editing && mode === 'pdf' &&
                <div className="document-options">
                  <button
                    className="_wa _green"
                    disabled={blocks.length === 0}
                    onClick={() => setMode('')}
                  >
                    To Edit
                  </button>
                </div>
              }

              {
                editing &&
                !!blocks.filter(item => item.editing && item.type === 'Image').length &&
                <div className="document-options">
                  <span>Edit Block:</span>

                  <div className='add-gallary-photo'>
                    <label
                      htmlFor="filePicker"
                      className='add-image'
                      title='Upload a image'
                    >
                      {getBase64() === '' ? 'Add Image From Gallery' : 'Change Image From Gallery'}
                    </label>
                    <input
                      id="filePicker"
                      style={{ visibility: "hidden" }}
                      type="file"
                      name="myImage"
                      onChange={(event) => {
                        event.target.files && handleAddImage(event.target.files[0])
                      }}
                    />
                  </div>

                  {
                    getBase64() !== '' &&
                    <div className="image-block">
                      <img src={getBase64()} alt="Attachment" />
                    </div>
                  }

                  <span className="border"></span>

                  <div className="change-width">
                    <span style={{ marginRight: 0 }}>Width: </span>

                    <input
                      type="number"
                      className="appearance"
                      value={getWidth()}
                      onChange={(event) => handleChangeWidth(+event.target.value)}
                    />
                  </div>

                  <div className="change-width">
                    <span>Height: </span>

                    <input
                      type="number"
                      className="appearance"
                      value={getHeight()}
                      onChange={(event) => handleChangeHeight(+event.target.value)}
                    />
                  </div>

                  <span className="border"></span>

                  <SelectComponent
                    selectedOption={getObjectFit()}
                    options={objectFitOptions.map(item => {
                      return {
                        span: item,
                        value: item,
                      }
                    })}
                    onChange={(value) => handleChangeObjectFit(value as ObjectFit)}
                  />

                  <span className="border"></span>

                  <button
                    className="_wa _iconed _bordered _red"
                    onClick={() => handleDelete()}
                  >
                    Delete
                  </button>
                </div>
              }

              {
                editing &&
                !!blocks.filter(item => item.editing && (item.type === 'Label' || item.type === 'Text')).length &&
                <div className="document-options">
                  <span>Edit Block:</span>

                  {
                    getType() === 'Label' &&
                    <input
                      className="value"
                      type="text"
                      value={getValue()}
                      onChange={(event) => handleChangeValue(event.target.value)}
                    />
                  }

                  {
                    getType() === 'Text' &&
                    <textarea
                      className="text-area"
                      style={{ width: `${getWidth()}px` }}
                      value={getValue()}
                      onChange={(event) => handleChangeValue(event.target.value)}
                    ></textarea>
                  }

                  {
                    getType() === 'Text' &&

                    <div className="change-width">
                      <span>Width: </span>

                      <input
                        type="number"
                        className="appearance"
                        value={getWidth()}
                        onChange={(event) => handleChangeWidth(+event.target.value)}
                      />
                    </div>
                  }

                  <span className="border"></span>

                  <button
                    className={classNames('settings', { _active: isBold() })}
                    onClick={() => handleBold()}
                  >
                    B
                  </button>

                  <button
                    className={classNames('italic settings', { _active: isItalic() })}
                    onClick={() => handleItalic()}
                  >
                    I
                  </button>

                  <button
                    className={classNames('underline settings', { _active: isUnderline() })}
                    onClick={() => handleUnderline()}
                  >
                    U
                  </button>

                  <span className="border"></span>

                  {
                    getType() === 'Text' &&
                    <div className="align-text-change">
                      <button
                        className={classNames('align', { _active: getAlign() === 'left' })}
                        onClick={() => handleAlign('left')}
                      >
                        <Icon
                          viewBox="0 0 1024 1024"
                          icon="left-28"
                        />
                      </button>

                      <button
                        className={classNames('align', { _active: getAlign() === 'center' })}
                        onClick={() => handleAlign('center')}
                      >
                        <Icon
                          viewBox="0 0 1024 1024"
                          icon="center-1"
                        />
                      </button>

                      <button
                        className={classNames('align', { _active: getAlign() === 'right' })}
                        onClick={() => handleAlign('right')}
                      >
                        <Icon
                          viewBox="0 0 1024 1024"
                          icon="right-34"
                        />
                      </button>
                    </div>
                  }

                  {
                    getType() === 'Text' &&
                    <span className="border"></span>
                  }

                  <div className="font-size-change">
                    <div
                      className="action"
                      onClick={() => handleChangeFontSizeFromButton('minus')}
                    >
                      <Icon
                        viewBox="0 0 1024 1024"
                        icon="minus-20"
                      />
                    </div>

                    <input
                      type="number"
                      className="appearance"
                      value={getFontSize()}
                      onChange={(event) => handleChangeFontSize(+event.target.value)}
                    />

                    <div
                      className="action"
                      onClick={() => handleChangeFontSizeFromButton('plus')}
                    >
                      <Icon
                        viewBox="0 0 1024 1024"
                        icon="plus-34"
                      />
                    </div>
                  </div>

                  <span className="border"></span>

                  <div
                    className="color-block"
                    ref={contextMenuRef}
                  >
                    <div
                      style={{ background: getColor() }}
                      className="color-block-view"
                      onClick={() => setOpenPicker(true)}
                    ></div>

                    {
                      openPicker &&
                      <HexColorPicker color={getColor()} onChange={(value) => handleChangeColor(value)} />
                    }
                  </div>

                  <span className="border"></span>

                  <button
                    className="_wa _iconed _bordered _red"
                    onClick={() => handleDelete()}
                  >
                    Delete
                  </button>
                </div>
              }

              {
                mode === '' &&
                <div className="document-page-wrapper">
                  <div
                    className="document-page"
                    style={{ width: `${canvasOptions.width}px`, height: `${canvasOptions.height}px` }}
                  >
                    {
                      blocks
                        .map((item, index) => (
                          <React.Fragment key={index}>
                            {
                              item.type === 'Label' &&
                              <Draggable
                                key={index}
                                bounds="parent"
                                position={{ x: item.deltaPosition.x, y: item.deltaPosition.y }}
                                disabled={!item.editing && !editing}
                                onDrag={(event, position) => handleDrag(event, position, index)}
                              >
                                <div
                                  className={classNames('block',
                                    {
                                      editing: item.editing,
                                      is_bold: item.is_bold,
                                      is_underline: item.is_underline,
                                      is_italic: item.is_italic
                                    })}
                                  style={{ fontSize: `${item.font_size}px`, color: item.color }}
                                  onClick={() => editing && handleEditBlock(index)}
                                >
                                  {item.value}
                                </div>
                              </Draggable>
                            }

                            {
                              item.type === 'Text' &&
                              <Draggable
                                key={index}
                                bounds="parent"
                                position={{ x: item.deltaPosition.x, y: item.deltaPosition.y }}
                                disabled={!item.editing && !editing}
                                onDrag={(event, position) => handleDrag(event, position, index)}
                              >
                                <div
                                  className={classNames('block text',
                                    {
                                      editing: item.editing,
                                      is_bold: item.is_bold,
                                      is_underline: item.is_underline,
                                      is_italic: item.is_italic
                                    })}
                                  style={{
                                    fontSize: `${item.font_size}px`,
                                    color: item.color,
                                    textAlign: item.align,
                                    width: `${item.width}px`
                                  }}
                                  onClick={() => editing && handleEditBlock(index)}
                                  dangerouslySetInnerHTML={{ __html: item.value }}
                                ></div>
                              </Draggable>
                            }

                            {
                              item.type === 'Image' &&
                              <Draggable
                                key={index}
                                bounds="parent"
                                position={{ x: item.deltaPosition.x, y: item.deltaPosition.y }}
                                disabled={!item.editing && !editing}
                                onDrag={(event, position) => handleDrag(event, position, index)}
                              >
                                <div
                                  className={classNames('block image',
                                    {
                                      editing: item.editing,
                                    })}
                                  onClick={() => editing && handleEditBlock(index)}
                                >
                                  <img
                                    style={{ width: `${item.width}px`, height: `${item.height}px`, objectFit: item.object_fit }}
                                    src={item.image_url ? item.image_url : item.image_base64}
                                    alt="Attachment"
                                  />

                                  <div className="image-block"></div>
                                </div>
                              </Draggable>
                            }
                          </React.Fragment>
                        ))
                    }
                  </div>
                </div>
              }

              {
                mode === 'pdf' &&
                <div className="pdf-block">
                  <PDFDownloadLink document={<DocumentPdf data={blocks} width={canvasOptions.width} />} >
                    {({ blob, url, loading, error }) => {
                      url && setUrl(url)
                    }}
                  </PDFDownloadLink>

                  <Iframe url={url} />
                </div>
              }
            </div>
          }

          {editing ? (
            <div className="wrapper flex-container sb editing-buttons">
              <div>
                {
                  reportData.permissions.template_delete &&
                  <button className="_red" onClick={() => setDeleting(true)}>Delete Template</button>
                }
              </div>

              <div className="buttons">
                <button
                  className="_bordered _red"
                  disabled={isSaving}
                  onClick={() => handleCancelEdit()}
                >
                  Cancel
                </button>

                <button
                  disabled={!readyToSave || isSaving || selectedCompany === ''}
                  className="_bordered _green"
                  onClick={() => handleSave()}
                >
                  Save
                </button>
              </div>
            </div>
          ) : null}

          {/* Item share popup */}
          {
            deleting ? (
              <div className="item-delete-popup" onClick={() => setDeleting(false)}>

                <div className="wrapper" onClick={(e) => e.stopPropagation()}>

                  <div className="title">
                    Delete Template
                  </div>

                  <div className="checkbox-wrapper">
                    <Checkbox contents="I understand that after deleting the template it will not be possible to recover." value={readyToDelete} onChange={(value) => setReadyToDelete(value)} />
                  </div>

                  <div className="buttons">

                    <button className="_bordered _green" onClick={() => setDeleting(false)}>
                      Cancel
                    </button>

                    <button
                      disabled={!readyToDelete || isSaving}
                      className="_bordered _red"
                      onClick={() => handleRemoveItem()}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
        </div>
      }
    </>
  )
}
