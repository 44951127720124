import { useEffect, useState } from 'react'
import { useRoute } from 'react-router5'

import Icon from '../../components/Icon';

import { dateToInfoBlock, formatPhoneNumberForItemTech, httpClientUpdate, nErrorUpdate } from '../../funcs'
import { useAppSelector } from '../../store/hooks'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: LinksProps
}

interface LinksProps {
  permissions: {
    estimate_show: boolean
    invoice_show: boolean
    job_show: boolean
    payment_show: boolean
  }

  link: {
    amount: string
    created_at: string
    created_by: string
    created_by_name: string
    estimate_id: string
    estimate_name: string
    expired_date: string
    invoice_id: string
    invoice_name: string
    job_id: string
    job_name: string
    link_id: string
    note: string
    payment_date_time: string
    payment_id: string
    phone: string
    service_resource: string
    service_resource_id: string
    status: string
    statuses: {
      date_time: string
      link_id: string
      status: string
    }[]
    type: string
    updated_at: string
    updated_by: string
    updated_by_name: string
    url: string
  }
}

function Item() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone

  const [reportData, setReportData] = useState<LinksProps | null>(null)

  // Load info function
  async function loadInfo() {
    try {
      // api/links/888882131231?account_id=88888
      const { data: { data: report, success, error } } = await httpClientUpdate.get('/links/' + $router.router.getState().params.linkId, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpClientUpdateReport }
      if (success) {
        setReportData(report)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {
        reportData &&
        <div className="JobsPage_Item entity-edit">
          <div className="wrapper flex-container sb">
            <h1>Link</h1>

            <div style={{ display: 'flex' }} >
              <button
                style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
                className="_wa"
                onClick={() =>
                  $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'links',
                    {
                      companyId: activeAccountId,
                      [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                      localInterface: $router.router.getState().params.localInterface
                    }, { reload: true })}
              >
                <Icon
                  style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                  viewBox="0 0 24 24"
                  icon="arrow-25"
                />
              </button>
            </div>
          </div>

          <div className="fieldset">
            <div className="legend">Main</div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>Job:</span>

                  <input
                    type="text"
                    value={reportData.link.job_name}
                    disabled={true}
                  />
                </div>

                <div className="field">
                  <span>Document:</span>

                  <input
                    type="text"
                    value={reportData.link.invoice_name ? reportData.link.invoice_name : reportData.link.estimate_name}
                    disabled={true}
                  />
                </div>

                <div className="field">
                  <span>Status:</span>

                  <input
                    type="text"
                    style={{ color: reportData?.link?.status?.toLowerCase() === 'paid' ? '#219653' : 'inherit' }}
                    value={reportData.link.status}
                    disabled={true}
                  />
                </div>
              </div>

              <div className="__right">
                <div style={{ justifyContent: 'flex-end' }} className="field">
                  <button
                    style={{ width: 'fit-content' }}
                    className="_bordered _green"
                    onClick={() => window.open(`${window.location.origin}/link${reportData.link.url}`, "_blank", 'noopener,noreferrer')}
                  >
                    Open
                  </button>
                </div>

                <div className="field">
                  <span>Type:</span>

                  <input
                    type="text"
                    value={reportData.link.type}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="fieldset">
            <div className="legend">Info</div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>Service Resource:</span>

                  <input
                    type="text"
                    value={reportData.link.service_resource}
                    disabled={true}
                  />
                </div>

                <div className="field">
                  <span>Expired:</span>

                  <input
                    type="text"
                    value={reportData.link.expired_date}
                    disabled={true}
                  />
                </div>
              </div>

              <div className="__right">
                <div className="field">
                  <span>Phone:</span>

                  <input
                    type="text"
                    value={formatPhoneNumberForItemTech(reportData.link.phone)}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="fieldset">
            <div className="legend">Info</div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>Date Created:</span>

                  <input
                    type="text"
                    defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, reportData.link.created_at)}
                    disabled={true}
                  />
                </div>

                <div className="field">
                  <span>Created By:</span>

                  <input
                    type="text"
                    value={reportData.link.created_by_name}
                    disabled={true}
                  />
                </div>
              </div>

              <div className="__right">
                <div className="field">
                  <span>Updated Created:</span>

                  <input
                    type="text"
                    defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, reportData.link.updated_at)}
                    disabled={true}
                  />
                </div>

                <div className="field">
                  <span>Updated By:</span>

                  <input
                    type="text"
                    value={reportData.link.updated_by_name}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Item