import classNames from 'classnames'

import { NavActive } from '../../store/reducer'

interface MoreModalProps {
  navActive: NavActive
  phoneCall: boolean
  setModal: (value: boolean) => void
  unactive: boolean
  setPaymentModal: (value: boolean) => void
  setPage: (value: string) => void
  permissions: {
    technician_unit_add: boolean
    technician_estimate_add: boolean
    technician_part_add: boolean
  }
}

export default function MoreModal({
  navActive,
  phoneCall,
  setModal,
  unactive,
  setPaymentModal,
  setPage,
  permissions,
}: MoreModalProps) {
  return (
    <div
      className={classNames('more-page payment-page job-tech-customer time-tracking mobile', '__show-on-mobile',
        {
          __respectAsidePanel: navActive.is && !phoneCall,
          __phoneCall: phoneCall && !navActive.is,
          __bothOpen: navActive.is && phoneCall,
          __nonePanel: !navActive.is && !phoneCall
        }
      )}
    >
      <div className='more-menu'>
        {
          !unactive &&
          <button
            onClick={() => {
              setModal(false)
              setPaymentModal(true)
            }}
          >
            Make Payment
          </button>
        }

        {
          permissions.technician_unit_add &&
          <button
            onClick={() => {
              setModal(false)
              setPage('unit_add')
            }}
          >
            Add Unit
          </button>
        }

        {
          permissions.technician_estimate_add &&
          <button
            onClick={() => {
              setModal(false)
              setPage('estimate_add')
            }}
          >
            Add Estimate
          </button>
        }

        {
          permissions.technician_part_add &&
          <button
            onClick={() => {
              setModal(false)
              setPage('parts_add')
            }}
          >
            Add Part
          </button>
        }

        <button
          onClick={() => setModal(false)}
        >
          Close
        </button>
      </div>
    </div>
  )
}
