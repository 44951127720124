import React, { useState, useEffect } from "react";
import { BaseLink, useRoute } from "react-router5"
import qs from "qs";
import classNames from "classnames"

import Icon from "../../components/Icon"
import InputTextWithTitle from "../../components/InputTextWithTitle";

import { httpClientUpdate, nErrorUpdate } from "../../funcs";
import { useAppSelector } from "../../store/hooks"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/lists.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: JobsAppliances
}

interface WarehousesItem {
  warehouse: string
  warehouse_id: string
  editing: boolean
}
interface PermissionsProps {
  list_warehouse_show: boolean
  list_warehouse_add: boolean
  list_warehouse_edit: boolean
  list_warehouse_delete: boolean
}

interface JobsAppliances {
  list_warehouses: WarehousesItem[]

  permissions: PermissionsProps
}

function ListsPage_Warehouses() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const accessToken = useAppSelector((store) => store.accessToken)

  const [isSaving, setIsSaving] = useState(false)
  const [errorAdd, setErrorAdd] = useState(false)
  const [errorEdit, setErrorEdit] = useState(false)

  const [newItemForm, setNewItemForm] = useState({
    name: ''
  })

  const [editItemForm, setEditItemForm] = useState({
    name: ''
  })

  const [items, setItems] = useState<WarehousesItem[] | []>([])
  const [permissions, setPermissions] = useState<PermissionsProps>({
    list_warehouse_add: true,
    list_warehouse_delete: true,
    list_warehouse_edit: true,
    list_warehouse_show: true,
  })

  // Load Warehouse function
  async function loadWarehouse() {

    httpClientUpdate.defaults.headers['Authorization'] = `Bearer ${accessToken}`

    try {
      const { data: { data: warehouse, success, error } } = (await httpClientUpdate.get(`/lists/warehouses`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        },
        params: {
          account_id: activeAccountId
        }
      })) as { data: HttpClientUpdateReport }
      if (success) {
        setItems(warehouse?.list_warehouses.map(item => {
          return { ...item, editing: false }
        }))
        warehouse.permissions && setPermissions(warehouse.permissions)
        errorEdit && setErrorEdit(false)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

    } catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // edit function
  async function editWarehouses(id: string, name: string) {
    setIsSaving(true)
    try {
      const response = await httpClientUpdate.put(`/lists/warehouses/${id}`, qs.stringify({
        account_id: activeAccountId,
        name
      }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        }
      })
      if (response.data.success) {
        setIsSaving(false)
        return true
      } else {
        setIsSaving(false)
        return false
      }
    } catch (error: Error | AxiosError | unknown) {
      setIsSaving(false)
      let createdError = nErrorUpdate(error)
      if (createdError.content.errorText === "This name is already taken") {
        setErrorEdit(true)
      }
    }
  }

  // add function
  async function addWarehouses(name: string) {
    setIsSaving(true)
    try {
      const response = await httpClientUpdate.post(`/lists/warehouses`, qs.stringify({
        account_id: activeAccountId,
        name
      }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        }
      })
      if (response.data.success) {
        setIsSaving(false)
        setNewItemForm({
          ...newItemForm,
          name: ''
        })
        return loadWarehouse()
      } else {
        setIsSaving(false)
      }
    } catch (error: Error | AxiosError | unknown) {
      setIsSaving(false)
      let createdError = nErrorUpdate(error)
      if (createdError.content.errorText === "This name is already taken") {
        setErrorAdd(true)
      }
    }
  }

  // remove function
  async function removeWarehouses(id: string) {
    setIsSaving(true)
    try {
      const response = await httpClientUpdate.delete(`/lists/warehouses/${id}`, {
        data: {
          account_id: activeAccountId,
        }
      })

      if (response.data.success) {
        setIsSaving(false)
        return loadWarehouse()
      } else {
        setIsSaving(false)
      }
    } catch (error: Error | AxiosError | unknown) {
      setIsSaving(false)
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  useEffect(() => {
    loadWarehouse()

    // unmount page
    return () => {
      setItems([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAccountId])

  useEffect(() => {
    if (errorAdd && newItemForm.name) {
      setErrorAdd(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newItemForm.name])

  useEffect(() => {
    if (errorEdit && editItemForm.name) {
      setErrorEdit(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editItemForm.name])

  // Add item function
  function addItem() {

    let { name } = newItemForm

    addWarehouses(name)
  }

  // Set editing function
  function setEditing(i: number, flag: boolean) {

    let _items = [...items]
    _items = _items.map((item) => ({ ...item, editing: false }))
    _items[i].editing = flag
    setItems(_items)

    setEditItemForm({
      ...editItemForm,
      name: flag ? _items[i].warehouse : ''
    })
  }

  // Set item function
  async function setItem(i: number) {
    let _items = [...items]
    _items[i] = {
      warehouse: editItemForm.name,
      editing: false,
      warehouse_id: _items[i].warehouse_id
    }
    let update = await editWarehouses(_items[i].warehouse_id, _items[i].warehouse)
    update && setItems(_items)
  }

  // Render function
  return (
    <div className="ListsPage_Appliances">

      {/* Top navigation */}
      <div className="top-nav top-nav-list">
        <div className="top-nav-list-item">
          <BaseLink
            router={$router.router}
            routeName="lists.appliances"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.appliances" })}
          >
            <Icon icon="task-1" />
            <span>Appliances</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.brands"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.brands" })}
          >
            <Icon icon="task-1" />
            <span>Brands</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.areas"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.areas" })}
          >
            <Icon icon="task-1" />
            <span>Areas</span>
          </BaseLink>
        </div>

        <div className="top-nav-list-item">
          <BaseLink
            router={$router.router}
            routeName="lists.sources"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.sources" })}
          >
            <Icon icon="task-1" />
            <span>Sources</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.spam_filters"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.spam_filters" })}
          >
            <Icon icon="task-1" />
            <span>Spam Filters</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.campaigns"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.campaigns" })}
          >
            <Icon icon="task-1" />
            <span>Campaigns</span>
          </BaseLink>
        </div>

        <div className="top-nav-list-item">
          <BaseLink
            router={$router.router}
            routeName="lists.companies"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.companies" })}
          >
            <Icon icon="task-1" />
            <span>Companies</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.payments_sevices"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.payments_sevices" })}
          >
            <Icon icon="task-1" />
            <span>Payment Services</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.warehouses"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.warehouses" })}
          >
            <Icon icon="task-1" />
            <span>Warehouses</span>
          </BaseLink>
        </div>

        <div className="top-nav-list-item">
          <BaseLink
            router={$router.router}
            routeName="lists.services"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.services" })}
          >
            <Icon icon="task-1" />
            <span>Services</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.materials"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.materials" })}
          >
            <Icon icon="task-1" />
            <span>Materials</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.discounts"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.discounts" })}
          >
            <Icon icon="task-1" />
            <span>Discounts</span>
          </BaseLink>
        </div>

        <div className="top-nav-list-item">
          <BaseLink
            router={$router.router}
            routeName="lists.ticket_templates"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.ticket_templates" })}
          >
            <Icon icon="task-1" />
            <span>Ticket Templates</span>
          </BaseLink>
        </div>
      </div>

      {/* Contents */}
      <div className="wrapper">

        <div className="flex-container sb header">
          <h1>List of Warehouses</h1>

          {
            (errorAdd || errorEdit) &&
            <span style={{ color: '#FF0000' }}>This name is already taken</span>
          }

          {
            permissions.list_warehouse_add &&
            <form onSubmit={(e) => { e.preventDefault(); addItem() }}>
              <InputTextWithTitle
                width={150}
                label='Name'
                value={newItemForm.name}
                setValue={(value) => setNewItemForm({ ...newItemForm, name: value })}
              />

              <button
                className="_wa _green"
                disabled={!permissions.list_warehouse_add || newItemForm.name.length < 1 || isSaving || errorAdd}
              >
                Add
              </button>
            </form>
          }
        </div>

        {/* Table */}
        {!!items.length && permissions.list_warehouse_show &&
          < table className="table">
            <thead>
              <tr>
                <th style={{ width: '100%' }}>Name</th>
                <th></th>
                <th></th>
              </tr>
            </thead>

            {items.map((item, i) => (<React.Fragment key={i}>
              {item.editing ? (
                <tr>
                  <td>
                    <div className="editing-form">
                      <input
                        className="_zeroed"
                        type="text"
                        disabled={!permissions.list_warehouse_edit}
                        defaultValue={editItemForm.name}
                        onChange={({ target: { value } }) => setEditItemForm({ ...editItemForm, name: value })}
                      />

                      <button
                        className="_zeroed _iconed _red"
                        onClick={() => setEditing(i, false)}
                      >
                        <Icon icon="pencil-16" />
                      </button>
                    </div>
                  </td>
                  <td>
                    <button
                      className="_zeroed _iconed _green"
                      disabled={isSaving || errorEdit}
                      onClick={() => setItem(i)}
                    >
                      <Icon icon="check-mark-1" />
                    </button>
                  </td>
                  <td>
                    <button
                      className="_zeroed _iconed _red"
                      disabled={!permissions.list_warehouse_delete || isSaving}
                      onClick={() => removeWarehouses(item.warehouse_id)}
                    >
                      <Icon icon="x-mark-1" />
                    </button>
                  </td>
                </tr>
              ) : (
                <tr key={i}>
                  <td>{item.warehouse}</td>
                  <td>
                    <button
                      className="_zeroed _iconed _blue"
                      disabled={!permissions.list_warehouse_edit}
                      onClick={() => setEditing(i, true)}
                    >
                      <Icon icon="pencil-14" />
                    </button>
                  </td>
                  <td>
                    <button
                      className="_zeroed _iconed _red"
                      disabled={!permissions.list_warehouse_delete || isSaving}
                      onClick={() => removeWarehouses(item.warehouse_id)}
                    >
                      <Icon icon="x-mark-1" />
                    </button>
                  </td>
                </tr>
              )}
            </React.Fragment>))}
          </table>}
      </div>
    </div >
  )
}

export default ListsPage_Warehouses
