import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';

import RobotoRegular from '../../fonts/Roboto.ttf'
import RobotoBold from '../../fonts/Roboto-Bold.ttf'
import RobotoItalic from '../../fonts/Roboto-Italic.ttf'
import RobotoBoldItalic from '../../fonts/Roboto-BoldItalic.ttf'

import { BlockProps } from './Index';

const styles = StyleSheet.create({
  page: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: '#fff',
    padding: '10px',
    position: 'relative',
    fontFamily: 'Roboto',
  },
});

interface DocumentPdfProps {
  data: BlockProps[]
  width: number
}

export default function DocumentPdf({
  data,
  width
}: DocumentPdfProps) {

  Font.register({
    family: 'Roboto',
    fonts: [
      {
        src: RobotoRegular,
        fontWeight: 400,
      },
      {
        src: RobotoBold,
        fontWeight: 700,
      },
      {
        src: RobotoItalic,
        fontStyle: 'italic',
      },
      {
        src: RobotoBoldItalic,
        fontWeight: 700,
        fontStyle: 'italic'
      },
    ]
  })

  function getPosition(num: number) {
    let result = 0

    result = (590 * num) / width

    return Number(result)
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {
          data
            .filter(item => item.type === 'Label')
            .map((item, index) => (
              <View
                key={index}
                style={{ position: 'absolute', left: `${getPosition(item.deltaPosition.x)}px`, top: `${item.deltaPosition.y}px` }}
              >
                <Text
                  style={{
                    fontSize: `${item.font_size}px`,
                    color: item.color,
                    fontWeight: item.is_bold ? 'bold' : 'normal',
                    fontStyle: item.is_italic ? 'italic' : 'normal',
                    textDecoration: item.is_underline ? 'underline' : 'none',
                  }}
                > {item.value} </Text>
              </View>
            ))
        }

        {
          data
            .filter(item => item.type === 'Text')
            .map((item, index) => (
              <View
                key={index}
                style={{
                  position: 'absolute',
                  left: `${getPosition(item.deltaPosition.x)}px`,
                  top: `${item.deltaPosition.y}px`,
                }}
              >
                {
                  item.value.split('\n').map(item_value => (
                    <Text
                      style={{
                        textAlign: item.align,
                        width: item.width,
                        fontSize: `${item.font_size}px`,
                        color: item.color,
                        fontWeight: item.is_bold ? 'bold' : 'normal',
                        fontStyle: item.is_italic ? 'italic' : 'normal',
                        textDecoration: item.is_underline ? 'underline' : 'none'
                      }}
                    > {item_value} </Text>
                  ))
                }
              </View>
            ))
        }

        {
          data
            .filter(item => item.type === 'Image')
            .map((item, index) => (
              <View
                key={index}
                style={{
                  position: 'absolute',
                  left: `${getPosition(item.deltaPosition.x)}px`,
                  top: `${item.deltaPosition.y}px`,
                }}
              >
                <Image
                  style={{
                    width: `${item.width}px`,
                    height: `${item.height}px`,
                    objectFit: item.object_fit,
                  }}
                  src={item.image_url ? item.image_url : item.image_base64}
                ></Image>
              </View>
            ))
        }
      </Page>
    </Document>
  )
}
