import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import qs from "qs";
import classNames from "classnames"
import { useCookies } from "react-cookie";

import Icon from "../../components/Icon"
import ReportFilters from "../../components/reports/Filters"
import ReportTableControls from "../../components/reports/TableControls"
import ReportTableField from "../../components/reports/TableField"

import { httpClientUpdate, nErrorUpdate, useOuterClick } from "../../funcs";
import { useAppSelector } from '../../store/hooks'

import ServiceResource from "../../models/ServiceResource"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/report-list.sass"

export interface HttpServiceResourcesListReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: ServiceResourcesReport
}

type FilterWord = 'area' | 'time_zone' | 'active' | 'mentor'

interface ServiceResourcesReport {
  interface: {
    filter_words: {
      area: string[],
      time_zone: string[],
      active: string[],
      mentor: string[],
    },

    max_pages: number,
    rows_start: number,
    rows_end: number,
    rows_all: number,
  },

  permissions: {
    service_resource_add: boolean
    service_resource_delete: boolean
    service_resource_edit: boolean
    service_resource_report_show: boolean
    service_resource_show: boolean
  },

  edit: {
    area: {
      area: string
      area_id: string
      area_time_zone: string
    }[]
    users: {
      time_zone_id: number
      user: string
      user_id: string
    }[]
    mentors: {
      service_resource: string
      user_name: string
      user_id: string
    }[]
  }

  service_resources: ServiceResource[]
}

function ServiceResourcesPage_List({ updated }: { updated: number }) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const accessToken = useAppSelector((store) => store.accessToken)
  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)

  const [cookies, setCookie] = useCookies();

  const cookiesSetting = cookies.settings ? cookies.settings : null

  const [$updater, $setUpdater] = useState<any>(Math.random())

  const [searchButtonActive, setSearchButtonActive] = useState(false)

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [reportData, setReportData] = useState<ServiceResourcesReport | null>(null)
  const [localInterface, setLocalInterface] = useState({
    search: '',
    page: 1,
    filter_words: cookiesSetting && cookiesSetting?.service_resources?.filter_field ? {
      area: [] as string[],
      time_zone: [] as string[],
      active: [] as string[],
      mentor: [] as string[],
      ...cookiesSetting.service_resources.filter_field,
    } : {
      area: [] as string[],
      time_zone: [] as string[],
      active: [] as string[],
      mentor: [] as string[],
    },
    sort: {
      field: cookiesSetting && cookiesSetting?.service_resources?.sort_field ? cookiesSetting.service_resources.sort_field : '',
      direction: cookiesSetting && cookiesSetting?.service_resources?.sort_type ? cookiesSetting.service_resources.sort_type : 'up' as 'down' | 'up'
    },
    sortFields: [{
      span: 'Name',
      value: 'nickname'
    }, {
      span: 'Name Code',
      value: 'code'
    }, {
      span: 'User',
      value: 'user'
    }, {
      span: 'Mentor',
      value: 'mentor'
    }, {
      span: 'Area',
      value: 'area'
    }, {
      span: 'Time Zone',
      value: 'area_time_zone'
    }, {
      span: 'Active',
      value: 'active'
    }, {
      span: 'Priority',
      value: 'priority'
    }, {
      span: '',
      value: ''
    }]
  })

  useEffect(() => {
    updated && setLocalInterface({
      ...localInterface,
      search: '',
      page: 1,
      filter_words: cookiesSetting && cookiesSetting?.service_resources?.filter_field ? {
        area: [] as string[],
        time_zone: [] as string[],
        active: [] as string[],
        mentor: [] as string[],
        ...cookiesSetting.service_resources.filter_field,
      } : {
        area: [] as string[],
        time_zone: [] as string[],
        active: [] as string[],
        mentor: [] as string[],
      },
      sort: {
        field: cookiesSetting && cookiesSetting?.service_resources?.sort_field ? cookiesSetting.service_resources.sort_field : '',
        direction: cookiesSetting && cookiesSetting?.service_resources?.sort_type ? cookiesSetting.service_resources.sort_type : 'up' as 'down' | 'up'
      },
    })
    updated && $setUpdater(updated)
    updated && setSearchButtonActive(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  // Load serviceResources function
  async function loadServiceResources() {

    httpClientUpdate.defaults.headers['Authorization'] = `Bearer ${accessToken}`

    let activeFilters: any = { ...localInterface.filter_words }
    Object.keys(localInterface.filter_words).forEach((key: any) => !activeFilters[key].length && delete activeFilters[key])

    let filterParams = {}

    if ($router.router.getState().params) {
      if ($router.router.getState().params.localInterface) {
        filterParams = {
          page: $router.router.getState().params.localInterface.page,
          sort_field: $router.router.getState().params.localInterface.sort.field ? $router.router.getState().params.localInterface.sort.field : null,
          sort_type: $router.router.getState().params.localInterface.sort.direction === 'up' ? 'asc' : 'desc',
          ...($router.router.getState().params.localInterface.search ? { search: $router.router.getState().params.localInterface.search } : {})
        }
      } else {
        filterParams = {
          page: localInterface.page,
          sort_field: localInterface.sort.field ? localInterface.sort.field : null,
          sort_type: localInterface.sort.direction === 'up' ? 'asc' : 'desc',
          ...(localInterface.search ? { search: localInterface.search } : {})
        }

        setCookie('settings', { ...cookiesSetting, service_resources: { filter_field: activeFilters, sort_field: localInterface.sort.field, sort_type: localInterface.sort.direction, limit_rows: cookiesSetting && cookiesSetting?.service_resources?.limit_rows ? cookiesSetting?.service_resources.limit_rows : reportsMaxRows } })
      }
    }

    try {
      const { data: { data: serviceResources, success, error } } = (await httpClientUpdate.post('/serviceresources/report', qs.stringify({
        account_id: activeAccountId,
        limit_rows: cookiesSetting && cookiesSetting?.service_resources?.limit_rows ? cookiesSetting?.service_resources?.limit_rows : reportsMaxRows,
        ...filterParams,
        filters: activeFilters,
      }, { skipNulls: true }))) as { data: HttpServiceResourcesListReport }
      if (success) {
        if (serviceResources.permissions.service_resource_report_show) {
          setReportData({
            ...serviceResources,
            interface: {
              ...serviceResources.interface,
              filter_words: {
                ...localInterface.filter_words,
                ...serviceResources.interface.filter_words,
              }
            },
            service_resources: serviceResources.service_resources.map(item => {
              let time_zone = serviceResources.edit.area.filter(areaItem => areaItem.area_id === item.area_id)[0].area_time_zone

              return {
                ...item, time_zone
              }
            })
          })
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => {
        setSearchButtonActive(false)
      }, 100)
    }
    catch (error: Error | AxiosError | unknown) {
      setCookie('settings', { ...cookiesSetting, service_resources: {} })

      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load clients on page mount
  useEffect(() => {
    loadServiceResources()

    // unmount page
    return () => {
      setReportData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    $updater,
    localInterface.page,
  ])

  useEffect(() => {
    if (!reportData) return
    setSearchButtonActive(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.search])

  // Update settings from back page
  useEffect(() => {
    if ($router.router.getState().params.localInterface) {
      setLocalInterface({
        ...$router.router.getState().params.localInterface
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$router.router.getState().params])

  // Is filter selected function
  function isFilterSelected(field: FilterWord, value: string) {

    return localInterface.filter_words[field].includes(value)
  }

  function isAllFiltersSelected(field: FilterWord) {
    return localInterface.filter_words[field].length === 0
  }

  function switchFilter(field: FilterWord, value: string | number, toggle: boolean) {

    let _arr = [...localInterface.filter_words[field]]

    if (toggle && value === "All") { _arr = [] }

    else if (!toggle) {

      while (true) {
        let i = _arr.findIndex((filter) => filter === value)
        if (i === -1) break
        _arr.splice(i, 1)
      }
    }

    else {

      if (reportData?.interface.filter_words[field].every((option) => _arr.concat([value as string]).includes(option)))
        _arr = []

      else if (_arr.findIndex((filter) => filter === value) === -1)
        _arr.push(value as string)
    }

    setLocalInterface({ ...localInterface, filter_words: { ...localInterface.filter_words, [field]: _arr } })
  }

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  function getMentorName(id: string) {
    let name = ''

    reportData && reportData.edit.mentors.forEach(item => {
      if (item.user_id === id) {
        name = item.service_resource ? item.service_resource : item.user_name
      }
    })

    return name
  }

  function handleSortDirectionName(value: string) {
    if (localInterface.sort.field === 'nickname') {
      setLocalInterface({ ...localInterface, sort: { field: 'code', direction: localInterface.sort.direction } })
    } else {
      setLocalInterface({ ...localInterface, sort: { field: 'nickname', direction: value } })
    }
  }

  // Render function
  return (<>
    {reportData && reportData.permissions.service_resource_report_show ? (
      <div className="CallCenter_ServiceResourcesPage_List" >

        <div className="page-header">
          <h1>Service Resources</h1>
        </div>

        { /* List filters */}
        <ReportFilters
          onSearchInputChange={(value) => setLocalInterface({ ...localInterface, search: value })}
          searchInput={localInterface.search}
          searchButtonActive={searchButtonActive}

          onUpdate={() => $setUpdater(Math.random())}

          phoneCall={phoneCall}
          navActive={navActive.is}
        />

        {/* Table controls */}
        <ReportTableControls
          zIndex={5}

          cookie_reportsMaxRows={cookiesSetting?.service_resources?.limit_rows}

          onMaxRowsChange={(value) => {
            setCookie('settings', { ...cookiesSetting, service_resources: { ...cookiesSetting.service_resources, limit_rows: value } })
            $setUpdater(Math.random())
          }}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}
          onPagesStart={() => setLocalInterface({ ...localInterface, page: 1 })}
          onPrevPage={() => setLocalInterface({ ...localInterface, page: localInterface.page - 1 })}
          onNextPage={() => setLocalInterface({ ...localInterface, page: localInterface.page + 1 })}
          onPagesEnd={() => setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
          onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
          onSortFire={() => $setUpdater(Math.random())}

          addButton={
            reportData.permissions.service_resource_add ?
              (
                <div className="add-button-wrapper">
                  <button
                    className="_iconed _rounded add-button"
                    onClick={() => $router.router.navigate('service_resources.new', {
                      companyId: activeAccountId,
                      localInterface: localInterface,
                    }, { reload: true })}
                  >
                    <Icon icon="plus-thin" />
                    <span>Add Service Resource</span>
                    <Icon icon="construction-3" />
                  </button>
                </div>
              ) : <></>
          }
        />

        <div className="contents">

          {/* Wide desktop table */}
          <table className={classNames('table', '__show-on-wide',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <thead>
              <tr>
                <ReportTableField
                  contents={(<span>Name</span>)}

                  sortDirection={localInterface.sort.field === 'nickname' || localInterface.sort.field === 'code' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => handleSortDirectionName(value)}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>User</span>)}

                  sortDirection={localInterface.sort.field === 'user' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'user', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Mentor</span>)}

                  allFiltersSelected={isAllFiltersSelected("mentor")}
                  onAllFiltersChange={(value) => switchFilter("mentor", "All", value)}

                  filterWords={reportData?.interface.filter_words.mentor.map((filterWord) => ({
                    word: filterWord,
                    word_name: getMentorName(filterWord),
                    selected: isFilterSelected("mentor", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("mentor", value.word, value.selected)}

                  sortDirection={localInterface.sort.field === 'mentor' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'mentor', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Area</span>)}

                  allFiltersSelected={isAllFiltersSelected("area")}
                  onAllFiltersChange={(value) => switchFilter("area", "All", value)}

                  filterWords={reportData.interface.filter_words.area.map((filterWord) => ({
                    word: filterWord,
                    selected: isFilterSelected("area", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("area", value.word, value.selected)}

                  sortDirection={localInterface.sort.field === 'area' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'area', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Time Zone</span>)}

                  allFiltersSelected={isAllFiltersSelected("time_zone")}
                  onAllFiltersChange={(value) => switchFilter("time_zone", "All", value)}

                  filterWords={reportData.interface.filter_words.time_zone.map((filterWord) => ({
                    word: filterWord,
                    selected: isFilterSelected("time_zone", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("time_zone", value.word, value.selected)}

                  sortDirection={localInterface.sort.field === 'area_time_zone' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'area_time_zone', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Active</span>)}

                  allFiltersSelected={isAllFiltersSelected("active")}
                  onAllFiltersChange={(value) => switchFilter("active", "All", value)}

                  filterWords={reportData.interface.filter_words.active.map((filterWord) => ({
                    word: filterWord ? 'Active' : 'Inactive',
                    selected: isFilterSelected("active", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("active", value.word === 'Active' ? 1 : 0, value.selected)}

                  sortDirection={localInterface.sort.field === 'active' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'active', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Priority</span>)}

                  sortDirection={localInterface.sort.field === 'priority' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'priority', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
              </tr>
            </thead>

            {reportData.service_resources.map((serviceResource, i) => (
              <tr
                key={i}
                style={{ cursor: reportData.permissions.service_resource_show && serviceResource.service_resource_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  reportData.permissions.service_resource_show &&
                  serviceResource.service_resource_id &&
                  $router.router.navigate('service_resources.item', {
                    companyId: activeAccountId,
                    serviceResourceId: serviceResource.service_resource_id,
                    localInterface: localInterface,
                  }, { reload: true })}
                onContextMenu={(event) =>
                  reportData.permissions.service_resource_show &&
                  serviceResource.service_resource_id &&
                  handleChangeMenu(event, serviceResource.service_resource_id, 'service_resources', i)}
              >
                <td
                  className={classNames({
                    'red-text': !serviceResource.active,
                  })}
                >{serviceResource.code ? `${serviceResource.nickname} (${serviceResource.code})` : serviceResource.nickname}</td>
                <td>{serviceResource.user}</td>
                <td>{serviceResource.mentor}</td>
                <td>{serviceResource.area}</td>
                <td>{serviceResource.time_zone}</td>
                <td className={classNames({
                  'red-text': !serviceResource.active,
                  'green-text': !!serviceResource.active,
                })}>
                  {!!serviceResource.active ? 'Active' : 'Inactive'}
                  {
                    reportData.permissions.service_resource_show &&
                    showContext === `service_resources_${serviceResource.service_resource_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('service_resources.item', {
                            companyId: activeAccountId,
                            serviceResourceId: serviceResource.service_resource_id,
                            localInterface: localInterface,
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/service_resources/${serviceResource.service_resource_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
                <td> {serviceResource.priority !== 0 ? serviceResource.priority : ''} </td>
              </tr>
            ))}
          </table>

          {/* Medium screen table */}
          <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <tr></tr>
            {reportData.service_resources.map((serviceResource, i) => (
              <tr
                key={i}
                style={{ cursor: reportData.permissions.service_resource_show && serviceResource.service_resource_id ? 'pointer' : 'inherit', position: 'relative' }}
                onClick={() =>
                  reportData.permissions.service_resource_show &&
                  serviceResource.service_resource_id &&
                  $router.router.navigate('service_resources.item', {
                    companyId: activeAccountId,
                    serviceResourceId: serviceResource.service_resource_id,
                    localInterface: localInterface,
                  }, { reload: true })}
                onContextMenu={(event) =>
                  reportData.permissions.service_resource_show &&
                  serviceResource.service_resource_id &&
                  handleChangeMenu(event, serviceResource.service_resource_id, 'service_resources', i)}
              >
                <td>
                  <div
                    className={classNames({
                      'red-text': !serviceResource.active,
                    })}
                  >
                    {serviceResource.code ? `${serviceResource.nickname} (${serviceResource.code})` : serviceResource.nickname}
                  </div>
                  <div>{serviceResource.user}</div>
                </td>
                <td> {serviceResource.mentor} </td>
                <td>
                  <div>{serviceResource.area}</div>
                  <div>{serviceResource.time_zone}</div>
                </td>
                <td>
                  <div className={classNames({
                    'red-text': !serviceResource.active,
                    'green-text': !!serviceResource.active,
                  })}>{!!serviceResource.active ? 'Active' : 'Inactive'}</div>
                  <div> {serviceResource.priority !== 0 ? serviceResource.priority : ''} </div>
                  {
                    reportData.permissions.service_resource_show &&
                    showContext === `service_resources_${serviceResource.service_resource_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('service_resources.item', {
                            companyId: activeAccountId,
                            serviceResourceId: serviceResource.service_resource_id,
                            localInterface: localInterface,
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/service_resources/${serviceResource.service_resource_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          {/* Mobile table items */}
          <div className={classNames('mobile-table-items', '__show-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>

            {reportData.service_resources.map((serviceResource, i: number) => (
              <div
                className="item"
                key={i}
                onClick={() =>
                  reportData.permissions.service_resource_show &&
                  serviceResource.service_resource_id &&
                  $router.router.navigate('service_resources.item', {
                    companyId: activeAccountId,
                    serviceResourceId: serviceResource.service_resource_id,
                    localInterface: localInterface,
                  }, { reload: true })}>

                <div className="__top">

                  <div className="__left">
                    <div
                      className={classNames({
                        'red-text': !serviceResource.active,
                      })}
                    >
                      <b>{serviceResource.code ? `${serviceResource.nickname} (${serviceResource.code})` : serviceResource.nickname}</b>
                    </div>
                    <div>
                      {serviceResource.user}
                    </div>
                    <div>
                      Mentor: {serviceResource.mentor}
                    </div>
                  </div>

                  <div className="__right small">
                    <div className={classNames({
                      'red-text': !serviceResource.active,
                      'green-text': !!serviceResource.active,
                    })}>
                      {!!serviceResource.active ? 'Active' : 'Inactive'}
                    </div>
                    <div> {serviceResource.priority !== 0 ? serviceResource.priority : ''} </div>
                  </div>
                </div>

                <div className="__bottom">
                  <div className="__left">
                  </div>

                  <div className="__right small">
                    <div>
                      <b>{serviceResource.area}</b>
                    </div>
                    <div>
                      <b>{serviceResource.time_zone}</b>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Table controls */}
        <ReportTableControls
          isUnder={true}

          cookie_reportsMaxRows={cookiesSetting?.service_resources?.limit_rows}

          onMaxRowsChange={(value) => {
            setCookie('settings', { ...cookiesSetting, service_resources: { ...cookiesSetting.service_resources, limit_rows: value } })
            $setUpdater(Math.random())
          }}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}
          onPagesStart={() => setLocalInterface({ ...localInterface, page: 1 })}
          onPrevPage={() => setLocalInterface({ ...localInterface, page: localInterface.page - 1 })}
          onNextPage={() => setLocalInterface({ ...localInterface, page: localInterface.page + 1 })}
          onPagesEnd={() => setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
          onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
          onSortFire={() => $setUpdater(Math.random())}
        />
      </div>
    ) : null}
  </>)
}

export default ServiceResourcesPage_List
