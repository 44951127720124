import { useState, useEffect } from 'react'
import { useRoute } from "react-router5"
import moment, { Moment } from 'moment'
import classNames from 'classnames'
import ReactInputMask from 'react-input-mask'

import Icon from '../../components/Icon'
import PdfView from './PdfView'

import { useAppSelector } from "../../store/hooks"
import { formatPhoneNumberToServerString, httpClientUpdate, nErrorUpdate } from '../../funcs'

import { NewMaterialProps } from './AddMaterials'
import { BlockProps } from '../Templates/Index'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

import './LineItems.sass'

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: ReportProps
}

interface ReportProps {
  invoice: InvoiceProps
  permissions: {
    technician_invoice_delete: boolean
    technician_invoice_edit: boolean
  }
  edit: {
    invoice_status: {
      invoice_status: string
      invoice_status_id: number
    }[]
  }
  templates: {
    content: string
    type: string
  }[]
}

interface InvoiceProps {
  contact_id: string
  estimate_id: string
  invoice_id: string
  job_id: string
  name: string
  status: string
  status_id: number
  total: number
  paid: number

  comment: string
  service_resource_code: number
  service_resource_nickname: string
  updated_by: string

  deposit_amount: string
  deposit_due_date: string

  items: ItemsProps[]
}

interface ItemsProps {
  description: string
  estimate_id: string
  estimate_item_id: string
  name: string
  price: string
  quantity: number
  sub_type: string
  type: string
  units: {
    unit_id: string
  }[]
}

interface InvoiceEditProps {
  switchPage: (name: string) => void
  id: string
  job_id: string
  service_resource_id: string
}

export interface DataProps {
  services: { name: string, comment: string, amount: number, quantity: number, unit_id: string[], is_save: boolean, id: string }[]
  discounts: { name: string, comment: string, amount: number, type: string, is_save: boolean, id: string }[]
  materials: NewMaterialProps[]
  deposite: { type: string, amount: number, date: Moment | Date | string }
  note: string
  name?: string
  status?: string
  paid?: number
  total?: number
}

export interface TemplateProps {
  width: number
  height: number
  type: string,
  blocks: BlockProps[]
}

export default function InvoiceEdit({
  switchPage,
  id,
  job_id,
  service_resource_id,
}: InvoiceEditProps) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [page, setPage] = useState('')

  const [data, setData] = useState<DataProps>({
    services: [],
    materials: [],
    discounts: [],
    deposite: { type: 'amount', amount: 0.00, date: moment() },
    note: '',
    name: '',
  })

  const [pageShow, setPageShow] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)

  const [template, setTemplate] = useState({
    width: 590,
    height: 300,
    type: 'template',
    blocks: []
  })

  const [sendPaymentLinkModal, setSendPaymentLinkModal] = useState(false)
  const [sendPayment, setSendPayment] = useState<{ count: number, note: string, phone: string }>({
    count: 0,
    phone: '1',
    note: '',
  })

  const [sendDocument, setSendDocument] = useState({
    is_open: false,
    phone: ''
  })

  async function handleSendApproveLink(sign: string, document: string) {
    try {
      // api/technician/approvals
      const response = await httpClientUpdate.post(`/technician/approvals`, {
        account_id: activeAccountId,
        url_sign: sign,
        url_signed_document: document,
        job_id: job_id,
        service_resource_id: service_resource_id,
        invoice_id: id,
      })

      if (response.data.success) {
        switchPage('main')
      }
    } catch (error) { }
  }

  async function loadInvoices() {
    try {
      const { data: { data: invoiceData, success, error } } = await httpClientUpdate.get('/technician/invoices/' + id, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpClientUpdateReport }
      if (success) {
        let services: { name: string, comment: string, amount: number, quantity: number, unit_id: string[], is_save: boolean, id: string }[] = []
        let materials: NewMaterialProps[] = []
        let discounts: { name: string, comment: string, amount: number, type: string, is_save: boolean, id: string }[] = []

        let paid = invoiceData.invoice.paid ? invoiceData.invoice.paid : 0
        let total = invoiceData.invoice.total ? invoiceData.invoice.total : 0

        invoiceData.invoice.items.forEach(item => {
          if (item.type === 'service') {
            services.push({
              name: item.name,
              comment: item.description,
              amount: Number(item.price),
              quantity: item.quantity,
              unit_id: item.units.map(item => item.unit_id),
              is_save: true,
              id: '',
            })
          } else if (item.type === 'material') {
            materials.push({
              name: item.name,
              description: item.description,
              part_number: item.quantity,
              price_per_unit: Number(item.price),
              quantity: item.quantity,
              unit_id: item.units.map(item => item.unit_id),
              taxable: false,
              is_save_price_book: true,
              id: '',
            })
          } else if (item.type === 'discount') {
            discounts.push({
              name: item.name,
              comment: item.description,
              amount: Number(item.price),
              type: item.sub_type ? item.sub_type : 'amount',
              is_save: true,
              id: ''
            })
          }
        })
        setData({
          ...data,
          services: services,
          materials: materials,
          discounts: discounts,
          deposite: {
            ...data.deposite,
            amount: Number(invoiceData.invoice.deposit_amount),
            date: moment(invoiceData.invoice.deposit_due_date)
          },
          note: invoiceData.invoice.comment,
          name: invoiceData.invoice.name,
          status: invoiceData.invoice.status,
          paid,
          total,
        })

        setSendPayment({
          count: total - paid,
          phone: '1',
          note: '',
        })
        setSendDocument({
          is_open: false,
          phone: '',
        })
        setPageShow(true)

        if (invoiceData.templates) {
          invoiceData.templates.forEach(item => {
            if (item.type === 'template') {
              let content = JSON.parse(invoiceData.templates[0].content)

              if (content.width) {
                setTemplate({
                  width: content.width,
                  height: content.height,
                  type: 'template',
                  blocks: content.content,
                })
              } else {
                setTemplate({
                  width: 590,
                  height: 400,
                  type: 'template',
                  blocks: content,
                })
              }
            }
          })
        }

      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function handleCanceledStatus() {
    try {
      // /api/technician/estimates/{ESTIMATE_ID}/cancel-restore
      const response = await httpClientUpdate.post(`/technician/invoices/${id}/cancel-restore`, {
        account_id: activeAccountId,
        type: data.status?.toLocaleLowerCase() === 'canceled' ? 'restore' : 'cancel',
      })

      if (response.data.success) {
        loadInvoices()
        setShowCancelModal(false)
      }
    } catch (eroor) { }
  }

  async function handlePaymentLink() {
    try {
      // /api/links/payments
      const response = await httpClientUpdate.post(`/links/payments`, {
        account_id: activeAccountId,
        job_id: job_id,
        service_resource_id: service_resource_id,
        invoice_id: id,
        amount: sendPayment.count,
        phone: formatPhoneNumberToServerString(sendPayment.phone),
        note: sendPayment.note,
      })

      if (response.data.success) {
        setSendPaymentLinkModal(false)
        setSendPayment({
          count: 0,
          phone: '1',
          note: '',
        })
      }
    } catch (error) { }
  }

  async function handleSendDocument() {
    try {
      // /api/links/invoices
      const response = await httpClientUpdate.post(`/links/invoices`, {
        account_id: activeAccountId,
        job_id: job_id,
        service_resource_id: service_resource_id,
        invoice_id: id
      })

      if (response.data.success) {
        setSendDocument({ is_open: false, phone: '' })
        loadInvoices()
      }
    } catch (eroor) { }
  }

  useEffect(() => {
    loadInvoices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function getTotalWithDiscount() {
    let total = 0
    let discount = 0

    data.services.forEach(item => {
      total = total + Number(item.amount * item.quantity)
    })

    data.materials.forEach(item => {
      total = total + (Number(item.price_per_unit) * Number(item.quantity))
    })

    data.discounts.forEach(item => {
      discount = discount + Number(item.amount)
    })


    return `$${(total - discount).toFixed(2)}`
  }

  function getSubTotal() {
    let total = 0

    data.services.forEach(item => {
      total = total + Number(item.amount * item.quantity)
    })

    data.materials.forEach(item => {
      total = total + (Number(item.price_per_unit) * Number(item.quantity))
    })

    return `$${total.toFixed(2)}`
  }

  function handleFocus(event: any) {
    const target = event.currentTarget;

    target.type = 'text';
    target.setSelectionRange(target.value.length, target.value.length);
    target.type = 'number';
    target && target.focus()
  }

  function handleChangeItem(event: any) {
    let price = event.target.value

    let numberWithoutDot = price.split('.')

    if (Number(price) >= 0) {
      if (numberWithoutDot[1] && numberWithoutDot[1].toString().length > 2) {
        let newNumber = `${numberWithoutDot[0]}${numberWithoutDot[1].toString()[0]}.${numberWithoutDot[1].toString()[1]}${price[price.length - 1]}`
        setSendPayment({
          ...sendPayment,
          count: Number(newNumber)
        })
      } else {
        let newNumber = price

        setSendPayment({
          ...sendPayment,
          count: Number(newNumber)
        })
      }
    }
  }

  function handleChangeItemBackspace(event: any) {
    if (event.keyCode === 8) {
      event.preventDefault()
      let price = event.target.value
      let numberWithoutDot = `${price}`.split('.')
      if (numberWithoutDot[0].length > 1) {
        let newNumber = `${numberWithoutDot[0].slice(0, -1)}.${numberWithoutDot[0][numberWithoutDot[0].length - 1]}${numberWithoutDot[1][0]}`
        setSendPayment({
          ...sendPayment,
          count: Number(newNumber)
        })
      } else {
        let newNumber = `0.${numberWithoutDot[0][0]}${numberWithoutDot[1][0]}`
        setSendPayment({
          ...sendPayment,
          count: Number(newNumber)
        })
      }
    }
  }

  return (
    <>
      {
        page === 'pdf_view' &&
        <PdfView
          setPage={setPage}
          data={data}
          name="Invoice"
          id={id}
          template={template}
          handleSendApproveLink={handleSendApproveLink}
        />
      }

      {
        page === '' &&
        <div className="EstimatePage_Add entity-edit">
          <div className="wrapper flex-container sb">
            <div className='row'>
              <Icon style={{ width: '24px', height: '24px', fill: '#393939' }} viewBox="0 0 1024 1024" icon="unordered-lists" />

              <h1 style={{ whiteSpace: 'nowrap', marginTop: '0', color: '#393939' }}>Invoice</h1>
            </div>

            <div style={{ gap: '30px' }} className='row'>
              <button
                className="_zeroed _iconed _blue"
                onClick={() => switchPage('main')}
              >
                <Icon
                  style={{ width: '24px', height: '24px', transform: 'rotate(180deg)' }}
                  viewBox="0 0 24 24"
                  icon="arrow-25"
                />
              </button>

              <button
                className="_zeroed _iconed _blue"
                onClick={() => setPage('pdf_view')}
              >

                <Icon
                  style={{ width: '24px', height: '24px', verticalAlign: 'middle', overflow: 'hidden' }}
                  viewBox="0 0 1024 1024"
                  icon="zoom-17"
                />
              </button>

              <button
                className="_zeroed _iconed _blue"
                onClick={() => setSendDocument({ is_open: true, phone: '' })}
              >
                <Icon
                  viewBox="0 0 1024 1024"
                  style={{
                    width: '24px',
                    minWidth: '24px',
                    height: '24px',
                    cursor: 'pointer',
                  }}
                  icon="send-21"
                />
              </button>
            </div>
          </div>

          {
            pageShow &&
            <div className='line-items-head'>
              <div className='line-items-head-name'>
                <span>
                  {data.name}
                </span>

                <div className='line-items-head-name-status'>
                  <span
                    className={classNames('status', {
                      'green': data.status?.toLocaleLowerCase() === 'new',
                      'red': data.status?.toLocaleLowerCase() === 'canceled',
                    })}>
                    {data.status}
                  </span>
                </div>
              </div>

              <div
                className='line-items-head-btns'
              >
                <button
                  className={classNames('_bordered _red', {
                    'new_status': data.status?.toLocaleLowerCase() === 'new',
                    'canceled_status': data.status?.toLocaleLowerCase() === 'canceled',
                  })}
                  onClick={() => setShowCancelModal(true)}
                >
                  {
                    data.status?.toLocaleLowerCase() === 'canceled' ? 'Restore' : 'Cancel'
                  }
                </button>

                <button
                  className={classNames({
                    'button-save-disabled': data.total === data.paid,
                  })}
                  onClick={() => data.total !== data.paid && setSendPaymentLinkModal(true)}
                >
                  Send Payment Link
                </button>

                <button
                  style={{ opacity: 0 }}
                >
                  Clone
                </button>
              </div>
            </div>
          }

          {
            pageShow &&
            <div className='line-items-content service'>
              <div className='line-items-col-show'>
                <div className='line-items-title'>
                  Services
                </div>

                {
                  data.services.map((item, index) => (
                    <div
                      key={index}
                      className="services-item services-item-show"
                    >
                      <div className='services-item-info'>
                        <div className='services-item-info-name'>
                          {item.name}
                        </div>

                        <div className='services-item-info-comment'>
                          {`Qty ${item.quantity} @ $${item.amount.toFixed(2)}`}
                        </div>

                        <div className='services-item-info-comment'>
                          {item.comment}
                        </div>
                      </div>

                      <div className='services-item-info-price'>
                        <div style={{ whiteSpace: 'nowrap', color: '#000' }} className='services-item-info-comment'>
                          $ {(item.amount * item.quantity).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  ))
                }

                <div className='line-items-title'>
                  Materials
                </div>

                {
                  data.materials.map((item, index) => (
                    <div
                      key={index}
                      className="services-item services-item-show"
                    >
                      <div className='services-item-info'>
                        <div className='services-item-info-name'>
                          {item.name}
                        </div>

                        <div className='services-item-info-comment'>
                          {`Qty ${item.quantity} @ $${item.price_per_unit.toFixed(2)}`}
                        </div>

                        <div className='services-item-info-comment'>
                          {item.description}
                        </div>
                      </div>

                      <div className='services-item-info-price'>
                        <div style={{ whiteSpace: 'nowrap', color: '#000' }} className='services-item-info-comment'>
                          $ {(item.price_per_unit * item.quantity).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  ))
                }

                <div style={{ marginTop: '0' }} className='line-items-subtotal-show'>
                  <span>
                    Subtotal
                  </span>

                  <span>
                    {getSubTotal()}
                  </span>
                </div>
              </div>

              {
                !!data.discounts.length &&
                <div className='line-items-content service'>
                  <div className='line-items-col-show'>
                    {
                      data.discounts.map((item, index) => (
                        <div
                          key={index}
                          className="services-item services-item-show"
                        >
                          <div className='services-item-info'>
                            <div className='services-item-info-name'>
                              Discount: {item.name}
                            </div>

                            <div className='services-item-info-comment'>
                              Discount ($ {(item.amount).toFixed(2)})
                            </div>
                          </div>

                          <div className='services-item-info-price'>
                            <div style={{ whiteSpace: 'nowrap' }} className='services-item-info-comment'>
                              - $ {(item.amount).toFixed(2)}
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              }

              <div className='line-items-content service'>
                <div className='line-items-col-show'>
                  <div style={{ marginTop: '0' }} className='line-items-subtotal-show'>
                    <span>
                      Total
                    </span>

                    <span>
                      {getTotalWithDiscount()}
                    </span>
                  </div>
                </div>
              </div>

              <div className='line-items-content service'>
                <div className='line-items-col-show'>
                  <div className="services-item services-item-show">
                    <div className='services-item-info'>
                      <div className='services-item-info-name'>
                        Deposit
                      </div>

                      <div className='services-item-info-comment'>
                        Due on {moment(data.deposite.date).format('MM/DD/YYYY')}
                      </div>
                    </div>

                    <div className='services-item-info-price'>
                      <div style={{ whiteSpace: 'nowrap' }} className='services-item-info-comment'>
                        ${(data.deposite.amount).toFixed(2)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='line-items-content service'>
                <div className='line-items-col-show'>
                  <div className="services-item services-item-show">
                    <div className='services-item-info'>
                      <div className='services-item-info-name'>
                        Note
                      </div>

                      <div className='services-item-info-comment'>
                        {data.note}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }

          {
            showCancelModal &&
            <div className="item-delete-popup" onClick={() => setShowCancelModal(false)}>

              <div className="wrapper" onClick={(e) => e.stopPropagation()}>

                <div style={{ background: '#1b2f5f' }} className="title">
                  {data.status?.toLocaleLowerCase() === 'canceled' ? 'Confirm Restore' : 'Confirm Cancellation'}
                </div>

                <div className="buttons">

                  <button
                    className="_bordered _red"
                    onClick={() => setShowCancelModal(false)}
                  >
                    No
                  </button>

                  <button
                    className="_bordered _green"
                    onClick={() => handleCanceledStatus()}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          }

          {
            sendDocument.is_open &&
            <div className="item-delete-popup" onClick={() => setSendDocument({ is_open: false, phone: '' })}>

              <div className="wrapper" onClick={(e) => e.stopPropagation()}>

                <div style={{ background: '#1b2f5f' }} className="title">
                  Send Invoice
                </div>

                <div className='send-payment-block'>
                  <div className='send-payment-block-row'>
                    <span>Phone</span>

                    <ReactInputMask
                      style={{ textAlign: 'right' }}
                      type="text"
                      mask="+1 (999) 999-9999"
                      value={sendDocument.phone}
                      onChange={({ target: { value } }) => setSendDocument({ ...sendDocument, phone: value })}
                    />
                  </div>
                </div>

                <div className="buttons">

                  <button
                    className="_bordered _red"
                    onClick={() => setSendDocument({ is_open: false, phone: '' })}
                  >
                    Cancel
                  </button>

                  <button
                    className="_bordered _green"
                    disabled={formatPhoneNumberToServerString(sendDocument.phone).length !== 12}
                    onClick={() => handleSendDocument()}
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          }

          {sendPaymentLinkModal && (
            <div className="item-delete-popup" onClick={() => setSendPaymentLinkModal(false)}>

              <div className="wrapper" onClick={(e) => e.stopPropagation()}>

                <div style={{ background: '#1b2f5f' }} className="title">
                  Send Payment Link
                </div>

                <div className='send-payment-block'>
                  <div className='send-payment-block-row'>
                    <span>Amount</span>

                    <input
                      style={{ textAlign: 'right' }}
                      type="number"
                      value={`${Number(sendPayment.count).toFixed(2)}`}
                      step={0.01}
                      onFocus={(event) => handleFocus(event)}
                      onChange={(event) => handleChangeItem(event)}
                      onKeyDown={(event) => handleChangeItemBackspace(event)}
                    />
                  </div>

                  <div className='send-payment-block-row'>
                    <span>Note</span>

                    <textarea
                      className='description'
                      style={{ margin: '0', maxHeight: '50px', minHeight: '50px' }}
                      value={sendPayment.note}
                      onChange={(event) => setSendPayment({ ...sendPayment, note: event.target.value })}
                    ></textarea>
                  </div>

                  <div className='send-payment-block-row'>
                    <span>Phone</span>

                    <ReactInputMask
                      style={{ textAlign: 'right' }}
                      type="text"
                      mask="+1 (999) 999-9999"
                      value={sendPayment.phone}
                      onChange={({ target: { value } }) => setSendPayment({ ...sendPayment, phone: value })}
                    />
                  </div>
                </div>

                <div className="buttons">

                  <button
                    className="_bordered _red"
                    onClick={() => setSendPaymentLinkModal(false)}
                  >
                    Cancel
                  </button>

                  <button
                    className="_bordered _green"
                    disabled={formatPhoneNumberToServerString(sendPayment.phone).length !== 12 || sendPayment.count === 0}
                    onClick={() => handlePaymentLink()}
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      }
    </>
  )
}
