import { useCallback, useEffect, useState } from 'react'
import { useRoute } from 'react-router5'

import Icon from '../../components/Icon'
import Checkbox from '../../components/Checkbox'
import AudioPlayerModal from '../../components/AudioPlayer'

import { httpClientUpdate, nErrorUpdate } from '../../funcs'
import { useAppSelector } from '../../store/hooks'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";
import { LibraryEditProps } from './List'
import SelectComponent from '../../components/Select'

interface HttpReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: DataProps
}

interface DataProps {
  permissions: {
    instruction_library_delete: boolean
    instruction_library_edit: boolean
  }

  edit: LibraryEditProps[]

  instruction_library: {
    attachments: AttachmentsProps[]
    created_at: string
    created_by: string
    instruction_library_id: string
    priority: number
    text: string
    theme: string
    updated_at: string
    user_groups: {
      laravel_through_key: string
      name: string
      user_group_id: string
    }[]
  }
}

interface AttachmentsProps {
  created_at: string
  instruction_library_attachment_id: string
  instruction_library_id: string
  priority: number
  type: string
  updated_at: string
  link: string
}

interface NewDataProps {
  text: string,
  theme: string,
  priority: number,
  attachments: {
    type: string
    priority: number
    file: string
  }[]
  user_groups: string[]
}

export default function Item() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [editing, setEditing] = useState(false)
  const [deleting, setDeleting] = useState(false)

  const [readyToDelete, setReadyToDelete] = useState(false)

  const [isSaving, setIsSaving] = useState(false)

  const [edit, setEdit] = useState<LibraryEditProps[]>([])
  const [availableUsers, setAvailableUsers] = useState<LibraryEditProps[]>([])

  const [selectedUser, setSelectedUser] = useState('')

  const [updatedSounds, setUpdatedSounds] = useState<{ data: string, is_open: boolean }>({ data: '', is_open: false })
  const [dragingSound, setDragingSound] = useState(false)
  const [draging, setDraging] = useState(false)
  const [dragingVideo, setDragingVideo] = useState(false)

  const [openImageModal, setOpenImageModal] = useState(false)
  const [openVideoModal, setOpenVideoModal] = useState({ is_open: false, data: '' })
  const [upImg, setUpImg] = useState('')

  const [imageModal, setImageModal] = useState({
    instruction_library_attachment_id: '',
    link: '',
  })

  const [newImageModal, setNewImageModal] = useState({
    data: '',
    index: 0,
  })

  const [audioModal, setAudioModal] = useState({
    instruction_library_attachment_id: '',
    link: '',
  })

  const [videoModal, setVideoModal] = useState({
    instruction_library_attachment_id: '',
    link: '',
  })

  const [newAudioModal, setNewAudioModal] = useState({
    data: '',
    index: 0,
  })

  const [newVideoModal, setNewVideoModal] = useState({
    data: '',
    index: 0,
  })

  const [data, setData] = useState<DataProps | null>(null)
  const [newData, setNewData] = useState<NewDataProps>({
    text: '',
    theme: '',
    priority: 0,
    attachments: [],
    user_groups: [],
  })
  const [attachments, setAttachments] = useState<AttachmentsProps[]>([])

  useEffect(() => {
    loadInfo()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function loadInfo() {
    try {
      // api/instruction-libraries/{ID}?account_id=88888
      const { data: { data: report, success, error } } = await httpClientUpdate.get(`/instruction-libraries/${$router.router.getState().params.libraryId}`, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpReport }
      if (success) {
        setData(report)
        setNewData({
          theme: report.instruction_library.theme,
          text: report.instruction_library.text,
          priority: report.instruction_library.priority,
          attachments: [],
          user_groups: report.instruction_library.user_groups.map(item => item.user_group_id)
        })
        let _availableUsers = []

        _availableUsers = report.edit.filter((item) => {
          return report.instruction_library.user_groups.findIndex((user) => {
            return user.user_group_id === item.user_group_id
          }) === -1
        })

        setEdit(report.edit)
        setAvailableUsers(_availableUsers)
        setAttachments(report.instruction_library.attachments)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function handleSave() {
    setIsSaving(true)
    try {

      // api/instruction-libraries/88888601s4x3r7umpg
      const response = await httpClientUpdate.put(`/instruction-libraries/${$router.router.getState().params.libraryId}`, {
        account_id: activeAccountId,
        theme: newData.theme,
        text: newData.text,
        priority: newData.priority,
        user_groups: newData.user_groups,
        attachments: [...attachments.map(item => {
          return {
            instruction_library_attachment_id: item.instruction_library_attachment_id,
            type: item.type,
            priority: item.priority
          }
        }), ...newData.attachments.map(item => {
          if (item.type === 'audio/mpeg') {
            let audio_file = item.file.replace('data:audio/mpeg;base64,', '')
            return {
              type: 'audio/mpeg',
              priority: 1,
              file: audio_file,
            }
          } else if (item.type === 'video/mp4') {
            let video_file = item.file.replace('data:video/mp4;base64,', '')
            return {
              type: 'video/mp4',
              priority: 1,
              file: video_file,
            }
          } else {
            let file = item.file.replace('data:image/png;base64,', '').replace('data:image/jpeg;base64,', '')
            return {
              ...item,
              file: file
            }
          }
        })]
      })

      if (response.data.success) {
        loadInfo()
        setEditing(false)
        setIsSaving(false)
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  function handleDeleteImage() {
    setAttachments(attachments.filter(item => item.instruction_library_attachment_id !== imageModal.instruction_library_attachment_id))
    setImageModal({ link: '', instruction_library_attachment_id: '' })
  }

  function handleDeleteNewImage() {
    setAttachments(attachments.filter((item, idx) => idx !== newImageModal.index))
    setNewImageModal({ data: '', index: 0 })
  }

  function handleDeleteAudio() {
    setAttachments(attachments.filter(item => item.instruction_library_attachment_id !== audioModal.instruction_library_attachment_id))
    setAudioModal({ link: '', instruction_library_attachment_id: '' })
  }

  function handleDeleteVideo() {
    setAttachments(attachments.filter(item => item.instruction_library_attachment_id !== videoModal.instruction_library_attachment_id))
    setVideoModal({ link: '', instruction_library_attachment_id: '' })
  }

  function handleDeleteNewAudio() {
    setAttachments(attachments.filter((item, idx) => idx !== newAudioModal.index))
    setNewAudioModal({ data: '', index: 0 })
  }

  function handleDeleteNewVideo() {
    setAttachments(attachments.filter((item, idx) => idx !== newVideoModal.index))
    setNewVideoModal({ data: '', index: 0 })
  }

  function handleSaveSound() {
    let updated = newData.attachments.map(item => item)

    updated.push({
      type: 'audio/mpeg',
      priority: 1,
      file: updatedSounds.data,
    })

    setNewData({
      ...newData,
      attachments: updated
    })
    setUpdatedSounds({ is_open: false, data: '' })
  }

  function handleSaveVideo() {
    let updated = newData.attachments.map(item => item)

    updated.push({
      type: 'video/mp4',
      priority: 1,
      file: openVideoModal.data,
    })

    setNewData({
      ...newData,
      attachments: updated
    })
    setOpenVideoModal({ is_open: false, data: '' })
  }

  function handleSaveImage() {
    let updated = newData.attachments.map(item => item)

    updated.push({
      type: 'image/jpeg',
      priority: 1,
      file: upImg,
    })

    setNewData({
      ...newData,
      attachments: updated
    })

    setUpImg('')
    setOpenImageModal(false)
  }

  async function handleRemoveItem() {
    try {
      setIsSaving(true)
      const response = await httpClientUpdate.delete(`/instruction-libraries/${$router.router.getState().params.libraryId}`, {
        data: {
          account_id: activeAccountId,
        }
      })

      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('instruction_library', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  function handleCancelEdit() {
    if (data) {
      let _availableUsers = []

      _availableUsers = data.edit.filter((item) => {
        return data.instruction_library.user_groups.findIndex((user) => {
          return user.user_group_id === item.user_group_id
        }) === -1
      })

      setEdit(data.edit)
      setAvailableUsers(_availableUsers)

      setEditing(false)
      setNewData({
        theme: data.instruction_library.theme,
        text: data.instruction_library.text,
        priority: data.instruction_library.priority,
        attachments: [],
        user_groups: data.instruction_library.user_groups.map(item => item.user_group_id)
      })
      setAttachments(data.instruction_library.attachments)
    }
  }

  const onDragSoundLeave = useCallback((e) => {
    setDragingSound(false);
    e.stopPropagation();
    e.preventDefault();
    return false;
  }, []);

  const onDragVideoLeave = useCallback((e) => {
    setDragingVideo(false);
    e.stopPropagation();
    e.preventDefault();
    return false;
  }, []);

  const onDragLeave = useCallback((e) => {
    setDraging(false);
    e.stopPropagation();
    e.preventDefault();
    return false;
  }, []);

  const onDragOver = useCallback((e) => {
    setDraging(true)
    e.preventDefault();
    e.stopPropagation();
    return false;
  }, []);

  const onDragSoundOver = useCallback((e) => {
    setDragingSound(true)
    e.preventDefault();
    e.stopPropagation();
    return false;
  }, []);

  const onDragVideoOver = useCallback((e) => {
    setDragingVideo(true)
    e.preventDefault();
    e.stopPropagation();
    return false;
  }, []);

  const onDropSound = useCallback((e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    const reader = new FileReader();
    reader.addEventListener('load', () =>
      setUpdatedSounds({ ...updatedSounds, data: reader.result as string })
    );
    reader.readAsDataURL(files[0]);
    setDragingSound(false);
    return false;
  }, [updatedSounds]);

  const onDropVideo = useCallback((e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    const reader = new FileReader();
    reader.addEventListener('load', () =>
      setOpenVideoModal({ ...openVideoModal, data: reader.result as string })
    );
    reader.readAsDataURL(files[0]);
    setDragingVideo(false);
    return false;
  }, [openVideoModal]);

  function onSelectSoundFile(e: any) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setUpdatedSounds({ ...updatedSounds, data: reader.result as string })
      }
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onSelectVideoFile(e: any) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setOpenVideoModal({ ...openVideoModal, data: reader.result as string })
      }
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const onDrop = useCallback((e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    const reader = new FileReader();
    reader.addEventListener('load', () =>
      setUpImg(reader.result as string)
    );
    reader.readAsDataURL(files[0]);
    setDraging(false);
    return false;
  }, []);

  function onSelectFile(e: any) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        setUpImg(reader.result as string)
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function addUser() {
    const newUsersList = [
      ...newData.user_groups,
      selectedUser
    ]

    setNewData({
      ...newData,
      user_groups: newUsersList
    })

    let _availableUser = [...availableUsers]

    _availableUser = _availableUser.filter(($user) => {

      return newUsersList.findIndex((user) => {
        return user === $user.user_group_id
      }) === -1
    })

    setAvailableUsers([
      ..._availableUser
    ])

    setSelectedUser('')
  }

  function removeUser(i: number) {
    let _callerGroupUsers = [...newData.user_groups]

    let _user = _callerGroupUsers[i]

    _callerGroupUsers.splice(i, 1)

    setNewData({
      ...newData,
      user_groups: _callerGroupUsers
    })

    setAvailableUsers([
      ...availableUsers,
      ...edit.filter(item => item.user_group_id === _user)
    ])
  }

  function getUserName(id: string) {
    let name = ''

    edit.forEach(item => {
      if (item.user_group_id === id) {
        name = item.name
      }
    })

    return name
  }

  return (
    <>
      {
        data &&
        <div className="AbsencesPage_Item entity-edit instruction-library">
          <div className="wrapper flex-container sb">
            <h1>Instruction</h1>

            <div style={{ display: 'flex' }} >
              <button
                disabled={editing}
                style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
                className="_wa"
                onClick={() =>
                  $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'instruction_library',
                    {
                      companyId: activeAccountId,
                      [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                      localInterface: $router.router.getState().params.localInterface
                    }, { reload: true })}
              >
                <Icon
                  style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                  viewBox="0 0 24 24"
                  icon="arrow-25"
                />
              </button>

              {
                !editing &&
                  data.permissions.instruction_library_edit ? (
                  <button className="_wa _green" onClick={() => setEditing(true)}>
                    Edit
                  </button>
                ) : null
              }
            </div>
          </div>

          {
            !editing &&
            <div className='instruction-library-content'>
              <div className='border-row'></div>

              <div className='instruction-library-theme'> {data.instruction_library.theme} </div>

              <div className='instruction-library-text'><div className='paragraph'></div> {data.instruction_library.text} </div>

              <div className='border-row'></div>

              <div className='user_groups-title'> User Groups: </div>

              <table className='table user_groups'>
                <tr>
                  <th> Name </th>
                </tr>

                {
                  data.instruction_library.user_groups.map(item => (
                    <tr key={item.user_group_id}>
                      <td> {item.name} </td>
                    </tr>
                  ))
                }
              </table>

              <div className='border-row'></div>
            </div>
          }

          {
            !editing &&
            <div className='instruction-library-content'>
              <div className='add-attachments'>
                {
                  data.instruction_library.attachments.map((item, index) => (
                    <div key={item.instruction_library_attachment_id}>
                      {
                        item.type === 'audio/mpeg' &&
                        <div
                          className='audio'
                          onClick={() => setAudioModal({
                            instruction_library_attachment_id: item.instruction_library_attachment_id,
                            link: item.link,
                          })}
                        >
                          <Icon
                            viewBox='0 0 1024 1024'
                            icon="sound-96"
                          />
                        </div>
                      }

                      {
                        item.type === 'video/mp4' &&
                        <div
                          className='audio'
                          onClick={() => setVideoModal({
                            instruction_library_attachment_id: item.instruction_library_attachment_id,
                            link: item.link,
                          })}
                        >
                          <video
                            controls={false}
                            style={{ width: '100%' }}
                          >
                            <source src={item.link} type="video/mp4" />
                          </video>
                        </div>
                      }

                      {
                        item.type === 'image/jpeg' &&
                        <div
                          className='audio'
                          onClick={() => setImageModal({
                            instruction_library_attachment_id: item.instruction_library_attachment_id,
                            link: item.link,
                          })}
                        >
                          <img src={item.link} alt="Attachment" />
                        </div>
                      }
                    </div>
                  ))
                }
              </div>
            </div>
          }

          {
            editing &&
            <div className='instruction-library-content'>
              <div className='border-row'></div>

              <div className='instruction-library-theme'>
                <input
                  type="text"
                  value={newData.theme}
                  onChange={(event) => setNewData({ ...newData, theme: event.target.value })}
                />
              </div>

              <div className='instruction-library-text'>
                <textarea
                  value={newData.text}
                  onChange={(event) => setNewData({ ...newData, text: event.target.value })}
                ></textarea>
              </div>

              <div className='border-row'></div>

              <div
                className='instruction-library-theme select'
                style={{ display: 'flex', alignItems: 'flex-start', fontSize: '18px' }}
              >
                <div className='row'>
                  <span>User:</span>

                  <SelectComponent
                    options={availableUsers.map(item => {
                      return {
                        span: item.name,
                        value: item.user_group_id,
                      }
                    })}
                    selectedOption={selectedUser}
                    onChange={(value) => setSelectedUser(value as string)}
                  />

                  <button
                    disabled={!selectedUser}
                    className="_green add"
                    onClick={() => addUser()}
                  >
                    Add
                  </button>
                </div>

                <table className='table'>
                  <tr>
                    <th>Name</th>
                    <th></th>
                  </tr>

                  {
                    newData.user_groups.map((item, i) => (
                      <tr key={item}>
                        <td> {getUserName(item)} </td>

                        <td>
                          <button
                            className="_zeroed _iconed _red"
                            onClick={() => removeUser(i)}
                          >
                            <Icon icon="x-mark-1" />
                          </button>
                        </td>
                      </tr>
                    ))
                  }
                </table>
              </div>

              <div className='border-row'></div>

              <div className='add-attachments'>
                {
                  attachments.map(item => (
                    <div key={item.instruction_library_attachment_id}>
                      {
                        item.type === 'audio/mpeg' &&
                        <div
                          className='audio'
                          onClick={() => setAudioModal({
                            instruction_library_attachment_id: item.instruction_library_attachment_id,
                            link: item.link,
                          })}
                        >
                          <Icon
                            viewBox='0 0 1024 1024'
                            icon="sound-96"
                          />
                        </div>
                      }

                      {
                        item.type === 'image/jpeg' &&
                        <div
                          className='audio'
                          onClick={() => setImageModal({
                            instruction_library_attachment_id: item.instruction_library_attachment_id,
                            link: item.link,
                          })}
                        >
                          <img src={item.link} alt="Attachment" />
                        </div>
                      }

                      {
                        item.type === 'video/mp4' &&
                        <div
                          className='audio'
                          onClick={() => setVideoModal({
                            instruction_library_attachment_id: item.instruction_library_attachment_id,
                            link: item.link,
                          })}
                        >
                          <video
                            controls={false}
                            style={{ width: '100%' }}
                          >
                            <source src={item.link} type="video/mp4" />
                          </video>
                        </div>
                      }
                    </div>
                  ))
                }

                {
                  newData.attachments.map((item, index) => (
                    <div key={index}>
                      {
                        item.type === 'audio/mpeg' &&
                        <div
                          className='audio'
                          onClick={() => setNewAudioModal({
                            index: index,
                            data: item.file
                          })}
                        >
                          <Icon
                            viewBox='0 0 1024 1024'
                            icon="sound-96"
                          />
                        </div>
                      }

                      {
                        item.type === 'image/jpeg' &&
                        <div
                          className='audio'
                          onClick={() => setNewImageModal({ index: index, data: item.file })}
                        >
                          <img src={item.file} alt="Attachment" />
                        </div>
                      }

                      {
                        item.type === 'video/mp4' &&
                        <div
                          className='audio'
                          onClick={() => setNewVideoModal({ index: index, data: item.file })}
                        >
                          <video
                            controls={false}
                            style={{ width: '100%' }}
                          >
                            <source src={item.file} type="video/mp4" />
                          </video>
                        </div>
                      }
                    </div>
                  ))
                }
              </div>
            </div>
          }

          {
            editing &&
            <div
              className='adding-buttons'
              style={{ display: 'flex', gap: '20px', margin: '20px' }}
            >
              <button
                className="_bordered _green"
                style={{ width: 'fit-content' }}
                onClick={() => setUpdatedSounds({ is_open: true, data: '' })}
              >
                Add Audio
              </button>

              <button
                className="_bordered _green"
                style={{ width: 'fit-content' }}
                onClick={() => setOpenImageModal(true)}
              >
                Add Image
              </button>

              <button
                className="_bordered _green"
                style={{ width: 'fit-content' }}
                onClick={() => setOpenVideoModal({ is_open: true, data: '' })}
              >
                Add Video
              </button>
            </div>
          }

          {
            imageModal.link &&
            <div className="popup imageCrop">
              <div
                className="wrapper"
                onClick={(e) => e.stopPropagation()}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    padding: '10px'
                  }}
                >
                  <img
                    src={imageModal.link}
                    alt="Attachment"
                  />
                </div>

                {
                  <div
                    style={{ display: 'flex', marginTop: '10px' }}
                  >
                    {
                      editing &&
                      <button
                        className="_wa _iconed _bordered _red"
                        onClick={() => handleDeleteImage()}
                      >
                        <span>Delete</span>
                      </button>
                    }

                    <button
                      className="_wa _iconed _bordered _blue"
                      onClick={() => {
                        setImageModal({ link: '', instruction_library_attachment_id: '' })
                      }}
                    >
                      <span>Close</span>
                    </button>
                  </div>
                }
              </div>
            </div>
          }

          {
            newImageModal.data &&
            <div className="popup imageCrop">
              <div
                className="wrapper"
                onClick={(e) => e.stopPropagation()}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    padding: '10px'
                  }}
                >
                  <img
                    src={newImageModal.data}
                    alt="Attachment"
                  />
                </div>

                {
                  <div
                    style={{ display: 'flex', marginTop: '10px' }}
                  >
                    {
                      editing &&
                      <button
                        className="_wa _iconed _bordered _red"
                        onClick={() => handleDeleteNewImage()}
                      >
                        <span>Delete</span>
                      </button>
                    }

                    <button
                      className="_wa _iconed _bordered _blue"
                      onClick={() => {
                        setNewImageModal({ data: '', index: 0 })
                      }}
                    >
                      <span>Close</span>
                    </button>
                  </div>
                }
              </div>
            </div>
          }

          {openImageModal ? (
            <div
              className="popup imageCrop"
              onClick={() => setOpenImageModal(false)}
              onDragLeave={onDragLeave}
              onDragOver={onDragOver}
              onDrop={(event) => {
                draging && onDrop(event)
              }}
            >

              <div
                className="wrapper"
                onClick={(e) => e.stopPropagation()}
              >

                {
                  draging ?
                    <div
                      style={{
                        border: '2px solid rgb(96, 147, 222)',
                        borderRadius: '5px',
                        position: 'relative'
                      }}
                    >
                      <input
                        type="file"
                        accept="image/*"
                        style={{ width: '100%', height: '120px', display: 'block', opacity: '0', zIndex: '2' }}
                        name="myImage"
                        onChange={onSelectFile}
                        id="file-uploader"
                      />
                      <span
                        style={{ position: 'absolute', top: '50px', width: '100%', textAlign: 'center', color: '#6093de' }}
                      >
                        Upload a File or click for refresh field
                      </span>
                    </div> :
                    <div>
                      <input type="file" accept="image/*" onChange={onSelectFile} id="file-uploader" />
                      <button
                        className="_wa _iconed _bordered _blue"
                        style={{ marginTop: upImg ? '' : '40px' }}
                        onClick={() => document.getElementById('file-uploader')?.click()}
                      >
                        <Icon icon="user-33" />
                        <span>Upload a File</span>
                      </button>
                    </div>
                }


                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    padding: '10px'
                  }}
                >
                  {
                    upImg &&
                    <img
                      src={upImg}
                      alt="Attachment"
                    />
                  }
                </div>

                {
                  <div
                    style={{ display: 'flex', marginTop: '10px' }}
                  >
                    <button
                      className="_wa _iconed _bordered _blue"
                      onClick={() => {
                        setOpenImageModal(false)
                        setDraging(false)
                        setUpImg('')
                      }}
                    >
                      <span>Cancel</span>
                    </button>

                    <button
                      className="_wa _iconed _bordered _blue"
                      onClick={() => {
                        setOpenImageModal(false)
                        setDraging(false)
                        handleSaveImage()
                      }}
                    >
                      <span>Save</span>
                    </button>
                  </div>
                }
              </div>
            </div>
          ) : null}

          {
            audioModal.link &&
            <div className="popup imageCrop">

              <div
                className="wrapper"
                onClick={(e) => e.stopPropagation()}
              >

                <AudioPlayerModal
                  audioSrc={audioModal.link}
                />

                <div
                  style={{ display: 'flex', marginTop: '10px' }}
                >
                  {
                    editing &&
                    <button
                      className="_wa _iconed _bordered _red"
                      onClick={() => handleDeleteAudio()}
                    >
                      <span>Delete</span>
                    </button>
                  }

                  <button
                    className="_wa _iconed _bordered _blue"
                    disabled={isSaving}
                    onClick={() => {
                      setAudioModal({ link: '', instruction_library_attachment_id: '' })
                    }}
                  >
                    <span>Close</span>
                  </button>
                </div>
              </div>
            </div>
          }

          {
            videoModal.link &&
            <div className="popup imageCrop">

              <div
                className="wrapper"
                onClick={(e) => e.stopPropagation()}
              >

                <video
                  controls
                  style={{ width: '100%' }}
                >
                  <source src={videoModal.link} type="video/mp4" />
                </video>

                <div
                  style={{ display: 'flex', marginTop: '10px' }}
                >
                  {
                    editing &&
                    <button
                      className="_wa _iconed _bordered _red"
                      onClick={() => handleDeleteVideo()}
                    >
                      <span>Delete</span>
                    </button>
                  }

                  <button
                    className="_wa _iconed _bordered _blue"
                    disabled={isSaving}
                    onClick={() => {
                      setVideoModal({ link: '', instruction_library_attachment_id: '' })
                    }}
                  >
                    <span>Close</span>
                  </button>
                </div>
              </div>
            </div>
          }

          {
            newVideoModal.data &&
            <div className="popup imageCrop">

              <div
                className="wrapper"
                onClick={(e) => e.stopPropagation()}
              >

                <video
                  controls
                  style={{ width: '100%' }}
                >
                  <source src={newVideoModal.data} type="video/mp4" />
                </video>

                <div
                  style={{ display: 'flex', marginTop: '10px' }}
                >
                  {
                    editing &&
                    <button
                      className="_wa _iconed _bordered _red"
                      onClick={() => handleDeleteNewVideo()}
                    >
                      <span>Delete</span>
                    </button>
                  }

                  <button
                    className="_wa _iconed _bordered _blue"
                    disabled={isSaving}
                    onClick={() => {
                      setNewVideoModal({ data: '', index: 0 })
                    }}
                  >
                    <span>Close</span>
                  </button>
                </div>
              </div>
            </div>
          }

          {
            newAudioModal.data &&
            <div className="popup imageCrop">

              <div
                className="wrapper"
                onClick={(e) => e.stopPropagation()}
              >

                <AudioPlayerModal
                  audioSrc={newAudioModal.data}
                />

                <div
                  style={{ display: 'flex', marginTop: '10px' }}
                >
                  {
                    editing &&
                    <button
                      className="_wa _iconed _bordered _red"
                      onClick={() => handleDeleteNewAudio()}
                    >
                      <span>Delete</span>
                    </button>
                  }

                  <button
                    className="_wa _iconed _bordered _blue"
                    disabled={isSaving}
                    onClick={() => {
                      setNewAudioModal({ data: '', index: 0 })
                    }}
                  >
                    <span>Close</span>
                  </button>
                </div>
              </div>
            </div>
          }

          {
            updatedSounds.is_open &&
            <div
              className="popup imageCrop"
              onClick={() => setUpdatedSounds({ is_open: true, data: '' })}
              onDragLeave={onDragSoundLeave}
              onDragOver={onDragSoundOver}
              onDrop={(event) => {
                dragingSound && onDropSound(event)
              }}
            >

              <div
                className="wrapper"
                onClick={(e) => e.stopPropagation()}
              >

                {
                  updatedSounds.data &&
                  <AudioPlayerModal
                    audioSrc={updatedSounds.data}
                  />
                }

                {
                  updatedSounds.data &&
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      padding: '10px'
                    }}
                  >
                  </div>
                }

                {
                  dragingSound ?
                    <div
                      style={{
                        border: '2px solid rgb(96, 147, 222)',
                        borderRadius: '5px',
                        position: 'relative'
                      }}
                    >
                      <input
                        type="file"
                        accept="audio/*"
                        style={{ width: '100%', height: '120px', display: 'block', opacity: '0', zIndex: '2' }}
                        onChange={onSelectSoundFile}
                        id="file-uploader-sound"
                      />
                      <span
                        style={{ position: 'absolute', top: '50px', width: '100%', textAlign: 'center', color: '#6093de' }}
                      >
                        Upload a File or click for refresh field
                      </span>
                    </div> :
                    <div>
                      <input type="file" accept="audio/*" onChange={onSelectSoundFile} id="file-uploader-sound" />
                      <button
                        className="_wa _iconed _bordered _blue"
                        style={{ marginTop: upImg ? '' : '40px' }}
                        onClick={() => document.getElementById('file-uploader-sound')?.click()}
                      >
                        <span>Upload a File</span>
                      </button>
                    </div>
                }


                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    padding: '10px'
                  }}
                >
                </div>

                <div
                  style={{ display: 'flex', marginTop: '10px' }}
                >
                  <button
                    className="_wa _iconed _bordered _blue"
                    onClick={() => setUpdatedSounds({ data: '', is_open: false })}
                  >
                    <span>Cancel</span>
                  </button>

                  <button
                    className="_wa _iconed _bordered _blue"
                    disabled={isSaving}
                    onClick={() => {
                      setDragingSound(false)
                      handleSaveSound()
                    }}
                  >
                    <span>Save</span>
                  </button>
                </div>
              </div>
            </div>
          }

          {
            openVideoModal.is_open &&
            <div
              className="popup imageCrop"
              onClick={() => setOpenVideoModal({ is_open: true, data: '' })}
              onDragLeave={onDragVideoLeave}
              onDragOver={onDragVideoOver}
              onDrop={(event) => {
                dragingVideo && onDropVideo(event)
              }}
            >

              <div
                className="wrapper"
                onClick={(e) => e.stopPropagation()}
              >
                {
                  openVideoModal.data &&
                  <video
                    controls
                    style={{ width: '100%' }}
                  >
                    <source src={openVideoModal.data} type="video/mp4" />
                  </video>
                }

                {
                  openVideoModal.data &&
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      padding: '10px'
                    }}
                  >
                  </div>
                }

                {
                  dragingVideo ?
                    <div
                      style={{
                        border: '2px solid rgb(96, 147, 222)',
                        borderRadius: '5px',
                        position: 'relative'
                      }}
                    >
                      <input
                        type="file"
                        accept="audio/*"
                        style={{ width: '100%', height: '120px', display: 'block', opacity: '0', zIndex: '2' }}
                        onChange={onSelectVideoFile}
                        id="file-uploader-video"
                      />
                      <span
                        style={{ position: 'absolute', top: '50px', width: '100%', textAlign: 'center', color: '#6093de' }}
                      >
                        Upload a File or click for refresh field
                      </span>
                    </div> :
                    <div>
                      <input type="file" accept="audio/*" onChange={onSelectVideoFile} id="file-uploader-video" />
                      <button
                        className="_wa _iconed _bordered _blue"
                        style={{ marginTop: upImg ? '' : '40px' }}
                        onClick={() => document.getElementById('file-uploader-video')?.click()}
                      >
                        <span>Upload a File</span>
                      </button>
                    </div>
                }

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    padding: '10px'
                  }}
                >
                </div>

                <div
                  style={{ display: 'flex', marginTop: '10px' }}
                >
                  <button
                    className="_wa _iconed _bordered _blue"
                    onClick={() => setOpenVideoModal({ data: '', is_open: false })}
                  >
                    <span>Cancel</span>
                  </button>

                  <button
                    className="_wa _iconed _bordered _blue"
                    disabled={isSaving}
                    onClick={() => {
                      setDragingVideo(false)
                      handleSaveVideo()
                    }}
                  >
                    <span>Save</span>
                  </button>
                </div>
              </div>
            </div>
          }

          {editing ? (
            <div className="wrapper flex-container sb editing-buttons">
              <div>
                {
                  data.permissions.instruction_library_delete &&
                  <button className="_red" onClick={() => setDeleting(true)}>Delete Instruction</button>
                }
              </div>
              <div className="buttons">
                <button
                  className="_bordered _red"
                  onClick={() => handleCancelEdit()}
                >
                  Cancel
                </button>
                <button
                  disabled={isSaving}
                  className="_bordered _green"
                  onClick={() => handleSave()}
                >
                  Save
                </button>
              </div>
            </div>
          ) : null}

          {data.permissions.instruction_library_delete &&
            deleting ? (
            <div className="item-delete-popup" onClick={() => setDeleting(false)}>

              <div className="wrapper" onClick={(e) => e.stopPropagation()}>

                <div className="title">
                  Delete Instruction
                </div>

                <div className="checkbox-wrapper">
                  <Checkbox
                    contents="I understand that after deleting the Instruction it will not be possible to recover."
                    value={readyToDelete}
                    onChange={(value) => setReadyToDelete(value)}
                  />
                </div>

                <div className="buttons">

                  <button className="_bordered _green" onClick={() => setDeleting(false)}>
                    Cancel
                  </button>

                  <button
                    disabled={!readyToDelete || isSaving}
                    className="_bordered _red"
                    onClick={() => handleRemoveItem()}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      }
    </>
  )
}
