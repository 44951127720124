import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import { DateTime } from "luxon"
import moment from "moment"
import classNames from "classnames"
import { useCookies } from "react-cookie";

import ReportFilters from "../../components/reports/Filters"
import ReportTableControls from "../../components/reports/TableControls"
import ReportTableField from "../../components/reports/TableField"

import { dateToInfoBlock, httpClientUpdate, nErrorUpdate } from "../../funcs";
import { getDateRangeByPreset } from "../../funcs/reports"
import { useAppSelector } from '../../store/hooks'

import { DateRangePreset } from "../../models/Misc"
import Payment from "../../models/Payments";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/report-list.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: PaymentReport
}

interface PaymentReport {
  interface: {

    max_pages: number,
    rows_start: number,
    rows_end: number,
    rows_all: number,

    min_date: Date,
    max_date: Date
  },

  permissions: {
    payment_add: boolean
    payment_delete: boolean
    payment_edit: boolean
    payment_report_show: boolean
    payment_show: boolean
  },

  payments: Payment[]

  edit: {
    created_by: {
      dispatcher_code: number
      dispatcher_nickname: string
      first_name: string
      last_name: string
      service_resource_code: number
      service_resource_nickname: string
      user_id: string
    }[]
  }
}

function List({ updated }: { updated: number }) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const accessToken = useAppSelector((store) => store.accessToken)
  const user = useAppSelector((store) => store.user)
  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)

  let timeZone = user?.time_zone;

  const [cookies, setCookie] = useCookies();

  const cookiesSetting = cookies.settings ? cookies.settings : null

  const [$updater, $setUpdater] = useState<any>(Math.random())

  const [reportIsDeprecated, setReportIsDeprecated] = useState(false)
  const [searchButtonActive, setSearchButtonActive] = useState(false)

  const [reportData, setReportData] = useState<PaymentReport | null>(null)
  const [localInterface, setLocalInterface] = useState(
    {
      search: '',
      jobSharePopup: false,
      dateRangeCalendarShown: false,
      dateRangePreset: "today" as DateRangePreset,
      ...(getDateRangeByPreset(user, 'today', DateTime.now().setZone(timeZone).startOf('day').toJSDate(), DateTime.now().setZone(timeZone).endOf('day').toJSDate())),
      page: 1,
      sort: {
        field: cookiesSetting && cookiesSetting?.technician_payments?.sort_field ? cookiesSetting.technician_payments.sort_field : 'created_at',
        direction: cookiesSetting && cookiesSetting?.technician_payments?.sort_type ? cookiesSetting.technician_payments.sort_type : 'down' as 'down' | 'up'
      },
      sortFields: [{
        span: 'Date',
        value: 'created_at'
      }, {
        span: 'Type',
        value: 'type'
      }, {
        span: 'Number',
        value: 'number'
      }, {
        span: 'Status',
        value: 'status'
      }, {
        span: 'Total',
        value: 'total'
      }, {
        span: 'Fee',
        value: 'fee_compensation'
      }, {
        span: 'Jobs/Invoices',
        value: 'items'
      }]
    })

  useEffect(() => {
    updated && setLocalInterface({
      ...localInterface,
      search: '',
      jobSharePopup: false,
      dateRangeCalendarShown: false,
      dateRangePreset: "today" as DateRangePreset,
      ...(getDateRangeByPreset(user, 'today', DateTime.now().setZone(timeZone).startOf('day').toJSDate(), DateTime.now().setZone(timeZone).endOf('day').toJSDate())),
      page: 1,
      sort: {
        field: cookiesSetting && cookiesSetting?.technician_payments?.sort_field ? cookiesSetting.technician_payments.sort_field : 'created_at',
        direction: cookiesSetting && cookiesSetting?.technician_payments?.sort_type ? cookiesSetting.technician_payments.sort_type : 'down' as 'down' | 'up'
      },
    })
    updated && $setUpdater(updated)

    setTimeout(() => {
      updated && setReportIsDeprecated(false)
      updated && setSearchButtonActive(false)
    }, 100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  // Watch date range preset
  useEffect(() => {
    if (!reportData) return

    if (localInterface.dateRangePreset === 'custom') return

    !updated && setLocalInterface({
      ...localInterface,
      ...(getDateRangeByPreset(user, localInterface.dateRangePreset, reportData?.interface.min_date, reportData?.interface.max_date))
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.dateRangePreset])

  // Load Payment function
  async function loadPayment() {

    httpClientUpdate.defaults.headers['Authorization'] = `Bearer ${accessToken}`
    let filterParams = {}

    if ($router.router.getState().params) {
      if ($router.router.getState().params.localInterface) {
        filterParams = {
          account_id: activeAccountId,
          limit_rows: cookiesSetting && cookiesSetting?.technician_payments?.limit_rows ? cookiesSetting?.technician_payments?.limit_rows : reportsMaxRows,
          page: $router.router.getState().params.localInterface.page,
          date_start: $router.router.getState().params.localInterface.min_date,
          date_end: $router.router.getState().params.localInterface.max_date,
          sort_field: $router.router.getState().params.localInterface.sort.field,
          sort_type: $router.router.getState().params.localInterface.sort.direction === 'up' ? 'asc' : 'desc',
          ...($router.router.getState().params.localInterface.search ? { search: $router.router.getState().params.localInterface.search } : {})
        }
      } else {
        filterParams = {
          account_id: activeAccountId,
          limit_rows: cookiesSetting && cookiesSetting?.technician_payments?.limit_rows ? cookiesSetting?.technician_payments?.limit_rows : reportsMaxRows,
          page: localInterface.page,
          date_start: localInterface.min_date,
          date_end: localInterface.max_date,
          sort_field: localInterface.sort.field,
          sort_type: localInterface.sort.direction === 'up' ? 'asc' : 'desc',
          ...(localInterface.search ? { search: localInterface.search } : {})
        }

        setCookie('settings', { ...cookiesSetting, technician_payments: { sort_field: localInterface.sort.field, sort_type: localInterface.sort.direction, limit_rows: cookiesSetting && cookiesSetting?.technician_payments?.limit_rows ? cookiesSetting?.technician_payments.limit_rows : reportsMaxRows } })
      }
    }

    try {
      // https://2022back4.artemiudintsev.com/api/technician/payments/report
      const { data: { data: payments, success, error } } = (await httpClientUpdate.get('/technician/payments/report', {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        },
        params: { ...filterParams }
      })) as { data: HttpClientUpdateReport }

      if (success) {
        setReportData({
          ...payments,
          interface: {
            ...payments.interface,
            min_date: moment(payments.interface.min_date).startOf('day').toDate(),
            max_date: moment(payments.interface.max_date).endOf('day').toDate(),
          }
        })
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

      setTimeout(() => {
        setReportIsDeprecated(false)
        setSearchButtonActive(false)
      }, 100)
    }
    catch (error: Error | AxiosError | unknown) {
      setCookie('settings', { ...cookiesSetting, technician_payments: {} })

      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load Payment on page mount
  useEffect(() => {
    loadPayment()

    // unmount page
    return () => {
      setReportData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$updater])

  // Watch for deprecated
  useEffect(() => {
    if (!reportData) return
    setReportIsDeprecated(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    localInterface.min_date,
    localInterface.max_date
  ])

  useEffect(() => {
    if (!reportData) return
    setSearchButtonActive(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.search])

  // Update settings from back page
  useEffect(() => {
    if ($router.router.getState().params.localInterface) {
      setLocalInterface({
        ...$router.router.getState().params.localInterface
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$router.router.getState().params])

  // Render function
  return (
    <>
      {
        reportData &&
        <div className="JobsPage_List">
          { /* Page header */}
          <div className="page-header">
            <h1>Payments</h1>
          </div>

          { /* List filters */}
          <ReportFilters
            onSearchInputChange={(value) => setLocalInterface({ ...localInterface, search: value })}
            searchInput={localInterface.search}
            searchButtonActive={searchButtonActive}

            dateRangePreset={localInterface.dateRangePreset}
            onDateRangePresetChange={(value) => setLocalInterface({ ...localInterface, dateRangePreset: value, page: 1 })}

            minDate={localInterface.min_date}
            onMinDateChange={(value) => setLocalInterface({ ...localInterface, min_date: value, dateRangePreset: 'custom', page: 1 })}

            maxDate={localInterface.max_date}
            onMaxDateChange={(value) => setLocalInterface({ ...localInterface, max_date: value, dateRangePreset: 'custom', page: 1 })}

            updateButtonActive={reportIsDeprecated}
            onUpdate={() => $setUpdater(Math.random())}

            phoneCall={phoneCall}
            navActive={navActive.is}
          />

          {/* Table controls */}
          <ReportTableControls
            zIndex={5}

            cookie_reportsMaxRows={cookiesSetting?.technician_payments?.limit_rows}

            onMaxRowsChange={(value) => {
              setCookie('settings', { ...cookiesSetting, technician_payments: { ...cookiesSetting.technician_payments, limit_rows: value } })
              $setUpdater(Math.random())
            }}

            amount={{
              total: reportData.interface.rows_all,
              start: reportData.interface.rows_start,
              end: reportData.interface.rows_end
            }}

            page={localInterface.page}
            maxPages={reportData.interface.max_pages}
            onPagesStart={() => {
              setLocalInterface({ ...localInterface, page: 1 })
              $setUpdater(Math.random())
            }}
            onPrevPage={() => {
              setLocalInterface({ ...localInterface, page: localInterface.page - 1 })
              $setUpdater(Math.random())
            }}
            onNextPage={() => {
              setLocalInterface({ ...localInterface, page: localInterface.page + 1 })
              $setUpdater(Math.random())
            }}
            onPagesEnd={() => {
              setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })
              $setUpdater(Math.random())
            }}

            sort={localInterface.sort}
            sortFields={localInterface.sortFields}
            onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
            onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
            onSortFire={() => $setUpdater(Math.random())}
          />

          <div className="contents">

            {/* Wide desktop table */}
            <table className={classNames('table', '__show-on-wide',
              {
                __respectAsidePanel: navActive.is && !phoneCall,
                __phoneCall: phoneCall && !navActive.is,
                __bothOpen: navActive.is && phoneCall,
                __nonePanel: !navActive.is && !phoneCall
              }
            )}>
              <thead>
                <tr>
                  <ReportTableField
                    contents={(<span>Date</span>)}

                    sortDirection={localInterface.sort.field === 'created_at' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'created_at', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />
                  <ReportTableField
                    contents={(<span>Type</span>)}

                    sortDirection={localInterface.sort.field === 'type' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'type', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />
                  <ReportTableField
                    contents={(<span>Number</span>)}

                    sortDirection={localInterface.sort.field === 'number' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'number', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />
                  <ReportTableField
                    contents={(<span>Status</span>)}

                    onFilterFire={() => $setUpdater(Math.random())}

                    sortDirection={localInterface.sort.field === 'status' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'status', direction: value } })}
                  />
                  <ReportTableField
                    contents={(<span>Total</span>)}

                    sortDirection={localInterface.sort.field === 'total' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'total', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />
                  <ReportTableField
                    contents={(<span>Fee</span>)}

                    sortDirection={localInterface.sort.field === 'fee_compensation' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'fee_compensation', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />
                  <ReportTableField
                    contents={(<span>Jobs/Invoices</span>)}

                    sortDirection={localInterface.sort.field === 'items' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'items', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />
                </tr>
              </thead>
              {reportData.payments.map((pay, i) => (
                <tr
                  key={i}
                  style={{ cursor: 'inherit', position: 'relative' }}
                >
                  <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, pay.created_at)}</td>
                  <td>{pay.type}</td>
                  <td>{pay.number}</td>
                  <td style={{ color: pay.status === 'Succeeded' ? '#219653' : 'inherit' }} >{pay.status}</td>
                  <td>{pay.total}</td>
                  <td>
                    ${pay.fee_compensation}
                  </td>
                  <td> {pay.items} </td>
                </tr>
              ))}
            </table>

            {/* Medum screen table */}
            <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile',
              {
                __respectAsidePanel: navActive.is && !phoneCall,
                __phoneCall: phoneCall && !navActive.is,
                __bothOpen: navActive.is && phoneCall,
                __nonePanel: !navActive.is && !phoneCall
              }
            )}>
              <tr></tr>
              {reportData.payments.map((pay, i) => (
                <tr
                  key={i}
                  style={{ cursor: 'inherit', position: 'relative' }}
                >
                  <td>
                    <div>{pay.type}</div>
                    <div>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, pay.created_at)}</div>
                  </td>
                  <td>
                    <div>{pay.number}</div>
                  </td>
                  <td>
                    <div style={{ color: pay.status === 'Succeeded' ? '#219653' : 'inherit' }}>{pay.status}</div>
                    <div>Total: {pay.total}</div>
                  </td>
                  <td>
                    <div>
                      Fee: ${pay.fee_compensation}
                    </div>
                    <div>
                      {pay.items}
                    </div>
                  </td>
                </tr>
              ))}
            </table>

            {/* Mobile table items */}
            <div className={classNames('mobile-table-items', '__show-on-mobile',
              {
                __respectAsidePanel: navActive.is && !phoneCall,
                __phoneCall: phoneCall && !navActive.is,
                __bothOpen: navActive.is && phoneCall,
                __nonePanel: !navActive.is && !phoneCall
              }
            )}>

              {reportData.payments.map((pay, i: number) => (
                <div
                  className="item"
                  key={i}
                >
                  <div className="__top">
                    <div className="__left">
                      <div>
                        <strong>{pay.type}</strong> ({pay.number})
                      </div>
                      <div style={{ color: pay.status === 'Succeeded' ? '#219653' : 'inherit' }}>{pay.status}</div>
                    </div>

                    <div className="__right">
                      <div>
                        Total: <b>{pay.total}</b>
                      </div>
                      <div>
                        Fee: <b>${pay.fee_compensation}</b>
                      </div>
                    </div>
                  </div>

                  <div className="__bottom">
                    <div className="__left">
                      {pay.items}
                    </div>
                    <div className="__right small">
                      <div>
                        {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, pay.created_at)}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Table controls */}
          <ReportTableControls
            isUnder={true}

            cookie_reportsMaxRows={cookiesSetting?.technician_payments?.limit_rows}

            onMaxRowsChange={(value) => {
              setCookie('settings', { ...cookiesSetting, technician_payments: { ...cookiesSetting.technician_payments, limit_rows: value } })
              $setUpdater(Math.random())
            }}

            amount={{
              total: reportData.interface.rows_all,
              start: reportData.interface.rows_start,
              end: reportData.interface.rows_end
            }}

            page={localInterface.page}
            maxPages={reportData.interface.max_pages}
            onPagesStart={() => {
              setLocalInterface({ ...localInterface, page: 1 })
              $setUpdater(Math.random())
            }}
            onPrevPage={() => {
              setLocalInterface({ ...localInterface, page: localInterface.page - 1 })
              $setUpdater(Math.random())
            }}
            onNextPage={() => {
              setLocalInterface({ ...localInterface, page: localInterface.page + 1 })
              $setUpdater(Math.random())
            }}
            onPagesEnd={() => {
              setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })
              $setUpdater(Math.random())
            }}

            sort={localInterface.sort}
            sortFields={localInterface.sortFields}
            onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
            onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
            onSortFire={() => $setUpdater(Math.random())}
          />
        </div>
      }
    </>
  )
}

export default List