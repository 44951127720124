import { useEffect, useState } from 'react'
import classNames from 'classnames'

import Icon from '../../components/Icon'
import Checkbox from '../../components/Checkbox'

import { DataProps } from './LineItems'
import { httpClientUpdate } from '../../funcs'
import { useAppSelector } from '../../store/hooks'

interface AddDiscountsProps {
  setPage: (value: string) => void
  data: DataProps
  setData: (value: DataProps) => void
  handleSave: (name: string, comment: string, amount: number, type: string, is_save: boolean, id: string) => void
}

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: DataReportProps
}

interface DataReportProps {
  list_discounts: ListProps[]
}

interface ListProps {
  description: string
  list_discount_id: string
  name: string
  type: string
  amount: string
  is_recent: boolean
}

export default function AddDiscounts({ setPage, handleSave }: AddDiscountsProps) {
  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [search, setSearch] = useState('')

  const [addDiscount, setAddDiscount] = useState({ name: '', comment: '', amount: 0, type: 'amount', is_save: false, id: '' })
  const [isAddAmount, setIsAddAmount] = useState(false)
  const [isAddNewAmount, setIsAddNewAmount] = useState(false)

  const [report, setReport] = useState<DataReportProps | null>(null)

  const [services, setServices] = useState<ListProps[]>([])
  const [servicesUsed, setServicesUsed] = useState<ListProps[]>([])

  useEffect(() => {
    if (report) {
      let used: ListProps[] = []
      let elseServices: ListProps[] = []

      report.list_discounts.forEach(item => {
        if (item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
          if (item.is_recent) {
            if (used.length < 10) {
              used.push(item)
            } else {
              elseServices.push(item)
            }
          } else {
            elseServices.push(item)
          }
        }
      })

      setServices(elseServices)
      setServicesUsed(used)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  useEffect(() => {
    getStore()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getStore() {
    try {
      // https://2022back4.artemiudintsev.com/api/technician/discounts/report?account_id=88888
      const { data: { data: reportData, success } } = (await httpClientUpdate.get(`/technician/discounts/report`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        },
        params: {
          account_id: activeAccountId
        }
      })) as { data: HttpClientUpdateReport }
      if (success) {
        setReport(reportData)
        setServices(reportData.list_discounts)
      }

    }
    catch (error) { }
  }

  async function handleSaveNewDiscount() {
    try {
      if (addDiscount.is_save) {
        // /api/technician/discounts
        const response = await httpClientUpdate.post(`/technician/discounts`, {
          account_id: activeAccountId,
          name: addDiscount.name,
          description: addDiscount.comment,
          amount: Number(addDiscount.amount).toFixed(2),
          type: addDiscount.type,
        })

        if (response.data.success) {
          handleSave(addDiscount.name, addDiscount.comment, addDiscount.amount, addDiscount.type, addDiscount.is_save, addDiscount.id)
          setAddDiscount({ name: '', comment: '', amount: 0, type: 'amount', is_save: false, id: '' })
          setIsAddNewAmount(false)
        } else {
        }
      } else {
        handleSave(addDiscount.name, addDiscount.comment, addDiscount.amount, addDiscount.type, addDiscount.is_save, addDiscount.id)
      }
    } catch (error) { }
  }

  function handleAddService(name: string, comment: string, amount: number, type: string, id: string) {
    setAddDiscount({ name: name, comment: comment, amount: amount, type: type, is_save: false, id })
    setIsAddAmount(true)
  }

  function handleCancelAddService() {
    setAddDiscount({ name: '', comment: '', amount: 0, type: 'amount', is_save: false, id: '' })
    setIsAddAmount(false)
    setIsAddNewAmount(false)
  }

  function handleSaveService() {
    handleSave(addDiscount.name, addDiscount.comment, addDiscount.amount, addDiscount.type, addDiscount.is_save, addDiscount.id)

    setAddDiscount({ name: '', comment: '', amount: 0, type: 'amount', is_save: false, id: '' })
    setIsAddAmount(false)
  }

  function handleJobFocus(event: any) {
    const target = event.currentTarget;

    target.type = 'text';
    target.setSelectionRange(target.value.length, target.value.length);
    target.type = 'number';
    target && target.focus()
  }

  function handleJobChangeItem(event: any) {
    let price = event.target.value

    let numberWithoutDot = price.split('.')

    if (Number(price) >= 0) {
      if (numberWithoutDot[1] && numberWithoutDot[1].toString().length > 2) {
        let newNumber = `${numberWithoutDot[0]}${numberWithoutDot[1].toString()[0]}.${numberWithoutDot[1].toString()[1]}${price[price.length - 1]}`
        setAddDiscount({ ...addDiscount, amount: Number(newNumber) })
      } else {
        let newNumber = price

        setAddDiscount({ ...addDiscount, amount: Number(newNumber) })
      }
    }
  }

  function handleJobChangeItemBackspace(event: any) {
    if (event.keyCode === 8) {
      event.preventDefault()
      let price = event.target.value
      let numberWithoutDot = `${price}`.split('.')
      if (numberWithoutDot[0].length > 1) {
        let newNumber = `${numberWithoutDot[0].slice(0, -1)}.${numberWithoutDot[0][numberWithoutDot[0].length - 1]}${numberWithoutDot[1][0]}`
        setAddDiscount({ ...addDiscount, amount: Number(newNumber) })
      } else {
        let newNumber = `0.${numberWithoutDot[0][0]}${numberWithoutDot[1][0]}`
        setAddDiscount({ ...addDiscount, amount: Number(newNumber) })
      }
    }
  }

  function checkError() {
    let check = false

    if (addDiscount.type === 'percentage' && Number(addDiscount.amount) > 100) {
      check = true
    }

    return check
  }

  return (
    <div className="EstimatePage_Add entity-edit">
      {
        !isAddAmount && !isAddNewAmount &&
        <div className="wrapper flex-container sb">
          <div className='row'>
            <button
              style={{ background: 'inherit' }}
              className="_wa"
              onClick={() => setPage('editing')}
            >
              <Icon
                style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#005eb5' }}
                viewBox="0 0 24 24"
                icon="arrow-25"
              />
            </button>

            <h1 style={{ whiteSpace: 'nowrap', marginTop: '0', color: '#005eb5' }}>New Estimate</h1>
          </div>

          <div className='row'>
            <button
              className="_zeroed _iconed _blue"
              style={{ color: '#005eb5', fontSize: '24px' }}
              onClick={() => setIsAddNewAmount(true)}
            >
              +
            </button>
          </div>
        </div>
      }

      {
        isAddAmount && !isAddNewAmount &&
        <div className="wrapper flex-container sb editing">
          <div>
            <button
              onClick={handleCancelAddService}
              className="button-cancel"
            >
              Cancel
            </button>
          </div>

          <div>
            <h1 style={{ whiteSpace: 'nowrap', marginTop: '0', color: '#393939' }}>Discount</h1>
          </div>

          <div>
            <button
              className={classNames('button-save-disabled', {
                'button-save': addDiscount.amount !== 0
              })}
              onClick={() => addDiscount.amount !== 0 && handleSaveService()}
            >
              Add
            </button>
          </div>
        </div>
      }

      {
        isAddNewAmount &&
        <div className="wrapper flex-container sb editing">
          <div>
            <button
              onClick={handleCancelAddService}
              className="button-cancel"
            >
              Cancel
            </button>
          </div>

          <div>
            <h1 style={{ whiteSpace: 'nowrap', marginTop: '0', color: '#393939' }}>New Discount</h1>
          </div>

          <div>
            <button
              className={classNames('button-save-disabled', {
                'button-save': addDiscount.amount !== 0 && addDiscount.name !== '' && !checkError()
              })}
              onClick={() => addDiscount.amount !== 0 && addDiscount.name !== '' && !checkError() && handleSaveNewDiscount()}
            >
              Add
            </button>
          </div>
        </div>
      }

      {
        isAddAmount && !isAddNewAmount &&
        <div className='line-items-content service'>
          <div className='line-items-row'>
            <div className='name'>
              Name
            </div>

            <div className='name grey input-name'>
              <div className='input-view'>
                {addDiscount.name}
              </div>
            </div>
          </div>

          <div className='line-items-row'>
            <div className='name'>
              Description
            </div>

            <div className='name grey input-name'>
              <div className='input-view'>
                {addDiscount.comment}
              </div>
            </div>
          </div>

          <div className='line-items-row'>
            <div className='name'>
              Type
            </div>

            <div
              className='line-items-tabs'
              style={{ margin: '0' }}
            >
              <div
                className={classNames('line-items-tab', { active: addDiscount.type === 'amount' })}
                style={{ cursor: 'inherit' }}
              >
                Fixed Amount ($)
              </div>

              <div
                className={classNames('line-items-tab', { active: addDiscount.type === 'percentage' })}
                style={{ cursor: 'inherit' }}
              >
                Percentage (%)
              </div>
            </div>
          </div>

          <div className='line-items-row'>
            <div className='name'>
              Amount
            </div>

            <div className='name grey'>
              <div className='input-view'>
                {addDiscount.type === 'amount' ? `$${addDiscount.amount}` : `${addDiscount.amount}%`}
              </div>
            </div>
          </div>

          <span className='note'> Discount name appears on invoice </span>
        </div>
      }

      {
        isAddNewAmount &&
        <div className='line-items-content service'>
          <div className='line-items-row'>
            <div className='name'>
              Name
            </div>

            <div className='name grey input-name'>
              <input
                type="text"
                placeholder='Discount name'
                value={addDiscount.name}
                onChange={(event) => setAddDiscount({ ...addDiscount, name: event.target.value })}
              />
            </div>
          </div>

          <div className='line-items-row'>
            <div className='name'>
              Description
            </div>

            <div className='name grey input-name'>
              <textarea
                placeholder='Description'
                rows={2}
                value={addDiscount.comment}
                onChange={(event) => setAddDiscount({ ...addDiscount, comment: event.target.value })}
              />
            </div>
          </div>

          <div className='line-items-row'>
            <div className='name'>
              Type
            </div>

            <div
              className='line-items-tabs'
              style={{ margin: '0' }}
            >
              <div
                className={classNames('line-items-tab', { active: addDiscount.type === 'amount' })}
                onClick={() => setAddDiscount({ ...addDiscount, type: 'amount' })}
              >
                Fixed Amount ($)
              </div>

              <div
                className={classNames('line-items-tab', { active: addDiscount.type === 'percentage' })}
                onClick={() => setAddDiscount({ ...addDiscount, type: 'percentage' })}
              >
                Percentage (%)
              </div>
            </div>
          </div>

          <div className='line-items-row'>
            <div className='name'>
              Amount
            </div>

            <div className='name grey'>
              <input
                className={classNames('appearance',
                  { '_error': checkError() })}
                type='number'
                value={`${Number(addDiscount.amount).toFixed(2)}`}
                step={0.01}
                onFocus={(event) => handleJobFocus(event)}
                onChange={(event) => handleJobChangeItem(event)}
                onKeyDown={(event) => handleJobChangeItemBackspace(event)}
              />
            </div>
          </div>

          <div className='line-items-row'>
            <div className=''>
              Save to price book
            </div>

            <Checkbox
              contents=""
              value={addDiscount.is_save}
              onChange={(value) => setAddDiscount({ ...addDiscount, is_save: value })}
            />
          </div>

          <span className='note'> Discount name appears on invoice </span>
        </div>
      }

      {
        !isAddAmount && !isAddNewAmount &&
        <div className='line-items-content service'>
          <div className='line-items-col'>
            <div className='line-items-title bold'>
              Discounts
            </div>

            <input
              type="text"
              placeholder='Search'
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />
          </div>

          <div className='line-items-col'>
            <div className='line-items-title'>
              Frequently used items
            </div>

            {
              servicesUsed
                .map(item => (
                  <div
                    key={item.name}
                    className="services-item"
                    onClick={() => handleAddService(item.name, item.description, Number(item.amount), item.type, item.list_discount_id)}
                  >
                    <div className='services-item-info'>
                      <div className='services-item-info-name'>
                        {item.name}
                      </div>

                      <div className='services-item-info-comment'>
                        {item.description}
                      </div>
                    </div>

                    <div className='services-item-info-price'>
                      <div
                        onClick={(event) => {
                          event.stopPropagation()
                          handleAddService(item.name, item.description, Number(item.amount), item.type, item.list_discount_id)
                        }}
                      >
                        <Icon
                          style={{ width: '16px', height: '16px', fill: '#ccc' }}
                          viewBox="0 0 24 24"
                          icon="arrow-25"
                        />
                      </div>

                      <div className='services-item-info-comment'>
                        {item.type === 'amount' ? `$${item.amount}` : `${item.amount}%`}
                      </div>
                    </div>
                  </div>
                ))
            }
          </div>

          <div
            className='line-items-col'
            style={{ border: 'none' }}
          >
            <div className='line-items-title'>
              Discounts
            </div>

            <div
              className="services-item"
              onClick={() => setIsAddNewAmount(true)}
            >
              <div> Custom Discount </div>

              <Icon
                style={{ width: '16px', height: '16px', fill: '#ccc' }}
                viewBox="0 0 24 24"
                icon="arrow-25"
              />
            </div>

            {
              services
                .map(item => (
                  <div
                    key={item.name}
                    className="services-item"
                    onClick={() => handleAddService(item.name, item.description, Number(item.amount), item.type, item.list_discount_id)}
                  >
                    <div className='services-item-info'>
                      <div className='services-item-info-name'>
                        {item.name}
                      </div>

                      <div className='services-item-info-comment'>
                        {item.description}
                      </div>
                    </div>


                    <div className='services-item-info-price'>
                      <div
                        onClick={(event) => {
                          event.stopPropagation()
                          handleAddService(item.name, item.description, Number(item.amount), item.type, item.list_discount_id)
                        }}
                      >
                        <Icon
                          style={{ width: '16px', height: '16px', fill: '#ccc' }}
                          viewBox="0 0 24 24"
                          icon="arrow-25"
                        />
                      </div>

                      <div className='services-item-info-comment'>
                        {item.type === 'amount' ? `$${item.amount}` : `${item.amount}%`}
                      </div>
                    </div>
                  </div>
                ))
            }
          </div>
        </div>
      }
    </div>
  )
}
