import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie';
import { useRoute } from 'react-router5';

import Icon from '../../components/Icon';
import ReportFilters from '../../components/reports/Filters';
import ReportTableControls from '../../components/reports/TableControls';
import { httpClientUpdate, nErrorUpdate } from '../../funcs';

import { useAppSelector } from '../../store/hooks';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import './Index.sass'

export interface HttpReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: DataProps
}

interface DataProps {
  permissions: {
    instruction_library_add: boolean
    instruction_library_show: boolean
  }
  interface: {
    max_pages: number,
    rows_start: number,
    rows_end: number,
    rows_all: number,
  }
  edit: LibraryEditProps[]

  instruction_libraries: InstructionLibrariesProps[]
}

export interface LibraryEditProps {
  name: string
  user_group_id: string
}

interface InstructionLibrariesProps {
  attachments: []
  created_at: string
  created_by: string
  instruction_library_id: string
  is_viewed: boolean
  priority: number
  text: string
  theme: string
  updated_at: string
  user_groups: []
}

export default function List({ updated }: { updated: number }) {
  const $router = useRoute();

  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [cookies, setCookie] = useCookies();

  const cookiesSetting = cookies.settings ? cookies.settings : null

  const [$updater, $setUpdater] = useState<any>(Math.random())

  const [searchButtonActive, setSearchButtonActive] = useState(false)

  const [reportData, setReportData] = useState<DataProps | null>(null)

  const [localInterface, setLocalInterface] = useState({
    search: '',
    page: 1,
    max_rows: 100 as 50 | 100 | 250 | 500,
    sort: {
      field: 'name',
      direction: 'up' as 'down' | 'up'
    },
    sortFields: []
  })

  useEffect(() => {
    updated && setLocalInterface({
      ...localInterface,
      search: '',
      page: 1,
      max_rows: 100 as 50 | 100 | 250 | 500,
      sort: {
        field: 'name',
        direction: 'up' as 'down' | 'up'
      },
    })
    updated && $setUpdater(updated)
    updated && setSearchButtonActive(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  async function loadReport() {
    try {
      // api/instruction-libraries/report?account_id=88888&limit_rows=100&page=1
      const { data: { data: report, success, error } } = (await httpClientUpdate.get('/instruction-libraries/report', {
        params: {
          account_id: activeAccountId,
          limit_rows: localInterface.max_rows,
          page: localInterface.page,
          ...(localInterface.search ? { search: localInterface.search } : {})
        }
      })) as { data: HttpReport }
      if (success) {
        setReportData(report)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {

      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }


  // Load clients on page mount
  useEffect(() => {
    loadReport()

    // unmount page
    return () => {
      setReportData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    $updater,
    localInterface.page
  ])

  useEffect(() => {
    if (!reportData) return
    setSearchButtonActive(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.search])

  // Update settings from back page
  useEffect(() => {
    if ($router.router.getState().params.localInterface) {
      setLocalInterface({
        ...$router.router.getState().params.localInterface
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$router.router.getState().params])

  return (
    <>
      {
        reportData &&
        <div className="ClientsPage_List" >
          <div className="page-header">
            <h1>Instruction Library</h1>
          </div>

          { /* List filters */}
          <ReportFilters
            onSearchInputChange={(value) => setLocalInterface({ ...localInterface, search: value })}
            searchInput={localInterface.search}
            searchButtonActive={searchButtonActive}

            onUpdate={() => {
              $setUpdater(Math.random());
            }}

            phoneCall={phoneCall}
            navActive={navActive.is}
          />

          {/* Table controls */}
          <ReportTableControls
            zIndex={5}
            isSort={true}

            cookie_reportsMaxRows={cookiesSetting?.clients?.limit_rows}

            onMaxRowsChange={(value) => {
              setCookie('settings', { ...cookiesSetting, clients: { ...cookiesSetting.clients, limit_rows: value } })
              $setUpdater(Math.random())
            }}

            amount={{
              total: 1,
              start: 1,
              end: 1
            }}

            page={localInterface.page}
            maxPages={1}
            onPagesStart={() => setLocalInterface({ ...localInterface, page: 1 })}
            onPrevPage={() => setLocalInterface({ ...localInterface, page: localInterface.page - 1 })}
            onNextPage={() => setLocalInterface({ ...localInterface, page: localInterface.page + 1 })}
            onPagesEnd={() => setLocalInterface({ ...localInterface, page: 1 })}

            sort={localInterface.sort}
            sortFields={localInterface.sortFields}
            onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
            onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
            onSortFire={() => $setUpdater(Math.random())}

            addButton={
              reportData.permissions.instruction_library_add ?
                (
                  <div className="add-button-wrapper">
                    <button
                      className="_iconed _rounded add-button"
                      onClick={() => $router.router.navigate('instruction_library.new', {
                        companyId: activeAccountId,
                        localInterface: localInterface,
                      })}
                    >
                      <Icon icon="plus-thin" />
                      <span>Add Instruction</span>
                      <Icon icon="time-16" />
                    </button>
                  </div>
                ) : (<></>)
            }
          />
          <div className="contents">
            {
              reportData.instruction_libraries.map((item) => (
                <div
                  className='contents-col'
                  key={item.instruction_library_id}
                  style={{ cursor: reportData.permissions.instruction_library_show ? 'pointer' : 'inherit' }}
                  onClick={() =>
                    reportData.permissions.instruction_library_show &&
                    $router.router.navigate('instruction_library.item', {
                      companyId: activeAccountId,
                      libraryId: item.instruction_library_id,
                      localInterface: localInterface,
                    }, { reload: true })
                  }
                >
                  <div className='contents-row-foot'>
                    <Icon style={{ fill: item.is_viewed ? '#ccc' : '#01B41F' }} icon="check-mark-1" />
                    {item.theme}
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      }
    </>
  )
}
