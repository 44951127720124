import {
  useStripe,
  useElements,
  CardElement
} from '@stripe/react-stripe-js';

interface CheckoutFormProps {
  cancel: () => void
  disabled: boolean
  setLoading: (value: boolean) => void
  disabledCancel: boolean
  handleSaveStripePay: (value: string) => void
  error_limit: boolean
  setErrorPaymentLimit: (value: boolean) => void
  error_amount: boolean
  setErrorPaymentAmount: (value: boolean) => void
}

const CheckoutForm = ({
  cancel,
  disabled,
  setLoading,
  disabledCancel,
  handleSaveStripePay,
  error_limit,
  setErrorPaymentLimit,
  error_amount,
  setErrorPaymentAmount,
}: CheckoutFormProps) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setLoading(true)

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const cardNumberElement = elements.getElement(CardElement)

    try {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { token, error } = await stripe.createToken(cardNumberElement)

      if (token) {
        setLoading(false)
        handleSaveStripePay(token.id)
      } else {
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  };

  const style = {
    base: {
      color: 'rgba(43, 43, 43, .7)',
      fontSize: '16px',
    }
  }

  function hamdleCancel(event: any) {
    event.preventDefault();
    cancel()
  }

  function handleLimit(event: any) {
    event.preventDefault();
    setErrorPaymentLimit(true)
  }

  function handleAmount(event: any) {
    event.preventDefault();
    setErrorPaymentAmount(true)
  }

  return (
    <form
      className='stripe-form'
    >
      <div className='stripe-row'>
        <CardElement options={{ style }} />
      </div>

      <div className='payment-page-actions'>
        <button
          className="_bordered _red"
          onClick={hamdleCancel}
          disabled={disabledCancel}
        >
          Cancel
        </button>

        <button
          className="_bordered _green"
          style={{ whiteSpace: 'nowrap' }}
          disabled={disabled}
          onClick={(event) => error_limit ?
            handleLimit(event) :
            error_amount ?
              handleAmount(event) :
              handleSubmit(event)}
        >
          Pay
        </button>
      </div>
    </form>
  )
};

export default CheckoutForm;