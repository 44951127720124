import { memo } from 'react'

import HomeContent from './HomeContent'
import CallsContent from './CallsContent'
import DialContent from './DialContent'
import ContactsContent from './ContactsContent'

import { CallsDataProps, DispatchersProps, PermissionsProps } from '../../App'

interface ContactProps {
  company_name: string
  first_name: string
  last_name: string
  phone: string
}

interface SoftphoneContentProps {
  tap: string

  actionMenu: string[]
  setActionMenu: (value: string[]) => void

  actionMenuCompetedCall: string[]
  setActionMenuCompetedCall: (value: string[]) => void

  infoBlock: string[]
  setInfoBlock: (value: string[]) => void

  infoBlockCompetedCall: string[]
  setInfoBlockCompetedCall: (value: string[]) => void

  infoBlockIncoming: string[]
  setInfoBlockIncoming: (value: string[]) => void

  pin: boolean
  setPin: (value: boolean) => void

  callsData: CallsDataProps[]
  stop: (value: string) => void
  disconnectedCall: (value: string) => void
  handleAnswer: (value: string) => void
  stoptalk: (value: string) => void
  handleRemoveCompletedCall: (value: string) => void

  number: string
  setNumber: (value: string) => void

  handleAddComment: (sid: string, tags: string, comment: string) => void

  permissions: Partial<PermissionsProps>

  selectedNumberFromContact: Partial<ContactProps>
  handleSelectedContact: (value: ContactProps) => void

  handleDialCall: (to: string, phone_id: string) => void

  phoneCallStatus: string

  handleCallExtension: (value: string) => void
  setTap: (value: string) => void

  dispatcherTwillio: DispatchersProps[]
  handleRedirectCall: (call_sid: string, dispatcher_id: string) => void
  handlePauseCall: (call_sid: string) => void
  handleResumeCall: (call_sid: string) => void

  selectedCallCenter: string
}

function SoftphoneContent({
  tap,
  setTap,
  actionMenu,
  setActionMenu,
  infoBlock,
  setInfoBlock,
  pin,
  setPin,
  actionMenuCompetedCall,
  setActionMenuCompetedCall,
  infoBlockCompetedCall,
  setInfoBlockCompetedCall,
  callsData,
  stop,
  handleAnswer,
  stoptalk,
  number,
  setNumber,
  handleRemoveCompletedCall,
  infoBlockIncoming,
  setInfoBlockIncoming,
  handleAddComment,
  permissions,
  selectedNumberFromContact,
  handleSelectedContact,
  handleDialCall,
  phoneCallStatus,
  handleCallExtension,
  disconnectedCall,
  dispatcherTwillio,
  handleRedirectCall,
  handlePauseCall,
  handleResumeCall,
  selectedCallCenter,
}: SoftphoneContentProps) {
  return (
    <div className='section__account_softphone-content'>
      {/* Content Item */}
      {
        tap === 'Home' &&
        <HomeContent
          actionMenu={actionMenu}
          setActionMenu={setActionMenu}
          infoBlock={infoBlock}
          setInfoBlock={setInfoBlock}
          pin={pin}
          setPin={setPin}
          actionMenuCompetedCall={actionMenuCompetedCall}
          setActionMenuCompetedCall={setActionMenuCompetedCall}
          infoBlockCompetedCall={infoBlockCompetedCall}
          setInfoBlockCompetedCall={setInfoBlockCompetedCall}
          callsData={callsData}
          stop={stop}
          disconnectedCall={disconnectedCall}
          handleAnswer={handleAnswer}
          stoptalk={stoptalk}
          handleRemoveCompletedCall={handleRemoveCompletedCall}
          infoBlockIncoming={infoBlockIncoming}
          setInfoBlockIncoming={setInfoBlockIncoming}
          handleAddComment={handleAddComment}
          permissions={permissions}
          dispatcherTwillio={dispatcherTwillio}
          handleRedirectCall={handleRedirectCall}
          handlePauseCall={handlePauseCall}
          handleResumeCall={handleResumeCall}
        />
      }
      {
        tap === 'Dial' &&
        <DialContent
          number={number}
          setNumber={setNumber}
          selectedNumberFromContact={selectedNumberFromContact}
          handleDialCall={handleDialCall}
          softphone_call_dial={permissions.softphone_call_dial}
          selectedCallCenter={selectedCallCenter}
        />
      }
      {
        tap === 'Calls' &&
        <CallsContent
          phoneCallStatus={phoneCallStatus}
          handleCallExtension={handleCallExtension}
          setTap={setTap}
          call_call_report={permissions.call_call_report}
          softphone_call_extension={permissions.softphone_call_extension}
        />
      }
      {
        tap === 'Contacts' &&
        <ContactsContent
          handleSelectedContact={handleSelectedContact}
          softphone_phonebook={permissions.softphone_phonebook}
        />
      }
    </div>
  )
}

export default memo(SoftphoneContent)