import { useEffect, useState } from 'react'
import { useRoute } from 'react-router5'
import classNames from 'classnames'

import Icon from '../../components/Icon'
import ReportFilters from '../../components/reports/Filters'
import ReportTableControls from '../../components/reports/TableControls'
import ReportTableField from '../../components/reports/TableField'

import { httpClientUpdate, nErrorUpdate, useOuterClick } from '../../funcs'
import { useAppSelector } from '../../store/hooks'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

interface HttpNotifyReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: Report
}

export interface Report {
  interface: {
    max_pages: number,
    rows_start: number,
    rows_end: number,
    rows_all: number,
  },

  permissions: {
    template_add: boolean
    template_show: boolean
    template_attachment_add: boolean
  },

  edit: {
    companies: CompaniesProps[]
  }

  templates: {
    created_at: string
    created_by: string
    document: string
    template_id: string
    type: string
    updated_at: string
    updated_by: string
    company_id: string
    company_name: string
  }[]
}

export interface CompaniesProps {
  company_id: string
  name: string
}

export default function List({ updated }: { updated: number }) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)

  const [searchButtonActive, setSearchButtonActive] = useState(false)
  const [$updater, $setUpdater] = useState<any>(Math.random())

  const [reportData, setReportData] = useState<Report | null>(null)

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [localInterface, setLocalInterface] = useState({
    search: '',
    page: 1,
    filter_words: {
      type: [] as string[],
      notify_type: [] as string[]
    },
    sort: {
      field: 'type',
      direction: 'down' as 'down' | 'up'
    },
    sortFields: [{
      span: 'Document',
      value: 'document'
    }, {
      span: 'Type',
      value: 'type'
    }, {
      span: 'Company',
      value: 'company_id'
    },]
  })

  async function loadNotify() {
    let activeFilters: any = {}

    Object.keys(localInterface.filter_words).forEach((key: any) => {
      if (localInterface.filter_words[key].length) {
        activeFilters[`filters[${key}]`] = localInterface.filter_words[key]
      }
    })

    let filterParams = {}

    if ($router.router.getState().params) {
      if ($router.router.getState().params.localInterface) {
        filterParams = {
          page: $router.router.getState().params.localInterface.page,
          sort_field: $router.router.getState().params.localInterface.sort.field,
          sort_type: $router.router.getState().params.localInterface.sort.direction === 'up' ? 'asc' : 'desc',
          ...activeFilters,
          ...($router.router.getState().params.localInterface.search ? { search: $router.router.getState().params.localInterface.search } : {})
        }
      } else {
        filterParams = {
          page: localInterface.page,
          sort_field: localInterface.sort.field,
          sort_type: localInterface.sort.direction === 'up' ? 'asc' : 'desc',
          ...activeFilters,
          ...(localInterface.search ? { search: localInterface.search } : {})
        }
      }
    }

    try {
      // https://2022back4.artemiudintsev.com/api/templates/report
      const { data: { data: report, success, error } } = await httpClientUpdate.get('/templates/report',
        {
          params: {
            account_id: activeAccountId,
            limit_rows: reportsMaxRows,
            ...filterParams,
          }
        }) as { data: HttpNotifyReport }

      if (success) {
        setReportData(report)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => {
        setSearchButtonActive(false)
      }, 100)
    }
    catch (error: Error | AxiosError | unknown) {

      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load accounts on page mount
  useEffect(() => {
    loadNotify()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    $updater,
    localInterface.page
  ])

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  return (
    <>
      {
        reportData &&
        <div className="AccountsPage_List" >
          { /* Page header */}
          <div className="page-header">
            <h1>Templates</h1>
          </div>

          { /* List filters */}
          <ReportFilters
            onSearchInputChange={(value) => setLocalInterface({ ...localInterface, search: value })}
            searchInput={localInterface.search}
            searchButtonActive={searchButtonActive}

            onUpdate={() => {
              $setUpdater(Math.random())
            }}

            phoneCall={phoneCall}
            navActive={navActive.is}
          />

          {/* Table controls */}
          <ReportTableControls
            zIndex={5}

            onMaxRowsChange={(value) => $setUpdater(Math.random())}

            amount={{
              total: 1,
              start: 1,
              end: 1
            }}

            page={localInterface.page}
            maxPages={1}
            onPagesStart={() => setLocalInterface({ ...localInterface, page: 1 })}
            onPrevPage={() => setLocalInterface({ ...localInterface, page: localInterface.page - 1 })}
            onNextPage={() => setLocalInterface({ ...localInterface, page: localInterface.page + 1 })}
            onPagesEnd={() => setLocalInterface({ ...localInterface, page: 1 })}

            sort={localInterface.sort}
            sortFields={localInterface.sortFields}
            onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
            onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
            onSortFire={() => $setUpdater(Math.random())}

            addButton={
              reportData.permissions.template_add ?
                <div className="add-button-wrapper">
                  <button className="_iconed _rounded add-button" onClick={() => $router.router.navigate('templates.new_templates', {
                    companyId: activeAccountId,
                    localInterface: localInterface,
                  })}>
                    <Icon icon="plus-thin" />
                    <span>Add Template</span>
                    <Icon icon="user-1" />
                  </button>
                </div> :
                <></>
            }
          />

          <div className="contents">
            <table className={classNames('table',
              {
                __respectAsidePanel: navActive.is && !phoneCall,
                __phoneCall: phoneCall && !navActive.is,
                __bothOpen: navActive.is && phoneCall,
                __nonePanel: !navActive.is && !phoneCall
              }
            )}>
              <thead>
                <tr>
                  <ReportTableField
                    contents={(<span>Document</span>)}

                    sortDirection={localInterface.sort.field === 'document' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'document', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />

                  <ReportTableField
                    contents={(<span>Type</span>)}

                    sortDirection={localInterface.sort.field === 'type' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'type', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />

                  <ReportTableField
                    contents={(<span>Company</span>)}

                    sortDirection={localInterface.sort.field === 'company_id' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'company_id', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />

                  <th> Clone </th>
                </tr>
              </thead>

              {
                reportData.templates.map((template, i) => (
                  <tr
                    key={template.template_id}
                    style={{ cursor: reportData.permissions.template_show && template.template_id ? 'pointer' : 'inherit', position: 'relative' }}
                    onClick={() =>
                      reportData.permissions.template_show &&
                      template.template_id &&
                      $router.router.navigate('templates.item', {
                        companyId: activeAccountId,
                        templatesId: template.template_id,
                        localInterface: localInterface,
                      }, { reload: true })}
                    onContextMenu={(event) =>
                      reportData.permissions.template_show &&
                      template.template_id &&
                      handleChangeMenu(event, template.template_id, 'templates', i)}
                  >
                    <td>
                      {template.document}
                    </td>

                    <td>
                      {template.type}
                    </td>

                    <td> {template.company_name} </td>

                    <td
                      onClick={(event) => {
                        event.stopPropagation()
                        event.preventDefault()
                        $router.router.navigate('templates.new_templates', {
                          companyId: activeAccountId,
                          templatesId: template.template_id,
                          localInterface: localInterface,
                        }, { reload: true })
                      }}
                    >
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Icon
                          style={{
                            width: '20px',
                            minWidth: '20px',
                            fill: '#8391AD'
                          }}
                          viewBox='0 0 1024 1024'
                          icon="clone-24"
                        />
                      </div>
                      {
                        showContext === `templates_${template.template_id}_${i}` &&
                        <div
                          className="redirect-menu"
                          ref={contextMenuRef}
                          style={{ left: `${screenX}px` }}
                        >
                          <button
                            onClick={(event) => {
                              event.preventDefault()
                              $router.router.navigate('templates.item', {
                                companyId: activeAccountId,
                                templatesId: template.template_id,
                                localInterface: localInterface,
                              }, { reload: true })
                            }
                            }
                          >
                            Open
                          </button>

                          <button
                            onClick={(event) => {
                              event.preventDefault()
                              event.stopPropagation()
                              window.open(`${window.location.origin}/${activeAccountId}/templates/${template.template_id}`, "_blank", 'noopener,noreferrer')
                              setShowContext('')
                            }}
                          >
                            Open in new tab
                          </button>
                        </div>
                      }
                    </td>

                  </tr>
                ))
              }
            </table>
          </div>
        </div>
      }
    </>
  )
}
