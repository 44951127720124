import { useEffect, useState } from 'react'
import classNames from 'classnames'
import moment, { Moment } from 'moment'
import Datetime from 'react-datetime'

import { DataProps } from './LineItems'

interface AddDepositeProps {
  setPage: (value: string) => void
  data: DataProps
  setData: (value: DataProps) => void
  handleSave: (value: DepositeProps) => void
  deposite: DepositeProps
}

export interface DepositeProps {
  type: string
  amount: number
  date: Moment | Date | string
}

export default function AddDeposite({ deposite, setPage, handleSave }: AddDepositeProps) {
  const [data, setData] = useState<DepositeProps>({ type: '', amount: 0, date: '' })

  let yesterday = moment().subtract(1, 'day');

  useEffect(() => {
    setData({ ...deposite })
  }, [deposite])

  function handleJobFocus(event: any) {
    const target = event.currentTarget;

    target.type = 'text';
    target.setSelectionRange(target.value.length, target.value.length);
    target.type = 'number';
    target && target.focus()
  }

  function handleJobChangeItem(event: any) {
    let price = event.target.value

    let numberWithoutDot = price.split('.')

    if (Number(price) >= 0) {
      if (numberWithoutDot[1] && numberWithoutDot[1].toString().length > 2) {
        let newNumber = `${numberWithoutDot[0]}${numberWithoutDot[1].toString()[0]}.${numberWithoutDot[1].toString()[1]}${price[price.length - 1]}`
        setData({ ...data, amount: Number(newNumber) })
      } else {
        let newNumber = price

        setData({ ...data, amount: Number(newNumber) })
      }
    }
  }

  function handleJobChangeItemBackspace(event: any) {
    if (event.keyCode === 8) {
      event.preventDefault()
      let price = event.target.value
      let numberWithoutDot = `${price}`.split('.')
      if (numberWithoutDot[0].length > 1) {
        let newNumber = `${numberWithoutDot[0].slice(0, -1)}.${numberWithoutDot[0][numberWithoutDot[0].length - 1]}${numberWithoutDot[1][0]}`
        setData({ ...data, amount: Number(newNumber) })
      } else {
        let newNumber = `0.${numberWithoutDot[0][0]}${numberWithoutDot[1][0]}`
        setData({ ...data, amount: Number(newNumber) })
      }
    }
  }

  function validDate(current: Moment) {
    return current.isAfter(yesterday)
  }

  function checkError() {
    let check = false

    if (data.type === 'percent' && Number(data.amount) > 100) {
      check = true
    }

    return check
  }

  return (
    <div className="EstimatePage_Add entity-edit">
      <div className="wrapper flex-container sb editing">
        <div>
          <button
            className="button-cancel"
            onClick={() => setPage('editing')}
          >
            Cancel
          </button>
        </div>

        <div>
          <h1 style={{ whiteSpace: 'nowrap', marginTop: '0', color: '#393939' }}>Add Deposit</h1>
        </div>

        <div>
          <button
            className={classNames('button-save-disabled', {
              'button-save': (deposite.amount !== data.amount || deposite.type !== data.type || moment(deposite.date).format('MM/DD/YYYY') !== moment(data.date).format('MM/DD/YYYY')) && !checkError()
            })}
            onClick={() => (deposite.amount !== data.amount || deposite.type !== data.type || moment(deposite.date).format('MM/DD/YYYY') !== moment(data.date).format('MM/DD/YYYY')) && !checkError() && handleSave(data)}
          >
            {
              deposite.amount === 0 ? 'Add' : 'Save'
            }
          </button>
        </div>
      </div>

      <div className='line-items-content service'>
        {/* <div className='line-items-row'>
          <div
            className='line-items-tabs'
            style={{ margin: '0', width: '100%' }}
          >
            <div
              className={classNames('line-items-tab', { active: data.type === 'amount' })}
              onClick={() => setData({ ...data, type: 'amount' })}
            >
              Dollar
            </div>

            <div
              className={classNames('line-items-tab', { active: data.type === 'percent' })}
              onClick={() => setData({ ...data, type: 'percent' })}
            >
              Percent
            </div>
          </div>
        </div> */}

        <div className='line-items-row'>
          <div className='name grey input-name'>
            <input
              className={classNames('appearance',
                { '_error': checkError() })}
              type='number'
              value={`${Number(data.amount).toFixed(2)}`}
              step={0.01}
              onFocus={(event) => handleJobFocus(event)}
              onChange={(event) => handleJobChangeItem(event)}
              onKeyDown={(event) => handleJobChangeItemBackspace(event)}
            />
          </div>
        </div>

        <div className='line-items-row'>
          <div className='name'>
            Due date
          </div>

          <Datetime
            className='border-none'
            isValidDate={validDate}
            dateFormat={true}
            timeFormat={false}
            value={data.date as Date}
            onChange={(value) => setData({ ...data, date: moment.isMoment(value) ? value.toDate() : data.date })}
          />
        </div>
      </div>
    </div>
  )
}
