import { useCallback, useEffect, useState } from 'react'
import { useRoute } from 'react-router5'

import Icon from '../../components/Icon'
import AudioPlayerModal from '../../components/AudioPlayer'

import { httpClientUpdate } from '../../funcs'
import { useAppSelector } from '../../store/hooks'

import { HttpReport, LibraryEditProps } from './List'
import SelectComponent from '../../components/Select'

interface DataProps {
  text: string,
  theme: string,
  priority: number,
  attachments: {
    type: string
    priority: number
    file: string
  }[]
  user_groups: string[]
}

export default function New() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [isSaving, setIsSaving] = useState(false)

  const [data, setData] = useState<DataProps>({
    theme: '',
    text: '',
    priority: 0,
    attachments: [],
    user_groups: []
  })

  const [imageModal, setImageModal] = useState({
    data: '',
    index: 0,
  })

  const [audioModal, setAudioModal] = useState({
    data: '',
    index: 0,
  })
  const [videoModal, setVideoModal] = useState({ index: 0, data: '' })

  const [edit, setEdit] = useState<LibraryEditProps[]>([])
  const [availableUsers, setAvailableUsers] = useState<LibraryEditProps[]>([])

  const [selectedUser, setSelectedUser] = useState('')

  const [updatedSounds, setUpdatedSounds] = useState<{ data: string, is_open: boolean }>({ data: '', is_open: false })
  const [dragingSound, setDragingSound] = useState(false)
  const [dragingVideo, setDragingVideo] = useState(false)

  const [draging, setDraging] = useState(false)
  const [openImageModal, setOpenImageModal] = useState(false)
  const [openVideoModal, setOpenVideoModal] = useState({ is_open: false, data: '' })

  const [upImg, setUpImg] = useState('')

  useEffect(() => {
    loadReport()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function loadReport() {
    try {
      // api/instruction-libraries/report?account_id=88888&limit_rows=100&page=1
      const { data: { data: report, success, error } } = (await httpClientUpdate.get('/instruction-libraries/report', {
        params: {
          account_id: activeAccountId,
          limit_rows: 1,
          page: 1,
        }
      })) as { data: HttpReport }
      if (success) {
        setEdit(report.edit)
        setAvailableUsers(report.edit)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error) { }
  }

  async function handleSave() {
    setIsSaving(true)
    try {
      // api/instruction-libraries
      const response = await httpClientUpdate.post(`/instruction-libraries`, {
        account_id: activeAccountId,
        theme: data.theme,
        text: data.text,
        priority: data.priority,
        user_groups: data.user_groups,
        attachments: data.attachments.map(item => {
          if (item.type === 'audio/mpeg') {
            let audio_file = item.file.replace('data:audio/mpeg;base64,', '')
            return {
              type: 'audio/mpeg',
              priority: 1,
              file: audio_file,
            }
          } else if (item.type === 'video/mp4') {
            let video_file = item.file.replace('data:video/mp4;base64,', '')
            return {
              type: 'video/mp4',
              priority: 1,
              file: video_file,
            }
          } else {
            let file = item.file.replace('data:image/png;base64,', '').replace('data:image/jpeg;base64,', '')
            return {
              ...item,
              file: file
            }
          }
        })
      })

      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('instruction_library', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  function handleDeleteImage() {
    setData({
      ...data,
      attachments: data.attachments.filter((item, idx) => idx !== imageModal.index)
    })
    setImageModal({ index: 0, data: '' })
  }

  function handleDeleteVideo() {
    setData({
      ...data,
      attachments: data.attachments.filter((item, idx) => idx !== videoModal.index)
    })
    setVideoModal({ index: 0, data: '' })
  }

  function handleDeleteNewAudio() {
    setData({
      ...data,
      attachments: data.attachments.filter((item, idx) => idx !== audioModal.index)
    })
    setAudioModal({ index: 0, data: '' })
  }

  function handleSaveSound() {
    let updated = data.attachments.map(item => item)

    updated.push({
      type: 'audio/mpeg',
      priority: 1,
      file: updatedSounds.data,
    })

    setData({
      ...data,
      attachments: updated
    })
    setUpdatedSounds({ is_open: false, data: '' })
  }

  function handleSaveVideo() {
    let updated = data.attachments.map(item => item)

    updated.push({
      type: 'video/mp4',
      priority: 1,
      file: openVideoModal.data,
    })

    setData({
      ...data,
      attachments: updated
    })
    setOpenVideoModal({ is_open: false, data: '' })
  }

  function handleSaveImage() {
    let updated = data.attachments.map(item => item)

    updated.push({
      type: 'image/jpeg',
      priority: 1,
      file: upImg,
    })

    setData({
      ...data,
      attachments: updated
    })

    setUpImg('')
    setOpenImageModal(false)
  }

  const onDragSoundLeave = useCallback((e) => {
    setDragingSound(false);
    e.stopPropagation();
    e.preventDefault();
    return false;
  }, []);

  const onDragLeave = useCallback((e) => {
    setDraging(false);
    e.stopPropagation();
    e.preventDefault();
    return false;
  }, []);

  const onVideoDragLeave = useCallback((e) => {
    setDragingVideo(false);
    e.stopPropagation();
    e.preventDefault();
    return false;
  }, []);

  const onDragOver = useCallback((e) => {
    setDraging(true)
    e.preventDefault();
    e.stopPropagation();
    return false;
  }, []);

  const onVideoDragOver = useCallback((e) => {
    setDragingVideo(true)
    e.preventDefault();
    e.stopPropagation();
    return false;
  }, []);

  const onDragSoundOver = useCallback((e) => {
    setDragingSound(true)
    e.preventDefault();
    e.stopPropagation();
    return false;
  }, []);

  const onDropSound = useCallback((e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    const reader = new FileReader();
    reader.addEventListener('load', () =>
      setUpdatedSounds({ ...updatedSounds, data: reader.result as string })
    );
    reader.readAsDataURL(files[0]);
    setDragingSound(false);
    return false;

  }, [updatedSounds]);

  const onDropVideo = useCallback((e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    const reader = new FileReader();
    reader.addEventListener('load', () =>
      setOpenVideoModal({ ...openVideoModal, data: reader.result as string })
    );
    reader.readAsDataURL(files[0]);
    setDragingVideo(false);
    return false;

  }, [openVideoModal]);

  function onSelectSoundFile(e: any) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setUpdatedSounds({ ...updatedSounds, data: reader.result as string })
      }
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const onDrop = useCallback((e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    const reader = new FileReader();
    reader.addEventListener('load', () =>
      setUpImg(reader.result as string)
    );
    reader.readAsDataURL(files[0]);
    setDraging(false);
    return false;
  }, []);

  function onSelectFile(e: any) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        setUpImg(reader.result as string)
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onSelectVideoFile(e: any) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setOpenVideoModal({ ...openVideoModal, data: reader.result as string })
      }
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function addUser() {
    const newUsersList = [
      ...data.user_groups,
      selectedUser
    ]

    setData({
      ...data,
      user_groups: newUsersList
    })

    let _availableUser = [...availableUsers]

    _availableUser = _availableUser.filter(($user) => {

      return newUsersList.findIndex((user) => {
        return user === $user.user_group_id
      }) === -1
    })

    setAvailableUsers([
      ..._availableUser
    ])

    setSelectedUser('')
  }

  function removeUser(i: number) {
    let _callerGroupUsers = [...data.user_groups]

    let _user = _callerGroupUsers[i]

    _callerGroupUsers.splice(i, 1)

    setData({
      ...data,
      user_groups: _callerGroupUsers
    })

    setAvailableUsers([
      ...availableUsers,
      ...edit.filter(item => item.user_group_id === _user)
    ])
  }

  function getUserName(id: string) {
    let name = ''

    edit.forEach(item => {
      if (item.user_group_id === id) {
        name = item.name
      }
    })

    return name
  }

  return (
    <div className="AbsencesPage_Item entity-edit instruction-library">
      <div className="wrapper flex-container sb">
        <h1>Instruction Library</h1>

        <button
          style={{ background: '#d0d3da', color: 'white' }}
          className="_wa"
          onClick={() => $router.router.navigate('instruction_library', {
            companyId: activeAccountId,
            localInterface: $router.router.getState().params.localInterface
          }, { reload: true })}
        >
          <Icon
            style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
            viewBox="0 0 24 24"
            icon="arrow-25"
          />
        </button>
      </div>

      <div className='instruction-library-content'>
        <div className='border-row'></div>

        <div
          className='instruction-library-theme'
          style={{ display: 'flex', alignItems: 'center', fontSize: '18px' }}
        >
          <span>Theme:</span>

          <input
            type="text"
            value={data.theme}
            onChange={(event) => setData({ ...data, theme: event.target.value })}
          />
        </div>

        <div
          className='instruction-library-theme select'
          style={{ display: 'flex', alignItems: 'flex-start', fontSize: '18px' }}
        >
          <div className='row'>
            <span>User:</span>

            <SelectComponent
              options={availableUsers.map(item => {
                return {
                  span: item.name,
                  value: item.user_group_id,
                }
              })}
              selectedOption={selectedUser}
              onChange={(value) => setSelectedUser(value as string)}
            />

            <button
              disabled={!selectedUser}
              className="_green add"
              onClick={() => addUser()}
            >
              Add
            </button>
          </div>

          <table className='table'>
            <tr>
              <th>Name</th>
              <th></th>
            </tr>

            {
              data.user_groups.map((item, i) => (
                <tr key={item}>
                  <td> {getUserName(item)} </td>

                  <td>
                    <button
                      className="_zeroed _iconed _red"
                      onClick={() => removeUser(i)}
                    >
                      <Icon icon="x-mark-1" />
                    </button>
                  </td>
                </tr>
              ))
            }
          </table>
        </div>

        <div
          className='instruction-library-theme'
          style={{ display: 'flex', alignItems: 'center', fontSize: '18px' }}
        >
          <span>Priority:</span>

          <input
            type="number"
            className="appearance"
            value={data.priority}
            onChange={(event) => setData({ ...data, priority: +event.target.value })}
          />
        </div>

        <div
          className='instruction-library-text'
          style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}
        >
          <span>Text:</span>

          <textarea
            value={data.text}
            onChange={(event) => setData({ ...data, text: event.target.value })}
          ></textarea>
        </div>

        <div className='border-row'></div>

        <div className='add-attachments'>
          {
            data.attachments.map((item, index) => (
              <div key={index}>
                {
                  item.type === 'audio/mpeg' &&
                  <div
                    className='audio'
                    onClick={() => setAudioModal({ index: index, data: item.file })}
                  >
                    <Icon
                      viewBox='0 0 1024 1024'
                      icon="sound-96"
                    />
                  </div>
                }

                {
                  item.type === 'image/jpeg' &&
                  <div
                    className='audio'
                    onClick={() => setImageModal({ index: index, data: item.file })}
                  >
                    <img src={item.file} alt="Attachment" />
                  </div>
                }

                {
                  item.type === 'video/mp4' &&
                  <div
                    className='audio'
                    onClick={() => setVideoModal({ index: index, data: item.file })}
                  >
                    <video
                      controls={false}
                      style={{ width: '100%' }}
                    >
                      <source src={item.file} type="video/mp4" />
                    </video>
                  </div>
                }
              </div>
            ))
          }
        </div>
      </div>

      <div
        className='adding-buttons'
        style={{ display: 'flex', gap: '20px', margin: '20px' }}
      >
        <button
          className="_bordered _green"
          style={{ width: 'fit-content' }}
          onClick={() => setUpdatedSounds({ is_open: true, data: '' })}
        >
          Add Audio
        </button>

        <button
          className="_bordered _green"
          style={{ width: 'fit-content' }}
          onClick={() => setOpenImageModal(true)}
        >
          Add Image
        </button>

        <button
          className="_bordered _green"
          style={{ width: 'fit-content' }}
          onClick={() => setOpenVideoModal({ is_open: true, data: '' })}
        >
          Add Video
        </button>
      </div>

      {
        videoModal.data &&
        <div className="popup imageCrop">
          <div
            className="wrapper"
            onClick={(e) => e.stopPropagation()}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px'
              }}
            >
              <video
                controls
                style={{ width: '400px' }}
              >
                <source src={videoModal.data} type="video/mp4" />
              </video>
            </div>

            {
              <div
                style={{ display: 'flex', marginTop: '10px' }}
              >
                <button
                  className="_wa _iconed _bordered _red"
                  onClick={() => handleDeleteVideo()}
                >
                  <span>Delete</span>
                </button>

                <button
                  className="_wa _iconed _bordered _blue"
                  onClick={() => {
                    setVideoModal({ data: '', index: 0 })
                  }}
                >
                  <span>Close</span>
                </button>
              </div>
            }
          </div>
        </div>
      }

      {
        imageModal.data &&
        <div className="popup imageCrop">
          <div
            className="wrapper"
            onClick={(e) => e.stopPropagation()}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px'
              }}
            >
              <img
                src={imageModal.data}
                alt="Attachment"
              />
            </div>

            {
              <div
                style={{ display: 'flex', marginTop: '10px' }}
              >
                <button
                  className="_wa _iconed _bordered _red"
                  onClick={() => handleDeleteImage()}
                >
                  <span>Delete</span>
                </button>

                <button
                  className="_wa _iconed _bordered _blue"
                  onClick={() => {
                    setImageModal({ data: '', index: 0 })
                  }}
                >
                  <span>Close</span>
                </button>
              </div>
            }
          </div>
        </div>
      }

      {openImageModal ? (
        <div
          className="popup imageCrop"
          onClick={() => setOpenImageModal(false)}
          onDragLeave={onDragLeave}
          onDragOver={onDragOver}
          onDrop={(event) => {
            draging && onDrop(event)
          }}
        >

          <div
            className="wrapper"
            onClick={(e) => e.stopPropagation()}
          >

            {
              draging ?
                <div
                  style={{
                    border: '2px solid rgb(96, 147, 222)',
                    borderRadius: '5px',
                    position: 'relative'
                  }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    style={{ width: '100%', height: '120px', display: 'block', opacity: '0', zIndex: '2' }}
                    name="myImage"
                    onChange={onSelectFile}
                    id="file-uploader"
                  />
                  <span
                    style={{ position: 'absolute', top: '50px', width: '100%', textAlign: 'center', color: '#6093de' }}
                  >
                    Upload a File or click for refresh field
                  </span>
                </div> :
                <div>
                  <input type="file" accept="image/*" onChange={onSelectFile} id="file-uploader" />
                  <button
                    className="_wa _iconed _bordered _blue"
                    style={{ marginTop: upImg ? '' : '40px' }}
                    onClick={() => document.getElementById('file-uploader')?.click()}
                  >
                    <Icon icon="user-33" />
                    <span>Upload a File</span>
                  </button>
                </div>
            }


            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px'
              }}
            >
              {
                upImg &&
                <img
                  src={upImg}
                  alt="Attachment"
                />
              }
            </div>

            {
              <div
                style={{ display: 'flex', marginTop: '10px' }}
              >
                <button
                  className="_wa _iconed _bordered _blue"
                  onClick={() => {
                    setOpenImageModal(false)
                    setDraging(false)
                    setUpImg('')
                  }}
                >
                  <span>Cancel</span>
                </button>

                <button
                  className="_wa _iconed _bordered _blue"
                  onClick={() => {
                    setOpenImageModal(false)
                    setDraging(false)
                    handleSaveImage()
                  }}
                >
                  <span>Save</span>
                </button>
              </div>
            }
          </div>
        </div>
      ) : null}

      {openVideoModal.is_open ? (
        <div
          className="popup imageCrop"
          onClick={() => setOpenImageModal(false)}
          onDragLeave={onVideoDragLeave}
          onDragOver={onVideoDragOver}
          onDrop={(event) => {
            dragingVideo && onDropVideo(event)
          }}
        >

          <div
            className="wrapper"
            onClick={(e) => e.stopPropagation()}
          >

            {
              dragingVideo ?
                <div
                  style={{
                    border: '2px solid rgb(96, 147, 222)',
                    borderRadius: '5px',
                    position: 'relative'
                  }}
                >
                  <input
                    type="file"
                    accept="video/*"
                    style={{ width: '100%', height: '120px', display: 'block', opacity: '0', zIndex: '2' }}
                    name="myImage"
                    onChange={onSelectVideoFile}
                    id="file-uploader-video"
                  />
                  <span
                    style={{ position: 'absolute', top: '50px', width: '100%', textAlign: 'center', color: '#6093de' }}
                  >
                    Upload a File or click for refresh field
                  </span>
                </div> :
                <div>
                  <input type="file" accept="video/*" onChange={onSelectVideoFile} id="file-uploader-video" />
                  <button
                    className="_wa _iconed _bordered _blue"
                    style={{ marginTop: upImg ? '' : '40px' }}
                    onClick={() => document.getElementById('file-uploader-video')?.click()}
                  >
                    <Icon icon="user-33" />
                    <span>Upload a File</span>
                  </button>
                </div>
            }

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px'
              }}
            >
              {
                openVideoModal.data &&
                <video
                  controls
                  style={{ width: '400px' }}
                >
                  <source src={openVideoModal.data} type="video/mp4" />
                </video>
              }
            </div>

            {
              <div
                style={{ display: 'flex', marginTop: '10px' }}
              >
                <button
                  className="_wa _iconed _bordered _blue"
                  onClick={() => {
                    setOpenVideoModal({ is_open: false, data: '' })
                    setDragingVideo(false)
                  }}
                >
                  <span>Cancel</span>
                </button>

                <button
                  className="_wa _iconed _bordered _blue"
                  onClick={() => {
                    setOpenImageModal(false)
                    setDragingVideo(false)
                    handleSaveVideo()
                  }}
                >
                  <span>Save</span>
                </button>
              </div>
            }
          </div>
        </div>
      ) : null}

      {
        audioModal.data &&
        <div className="popup imageCrop">

          <div
            className="wrapper"
            onClick={(e) => e.stopPropagation()}
          >

            <AudioPlayerModal
              audioSrc={audioModal.data}
            />

            <div
              style={{ display: 'flex', marginTop: '10px' }}
            >
              <button
                className="_wa _iconed _bordered _red"
                onClick={() => handleDeleteNewAudio()}
              >
                <span>Delete</span>
              </button>

              <button
                className="_wa _iconed _bordered _blue"
                disabled={isSaving}
                onClick={() => {
                  setAudioModal({ data: '', index: 0 })
                }}
              >
                <span>Close</span>
              </button>
            </div>
          </div>
        </div>
      }

      {
        updatedSounds.is_open &&
        <div
          className="popup imageCrop"
          onClick={() => setUpdatedSounds({ is_open: true, data: '' })}
          onDragLeave={onDragSoundLeave}
          onDragOver={onDragSoundOver}
          onDrop={(event) => {
            dragingSound && onDropSound(event)
          }}
        >

          <div
            className="wrapper"
            onClick={(e) => e.stopPropagation()}
          >

            {
              updatedSounds.data &&
              <AudioPlayerModal
                audioSrc={updatedSounds.data}
              />
            }

            {
              updatedSounds.data &&
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  padding: '10px'
                }}
              >
              </div>
            }

            {
              dragingSound ?
                <div
                  style={{
                    border: '2px solid rgb(96, 147, 222)',
                    borderRadius: '5px',
                    position: 'relative'
                  }}
                >
                  <input
                    type="file"
                    accept="audio/*"
                    style={{ width: '100%', height: '120px', display: 'block', opacity: '0', zIndex: '2' }}
                    onChange={onSelectSoundFile}
                    id="file-uploader-sound"
                  />
                  <span
                    style={{ position: 'absolute', top: '50px', width: '100%', textAlign: 'center', color: '#6093de' }}
                  >
                    Upload a File or click for refresh field
                  </span>
                </div> :
                <div>
                  <input type="file" accept="audio/*" onChange={onSelectSoundFile} id="file-uploader-sound" />
                  <button
                    className="_wa _iconed _bordered _blue"
                    style={{ marginTop: upImg ? '' : '40px' }}
                    onClick={() => document.getElementById('file-uploader-sound')?.click()}
                  >
                    <span>Upload a File</span>
                  </button>
                </div>
            }


            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '10px'
              }}
            >
            </div>

            <div
              style={{ display: 'flex', marginTop: '10px' }}
            >
              <button
                className="_wa _iconed _bordered _blue"
                onClick={() => setUpdatedSounds({ data: '', is_open: false })}
              >
                <span>Cancel</span>
              </button>

              <button
                className="_wa _iconed _bordered _blue"
                disabled={isSaving}
                onClick={() => {
                  setDragingSound(false)
                  handleSaveSound()
                }}
              >
                <span>Save</span>
              </button>
            </div>
          </div>
        </div>
      }

      <div className="wrapper flex-container sb editing-buttons">
        <div></div>
        <div className="buttons">
          <button className="_bordered _red" onClick={() => $router.router.navigate('instruction_library', {
            companyId: activeAccountId
          }, { reload: true })}>
            Cancel
          </button>
          {
            <button
              disabled={isSaving || data.theme === '' || data.text === '' || data.user_groups.length === 0}
              className="_bordered _green"
              onClick={() => handleSave()}
            >
              Save
            </button>
          }
        </div>
      </div>
    </div>
  )
}
