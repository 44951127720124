import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import moment, { Moment } from "moment";

import Account from "../models/Account";
import Menus from "../models/Menus";
import User from "../models/User";

export type NavMode = "main" | "settings" | "channels";

export type NavActive = {
  is: boolean;
  floating: boolean;
};

export interface RedirectRoute {
  name: string;
  params: {
    [key: string]: string;
  };
}

export interface ScheduleFrame {
  min_time: string;
  max_time: string;
}

export interface ChannelsProps {
  channel_id: string;
  is_archive: number;
  name: string;
  unread_messages_with_notification: number;
}

// Define a type for the slice state
export interface StoreState {
  accessToken: string | null;
  accounts: Account[];
  activeAccountId: string | null;
  user: User | null;
  menus: Menus | null;
  channels: ChannelsProps[] | null;
  navMode: NavMode;
  redirectRoute: RedirectRoute | null;
  navActive: NavActive;
  reportsMaxRows: number;
  scheduleDay: string;
  dispatchersScheduleDay: string;
  scheduleFrame: ScheduleFrame;
  dispatchersScheduleFrame: ScheduleFrame;
  phoneCall: boolean;
  selectedDayForTech: Moment;
  phoneCallStatus: string;
  is_agree_sound: boolean;
}

// Define the initial state using that type
const initialState: StoreState = {
  accessToken: null,
  accounts: [],
  activeAccountId: null,
  navMode: "main",
  user: null,
  menus: null,
  channels: null,
  redirectRoute: null,
  navActive: {
    is: false,
    floating: false,
  },
  reportsMaxRows: 100,
  scheduleDay: "one",
  dispatchersScheduleDay: "one",
  scheduleFrame: {
    min_time: "8AM",
    max_time: "8PM",
  },
  dispatchersScheduleFrame: {
    min_time: "8AM",
    max_time: "8PM",
  },
  phoneCall: false,
  selectedDayForTech: moment(),
  phoneCallStatus: "Not Available",
  is_agree_sound: false,
};

export const counterSlice = createSlice({
  name: "crm",
  initialState,
  reducers: {
    setAccessToken: (store, action: PayloadAction<string | null>) => {
      store.accessToken = action.payload;
    },
    setAccounts: (store, action: PayloadAction<Account[]>) => {
      store.accounts = action.payload;
    },
    setActiveAccountId: (store, action: PayloadAction<string | null>) => {
      store.activeAccountId = action.payload;
    },
    setNavMode: (store, action: PayloadAction<NavMode>) => {
      store.navMode = action.payload;
    },
    setUser: (store, action: PayloadAction<User | null>) => {
      store.user = action.payload;
    },
    setMenus: (store, action: PayloadAction<Menus | null>) => {
      store.menus = action.payload;
    },
    setChannels: (store, action: PayloadAction<ChannelsProps[] | null>) => {
      store.channels = action.payload;
    },
    setRedirectRoute: (store, action: PayloadAction<RedirectRoute | null>) => {
      store.redirectRoute = action.payload;
    },
    setNavActive: (store, action: PayloadAction<NavActive>) => {
      store.navActive = action.payload;
    },
    setReportsMaxRows: (store, action: PayloadAction<number>) => {
      store.reportsMaxRows = action.payload;
    },
    setScheduleDay: (store, action: PayloadAction<string>) => {
      store.scheduleDay = action.payload;
    },
    setDispatchersScheduleDay: (store, action: PayloadAction<string>) => {
      store.dispatchersScheduleDay = action.payload;
    },
    setScheduleFrame: (store, action: PayloadAction<ScheduleFrame>) => {
      store.scheduleFrame = action.payload;
    },
    setDispatchersScheduleFrame: (
      store,
      action: PayloadAction<ScheduleFrame>
    ) => {
      store.dispatchersScheduleFrame = action.payload;
    },
    setPhoneCall: (store, action: PayloadAction<boolean>) => {
      store.phoneCall = action.payload;
    },
    setSelectedDayForTech: (store, action: PayloadAction<Moment>) => {
      store.selectedDayForTech = action.payload;
    },
    setPhoneCallStatus: (store, action: PayloadAction<string>) => {
      store.phoneCallStatus = action.payload;
    },
    setIsAgreeSound: (store, action: PayloadAction<boolean>) => {
      store.is_agree_sound = action.payload;
    },
  },
});

export const {
  setAccessToken,
  setAccounts,
  setActiveAccountId,
  setNavMode,
  setUser,
  setMenus,
  setRedirectRoute,
  setNavActive,
  setReportsMaxRows,
  setScheduleDay,
  setScheduleFrame,
  setPhoneCall,
  setSelectedDayForTech,
  setPhoneCallStatus,
  setChannels,
  setDispatchersScheduleDay,
  setDispatchersScheduleFrame,
  setIsAgreeSound,
} = counterSlice.actions;

export default counterSlice.reducer;
