import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { BaseLink, useRoute } from "react-router5"
import qs from "qs";

import Icon from '../../components/Icon'
import SelectComponent from '../../components/Select';
import InputTextWithTitle from '../../components/InputTextWithTitle';
import InputNumberWithTitle from '../../components/InputNumberWithTitle';
import SelectWithTitle from '../../components/SelectWithTitle';

import { httpClientUpdate, nErrorUpdate } from '../../funcs'
import { useAppSelector } from "../../store/hooks"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: ListData
}

interface ListData {
  list_discounts: DataItem[]

  permissions: {
    list_discounts_report_show: boolean
    list_discounts_add: boolean
    list_discounts_edit: boolean
    list_discounts_delete: boolean
  }
}

interface DataItem {
  description: string
  list_discount_id: string
  name: string
  amount: number
  type: string
  editing: boolean
}

const typeOptions = ['amount', 'percentage']

export default function Discounts() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [isSaving, setIsSaving] = useState(false)
  const [errorAdd, setErrorAdd] = useState(false)
  const [errorEdit, setErrorEdit] = useState(false)

  const [newItemForm, setNewItemForm] = useState({
    name: '',
    description: '',
    amount: 0,
    type: 'amount'
  })

  const [editItemForm, setEditItemForm] = useState({
    name: '',
    description: '',
    amount: 0,
    type: 'amount',
  })

  const [items, setItems] = useState<DataItem[]>([])
  const [permissions, setPermissions] = useState({
    list_discounts_report_show: false,
    list_discounts_add: false,
    list_discounts_edit: false,
    list_discounts_delete: false,
  })

  async function loadData() {
    try {
      // https://2022back4.artemiudintsev.com/api/lists/discounts/report?account_id=88888
      const { data: { data: lists, success, error } } = (await httpClientUpdate.get(`/lists/discounts/report`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        },
        params: {
          account_id: activeAccountId
        }
      })) as { data: HttpClientUpdateReport }
      if (success) {
        if (lists.permissions) {
          setPermissions(lists.permissions)
        }
        setItems(lists.list_discounts.map(item => {
          return { ...item, editing: false }
        }))
        errorEdit && setErrorEdit(false)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function addItem() {
    setIsSaving(true)
    try {
      // https://2022back4.artemiudintsev.com/api/lists/discounts
      const response = await httpClientUpdate.post(`/lists/discounts`, qs.stringify({
        account_id: activeAccountId,
        name: newItemForm.name,
        description: newItemForm.description,
        amount: Number(newItemForm.amount),
        type: newItemForm.type,
      }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        }
      })
      if (response.data.success) {
        setIsSaving(false)
        setNewItemForm({
          ...newItemForm,
          name: '',
          description: '',
          amount: 0,
          type: 'amount',
        })
        return loadData()
      } else {
        setIsSaving(false)
        setNewItemForm({
          ...newItemForm,
          name: '',
          description: '',
          amount: 0,
          type: 'amount',
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      setIsSaving(false)
      let createdError = nErrorUpdate(error)
      if (createdError.content.errorText === "This name is already taken") {
        setErrorAdd(true)
      }
    }
  }

  async function editItem(id: string, name: string, description: string, amount: number, type: string) {
    setIsSaving(true)
    try {
      // https://2022back4.artemiudintsev.com/api/lists/discounts/888880607q1t5gsahr
      const response = await httpClientUpdate.put(`/lists/discounts/${id}`, qs.stringify({
        account_id: activeAccountId,
        name,
        description,
        amount,
        type,
      }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        }
      })
      if (response.data.success) {
        setIsSaving(false)
        return true
      } else {
        setIsSaving(false)
        return false
      }
    }
    catch (error: Error | AxiosError | unknown) {
      setIsSaving(false)
      let createdError = nErrorUpdate(error)
      if (createdError.content.errorText === "This name is already taken") {
        setErrorEdit(true)
      }
    }
  }

  async function removeItem(id: string) {
    setIsSaving(true)
    try {
      // https://2022back4.artemiudintsev.com/api/lists/discounts/888880607q1t5gsahr
      const response = await httpClientUpdate.delete(`/lists/discounts/${id}`, {
        data: {
          account_id: activeAccountId,
        }
      })

      if (response.data.success) {
        setIsSaving(false)
        return loadData()
      } else {
        setIsSaving(false)
      }
    }
    catch (error: Error | AxiosError | unknown) {
      setIsSaving(false)
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  useEffect(() => {
    loadData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (errorAdd && newItemForm.name) {
      setErrorAdd(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newItemForm.name])

  useEffect(() => {
    if (errorEdit && editItemForm.name) {
      setErrorEdit(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editItemForm.name])

  function setEditing(i: number, flag: boolean) {

    let _items = [...items]
    _items = _items.map((item) => ({ ...item, editing: false }))
    _items[i].editing = flag
    setItems(_items)

    setEditItemForm({
      ...editItemForm,
      name: flag ? _items[i].name : '',
      description: flag ? _items[i].description : '',
      amount: flag ? _items[i].amount : 0,
      type: flag ? _items[i].type : 'amount',
    })
  }

  // Set item function
  async function setItem(i: number) {

    let _items = [...items]
    _items[i] = {
      name: editItemForm.name,
      description: editItemForm.description,
      amount: editItemForm.amount,
      type: editItemForm.type,
      editing: false,
      list_discount_id: _items[i].list_discount_id
    }

    let update = await editItem(_items[i].list_discount_id, _items[i].name, _items[i].description, _items[i].amount, _items[i].type)
    update && setItems(_items)
  }

  function handleEditFocus(event: any) {
    const target = event.currentTarget;

    target.type = 'text';
    target.setSelectionRange(target.value.length, target.value.length);
    target.type = 'number';
    target && target.focus()
  }

  function handleEditChangeItem(event: any) {
    let price = event.target.value

    let numberWithoutDot = price.split('.')

    if (Number(price) >= 0) {
      if (numberWithoutDot[1] && numberWithoutDot[1].toString().length > 2) {
        let newNumber = `${numberWithoutDot[0]}${numberWithoutDot[1].toString()[0]}.${numberWithoutDot[1].toString()[1]}${price[price.length - 1]}`
        setEditItemForm({ ...editItemForm, amount: Number(newNumber) })
      } else {
        let newNumber = price

        setEditItemForm({ ...editItemForm, amount: Number(newNumber) })
      }
    }
  }

  function handleEditChangeItemBackspace(event: any) {
    if (event.keyCode === 8) {
      event.preventDefault()
      let price = event.target.value
      let numberWithoutDot = `${price}`.split('.')
      if (numberWithoutDot[0].length > 1) {
        let newNumber = `${numberWithoutDot[0].slice(0, -1)}.${numberWithoutDot[0][numberWithoutDot[0].length - 1]}${numberWithoutDot[1][0]}`
        setEditItemForm({ ...editItemForm, amount: Number(newNumber) })
      } else {
        let newNumber = `0.${numberWithoutDot[0][0]}${numberWithoutDot[1][0]}`
        setEditItemForm({ ...editItemForm, amount: Number(newNumber) })
      }
    }
  }

  return (
    <div className="ListsPage_Appliances">
      {/* Top navigation */}
      <div className="top-nav top-nav-list">
        <div className="top-nav-list-item">
          <BaseLink
            router={$router.router}
            routeName="lists.appliances"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.appliances" })}
          >
            <Icon icon="task-1" />
            <span>Appliances</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.brands"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.brands" })}
          >
            <Icon icon="task-1" />
            <span>Brands</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.areas"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.areas" })}
          >
            <Icon icon="task-1" />
            <span>Areas</span>
          </BaseLink>
        </div>

        <div className="top-nav-list-item">
          <BaseLink
            router={$router.router}
            routeName="lists.sources"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.sources" })}
          >
            <Icon icon="task-1" />
            <span>Sources</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.spam_filters"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.spam_filters" })}
          >
            <Icon icon="task-1" />
            <span>Spam Filters</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.campaigns"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.campaigns" })}
          >
            <Icon icon="task-1" />
            <span>Campaigns</span>
          </BaseLink>
        </div>

        <div className="top-nav-list-item">
          <BaseLink
            router={$router.router}
            routeName="lists.companies"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.companies" })}
          >
            <Icon icon="task-1" />
            <span>Companies</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.payments_sevices"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.payments_sevices" })}
          >
            <Icon icon="task-1" />
            <span>Payment Services</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.warehouses"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.warehouses" })}
          >
            <Icon icon="task-1" />
            <span>Warehouses</span>
          </BaseLink>
        </div>

        <div className="top-nav-list-item">
          <BaseLink
            router={$router.router}
            routeName="lists.services"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.services" })}
          >
            <Icon icon="task-1" />
            <span>Services</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.materials"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.materials" })}
          >
            <Icon icon="task-1" />
            <span>Materials</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.discounts"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.discounts" })}
          >
            <Icon icon="task-1" />
            <span>Discounts</span>
          </BaseLink>
        </div>

        <div className="top-nav-list-item">
          <BaseLink
            router={$router.router}
            routeName="lists.ticket_templates"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.ticket_templates" })}
          >
            <Icon icon="task-1" />
            <span>Ticket Templates</span>
          </BaseLink>
        </div>
      </div>

      <div className="wrapper">
        <div className="flex-container sb header">
          <h1>List of Discounts</h1>

          {
            (errorAdd || errorEdit) &&
            <span style={{ color: '#FF0000' }}>This name is already taken</span>
          }

          {
            permissions.list_discounts_add &&
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gridGap: '15px',
                gap: '15px',
                flexWrap: 'wrap',
                justifyContent: 'flex-end'
              }}
              className="flex-container-select"
            >
              <InputTextWithTitle
                width={150}
                label='Name'
                value={newItemForm.name}
                setValue={(value) => setNewItemForm({ ...newItemForm, name: value })}
              />

              <InputTextWithTitle
                width={150}
                label='Description'
                value={newItemForm.description}
                setValue={(value) => setNewItemForm({ ...newItemForm, description: value })}
              />

              <SelectWithTitle
                width={150}
                label='Type'
                value={newItemForm.type}
                setValue={(value) => setNewItemForm({ ...newItemForm, type: value })}
                options={typeOptions}
              />

              <InputNumberWithTitle
                width={150}
                label='Price'
                value={newItemForm.amount}
                setValue={(value) => setNewItemForm({ ...newItemForm, amount: value })}
              />

              <button
                className="_wa _green"
                disabled={newItemForm.name.length < 1 || newItemForm.amount === 0 || isSaving || errorAdd}
                onClick={(e) => { e.preventDefault(); addItem() }}
              >
                Add
              </button>
            </div>
          }
        </div>

        {
          !!items.length &&
          permissions.list_discounts_report_show &&
          <table className="table">
            <thead>
              <tr>
                <th style={{ width: '20%' }}>Name</th>
                <th style={{ width: '30%' }}>Description</th>
                <th style={{ width: '25%' }}>type</th>
                <th style={{ width: '25%' }}>Amount</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            {items.map((item, i) => (<React.Fragment key={i}>
              {item.editing ? (
                <tr>
                  <td>
                    <div className="editing-form">
                      <input
                        className="_zeroed"
                        type="text"
                        disabled={!permissions.list_discounts_edit}
                        defaultValue={editItemForm.name}
                        onChange={({ target: { value } }) => setEditItemForm({ ...editItemForm, name: value })}
                      />

                      <button
                        className="_zeroed _iconed _red"
                        onClick={() => setEditing(i, false)}
                      >
                        <Icon icon="pencil-16" />
                      </button>
                    </div>
                  </td>

                  <td>
                    <div className="editing-form">
                      <input
                        className="_zeroed"
                        type="text"
                        disabled={!permissions.list_discounts_edit}
                        defaultValue={editItemForm.description}
                        onChange={({ target: { value } }) => setEditItemForm({ ...editItemForm, description: value })}
                      />

                      <button
                        className="_zeroed _iconed _red"
                        onClick={() => setEditing(i, false)}
                      >
                        <Icon icon="pencil-16" />
                      </button>
                    </div>
                  </td>

                  <td>
                    <div className="editing-form">
                      <SelectComponent
                        disabled={!permissions.list_discounts_edit}
                        options={typeOptions.map((option) => ({
                          span: option,
                          value: option
                        }))}
                        selectedOption={editItemForm.type}
                        onChange={(value) => setEditItemForm({ ...editItemForm, type: value as string })}
                      />

                      <button
                        className="_zeroed _iconed _red"
                        onClick={() => setEditing(i, false)}
                      >
                        <Icon icon="pencil-16" />
                      </button>
                    </div>
                  </td>

                  <td>
                    <div className="editing-form">
                      <input
                        className="_zeroed appearance"
                        type='number'
                        value={`${Number(editItemForm.amount).toFixed(2)}`}
                        step={0.01}
                        onFocus={(event) => handleEditFocus(event)}
                        onChange={(event) => handleEditChangeItem(event)}
                        onKeyDown={(event) => handleEditChangeItemBackspace(event)}
                      />

                      <button
                        className="_zeroed _iconed _red"
                        onClick={() => setEditing(i, false)}
                      >
                        <Icon icon="pencil-16" />
                      </button>
                    </div>
                  </td>

                  <td>
                    <button
                      className="_zeroed _iconed _green"
                      disabled={isSaving || errorEdit}
                      onClick={() => setItem(i)}
                    >
                      <Icon icon="check-mark-1" />
                    </button>
                  </td>
                  <td>
                    <button
                      className="_zeroed _iconed _red"
                      disabled={!permissions.list_discounts_delete || isSaving}
                      onClick={() => removeItem(item.list_discount_id)}
                    >
                      <Icon icon="x-mark-1" />
                    </button>
                  </td>
                </tr>
              ) : (
                <tr key={i}>
                  <td>{item.name}</td>
                  <td>{item.description}</td>
                  <td> {item.type} </td>
                  <td>{Number(item.amount).toFixed(2)}</td>
                  <td>
                    <button
                      disabled={!permissions.list_discounts_edit}
                      className="_zeroed _iconed _blue"
                      onClick={() => setEditing(i, true)}
                    >
                      <Icon icon="pencil-14" />
                    </button>
                  </td>
                  <td>
                    <button
                      disabled={!permissions.list_discounts_delete || isSaving}
                      className="_zeroed _iconed _red"
                      onClick={() => removeItem(item.list_discount_id)}
                    >
                      <Icon icon="x-mark-1" />
                    </button>
                  </td>
                </tr>
              )}
            </React.Fragment>))}
          </table>
        }
      </div>
    </div>
  )
}
