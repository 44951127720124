import { useEffect, useState } from "react";
import { BaseLink, useRoute } from "react-router5"
import classNames from "classnames"
import ReactInputMask from "react-input-mask";

import Icon from "../../../components/Icon"
import Select from "../../../components/Select"
import Checkbox from "../../../components/Checkbox"
import Switcher from "../../../components/Switcher"
import SelectComponent from "../../../components/Select";

import { dateToInfoBlock, httpClientUpdate, nErrorUpdate } from "../../../funcs"
import { useAppSelector } from '../../../store/hooks'

import { CallCenter_PhoneNumber } from "../../../models/CallCenter"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: PhoneNumberData
}

interface PhoneNumberData {

  permissions: {
    phone_number_add: boolean
    phone_number_delete: boolean
    phone_number_edit: boolean
    phone_number_report_show: boolean
    phone_number_show: boolean
  },

  phone_number: CallCenter_PhoneNumber,

  edit: {
    types: string[],

    sources: {
      list_sources_id: string
      source: string
    }[],

    companies: {
      company_id: string
      name: string
    }[],

    areas: {
      area: string
      area_id: string
    }[],

    call_flows: {
      call_flow_id: string
      name: string
    }[]

    call_centers: {
      call_center_id: string
      name: string
    }[]
  }
}

function CallCenter_PhoneNumbersPage_Item() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  const [isSaving, setIsSaving] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)
  const [errorFields, setErrorFields] = useState<string[] | []>([])

  const [editing, setEditing] = useState(false)

  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)

  const [phoneNumberData, setPhoneNumberData] = useState<PhoneNumberData | null>(null)

  const [newPhoneNumberData, setNewPhoneNumberData] = useState<Partial<CallCenter_PhoneNumber>>({

  })

  function handleCancelEdit() {
    setEditing(false)
    let source = phoneNumberData && phoneNumberData.phone_number[0].source ? phoneNumberData.edit.sources.filter(item => item.source === phoneNumberData.phone_number[0].source)[0].list_sources_id : ''
    let company = phoneNumberData && phoneNumberData.phone_number[0].company ? phoneNumberData.edit.companies.filter(item => item.name === phoneNumberData.phone_number[0].company)[0].company_id : ''
    let area = phoneNumberData && phoneNumberData.phone_number[0].area ? phoneNumberData.edit.areas.filter(item => item.area === phoneNumberData.phone_number[0].area)[0].area_id : ''
    let call_flow = phoneNumberData && phoneNumberData.phone_number[0].call_flow ? phoneNumberData.edit.call_flows.filter(item => item.name === phoneNumberData.phone_number[0].call_flow)[0].call_flow_id : ''

    phoneNumberData && setNewPhoneNumberData({
      phone: phoneNumberData.phone_number[0].phone,
      active: phoneNumberData.phone_number[0].active,
      conversions: phoneNumberData.phone_number[0].conversions,
      type: phoneNumberData.phone_number[0].type,
      friendly_name: phoneNumberData.phone_number[0].friendly_name,
      source: source,
      company: company,
      area: area,
      call_flow: call_flow,
      call_center_id: phoneNumberData.phone_number[0].call_center_id,
      messaging_service_sid: phoneNumberData.phone_number[0].messaging_service_sid ? phoneNumberData.phone_number[0].messaging_service_sid : ''
    })
  }

  // remove document
  async function handleRemoveItem() {
    setIsSaving(true)
    try {
      const response = await httpClientUpdate.delete(`/callcenter/phonenumbers/${$router.router.getState().params.phoneNumbersId}`, {
        data: {
          account_id: activeAccountId,
        }
      })
      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('call_center.phoneNumbers', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // update document
  async function handleSave() {
    setIsSaving(true)
    try {
      const response = await httpClientUpdate.put(`/callcenter/phonenumbers/${$router.router.getState().params.phoneNumbersId}`, {
        account_id: activeAccountId,
        active: newPhoneNumberData.active === 'On' ? 1 : 0,
        conversions: newPhoneNumberData.conversions === 'On' ? 1 : 0,
        types: newPhoneNumberData.type ? newPhoneNumberData.type : null,
        source_id: newPhoneNumberData.source ? newPhoneNumberData.source : null,
        company_id: newPhoneNumberData.company ? newPhoneNumberData.company : null,
        area_id: newPhoneNumberData.area ? newPhoneNumberData.area : null,
        callflow_id: newPhoneNumberData.call_flow ? newPhoneNumberData.call_flow : null,
        friendly_name: newPhoneNumberData.friendly_name,
        call_center_id: newPhoneNumberData.call_center_id ? newPhoneNumberData.call_center_id : null,
        messaging_service_sid: newPhoneNumberData.messaging_service_sid ? newPhoneNumberData.messaging_service_sid : null,
      })

      if (response.data.success) {
        setIsSaving(false)
        setEditing(false)
        loadInfo()
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
      let createdError = nErrorUpdate(error)
      createdError.content.errorFields && setErrorFields(createdError.content.errorFields)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      const { data: { data: phoneNumberData, success, error } } = (await httpClientUpdate.get('/callcenter/phonenumbers/' + $router.router.getState().params.phoneNumbersId, {
        params: {
          account_id: activeAccountId
        }
      })) as { data: HttpClientUpdateReport }
      if (success) {
        if (phoneNumberData.permissions.phone_number_show) {
          let source = phoneNumberData.phone_number[0].source ? phoneNumberData.edit.sources.filter(item => item.source === phoneNumberData.phone_number[0].source)[0].list_sources_id : ''
          let company = phoneNumberData.phone_number[0].company ? phoneNumberData.edit.companies.filter(item => item.name === phoneNumberData.phone_number[0].company)[0].company_id : ''
          let area = phoneNumberData.phone_number[0].area ? phoneNumberData.edit.areas.filter(item => item.area === phoneNumberData.phone_number[0].area)[0].area_id : ''
          let call_flow = phoneNumberData.phone_number[0].call_flow ? phoneNumberData.edit.call_flows.filter(item => item.name === phoneNumberData.phone_number[0].call_flow)[0].call_flow_id : ''
          setPhoneNumberData(phoneNumberData)
          setNewPhoneNumberData({
            phone: phoneNumberData.phone_number[0].phone,
            active: phoneNumberData.phone_number[0].active,
            conversions: phoneNumberData.phone_number[0].conversions ? 'On' : 'Off',
            type: phoneNumberData.phone_number[0].type,
            friendly_name: phoneNumberData.phone_number[0].friendly_name,
            source: source,
            company: company,
            area: area,
            call_flow: call_flow,
            call_center_id: phoneNumberData.phone_number[0].call_center_id,
            messaging_service_sid: phoneNumberData.phone_number[0].messaging_service_sid,
          })
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load phoneNumber data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setReadyToSave(true), [
    newPhoneNumberData
  ])

  // Render function
  return (<>
    {phoneNumberData && newPhoneNumberData.active ? (
      <div className="CallCenter_PhoneNumbersPage_Item entity-edit">

        {/* Top navigation */}
        <div className="top-nav">
          <BaseLink
            router={$router.router}
            routeName="call_center.phoneNumbers"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.phoneNumbers" })}
          >
            <Icon icon="phone-1" />
            <span>Phone Numbers</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.dispatchers"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.dispatchers" })}
          >
            <Icon icon="user-1" />
            <span>Dispatchers</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.additionalTime"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.additionalTime" })}
          >
            <Icon viewBox="0 0 1024 1024" icon="time-22" />
            <span>Additional Time</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.absence"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.absence" })}
          >
            <Icon icon="time-16" />
            <span>Absence</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.groups"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.groups" })}
          >
            <Icon icon="user-29" />
            <span>Groups</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.callFlows"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.callFlows" })}
          >
            <Icon icon="share-7" />
            <span>Call Flows</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.caller_groups"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.caller_groups" })}
          >
            <Icon icon="user-29" />
            <span>Caller Groups</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.temporaryClosed"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.temporaryClosed" })}
          >
            <Icon viewBox="0 0 1024 1024" icon="close-664" />
            <span>Temporary Closed</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.callCenters"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.callCenters" })}
          >
            <Icon icon="delivery-8" />
            <span>Call Centers</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.audioLibrary"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.audioLibrary" })}
          >
            <Icon viewBox="0 0 1024 1024" icon="audio-15" />
            <span>Audio Library</span>
          </BaseLink>
        </div>

        <div className="wrapper flex-container sb">
          <h1>Phone Number</h1>

          <div style={{ display: 'flex' }} >
            <button
              disabled={editing}
              style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
              className="_wa"
              onClick={() =>
                $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'call_center.phoneNumbers',
                  {
                    companyId: activeAccountId,
                    [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
            >
              <Icon
                style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                viewBox="0 0 24 24"
                icon="arrow-25"
              />
            </button>

            {
              !editing &&
                phoneNumberData?.permissions.phone_number_edit ? (
                <button className="_wa _green" onClick={() => setEditing(true)}>
                  Edit
                </button>
              ) : null
            }
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Main</div>

          <div className="fields">
            <div className="__left">
              <div className="field" style={{ zIndex: 6 }}>
                <span>Phone Number:</span>

                <ReactInputMask
                  disabled={true}
                  type="text"
                  mask="+1 (999) 999-9999"
                  value={phoneNumberData.phone_number[0].phone}
                  onChange={({ target: { value } }) => setNewPhoneNumberData({ ...newPhoneNumberData, phone: value })}
                />
              </div>

              <div className="field" style={{ zIndex: 6 }}>
                <span>Friendly Name:</span>

                <input
                  disabled={!editing}
                  type="text"
                  defaultValue={newPhoneNumberData.friendly_name}
                  onChange={({ target: { value } }) => setNewPhoneNumberData({ ...newPhoneNumberData, friendly_name: value })} />
              </div>

              <div className="field">
                <span>Call Center:</span>

                <SelectComponent
                  disabled={!editing}
                  options={phoneNumberData.edit.call_centers.map((item) => ({
                    span: item.name,
                    value: item.call_center_id,
                  }))}
                  selectedOption={newPhoneNumberData.call_center_id as string}
                  onChange={(value: string) => setNewPhoneNumberData({ ...newPhoneNumberData, call_center_id: value })}
                />
              </div>
            </div>


            <div className="__right">
              <div className="field" style={{ zIndex: 6 }}>
                <span>Active:</span>

                <Switcher
                  disabled={!editing}
                  checked={newPhoneNumberData.active === 'On'}
                  onChange={(value) => setNewPhoneNumberData({ ...newPhoneNumberData, active: value ? 'On' : 'Off' })}
                />
              </div>

              <div className="field" style={{ zIndex: 6 }}>
                <span>Conversions:</span>

                <Switcher
                  disabled={!editing}
                  checked={newPhoneNumberData.conversions === 'On'}
                  onChange={(value) => setNewPhoneNumberData({ ...newPhoneNumberData, conversions: value ? 'On' : 'Off' })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Settings</div>

          <div className="fields">
            <div className="__left">
              <div className="field" style={{ zIndex: 6 }}>
                <span>Types:</span>

                <div className="input-wrapper">
                  {
                    editing ?
                      <Select
                        error={(errorFields as string[]).includes('type')}
                        disabled={!editing}
                        options={[""].concat(phoneNumberData.edit.types).map((type) => ({
                          span: type,
                          value: type
                        }))}
                        selectedOption={newPhoneNumberData.type as string}
                        onChange={(value: string) => {
                          setNewPhoneNumberData({ ...newPhoneNumberData, type: value });
                          (errorFields as string[]).includes('type') && setErrorFields(errorFields.filter(item => item !== 'type'))
                        }}
                      /> :
                      <input
                        disabled={true}
                        type="text"
                        defaultValue={phoneNumberData.phone_number[0].type}
                      />
                  }
                </div>
              </div>

              <div className="field">
                <span>Source:</span>

                <div className="input-wrapper">
                  {
                    editing ?
                      <Select
                        error={(errorFields as string[]).includes('source_id')}
                        disabled={!editing}
                        options={[{ source: '', list_sources_id: '' }].concat(phoneNumberData.edit.sources).map((source) => ({
                          span: source.source,
                          value: source.list_sources_id
                        }))}
                        selectedOption={newPhoneNumberData.source as string}
                        onChange={(value: string) => {
                          setNewPhoneNumberData({ ...newPhoneNumberData, source: value });
                          (errorFields as string[]).includes('source_id') && setErrorFields(errorFields.filter(item => item !== 'source_id'))
                        }}
                      /> :
                      <input
                        disabled={true}
                        type="text"
                        defaultValue={phoneNumberData.phone_number[0].source}
                      />
                  }
                </div>
              </div>

              <div className="field">
                <span>Messaging Service Sid:</span>

                <input
                  disabled={!editing}
                  type="text"
                  defaultValue={newPhoneNumberData.messaging_service_sid}
                  onChange={({ target: { value } }) => setNewPhoneNumberData({ ...newPhoneNumberData, messaging_service_sid: value })}
                />
              </div>
            </div>

            <div className="__right">
              <div className="field" style={{ zIndex: 6 }}>
                <span>Company:</span>

                <div
                  className="input-wrapper"
                >
                  {
                    editing ?
                      <Select
                        error={(errorFields as string[]).includes('company_id')}
                        disabled={!editing}
                        options={[{ name: '', company_id: '' }].concat(phoneNumberData.edit.companies).map((company) => ({
                          span: company.name,
                          value: company.company_id
                        }))}
                        selectedOption={newPhoneNumberData.company as string}
                        onChange={(value: string) => {
                          setNewPhoneNumberData({ ...newPhoneNumberData, company: value });
                          (errorFields as string[]).includes('company_id') && setErrorFields(errorFields.filter(item => item !== 'company_id'))
                        }}
                      /> :
                      <input
                        disabled={true}
                        type="text"
                        defaultValue={phoneNumberData.phone_number[0].company}
                      />
                  }
                </div>
              </div>

              <div className="field" style={{ zIndex: 5 }}>
                <span>Area:</span>

                <div className="input-wrapper">
                  {
                    editing ?
                      <Select
                        error={(errorFields as string[]).includes('area_id')}
                        disabled={!editing}
                        options={[{ area: '', area_id: '' }].concat(phoneNumberData.edit.areas).map((area) => ({
                          span: area.area,
                          value: area.area_id
                        }))}
                        selectedOption={newPhoneNumberData.area as string}
                        onChange={(value: string) => {
                          setNewPhoneNumberData({ ...newPhoneNumberData, area: value });
                          (errorFields as string[]).includes('area_id') && setErrorFields(errorFields.filter(item => item !== 'area_id'))
                        }}
                      /> :
                      <input
                        disabled={true}
                        type="text"
                        defaultValue={phoneNumberData.phone_number[0].area}
                      />
                  }
                </div>
              </div>

              <div className="field">
                <span>Call Flow:</span>

                <div className="input-wrapper">
                  {
                    editing ?
                      <Select
                        error={(errorFields as string[]).includes('callflow_id')}
                        disabled={!editing}
                        options={[{ name: '', call_flow_id: '' }].concat(phoneNumberData.edit.call_flows).map((call_flow) => ({
                          span: call_flow.name,
                          value: call_flow.call_flow_id
                        }))}
                        selectedOption={newPhoneNumberData.call_flow as string}
                        onChange={(value: string) => {
                          setNewPhoneNumberData({ ...newPhoneNumberData, call_flow: value });
                          (errorFields as string[]).includes('callflow_id') && setErrorFields(errorFields.filter(item => item !== 'callflow_id'))

                        }}
                      /> :
                      <input
                        disabled={true}
                        type="text"
                        defaultValue={phoneNumberData.phone_number[0].call_flow}
                      />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Info</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Date Created:</span>
                <input
                  type="text"
                  defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, phoneNumberData.phone_number[0].created_at)}
                  disabled={true}
                />
              </div>

              <div className="field">
                <span>Created by:</span>
                <input type="text" defaultValue={phoneNumberData.phone_number[0].created_by} disabled={true} />
              </div>
            </div>

            <div className="__right">

              <div className="field">
                <span>Last Edited:</span>
                <input
                  type="text"
                  defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, phoneNumberData.phone_number[0].updated_at)}
                  disabled={true}
                />
              </div>

              <div className="field">
                <span>Last Edited by:</span>
                <input type="text" defaultValue={phoneNumberData.phone_number[0].updated_by} disabled={true} />
              </div>
            </div>
          </div>
        </div>

        {editing ? (
          <div className="wrapper flex-container sb editing-buttons">
            <div>
              {
                phoneNumberData.permissions.phone_number_delete &&
                <button className="_red" onClick={() => setDeleting(true)}>Delete Phone Number</button>
              }
            </div>
            <div className="buttons">
              <button
                className="_bordered _red"
                onClick={() => handleCancelEdit()}
              >
                Cancel
              </button>
              <button
                className="_bordered _green"
                disabled={!readyToSave || !!errorFields.length || isSaving}
                onClick={() => handleSave()}
              >
                Save
              </button>
            </div>
          </div>
        ) : null}

        {/* Item share popup */}
        {phoneNumberData.permissions.phone_number_delete && deleting ? (
          <div className="item-delete-popup" onClick={() => setDeleting(false)}>

            <div className="wrapper" onClick={(e) => e.stopPropagation()}>

              <div className="title">
                Delete PhoneNumber
              </div>

              <div className="checkbox-wrapper">
                <Checkbox contents="I understand that after deleting the phone number it will not be possible to recover." value={readyToDelete} onChange={(value) => setReadyToDelete(value)} />
              </div>

              <div className="buttons">

                <button
                  className="_bordered _green"
                  onClick={() => setDeleting(false)}
                >
                  Cancel
                </button>

                <button
                  className="_bordered _red"
                  disabled={!readyToDelete || isSaving}
                  onClick={() => handleRemoveItem()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    ) : null}
  </>)
}

export default CallCenter_PhoneNumbersPage_Item
