import { useState, useEffect } from 'react'
import { useRoute } from "react-router5"
import classNames from 'classnames'
import qs from 'qs'

import Checkbox from '../../components/Checkbox'
import Select from '../../components/Select'
import Icon from '../../components/Icon'
import UnitTable from './UnitTable'
import ImageModal from '../Jobs/ImageModal'

import { useAppSelector } from "../../store/hooks"
import { httpClientUpdate, nErrorUpdate } from '../../funcs'

import { UnitProps } from '../Jobs/ItemTech'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

import "../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: DataProps
}

interface DataProps {
  permissions: {
    technician_part_delete: boolean
    technician_part_edit: boolean
  }
  part: PartProps
  edit: {
    part_status: {
      part_status: string
      part_status_id: number
    }[]
    warehouses: {
      warehouse: string
      warehouse_id: string
    }[]
    invoices: {
      invoice_id: string
      invoice_name: string
      invoice_total: number
    }[]
    units: {
      appliance: string
      brand: string
      other_appliance: string
      other_brand: string
      unit_id: string
      unit_type: string
    }[]
  }
}

interface PartProps {
  part_id: string
  name: string
  price: string | number
  part_number: string | number
  warehouse_id: string
  warehouse: string
  other_warehouse: string
  owner: string
  status_id: number
  status: string
  job_id: string
  invoice_id: string
  invoice_name: string
  invoice_total: number
  unit_id: string[]
  unit_name: string | null
  quantity: number
  attachments: AttachmentsProps[]
  units: {
    appliance_id: string
    brand_id: string
    contact_id: string
    laravel_through_key: string
    model_number: string
    other_appliance: string
    other_brand: string
    serial_number: string
    unit_id: string
    unit_type: string
  }[]
}

interface AttachmentsProps {
  document_id: string
  job_attachment_id: string
  type: string
  url: string
}

interface PartsEditProps {
  switchPage: (name: string) => void
  id: string
  permission_edit: boolean
  units: UnitProps[]
  status_id: number
  job_id: string
}

export default function PartsEdit({
  switchPage,
  id,
  permission_edit,
  units,
  status_id,
  job_id,
}: PartsEditProps) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)

  const [editing, setEditing] = useState(false)
  const [readyToSave, setReadyToSave] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)

  const [data, setData] = useState<DataProps | null>(null)

  const [newData, setNewData] = useState<Partial<PartProps>>({})

  const [uploadImage, setUploadImage] = useState<File | null>(null)
  const [uploadImageBase64, setUploadImageBase64] = useState('')

  const [mode, setMode] = useState('')
  const [hover, setHover] = useState('')

  const [imageModal, setImageModal] = useState<{ url: string, id: string, is_delete: boolean } | null>(null)

  async function loadParts() {
    try {
      const { data: { data: partsData, success, error } } = await httpClientUpdate.get(`/technician/parts/${id}`, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpClientUpdateReport }
      if (success) {
        setData(partsData)
        setNewData({
          ...partsData.part,
          price: +partsData.part.price,
          unit_id: partsData.part.units.map(item => item.unit_id)
        })
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  useEffect(() => {
    loadParts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => setReadyToSave(true), [
    newData
  ])

  async function handleRemoveItem() {
    const response = await httpClientUpdate.delete(`/technician/parts/${id}`, {
      data: {
        account_id: activeAccountId,
      }
    })
    if (response.data.success) {
      switchPage('main')
    }
  }

  async function handleSaveImages() {
    try {
      // api/technician/jobs/8888821108z6i3wmr7/attachments
      const { data: { success } } = await httpClientUpdate.post(`/technician/jobs/${job_id}/attachments`, {
        account_id: activeAccountId,
        photo: uploadImageBase64,
        type: 'part',
        document_id: id
      })
      if (success) {
        loadParts()
        setMode('')
        setUploadImage(null)
        setUploadImageBase64('')
      }
    } catch (error) { }
  }

  async function handleDeleteImage() {
    try {
      if (imageModal) {
        // api/technician/jobs/attachments/888882157iv5e6gtmc?account_id=88888
        const { data: { success } } = await httpClientUpdate.delete(`/technician/jobs/attachments/${imageModal.id}`, {
          params: {
            account_id: activeAccountId,
          }
        })
        if (success) {
          loadParts()
          setImageModal(null)
          setHover('')
          setMode('')
          setUploadImage(null)
          setUploadImageBase64('')
        }
      }
    } catch (error) { }
  }

  function handleAddImage(file: File) {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      setUploadImageBase64(reader.result as string)
    });

    reader.readAsDataURL(file);

    setUploadImage(file)
  }

  function handleCancelEdit() {
    data && setNewData({
      ...data.part,
      price: +data.part.price,
      unit_id: data.part.unit_id ? data.part.unit_id : []
    })
    setEditing(false)
  }

  async function handleSave() {
    const response = await httpClientUpdate.put(`/technician/parts/${id}`, qs.stringify({
      account_id: activeAccountId,
      status_id: status_id,
      price: newData.price,
      part_number: newData.part_number,
      name: newData.name,
      warehouse_id: newData.warehouse_id,
      other_warehouse: newData.other_warehouse,
      job_id: newData.job_id,
      owner: newData.owner,
      invoice_id: newData.invoice_id,
      units: newData.unit_id,
      quantity: newData.quantity,
    }))
    if (response.data.success) {
      switchPage('main')
    }
  }

  function handleChangePrice(event: React.SyntheticEvent<EventTarget>) {
    let price = (event.target as HTMLInputElement).value
    let numberWithoutDot = price.split('.')
    if (numberWithoutDot[1].toString().length > 2) {
      let newNumber = `${numberWithoutDot[0]}${numberWithoutDot[1].toString()[0]}.${numberWithoutDot[1].toString()[1]}${price[price.length - 1]}`
      setNewData({
        ...newData,
        price: +newNumber
      })
    }
  }

  function handleChangePriceBackspace(event: any) {
    if (event.keyCode === 8) {
      event.preventDefault()
      let price = event.target.value
      let numberWithoutDot = `${price}`.split('.')
      if (numberWithoutDot[0].length > 1) {
        let newNumber = `${numberWithoutDot[0].slice(0, -1)}.${numberWithoutDot[0][numberWithoutDot[0].length - 1]}${numberWithoutDot[1][0]}`
        setNewData({
          ...newData,
          price: +newNumber
        })
      } else {
        let newNumber = `0.${numberWithoutDot[0][0]}${numberWithoutDot[1][0]}`
        setNewData({
          ...newData,
          price: +newNumber
        })
      }
    }
  }

  function handleFocus(event: any) {
    const target = event.currentTarget;

    target.type = 'text';
    target.setSelectionRange(target.value.length, target.value.length);
    target.type = 'number';
    target && target.focus()
  }

  function checkOtherField() {
    let warehouses = data?.edit.warehouses.filter(item => item.warehouse_id === newData.warehouse_id)
    return warehouses?.length === 1 ? warehouses[0].warehouse : ''
  }

  function getTotal() {
    let sum = 0

    if (newData.price && newData.quantity) {
      sum = Number(newData.price) * newData.quantity
    }


    return `$${sum.toFixed(2)}`
  }

  return (
    <>
      {
        data ?
          <div className="EstimatePage_Edit entity-edit item-job">
            {
              mode === '' &&
              <div className="wrapper flex-container sb">
                <div className="flex-container _gap-narrow">
                  <h1>Part: </h1>
                  <div className="item-name">
                    {newData.name}
                  </div>
                </div>

                <div style={{ display: 'flex' }} >
                  {
                    permission_edit &&
                    <button
                      disabled={editing}
                      style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
                      className="_wa"
                      onClick={() => switchPage('main')}
                    >
                      <Icon
                        style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                        viewBox="0 0 24 24"
                        icon="arrow-25"
                      />
                    </button>
                  }

                  {
                    !editing && data.part.owner !== 'Company' &&
                      data.permissions.technician_part_edit ? (
                      <button className="_wa _green" onClick={() => setEditing(true)}>
                        <Icon
                          style={{ width: '16px', height: '16px' }}
                          viewBox="0 0 16 16"
                          icon="pencil-1"
                        />
                      </button>
                    ) : null
                  }
                </div>
              </div>
            }

            {
              mode === 'attachments_add' &&
              <div className="wrapper flex-container sb">
                <div className="flex-container _gap-narrow">
                  <h1>Add Attachments: </h1>
                </div>

                <div style={{ marginBottom: '0' }} className='row'>
                  <button
                    disabled={editing}
                    style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
                    className="_wa"
                    onClick={() => setMode('')}
                  >
                    <Icon
                      style={{ width: '16px', height: '16px', minWidth: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                      viewBox="0 0 24 24"
                      icon="arrow-25"
                    />
                  </button>

                  <button
                    className={classNames('button-save-disabled', {
                      'button-save': !!uploadImage
                    })}
                    onClick={handleSaveImages}
                  >
                    Save
                  </button>
                </div>
              </div>
            }

            {
              mode === 'attachments_add' &&
              <div className='attachments-col'>
                <div className='add-gallary-photo'>
                  <label
                    htmlFor="filePicker"
                    className='add-image'
                    title='Upload a image'
                  >
                    Add From Gallery
                  </label>
                  <input
                    id="filePicker"
                    style={{ visibility: "hidden" }}
                    type="file"
                    name="myImage"
                    onChange={(event) => {
                      event.target.files && handleAddImage(event.target.files[0])
                    }}
                  />
                </div>

                {
                  uploadImage &&
                  <div className='photo-view'>
                    <img
                      style={{ maxHeight: '400px', objectFit: 'cover' }}
                      alt="not found"
                      src={URL.createObjectURL(uploadImage)}
                    />
                  </div>
                }
              </div>
            }

            {
              mode === '' &&
              <div className="fieldset">
                <div className={classNames('__show-on-wide', {
                  __respectAsidePanel: navActive && !phoneCall,
                  __phoneCall: phoneCall && !navActive,
                  __bothOpen: navActive && phoneCall,
                  __nonePanel: !navActive && !phoneCall
                })}>
                  <div className="fields">
                    <div className="__left">
                      <div className="field">
                        <span>Name:<span className='requered_field'>*</span></span>

                        <input
                          type="text"
                          className='model_number'
                          disabled={!editing}
                          defaultValue={newData.name}
                          onChange={({ target: { value } }) => setNewData({ ...newData, name: value })}
                        />
                      </div>

                      <div className="field">
                        <span>Part Number:<span className='requered_field'>*</span></span>

                        <input
                          type="text"
                          className='model_number'
                          disabled={!editing}
                          defaultValue={newData.part_number || ''}
                          onChange={({ target: { value } }) => setNewData({ ...newData, part_number: +value })}
                        />
                      </div>

                      <div className="field">
                        <span>Price:<span className='requered_field'>*</span></span>

                        <input
                          className='model_number'
                          type="number"
                          pattern="[0-9]*"
                          disabled={!editing}
                          style={{ textAlign: 'right' }}
                          value={(newData.price as number).toFixed(2)}
                          onFocus={(event) => handleFocus(event)}
                          onChange={(event) => handleChangePrice(event)}
                          onKeyDown={handleChangePriceBackspace}
                        />
                      </div>

                      <div className="field">
                        <span>Quantity:</span>

                        <input
                          className='model_number'
                          type="number"
                          disabled={!editing}
                          value={newData.quantity}
                          onChange={(event) => setNewData({ ...newData, quantity: +event.target.value })}
                        />
                      </div>
                    </div>

                    <div className="__right">
                      <div className="field">
                        <span>Warehouse:<span className='requered_field'>*</span></span>

                        <Select
                          disabled={!editing}
                          options={data.edit.warehouses.sort((a, b) => a.warehouse !== 'Other' ? a.warehouse.localeCompare(b.warehouse) : 0).map((option) => ({
                            span: option.warehouse,
                            value: option.warehouse_id
                          }))}
                          selectedOption={newData.warehouse_id as string}
                          onChange={(value) => setNewData({ ...newData, warehouse_id: value as string, other_warehouse: '' })}
                        />
                      </div>

                      {
                        editing && checkOtherField() === "Other" &&
                        <div className="field">
                          <span>Other Warehouse:<span className='requered_field'>*</span></span>

                          <input
                            className='model_number'
                            name='other_brand'
                            type="text"
                            disabled={!editing}
                            defaultValue={newData.other_warehouse}
                            onChange={({ target: { value } }) => setNewData({ ...newData, other_warehouse: value })}
                          />
                        </div>
                      }

                      <div className="field">
                        <span>Part`s Owner:<span className='requered_field'>*</span></span>

                        <Select
                          disabled={!editing}
                          options={['Technician', 'Company'].map((option) => ({
                            span: option,
                            value: option
                          }))}
                          selectedOption={newData.owner as string}
                          onChange={(value) => setNewData({ ...newData, owner: value as string })}
                        />
                      </div>
                    </div>
                  </div>

                  <div style={{ marginTop: '20px' }}>
                    <UnitTable
                      disabled={!editing}
                      units={units}
                      unit_id={newData.unit_id as string[]}
                      addUnit={(value) => setNewData({ ...newData, unit_id: value })}
                    />
                  </div>

                  <div
                    className='row unit_type'
                    style={{ marginTop: '40px' }}
                  >
                    <span style={{ fontWeight: 'bold' }}>Total:</span>

                    <span style={{ fontWeight: 'bold' }}> {getTotal()} </span>
                  </div>
                </div>

                <div className={classNames('__hide-on-wide', '__hide-on-mobile', {
                  __respectAsidePanel: navActive && !phoneCall,
                  __phoneCall: phoneCall && !navActive,
                  __bothOpen: navActive && phoneCall,
                  __nonePanel: !navActive && !phoneCall
                })}>
                  <div className="item">
                    <div className='row unit_type'>
                      <span>Name:<span className='requered_field'>*</span></span>

                      <input
                        type="text"
                        className='model_number'
                        disabled={!editing}
                        defaultValue={newData.name}
                        onChange={({ target: { value } }) => setNewData({ ...newData, name: value })}
                      />
                    </div>

                    <div className='row unit_type'>
                      <span>Part Number:<span className='requered_field'>*</span></span>

                      <input
                        type="text"
                        className='model_number'
                        disabled={!editing}
                        defaultValue={newData.part_number || ''}
                        onChange={({ target: { value } }) => setNewData({ ...newData, part_number: +value })}
                      />
                    </div>

                    <div className='row unit_type'>
                      <span>Price:<span className='requered_field'>*</span></span>

                      <input
                        className='model_number'
                        type="number"
                        pattern="[0-9]*"
                        disabled={!editing}
                        style={{ textAlign: 'right' }}
                        value={(newData.price as number).toFixed(2)}
                        onFocus={(event) => handleFocus(event)}
                        onChange={(event) => handleChangePrice(event)}
                        onKeyDown={handleChangePriceBackspace}
                      />
                    </div>

                    <div className='row unit_type'>
                      <span>Warehouse:<span className='requered_field'>*</span></span>

                      <Select
                        disabled={!editing}
                        options={data.edit.warehouses.sort((a, b) => a.warehouse !== 'Other' ? a.warehouse.localeCompare(b.warehouse) : 0).map((option) => ({
                          span: option.warehouse,
                          value: option.warehouse_id
                        }))}
                        selectedOption={newData.warehouse_id as string}
                        onChange={(value) => setNewData({ ...newData, warehouse_id: value as string, other_warehouse: '' })}
                      />
                    </div>

                    {
                      checkOtherField() === 'Other' &&
                      <div className="row unit_type other">
                        <span>Other Warehouse:<span className='requered_field'>*</span></span>

                        <input
                          className='model_number'
                          name='other_brand'
                          type="text"
                          disabled={!editing}
                          defaultValue={newData.other_warehouse}
                          onChange={({ target: { value } }) => setNewData({ ...newData, other_warehouse: value })}
                        />
                      </div>
                    }

                    <div className='row unit_type'>
                      <span>Part`s Owner:<span className='requered_field'>*</span></span>

                      <Select
                        disabled={!editing}
                        options={['Technician', 'Company'].map((option) => ({
                          span: option,
                          value: option
                        }))}
                        selectedOption={newData.owner as string}
                        onChange={(value) => setNewData({ ...newData, owner: value as string })}
                      />
                    </div>

                    <div className='row unit_type'>
                      <span>Quantity:</span>

                      <input
                        className='model_number'
                        type="number"
                        disabled={!editing}
                        value={newData.quantity}
                        onChange={(event) => setNewData({ ...newData, quantity: +event.target.value })}
                      />
                    </div>

                    <div className='line-items-content service'>
                      <UnitTable
                        disabled={!editing}
                        units={units}
                        unit_id={newData.unit_id as string[]}
                        addUnit={(value) => setNewData({ ...newData, unit_id: value })}
                      />
                    </div>

                    <div
                      className='row unit_type'
                      style={{ marginTop: '40px' }}
                    >
                      <span style={{ fontWeight: 'bold' }}>Total:</span>

                      <span style={{ fontWeight: 'bold' }}> {getTotal()} </span>
                    </div>
                  </div>
                </div>

                <div className={classNames('__show-on-mobile', {
                  __respectAsidePanel: navActive && !phoneCall,
                  __phoneCall: phoneCall && !navActive,
                  __bothOpen: navActive && phoneCall,
                  __nonePanel: !navActive && !phoneCall
                })}>
                  <div className="item">
                    <div className='row unit_type'>
                      <span>Name:<span className='requered_field'>*</span></span>

                      <input
                        type="text"
                        className='model_number'
                        disabled={!editing}
                        defaultValue={newData.name}
                        onChange={({ target: { value } }) => setNewData({ ...newData, name: value })}
                      />
                    </div>

                    <div className='row unit_type'>
                      <span>Part Number:<span className='requered_field'>*</span></span>

                      <input
                        type="text"
                        className='model_number'
                        disabled={!editing}
                        defaultValue={newData.part_number || ''}
                        onChange={({ target: { value } }) => setNewData({ ...newData, part_number: +value })}
                      />
                    </div>

                    <div className='row unit_type'>
                      <span>Price:<span className='requered_field'>*</span></span>

                      <input
                        className='model_number'
                        type="number"
                        pattern="[0-9]*"
                        disabled={!editing}
                        style={{ textAlign: 'right' }}
                        value={(newData.price as number).toFixed(2)}
                        onFocus={(event) => handleFocus(event)}
                        onChange={(event) => handleChangePrice(event)}
                        onKeyDown={handleChangePriceBackspace}
                      />
                    </div>

                    <div className='row unit_type'>
                      <span>Warehouse:<span className='requered_field'>*</span></span>

                      <Select
                        disabled={!editing}
                        options={data.edit.warehouses.sort((a, b) => a.warehouse !== 'Other' ? a.warehouse.localeCompare(b.warehouse) : 0).map((option) => ({
                          span: option.warehouse,
                          value: option.warehouse_id
                        }))}
                        selectedOption={newData.warehouse_id as string}
                        onChange={(value) => setNewData({ ...newData, warehouse_id: value as string, other_warehouse: '' })}
                      />
                    </div>

                    {
                      checkOtherField() === 'Other' &&
                      <div className="row unit_type other">
                        <span>Other Warehouse:<span className='requered_field'>*</span></span>

                        <input
                          className='model_number'
                          name='other_brand'
                          type="text"
                          disabled={!editing}
                          defaultValue={newData.other_warehouse}
                          onChange={({ target: { value } }) => setNewData({ ...newData, other_warehouse: value })}
                        />
                      </div>
                    }

                    <div className='row unit_type'>
                      <span>Part`s Owner:<span className='requered_field'>*</span></span>

                      <Select
                        disabled={!editing}
                        options={['Technician', 'Company'].map((option) => ({
                          span: option,
                          value: option
                        }))}
                        selectedOption={newData.owner as string}
                        onChange={(value) => setNewData({ ...newData, owner: value as string })}
                      />
                    </div>

                    <div className='row unit_type'>
                      <span>Quantity:</span>

                      <input
                        className='model_number'
                        type="number"
                        disabled={!editing}
                        value={newData.quantity}
                        onChange={(event) => setNewData({ ...newData, quantity: +event.target.value })}
                      />
                    </div>

                    <div className='line-items-content service'>
                      <UnitTable
                        disabled={!editing}
                        units={units}
                        unit_id={newData.unit_id as string[]}
                        addUnit={(value) => setNewData({ ...newData, unit_id: value })}
                      />
                    </div>

                    <div
                      className='row unit_type'
                      style={{ marginTop: '40px' }}
                    >
                      <span style={{ fontWeight: 'bold' }}>Total:</span>

                      <span style={{ fontWeight: 'bold' }}> {getTotal()} </span>
                    </div>
                  </div>
                </div>
              </div>
            }

            {editing ? (
              <div className="wrapper flex-container sb editing-buttons">
                <div>
                  {
                    data.permissions.technician_part_delete &&
                    <button className="_red" onClick={() => setDeleting(true)}>Delete Part</button>
                  }
                </div>
                <div className="buttons">
                  <button
                    className="_bordered _red"
                    onClick={() => handleCancelEdit()}
                  >
                    Cancel
                  </button>
                  <button
                    disabled={!readyToSave}
                    className="_bordered _green"
                    onClick={() => handleSave()}
                  >
                    Save
                  </button>
                </div>
              </div>
            ) : null}

            {/* Item share popup */}
            {deleting ? (
              <div className="item-delete-popup" onClick={() => setDeleting(false)}>

                <div className="wrapper" onClick={(e) => e.stopPropagation()}>

                  <div className="title">
                    Delete Part
                  </div>

                  <div className="checkbox-wrapper">
                    <Checkbox contents="I understand that after deleting the part it will not be possible to recover." value={readyToDelete} onChange={(value) => setReadyToDelete(value)} />
                  </div>

                  <div className="buttons">

                    <button
                      className="_bordered _green"
                      onClick={() => setDeleting(false)}
                    >
                      Cancel
                    </button>

                    <button
                      disabled={!readyToDelete}
                      className="_bordered _red"
                      onClick={() => handleRemoveItem()}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            ) : null}

            {
              !!imageModal &&
              <ImageModal
                navActive={navActive}
                phoneCall={phoneCall}
                setModal={setImageModal}
                modal={imageModal}
                handleDelete={handleDeleteImage}
              />
            }

            {
              mode === '' &&
              <div className="fieldset">
                <div className={classNames({
                  __respectAsidePanel: navActive && !phoneCall,
                  __phoneCall: phoneCall && !navActive,
                  __bothOpen: navActive && phoneCall,
                  __nonePanel: !navActive && !phoneCall
                })}>
                  <div className="row">
                    <span>Attachments:</span>

                    <button
                      className='attachments-button'
                      onClick={() => setMode('attachments_add')}
                    >
                      <Icon viewBox="0 0 60 60" icon="plus-job-tech-1" />
                    </button>
                  </div>

                  {
                    !!newData?.attachments?.length &&
                    <div
                      className='row'
                      style={{ justifyContent: 'flex-start', gap: '20px' }}
                    >
                      {
                        newData?.attachments && newData?.attachments.map((item) => (
                          <div
                            className='photo-view attachments'
                            key={item.job_attachment_id}
                            onMouseEnter={() => setHover(item.job_attachment_id)}
                            onMouseLeave={() => setHover('')}
                            onClick={() => hover === item.job_attachment_id && setImageModal({ id: item.job_attachment_id, url: item.url, is_delete: false })}

                          >
                            <img
                              style={{ maxHeight: '100px', objectFit: 'cover' }}
                              alt="not found"
                              src={item.url}
                            />

                            {
                              hover === item.job_attachment_id &&
                              <div className='attachments-delete'>Open</div>
                            }
                          </div>
                        ))
                      }
                    </div>
                  }
                </div>
              </div>
            }
          </div> : null
      }
    </>
  )
}
