import { useState } from "react";
import { BaseLink, useRoute } from "react-router5";
import classNames from "classnames";
import { Scrollbar } from "react-scrollbars-custom";

import Icon from "../components/Icon";

import { ChannelsProps, NavActive, NavMode } from "../store/reducer";

import Menus from "../models/Menus";

type AsideNavPanelProps = {
  channels: ChannelsProps[] | null
  menus: Menus | null;
  navActive: NavActive;
  navMode: NavMode;
  isNavItemAllowed(page: string): boolean | null;
  onNavSwitchNotchLeft(): void;
  activeAccountId: string | null
  setUpdated(value: number): void
}

export default function AsideNavPanel({
  channels,
  menus,
  navActive,
  navMode,
  onNavSwitchNotchLeft,
  activeAccountId,
  setUpdated,
}: AsideNavPanelProps) {
  const $router = useRoute()

  const [showChannels, setShowChannels] = useState(true)

  return (
    <aside
      className={classNames({ _active: navActive.is, _floating: navActive.floating })}
      onMouseLeave={() => onNavSwitchNotchLeft()}
    >
      { /* Aside navigation */}
      <Scrollbar
        renderer={props => {
          const { elementRef, ...restProps } = props
          return <nav {...restProps} ref={elementRef} />
        }}
      >
        {
          navMode !== 'channels' ?
            menus ?
              (<>
                {menus[navMode].map(item => {
                  if (item.name === 'dashboard') {
                    return <BaseLink
                      key={item.name}
                      router={$router.router}
                      routeName={activeAccountId ? 'dashboard' : 'ownDashboard'}
                      routeParams={{ companyId: activeAccountId }}
                      className={classNames({ _active: $router.route.name === "dashboard" })}
                      onClick={() => $router.route.name === "dashboard" ? setUpdated(Math.random()) : setUpdated(0)}
                    >
                      <Icon icon="chart-5" />
                      <span>Dashboard</span>
                      <div className="__bg"></div>
                    </BaseLink>
                  } else if (item.name === 'schedule') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="schedule"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name === "schedule" })}
                        onClick={() => $router.route.name === "schedule" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon icon="calendar-4" />
                        <span>Schedule</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'dispatchers-schedule') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="dispatchers-schedule"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name === "dispatchers-schedule" })}
                        onClick={() => $router.route.name === "dispatchers-schedule" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon icon="calendar-4" />
                        <span>Dispatchers Schedule</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'google-local-service-ads') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="google-local-service-ads"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name === "google-local-service-ads" })}
                        onClick={() => $router.route.name === "google-local-service-ads" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon viewBox="0 0 1024 1024" icon="google-58" />
                        <span>Google Local Service Ads</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'booking') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="booking"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name === "booking" })}
                        onClick={() => $router.route.name === "booking" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon icon="calendar-9" />
                        <span>Booking</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'calls') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="calls"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('calls') })}
                        onClick={() => $router.route.name === "calls" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon icon="phone-1" />
                        <span>Calls</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'clients') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="clients"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('clients') })}
                        onClick={() => $router.route.name === "clients" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon icon="user-29" />
                        <span>Clients</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'conversions') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="conversions"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('conversions') })}
                        onClick={() => $router.route.name === "conversions" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon
                          style={{ width: '24px', height: '24px', verticalAlign: 'middle', overflow: 'hidden' }}
                          viewBox="0 0 1024 1024"
                          icon="conversion-3"
                        />
                        <span>Conversions</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'templates') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="templates"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('templates') })}
                        onClick={() => $router.route.name === "templates" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon
                          style={{ width: '24px', height: '24px', verticalAlign: 'middle', overflow: 'hidden' }}
                          viewBox="0 0 1024 1024"
                          icon="templates-1"
                        />
                        <span>Templates</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'appointment-calendar') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="appointment_calendar"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name === "appointment_calendar" })}
                        onClick={() => $router.route.name === "appointment_calendar" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon icon="calendar-6" />
                        <span>Appointment Calendar</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'appointments') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="appointments"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('appointments') })}
                        onClick={() => $router.route.name === "appointments" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon icon="calendar-6" />
                        <span>Appointments</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'appointment-reschedule') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="appointment_reschedule"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('appointment_reschedule') })}
                        onClick={() => $router.route.name === "appointment_reschedule" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon icon="calendar-6" />
                        <span>Appointment Reschedule</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'customer-service') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="customer_service"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('customer_service') })}
                        onClick={() => $router.route.name === "customer_service" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon viewBox="0 0 1025 1024" icon="customer-service-1" />
                        <span>Customer Service</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'jobs') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="jobs"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('jobs') })}
                        onClick={() => $router.route.name === "jobs" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon icon="wrench-25" />
                        <span>Jobs</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'website-requests') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="website_requests"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('website_requests') })}
                        onClick={() => $router.route.name === "website_requests" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon icon="website-1" viewBox="0 0 1024 1024" />
                        <span>Website Request</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'estimates') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="estimates"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('estimates') })}
                        onClick={() => $router.route.name === "estimates" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon icon="calculator-6" />
                        <span>Estimates</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'instruction_library') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="instruction_library"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('instruction_library') })}
                        onClick={() => $router.route.name === "instruction_library" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon viewBox="0 0 1024 1024" icon="library-116" />
                        <span>Instruction Library</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'invoices') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="invoices"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('invoices') })}
                        onClick={() => $router.route.name === "invoices" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon icon="currency-3" />
                        <span>Invoices</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'deposits') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="deposits"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('deposits') })}
                        onClick={() => $router.route.name === "deposits" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon viewBox="0 0 16 16" icon="deposits-1" />
                        <span>Deposits</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'payments') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="payments"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('payments') })}
                        onClick={() => $router.route.name === "payments" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon icon="coin-6" />
                        <span>Payments</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'links') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="links"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('links') })}
                        onClick={() => $router.route.name === "links" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon viewBox='0 0 1024 1024' icon="link-3" />

                        <span>Links</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'technician-payments') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="technician-payments"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name === 'technician-payments' })}
                        onClick={() => $router.route.name === "technician-payments" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon icon="coin-6" />
                        <span>Payments</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'technician-jobs') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="technician-jobs"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name === 'technician-jobs' })}
                        onClick={() => $router.route.name === "technician-jobs" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon icon="wrench-25" />
                        <span>Jobs List</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'payouts') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="payouts"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('payouts') })}
                        onClick={() => $router.route.name === "payouts" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon viewBox="0 0 16 16" icon="payouts-1" />
                        <span>Payouts</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'absences') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="absences"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('absences') })}
                        onClick={() => $router.route.name === "absences" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon icon="time-16" />
                        <span>Absences</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'tickets') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="tickets"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('tickets') })}
                        onClick={() => $router.route.name === "tickets" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon icon="debit-4" />
                        <span>Tickets</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'lists') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="lists"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('lists') })}
                        onClick={() => $router.route.name === "lists" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon icon="task-1" />
                        <span>Lists</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'users') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="users"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name === "users" })}
                        onClick={() => $router.route.name === "users" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon icon="user-21" />
                        <span>Users</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'profile') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="profile"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name === "profile" })}
                        onClick={() => $router.route.name === "profile" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon viewBox="0 0 1024 1024" icon="profile-12" />
                        <span>Profile</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'users-menus') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="users_menus"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name === "users_menus" })}
                        onClick={() => $router.route.name === "users_menus" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon icon="user-21" />
                        <Icon viewBox="0 0 20 20" style={{ position: 'absolute', left: '32px' }} icon="list-1" />
                        <span>Users Menus</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'accounts') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="accounts"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('accounts') && $router.route.name[8] !== '_' })}
                        onClick={() => $router.route.name === "accounts" && $router.route.name[8] !== '_' ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon icon="building-20" />
                        <span>Accounts</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'accounts-menus') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="accounts_menus"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('accounts_menus') })}
                        onClick={() => $router.route.name === "accounts_menus" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon icon="building-20" />
                        <Icon viewBox="0 0 20 20" style={{ position: 'absolute', left: '35px' }} icon="list-1" />
                        <span>Accounts Menus</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'permissions') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="permissions"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('permissions') })}
                        onClick={() => $router.route.name === "permissions" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon icon="key-3" />
                        <span>Permissions</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'accounts-permissions') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="accounts_permissions"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('accounts_permissions') })}
                        onClick={() => $router.route.name === "accounts_permissions" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon icon="lock-7" />
                        <span>Account`s Permissions</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'call-center') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="call_center"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('call_center') })}
                        onClick={() => $router.route.name === "call_center" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon icon="delivery-8" />
                        <span>Call Center</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'service-resources') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="service_resources"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('service_resources') })}
                        onClick={() => $router.route.name === "service_resources" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon icon="construction-3" />
                        <span>Service Resources</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'sms') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="sms"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('sms') })}
                        onClick={() => $router.route.name === "sms" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon viewBox="0 0 1024 1024" icon="sms-11" />
                        <span>SMS</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'user-groups') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="user_groups"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('user_groups') })}
                        onClick={() => $router.route.name === "user_groups" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon icon="user-21" />
                        <span>User Groups</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'reports') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="reports"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('reports') })}
                        onClick={() => $router.route.name === "reports" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon viewBox="0 0 1024 1024" icon="reports-1" />
                        <span>Reports</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'mailboxes') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="mailboxes"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('mailboxes') })}
                        onClick={() => $router.route.name === "mailboxes" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon icon="email-1" />
                        <span>Mailboxes</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'google-accounts') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="google-accounts"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('google-accounts') })}
                        onClick={() => $router.route.name === "google-accounts" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon viewBox="0 0 1024 1024" icon="google-58" />
                        <span>Google Accounts</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'google-local-services') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="google-local-services"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('google-local-services') })}
                        onClick={() => $router.route.name === "google-local-services" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon viewBox="0 0 1024 1024" icon="google-58" />
                        <span>Google Local Services</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'tools') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="tools"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('tools') })}
                        onClick={() => $router.route.name === "tools" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon viewBox="0 0 1024 1024" icon="tools-1" />
                        <span>Tools</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'mail') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="mail"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('mail') })}
                        onClick={() => $router.route.name === "mail" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon icon="email-1" />
                        <span>Mail</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'notify-templates') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="notify-templates"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('notify-templates') })}
                        onClick={() => $router.route.name === "notify-templates" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon icon="bell-1" />
                        <span>Notify Templates</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'notify-resources') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="notify-resources"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('notify-resources') })}
                        onClick={() => $router.route.name === "notify-resources" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon icon="bell-1" />
                        <span>Notify Resources</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'payment-terminal') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="payment-terminal"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('payment-terminal') })}
                        onClick={() => $router.route.name === "payment-terminal" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon icon="coin-6" />
                        <span>Payment Terminal</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'technician-payment-terminal') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="technician-payment-terminal"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('technician-payment-terminal') })}
                        onClick={() => $router.route.name === "technician-payment-terminal" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon icon="coin-6" />
                        <span>Payment Terminal</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'payout-dispatcher') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="payout-dispatcher"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('payout-dispatcher') })}
                        onClick={() => $router.route.name === "payout-dispatcher" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon viewBox="0 0 16 16" icon="payouts-1" />
                        <span>Payout Dispatcher</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'staff') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="staff"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('staff') })}
                        onClick={() => $router.route.name === "staff" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon icon="user-21" />
                        <span>Staff</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'payout-staff') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="payout-staff"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('payout-staff') })}
                        onClick={() => $router.route.name === "payout-staff" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon icon="user-21" />
                        <span>Payout Staff</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'time-tracking') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="time-tracking"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('time-tracking') })}
                        onClick={() => $router.route.name === "time-tracking" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon icon="time-16" />
                        <span>Time Tracking</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'check-available-tracker') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="check-available-tracker"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('check-available-tracker') })}
                        onClick={() => $router.route.name === "check-available-tracker" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon icon="time-16" />
                        <span>Check Available Tracker</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'additional-time') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="additional-time"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('additional-time') })}
                        onClick={() => $router.route.name === "additional-time" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon viewBox="0 0 1024 1024" icon="time-22" />
                        <span>Additional Time</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'chat-channels') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="chat-channels"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('chat-channels') })}
                        onClick={() => $router.route.name === "chat-channels" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon viewBox="0 0 1024 1024" icon="chat-80" />
                        <span>Chat Channels</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else if (item.name === 'websites') {
                    return (
                      <BaseLink
                        key={item.name}
                        router={$router.router}
                        routeName="websites"
                        routeParams={{ companyId: activeAccountId }}
                        className={classNames({ _active: $router.route.name.startsWith('websites') })}
                        onClick={() => $router.route.name === "websites" ? setUpdated(Math.random()) : setUpdated(0)}
                      >
                        <Icon icon="website-1" viewBox="0 0 1024 1024" />
                        <span>Websites</span>
                        <div className="__bg"></div>
                      </BaseLink>
                    )
                  } else {
                    return null
                  }
                })}
              </>) : null :
            <>
              <BaseLink
                router={$router.router}
                routeName="chat.threads"
                routeParams={{ companyId: activeAccountId }}
                className={classNames({ _active: $router.route.name === 'chat.threads' })}
                onClick={() => $router.route.name === "chat.threads" ? setUpdated(Math.random()) : setUpdated(0)}
              >
                <Icon viewBox="0 0 1024 1024" icon="chat-118" />
                <span>Threads</span>
                <div className="__bg"></div>
              </BaseLink>

              <BaseLink
                router={$router.router}
                routeName="chat.drafts"
                routeParams={{ companyId: activeAccountId }}
                className={classNames({ _active: $router.route.name === 'chat.drafts' })}
                onClick={() => $router.route.name === "chat.drafts" ? setUpdated(Math.random()) : setUpdated(0)}
              >
                <Icon viewBox="0 0 1024 1024" icon="send-21" />
                <span>Drafts & sent</span>
                <div className="__bg"></div>
              </BaseLink>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gridGap: '18px',
                  gap: '18px',
                  padding: '13px 18px',
                  textDecoration: 'none',
                  transition: '0.21s',
                  marginTop: '20px',
                }}
              >
                <div
                  style={{
                    width: '30px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'inherit',
                    cursor: 'pointer',
                  }}
                  onClick={() => setShowChannels(!showChannels)}
                  className="auto-complite-select"
                >
                  <Icon
                    style={{ width: '16px', fill: '#ADB5BD', transform: showChannels ? 'rotateX(360deg)' : 'rotate(270deg)' }}
                    icon="arrow-65"
                  />
                </div>

                <div>
                  <span
                    style={{
                      color: '#ADB5BD',
                      fontSize: '11pt',
                    }}
                  >
                    Channels
                  </span>
                </div>
              </div>
              {
                showChannels && channels && channels.map((item) => (
                  <BaseLink
                    key={item.channel_id}
                    router={$router.router}
                    routeName="chat.item"
                    routeParams={{ companyId: activeAccountId, channelId: item.channel_id }}
                    className={classNames('channels', {
                      _active: $router.route.params.channelId === item.channel_id,
                      _archive: !!item.is_archive
                    })}
                    onClick={() => $router.route.name === "chat.item" ? setUpdated(Math.random()) : setUpdated(0)}
                  >
                    <div className="channel-item">
                      <Icon style={{ minWidth: '24px' }} viewBox="0 0 1024 1024" icon="chat-80" />
                      <span>{item.name} {!!item.is_archive && `(Archive)`} </span>
                    </div>
                    {
                      !!item.unread_messages_with_notification &&
                      <div className="count">
                        <span> {item.unread_messages_with_notification} </span>
                      </div>
                    }
                    <div className="__bg"></div>
                  </BaseLink>
                ))
              }
            </>
        }
      </Scrollbar>
    </aside >
  )
}
