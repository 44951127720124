import classNames from 'classnames'
import { getSchuduleTime } from '../../funcs'

import { NavActive } from '../../store/reducer'

interface ScheduleModalProps {
  navActive: NavActive
  phoneCall: boolean
  setModal: (value: boolean) => void
  appointment: {
    appointment_finish: string
    appointment_id: string
    appointment_start: string
    appointment_type: string
    appointment_note: string
    appointment_status: string
    invoice_status: string
    time_zone: string
    appointment_last_time_slot_task: {
      appointment_id: string
      start: string
      time_slot_task_id: string
      time_slot_type: string
    }
    appointment_phones: {
      laravel_through_key: string
      name: string
      phone: string
    }[]
  }
  job_info: {
    job_id: string
    job_address: string
    job_name: string
    job_status: string
  }
  handleAppointmentUpdate: (id: string, status: string) => void
  handleJobUpdate: (id: string, status: string) => void
  isSaving: boolean
}

export default function ScheduleModal({
  navActive,
  phoneCall,
  setModal,
  appointment,
  job_info,
  handleAppointmentUpdate,
  handleJobUpdate,
  isSaving,
}: ScheduleModalProps) {
  return (
    <div
      className={classNames('link-page payment-page job-tech-customer time-tracking mobile', '__show-on-mobile',
        {
          __respectAsidePanel: navActive.is && !phoneCall,
          __phoneCall: phoneCall && !navActive.is,
          __bothOpen: navActive.is && phoneCall,
          __nonePanel: !navActive.is && !phoneCall
        }
      )}
      style={{ margin: 0 }}
    >
      <div className='link-menu'>
        <div style={{ background: '#1b2f5f' }} className="title">
          Change Status
        </div>

        <div className='change-status-block'>
          <div className='content-customer-appointment-row'>
            <div className='mobile-text'>
              {job_info.job_name}
            </div>

            <div
              className='status'
              style={{ opacity: isSaving ? '.6' : '1' }}
              onClick={() => job_info.job_status.toLocaleLowerCase() !== 'canceled' && !isSaving && handleJobUpdate(job_info.job_id, job_info.job_status)}
            >
              {job_info.job_status}
            </div>
          </div>

          <div className='content-customer-appointment-row'>
            <div className='content-customer-appointment-date'>
              {job_info.job_address}
            </div>
          </div>
        </div>

        <div className='change-status-block'>
          <div className='content-customer-appointment-row'>
            <div className='mobile-text'>
              Appointment:
            </div>

            <div
              className='status'
              style={{ opacity: isSaving ? '.6' : '1' }}
              onClick={() => appointment.appointment_status.toLocaleLowerCase() !== 'canceled' && !isSaving && handleAppointmentUpdate(appointment.appointment_id, appointment.appointment_status)}
            >
              {appointment.appointment_status}
            </div>
          </div>

          <div className='content-customer-appointment-row'>
            <div className='content-customer-appointment-date'>
              {getSchuduleTime(appointment.appointment_start, appointment.appointment_finish, appointment.time_zone)}
            </div>
          </div>

          <div className='content-customer-appointment-row'>
            <div className='mobile-text'>
              Note:
            </div>

            <div className='content-customer-appointment-note'>
              {appointment.appointment_note}
            </div>
          </div>
        </div>

        <div className="buttons">
          <button
            className="_bordered _red"
            onClick={() => setModal(false)}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  )
}
