import SelectComponent from './Select'

import './InputTextWithTitle.sass'

interface Option {
  span: string,
  value: any,
  is_connected?: boolean
}

interface SelectWithTitleProps {
  value: number
  setValue: (value: string, span: string) => void
  label: string
  width: number
  options: Option[]
}

export default function SelectIdWithTitle({ value, setValue, label, width, options }: SelectWithTitleProps) {
  return (
    <div
      className='input-text-title select'
      style={{ width: `${width}px`, minWidth: `${width}px` }}
    >
      <span className="label">{label}</span>

      <SelectComponent
        options={options.map((option) => ({
          span: option.span,
          value: option.value
        }))}
        selectedOption={value}
        onChange={(value, span) => setValue(value as string, span)}
      />
    </div>
  )
}
