import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import { DateTime } from "luxon"
import qs from "qs"
import classNames from "classnames"
import { useCookies } from "react-cookie";

import Icon from "../../components/Icon"
import ReportFilters from "../../components/reports/Filters"
import ReportTableControls from "../../components/reports/TableControls"
import ReportTableField from "../../components/reports/TableField"

import { dateToInfoBlock, getSchuduleTime, httpClientUpdate, nErrorUpdate, typeAppointmentForView, useOuterClick } from "../../funcs";
import { getDateRangeByPreset } from "../../funcs/reports"
import { useAppSelector } from "../../store/hooks"

import Appointment from "../../models/Appointment"
import { DateRangePreset } from "../../models/Misc"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/report-list.sass"

type FilterWord = 'type' | 'status' | 'property_type' | 'area' | 'created_by' | 'service_resource_id'

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: AppointmentsReport
}

interface AppointmentsReport {

  interface: {

    filter_words: {

      type: string[],
      status: string[],
      property_type: string[],
      area: string[],
      created_by: string[],
      service_resource_id: string[]
    },

    max_pages: number,
    rows_start: number,
    rows_end: number,
    rows_all: number,

    min_date: Date,
    max_date: Date,

    date_schedule_end: Date,
    date_schedule_start: Date,
  },

  permissions: {
    appointment_add: boolean
    appointment_delete: boolean
    appointment_edit: boolean
    appointment_report_show: boolean
    appointment_send: boolean
    appointment_show: boolean
  },

  dashboard: {

    service_calls: string,
    recalls: string,
    all: string,
    closed: string
  },

  appointments: Appointment[]

  edit: {
    service_resources: {
      code: number
      nickname: string
      service_resource_id: string
      time_zone: string
    }[]
    users: {
      user_id: string
      first_name: string
      last_name: string
      service_resource_code: number
      service_resource_nickname: string
    }[]
  }
}

function AppointmentsPage_List({ updated }: { updated: number }) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)
  const accessToken = useAppSelector((store) => store.accessToken)
  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)

  let timeZone = user?.time_zone;

  const [cookies, setCookie] = useCookies();

  const cookiesSetting = cookies.settings ? cookies.settings : null

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [isSending, setIsSending] = useState(false)

  const [$updater, $setUpdater] = useState<any>(Math.random())

  const [reportIsDeprecated, setReportIsDeprecated] = useState(false)
  const [searchButtonActive, setSearchButtonActive] = useState(false)

  const [selectedSendAppointment, setSelectedSendAppointment] = useState({ name: '', id: '' })

  const [reportData, setReportData] = useState<AppointmentsReport | null>(null)
  const [localInterface, setLocalInterface] = useState({
    search: '',
    appointmentSharePopup: false,
    dateRangeCalendarShown: false,
    dateRangeType: "schedule" as "created" | "schedule",
    dateRangePreset: "today" as DateRangePreset,
    ...(getDateRangeByPreset(user, 'today', DateTime.now().setZone(timeZone).startOf('day').toJSDate(), DateTime.now().setZone(timeZone).endOf('day').toJSDate())),
    page: 1,
    filter_words: cookiesSetting && cookiesSetting?.appointments?.filter_field ? {
      type: [] as string[],
      status: [] as string[],
      property_type: [] as string[],
      area: [] as string[],
      created_by: [] as string[],
      service_resource_id: [] as string[],
      ...cookiesSetting.appointments.filter_field,
    } : {
      type: [] as string[],
      status: [] as string[],
      property_type: [] as string[],
      area: [] as string[],
      created_by: [] as string[],
      service_resource_id: [] as string[],
    },
    sort: {
      field: cookiesSetting && cookiesSetting?.appointments?.sort_field ? cookiesSetting.appointments.sort_field : 'created_at',
      direction: cookiesSetting && cookiesSetting?.appointments?.sort_type ? cookiesSetting.appointments.sort_type : 'down' as 'down' | 'up'
    },
    sortFields: [{
      span: 'Date',
      value: 'created_at'
    }, {
      span: 'Name',
      value: 'name'
    }, {
      span: 'Type',
      value: 'type'
    }, {
      span: 'Status',
      value: 'status'
    }, {
      span: 'Property Type',
      value: 'property_type'
    }, {
      span: 'Area',
      value: 'area'
    }, {
      span: 'Created by',
      value: 'created_by'
    }, {
      span: 'Created by Dispatcher Code',
      value: 'dispatcher_code'
    }, {
      span: 'Schedule time',
      value: 'schedule_time_start'
    }, {
      span: 'Service resource',
      value: 'service_resource'
    }, {
      span: 'Service resource Code',
      value: 'service_resource_code'
    }, {
      span: 'Is Sent',
      value: 'is_sent'
    }]
  })

  useEffect(() => {
    updated && setLocalInterface({
      ...localInterface,
      search: localInterface.search,
      appointmentSharePopup: false,
      dateRangeCalendarShown: false,
      dateRangeType: "schedule" as "created" | "schedule",
      dateRangePreset: "today" as DateRangePreset,
      ...(getDateRangeByPreset(user, 'today', DateTime.now().setZone(timeZone).startOf('day').toJSDate(), DateTime.now().setZone(timeZone).endOf('day').toJSDate())),
      page: 1,
      filter_words: cookiesSetting && cookiesSetting?.appointments?.filter_field ? {
        type: [] as string[],
        status: [] as string[],
        property_type: [] as string[],
        area: [] as string[],
        created_by: [] as string[],
        service_resource_id: [] as string[],
        ...cookiesSetting.appointments.filter_field,
      } : {
        type: [] as string[],
        status: [] as string[],
        property_type: [] as string[],
        area: [] as string[],
        created_by: [] as string[],
        service_resource_id: [] as string[],
      },
      sort: {
        field: cookiesSetting && cookiesSetting?.appointments?.sort_field ? cookiesSetting.appointments.sort_field : 'created_at',
        direction: cookiesSetting && cookiesSetting?.appointments?.sort_type ? cookiesSetting.appointments.sort_type : 'down' as 'down' | 'up'
      },
    })
    updated && $setUpdater(updated)

    setTimeout(() => {
      updated && setReportIsDeprecated(false)
      updated && setSearchButtonActive(false)
    }, 100)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  // Watch date range preset
  useEffect(() => {

    if (!reportData) return

    if (localInterface.dateRangePreset === 'custom') return

    if (!updated) {
      if (localInterface.dateRangePreset === 'all') {
        if (localInterface.dateRangeType === 'schedule') {
          setLocalInterface({
            ...localInterface,
            ...(getDateRangeByPreset(user, localInterface.dateRangePreset, reportData?.interface.date_schedule_start, reportData?.interface.date_schedule_end))
          })
        } else {
          setLocalInterface({
            ...localInterface,
            ...(getDateRangeByPreset(user, localInterface.dateRangePreset, reportData?.interface.min_date, reportData?.interface.max_date))
          })
        }
      } else {
        setLocalInterface({
          ...localInterface,
          ...(getDateRangeByPreset(user, localInterface.dateRangePreset, reportData?.interface.min_date, reportData?.interface.max_date))
        })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.dateRangePreset])

  useEffect(() => {
    if (!reportData) return

    if (localInterface.dateRangePreset === 'all') {
      if (localInterface.dateRangeType === 'schedule') {
        setLocalInterface({
          ...localInterface,
          ...(getDateRangeByPreset(user, localInterface.dateRangePreset, reportData?.interface.date_schedule_start, reportData?.interface.date_schedule_end))
        })
      } else {
        setLocalInterface({
          ...localInterface,
          ...(getDateRangeByPreset(user, localInterface.dateRangePreset, reportData?.interface.min_date, reportData?.interface.max_date))
        })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.dateRangeType])

  // Load appointments function
  async function loadAppointments() {

    httpClientUpdate.defaults.headers['Authorization'] = `Bearer ${accessToken}`

    let activeFilters: any = { ...localInterface.filter_words }
    Object.keys(localInterface.filter_words).forEach((key: any) => !activeFilters[key].length && delete activeFilters[key])

    let reqData = {}

    if ($router.router.getState().params) {
      if ($router.router.getState().params.localInterface) {
        reqData = qs.stringify({
          account_id: activeAccountId,
          limit_rows: cookiesSetting && cookiesSetting?.appointments?.limit_rows ? cookiesSetting?.appointments?.limit_rows : reportsMaxRows,
          page: $router.router.getState().params.localInterface.page,
          date_type: $router.router.getState().params.localInterface.dateRangeType,
          date_start: $router.router.getState().params.localInterface.min_date,
          date_end: $router.router.getState().params.localInterface.max_date,
          sort_field: $router.router.getState().params.localInterface.sort.field,
          sort_type: $router.router.getState().params.localInterface.sort.direction === 'up' ? 'asc' : 'desc',
          filters: activeFilters,
          ...($router.router.getState().params.localInterface.search ? { search: $router.router.getState().params.localInterface.search } : {})
        });
      } else {
        reqData = qs.stringify({
          account_id: activeAccountId,
          limit_rows: cookiesSetting && cookiesSetting?.appointments?.limit_rows ? cookiesSetting?.appointments?.limit_rows : reportsMaxRows,
          page: localInterface.page,
          date_type: localInterface.dateRangeType,
          date_start: localInterface.min_date,
          date_end: localInterface.max_date,
          sort_field: localInterface.sort.field,
          sort_type: localInterface.sort.direction === 'up' ? 'asc' : 'desc',
          filters: activeFilters,
          ...(localInterface.search ? { search: localInterface.search } : {})
        });

        setCookie('settings', { ...cookiesSetting, appointments: { filter_field: activeFilters, sort_field: localInterface.sort.field, sort_type: localInterface.sort.direction, limit_rows: cookiesSetting && cookiesSetting?.appointments?.limit_rows ? cookiesSetting?.appointments.limit_rows : reportsMaxRows } })
      }
    }
    try {
      const { data: { data: appointments, success, error } } = await httpClientUpdate.post('/appointments/report', reqData, {
        headers: {
          'Accept': 'application/jsons',
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }) as { data: HttpClientUpdateReport }

      if (success) {
        if (appointments.permissions.appointment_report_show) {
          setReportData({
            ...appointments,

            appointments: appointments.appointments.map(item => {
              return {
                ...item,
                type: typeAppointmentForView(item.type)
              }
            }),

            interface: {
              ...appointments.interface,
              min_date: DateTime.fromISO(appointments.interface.min_date as unknown as string).startOf('day').toJSDate(),
              max_date: DateTime.fromISO(appointments.interface.max_date as unknown as string).endOf('day').toJSDate(),
              date_schedule_start: DateTime.fromISO(appointments.interface.date_schedule_start as unknown as string).startOf('day').toJSDate(),
              date_schedule_end: DateTime.fromISO(appointments.interface.date_schedule_end as unknown as string).endOf('day').toJSDate(),
            }
          })
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => {
        setReportIsDeprecated(false)
        setSearchButtonActive(false)
      }, 100)
    }
    catch (error: Error | AxiosError | unknown) {
      setCookie('settings', { ...cookiesSetting, appointments: {} })
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // send appointment
  async function handleSend() {
    setIsSending(true)

    try {
      // https://2022back4.artemiudintsev.com/api/notifications/serviceresource/appointment
      const response = await httpClientUpdate.post(`/notifications/serviceresource/appointment`, {
        account_id: activeAccountId,
        appointment_id: selectedSendAppointment.id
      })

      if (response.data.success) {
        setLocalInterface({ ...localInterface, appointmentSharePopup: false })
        setSelectedSendAppointment({ name: '', id: '' })
        loadAppointments()
        setIsSending(false)
      } else {
        setIsSending(false)
      }
    } catch (error) {
      setLocalInterface({ ...localInterface, appointmentSharePopup: false })
      setSelectedSendAppointment({ name: '', id: '' })
      setIsSending(false)
    }
  }

  function handleCancelSend() {
    setLocalInterface({ ...localInterface, appointmentSharePopup: false })
    setSelectedSendAppointment({ name: '', id: '' })
  }

  // Load appointments on page mount
  useEffect(() => {
    loadAppointments();

    // unmount page
    return () => {
      setReportData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$updater])

  // Watch for deprecated
  useEffect(() => {
    if (!reportData) return

    setReportIsDeprecated(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    localInterface.min_date,
    localInterface.max_date,
    localInterface.dateRangeType,
  ])

  useEffect(() => {
    if (!reportData) return
    setSearchButtonActive(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.search])

  useEffect(() => {
    if ($router.router.getState().params.localInterface) {
      setLocalInterface({
        ...$router.router.getState().params.localInterface
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$router.router.getState().params])

  // Is filter selected function
  function isFilterSelected(field: FilterWord, value: string) {
    return localInterface.filter_words[field].includes(value)
  }

  function isAllFiltersSelected(field: FilterWord) {
    return localInterface.filter_words[field].length === 0
  }

  function switchFilter(field: FilterWord, value: string, toggle: boolean) {

    let _arr = [...localInterface.filter_words[field]]

    if (toggle && value === "All") { _arr = [] }

    else if (!toggle) {

      while (true) {
        let i = _arr.findIndex((filter) => filter === value)
        if (i === -1) break
        _arr.splice(i, 1)
      }
    }

    else {

      if (reportData?.interface.filter_words[field].every((option) => _arr.concat([value]).includes(option)))
        _arr = []

      else if (_arr.findIndex((filter) => filter === value) === -1)
        _arr.push(value)
    }

    setLocalInterface({ ...localInterface, filter_words: { ...localInterface.filter_words, [field]: _arr } })
  }

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  function getServiceResourceName(id: string) {
    let name = ''

    reportData && reportData.edit.service_resources.forEach(item => {
      if (item.service_resource_id === id) {
        name = item.code ? `${item.nickname} (${item.code})` : item.nickname
      }
    })

    return name
  }

  function getUsersName(id: string) {
    let name = ''

    reportData && reportData.edit.users.forEach(item => {
      if (item.user_id === id) {
        name = item.service_resource_code ? `${item.service_resource_nickname} (${item.service_resource_code})` : item.service_resource_nickname
      }
    })

    return name
  }

  function handleSortDirectionCreatedName(value: string) {
    if (localInterface.sort.field === 'created_by') {
      setLocalInterface({ ...localInterface, sort: { field: 'dispatcher_code', direction: localInterface.sort.direction } })
    } else {
      setLocalInterface({ ...localInterface, sort: { field: 'created_by', direction: value } })
    }
  }

  function handleSortDirectionServiceName(value: string) {
    if (localInterface.sort.field === 'service_resource') {
      setLocalInterface({ ...localInterface, sort: { field: 'service_resource_code', direction: localInterface.sort.direction } })
    } else {
      setLocalInterface({ ...localInterface, sort: { field: 'service_resource', direction: value } })
    }
  }

  // Render function
  return (<>
    {reportData ? (
      <div className="AppointmentsPage_List" >

        { /* Reports grid */}
        <div className="reports-grid">

          <div className="cell">

            <div className="amount">{reportData.dashboard.service_calls}</div>
            <div className="legend">Service calls</div>
          </div>

          <div className="cell">

            <div className="amount">{reportData.dashboard.recalls}</div>
            <div className="legend">Recalls</div>
          </div>

          <div className="cell">

            <div className="amount">{reportData.dashboard.all}</div>
            <div className="legend">All appointments</div>
          </div>

          <div className="cell">

            <div className="amount">{reportData.dashboard.closed}</div>
            <div className="legend">Closed appointments</div>
          </div>
        </div>

        { /* Page header */}
        <div className="page-header">
          <h1>Appointments</h1>
        </div>

        { /* List filters */}
        <ReportFilters
          onSearchInputChange={(value) => setLocalInterface({ ...localInterface, search: value })}
          searchInput={localInterface.search}
          searchButtonActive={searchButtonActive}

          dateRangeType={localInterface.dateRangeType}
          onDateRangeTypeChange={(value) => setLocalInterface({ ...localInterface, dateRangeType: value as any, page: 1 })}

          dateRangePreset={localInterface.dateRangePreset}
          onDateRangePresetChange={(value) => setLocalInterface({ ...localInterface, dateRangePreset: value, page: 1 })}

          minDate={localInterface.min_date}
          onMinDateChange={(value) => setLocalInterface({ ...localInterface, min_date: value, dateRangePreset: 'custom', page: 1 })}

          maxDate={localInterface.max_date}
          onMaxDateChange={(value) => setLocalInterface({ ...localInterface, max_date: value, dateRangePreset: 'custom', page: 1 })}

          updateButtonActive={reportIsDeprecated}
          onUpdate={() => {
            $setUpdater(Math.random())
          }}

          phoneCall={phoneCall}
          navActive={navActive.is}
        />

        {/* Table controls */}
        <ReportTableControls
          zIndex={5}

          cookie_reportsMaxRows={cookiesSetting?.appointments?.limit_rows}

          onMaxRowsChange={(value) => {
            setCookie('settings', { ...cookiesSetting, appointments: { ...cookiesSetting.appointments, limit_rows: value } })
            $setUpdater(Math.random())
          }}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}
          onPagesStart={() => {
            setLocalInterface({ ...localInterface, page: 1 })
            $setUpdater(Math.random())
          }}
          onPrevPage={() => {
            setLocalInterface({ ...localInterface, page: localInterface.page - 1 })
            $setUpdater(Math.random())
          }}
          onNextPage={() => {
            setLocalInterface({ ...localInterface, page: localInterface.page + 1 })
            $setUpdater(Math.random())
          }}
          onPagesEnd={() => {
            setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })
            $setUpdater(Math.random())
          }}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
          onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
          onSortFire={() => $setUpdater(Math.random())}
        />

        <div className="contents">

          {/* Wide desktop table */}
          <table className={classNames('table', '__show-on-wide',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <thead>
              <tr>
                <ReportTableField
                  contents={(<span>Date</span>)}

                  sortDirection={localInterface.sort.field === 'created_at' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'created_at', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Name</span>)}

                  sortDirection={localInterface.sort.field === 'name' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'name', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Type</span>)}

                  allFiltersSelected={isAllFiltersSelected("type")}
                  onAllFiltersChange={(value) => switchFilter("type", "All", value)}

                  filterWords={reportData.interface.filter_words.type.map((filterWord) => ({
                    word: filterWord,
                    selected: isFilterSelected("type", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("type", value.word, value.selected)}
                  onFilterFire={() => $setUpdater(Math.random())}

                  sortDirection={localInterface.sort.field === 'type' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'type', direction: value } })}
                />
                <ReportTableField
                  contents={(<span>Status</span>)}

                  allFiltersSelected={isAllFiltersSelected("status")}
                  onAllFiltersChange={(value) => switchFilter("status", "All", value)}

                  filterWords={reportData.interface.filter_words.status.map((filterWord) => ({
                    word: filterWord,
                    selected: isFilterSelected("status", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("status", value.word, value.selected)}
                  onFilterFire={() => $setUpdater(Math.random())}

                  sortDirection={localInterface.sort.field === 'status' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'status', direction: value } })}
                />
                <ReportTableField
                  contents={(<span>Property Type</span>)}

                  allFiltersSelected={isAllFiltersSelected("property_type")}
                  onAllFiltersChange={(value) => switchFilter("property_type", "All", value)}

                  filterWords={reportData.interface.filter_words.property_type.map((filterWord) => ({
                    word: filterWord,
                    selected: isFilterSelected("property_type", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("property_type", value.word, value.selected)}
                  onFilterFire={() => $setUpdater(Math.random())}

                  sortDirection={localInterface.sort.field === 'property_type' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'property_type', direction: value } })}
                />
                <ReportTableField
                  contents={(<span>Area</span>)}

                  allFiltersSelected={isAllFiltersSelected("area")}
                  onAllFiltersChange={(value) => switchFilter("area", "All", value)}

                  filterWords={reportData.interface.filter_words.area.map((filterWord) => ({
                    word: filterWord,
                    selected: isFilterSelected("area", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("area", value.word, value.selected)}
                  onFilterFire={() => $setUpdater(Math.random())}

                  sortDirection={localInterface.sort.field === 'area' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'area', direction: value } })}
                />
                <ReportTableField
                  contents={(<span>Created by</span>)}

                  allFiltersSelected={isAllFiltersSelected("created_by")}
                  onAllFiltersChange={(value) => switchFilter("created_by", "All", value)}

                  filterWords={reportData.interface.filter_words.created_by.map((filterWord) => ({
                    word: filterWord,
                    word_name: getUsersName(filterWord),
                    selected: isFilterSelected("created_by", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("created_by", value.word, value.selected)}
                  onFilterFire={() => $setUpdater(Math.random())}

                  sortDirection={localInterface.sort.field === 'created_by' || localInterface.sort.field === 'dispatcher_code' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => handleSortDirectionCreatedName(value)}
                />
                <ReportTableField
                  contents={(<span>Schedule time</span>)}

                  sortDirection={localInterface.sort.field === 'schedule_time_start' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'schedule_time_start', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Service resource</span>)}

                  allFiltersSelected={isAllFiltersSelected("service_resource_id")}
                  onAllFiltersChange={(value) => switchFilter("service_resource_id", "All", value)}

                  filterWords={reportData.interface.filter_words.service_resource_id.map((filterWord) => ({
                    word: filterWord,
                    word_name: getServiceResourceName(filterWord),
                    selected: isFilterSelected("service_resource_id", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("service_resource_id", value.word, value.selected)}
                  onFilterFire={() => $setUpdater(Math.random())}

                  sortDirection={localInterface.sort.field === 'service_resource' || localInterface.sort.field === 'service_resource_code' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => handleSortDirectionServiceName(value)}
                />
                <ReportTableField
                  contents={(<Icon className="__icon" icon="arrow-60" />)}

                  sortDirection={localInterface.sort.field === 'is_sent' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'is_sent', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
              </tr>
            </thead>

            {reportData.appointments.map((appointment, i) => (
              <tr
                key={i}
                style={{ position: 'relative' }}
                className={reportData.permissions.appointment_show && appointment.appointment_id ? "" : "permission-show"}
                onClick={() =>
                  reportData.permissions.appointment_show &&
                  appointment.appointment_id &&
                  $router.router.navigate('appointments.item', {
                    companyId: activeAccountId,
                    appointmentId: appointment.appointment_id,
                    fromPage: 'appointments',
                    localInterface: localInterface,
                  }, { reload: true })}
                onContextMenu={(event) =>
                  reportData.permissions.appointment_show &&
                  appointment.appointment_id &&
                  handleChangeMenu(event, appointment.appointment_id, 'appointments', i)}
              >
                <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, appointment.created_at)}</td>
                <td>{appointment.name}</td>
                <td>
                  {appointment.type === 'Recall' ? (
                    <span className="red">
                      {appointment.type}
                    </span>
                  ) : appointment.type === 'Service call' ? (
                    <span className="green">
                      {appointment.type}
                    </span>
                  ) : (
                    <span className="blue">
                      {appointment.type}
                    </span>
                  )}
                </td>
                <td>
                  {
                    appointment.status === 'Canceled' ?
                      <span className="red">
                        {appointment.status}
                      </span> :
                      <span>
                        {appointment.status}
                      </span>
                  }
                </td>
                <td>{appointment.property_type}</td>
                <td>{appointment.area}</td>
                <td>{appointment.dispatcher_code ? `${appointment.created_by} (${appointment.dispatcher_code})` : appointment.created_by}</td>
                <td>{getSchuduleTime(appointment.schedule_time_start as string, appointment.schedule_time_finish as string, appointment.time_zone)}</td>
                <td>{appointment.service_resource_code ? `${appointment.service_resource}(${appointment.service_resource_code})` : appointment.service_resource}</td>
                <td>
                  {
                    (appointment.is_sent || reportData.permissions.appointment_send) &&
                    <button
                      className="_zeroed _iconed _blue"
                      disabled={appointment.is_sent || appointment.status === 'Canceled'}
                      onClick={(e) => {
                        e.stopPropagation();
                        reportData.permissions.appointment_send &&
                          setLocalInterface({ ...localInterface, appointmentSharePopup: true })
                        reportData.permissions.appointment_send && setSelectedSendAppointment({ name: appointment.name, id: appointment.appointment_id })
                      }}>
                      <Icon icon="arrow-60" />
                    </button>
                  }
                  {
                    reportData.permissions.appointment_show &&
                    showContext === `appointments_${appointment.appointment_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('appointments.item', {
                            companyId: activeAccountId,
                            appointmentId: appointment.appointment_id,
                            fromPage: 'appointments',
                            localInterface: localInterface
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/appointments/${appointment.appointment_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          {/* Medium desktop table */}
          <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <tr></tr>
            {reportData.appointments.map((appointment, i) => (
              <tr
                key={i}
                style={{ position: 'relative' }}
                className={reportData.permissions.appointment_show && appointment.appointment_id ? "" : "permission-show"}
                onClick={() =>
                  reportData.permissions.appointment_show &&
                  appointment.appointment_id &&
                  $router.router.navigate('appointments.item', {
                    companyId: activeAccountId,
                    appointmentId: appointment.appointment_id,
                    fromPage: 'appointments',
                    localInterface: localInterface
                  }, { reload: true })}
                onContextMenu={(event) =>
                  reportData.permissions.appointment_show &&
                  appointment.appointment_id &&
                  handleChangeMenu(event, appointment.appointment_id, 'appointments', i)}
              >
                <td>
                  <div>{appointment.name}</div>
                  <div>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, appointment.created_at)}</div>
                </td>
                <td>
                  <div>{appointment.service_resource_code ? `${appointment.service_resource}(${appointment.service_resource_code})` : appointment.service_resource}</div>
                  <div>{appointment.dispatcher_code ? `${appointment.created_by} (${appointment.dispatcher_code})` : appointment.created_by}</div>
                </td>
                <td>
                  <div>{appointment.area}</div>
                  <div>
                    {appointment.type === 'Recall' ? (
                      <span className="red">
                        {appointment.type}
                      </span>
                    ) : appointment.type === 'Service call' ? (
                      <span className="green">
                        {appointment.type}
                      </span>
                    ) : (
                      <span className="blue">
                        {appointment.type}
                      </span>
                    )}
                  </div>
                </td>
                <td>
                  <div>{appointment.property_type}</div>
                  <div>
                    {
                      appointment.status === 'Canceled' ?
                        <span className="red">
                          {appointment.status}
                        </span> :
                        <span>
                          {appointment.status}
                        </span>
                    }
                  </div>
                </td>
                <td>
                  <div>{getSchuduleTime(appointment.schedule_time_start as string, appointment.schedule_time_finish as string, appointment.time_zone)}</div>
                </td>
                <td>
                  {
                    (appointment.is_sent || reportData.permissions.appointment_send) &&
                    <button
                      className="_zeroed _iconed _blue"
                      disabled={appointment.is_sent || appointment.status === 'Canceled'}
                      onClick={(e) => {
                        e.stopPropagation();
                        reportData.permissions.appointment_send &&
                          setLocalInterface({ ...localInterface, appointmentSharePopup: true })
                        reportData.permissions.appointment_send && setSelectedSendAppointment({ name: appointment.name, id: appointment.appointment_id })
                      }}>
                      <Icon icon="arrow-60" />
                    </button>
                  }
                  {
                    reportData.permissions.appointment_show &&
                    showContext === `appointments_${appointment.appointment_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('appointments.item', {
                            companyId: activeAccountId,
                            appointmentId: appointment.appointment_id,
                            fromPage: 'appointments',
                            localInterface: localInterface
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/appointments/${appointment.appointment_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          {/* Mobile table items */}
          <div className={classNames('mobile-table-items', '__show-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>

            {reportData.appointments.map((appointment, i: number) => (
              <div
                className="item"
                key={i}
                onClick={() =>
                  reportData.permissions.appointment_show &&
                  appointment.appointment_id &&
                  $router.router.navigate('appointments.item', {
                    companyId: activeAccountId,
                    appointmentId: appointment.appointment_id,
                    fromPage: 'appointments',
                    localInterface: localInterface
                  }, { reload: true })}
              >

                <div className="__top">

                  <div className="__left">
                    <div className="flex-container">
                      <strong>{appointment.name}</strong>
                      {
                        (appointment.is_sent || reportData.permissions.appointment_send) &&
                        <button
                          className="_zeroed _iconed _blue"
                          disabled={appointment.is_sent || appointment.status === 'Canceled'}
                          onClick={(e) => {
                            e.stopPropagation();
                            reportData.permissions.appointment_send &&
                              setLocalInterface({ ...localInterface, appointmentSharePopup: true })
                            reportData.permissions.appointment_send && setSelectedSendAppointment({ name: appointment.name, id: appointment.appointment_id })
                          }}>
                          <Icon icon="arrow-60" />
                        </button>
                      }
                    </div>
                    <div>
                      {getSchuduleTime(appointment.schedule_time_start as string, appointment.schedule_time_finish as string, appointment.time_zone)}
                    </div>
                  </div>

                  <div className="__right">
                    <div>
                      {appointment.type === 'Recall' ? (
                        <span className="red">
                          {appointment.type}
                        </span>
                      ) : appointment.type === 'Service call' ? (
                        <span className="green">
                          {appointment.type}
                        </span>
                      ) : (
                        <span className="blue">
                          {appointment.type}
                        </span>
                      )}
                    </div>
                    <div>
                      {appointment.property_type}
                    </div>
                    <div>
                      <div className="fw500">
                        {
                          appointment.status === 'Canceled' ?
                            <span className="red">
                              {appointment.status}
                            </span> :
                            <span>
                              {appointment.status}
                            </span>
                        }
                      </div>
                    </div>
                  </div>
                </div>

                <div className="__bottom">

                  <div className="__left">
                    <div>
                      <span className="gray">Area:</span>
                      <span> {appointment.area}</span>
                    </div>
                    <div>
                      <span className="gray">Service Resourse:</span>
                      <span> {appointment.service_resource_code ? `${appointment.service_resource}(${appointment.service_resource_code})` : appointment.service_resource}</span>
                    </div>
                  </div>

                  <div className="__right">
                    <div>
                      {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, appointment.created_at)}
                    </div>
                    <div>
                      Created by {appointment.dispatcher_code ? `${appointment.created_by} (${appointment.dispatcher_code})` : appointment.created_by}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Table controls */}
        <ReportTableControls
          isUnder={true}

          cookie_reportsMaxRows={cookiesSetting?.appointments?.limit_rows}

          onMaxRowsChange={(value) => {
            setCookie('settings', { ...cookiesSetting, appointments: { ...cookiesSetting.appointments, limit_rows: value } })
            $setUpdater(Math.random())
          }}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}
          onPagesStart={() => {
            setLocalInterface({ ...localInterface, page: 1 })
            $setUpdater(Math.random())
          }}
          onPrevPage={() => {
            setLocalInterface({ ...localInterface, page: localInterface.page - 1 })
            $setUpdater(Math.random())
          }}
          onNextPage={() => {
            setLocalInterface({ ...localInterface, page: localInterface.page + 1 })
            $setUpdater(Math.random())
          }}
          onPagesEnd={() => {
            setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })
            $setUpdater(Math.random())
          }}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
          onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
          onSortFire={() => $setUpdater(Math.random())}
        />

        {/* Item share popup */}
        {localInterface.appointmentSharePopup ? (
          <div className="popup appointmentSharePopup" onClick={() => {
            setLocalInterface({ ...localInterface, appointmentSharePopup: false })
            setSelectedSendAppointment({ name: '', id: '' })
          }}>

            <div className="wrapper" onClick={(e) => e.stopPropagation()}>

              <div className="item-name">
                {selectedSendAppointment.name}
              </div>

              <button
                className="_wa _iconed _bordered _red"
                disabled={isSending}
                onClick={() => !isSending && handleCancelSend()}
              >
                <span>Cancel</span>
              </button>

              <button
                className="_wa _iconed _bordered _blue"
                disabled={isSending}
                onClick={() => !isSending && handleSend()}
              >
                <span>Send</span>
                <Icon icon="arrow-60" />
              </button>
            </div>
          </div>
        ) : null}
      </div>
    ) : null}
  </>)
}

export default AppointmentsPage_List
