import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import classNames from "classnames"
import moment from "moment"
import { DateTime } from "luxon";
import { Transition } from "react-transition-group"
import { useCookies } from "react-cookie";

import AudioPlayer from "../../components/AudioPlayer"
import Checkbox from "../../components/Checkbox"
import Icon from "../../components/Icon"
import ReportFilters from "../../components/reports/Filters"
import ReportTableControls from "../../components/reports/TableControls"
import ReportTableField from "../../components/reports/TableField"
import SelectComponent from "../../components/Select";
import ContactMenu from "../Calls/ContactMenu";

import { getDateRangeByPreset } from "../../funcs/reports"
import { dateToInfoBlock, formatPhoneNumber, formatPhoneNumberToServerString, httpClientUpdate, nErrorUpdate, useOuterClick } from "../../funcs";
import { useAppSelector } from "../../store/hooks"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";
import { DateRangePreset } from "../../models/Misc"
import Sms from "../../models/Sms";
import { CallCentersOptionsProps } from "../../App";

import "../../styles/pages/common/report-list.sass"

type FilterWord = 'dispatcher' | 'friendly_name' | 'status'

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: SmsReport
}

interface SmsReport {

  interface: {

    filter_words: {

      dispatcher: string[],
      friendly_name: string[],
      status: string[]
    },

    tag_words: string[],

    max_pages: number,

    rows_start: number,
    rows_end: number,
    rows_all: number,

    min_date: Date,
    max_date: Date
  },

  permissions: {
    message_dialog_show: boolean
    message_report_show: boolean
    message_send_message: boolean
    client_show: boolean
    use_private_contact_list: boolean
  },

  dashboard: {
    messages_today: number
    message_all: number
    dialog_all: number
    dialog_unread: number
  },

  messages: Sms[] | []

  edit: {
    call_centers: {
      call_center_id: string
      name: string
    }[]
  }
}

interface SmsPageProps {
  updated: number
  call_center_id: string
  callCentersOptions: CallCentersOptionsProps[]
}

function SmsPage({
  updated,
  call_center_id,
  callCentersOptions,
}: SmsPageProps) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)
  const user = useAppSelector((store) => store.user)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const accessToken = useAppSelector((store) => store.accessToken)

  let timeZone = user?.time_zone;

  const [cookies, setCookie] = useCookies();

  const cookiesSetting = cookies.settings ? cookies.settings : null

  const [$updater, $setUpdater] = useState<any>(Math.random())

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [addContact, setAddContact] = useState<null | { phone: string; friendly_name: string; error: string }>(null)
  const [editContact, setEditContact] = useState<null | { initial_name: string, friendly_name: string; id: string; error: string }>(null)

  const [topFilterShown, setTopFilterShown] = useState(false)

  const [reportIsDeprecated, setReportIsDeprecated] = useState(false)
  const [searchButtonActive, setSearchButtonActive] = useState(false)

  const [selectedCallCenter, setSelectedCallCenter] = useState($router.router.getState().params.selectedCallCenter ? $router.router.getState().params.selectedCallCenter : call_center_id)

  const [reportData, setReportData] = useState<SmsReport | null>(null)
  const [localInterface, setLocalInterface] = useState({
    search: '',
    callListenPopup: null as string | null,
    dateRangeCalendarShown: false,
    dateRangePreset: "last_7_days" as DateRangePreset,
    ...(getDateRangeByPreset(user, 'last_7_days', DateTime.now().setZone(timeZone).minus({ days: 6 }).startOf('day').toJSDate(), DateTime.now().setZone(timeZone).endOf('day').toJSDate())),
    page: 1,
    filter_words: cookiesSetting && cookiesSetting?.sms?.filter_field ? {
      dispatcher: [] as string[],
      friendly_name: [] as string[],
      status: [] as string[],
      ...cookiesSetting.sms.filter_field,
    } : {
      dispatcher: [] as string[],
      friendly_name: [] as string[],
      status: [] as string[],
    },
    sort: {
      field: cookiesSetting && cookiesSetting?.sms?.sort_field ? cookiesSetting.sms.sort_field : 'date',
      direction: cookiesSetting && cookiesSetting?.sms?.sort_type ? cookiesSetting.sms.sort_type : 'down' as 'down' | 'up'
    },
    tag_words: [] as string[],
    searchFilters: [["source", "system"]],
    selectedSearchFilters: { type: ['source'] as string[], direction: [] as string[] },
    sortFields: [{
      span: 'Date',
      value: 'date'
    }, {
      span: 'Caller Name',
      value: 'caller_name'
    }, {
      span: 'Client',
      value: 'client_id'
    }, {
      span: 'Contact',
      value: 'contact_name'
    }, {
      span: 'From',
      value: 'from'
    }, {
      span: 'Friendly Name',
      value: 'friendly_name'
    }, {
      span: 'To',
      value: 'to'
    }, {
      span: 'Messages',
      value: 'messages'
    }]
  })

  useEffect(() => {
    updated && setLocalInterface({
      ...localInterface,
      search: '',
      callListenPopup: null as string | null,
      dateRangeCalendarShown: false,
      dateRangePreset: "last_7_days" as DateRangePreset,
      ...(getDateRangeByPreset(user, 'last_7_days', DateTime.now().setZone(timeZone).minus({ days: 6 }).startOf('day').toJSDate(), DateTime.now().setZone(timeZone).endOf('day').toJSDate())),
      page: 1,
      filter_words: cookiesSetting && cookiesSetting?.sms?.filter_field ? {
        dispatcher: [] as string[],
        friendly_name: [] as string[],
        status: [] as string[],
        ...cookiesSetting.sms.filter_field,
      } : {
        dispatcher: [] as string[],
        friendly_name: [] as string[],
        status: [] as string[],
      },
      sort: {
        field: cookiesSetting && cookiesSetting?.sms?.sort_field ? cookiesSetting.sms.sort_field : 'date',
        direction: cookiesSetting && cookiesSetting?.sms?.sort_type ? cookiesSetting.sms.sort_type : 'down' as 'down' | 'up'
      },
      tag_words: [] as string[],
      searchFilters: [["source", "system"]],
      selectedSearchFilters: { type: ['source'] as string[], direction: [] as string[] },
    })
    updated && $setUpdater(updated)

    setTimeout(() => {
      updated && setReportIsDeprecated(false)
      updated && setSearchButtonActive(false)
    }, 100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  // Watch date range preset
  useEffect(() => {

    if (!reportData) return

    if (localInterface.dateRangePreset === 'custom') return

    !updated && setLocalInterface({
      ...localInterface,
      ...(getDateRangeByPreset(user, localInterface.dateRangePreset, reportData?.interface.min_date, reportData?.interface.max_date))
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.dateRangePreset])

  // Load calls function
  async function loadCalls() {
    httpClientUpdate.defaults.headers['Authorization'] = `Bearer ${accessToken}`

    let activeFilters: any = {}

    Object.keys(localInterface.filter_words).forEach((key: any) => {
      if (localInterface.filter_words[key].length) {
        activeFilters[`filters[${key}]`] = localInterface.filter_words[key]
      }
    })

    let filterParams: {
      account_id: string | null
      limit_rows: number
      page: number
      date_start: Date
      date_end: Date
      filter_tag: string
      main_filter: string
      sort_field: string
      sort_type: string
      filter_field: string
      call_center_id?: string
    } | any = {}

    if ($router.router.getState().params) {
      if ($router.router.getState().params.localInterface) {
        filterParams = {
          account_id: activeAccountId,
          limit_rows: cookiesSetting && cookiesSetting?.sms?.limit_rows ? JSON.stringify(cookiesSetting?.sms?.limit_rows) : JSON.stringify(reportsMaxRows),
          page: $router.router.getState().params.localInterface.page as number,
          date_start: $router.router.getState().params.localInterface.min_date as Date,
          date_end: $router.router.getState().params.localInterface.max_date as Date,
          sort_field: $router.router.getState().params.localInterface.sort.field as string,
          sort_type: $router.router.getState().params.localInterface.sort.direction === 'up' ? 'asc' : 'desc',
          ...activeFilters,
          filter_tag: JSON.stringify($router.router.getState().params.localInterface.tag_words),
          main_filter: JSON.stringify($router.router.getState().params.localInterface.selectedSearchFilters),
          ...($router.router.getState().params.localInterface.search ? { search: $router.router.getState().params.localInterface.search } : {})
        }
      } else {
        filterParams = {
          account_id: activeAccountId,
          limit_rows: cookiesSetting && cookiesSetting?.sms?.limit_rows ? JSON.stringify(cookiesSetting?.sms?.limit_rows) : JSON.stringify(reportsMaxRows),
          page: localInterface.page,
          date_start: localInterface.min_date,
          date_end: localInterface.max_date,
          sort_field: localInterface.sort.field,
          sort_type: localInterface.sort.direction === 'up' ? 'asc' : 'desc',
          filter_tag: JSON.stringify(localInterface.tag_words),
          main_filter: JSON.stringify(localInterface.selectedSearchFilters),
          ...activeFilters,
          ...(localInterface.search ? { search: localInterface.search } : {})
        }

        setCookie('settings', { ...cookiesSetting, sms: { filter_field: activeFilters, sort_field: localInterface.sort.field, sort_type: localInterface.sort.direction, limit_rows: cookiesSetting && cookiesSetting?.sms?.limit_rows ? cookiesSetting?.sms.limit_rows : reportsMaxRows } })
      }
    }

    if (selectedCallCenter) {
      filterParams.call_center_id = selectedCallCenter
    }

    try {
      const { data: { data: messagesData, success, error } } = (await httpClientUpdate.get('/messages/report', {
        params: {
          ...filterParams
        }
      })) as { data: HttpClientUpdateReport }

      if (success) {
        setReportData({
          ...messagesData,
          interface: {
            ...messagesData.interface,
            min_date: moment(messagesData.interface.min_date).startOf('day').toDate(),
            max_date: moment(messagesData.interface.max_date).endOf('day').toDate(),
          },
        })
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

      setTimeout(() => {
        setReportIsDeprecated(false)
        setSearchButtonActive(false)
      }, 100)
    }
    catch (error: Error | AxiosError | unknown) {
      setCookie('settings', { ...cookiesSetting, sms: {} })

      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function handleAddContact() {
    try {
      if (addContact) {
        // https://2022back4.artemiudintsev.com/api/lists/contacts
        const response = await httpClientUpdate.post(`/lists/contacts`, {
          account_id: activeAccountId,
          friendly_name: addContact.friendly_name,
          phone: formatPhoneNumberToServerString(addContact.phone),
        })

        if (response.data.success) {
          setAddContact(null)
          loadCalls()
        }
      }
    } catch (error) {
      let createdError = nErrorUpdate(error)
      addContact && setAddContact({ ...addContact, error: createdError.content.errorText })
    }
  }

  async function handleUpdateContact() {
    try {
      if (editContact) {
        // https://2022back4.artemiudintsev.com/api/lists/contacts/88888050uwc9p6ietm
        const response = await httpClientUpdate.put(`/lists/contacts/${editContact.id}`, {
          account_id: activeAccountId,
          friendly_name: editContact.friendly_name,
          // phone: formatPhoneNumberToServerString(addContact.phone),
        })

        if (response.data.success) {
          setEditContact(null)
          loadCalls()
        }
      }
    } catch (error) {
      let createdError = nErrorUpdate(error)
      editContact && setEditContact({ ...editContact, error: createdError.content.errorText })
    }
  }

  // Watch for deprecated
  useEffect(() => {
    if (!reportData) return
    setReportIsDeprecated(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    localInterface.min_date,
    localInterface.max_date
  ])

  useEffect(() => {
    if (!reportData) return
    setSearchButtonActive(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.search])

  // Load calls on page mount
  useEffect(() => {
    loadCalls()

    // unmount page
    return () => {
      setReportData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$updater, selectedCallCenter])

  // Update settings from back page
  useEffect(() => {
    if ($router.router.getState().params.localInterface) {
      setLocalInterface({
        ...$router.router.getState().params.localInterface
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$router.router.getState().params])

  // Is filter selected function
  function isFilterSelected(field: FilterWord, value: string) {
    return localInterface.filter_words[field].includes(value)
  }

  // Is all filters selected function
  function isAllFiltersSelected(field: FilterWord) {
    return localInterface.filter_words[field].length === 0
  }

  // Filter switch function
  function switchFilter(field: FilterWord, value: string, toggle: boolean) {

    let _arr = [...localInterface.filter_words[field]]

    if (toggle && value === "All") { _arr = [] }

    else if (!toggle) {

      while (true) {
        let i = _arr.findIndex((filter) => filter === value)
        if (i === -1) break
        _arr.splice(i, 1)
      }
    }

    else {

      if (reportData?.interface.filter_words[field].every((option) => _arr.concat([value]).includes(option)))
        _arr = []

      else if (_arr.findIndex((filter) => filter === value) === -1)
        _arr.push(value)
    }

    setLocalInterface({ ...localInterface, filter_words: { ...localInterface.filter_words, [field]: _arr } })
  }

  // Is search field selected function

  function isSearchFilterSelected(i: number, value: string, field: string) {

    if (field === 'type') {
      return localInterface.selectedSearchFilters.type.includes(value);
    }
    if (field === 'direction') {
      return localInterface.selectedSearchFilters.direction.includes(value);
    }
  }

  // Is all search fields selected function
  function isAllSearchFiltersSelected(i: number, field: string) {
    if (field === 'type') {
      return localInterface.selectedSearchFilters.type.length === 0
    } else if (field === 'direction') {
      return localInterface.selectedSearchFilters.direction.length === 0
    }

  }

  // Toggle search filter function
  function switchSearchFilter(i: number, value: string, toggle: boolean, field: string, $arr: string[]) {
    let $arrType = $arr;
    let $object = localInterface.selectedSearchFilters;
    if (toggle && value === "All") {
      $arrType = [];
    }
    else if (toggle === false) {
      for (let i = 0; i < $arrType.length; i++) {
        // eslint-disable-next-line no-loop-func
        $arrType.forEach((v, index) => {
          if (v === value) {
            $arrType.splice(index, 1)
          }
        })
      }
    } else {
      $arrType.push(value);
    }

    if (field === 'type') {
      if ($arrType.length === localInterface.searchFilters[0].length) {
        $arrType = [];
      }
      $object.type = $arrType;
    }
    if (field === 'direction') {
      if ($arrType.length === localInterface.searchFilters[1].length) {
        $arrType = [];
      }
      $object.direction = $arrType;
    }
    setLocalInterface({ ...localInterface, selectedSearchFilters: $object })
  }

  function handleChangeMenu(event: any, id: number, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  // Render function
  return (<>
    {reportData ? (
      <div className="SmsPage_List" >

        { /* Reports grid */}
        <div className="reports-grid">
          <div className="cell">
            <div className="amount">{reportData.dashboard?.messages_today}</div>
            <div className="legend">Today Messages</div>
          </div>

          <div className="cell">

            <div className="amount">{reportData.dashboard?.message_all}</div>
            <div className="legend"> All Messages</div>
          </div>

          <div className="cell">

            <div className="amount">{reportData.dashboard?.dialog_all}</div>
            <div className="legend">All Dialogs</div>
          </div>

          <div className="cell">

            <div className="amount">{reportData.dashboard?.dialog_unread}</div>
            <div className="legend">Unread Dialogs</div>
          </div>
        </div>

        { /* Page header */}
        <div
          className="page-header"
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <h1>Messages</h1>
          {
            reportData.permissions.message_send_message &&
            <div>
              <button
                className='send-message'
                onClick={(event) => {
                  event.preventDefault()
                  $router.router.navigate('sms.itemMessage', {
                    companyId: activeAccountId,
                    localInterface: localInterface,
                    selectedCallCenter: selectedCallCenter,
                  }, { reload: true })
                }}
              >
                Send Message
              </button>
            </div>
          }
        </div>

        { /* List filters */}
        <ReportFilters
          onSearchInputChange={(value) => setLocalInterface({ ...localInterface, search: value })}
          searchInput={localInterface.search}
          searchButtonActive={searchButtonActive}

          dateRangePreset={localInterface.dateRangePreset}
          onDateRangePresetChange={(value) => setLocalInterface({ ...localInterface, dateRangePreset: value, page: 1 })}

          minDate={localInterface.min_date}
          onMinDateChange={(value) => setLocalInterface({ ...localInterface, min_date: value, dateRangePreset: 'custom', page: 1 })}

          maxDate={localInterface.max_date}
          onMaxDateChange={(value) => setLocalInterface({ ...localInterface, max_date: value, dateRangePreset: 'custom', page: 1 })}

          updateButtonActive={reportIsDeprecated}
          onUpdate={() => {
            setReportIsDeprecated(false);
            $setUpdater(Math.random());
          }}
          phoneCall={phoneCall}
          navActive={navActive.is}

          childrenAfterSearch={(<>
            <div
              tabIndex={-1}
              className="filter-button"
              onFocus={() => setTopFilterShown(true)}
              onBlur={(e) => !e.currentTarget.contains(e.relatedTarget as any) && setTopFilterShown(false)}
            >
              <button className={classNames("_wa", "_rounded", "_iconed", {
                _blue: !isAllSearchFiltersSelected(0, 'type') || !isAllSearchFiltersSelected(1, 'direction')
              })}>
                <Icon icon="filter-8" />
              </button>

              <Transition in={topFilterShown} mountOnEnter={true} unmountOnExit={true} timeout={210}>
                {(state) => (
                  <div className={classNames("filter-wrapper", `transition-fade-${state}`)}>
                    <Checkbox contents="All" value={isAllSearchFiltersSelected(0, 'type')} onChange={(value) => switchSearchFilter(0, "All", value, 'type', localInterface.selectedSearchFilters.type)} />
                    {localInterface.searchFilters[0].map((option, index) => (
                      <Checkbox
                        key={index}
                        contents={option}
                        value={isSearchFilterSelected(0, option, 'type')}
                        onChange={(value) => switchSearchFilter(1, option, value, 'type', localInterface.selectedSearchFilters.type)}
                      />
                    ))}
                    <hr />
                    <button onClick={() => {
                      $setUpdater(Math.random());
                      setTopFilterShown(false)
                    }} className="_bordered _blue _wa">
                      Filter
                    </button>
                  </div>
                )}
              </Transition>
            </div>

            {
              callCentersOptions.length > 1 &&
              <SelectComponent
                options={callCentersOptions.map((item) => ({
                  span: item.name,
                  value: item.call_center_id,
                }))}
                selectedOption={selectedCallCenter as string}
                onChange={(value: string) => setSelectedCallCenter(value)}
              />
            }

            {
              reportData.permissions.use_private_contact_list &&
              <ContactMenu
                setUpdater={$setUpdater}
              />
            }
          </>)}
        />

        {/* Table controls */}
        <ReportTableControls
          zIndex={5}

          cookie_reportsMaxRows={cookiesSetting?.sms?.limit_rows}

          onMaxRowsChange={(value) => {
            setCookie('settings', { ...cookiesSetting, sms: { ...cookiesSetting.sms, limit_rows: value } })
            $setUpdater(Math.random())
          }}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}
          onPagesStart={() => {
            setLocalInterface({ ...localInterface, page: 1 })
            $setUpdater(Math.random())
          }}
          onPrevPage={() => {
            setLocalInterface({ ...localInterface, page: localInterface.page - 1 })
            $setUpdater(Math.random())
          }}
          onNextPage={() => {
            setLocalInterface({ ...localInterface, page: localInterface.page + 1 })
            $setUpdater(Math.random())
          }}
          onPagesEnd={() => {
            setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })
            $setUpdater(Math.random())
          }}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
          onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
          onSortFire={() => $setUpdater(Math.random())}
        />

        <div className="contents">

          {/* Wide desktop table */}
          <table className={classNames('table', '__show-on-wide',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <thead>
              <tr>
                <ReportTableField
                  contents={(<span>Date</span>)}

                  sortDirection={localInterface.sort.field === 'date' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'date', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Caller Name</span>)}

                  sortDirection={localInterface.sort.field === 'caller_name' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'caller_name', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Client</span>)}

                  sortDirection={localInterface.sort.field === 'client_id' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'client_id', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                {
                  reportData.permissions.use_private_contact_list &&
                  <ReportTableField
                    contents={(<span>Contact</span>)}

                    sortDirection={localInterface.sort.field === 'contact_id' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'contact_id', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />
                }
                <ReportTableField
                  contents={(<span>Client Phone</span>)}

                  sortDirection={localInterface.sort.field === 'from' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'from', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Friendly name</span>)}

                  allFiltersSelected={isAllFiltersSelected("friendly_name")}
                  onAllFiltersChange={(value) => switchFilter("friendly_name", "All", value)}

                  filterWords={reportData.interface.filter_words.friendly_name.map((filterWord) => ({
                    word: filterWord,
                    selected: isFilterSelected("friendly_name", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("friendly_name", value.word, value.selected)}

                  sortDirection={localInterface.sort.field === 'friendly_name' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'friendly_name', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Company Phone</span>)}

                  sortDirection={localInterface.sort.field === 'to' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'to', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Messages</span>)}

                  sortDirection={localInterface.sort.field === 'messages' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'messages', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
              </tr>
            </thead>

            {reportData?.messages.map((item, i) => (
              <tr
                key={i}
                className={classNames({
                  active_row: item.is_read !== null && !item.is_read
                })}
                style={{
                  position: 'relative',
                  cursor: reportData.permissions.message_report_show && item.message_dialog_id ? 'pointer' : 'inherit'
                }}
                onClick={() =>
                  reportData.permissions.message_report_show &&
                  item.message_dialog_id &&
                  $router.router.navigate('sms.item', {
                    companyId: activeAccountId,
                    dialogId: item.message_dialog_id,
                    localInterface: localInterface,
                    selectedCallCenter: selectedCallCenter,
                  }, { reload: true })}
                onContextMenu={(event) =>
                  reportData.permissions.message_report_show &&
                  item.message_dialog_id &&
                  handleChangeMenu(event, i, 'sms', i)}
              >
                <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, item.date)}</td>
                <td>{item.caller_name}</td>
                <td>{item.client_id ? (
                  <div
                    className="flex-container"
                    style={{ gap: '5px' }}
                  >
                    <button
                      className="_zeroed _iconed _gray"
                      style={{ cursor: reportData.permissions.client_show ? 'pointer' : 'inherit' }}
                      onClick={(event) => {
                        reportData.permissions.client_show && event.preventDefault()
                        reportData.permissions.client_show && event.stopPropagation()

                        reportData.permissions.client_show &&
                          $router.router.navigate('clients.item', {
                            companyId: activeAccountId,
                            clientId: item.client_id,
                            fromPage: 'sms',
                            localInterface: localInterface,
                            selectedCallCenter: selectedCallCenter,
                          }, { reload: true })
                      }}
                    >
                      <Icon icon="user-32" />
                    </button>
                    <span
                      style={{ cursor: reportData.permissions.client_show ? 'pointer' : 'inherit' }}
                      onClick={(event) => {
                        reportData.permissions.client_show && event.preventDefault()
                        reportData.permissions.client_show && event.stopPropagation()

                        reportData.permissions.client_show &&
                          $router.router.navigate('clients.item', {
                            companyId: activeAccountId,
                            clientId: item.client_id,
                            fromPage: 'sms',
                            localInterface: localInterface,
                            selectedCallCenter: selectedCallCenter,
                          }, { reload: true })
                      }}
                    >
                      {item.client}
                    </span>
                  </div>
                ) : null}</td>
                {
                  reportData.permissions.use_private_contact_list &&
                  <td>
                    <span
                      style={{ cursor: item.contact_id ? 'pointer' : 'inherit' }}
                      onClick={(event) => {
                        event.stopPropagation()
                        item.contact_id && setEditContact({ initial_name: item.contact, friendly_name: item.contact, id: item.contact_id, error: '' })
                      }}
                    >
                      {item.contact}
                    </span>
                  </td>
                }
                <td
                  style={{ cursor: item.contact_id ? 'pointer' : 'inherit' }}
                >
                  {
                    reportData.permissions.use_private_contact_list && !!!item.contact_id ?
                      <div
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <span>{formatPhoneNumber(item.from)}</span>
                        <div
                          style={{
                            width: '16px',
                            height: '16px',
                            border: '1px solid #ccc',
                            borderRadius: '50%',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '2px',
                            marginLeft: '5px',
                          }}
                          onClick={(event) => {
                            event.stopPropagation()
                            setAddContact({ phone: item.from, friendly_name: '', error: '' })
                          }}
                        >
                          <Icon style={{ minWidth: '10px' }} icon="plus-thin" />
                        </div>
                      </div> :
                      <span>{formatPhoneNumber(item.from)}</span>
                  }
                </td>
                <td>{item.friendly_name}</td>
                <td>{formatPhoneNumber(item.to)}</td>
                <td style={{ position: 'relative' }}>
                  {item.messages}
                  {
                    !!item.is_fault &&
                    <div
                      style={{
                        position: 'absolute',
                        background: '#FF0000',
                        color: '#fff',
                        borderRadius: '50%',
                        width: '20px',
                        height: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        right: '5px',
                        top: 'calc(50% - 10px)'
                      }}
                    >
                      !
                    </div>
                  }
                  {
                    reportData.permissions.message_report_show &&
                    showContext === `sms_${i}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('sms.item', {
                            companyId: activeAccountId,
                            dialogId: item.message_dialog_id,
                            localInterface: localInterface,
                            selectedCallCenter: selectedCallCenter,
                          }, { reload: true })
                        }}
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/sms/${item.message_dialog_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          {/* Medium screen table */}
          <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <tr></tr>
            {reportData.messages.map((item, i) => (
              <tr
                key={i}
                className={classNames({
                  active_row: item.is_read !== null && !item.is_read
                })}
                style={{
                  position: 'relative',
                  cursor: reportData.permissions.message_report_show && item.message_dialog_id ? 'pointer' : 'inherit'
                }}
                onClick={() =>
                  reportData.permissions.message_report_show &&
                  item.message_dialog_id &&
                  $router.router.navigate('sms.item', {
                    companyId: activeAccountId,
                    dialogId: item.message_dialog_id,
                    localInterface: localInterface,
                    selectedCallCenter: selectedCallCenter,
                  }, { reload: true })}
                onContextMenu={(event) =>
                  reportData.permissions.message_report_show &&
                  item.message_dialog_id &&
                  handleChangeMenu(event, i, 'sms', i)}
              >
                <td>
                  <div> {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, item.date)} </div>
                  <div>
                    {item.caller_name}
                  </div>
                </td>
                <td>
                  {
                    reportData.permissions.use_private_contact_list &&
                    <div
                      style={{ cursor: item.contact_id ? 'pointer' : 'inherit', width: 'fit-content' }}
                      onClick={(event) => {
                        event.stopPropagation()
                        item.contact_id && setEditContact({ initial_name: item.contact, friendly_name: item.contact, id: item.contact_id, error: '' })
                      }}
                    >
                      <span> {item.contact} </span>
                    </div>
                  }

                  {item.client_id ? (
                    <div
                      className="flex-container"
                      style={{ gap: '5px' }}
                    >
                      <button
                        className="_zeroed _iconed _gray"
                        style={{ cursor: reportData.permissions.client_show ? 'pointer' : 'inherit' }}
                        onClick={(event) => {
                          reportData.permissions.client_show && event.preventDefault()
                          reportData.permissions.client_show && event.stopPropagation()

                          reportData.permissions.client_show &&
                            $router.router.navigate('clients.item', {
                              companyId: activeAccountId,
                              clientId: item.client_id,
                              fromPage: 'sms',
                              localInterface: localInterface,
                              selectedCallCenter: selectedCallCenter,
                            }, { reload: true })
                        }}
                      >
                        <Icon icon="user-32" />
                      </button>

                      <span
                        style={{ cursor: reportData.permissions.client_show ? 'pointer' : 'inherit' }}
                        onClick={(event) => {
                          reportData.permissions.client_show && event.preventDefault()
                          reportData.permissions.client_show && event.stopPropagation()

                          reportData.permissions.client_show &&
                            $router.router.navigate('clients.item', {
                              companyId: activeAccountId,
                              clientId: item.client_id,
                              fromPage: 'sms',
                              localInterface: localInterface,
                              selectedCallCenter: selectedCallCenter,
                            }, { reload: true })
                        }}
                      >
                        {item.client}
                      </span>
                    </div>
                  ) : <span>&nbsp;</span>}
                </td>
                <td>
                  {
                    reportData.permissions.use_private_contact_list && !!!item.contact_id ?
                      <div
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <span>
                          Client Phone: {formatPhoneNumber(item.from)}
                        </span>

                        <div
                          style={{
                            width: '16px',
                            height: '16px',
                            border: '1px solid #ccc',
                            borderRadius: '50%',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '2px',
                            marginLeft: '10px',
                          }}
                          onClick={(event) => {
                            event.stopPropagation()
                            setAddContact({ phone: item.from, friendly_name: '', error: '' })
                          }}
                        >
                          <Icon style={{ minWidth: '10px' }} icon="plus-thin" />
                        </div>
                      </div> :
                      <div>
                        Client Phone: {formatPhoneNumber(item.from)}
                      </div>
                  }
                  <div>Company Phone: {formatPhoneNumber(item.to)}</div>
                </td>
                <td>
                  <div>{item.friendly_name}</div>
                  <div style={{ position: 'relative' }}>
                    Messages: {item.messages}
                    {
                      !!item.is_fault &&
                      <div
                        style={{
                          position: 'absolute',
                          background: '#FF0000',
                          color: '#fff',
                          borderRadius: '50%',
                          width: '20px',
                          height: '20px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          right: '5px',
                          top: 'calc(50% - 10px)'
                        }}
                      >
                        !
                      </div>
                    }
                  </div>

                  {
                    reportData.permissions.message_report_show &&
                    showContext === `sms_${i}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('sms.item', {
                            companyId: activeAccountId,
                            dialogId: item.message_dialog_id,
                            localInterface: localInterface,
                            selectedCallCenter: selectedCallCenter,
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/sms/${item.message_dialog_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          {/* Mobile table items */}
          <div className={classNames('mobile-table-items', '__show-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>

            {reportData.messages.map((item, i: number) => (
              <div
                className="item"
                key={i}
                style={{ background: (item.is_read !== null && !item.is_read) ? '#d8ebf6' : 'inherit' }}
                onClick={() =>
                  reportData.permissions.message_report_show &&
                  item.message_dialog_id &&
                  $router.router.navigate('sms.item', {
                    companyId: activeAccountId,
                    dialogId: item.message_dialog_id,
                    localInterface: localInterface,
                    selectedCallCenter: selectedCallCenter,
                  }, { reload: true })}
              >

                <div className="__top">

                  <div className="__left">
                    <div className="flex-container">
                      <strong>{item.friendly_name}</strong>
                    </div>
                    <div>
                      <strong>{item.caller_name}</strong>
                    </div>
                    {item.client_id ? (
                      <div
                        className="flex-container" style={{ gap: '5px' }}
                      >
                        <button
                          className="_zeroed _iconed _gray"
                          onClick={(event) => {
                            reportData.permissions.client_show && event.preventDefault()
                            reportData.permissions.client_show && event.stopPropagation()

                            reportData.permissions.client_show &&
                              $router.router.navigate('clients.item', {
                                companyId: activeAccountId,
                                clientId: item.client_id,
                                fromPage: 'sms',
                                localInterface: localInterface,
                                selectedCallCenter: selectedCallCenter,
                              }, { reload: true })
                          }}
                        >
                          <Icon icon="user-32" />
                        </button>
                        <span
                          onClick={(event) => {
                            reportData.permissions.client_show && event.preventDefault()
                            reportData.permissions.client_show && event.stopPropagation()

                            reportData.permissions.client_show &&
                              $router.router.navigate('clients.item', {
                                companyId: activeAccountId,
                                clientId: item.client_id,
                                fromPage: 'sms',
                                localInterface: localInterface,
                                selectedCallCenter: selectedCallCenter,
                              }, { reload: true })
                          }}
                        >
                          {item.client}
                        </span>
                      </div>
                    ) : (<span>&nbsp;</span>)}
                  </div>
                </div>

                <div className="__bottom">

                  <div className="__left">
                    {
                      reportData.permissions.use_private_contact_list &&
                      <div
                        style={{ cursor: item.contact_id ? 'pointer' : 'inherit', width: 'fit-content' }}
                        onClick={(event) => {
                          event.stopPropagation()
                          item.contact_id && setEditContact({ initial_name: item.contact, friendly_name: item.contact, id: item.contact_id, error: '' })
                        }}
                      >
                        <span> {item.contact} </span>
                      </div>
                    }
                    {
                      reportData.permissions.use_private_contact_list && !!!item.contact_id ?
                        <div
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <div>
                            <span className="gray">Client Phone:</span>
                            <span> {formatPhoneNumber(item.from)}</span>
                          </div>

                          <div
                            style={{
                              width: '16px',
                              height: '16px',
                              border: '1px solid #ccc',
                              borderRadius: '50%',
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              padding: '2px',
                              marginLeft: '10px',
                            }}
                            onClick={() => setAddContact({ phone: item.from, friendly_name: '', error: '' })}
                          >
                            <Icon style={{ minWidth: '10px' }} icon="plus-thin" />
                          </div>
                        </div> :
                        <div>
                          <span className="gray">Client Phone:</span>
                          <span> {formatPhoneNumber(item.from)}</span>
                        </div>
                    }
                    <div>
                      <span className="gray">Company Phone:</span>
                      <span> {formatPhoneNumber(item.to)}</span>
                    </div>
                    <div style={{ position: 'relative' }}>
                      <span className="gray">Messages:</span>
                      <span> {item.messages}</span>

                      {
                        !!item.is_fault &&
                        <div
                          style={{
                            position: 'absolute',
                            background: '#FF0000',
                            color: '#fff',
                            borderRadius: '50%',
                            width: '20px',
                            height: '20px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            right: '5px',
                            top: 'calc(50% - 10px)'
                          }}
                        >
                          !
                        </div>
                      }
                    </div>
                  </div>

                  <div className="__right">
                    <div>
                      {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, item.date)}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Table controls */}
        <ReportTableControls
          isUnder={true}

          cookie_reportsMaxRows={cookiesSetting?.sms?.limit_rows}

          onMaxRowsChange={(value) => {
            setCookie('settings', { ...cookiesSetting, sms: { ...cookiesSetting.sms, limit_rows: value } })
            $setUpdater(Math.random())
          }}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}
          onPagesStart={() => {
            setLocalInterface({ ...localInterface, page: 1 })
            $setUpdater(Math.random())
          }}
          onPrevPage={() => {
            setLocalInterface({ ...localInterface, page: localInterface.page - 1 })
            $setUpdater(Math.random())
          }}
          onNextPage={() => {
            setLocalInterface({ ...localInterface, page: localInterface.page + 1 })
            $setUpdater(Math.random())
          }}
          onPagesEnd={() => {
            setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })
            $setUpdater(Math.random())
          }}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
          onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
          onSortFire={() => $setUpdater(Math.random())}
        />

        {/* Call listen popup */}
        {localInterface.callListenPopup ? (
          <div className="popup callListenPopup" onClick={() => setLocalInterface({ ...localInterface, callListenPopup: null })}>

            <div className="wrapper" onClick={(e) => e.stopPropagation()}>

              <div className="call-player">
                <AudioPlayer audioSrc={localInterface.callListenPopup} />
              </div>
            </div>
          </div>
        ) : null}

        {
          addContact &&
          <div className="popup item-delete-popup">
            <div
              style={{ maxWidth: '350px', padding: '20px' }}
              className="wrapper"
              onClick={(e) => e.stopPropagation()}
            >
              <div
                className="title"
                style={{
                  background: '#6093de',
                  color: '#fff',
                  fontSize: '16pt',
                  padding: '7px 12px',
                  margin: '0 -20px',
                  textAlign: 'center'
                }}
              >
                <p style={{ margin: '0' }}>
                  Add contact for number
                </p>

                <p style={{ margin: '10px 0 0 0' }}>
                  {formatPhoneNumber(addContact.phone)}
                </p>
              </div>

              <div style={{ marginTop: '20px' }}>
                <input
                  type="text"
                  placeholder='Friendly Name'
                  value={addContact.friendly_name}
                  onChange={(event) => setAddContact({ ...addContact, friendly_name: event.target.value })}
                />
              </div>

              {
                !!addContact.error &&
                <div style={{ marginTop: '20px', textAlign: 'center', color: '#FF0000' }}>
                  {addContact.error}
                </div>
              }

              <div
                className="buttons"
                style={{
                  display: 'flex',
                  gridGap: '10px',
                  gap: '10px',
                  marginTop: '30px',
                  justifyContent: 'space-around'
                }}
              >
                <button
                  className="_bordered _red"
                  onClick={() => setAddContact(null)}
                >
                  <span>Cancel</span>
                </button>

                <button
                  disabled={addContact.friendly_name === ''}
                  className="_bordered _green"
                  onClick={() => handleAddContact()}
                >
                  <span>Save</span>
                </button>
              </div>
            </div>
          </div>
        }

        {
          editContact &&
          <div className="popup item-delete-popup">
            <div
              style={{ maxWidth: '350px', padding: '20px' }}
              className="wrapper"
              onClick={(e) => e.stopPropagation()}
            >
              <div
                className="title"
                style={{
                  background: '#6093de',
                  color: '#fff',
                  fontSize: '16pt',
                  padding: '7px 12px',
                  margin: '0 -20px',
                  textAlign: 'center'
                }}
              >
                <p style={{ margin: '0' }}>
                  Update contact name
                </p>
              </div>

              <div style={{ marginTop: '20px' }}>
                <input
                  type="text"
                  placeholder='Friendly Name'
                  value={editContact.friendly_name}
                  onChange={(event) => setEditContact({ ...editContact, friendly_name: event.target.value })}
                />
              </div>

              {
                !!editContact.error &&
                <div style={{ marginTop: '20px', textAlign: 'center', color: '#FF0000' }}>
                  {editContact.error}
                </div>
              }

              <div
                className="buttons"
                style={{
                  display: 'flex',
                  gridGap: '10px',
                  gap: '10px',
                  marginTop: '30px',
                  justifyContent: 'space-around'
                }}
              >
                <button
                  className="_bordered _red"
                  onClick={() => setEditContact(null)}
                >
                  <span>Cancel</span>
                </button>

                <button
                  disabled={editContact.friendly_name === '' || editContact.friendly_name === editContact.initial_name}
                  className="_bordered _green"
                  onClick={() => handleUpdateContact()}
                >
                  <span>Save</span>
                </button>
              </div>
            </div>
          </div>
        }
      </div>
    ) : null}
  </>)
}

export default SmsPage
