import classNames from 'classnames'
import { useState, useEffect } from 'react'

import Icon from '../../components/Icon'
import Checkbox from '../../components/Checkbox'

import { httpClientUpdate } from '../../funcs'
import { useAppSelector } from '../../store/hooks'

import { DataProps } from './LineItems'
import { UnitProps } from '../Jobs/ItemTech'
import UnitTable from './UnitTable'

interface AddMateralsProps {
  setPage: (value: string) => void
  data: DataProps
  setData: (value: DataProps) => void
  handleSave: (value: NewMaterialProps) => void
  handleSaveNewMaterial: (value: NewMaterialProps) => void
  units: UnitProps[]
}

export interface NewMaterialProps {
  name: string,
  description: string,
  part_number: number,
  price_per_unit: number,
  quantity: number,
  unit_id: string[],
  taxable: boolean,
  is_save_price_book: boolean,
  id: string
}

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: DataReportProps
}

interface DataReportProps {
  list_materials: ListProps[]
}

interface ListProps {
  description: string
  list_material_id: string
  name: string
  price: string
  is_favorite: boolean
  is_recent: boolean
}

interface InitialNewMaterial {
  name: string,
  description: string,
  part_number: number,
  price_per_unit: number,
  quantity: number,
  unit_id: string[],
  taxable: boolean,
  is_save_price_book: boolean,
  id: string
}

const initialNewMaterial = { name: '', description: '', part_number: 0, price_per_unit: 0, quantity: 1, unit_id: [], taxable: false, is_save_price_book: false, id: '' }

export default function AddMaterials({
  setPage,
  handleSave,
  units,
}: AddMateralsProps) {
  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [search, setSearch] = useState('')
  const [tab, setTab] = useState<'all' | 'favorite'>('all')

  const [materials, setMaterials] = useState<ListProps[]>([])
  const [materialsUsed, setMaterialsUsed] = useState<ListProps[]>([])

  const [addMaterials, setAddMaterials] = useState<NewMaterialProps>(initialNewMaterial)
  const [addNewMaterials, setAddNewMaterials] = useState<InitialNewMaterial>(initialNewMaterial)
  const [isAddAmount, setIsAddAmount] = useState(false)
  const [isAddNewAmount, setIsAddNewAmount] = useState(false)

  const [report, setReport] = useState<DataReportProps | null>(null)

  useEffect(() => {
    if (report) {
      let used: ListProps[] = []
      let elseServices: ListProps[] = []

      report.list_materials.forEach(item => {
        if (item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
          if (item.is_recent) {
            if (used.length < 10) {
              used.push(item)
            } else {
              elseServices.push(item)
            }
          } else {
            elseServices.push(item)
          }
        }
      })

      setMaterials(elseServices)
      setMaterialsUsed(used)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  useEffect(() => {
    if (report) {
      if (tab === 'all') {
        let used: ListProps[] = []
        let elseServices: ListProps[] = []

        report.list_materials.forEach(item => {
          if (item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
            if (item.is_recent) {
              if (used.length < 10) {
                used.push(item)
              } else {
                elseServices.push(item)
              }
            } else {
              elseServices.push(item)
            }
          }
        })

        setMaterials(elseServices)
        setMaterialsUsed(used)
      } else {
        let used: ListProps[] = []
        let elseServices: ListProps[] = []

        report.list_materials.forEach(item => {
          if (item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) && item.is_favorite) {
            if (item.is_recent) {
              if (used.length < 10) {
                used.push(item)
              } else {
                elseServices.push(item)
              }
            } else {
              elseServices.push(item)
            }
          }
        })

        setMaterials(elseServices)
        setMaterialsUsed(used)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab])

  useEffect(() => {
    getStore()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getStore() {
    try {
      // https://2022back4.artemiudintsev.com/api/technician/materials/report?account_id=88888
      const { data: { data: reportData, success } } = (await httpClientUpdate.get(`/technician/materials/report`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        },
        params: {
          account_id: activeAccountId
        }
      })) as { data: HttpClientUpdateReport }
      if (success) {
        let used: ListProps[] = []
        let elseServices: ListProps[] = []

        reportData.list_materials.forEach(item => {
          if (item.is_recent) {
            if (used.length < 10) {
              used.push(item)
            } else {
              elseServices.push(item)
            }
          } else {
            elseServices.push(item)
          }
        })
        setReport(reportData)

        setMaterials(elseServices)
        setMaterialsUsed(used)
      }

    }
    catch (error) { }
  }

  async function handleFavoriteService(id: string, is_favorite: boolean) {
    try {
      // service-material-users/use
      const response = await httpClientUpdate.post(`/service-material-users/use`, {
        account_id: activeAccountId,
        action: 'favorite',
        relatable_type: 'material',
        relatable_id: id,
        use: is_favorite ? 0 : 1,
      })

      if (response.data.success) {
        getStore()
      } else {
      }
    } catch (error) { }
  }

  async function handleSaveNewMaterials() {
    try {
      if (addNewMaterials.is_save_price_book) {
        // /api/technician/materials
        const response = await httpClientUpdate.post(`/technician/materials`, {
          account_id: activeAccountId,
          name: addNewMaterials.name,
          description: addNewMaterials.description,
          price: Number(addNewMaterials.price_per_unit).toFixed(2),
        })

        if (response.data.success) {
          handleSave({ ...addNewMaterials })
          setAddNewMaterials(initialNewMaterial)
          setIsAddNewAmount(false)
        } else {
        }
      } else {
        handleSave({ ...addNewMaterials })
      }
    } catch (error) { }
  }

  function handleAddService(name: string, comment: string, price: string, id: string) {
    setAddMaterials({ ...addMaterials, name: name, description: comment, price_per_unit: Number(price), id })
    setIsAddAmount(true)
  }

  function handleCancelAddService() {
    setAddMaterials(initialNewMaterial)
    setIsAddAmount(false)
    setIsAddNewAmount(false)
  }

  function handleSaveMaterials() {
    handleSave({ ...addMaterials })

    setAddMaterials(initialNewMaterial)
    setIsAddAmount(false)
  }

  function handleJobFocus(event: any) {
    const target = event.currentTarget;

    target.type = 'text';
    target.setSelectionRange(target.value.length, target.value.length);
    target.type = 'number';
    target && target.focus()
  }

  function handleJobChangeItem(event: any, name: string, is_new: string) {
    let price = event.target.value

    let numberWithoutDot = price.split('.')

    if (Number(price) >= 0) {
      if (numberWithoutDot[1] && numberWithoutDot[1].toString().length > 2) {
        let newNumber = `${numberWithoutDot[0]}${numberWithoutDot[1].toString()[0]}.${numberWithoutDot[1].toString()[1]}${price[price.length - 1]}`
        if (is_new === 'add') {
          setAddMaterials({ ...addMaterials, [name]: Number(newNumber) })
        } else {
          setAddNewMaterials({ ...addNewMaterials, [name]: Number(newNumber) })
        }
      } else {
        let newNumber = price

        if (is_new === 'add') {
          setAddMaterials({ ...addMaterials, [name]: Number(newNumber) })
        } else {
          setAddNewMaterials({ ...addNewMaterials, [name]: Number(newNumber) })
        }
      }
    }
  }

  function handleJobChangeItemBackspace(event: any, name: string, is_new: string) {
    if (event.keyCode === 8) {
      event.preventDefault()
      let price = event.target.value
      let numberWithoutDot = `${price}`.split('.')
      if (numberWithoutDot[0].length > 1) {
        let newNumber = `${numberWithoutDot[0].slice(0, -1)}.${numberWithoutDot[0][numberWithoutDot[0].length - 1]}${numberWithoutDot[1][0]}`
        if (is_new === 'add') {
          setAddMaterials({ ...addMaterials, [name]: Number(newNumber) })
        } else {
          setAddNewMaterials({ ...addNewMaterials, [name]: Number(newNumber) })
        }
      } else {
        let newNumber = `0.${numberWithoutDot[0][0]}${numberWithoutDot[1][0]}`
        if (is_new === 'add') {
          setAddMaterials({ ...addMaterials, [name]: Number(newNumber) })
        } else {
          setAddNewMaterials({ ...addNewMaterials, [name]: Number(newNumber) })
        }
      }
    }
  }

  return (
    <div className="EstimatePage_Add entity-edit">
      {
        !isAddAmount && !isAddNewAmount &&
        <div className="wrapper flex-container sb">
          <div className='row'>
            <button
              style={{ background: 'inherit' }}
              className="_wa"
              onClick={() => setPage('editing')}
            >
              <Icon
                style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#005eb5' }}
                viewBox="0 0 24 24"
                icon="arrow-25"
              />
            </button>

            <h1 style={{ whiteSpace: 'nowrap', marginTop: '0', color: '#005eb5' }}>New Estimate</h1>
          </div>

          <div className='row'>
            <button
              className="_zeroed _iconed _blue"
              style={{ color: '#005eb5', fontSize: '24px' }}
              onClick={() => setIsAddNewAmount(true)}
            >
              +
            </button>
          </div>
        </div>
      }

      {
        isAddAmount && !isAddNewAmount &&
        <div className="wrapper flex-container sb editing">
          <div>
            <button
              onClick={handleCancelAddService}
              className="button-cancel"
            >
              Cancel
            </button>
          </div>

          <div>
            <h1 style={{ whiteSpace: 'nowrap', marginTop: '0', color: '#393939' }}>Materials</h1>
          </div>

          <div>
            <button
              className={classNames('button-save-disabled', {
                'button-save': addMaterials.price_per_unit !== 0 && addMaterials.unit_id.length !== 0
              })}
              onClick={() => addMaterials.price_per_unit !== 0 && addMaterials.unit_id.length !== 0 && handleSaveMaterials()}
            >
              Add
            </button>
          </div>
        </div>
      }

      {
        isAddNewAmount &&
        <div className="wrapper flex-container sb editing">
          <div>
            <button
              onClick={handleCancelAddService}
              className="button-cancel"
            >
              Cancel
            </button>
          </div>

          <div>
            <h1 style={{ whiteSpace: 'nowrap', marginTop: '0', color: '#393939' }}>New Material</h1>
          </div>

          <div>
            <button
              className={classNames('button-save-disabled', {
                'button-save': addNewMaterials.price_per_unit !== 0 && addNewMaterials.name && addMaterials.unit_id.length !== 0
              })}
              onClick={() => addNewMaterials.price_per_unit !== 0 && addNewMaterials.name && addMaterials.unit_id.length !== 0 && handleSaveNewMaterials()}
            >
              Add
            </button>
          </div>
        </div>
      }

      {
        isAddAmount && !isAddNewAmount &&
        <div className='line-items-content service'>
          <div className='line-items-row'>
            <div className='name'>
              Name
            </div>

            <div className='name grey input-name'>
              <div className='input-view'>
                {addMaterials.name}
              </div>
            </div>
          </div>

          <div className='line-items-row'>
            <div className='name'>
              Description
            </div>

            <div className='name grey input-name'>
              <div className='input-view'>
                {addMaterials.description}
              </div>
            </div>
          </div>

          <div className='line-items-row'>
            <div className='name'>
              Price
            </div>

            <div className='name grey'>
              <input
                className="appearance"
                type='number'
                style={{ textAlign: 'right' }}
                value={`${Number(addMaterials.price_per_unit)}`}
                step={1}
                onFocus={(event) => handleJobFocus(event)}
                onChange={(event) => handleJobChangeItem(event, 'price_per_unit', 'add')}
                onKeyDown={(event) => handleJobChangeItemBackspace(event, 'price_per_unit', 'add')}
              />
            </div>
          </div>

          <div className='line-items-row'>
            <div className='name'>
              Quantity
            </div>

            <div className='name grey'>
              <input
                className="appearance"
                type='number'
                min={1}
                value={addMaterials.quantity}
                step={1}
                onChange={(event) => setAddMaterials({ ...addMaterials, quantity: +event.target.value })}
              />
            </div>
          </div>

          <UnitTable
            units={units}
            unit_id={addMaterials.unit_id}
            addUnit={(value) => setAddMaterials({ ...addMaterials, unit_id: value })}
          />

          <span className='note'> Material name appears on invoice </span>
        </div>
      }

      {
        isAddNewAmount &&
        <div className='line-items-content service'>
          <div className='line-items-row'>
            <div className='name'>
              Name
            </div>

            <div className='name grey input-name'>
              <input
                type="text"
                placeholder='Material name'
                value={addNewMaterials.name}
                onChange={(event) => setAddNewMaterials({ ...addNewMaterials, name: event.target.value })}
              />
            </div>
          </div>

          <div className='line-items-row'>
            <div className='name'>
              Description
            </div>

            <div className='name grey input-name'>
              <textarea
                placeholder='Description'
                rows={2}
                value={addNewMaterials.description}
                onChange={(event) => setAddNewMaterials({ ...addNewMaterials, description: event.target.value })}
              />
            </div>
          </div>

          <div className='line-items-row'>
            <div className='name'>
              Price
            </div>

            <div className='name grey'>
              <input
                className="appearance"
                type='number'
                style={{ textAlign: 'right' }}
                value={`${Number(addNewMaterials.price_per_unit).toFixed(2)}`}
                step={0.01}
                onFocus={(event) => handleJobFocus(event)}
                onChange={(event) => handleJobChangeItem(event, 'price_per_unit', 'new')}
                onKeyDown={(event) => handleJobChangeItemBackspace(event, 'price_per_unit', 'new')}
              />
            </div>
          </div>

          <div className='line-items-row'>
            <div className='name'>
              Quantity
            </div>

            <div className='name grey'>
              <input
                className="appearance"
                type='number'
                min={1}
                value={addNewMaterials.quantity}
                step={1}
                onChange={(event) => setAddNewMaterials({ ...addNewMaterials, quantity: +event.target.value })}
              />
            </div>
          </div>

          <UnitTable
            units={units}
            unit_id={addNewMaterials.unit_id}
            addUnit={(value) => setAddNewMaterials({ ...addNewMaterials, unit_id: value })}
          />

          <div className='line-items-row'>
            <div className=''>
              Save to price book
            </div>

            <Checkbox
              contents=""
              value={addNewMaterials.is_save_price_book}
              onChange={(value) => setAddNewMaterials({ ...addNewMaterials, is_save_price_book: value })}
            />
          </div>

          <span className='note'> Material name appears on invoice </span>
        </div>
      }

      {
        !isAddAmount && !isAddNewAmount &&
        <div className='line-items-content service'>
          <div className='line-items-col'>
            <div className='line-items-title bold'>
              Materials
            </div>

            <input
              type="text"
              placeholder='Search'
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />
          </div>

          <div className='line-items-tabs'>
            <div
              className={classNames('line-items-tab', { active: tab === 'all' })}
              onClick={() => setTab('all')}
            >
              All materials
            </div>

            <div
              className={classNames('line-items-tab', { active: tab === 'favorite' })}
              onClick={() => setTab('favorite')}
            >
              Favorites
            </div>
          </div>

          <div className='line-items-col'>
            <div className='line-items-title'>
              Frequently used items
            </div>

            {
              materialsUsed
                .map(item => (
                  <div
                    key={item.name}
                    className="services-item"
                    onClick={() => handleAddService(item.name, item.description, item.price, item.list_material_id)}
                  >
                    <div className='services-item-info'>
                      <div className='services-item-info-name'>
                        {item.name}
                      </div>

                      <div className='services-item-info-comment'>
                        {item.description}
                      </div>
                    </div>

                    <div className='services-item-info-price'>
                      <div
                        onClick={(event) => {
                          event.stopPropagation()
                          handleFavoriteService(item.list_material_id, item.is_favorite)
                        }}
                      >
                        {
                          item.is_favorite ?
                            <Icon
                              style={{ width: '20px', height: '20px', fill: 'rgba(0,95,181,255)' }}
                              viewBox="0 0 1024 1024"
                              icon="star-87"
                            /> :
                            <Icon
                              style={{ width: '20px', height: '20px', fill: 'rgba(0,95,181,255)' }}
                              viewBox="0 0 1024 1024"
                              icon="star-108"
                            />
                        }
                      </div>

                      <div className='services-item-info-comment'>
                        ${item.price}
                      </div>
                    </div>
                  </div>
                ))
            }
          </div>

          <div
            className='line-items-col'
            style={{ border: 'none' }}
          >
            <div className='line-items-title'>
              Materials
            </div>

            <div
              className="services-item"
              onClick={() => setIsAddNewAmount(true)}
            >
              <div> Custom Materials </div>

              <Icon
                style={{ width: '16px', height: '16px', fill: '#ccc' }}
                viewBox="0 0 24 24"
                icon="arrow-25"
              />
            </div>

            {
              materials
                .map(item => (
                  <div
                    key={item.name}
                    className="services-item"
                    onClick={() => handleAddService(item.name, item.description, item.price, item.list_material_id)}
                  >
                    <div className='services-item-info'>
                      <div className='services-item-info-name'>
                        {item.name}
                      </div>

                      <div className='services-item-info-comment'>
                        {item.description}
                      </div>
                    </div>

                    <div className='services-item-info-price'>
                      <div
                        onClick={(event) => {
                          event.stopPropagation()
                          handleFavoriteService(item.list_material_id, item.is_favorite)
                        }}
                      >
                        {
                          item.is_favorite ?
                            <Icon
                              style={{ width: '20px', height: '20px', fill: 'rgba(0,95,181,255)' }}
                              viewBox="0 0 1024 1024"
                              icon="star-87"
                            /> :
                            <Icon
                              style={{ width: '20px', height: '20px', fill: 'rgba(0,95,181,255)' }}
                              viewBox="0 0 1024 1024"
                              icon="star-108"
                            />
                        }
                      </div>

                      <div className='services-item-info-comment'>
                        ${item.price}
                      </div>
                    </div>
                  </div>
                ))

            }
          </div>
        </div>
      }
    </div>
  )
}
