import { useState, useEffect } from 'react'
import classNames from "classnames"
import copy from 'copy-to-clipboard';

import Icon from '../../components/Icon'
import CustomerMap from './CustomerMap'
import { PlacesAutocomplete } from './AutoCompliteAddress'

import CarImg from '../../images/car.svg'

import { usePosition } from './UsePosition';

interface PositionProps {
  lat: number,
  lng: number
}

interface CustomerAddressProps {
  switchPage: (name: string) => void
  address: string
}

export default function CustomerAddress({ switchPage, address }: CustomerAddressProps) {
  const [copied, setCopied] = useState(false)
  const [loadingGoogle, setLoadingGoogle] = useState(false)
  const [center, setCenter] = useState<PositionProps | null>(null)
  const [currentLocation, setCurrentLocation] = useState<PositionProps | null>(null)
  const [directionResponse, setDirectionResponse] = useState<google.maps.DirectionsResult | null>(null)
  const [distance, setDistance] = useState<string | undefined>('')
  const [duration, setDuration] = useState<string | undefined>('')
  const [steps, setSteps] = useState({
    distance: '',
    duration: '',
    maneuver: ''
  })
  const [updateAddress, setUpdateAddress] = useState<string>(address)
  const [mode, setMode] = useState('DRIVING')
  const [startDriving, setStartDriving] = useState<string>('Start')

  let { latitude, longitude } = usePosition()

  useEffect(() => {
    if (startDriving !== 'Start') {
      setTimeout(() => {
        let newTime = +startDriving - 1
        newTime >= -1 && setStartDriving(`${newTime}`)
      }, 1000)
    }

  }, [startDriving])

  useEffect(() => {
    if (latitude && longitude) {
      setCurrentLocation({ lat: latitude, lng: longitude })
    }
  }, [latitude, longitude])

  useEffect(() => {
    (async function () {
      if (currentLocation && center) {
        const directionsService = new google.maps.DirectionsService()
        const directionsRenderer = new google.maps.DirectionsRenderer()

        const results = await directionsService.route({
          origin: currentLocation,
          destination: center,
          travelMode: google.maps.TravelMode[mode],
        })

        results && directionsRenderer.setDirections(results);
        var leg = results.routes[0].legs[0];
        results && makeMarker(leg.start_location, 'icons.start', "Start");
        results && makeMarker(leg.end_location, 'icons.end', 'Finish');


        results && setSteps({
          distance: results.routes[0].legs[0].steps[0].distance?.text || '',
          duration: results.routes[0].legs[0].steps[0].duration?.text || '',
          maneuver: results.routes[0].legs[0].steps[1].maneuver
        })

        results && setDirectionResponse(results)
        results && setDistance(results.routes[0].legs[0].distance?.text)
        results && setDuration(results.routes[0].legs[0].duration?.text)
      }
    })();
  }, [currentLocation, center, updateAddress, mode]);

  useEffect(() => {
    setCopied(false)
  }, [address])

  function makeMarker(position: any, icon: string, title: string) {
    new google.maps.Marker({
      position: position,
      //  map: map,
      icon: icon,
      title: title
    });
  }

  async function handleCopy(e: any, text: string) {
    setCopied(true)
    copy(text)
  }

  return (
    <div className="UnitPage_Add entity-edit">
      <div className="wrapper flex-container sb" >
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <h1> {address} </h1>

          <button
            style={{ marginLeft: '5px', width: 'fit-content', backgroundColor: 'inherit' }}
            onClick={(e) => handleCopy(e, address)}
            title="Copy address"
          >
            <Icon
              style={{
                width: '24px',
                minWidth: '24px',
                height: '24px',
                verticalAlign: 'middle',
                overflow: 'hidden',
                fill: copied ? '#6093DE' : 'inherit'
              }}
              viewBox="0 0 1024 1024"
              icon="copy-1"
            />
          </button>
        </div>

        <button
          style={{ background: '#d0d3da', color: 'white' }}
          className="_wa"
          onClick={() => switchPage('main')}
        >
          <Icon
            style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
            viewBox="0 0 24 24"
            icon="arrow-25"
          />
        </button>
      </div>

      <div className="fieldset customer-address">
        <div className='customer-address-action'>
          <div style={{ position: 'relative' }} className='row'>
            {
              loadingGoogle &&
              <PlacesAutocomplete
                address={updateAddress}
                setCenter={setCenter}
                setUpdateAddress={setUpdateAddress}
              />
            }

            <div className='customer-address-action-btns' >
              <button
                style={{ width: '70px' }}
                className={classNames(
                  {
                    active: mode === 'DRIVING',
                  }
                )}
                onClick={() => setMode('DRIVING')}
              >
                <img src={CarImg} alt="car" />
              </button>

              <button
                style={{ width: '70px' }}
                className={classNames(
                  {
                    active: mode === 'WALKING',
                  }
                )}
                onClick={() => setMode('WALKING')}
              >
                <Icon
                  style={{ width: '40px', height: '24px', fill: 'black' }}
                  viewBox="0 0 24 24"
                  icon="walker-1"
                />

              </button>

              {
                startDriving !== '-1' &&
                <button
                  style={{ width: '70px', cursor: !directionResponse ? 'inherit' : 'pointer' }}
                  disabled={!directionResponse}
                  className={classNames('start-button',
                    {
                      active: !!directionResponse,
                    }
                  )}
                  onClick={() => setStartDriving('3')}
                >
                  {startDriving}
                </button>
              }
            </div>
          </div>

          <div
            style={{ justifyContent: 'space-around' }}
            className='row'>
            <div className='customer-address-route'>
              <p> Distance: </p>
              <p> {distance} </p>
            </div>

            <div className='customer-address-route'>
              <p> Duration: </p>
              <p> {duration} </p>
            </div>
          </div>

          <div
            style={{ justifyContent: 'space-between' }}
            className='row customer-address-action-navigation'
          >
            <div style={{ width: '50px', height: '50px' }}>
              {
                steps.maneuver.includes('left') &&
                <Icon
                  style={{ width: '100%', height: '100%', fill: 'black' }}
                  viewBox="0 0 18 18"
                  icon="turn-left-1"
                />
              }
              {
                steps.maneuver.includes('right') &&
                <Icon
                  style={{ width: '100%', height: '100%', fill: 'black' }}
                  viewBox="0 0 18 18"
                  icon="turn-right-1"
                />
              }
              {
                steps.maneuver.includes('straight') &&
                <Icon
                  style={{ width: '100%', height: '100%', fill: 'black' }}
                  viewBox="0 0 18 18"
                  icon="arrow-up-1"
                />
              }
            </div>

            <div style={{ height: '20px', width: '50px' }}>
              <p style={{ margin: '0', textAlign: 'center' }}>
                {steps.distance}
              </p>
            </div>
          </div>

        </div>
        <CustomerMap
          center={center}
          directionResponse={directionResponse}
          currentLocation={currentLocation}
          setLoadingGoogle={setLoadingGoogle}
          mode={mode}
          startDriving={startDriving}
        />
      </div>
    </div>
  )
}
