import { useEffect, useState } from "react";
import { BaseLink, useRoute } from "react-router5"
import ReactInputMask from "react-input-mask"
import qs from "qs";
import classNames from "classnames"

import Select from "../../../components/Select"
import Switcher from "../../../components/Switcher"
import Icon from "../../../components/Icon"
import SelectComponent from "../../../components/Select";

import { formatPhoneNumberToServerString, httpClientUpdate, nErrorUpdate } from "../../../funcs"
import { useAppSelector } from '../../../store/hooks'

import { CallCenter_PhoneNumber } from "../../../models/CallCenter"
import { PhoneNumbersReport } from "./List";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: PhoneNumbersReport
}

function CallCenter_PhoneNumbersPage_New() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [isSaving, setIsSaving] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)
  const [errorFields, setErrorFields] = useState<string[]>([])

  const [edit, setEdit] = useState<{
    types: string[],

    sources: {
      list_sources_id: string
      source: string
    }[],

    companies: {
      company_id: string
      name: string
    }[],

    areas: {
      area: string
      area_id: string
    }[],

    call_flows: {
      call_flow_id: string
      name: string
    }[]

    call_centers: {
      call_center_id: string
      name: string
    }[]
  } | null>(null)

  const [newPhoneNumberData, setNewPhoneNumberData] = useState<Partial<CallCenter_PhoneNumber> | null>(null)

  // save document
  async function handleSave() {
    setIsSaving(true)
    try {
      let phone = newPhoneNumberData?.phone ? formatPhoneNumberToServerString(newPhoneNumberData.phone) : ''

      const response = await httpClientUpdate.post(`/callcenter/phonenumbers`, {
        account_id: activeAccountId,
        phonenumber: phone,
        friendly_name: newPhoneNumberData?.friendly_name,
        active: newPhoneNumberData?.active === 'On' ? 1 : 0,
        conversions: newPhoneNumberData?.conversions === 'On' ? 1 : 0,
        types: newPhoneNumberData?.type ? newPhoneNumberData?.type : null,
        source_id: newPhoneNumberData?.source ? newPhoneNumberData?.source : null,
        company_id: newPhoneNumberData?.company ? newPhoneNumberData?.company : null,
        area_id: newPhoneNumberData?.area ? newPhoneNumberData?.area : null,
        callflow_id: newPhoneNumberData?.call_flow ? newPhoneNumberData?.call_flow : null,
        call_center_id: newPhoneNumberData?.call_center_id ? newPhoneNumberData?.call_center_id : null,
        messaging_service_sid: newPhoneNumberData?.messaging_service_sid ? newPhoneNumberData.messaging_service_sid : null,
      })
      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('call_center.phoneNumbers', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
      let createdError = nErrorUpdate(error)
      createdError.content.errorFields && setErrorFields(createdError.content.errorFields)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      const { data: { data: phoneNumbersData, success, error } } = (await httpClientUpdate.post('/callcenter/phonenumbers/report', qs.stringify({
        account_id: activeAccountId,
        limit_rows: 1,
        page: 1,
        date_type: 'created',
        sort_field: 'service_resource',
        sort_type: 'asc',
        filter_field: JSON.stringify({})
      }))) as { data: HttpClientUpdateReport }
      if (success) {
        if (phoneNumbersData.permissions.phone_number_add) {
          phoneNumbersData && setEdit(phoneNumbersData.edit)
          setNewPhoneNumberData({
            ...newPhoneNumberData,
            phone: $router.router.getState().params.phone_number ? $router.router.getState().params.phone_number : '',
            active: 'Off',
            conversions: 'On',
            type: '',
            source: '',
            company: '',
            area: '',
            call_flow: '',
            friendly_name: $router.router.getState().params.friendly_name ? $router.router.getState().params.friendly_name : '',
            call_center_id: '',
            messaging_service_sid: '',
          })
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load phoneNumber data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Watch changes
  useEffect(() => {
    let phone = newPhoneNumberData?.phone ? formatPhoneNumberToServerString(newPhoneNumberData.phone) : ''

    if (newPhoneNumberData?.call_center_id && phone.length === 12)
      setReadyToSave(true)
  }, [
    newPhoneNumberData
  ])

  // Render function
  return (<>
    {newPhoneNumberData && edit ? (
      <div className="CallCenter_PhoneNumbersPage_New entity-edit">

        {/* Top navigation */}
        <div className="top-nav">
          <BaseLink
            router={$router.router}
            routeName="call_center.phoneNumbers"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.phoneNumbers" })}
          >
            <Icon icon="phone-1" />
            <span>Phone Numbers</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.dispatchers"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.dispatchers" })}
          >
            <Icon icon="user-1" />
            <span>Dispatchers</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.additionalTime"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.additionalTime" })}
          >
            <Icon viewBox="0 0 1024 1024" icon="time-22" />
            <span>Additional Time</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.absence"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.absence" })}
          >
            <Icon icon="time-16" />
            <span>Absence</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.groups"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.groups" })}
          >
            <Icon icon="user-29" />
            <span>Groups</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.callFlows"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.callFlows" })}
          >
            <Icon icon="share-7" />
            <span>Call Flows</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.caller_groups"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.caller_groups" })}
          >
            <Icon icon="user-29" />
            <span>Caller Groups</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.temporaryClosed"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.temporaryClosed" })}
          >
            <Icon viewBox="0 0 1024 1024" icon="close-664" />
            <span>Temporary Closed</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.callCenters"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.callCenters" })}>
            <Icon icon="delivery-8" />
            <span>Call Centers</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.audioLibrary"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.audioLibrary" })}
          >
            <Icon viewBox="0 0 1024 1024" icon="audio-15" />
            <span>Audio Library</span>
          </BaseLink>
        </div>

        <div className="wrapper flex-container sb">
          <h1>New Phone Number</h1>

          <button
            style={{ background: '#d0d3da', color: 'white' }}
            className="_wa"
            onClick={() => $router.router.navigate('call_center.phoneNumbers', {
              companyId: activeAccountId,
              localInterface: $router.router.getState().params.localInterface
            }, { reload: true })}
          >
            <Icon
              style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
              viewBox="0 0 24 24"
              icon="arrow-25"
            />
          </button>
        </div>

        <div className="fieldset">

          <div className="legend">Main</div>

          <div className="fields">

            <div className="__left">
              <div className="field" style={{ zIndex: 6 }}>
                <span>Phone Number:</span>

                <ReactInputMask
                  className={classNames({
                    _error: errorFields.includes('phonenumber')
                  })}
                  type="text"
                  mask="+1 (999) 999-9999"
                  value={newPhoneNumberData.phone}
                  onChange={({ target: { value } }) => {
                    setNewPhoneNumberData({ ...newPhoneNumberData, phone: value })
                    errorFields.includes('phonenumber') && setErrorFields(errorFields.filter(item => item !== 'phonenumber'))
                  }}
                />
              </div>

              <div className="field" style={{ zIndex: 6 }}>
                <span>Friendly Name:</span>

                <input
                  className={classNames({
                    _error: errorFields.includes('friendly_name')
                  })}
                  type="text"
                  defaultValue={newPhoneNumberData.friendly_name}
                  onChange={({ target: { value } }) => {
                    setNewPhoneNumberData({ ...newPhoneNumberData, friendly_name: value })
                    errorFields.includes('friendly_name') && setErrorFields(errorFields.filter(item => item !== 'friendly_name'))
                  }}
                />
              </div>

              <div className="field" style={{ zIndex: 6 }}>
                <span>Call Center:</span>

                <SelectComponent
                  options={edit.call_centers.map((item) => ({
                    span: item.name,
                    value: item.call_center_id,
                  }))}
                  selectedOption={newPhoneNumberData.call_center_id as string}
                  onChange={(value: string) => setNewPhoneNumberData({ ...newPhoneNumberData, call_center_id: value })}
                />
              </div>
            </div>


            <div className="__right">

              <div className="field" style={{ zIndex: 6 }}>
                <span>Active:</span>

                <Switcher
                  checked={newPhoneNumberData.active === 'On'}
                  onChange={(value) => setNewPhoneNumberData({ ...newPhoneNumberData, active: value ? 'On' : 'Off' })}
                />
              </div>

              <div className="field" style={{ zIndex: 6 }}>
                <span>Conversions:</span>

                <Switcher
                  checked={newPhoneNumberData.conversions === 'On'}
                  onChange={(value) => setNewPhoneNumberData({ ...newPhoneNumberData, conversions: value ? 'On' : 'Off' })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">

          <div className="legend">Settings</div>

          <div className="fields">

            <div className="__left">

              <div className="field" style={{ zIndex: 6 }}>
                <span>Types:</span>

                <div className="input-wrapper">
                  <Select
                    error={(errorFields as string[]).includes('types')}
                    options={[''].concat(edit.types).map((type) => ({
                      span: type,
                      value: type
                    }))}
                    selectedOption={newPhoneNumberData.type as string}
                    onChange={(value: string) => {
                      setNewPhoneNumberData({ ...newPhoneNumberData, type: value });
                      (errorFields as string[]).includes('types') && setErrorFields(errorFields.filter(item => item !== 'types'))
                    }} />
                </div>
              </div>

              <div className="field">
                <span>Sources:</span>

                <div className="input-wrapper">
                  <Select
                    error={(errorFields as string[]).includes('source_id')}
                    options={[{ source: '', list_sources_id: '' }].concat(edit.sources).map((source) => ({
                      span: source.source,
                      value: source.list_sources_id
                    }))}
                    selectedOption={newPhoneNumberData.source as string}
                    onChange={(value: string) => {
                      setNewPhoneNumberData({ ...newPhoneNumberData, source: value });
                      (errorFields as string[]).includes('source_id') && setErrorFields(errorFields.filter(item => item !== 'source_id'))
                    }}
                  />
                </div>
              </div>

              <div className="field">
                <span>Messaging Service Sid:</span>

                <input
                  type="text"
                  defaultValue={newPhoneNumberData.messaging_service_sid}
                  onChange={({ target: { value } }) => setNewPhoneNumberData({ ...newPhoneNumberData, messaging_service_sid: value })}
                />
              </div>
            </div>

            <div className="__right">

              <div className="field" style={{ zIndex: 6 }}>
                <span>Company:</span>

                <div className="input-wrapper">
                  <Select
                    error={(errorFields as string[]).includes('company_id')}
                    options={[{ name: '', company_id: '' }].concat(edit.companies).map((company) => ({
                      span: company.name,
                      value: company.company_id
                    }))}
                    selectedOption={newPhoneNumberData.company as string}
                    onChange={(value: string) => {
                      setNewPhoneNumberData({ ...newPhoneNumberData, company: value });
                      (errorFields as string[]).includes('company_id') && setErrorFields(errorFields.filter(item => item !== 'company_id'))
                    }}
                  />
                </div>
              </div>

              <div className="field" style={{ zIndex: 5 }}>
                <span>Area:</span>

                <div className="input-wrapper">
                  <Select
                    error={(errorFields as string[]).includes('area_id')}
                    options={[{ area: '', area_id: '' }].concat(edit.areas).map((area) => ({
                      span: area.area,
                      value: area.area_id
                    }))}
                    selectedOption={newPhoneNumberData.area as string}
                    onChange={(value: string) => {
                      setNewPhoneNumberData({ ...newPhoneNumberData, area: value });
                      (errorFields as string[]).includes('area_id') && setErrorFields(errorFields.filter(item => item !== 'area_id'))
                    }}
                  />
                </div>
              </div>

              <div className="field">
                <span>Call Flow:</span>

                <div className="input-wrapper">
                  <Select
                    error={(errorFields as string[]).includes('callflow_id')}
                    options={[{ name: '', call_flow_id: '' }].concat(edit.call_flows).map((call_flow) => ({
                      span: call_flow.name,
                      value: call_flow.call_flow_id
                    }))}
                    selectedOption={newPhoneNumberData.call_flow as string}
                    onChange={(value: string) => {
                      setNewPhoneNumberData({ ...newPhoneNumberData, call_flow: value });
                      (errorFields as string[]).includes('callflow_id') && setErrorFields(errorFields.filter(item => item !== 'callflow_id'))
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="wrapper flex-container sb editing-buttons">
          <div />
          <div className="buttons">
            <button
              className="_bordered _red"
              onClick={() => $router.router.navigate('call_center.phoneNumbers', {
                companyId: activeAccountId,
              }, {
                reload: true
              })}
            >
              Cancel
            </button>
            <button
              className="_bordered _green"
              disabled={!readyToSave || !!errorFields.length || isSaving}
              onClick={() => handleSave()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    ) : null}
  </>)
}

export default CallCenter_PhoneNumbersPage_New
