import { ReactElement, useState } from "react";
import { DateTime } from "luxon"
import classNames from "classnames"

import DateRangeCalendar from "../DateRangeCalendar"
import Icon from "../Icon"
import Select from "../Select"

import { DateRangePreset } from "../../models/Misc"

import "../../styles/components/reports/filters.sass"

interface Props {

  onSearchInputChange?(value: string): void,
  searchInput?: string
  searchButtonActive?: boolean

  dateRangeType?: string,
  onDateRangeTypeChange?(value: string): void,

  dataRangeOptions?: string[] | []

  dateRangePreset?: DateRangePreset,
  onDateRangePresetChange?(value: DateRangePreset): void,

  minDate?: Date,
  onMinDateChange?(value: Date): void,

  maxDate?: Date,
  onMaxDateChange?(value: Date): void,

  updateButtonActive?: boolean,
  onUpdate(): void,

  childrenAfterSearch?: ReactElement,
  childrenBeforeDateType?: ReactElement,

  phoneCall: boolean,
  navActive: boolean,

  selectSearch?: boolean
  selectedSearch?: string
  setSelectedSearch?: (value: string) => void
  selectSearchOptions?: string[]

  selectOptions?: { span: string, value: string }[]
  is_mail?: boolean
}

function ReportFilters({
  onSearchInputChange,
  searchInput,
  dateRangeType,
  dataRangeOptions,
  onDateRangeTypeChange,
  dateRangePreset,
  onDateRangePresetChange,
  minDate,
  onMinDateChange,
  maxDate,
  onMaxDateChange,
  updateButtonActive,
  onUpdate,
  childrenAfterSearch,
  childrenBeforeDateType,
  phoneCall,
  navActive,
  searchButtonActive,
  selectSearch,
  selectedSearch,
  setSelectedSearch,
  selectSearchOptions,
  selectOptions,
  is_mail,
}: Props) {
  const [dateRangeCalendarShown, setDateRangeCalendarShown] = useState(false)

  // Render function
  return (
    <div className="ReportFilters">

      <div className="flex-container">

        <div
          className={classNames("__hide-on-mobile", "__left", "flex-container",
            {
              __respectAsidePanel: navActive && !phoneCall,
              __phoneCall: phoneCall && !navActive,
              __bothOpen: navActive && phoneCall,
              __nonePanel: !navActive && !phoneCall
            }
          )}
        >

          { /* Search field */}
          {
            selectSearch && selectSearchOptions &&
            <div className="search-select">
              <Select
                options={selectSearchOptions.map((report) => ({
                  span: report,
                  value: report
                }))}
                selectedOption={selectedSearch as string}
                onChange={(value) => setSelectedSearch && setSelectedSearch(value as string)}
              />
            </div>
          }
          {
            !selectSearch &&
            <div className="form-field __search-form">
              <input
                type="text"
                className="_rounded"
                value={searchInput}
                onChange={({ target: { value } }) => onSearchInputChange && onSearchInputChange(value)}
              />
              <button
                className={classNames(['_wa', '_rounded', '_iconed', '_white', { _blue: searchButtonActive }])}
                onClick={() => onUpdate()}
              >
                <Icon icon="magnifier-5" />
              </button>
            </div>
          }

          {
            selectOptions &&
            <div className="search-select">
              <Select
                options={selectOptions.map((report) => ({
                  span: report.span,
                  value: report.value
                }))}
                selectedOption={selectedSearch as string}
                onChange={(value) => setSelectedSearch && setSelectedSearch(value as string)}
              />
            </div>
          }

          {childrenAfterSearch}
        </div>

        <div
          className={classNames("__hide-on-mobile", "__right", "flex-container",
            {
              __respectAsidePanel: navActive && !phoneCall,
              __phoneCall: phoneCall && !navActive,
              __bothOpen: navActive && phoneCall,
              __nonePanel: !navActive && !phoneCall
            }
          )}
        >

          {childrenBeforeDateType}

          { /* Date range type selection */}
          {(
            true
            && dateRangeType
            && onDateRangeTypeChange
          ) ? (
            <div>
              <Select
                zeroed={true}
                disabled={dataRangeOptions && dataRangeOptions.length === 1}
                options={dataRangeOptions ?
                  dataRangeOptions.map(item => {
                    return {
                      span: item,
                      value: item
                    }
                  }) : [{
                    span: "Schedule date",
                    value: "schedule"
                  }, {
                    span: "Created date",
                    value: "created"
                  }]}
                selectedOption={dateRangeType} onChange={(value: string) => {
                  (onDateRangeTypeChange as any)(value)
                }} />
            </div>
          ) : null}

          { /* Date range type selection */}
          {(true
            && dateRangePreset
            && onDateRangePresetChange
          ) ? (
            <div>
              <Select zeroed={true} options={[{
                span: "Custom",
                value: "custom"
              }, {
                span: "Tomorrow & Next",
                value: "tomorrow_and_next"
              }, {
                span: "Tomorrow",
                value: "tomorrow"
              }, {
                span: "Today",
                value: "today"
              }, {
                span: "Yesterday",
                value: "yesterday"
              }, {
                span: "This week (Sun - today)",
                value: "this_week_sun_today"
              }, {
                span: "This week (Mon - today)",
                value: "this_week_mon_today"
              }, {
                span: "Last 7 days",
                value: "last_7_days"
              }, {
                span: "Last week (Sun - Sat)",
                value: "last_week_sun_sat"
              }, {
                span: "Last week (Mon - Sun)",
                value: "last_week_mon_sun"
              }, {
                span: "Last business week",
                value: "last_business_week"
              }, {
                span: "Last 14 days",
                value: "last_14_days"
              }, {
                span: "Last 30 days",
                value: "last_30_days"
              }, {
                span: "This month",
                value: "this_month"
              }, {
                span: "Last month",
                value: "last_month"
              }, {
                span: "All",
                value: "all"
              }]} selectedOption={dateRangePreset} onChange={(value: string) => {
                (onDateRangePresetChange as any)(value as DateRangePreset)
              }} />
            </div>
          ) : null}

          { /* Date range */}
          {(true
            && minDate
            && maxDate
            && onMinDateChange
            && onMaxDateChange
          ) ? (
            <div onFocus={() => setDateRangeCalendarShown(true)} onBlur={() => setDateRangeCalendarShown(false)} tabIndex={-1} className="form-field date-range">
              <div className="form-field _iconed">
                <input
                  type="text"
                  readOnly
                  value={DateTime.fromJSDate(minDate).toLocaleString(DateTime.DATE_SHORT)}
                />
                <button className="_zeroed _iconed __noclick">
                  <Icon icon="calendar-4" />
                </button>
              </div>
              <div className="input-between-text">to</div>
              <div className="form-field _iconed">
                <input
                  type="text"
                  readOnly
                  value={DateTime.fromJSDate(maxDate).toLocaleString(DateTime.DATE_SHORT)}
                />
                <button className="_zeroed _iconed __noclick">
                  <Icon icon="calendar-4" />
                </button>
              </div>

              <div className={classNames("date-range-wrapper", { _shown: dateRangeCalendarShown })}>
                <DateRangeCalendar
                  startDate={minDate}
                  endDate={maxDate}

                  onStartDateUpdate={(date) => { (onMinDateChange as any)(date); }}
                  onEndDateUpdate={(date) => { (onMaxDateChange as any)(date); }}
                />
              </div>
            </div>
          ) : null}

          { /* Refresh button */}
          {(true
            && minDate
            && maxDate
          ) ? (
            <button
              className={classNames(['_wa', '_rounded', '_iconed', 'update-button', '__hide-on-mobile', { _blue: updateButtonActive }])}
              onClick={() => onUpdate()}
            >
              <Icon className="_mirrored-y" icon="refresh-2" />
            </button>
          ) : null}
        </div>

        <div
          className={classNames("__show-on-mobile", "__right", "flex-container",
            {
              __respectAsidePanel: navActive && !phoneCall,
              __phoneCall: phoneCall && !navActive,
              __bothOpen: navActive && phoneCall,
              __nonePanel: !navActive && !phoneCall
            }
          )}
          style={{ marginLeft: '0' }}
        >
          {childrenBeforeDateType}

          { /* Date range type selection */}
          {(
            true
            && dateRangeType
            && onDateRangeTypeChange
          ) ? (
            <div>
              <Select
                zeroed={true}
                disabled={dataRangeOptions && dataRangeOptions.length === 1}
                options={dataRangeOptions ?
                  dataRangeOptions.map(item => {
                    return {
                      span: item,
                      value: item
                    }
                  }) : [{
                    span: "Schedule date",
                    value: "schedule"
                  }, {
                    span: "Created date",
                    value: "created"
                  }]}
                selectedOption={dateRangeType}
                onChange={(value: string) => {
                  (onDateRangeTypeChange as any)(value)
                }} />
            </div>
          ) : null}

          { /* Date range type selection */}
          {(true
            && dateRangePreset
            && onDateRangePresetChange
          ) ? (
            <div>
              <Select is_date_range_mobile={true} zeroed={true} options={[{
                span: "Custom",
                value: "custom"
              }, {
                span: "Tomorrow & Next",
                value: "tomorrow_and_next"
              }, {
                span: "Tomorrow",
                value: "tomorrow"
              }, {
                span: "Today",
                value: "today"
              }, {
                span: "Yesterday",
                value: "yesterday"
              }, {
                span: "This week (Sun - today)",
                value: "this_week_sun_today"
              }, {
                span: "This week (Mon - today)",
                value: "this_week_mon_today"
              }, {
                span: "Last 7 days",
                value: "last_7_days"
              }, {
                span: "Last week (Sun - Sat)",
                value: "last_week_sun_sat"
              }, {
                span: "Last week (Mon - Sun)",
                value: "last_week_mon_sun"
              }, {
                span: "Last business week",
                value: "last_business_week"
              }, {
                span: "Last 14 days",
                value: "last_14_days"
              }, {
                span: "Last 30 days",
                value: "last_30_days"
              }, {
                span: "This month",
                value: "this_month"
              }, {
                span: "Last month",
                value: "last_month"
              }, {
                span: "All",
                value: "all"
              }]} selectedOption={dateRangePreset} onChange={(value: string) => {
                (onDateRangePresetChange as any)(value as DateRangePreset)
              }} />
            </div>
          ) : null}

          { /* Date range */}
          {(true
            && minDate
            && maxDate
            && onMinDateChange
            && onMaxDateChange
          ) ? (
            <div onFocus={() => setDateRangeCalendarShown(true)} onBlur={() => setDateRangeCalendarShown(false)} tabIndex={-1} className="form-field date-range">
              <div className="form-field _iconed">
                <input
                  type="text"
                  readOnly
                  value={DateTime.fromJSDate(minDate).toLocaleString(DateTime.DATE_SHORT)}
                />
                <button className="_zeroed _iconed __noclick">
                  <Icon icon="calendar-4" />
                </button>
              </div>
              <div className="input-between-text">to</div>
              <div className="form-field _iconed">
                <input
                  type="text"
                  readOnly
                  value={DateTime.fromJSDate(maxDate).toLocaleString(DateTime.DATE_SHORT)}
                />
                <button className="_zeroed _iconed __noclick">
                  <Icon icon="calendar-4" />
                </button>
              </div>

              <div className={classNames("date-range-wrapper", { _shown: dateRangeCalendarShown })}>
                <DateRangeCalendar
                  startDate={minDate}
                  endDate={maxDate}

                  onStartDateUpdate={(date) => { (onMinDateChange as any)(date); }}
                  onEndDateUpdate={(date) => { (onMaxDateChange as any)(date); }}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <div
        style={{
          flexWrap: !!is_mail ? 'wrap' : 'nowrap',
          justifyContent: !!is_mail ? 'space-between' : 'flex-start'
        }}
        className={classNames("__show-on-mobile", "flex-container",
          {
            __respectAsidePanel: navActive && !phoneCall,
            __phoneCall: phoneCall && !navActive,
            __bothOpen: navActive && phoneCall,
            __nonePanel: !navActive && !phoneCall
          }
        )}>

        { /* Search field */}
        {
          selectSearch && selectSearchOptions &&
          <div className="search-select">
            <Select
              options={selectSearchOptions.map((report) => ({
                span: report,
                value: report
              }))}
              selectedOption={selectedSearch as string}
              onChange={(value) => setSelectedSearch && setSelectedSearch(value as string)}
            />
          </div>
        }

        {
          !selectSearch &&
          <div className="form-field __search-form">
            <input
              type="text"
              className="_rounded"
              value={searchInput}
              onChange={({ target: { value } }) => onSearchInputChange && onSearchInputChange(value)}
            />
            <button
              className={classNames(['_wa', '_rounded', '_iconed', '_white', { _blue: searchButtonActive }])}
              onClick={() => onUpdate()}
            >
              <Icon icon="magnifier-5" />
            </button>
          </div>
        }

        {
          selectOptions &&
          <div className="search-select">
            <Select
              options={selectOptions.map((report) => ({
                span: report.span,
                value: report.value
              }))}
              selectedOption={selectedSearch as string}
              onChange={(value) => setSelectedSearch && setSelectedSearch(value as string)}
            />
          </div>
        }

        {childrenAfterSearch}

        { /* Refresh button */}
        {(true
          && minDate
          && maxDate
        ) ? (
          <button
            className={classNames(['_wa', '_rounded', '_iconed', 'update-button', { _blue: updateButtonActive }])}
            onClick={() => onUpdate()}
          >
            <Icon className="_mirrored-y" icon="refresh-2" />
          </button>
        ) : null}
      </div>
    </div>
  )
}

export default ReportFilters
