import React, { useRef, useEffect, useState } from "react";
import moment from "moment";
import { DateTime } from "luxon";

import Icon from "../../components/Icon"
import WorkerInfo from "./WorkerInfo";

import CofeeSvg from '../../images/coffee-cup.svg'

import { DispatchersProps, TimesProps } from "./List";
import { dateToInfoBlock } from "../../funcs";

export interface ChangeAbsenceProps {
  absence_end: string
  absence_start: string
  absences_id: string
  service_resource_nickname: string
  service_resource_code: number | null
}

export interface ChangeAppointProps {
  appointment_date_end: string
  appointment_date_start: string
  appointment_id: string
  appointment_job_number: string
  service_resource_nickname: string
  service_resource_code: number | null
}

export interface RescheduleAppoint {
  appointment_date_end: string
  appointment_date_start: string
  appointment_id: string
  appointment_job_number: string
  service_resource_nickname: string
  service_resource_code: number | null
  service_resource_id: string
  service_resource_select: {
    service_resource_nickname: string
    service_resource_code: number | null
    service_resource_id: string
  }[]
}

export interface sendNotificationAppoint {
  appointment_id: string
}

interface GantChartProps {
  dateRangeType: string,
  minDate: string,
  arrTimesGrid: TimesProps[],
  arrTimesDayGrid: string[],
  navActive: boolean
  scheduleData: DispatchersProps[]
  setScheduleData: (value: DispatchersProps[] | []) => void,
  activeAccountId: string | null,
  timeZone: string
}

export default function GantChart({
  dateRangeType,
  minDate,
  arrTimesGrid,
  arrTimesDayGrid,
  navActive,
  scheduleData,
  setScheduleData,
  activeAccountId,
  timeZone
}: GantChartProps) {

  let tableRef = useRef<HTMLDivElement>(null)
  let cellRef = useRef<HTMLDivElement>()
  let asideRef = useRef<HTMLDivElement>(null)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updatedTime, setUpdatedTime] = useState(0)

  const [leftParam, setLeftParam] = useState(0)
  const [leftHoverParam, setLeftHoverParam] = useState(0)

  const [showDate, setShowDate] = useState<string[]>([])
  const [showAreas, setShowAreas] = useState({})
  const [showDropList, setShowDropList] = useState({})
  const [cellWidth, setCellWidth] = useState(0)

  const [hoverAbsence, setHoverAbsence] = useState('')

  useEffect(() => {
    let objShow = {}
    scheduleData.forEach((item, index) => {
      objShow[index] = true
    })
    setShowAreas(objShow)
  }, [scheduleData])

  useEffect(() => {
    let timeInterval = setInterval(() => {
      setUpdatedTime(Math.random())
    }, 10000)

    return () => clearInterval(timeInterval)
  }, [])

  const resizeCell = () => {
    let { width } = document.querySelector('.timegrid__cell')?.getBoundingClientRect() || {};
    width && setCellWidth(width)
  };

  const resizeNav = () => {
    let { width } = tableRef?.current?.querySelector('.timegrid__cell')?.getBoundingClientRect() || {};

    width && setCellWidth(width)
  };

  useEffect(() => {
    resizeCell()
  }, [arrTimesGrid, arrTimesDayGrid, scheduleData])

  useEffect(() => {
    resizeNav()
  }, [navActive])

  useEffect(() => {
    window.addEventListener("resize", resizeCell);
    resizeCell();
    return () => {
      window.removeEventListener("resize", resizeCell);
    };
  }, []);

  useEffect(() => {
    if (dateRangeType === 'one') {
      setShowDate([minDate])
    } else if (dateRangeType === 'two') {
      let nextDay = moment(minDate, "ddd, MMM DD YYYY").add(1, 'day').format("ddd, MMM DD YYYY")
      setShowDate([minDate, nextDay])
    } else {
      let nextDay = moment(minDate, "ddd, MMM DD YYYY").add(1, 'day').format("ddd, MMM DD YYYY")
      let lastDay = moment(minDate, "ddd, MMM DD YYYY").add(2, 'days').format("ddd, MMM DD YYYY")

      setShowDate([minDate, nextDay, lastDay])
    }
  }, [dateRangeType, minDate])

  function getClassForGrid(id: string, time_slot: string, idx: number) {

    let class_name = 'timegrid__cell'
    let indexDate = idx < arrTimesDayGrid.length - 1 ? 0 : idx < (arrTimesDayGrid.length - 1) * 2 ? 1 : 2
    scheduleData.forEach(dispatcher => {
      if (dispatcher.dispatcher_id === id) {
        dispatcher.call_center_schedule.forEach(item => {
          let start = DateTime.fromISO(item.time_slot_start, { setZone: true }).toFormat('HH:mm')
          let startDay = DateTime.fromISO(item.time_slot_start, { setZone: true }).toFormat('ccc, LLL dd yyyy')
          let end = DateTime.fromISO(item.time_slot_end, { setZone: true }).toFormat('HH:mm')
          let endDay = DateTime.fromISO(item.time_slot_end, { setZone: true }).toFormat('ccc, LLL dd yyyy')
          if (end === '00:00') {
            end = '24:00'
          }
          if (
            (showDate[indexDate] === startDay || showDate[indexDate] === endDay) &&
            start <= time_slot &&
            time_slot < end
          ) {
            class_name += ` timegrid__cell_regular`
          }
        })
        dispatcher.additional_times.forEach(item => {
          let start = DateTime.fromISO(item.additional_time_start, { setZone: true }).setZone(timeZone).toFormat('HH:mm')
          let startDay = DateTime.fromISO(item.additional_time_start, { setZone: true }).setZone(timeZone).toFormat('ccc, LLL dd yyyy')
          let end = DateTime.fromISO(item.additional_time_end, { setZone: true }).setZone(timeZone).toFormat('HH:mm')
          let endDay = DateTime.fromISO(item.additional_time_end, { setZone: true }).setZone(timeZone).toFormat('ccc, LLL dd yyyy')

          if (end === '00:00') {
            end = '24:00'
          }

          if (
            (showDate[indexDate] === startDay || showDate[indexDate] === endDay) &&
            start <= time_slot &&
            time_slot < end
          ) {
            class_name += ` timegrid__cell_additional_time`
          }
        })
      }
    })
    return class_name
  }

  function getRealTimeLine(time: string, day: string) {
    let indexOfDate = showDate.indexOf(day)
    let timeline = 0
    let asideWidth = document.getElementsByClassName('board__aside') ? document.getElementsByClassName('board__aside')[0]?.getBoundingClientRect()?.width : 0
    let minutWidth = cellWidth / 30
    if (time.split(':')[1] < '30') {
      let indexStart = arrTimesDayGrid.indexOf(`${time.split(':')[0]}:00`)
      if (indexStart !== -1 && indexStart !== arrTimesDayGrid.length - 1) {
        timeline = cellWidth * indexStart + (+time.split(':')[1] * minutWidth) + ((arrTimesDayGrid.length - 1) * indexOfDate * cellWidth)
      } else {
        timeline = - 1
      }
    } else {
      let indexStart = arrTimesDayGrid.indexOf(`${time.split(':')[0]}:30`)
      if (indexStart !== -1) {
        timeline = cellWidth * indexStart + ((+time.split(':')[1] - 30) * minutWidth) + ((arrTimesDayGrid.length - 1) * indexOfDate * cellWidth)
      } else {
        timeline = - 1
      }
    }

    return timeline < 0 ? null : `${asideWidth + timeline}px`
  }

  function getPositionAbsence(start: string, end: string, dayStart: string, dayEnd: string, days: string[], timeZone: string) {

    let dayIndexStart = showDate.indexOf(days[0])
    let dayIndexEnd = showDate.indexOf(days[days.length - 1])
    let startDay = ''
    let startLine = 0
    let width = 0
    let minutWidth = cellWidth / 30
    let widthOfOneDay = moment(arrTimesDayGrid[arrTimesDayGrid.length - 1], 'HH:mm').diff(moment(arrTimesDayGrid[0], 'HH:mm'), 'minutes') * minutWidth


    let isSameDaysStart = moment(showDate[dayIndexStart]).diff(moment(dayStart).startOf('day')) > 0
    if (isSameDaysStart) {
      startLine = ((arrTimesDayGrid.length - 1) * dayIndexStart * cellWidth)
      startDay = arrTimesDayGrid[0]
    } else {
      if (start.split(':')[1] < '30') {

        let indexStart = arrTimesDayGrid.indexOf(`${start.split(':')[0]}:00`)
        if (indexStart !== -1) {
          startLine = cellWidth * indexStart + (+start.split(':')[1] * minutWidth) + ((arrTimesDayGrid.length - 1) * dayIndexStart * cellWidth)
        } else {
          if (dayIndexStart === 0) {
            startLine = - 1
          } else {
            startLine = cellWidth * indexStart + (+start.split(':')[1] * minutWidth) + ((arrTimesDayGrid.length) * dayIndexStart * cellWidth)
          }
        }
      } else {
        let indexStart = arrTimesDayGrid.indexOf(`${start.split(':')[0]}:30`)
        if (indexStart !== -1) {
          startLine = dayIndexStart * cellWidth * indexStart + ((+start.split(':')[1] - 30) * minutWidth)
        } else {
          startLine = - 1
        }
      }

      if (arrTimesDayGrid.indexOf(`${start.split(':')[0]}:00`) === -1) {
        startDay = arrTimesDayGrid[0]
      } else {
        startDay = start
      }
    }

    let isSameDaysEnd = moment(showDate[dayIndexEnd]).diff(moment(dayEnd).startOf('day')) >= 0

    if (isSameDaysEnd) {
      if (arrTimesDayGrid.indexOf(`${end.split(':')[0]}:00`) !== -1) {
        if (arrTimesDayGrid.indexOf(`${end.split(':')[0]}:00`) === arrTimesDayGrid.length - 1) {
          let duration = moment(`${end.split(':')[0]}:00`, 'HH:mm').diff(moment(startDay, 'HH:mm'), 'minutes')

          width = (duration * minutWidth) + (widthOfOneDay * (days.length - 1))
        } else {
          let duration = moment(end, 'HH:mm').diff(moment(startDay, 'HH:mm'), 'minutes')

          width = (duration * minutWidth) + (widthOfOneDay * (days.length - 1))
        }
      } else {
        if (+end.split(':')[0] < +arrTimesDayGrid[0].split(':')[0]) {
          width = 0 + (widthOfOneDay * (days.length - 1))
        } else {
          let duration = moment(arrTimesDayGrid[arrTimesDayGrid.length - 1], 'HH:mm').diff(moment(startDay, 'HH:mm'), 'minutes')

          width = (duration * minutWidth) + (widthOfOneDay * (days.length - 1))
        }
      }
    } else {
      if (isSameDaysStart) {
        let duration = moment(arrTimesDayGrid[arrTimesDayGrid.length - 1], 'HH:mm').diff(moment(arrTimesDayGrid[0], 'HH:mm'), 'minutes')

        width = (duration * minutWidth + (widthOfOneDay * (days.length - 1)))
      } else {
        let duration = moment(arrTimesDayGrid[arrTimesDayGrid.length - 1], 'HH:mm').diff(moment(startDay, 'HH:mm'), 'minutes')

        width = (duration * minutWidth) + (widthOfOneDay * (days.length - 1))
      }
    }
    let left = 0
    let right = 0

    if (showDate.length === 1) {
      left = startLine < 0 ? 0 : -startLine
      width = width < 0 || !width ? 0 : width
      right = (arrTimesDayGrid.length - 1) * cellWidth
    } else if (showDate.length === 2) {
      left = startLine < 0 ? 0 : -startLine - (dayIndexStart * widthOfOneDay)
      width = width < 0 || !width ? 0 : width
      right = (arrTimesDayGrid.length - 1) * cellWidth + ((1 - dayIndexEnd) * widthOfOneDay)
    } else {
      left = startLine < 0 ? 0 : -startLine - (dayIndexStart * widthOfOneDay)
      width = width < 0 || !width ? 0 : width
      right = (arrTimesDayGrid.length - 1) * cellWidth + ((2 - dayIndexEnd) * widthOfOneDay)
    }

    if (dayIndexStart === dayIndexEnd) {
      right = right + left - width
    } else {
      right = right + left
    }

    return {
      start: startLine < 0 ? null : `${startLine}px`,
      width,
      left,
      right: right
    }
  }

  function getHeigthRow(id: string) {
    let points: { start: string, end: string, id: string }[] = []
    let number = 0
    showDate.forEach(day => {
      points = []
      scheduleData.forEach((dispatcher, index) => {
        if (dispatcher.dispatcher_id === id) {
          dispatcher.dispatcher_absence.forEach(absence => {
            let startFormatDay = dateToInfoBlock('ccc, LLL dd yyyy', timeZone, absence.absence_start)
            let endFormatDay = dateToInfoBlock('ccc, LLL dd yyyy', timeZone, absence.absence_end)

            if (
              moment(day).isBetween(moment(startFormatDay), moment(endFormatDay))
              || moment(day).isSame(moment(startFormatDay), 'day')
              || moment(day).isSame(moment(endFormatDay), 'day')
            ) {
              let isSameDaysStart = moment(day).diff(moment(startFormatDay).startOf('day')) >= 0
              let isSameDaysEnd = moment(day).diff(moment(endFormatDay).startOf('day')) <= 0
              if (
                moment(day).isSame(moment(startFormatDay), 'day')
                && moment(day).isSame(moment(endFormatDay), 'day')
              ) {
                points.push({
                  start: dateToInfoBlock('HH:mm', timeZone, absence.absence_start),
                  end: dateToInfoBlock('HH:mm', timeZone, absence.absence_end),
                  id: absence.dispatcher_absence_id
                })
              } else if (isSameDaysStart && moment(day).isSame(moment(endFormatDay), 'day')) {
                points.push({
                  start: arrTimesDayGrid[0],
                  end: dateToInfoBlock('HH:mm', timeZone, absence.absence_end),
                  id: absence.dispatcher_absence_id
                })
              } else if (isSameDaysEnd && moment(day).isSame(moment(startFormatDay), 'day')) {
                points.push({
                  start: dateToInfoBlock('HH:mm', timeZone, absence.absence_start),
                  end: arrTimesDayGrid[arrTimesDayGrid.length - 1],
                  id: absence.dispatcher_absence_id
                })
              } else {
                points.push({
                  start: arrTimesDayGrid[0],
                  end: arrTimesDayGrid[arrTimesDayGrid.length - 1],
                  id: absence.dispatcher_absence_id
                })
              }
            }
          })
        }
      })
      if (points.length) {
        let getHeiht = getPositionAppUpdate(points, false)

        number = getHeiht > number ? getHeiht : number
      }
    })

    return number
  }

  function getPositionAppUpdate(appointmentsOneDay: { start: string, end: string, id: string }[], is_height: boolean, appoint_id?: string) {
    let obj = {}
    let myObj = {}
    if (appointmentsOneDay.length > 1) {
      appointmentsOneDay.sort((a, b) => a.start.localeCompare(b.start))

      appointmentsOneDay.reduce((acc, item) => {
        if (acc === 0) {
          obj[0] = {
            startDate: item.start,
            endDate: item.end,
            floor: 0
          }
          myObj[0] = {
            startDate: item.start,
            endDate: item.end,
            floor: 0
          }
          acc = acc + 1
        } else {
          if (acc === 1) {
            if (item.start >= obj[0].endDate) {
              obj[acc] = {
                startDate: item.start,
                endDate: item.end,
                floor: obj[acc - 1].floor
              }
              myObj[acc] = {
                startDate: item.start,
                endDate: item.end,
                floor: obj[acc - 1].floor
              }
            } else {
              obj[acc] = {
                startDate: item.start,
                endDate: item.end,
                floor: obj[acc - 1].floor + 1
              }
              myObj[acc] = {
                startDate: item.start,
                endDate: item.end,
                floor: obj[acc - 1].floor + 1
              }
            }
          } else {
            const arrOneFloor: { startDate: string, endDate: string, floor: number }[] = []
            const checkArr: { startDate: string, endDate: string, floor: number }[] = Object.values(obj)
            const checkArrReverse = checkArr.reverse()

            checkArrReverse.forEach(check => {
              if (arrOneFloor.length === 0 || arrOneFloor.filter(it => it.floor === check.floor).length === 0) {
                arrOneFloor.push(check)
              }
            })

            const arr = arrOneFloor.sort((a, b) => a.floor < b.floor ? -1 : a.floor > b.floor ? 1 : 0)

            let addItem = false

            arr.forEach((objectItem, idx) => {
              if (!addItem && item.start >= objectItem.endDate) {
                addItem = true
                obj[acc] = {
                  startDate: item.start,
                  endDate: item.end,
                  floor: objectItem.floor
                }
                myObj[acc] = {
                  startDate: item.start,
                  endDate: item.end,
                  floor: objectItem.floor
                }
              } else if (!addItem && idx === arr.length - 1) {
                addItem = true
                obj[acc] = {
                  startDate: item.start,
                  endDate: item.end,
                  floor: objectItem.floor + 1
                }
                myObj[acc] = {
                  startDate: item.start,
                  endDate: item.end,
                  floor: objectItem.floor + 1
                }
              }
            })
          }
          acc = acc + 1
        }
        return acc
      }, 0)
    }
    let result = 0

    if (is_height) {
      let indexApp = appointmentsOneDay.findIndex(x => x.id === appoint_id)
      if (indexApp !== -1 && Object.keys(obj).length) {
        if (Object.keys(obj).length - 1 < indexApp) {
          result = 0
        } else {
          result = obj[indexApp].floor
        }
      }
    } else {
      Object.keys(obj).forEach(item => {
        if (obj[item].floor >= result) {
          result = obj[item].floor + 1
        }
      })
    }

    return result
  }

  function getpositionAppointment(id: string, day: string, appointId: string) {
    let points: { start: string, end: string, id: string }[] = []
    scheduleData.forEach((dispatcher, index) => {
      if (dispatcher.dispatcher_id === id) {

        dispatcher.dispatcher_absence.forEach(absence => {
          let startFormatDay = dateToInfoBlock('ccc, LLL dd yyyy', timeZone, absence.absence_start)
          let endFormatDay = dateToInfoBlock('ccc, LLL dd yyyy', timeZone, absence.absence_end)

          if (
            moment(day).isBetween(moment(startFormatDay), moment(endFormatDay))
            || moment(day).isSame(moment(startFormatDay), 'day')
            || moment(day).isSame(moment(endFormatDay), 'day')) {

            let isSameDaysStart = moment(day).diff(moment(startFormatDay).startOf('day')) >= 0
            let isSameDaysEnd = moment(day).diff(moment(endFormatDay).startOf('day')) <= 0
            if (
              moment(day).isSame(moment(startFormatDay), 'day')
              && moment(day).isSame(moment(endFormatDay), 'day')
            ) {
              points.push({
                start: dateToInfoBlock('HH:mm', timeZone, absence.absence_start),
                end: dateToInfoBlock('HH:mm', timeZone, absence.absence_end),
                id: absence.dispatcher_absence_id
              })
            } else if (isSameDaysStart && moment(day).isSame(moment(endFormatDay), 'day')) {
              points.push({
                start: arrTimesDayGrid[0],
                end: dateToInfoBlock('HH:mm', timeZone, absence.absence_end),
                id: absence.dispatcher_absence_id
              })
            } else if (isSameDaysEnd && moment(day).isSame(moment(startFormatDay), 'day')) {
              points.push({
                start: dateToInfoBlock('HH:mm', timeZone, absence.absence_start),
                end: arrTimesDayGrid[arrTimesDayGrid.length - 1],
                id: absence.dispatcher_absence_id
              })
            } else {
              points.push({
                start: arrTimesDayGrid[0],
                end: arrTimesDayGrid[arrTimesDayGrid.length - 1],
                id: absence.dispatcher_absence_id
              })
            }
          }
        })
      }
    })

    let number = points.length ? getPositionAppUpdate(points, true, appointId) : 0

    return number
  }

  function handleChange(event: any, id: string) {
    if (event.button === 2) {
      setLeftParam(event.nativeEvent.layerX)
      event.nativeEvent.stopImmediatePropagation();
      event.preventDefault()
      event.stopPropagation()
      setShowDropList({
        [id]: true
      })
    }
  }

  function getAsideWidth() {
    let width = 0
    if (asideRef && asideRef.current) {
      width = asideRef.current.offsetWidth
    }

    return width
  }

  return (
    <section
      onClick={() => { Object.keys(showDropList).length && setShowDropList({}) }}
      className="boards">
      <div className="boards__wrapper">
        <div className="boards__dates-wrapper">
          <div style={{ minWidth: '180px' }}></div>

          <div className="boards__dates">
            {
              showDate.map(day => (
                <div
                  key={day}
                  className="boards__dates-item"
                >
                  <p className="boards__dates-item-time">{day}</p>
                </div>
              ))
            }
          </div>
        </div>

        <div className="boards__timeline-wrapper">
          <div style={{ minWidth: '180px' }}></div>

          <div className="boards__timeline">
            {
              dateRangeType === "one" &&
              <div
                style={{ width: `calc(100% / ${(arrTimesGrid.length + 2)})` }}
                className="timeline__item"
              >
                <p className="timeline__item-time"></p>
              </div>
            }
            {dateRangeType === "one"
              ? arrTimesGrid.map((item, index) => (
                <div
                  key={index}
                  style={{ width: `calc(100% / ${(arrTimesGrid.length + 2) / 2})` }}
                  className="timeline__item"
                >
                  <p className="timeline__item-time">{item.span}</p>
                </div>
              ))
              : dateRangeType === "two" ?
                arrTimesGrid.concat(arrTimesGrid).map((item, index) => (
                  <React.Fragment key={index}>
                    {
                      (index === 0 || index === arrTimesGrid.length) &&
                      <div
                        style={{ width: `calc((100% / ((${arrTimesGrid.length} * 2) + 2)) / 2)` }}
                        className={index === arrTimesGrid.length ? "timeline__item start timeline__item_end" : "timeline__item start"}
                      >
                        <p className="timeline__item-time"></p>
                      </div>
                    }
                    <div
                      style={{ width: `calc(100% / ((${arrTimesGrid.length} * 2) + 2))` }}
                      className="timeline__item"
                    >
                      <p className="timeline__item-time">{item.span}</p>
                    </div>
                    {
                      (index === arrTimesGrid.length - 1 || index === arrTimesGrid.length * 2) &&
                      <div
                        style={{ width: `calc((100% / ((${arrTimesGrid.length} * 2) + 2)) / 2)` }}
                        className="timeline__item finish"
                      >
                        <p className="timeline__item-time"></p>
                      </div>
                    }
                  </React.Fragment>
                )) :
                arrTimesGrid.concat(arrTimesGrid).concat(arrTimesGrid).map((item, index) => (
                  <React.Fragment key={index}>
                    {
                      (index === 0 || index === arrTimesGrid.length || index === arrTimesGrid.length * 2) &&
                      <div
                        style={{ width: `calc((100% / ((${arrTimesGrid.length} * 3) + 3)) / 2)` }}
                        className={index === arrTimesGrid.length || index === arrTimesGrid.length * 2 ? "timeline__item start timeline__item_end" : "timeline__item start"}
                      >
                        <p className="timeline__item-time"></p>
                      </div>
                    }
                    <div
                      style={{ width: `calc(100% / ((${arrTimesGrid.length} * 2) + 2))` }}
                      className="timeline__item"
                    >
                      <p className="timeline__item-time">{item.span}</p>
                    </div>
                    {
                      (index === arrTimesGrid.length - 1 || index === (arrTimesGrid.length * 2) - 1 || index === (arrTimesGrid.length * 3) - 1) &&
                      <div
                        style={{ width: `calc((100% / ((${arrTimesGrid.length} * 3) + 3)) / 2)` }}
                        className="timeline__item finish"
                      >
                        <p className="timeline__item-time"></p>
                      </div>
                    }
                  </React.Fragment>
                ))
            }
            {
              dateRangeType === "one" &&
              <div
                style={{ width: `calc(100% / ${(arrTimesGrid.length + 2)})` }}
                className="timeline__item"
              >
                <p className="timeline__item-time"></p>
              </div>
            }
          </div>
        </div>

        <div
          ref={tableRef as React.RefObject<HTMLDivElement>}
          id="board__items"
          className="board__items"
        >
          {/* areas */}
          {
            scheduleData.map((dispatcher, index) => (
              <div key={dispatcher.dispatcher_id} className="boards__item">

                {
                  showAreas[index] &&

                  <div className="boards__board board">
                    <div className="board__table">
                      {
                        showDate.filter(day => moment(day).isSame(moment(moment().tz(timeZone).format('dddd, MMMM DD, YYYY HH:mm')), 'day')).length > 0 &&
                        <div
                          className="timegrid__realtime-line timegrid__realtime-line_active"
                          style={{
                            left: getRealTimeLine(moment().tz(timeZone).format('HH:mm'), moment().tz(timeZone).format('ddd, MMM DD YYYY'))
                              || 0,
                            display: getRealTimeLine(moment().tz(timeZone).format('HH:mm'), moment().tz(timeZone).format('ddd, MMM DD YYYY')) ? 'block' : 'none'
                          }}
                        ></div>
                      }

                      <div
                        style={{ height: getHeigthRow(dispatcher.dispatcher_id) === 0 || getHeigthRow(dispatcher.dispatcher_id) === 1 ? `60px` : `${(getHeigthRow(dispatcher.dispatcher_id) * 60)}px` }}
                        key={dispatcher.dispatcher_id}
                        className="board__row"
                        tabIndex={index + 1}
                      >
                        <div
                          className="board__aside"
                          ref={asideRef}
                        >
                          <div className="board__worker">
                            <div style={{ display: 'flex', alignItems: 'center' }}>

                              <div className="board__worker-img">
                                {
                                  dispatcher.dispatcher_image ?
                                    <img src={dispatcher.dispatcher_image} alt="Pic" /> :
                                    <Icon icon="user-20" />
                                }
                              </div>

                              <WorkerInfo
                                dispatcher_id={dispatcher.dispatcher_id}
                                available={dispatcher.available}
                                code={dispatcher.code}
                                dispatcher_nickname={dispatcher.dispatcher_nickname}
                                dispatcher_fullname={dispatcher.dispatcher_fullname}
                                dispatcher_function={dispatcher.dispatcher_function}
                                getAsideWidth={getAsideWidth}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="board__main">
                          <div className="board__timegrid timegrid">
                            <div
                              className="timegrid__row"
                              ref={el => {
                                let width = el?.querySelector('.timegrid__cell')?.getBoundingClientRect().width ? el?.querySelector('.timegrid__cell')?.getBoundingClientRect().width : 0
                                width && width !== cellWidth && setCellWidth(width)
                              }}
                            >
                              {
                                dateRangeType === 'one' ?
                                  arrTimesDayGrid.map((slot, index) => {
                                    return arrTimesDayGrid.length - 1 !== index &&
                                      <div
                                        ref={cellRef as React.RefObject<HTMLDivElement>}
                                        key={slot}
                                        style={{ width: `calc(100% / ${arrTimesDayGrid.length - 1})`, position: 'relative' }}
                                        className={`${getClassForGrid(dispatcher.dispatcher_id, slot, 0)} ${arrTimesGrid.map(item => item.value).includes(slot) ? 'timegrid_cell_border' : ''}`}
                                      >
                                      </div>
                                  }) :
                                  dateRangeType === 'two' ?
                                    arrTimesDayGrid.concat(arrTimesDayGrid).map((slot, idx) => {

                                      return (arrTimesDayGrid.length - 1 !== idx && (arrTimesDayGrid.length * 2) - 1 !== idx) &&
                                        <div
                                          key={idx}
                                          style={{ width: `calc(100% / (${arrTimesDayGrid.length - 1} * 2))`, position: 'relative' }}
                                          className={
                                            `${getClassForGrid(dispatcher.dispatcher_id, slot, idx)}
                                                     ${idx !== 0 && slot === arrTimesDayGrid[0] ? 'timegrid__cell_end' : ''}
                                                     ${arrTimesGrid.map(item => item.value).includes(slot) ? 'timegrid_cell_border' : ''}
                                                     `}
                                        >
                                        </div>
                                    }) :
                                    arrTimesDayGrid.concat(arrTimesDayGrid).concat(arrTimesDayGrid).map((slot, idx) => {

                                      return (arrTimesDayGrid.length - 1 !== idx &&
                                        (arrTimesDayGrid.length * 2) - 1 !== idx &&
                                        (arrTimesDayGrid.length * 3) - 1 !== idx) &&
                                        <div
                                          key={idx}
                                          style={{ width: `calc(100% / (${arrTimesDayGrid.length - 1} * 3))`, position: 'relative' }}
                                          className={
                                            `${getClassForGrid(dispatcher.dispatcher_id, slot, idx)} 
                                              ${idx !== 0 && slot === arrTimesDayGrid[0] ? 'timegrid__cell_end' : ''}
                                              ${arrTimesGrid.map(item => item.value).includes(slot) ? 'timegrid_cell_border' : ''}
                                            `}
                                        >
                                        </div>
                                    })
                              }
                            </div>

                            <div className="schedule-items">
                              <div
                                style={{
                                  width: '100%',
                                  overflow: 'inherit',
                                  flexDirection: getHeigthRow(dispatcher.dispatcher_id) < 2 ? 'row' : 'column'
                                }}
                                className="schedule-items__day-wrapper schedule-items__day-wrapper_1"
                              >

                                {/* Absence  */}
                                {
                                  dispatcher.dispatcher_absence.sort((a, b) =>
                                    moment(a.absence_start).diff(moment(b.absence_start)))
                                    .map((absence, absenceIndex) => {
                                      let startAbsence = DateTime.fromISO(absence.absence_start).setZone(timeZone).toFormat("MMM dd yyyy")
                                      let endAbsence = DateTime.fromISO(absence.absence_end).setZone(timeZone).toFormat("MMM dd yyyy")

                                      let absenceCheck = showDate.filter(date => moment(date).isBetween(moment(startAbsence), moment(endAbsence))
                                        || moment(date).isSame(moment(startAbsence), 'day')
                                        || moment(date).isSame(moment(endAbsence), 'day'))

                                      if (!!absenceCheck.length) {
                                        let getTime = getPositionAbsence(
                                          dateToInfoBlock('HH:mm', timeZone, absence.absence_start),
                                          dateToInfoBlock('HH:mm', timeZone, absence.absence_end),
                                          startAbsence,
                                          endAbsence,
                                          absenceCheck,
                                          timeZone,
                                        )

                                        let number = 0
                                        showDate.forEach(date => {
                                          let getPosition = getpositionAppointment(dispatcher.dispatcher_id, date, absence.dispatcher_absence_id)
                                          if (getPosition > number) {
                                            number = getPosition
                                          }
                                        })

                                        let hoverPositionTop = '50px'
                                        if (index === scheduleData.length - 1) {
                                          hoverPositionTop = '-55px'
                                        }

                                        return (
                                          <div
                                            style={{
                                              left: `${getTime.start}`,
                                              width: getTime.width,
                                              display: getTime.width === 0 ? 'none' : 'flex',
                                              top: number * 60,
                                              zIndex: showDropList[absence.dispatcher_absence_id + index + absenceIndex] ? 3 : 2,
                                            }}
                                            key={absence.dispatcher_absence_id + index + absenceIndex}
                                            className="absence"
                                            onMouseDown={(e) => handleChange(e, absence.dispatcher_absence_id + index + absenceIndex)}
                                            onContextMenu={(e) => e.preventDefault()}
                                            onMouseOver={(e) => {
                                              //@ts-ignore
                                              setLeftHoverParam(e.nativeEvent.layerX)
                                              !showDropList[absence.dispatcher_absence_id + index + absenceIndex] && setHoverAbsence(absence.dispatcher_absence_id + index + absenceIndex)
                                            }}
                                            onMouseLeave={() => !showDropList[absence.dispatcher_absence_id + index + absenceIndex] && setHoverAbsence('')}
                                          >
                                            <img src={CofeeSvg} alt="Coffe Break" />
                                            <div
                                              style={{
                                                display: 'flex',
                                                overflow: 'hidden',
                                                marginLeft: '5px'
                                              }}
                                            >
                                              <span style={{ cursor: 'inherit' }}> {absence.note} </span>
                                            </div>
                                            {
                                              showDropList[absence.dispatcher_absence_id + index + absenceIndex] &&
                                              <div
                                                style={{
                                                  top: hoverPositionTop,
                                                  // left: hoverPositionLeft < 100 ? '-74px' : `${getTime.width}px`,
                                                  left: `${leftParam}px`,
                                                }}
                                                className="absence-drop-list open visible" >
                                                <p
                                                  style={{ cursor: 'pointer' }}
                                                  onClick={(event) => {
                                                    event.preventDefault()
                                                    event.stopPropagation()
                                                    setShowDropList({})
                                                    window.open(`${window.location.origin}/${activeAccountId}/call_center/absence/${absence.dispatcher_absence_id}`, "_blank", 'noopener,noreferrer')
                                                  }}
                                                >
                                                  Open
                                                </p>
                                              </div>
                                            }
                                            {
                                              !showDropList[absence.dispatcher_absence_id + index + absenceIndex] &&
                                              hoverAbsence === absence.dispatcher_absence_id + index + absenceIndex &&
                                              absence.note &&
                                              <div
                                                style={{
                                                  position: 'absolute',
                                                  width: '150px',
                                                  top: hoverPositionTop,
                                                  // left: hoverPositionLeft < 100 ? '-150px' : `${getTime.width}px`,
                                                  left: `${leftHoverParam}px`,
                                                  padding: '5px 10px',
                                                  border: '3px solid #6093DE',
                                                  borderRadius: '5px',
                                                  backgroundColor: 'white',
                                                  zIndex: '9999'
                                                }}
                                              >
                                                <p
                                                  style={{ color: 'black', fontSize: '14px', margin: '1px', wordBreak: 'break-word' }}
                                                  className="appointment__job-info"
                                                >
                                                  {absence.note}
                                                </p>
                                              </div>
                                            }
                                          </div>
                                        )
                                      } else {
                                        return null
                                      }
                                    })
                                }
                              </div>
                            </div>

                          </div>
                        </div>


                      </div>
                    </div>
                  </div>
                }
              </div>
            ))
          }
        </div>
      </div>
    </section>
  );
}