import { memo } from 'react'

import UnitEdit from '../BlankPagesForTech/UnitEdit'
import UnitAdd from '../BlankPagesForTech/UnitAdd'
import InvoiceAdd from '../BlankPagesForTech/InvoiceAdd'
import InvoiceEdit from '../BlankPagesForTech/InvoiceEdit'
import AppointmentAdd from '../BlankPagesForTech/AppointmentAdd'
import AppointmentEdit from '../BlankPagesForTech/AppointmentEdit'
import PartsAdd from '../BlankPagesForTech/PartsAdd'
import PartsEdit from '../BlankPagesForTech/PartsEdit'
import CustomerAddress from '../BlankPagesForTech/CustomerAddress'

import { EditProps, OtherAppointmentsProps, PermissionsProps, UnitProps } from './ItemTech'
import ClientShow from '../BlankPagesForTech/ClientShow'
import LineItems from '../BlankPagesForTech/LineItems'
import LineItemsEdit from '../BlankPagesForTech/LineItemsEdit'
import OtherAppointments from '../BlankPagesForTech/OtherAppointments'
import AttachmentsAdd from '../BlankPagesForTech/AttachmentsAdd'

interface BlankPageProps {
  page: string
  switchPage: (name: string) => void
  id: string
  edit?: EditProps
  jobId?: string
  client_id?: string | null
  address: string
  jobAddress?: string
  units: UnitProps[]
  other_appointments: OtherAppointmentsProps[]
  permissions: Partial<PermissionsProps>
  service_resource_id: string
  status_id: number
  appointment_id: string
}

function BlankPage({
  page,
  switchPage,
  id,
  edit,
  jobId,
  client_id,
  address,
  jobAddress,
  units,
  other_appointments,
  permissions,
  service_resource_id,
  status_id,
  appointment_id,
}: BlankPageProps) {

  return (
    <div className='blank-page'>
      {
        page === 'attachments_add' && jobId &&
        <AttachmentsAdd switchPage={switchPage} jobId={jobId} />
      }
      {
        page === 'other_appointments' &&
        <OtherAppointments switchPage={switchPage} data={other_appointments} />
      }
      {
        page === 'unit_edit' && jobId && client_id &&
        <UnitEdit
          switchPage={switchPage}
          id={id}
          jobId={jobId}
          client_id={client_id}
          permission_edit={permissions.technician_unit_edit ? permissions.technician_unit_edit : false}
          appointment_id={appointment_id}
        />
      }
      {
        page === 'unit_add' && jobId && client_id &&
        <UnitAdd
          switchPage={switchPage}
          edit={{
            unit_type: edit && edit.unit_type ? edit.unit_type : [],
            appliances: edit && edit.appliances ? edit.appliances : [],
            appliance_brands: edit && edit.appliance_brands ? edit.appliance_brands : [],
          }}
          jobId={jobId}
          client_id={client_id}
          appointment_id={appointment_id}
        />
      }
      {
        page === 'estimate_add' && jobId && client_id &&
        <LineItems
          switchPage={switchPage}
          jobId={jobId}
          client_id={client_id}
          edit={edit && edit.estimate_status ? edit.estimate_status : []}
          units={units}
          permission_edit={permissions.technician_estimate_edit ? permissions.technician_estimate_edit : false}
          service_resource_id={service_resource_id}
        />
      }

      {
        page === 'estimate_edit' && jobId && client_id &&
        <LineItemsEdit
          switchPage={switchPage}
          jobId={jobId}
          client_id={client_id}
          edit={edit && edit.estimate_status ? edit.estimate_status : []}
          units={units}
          id={id}
          permission_edit={permissions.technician_estimate_edit ? permissions.technician_estimate_edit : false}
          service_resource_id={service_resource_id}
        />
      }

      {
        page === 'invoice_add' && jobId && client_id && jobAddress &&
        <InvoiceAdd
          switchPage={switchPage}
          jobId={jobId}
          client_id={client_id}
          edit={edit && edit.invoice_status ? edit.invoice_status : []}
          jobAddress={jobAddress}
        />
      }
      {
        page === 'invoice_edit' && id && jobId &&
        <InvoiceEdit
          switchPage={switchPage}
          id={id}
          job_id={jobId}
          service_resource_id={service_resource_id}
        />
      }

      {
        page === 'appointment_add' && jobId && client_id &&
        <AppointmentAdd
          switchPage={switchPage}
          edit={edit && edit.appointment_status ? edit.appointment_status : []}
          jobId={jobId}
          client_id={client_id}
        />
      }
      {
        page === 'appointment_edit' && edit && id &&
        <AppointmentEdit switchPage={switchPage} edit={edit.appointment_status} id={id} />
      }
      {
        page === 'parts_add' && jobId &&
        <PartsAdd
          switchPage={switchPage}
          jobId={jobId}
          edit={{
            part_status: edit && edit.part_status ? edit.part_status : [],
            warehouses: edit && edit.warehouses ? edit.warehouses : []
          }}
          units={units}
          status_id={status_id}
        />
      }
      {
        page === 'parts_edit' && id && jobId &&
        <PartsEdit
          switchPage={switchPage}
          id={id}
          permission_edit={permissions.technician_part_edit ? permissions.technician_part_edit : false}
          status_id={status_id}
          units={units}
          job_id={jobId}
        />
      }
      {
        page === 'google_map' && <CustomerAddress switchPage={switchPage} address={address} />
      }
      {
        page === 'client' && client_id && <ClientShow switchPage={switchPage} client_id={client_id} />
      }
    </div>
  )
}

export default memo(BlankPage)
