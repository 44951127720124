import React, { useEffect, useRef, useState } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import classNames from 'classnames';
import SignatureCanvas from 'react-signature-canvas';

import Icon from '../../components/Icon'
import PdfDocument from './PdfDocument';
import Checkbox from '../../components/Checkbox';

import { DataProps, TemplateProps } from './InvoiceEdit';

interface PdfViewProps {
  setPage: (value: string) => void
  data: DataProps
  name: string
  id: string
  template?: TemplateProps
  handleSendApproveLink: (sign: string, document_base64: string) => void
}

export default function PdfView({
  setPage,
  data,
  name,
  id,
  template,
  handleSendApproveLink,
}: PdfViewProps) {
  const sigCanvas = useRef(null)

  const [agree, setAgree] = useState(false)
  const [isSignEnd, setIsSignEnd] = useState(false)

  const [base64Sign, setBase64Sign] = useState('')
  const [blobDoc, setBlobDoc] = useState<Blob | null>(null)

  const [isSend, setIsSend] = useState(false)

  useEffect(() => {
    if (blobDoc) {
      let reader = new FileReader();
      reader.readAsDataURL(blobDoc);
      reader.onloadend = function () {
        var base64data = reader.result;
        handleSendLink(base64Sign, base64data as string)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blobDoc])

  async function handleSendLink(sign: string, blobDoc: string) {
    setIsSend(true)
    let file = sign.replace('data:image/png;base64,', '').replace('data:image/jpeg;base64,', '')
    let document_base64 = blobDoc.replace('data:application/pdf;base64,', '')

    handleSendApproveLink(file, document_base64)
  }

  function handleAgree() {
    if (sigCanvas && sigCanvas.current) {

      //@ts-ignore
      let base64 = sigCanvas.current.toDataURL()
      setBase64Sign(base64)
    }
  }

  function getPosition(num: number) {
    let result = 0
    let width = 590
    if (template) {
      width = template.width ? template.width : 590
    }

    result = (590 * num) / width

    return Number(result)
  }

  function getSubTotal() {
    let total = 0

    data.services.forEach(item => {
      total = total + Number(item.amount * item.quantity)
    })

    data.materials.forEach(item => {
      total = total + (Number(item.price_per_unit) * Number(item.quantity))
    })

    return `$${total.toFixed(2)}`
  }

  function getTotalWithDiscount() {
    let total = 0
    let discount = 0

    data.services.forEach(item => {
      total = total + Number(item.amount * item.quantity)
    })

    data.materials.forEach(item => {
      total = total + (Number(item.price_per_unit) * Number(item.quantity))
    })

    data.discounts.forEach(item => {
      discount = discount + Number(item.amount)
    })


    return `$${(total - discount).toFixed(2)}`
  }

  function getSubTotalDiscount() {
    let discount = 0

    data.discounts.forEach(item => {
      discount = discount + Number(item.amount)
    })


    return `$${(discount).toFixed(2)}`
  }

  function getTotalService() {
    let total = 0

    data.services.forEach(item => {
      total = total + Number(item.amount * item.quantity)
    })

    return total
  }

  function getTotalMaterials() {
    let total = 0

    data.materials.forEach(item => {
      total = total + (Number(item.price_per_unit) * Number(item.quantity))
    })

    return total
  }

  return (
    <div className="EstimatePage_Add entity-edit">
      <div className="wrapper flex-container sb">
        <div className='row'>
          <button
            style={{ background: 'inherit' }}
            className="_wa"
            onClick={() => setPage('')}
          >
            <Icon
              style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#005eb5' }}
              viewBox="0 0 24 24"
              icon="arrow-25"
            />
          </button>

          <h1 style={{ whiteSpace: 'nowrap', marginTop: '0', color: '#005eb5' }}>View Pdf</h1>
        </div>
      </div>

      <div>
        {/* <button className="_wa" onClick={generatePdfDocument}>View and Save</button>

        <PDFDownloadLink document={<PdfDocument data={data} name={name} id={id} template={template} />} >
          {({ blob, url, loading, error }) => {
            url && setUrl(url)
          }}
        </PDFDownloadLink> */}
      </div>
      {/* <Iframe url={url} /> */}

      <div className='document-pdf-file'>
        {
          base64Sign &&
          <PDFDownloadLink document={<PdfDocument
            data={data}
            name={name}
            id={id}
            template={template}
            sign={base64Sign}
          />} >
            {({ blob, url, loading, error }) => {
              blob && setBlobDoc(blob)
            }}
          </PDFDownloadLink>
        }

        <div
          className="document-header"
          style={{ height: template?.height ? template.height : '300px' }}
        >
          {
            template &&
            template.blocks.map((item, index) => (
              <React.Fragment key={index}>
                {
                  item.type === 'Label' &&
                  <div
                    className={classNames('block',
                      {
                        is_bold: item.is_bold,
                        is_underline: item.is_underline,
                        is_italic: item.is_italic
                      })}
                    style={{
                      fontSize: `${item.font_size}px`,
                      color: item.color,
                      left: `${getPosition(item.deltaPosition.x)}px`,
                      top: `${item.deltaPosition.y}px`,
                    }}
                  >
                    {item.value}
                  </div>
                }

                {
                  item.type === 'Text' &&
                  <div
                    className={classNames('block text',
                      {
                        is_bold: item.is_bold,
                        is_underline: item.is_underline,
                        is_italic: item.is_italic
                      })}
                    style={{
                      fontSize: `${item.font_size}px`,
                      color: item.color,
                      textAlign: item.align,
                      width: `${item.width}px`,
                      left: `${getPosition(item.deltaPosition.x)}px`,
                      top: `${item.deltaPosition.y}px`,
                    }}
                    dangerouslySetInnerHTML={{ __html: item.value }}
                  ></div>
                }

                {
                  item.type === 'Image' &&
                  <div
                    className='block image'
                    style={{
                      left: `${getPosition(item.deltaPosition.x)}px`,
                      top: `${item.deltaPosition.y}px`,
                    }}
                  >
                    <img
                      style={{
                        width: `${item.width}px`,
                        height: `${item.height}px`,
                        objectFit: item.object_fit,
                      }}
                      src={item.image_url ? item.image_url : item.image_base64}
                      alt="Attachment"
                    />
                  </div>
                }
              </React.Fragment>
            ))
          }
        </div>

        <div className="content">
          <div className="content-title"> {name} </div>

          <div className="content-block">
            {
              !!data.services.length &&
              <div className="content-block-header">
                <div style={{ width: '55%' }} className="header-title">Services</div>

                <div style={{ width: '10%' }} className="header-title">qty</div>

                <div style={{ width: '15%' }} className="header-title">unit price</div>

                <div style={{ width: '20%', textAlign: 'right' }} className="header-title">amount</div>
              </div>
            }

            {
              data.services.map(item => (
                <div className="content-block-items">
                  <div style={{ width: '55%' }} className="content-block-item-col">
                    <span> {item.name} </span>
                    <span className="comment"> {item.comment} </span>
                  </div>

                  <div style={{ width: '10%' }}> {item.quantity} </div>
                  <div style={{ width: '15%' }}> ${item.amount.toFixed(2)} </div>
                  <div style={{ width: '20%', textAlign: 'right' }}> ${(item.amount * item.quantity).toFixed(2)} </div>
                </div>
              ))
            }

            {
              !!data.services.length &&
              <div className="content-block-subtotal">
                Services subtotal: ${getTotalService().toFixed(2)}
              </div>
            }

            {
              !!data.materials.length &&
              <div className="content-block-header">
                <div style={{ width: '55%' }} className="header-title">Materials</div>

                <div style={{ width: '10%' }} className="header-title">qty</div>

                <div style={{ width: '15%' }} className="header-title">unit price</div>

                <div style={{ width: '20%', textAlign: 'right' }} className="header-title">amount</div>
              </div>
            }

            {
              data.materials.map(item => (
                <div className="content-block-items">
                  <div style={{ width: '55%' }} className="content-block-item-col">
                    <span> {item.name} </span>
                    <span className="comment"> {item.description} </span>
                  </div>

                  <div style={{ width: '10%' }}> {item.quantity} </div>
                  <div style={{ width: '15%' }}> ${item.price_per_unit.toFixed(2)} </div>
                  <div style={{ width: '20%', textAlign: 'right' }}> ${(item.price_per_unit * item.quantity).toFixed(2)} </div>
                </div>
              ))
            }

            {
              !!data.materials.length &&
              <div className="content-block-subtotal">
                Materials subtotal: ${getTotalMaterials().toFixed(2)}
              </div>
            }

            <div className="content-block-footer">
              <div className="footer-items">
                <div className="footer-item grey">
                  <span>Subtotal</span>
                  <span> {getSubTotal()} </span>
                </div>

                <div className="footer-item">
                  <span>Discount</span>
                  <span> {getSubTotalDiscount()} </span>
                </div>

                <div className="footer-item total">
                  <span>Total</span>
                  <span> {getTotalWithDiscount()} </span>
                </div>

                <div className="footer-item deposite">
                  <span>Deposite</span>
                  <span> ${(data.deposite.amount).toFixed(2)} </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="link-page-row">
          <span>Lorem Ipsum is simply dummy text of the printing. </span>

          <Checkbox
            contents=''
            value={agree}
            onChange={(value) => setAgree(value)}
          />
        </div>

        <div className="link-page-row right">
          <SignatureCanvas
            penColor='black'
            ref={sigCanvas}
            canvasProps={{ width: 200, height: 100 }}
            onEnd={() => setIsSignEnd(true)}
          />
        </div>

        <div className="link-page-row center">
          <button
            className="_bordered _green"
            disabled={!agree || !isSignEnd || isSend}
            onClick={handleAgree}
          >
            Approve
          </button>
        </div>
      </div>


    </div>
  )
}
