import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import SignatureCanvas from "react-signature-canvas";
import { PDFDownloadLink } from "@react-pdf/renderer";

import Checkbox from "../../components/Checkbox";
import PdfDocument from "./PdfDocument";

import { httpClientUpdate } from "../../funcs";

import { TemplateProps } from "../BlankPagesForTech/InvoiceEdit";
import { ItemsProps } from "./Index";

interface DocProps {
  deposit_amount: string
  items: ItemsProps[]
  name: string
  template: TemplateProps
  account_id: string
  link_id: string
  job_id: string
  service_resource_id: string
  invoice_id: string
  estimate_id: string
}

export default function Doc({
  deposit_amount,
  items,
  name,
  template,
  account_id,
  link_id,
  job_id,
  service_resource_id,
  invoice_id,
  estimate_id,
}: DocProps) {
  const sigCanvas = useRef(null)

  const [agree, setAgree] = useState(false)
  const [isSignEnd, setIsSignEnd] = useState(false)

  const [base64Sign, setBase64Sign] = useState('')
  const [blobDoc, setBlobDoc] = useState<Blob | null>(null)

  const [isSend, setIsSend] = useState(false)

  useEffect(() => {
    if (blobDoc) {
      let reader = new FileReader();
      reader.readAsDataURL(blobDoc);
      reader.onloadend = function () {
        var base64data = reader.result;
        handleSendLink(base64Sign, base64data as string)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blobDoc])

  async function handleSendLink(sign: string, blobDoc: string) {
    setIsSend(true)
    try {
      let file = sign.replace('data:image/png;base64,', '').replace('data:image/jpeg;base64,', '')
      let document_base64 = blobDoc.replace('data:application/pdf;base64,', '')

      // technician/approvals
      const response = await httpClientUpdate.post(`/technician/approvals`, {
        account_id: account_id,
        job_id,
        service_resource_id,
        estimate_id,
        invoice_id,
        url_sign: file,
        url_signed_document: document_base64,
      })

      if (response.data.success) { }
    } catch (error) { }
  }

  function getTotalService() {
    let total = 0

    items.forEach(item => {
      if (item.type === 'service') {
        total = total + (Number(item.quantity) * Number(item.price))
      }
    })

    return total
  }

  function getTotalMaterial() {
    let total = 0

    items.forEach(item => {
      if (item.type === 'material') {
        total = total + (Number(item.quantity) * Number(item.price))
      }
    })

    return total
  }

  function getSubTotal() {
    return getTotalService() + getTotalMaterial()
  }

  function getDiscount() {
    let total = 0

    items.forEach(item => {
      if (item.type === 'discount') {
        total = total + (Number(item.quantity) * Number(item.price))
      }
    })

    return total
  }

  function getTotalWithDiscount() {
    return getTotalService() + getTotalMaterial() - getDiscount()
  }

  function getPosition(num: number) {
    let width = template.width ? template.width : 590
    let result = 0

    result = (590 * num) / width

    return Number(result)
  }

  function handleAgree() {
    if (sigCanvas && sigCanvas.current) {

      //@ts-ignore
      let base64 = sigCanvas.current.toDataURL()
      setBase64Sign(base64)
    }
  }


  return (
    <div className='document'>
      {
        base64Sign &&
        <PDFDownloadLink document={<PdfDocument
          data={items}
          template={template}
          name={name}
          deposit_amount={deposit_amount}
          sign={base64Sign}
        />} >
          {({ blob, url, loading, error }) => {
            blob && setBlobDoc(blob)
          }}
        </PDFDownloadLink>
      }

      <div
        className="document-header"
        style={{ height: template.height }}
      >
        {
          template.blocks.map((item, index) => (
            <React.Fragment key={index}>
              {
                item.type === 'Label' &&
                <div
                  className={classNames('block',
                    {
                      is_bold: item.is_bold,
                      is_underline: item.is_underline,
                      is_italic: item.is_italic
                    })}
                  style={{
                    fontSize: `${item.font_size}px`,
                    color: item.color,
                    left: `${getPosition(item.deltaPosition.x)}px`,
                    top: `${item.deltaPosition.y}px`,
                  }}
                >
                  {item.value}
                </div>
              }

              {
                item.type === 'Text' &&
                <div
                  className={classNames('block text',
                    {
                      is_bold: item.is_bold,
                      is_underline: item.is_underline,
                      is_italic: item.is_italic
                    })}
                  style={{
                    fontSize: `${item.font_size}px`,
                    color: item.color,
                    textAlign: item.align,
                    width: `${item.width}px`,
                    left: `${getPosition(item.deltaPosition.x)}px`,
                    top: `${item.deltaPosition.y}px`,
                  }}
                  dangerouslySetInnerHTML={{ __html: item.value }}
                ></div>
              }

              {
                item.type === 'Image' &&
                <div
                  className='block image'
                  style={{
                    left: `${getPosition(item.deltaPosition.x)}px`,
                    top: `${item.deltaPosition.y}px`,
                  }}
                >
                  <img
                    style={{
                      width: `${item.width}px`,
                      height: `${item.height}px`,
                      objectFit: item.object_fit,
                    }}
                    src={item.image_url ? item.image_url : item.image_base64}
                    alt="Attachment"
                  />
                </div>
              }
            </React.Fragment>
          ))
        }
      </div>

      <div className="content">
        <div className="content-title"> {name} </div>

        <div className="content-block">
          {
            !!items.filter(item => item.type === 'service').length &&
            <div className="content-block-header">
              <div style={{ width: '55%' }} className="header-title">Services</div>

              <div style={{ width: '10%' }} className="header-title">qty</div>

              <div style={{ width: '15%' }} className="header-title">unit price</div>

              <div style={{ width: '20%', textAlign: 'right' }} className="header-title">amount</div>
            </div>
          }

          {
            items
              .filter(item => item.type === 'service')
              .map(item => (
                <div className="content-block-items">
                  <div style={{ width: '55%' }} className="content-block-item-col">
                    <span> {item.name} </span>
                    <span className="comment"> {item.description} </span>
                  </div>

                  <div style={{ width: '10%' }}> {item.quantity} </div>
                  <div style={{ width: '15%' }}> ${item.price} </div>
                  <div style={{ width: '20%', textAlign: 'right' }}> ${(Number(item.price) * Number(item.quantity)).toFixed(2)} </div>
                </div>
              ))
          }

          {
            !!items.filter(item => item.type === 'service').length &&
            <div className="content-block-subtotal">
              Services subtotal: ${getTotalService().toFixed(2)}
            </div>
          }

          {
            !!items.filter(item => item.type === 'material').length &&
            <div className="content-block-header">
              <div style={{ width: '55%' }} className="header-title">Materials</div>

              <div style={{ width: '10%' }} className="header-title">qty</div>

              <div style={{ width: '15%' }} className="header-title">unit price</div>

              <div style={{ width: '20%', textAlign: 'right' }} className="header-title">amount</div>
            </div>
          }

          {
            items
              .filter(item => item.type === 'material')
              .map(item => (
                <div className="content-block-items">
                  <div style={{ width: '55%' }} className="content-block-item-col">
                    <span> {item.name} </span>
                    <span className="comment"> {item.description} </span>
                  </div>

                  <div style={{ width: '10%' }}> {item.quantity} </div>
                  <div style={{ width: '15%' }}> ${item.price} </div>
                  <div style={{ width: '20%' }}> ${(Number(item.price) * Number(item.quantity)).toFixed(2)} </div>
                </div>
              ))
          }

          {
            !!items.filter(item => item.type === 'material').length &&
            <div className="content-block-subtotal">
              Materials subtotal: ${getTotalMaterial().toFixed(2)}
            </div>
          }

          <div className="content-block-footer">
            <div className="footer-items">
              <div className="footer-item grey">
                <span>Subtotal</span>
                <span> ${getSubTotal().toFixed(2)} </span>
              </div>

              <div className="footer-item">
                <span>Discount</span>
                <span> ${getDiscount().toFixed(2)} </span>
              </div>

              <div className="footer-item total">
                <span>Total</span>
                <span> ${getTotalWithDiscount().toFixed(2)} </span>
              </div>

              <div className="footer-item deposite">
                <span>Deposite</span>
                <span> ${deposit_amount} </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="link-page-row">
        <span>Lorem Ipsum is simply dummy text of the printing. </span>

        <Checkbox
          contents=''
          value={agree}
          onChange={(value) => setAgree(value)}
        />
      </div>

      <div className="link-page-row right">
        <SignatureCanvas penColor='black'
          ref={sigCanvas}
          canvasProps={{ width: 200, height: 100 }}
          onEnd={() => setIsSignEnd(true)}
        />
      </div>

      <div className="link-page-row center">
        <button
          className="_bordered _green"
          disabled={!agree || !isSignEnd || isSend}
          onClick={handleAgree}
        >
          Approve
        </button>
      </div>
    </div>
  )
}
