import { useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import moment from 'moment'
import { useCookies } from 'react-cookie'
import { useRoute } from 'react-router5'
import classNames from 'classnames'

import ReportTableField from '../../components/reports/TableField'
import ReportFilters from '../../components/reports/Filters'
import ReportTableControls from '../../components/reports/TableControls'

import { dateToInfoBlock, httpClientUpdate, nErrorUpdate, useOuterClick, formatPhoneNumberForItemTech } from '../../funcs'
import { getDateRangeByPreset } from '../../funcs/reports'
import { useAppSelector } from '../../store/hooks'

import { DateRangePreset } from '../../models/Misc'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: LinksProps
}

interface LinksProps {
  interface: {
    max_pages: number,
    rows_start: number,
    rows_end: number,
    rows_all: number,

    min_date: Date,
    max_date: Date
  },

  permissions: {
    estimate_show: boolean
    invoice_show: boolean
    job_show: boolean
    links_show: boolean
    payment_show: boolean
  }

  links: {
    amount
    :
    null
    created_at: string
    created_by: string
    created_by_name: string
    estimate_id: string
    estimate_name: string
    expired_date: string
    invoice_id: string
    invoice_name: string
    job_id: string
    job_name: string
    link_id: string
    note: string
    payment_date_time: string
    payment_id: string
    phone: string
    service_resource: string
    service_resource_id: string
    status: string
    type: string
  }[]
}

export default function List({ updated }: { updated: number }) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)
  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)

  let timeZone = user?.time_zone;

  const [cookies, setCookie] = useCookies();

  const cookiesSetting = cookies.settings ? cookies.settings : null

  const [$updater, $setUpdater] = useState<any>(Math.random())

  const [reportIsDeprecated, setReportIsDeprecated] = useState(false)
  const [searchButtonActive, setSearchButtonActive] = useState(false)

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [reportData, setReportData] = useState<LinksProps | null>(null)
  const [localInterface, setLocalInterface] = useState(
    {
      search: '',
      jobSharePopup: false,
      dateRangeCalendarShown: false,
      dateRangePreset: "last_7_days" as DateRangePreset,
      ...(getDateRangeByPreset(user, 'last_7_days', DateTime.now().setZone(timeZone).startOf('day').toJSDate(), DateTime.now().setZone(timeZone).endOf('day').toJSDate())),
      page: 1,
      sort: {
        field: cookiesSetting && cookiesSetting?.links?.sort_field ? cookiesSetting.links.sort_field : 'created_at',
        direction: cookiesSetting && cookiesSetting?.links?.sort_type ? cookiesSetting.links.sort_type : 'down' as 'down' | 'up'
      },
      sortFields: [{
        span: 'Date',
        value: 'created_at'
      }, {
        span: 'Created By',
        value: 'created_by_name'
      }, {
        span: 'Expired',
        value: 'expired_date'
      }, {
        span: 'Document',
        value: 'job_id'
      }, {
        span: 'Phone',
        value: 'phone'
      }, {
        span: 'Service Resource',
        value: 'service_resource_id'
      }, {
        span: 'Status',
        value: 'status'
      }, {
        span: 'Type',
        value: 'type',
      }, {
        span: 'Amount',
        value: 'amount'
      }]
    })

  useEffect(() => {
    updated && setLocalInterface({
      ...localInterface,
      search: '',
      jobSharePopup: false,
      dateRangeCalendarShown: false,
      dateRangePreset: "last_7_days" as DateRangePreset,
      ...(getDateRangeByPreset(user, 'last_7_days', DateTime.now().setZone(timeZone).startOf('day').toJSDate(), DateTime.now().setZone(timeZone).endOf('day').toJSDate())),
      page: 1,
      sort: {
        field: cookiesSetting && cookiesSetting?.links?.sort_field ? cookiesSetting.links.sort_field : 'created_at',
        direction: cookiesSetting && cookiesSetting?.links?.sort_type ? cookiesSetting.links.sort_type : 'down' as 'down' | 'up'
      },
    })
    updated && $setUpdater(updated)

    setTimeout(() => {
      updated && setReportIsDeprecated(false)
      updated && setSearchButtonActive(false)
    }, 100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  // Watch date range preset
  useEffect(() => {
    if (!reportData) return

    if (localInterface.dateRangePreset === 'custom') return

    !updated && setLocalInterface({
      ...localInterface,
      ...(getDateRangeByPreset(user, localInterface.dateRangePreset, reportData?.interface.min_date, reportData?.interface.max_date))
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.dateRangePreset])

  // Load Payment function
  async function loadPayment() {
    let filterParams = {}

    if ($router.router.getState().params) {
      if ($router.router.getState().params.localInterface) {
        filterParams = {
          account_id: activeAccountId,
          limit_rows: cookiesSetting && cookiesSetting?.links?.limit_rows ? cookiesSetting?.links?.limit_rows : reportsMaxRows,
          page: $router.router.getState().params.localInterface.page,
          date_start: $router.router.getState().params.localInterface.min_date,
          date_end: $router.router.getState().params.localInterface.max_date,
          sort_field: $router.router.getState().params.localInterface.sort.field,
          sort_type: $router.router.getState().params.localInterface.sort.direction === 'up' ? 'asc' : 'desc',
          ...($router.router.getState().params.localInterface.search ? { search: $router.router.getState().params.localInterface.search } : {})
        }
      } else {
        filterParams = {
          account_id: activeAccountId,
          limit_rows: cookiesSetting && cookiesSetting?.links?.limit_rows ? cookiesSetting?.links?.limit_rows : reportsMaxRows,
          page: localInterface.page,
          date_start: localInterface.min_date,
          date_end: localInterface.max_date,
          sort_field: localInterface.sort.field,
          sort_type: localInterface.sort.direction === 'up' ? 'asc' : 'desc',
          ...(localInterface.search ? { search: localInterface.search } : {})
        }

        setCookie('settings', { ...cookiesSetting, links: { sort_field: localInterface.sort.field, sort_type: localInterface.sort.direction, limit_rows: cookiesSetting && cookiesSetting?.links?.limit_rows ? cookiesSetting?.links.limit_rows : reportsMaxRows } })
      }
    }

    try {
      // api/links/report?limit_rows=100&page=1&account_id=88888
      const { data: { data: payments, success, error } } = (await httpClientUpdate.get('/links/report', {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        },
        params: {
          ...filterParams
        }
      })) as { data: HttpClientUpdateReport }
      if (success) {
        setReportData({
          ...payments,
          interface: {
            ...payments.interface,
            min_date: moment(payments.interface.min_date).startOf('day').toDate(),
            max_date: moment(payments.interface.max_date).endOf('day').toDate(),
          }
        })
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

      setTimeout(() => {
        setReportIsDeprecated(false)
        setSearchButtonActive(false)
      }, 100)
    }
    catch (error: Error | AxiosError | unknown) {
      setCookie('settings', { ...cookiesSetting, payments: {} })

      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load Payment on page mount
  useEffect(() => {
    loadPayment()

    // unmount page
    return () => {
      setReportData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$updater])

  // Watch for deprecated
  useEffect(() => {
    if (!reportData) return
    setReportIsDeprecated(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    localInterface.min_date,
    localInterface.max_date
  ])

  useEffect(() => {
    if (!reportData) return
    setSearchButtonActive(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.search])

  // Update settings from back page
  useEffect(() => {
    if ($router.router.getState().params.localInterface) {
      setLocalInterface({
        ...$router.router.getState().params.localInterface
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$router.router.getState().params])

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });
  return (
    <>
      {
        reportData &&
        <div className="JobsPage_List">
          { /* Page header */}
          <div className="page-header">
            <h1>Links</h1>
          </div>

          { /* List filters */}
          <ReportFilters
            onSearchInputChange={(value) => setLocalInterface({ ...localInterface, search: value })}
            searchInput={localInterface.search}
            searchButtonActive={searchButtonActive}

            dateRangePreset={localInterface.dateRangePreset}
            onDateRangePresetChange={(value) => setLocalInterface({ ...localInterface, dateRangePreset: value, page: 1 })}

            minDate={localInterface.min_date}
            onMinDateChange={(value) => setLocalInterface({ ...localInterface, min_date: value, dateRangePreset: 'custom', page: 1 })}

            maxDate={localInterface.max_date}
            onMaxDateChange={(value) => setLocalInterface({ ...localInterface, max_date: value, dateRangePreset: 'custom', page: 1 })}

            updateButtonActive={reportIsDeprecated}
            onUpdate={() => $setUpdater(Math.random())}

            phoneCall={phoneCall}
            navActive={navActive.is}
          />

          {/* Table controls */}
          <ReportTableControls
            zIndex={5}

            cookie_reportsMaxRows={cookiesSetting?.links?.limit_rows}

            onMaxRowsChange={(value) => {
              setCookie('settings', { ...cookiesSetting, links: { ...cookiesSetting.links, limit_rows: value } })
              $setUpdater(Math.random())
            }}

            amount={{
              total: reportData.interface.rows_all,
              start: reportData.interface.rows_start,
              end: reportData.interface.rows_end
            }}

            page={localInterface.page}
            maxPages={reportData.interface.max_pages}
            onPagesStart={() => {
              setLocalInterface({ ...localInterface, page: 1 })
              $setUpdater(Math.random())
            }}
            onPrevPage={() => {
              setLocalInterface({ ...localInterface, page: localInterface.page - 1 })
              $setUpdater(Math.random())
            }}
            onNextPage={() => {
              setLocalInterface({ ...localInterface, page: localInterface.page + 1 })
              $setUpdater(Math.random())
            }}
            onPagesEnd={() => {
              setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })
              $setUpdater(Math.random())
            }}

            sort={localInterface.sort}
            sortFields={localInterface.sortFields}
            onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
            onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
            onSortFire={() => $setUpdater(Math.random())}
          />

          <div className="contents">
            {/* Wide desktop table */}
            <table className={classNames('table', '__show-on-wide',
              {
                __respectAsidePanel: navActive.is && !phoneCall,
                __phoneCall: phoneCall && !navActive.is,
                __bothOpen: navActive.is && phoneCall,
                __nonePanel: !navActive.is && !phoneCall
              }
            )}>
              <thead>
                <tr>
                  <ReportTableField
                    contents={(<span>Date</span>)}

                    sortDirection={localInterface.sort.field === 'created_at' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'created_at', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />
                  <ReportTableField
                    contents={(<span>Type</span>)}

                    sortDirection={localInterface.sort.field === 'type' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'type', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />
                  <ReportTableField
                    contents={(<span>Status</span>)}

                    sortDirection={localInterface.sort.field === 'status' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'status', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />
                  <ReportTableField
                    contents={(<span>Document</span>)}

                    sortDirection={localInterface.sort.field === 'job_id' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'job_id', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />
                  <ReportTableField
                    contents={(<span>Phone</span>)}

                    sortDirection={localInterface.sort.field === 'phone' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'phone', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />
                  <ReportTableField
                    contents={(<span>Amount</span>)}

                    sortDirection={localInterface.sort.field === 'amount' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'amount', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />
                  <ReportTableField
                    contents={(<span>Service Resource</span>)}

                    sortDirection={localInterface.sort.field === 'service_resource_id' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'service_resource_id', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />
                  <ReportTableField
                    contents={(<span>Created By</span>)}

                    sortDirection={localInterface.sort.field === 'created_by' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'created_by', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />
                </tr>
              </thead>
              {reportData.links.map((link, i) => (
                <tr
                  key={i}
                  style={{ cursor: reportData.permissions.links_show && link.link_id ? 'pointer' : 'inherit', position: 'relative' }}
                  onClick={() =>
                    reportData.permissions.links_show &&
                    link.link_id &&
                    $router.router.navigate('links.item', {
                      companyId: activeAccountId,
                      linkId: link.link_id,
                      localInterface: localInterface,
                    }, { reload: true })}
                  onContextMenu={(event) =>
                    reportData.permissions.links_show &&
                    link.link_id &&
                    handleChangeMenu(event, link.link_id, 'links', i)}
                >
                  <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, link.created_at)}</td>
                  <td>{link.type}</td>
                  <td style={{ color: link?.status?.toLowerCase() === 'paid' ? '#219653' : 'inherit' }}>{link.status}</td>
                  <td>{link.invoice_name ? link.invoice_name : link.estimate_name ? link.estimate_name : link.job_name}</td>
                  <td>{formatPhoneNumberForItemTech(link.phone)}</td>
                  <td>{link.amount}</td>
                  <td>{link.service_resource}</td>
                  <td>
                    {link.created_by_name}
                    {
                      reportData.permissions.links_show &&
                      showContext === `links_${link.link_id}_${i}` &&
                      <div
                        className="redirect-menu"
                        ref={contextMenuRef}
                        style={{ left: `${screenX}px` }}
                      >
                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            $router.router.navigate('links.item', {
                              companyId: activeAccountId,
                              linkId: link.link_id,
                              localInterface: localInterface,
                            }, { reload: true })
                          }
                          }
                        >
                          Open
                        </button>

                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            window.open(`${window.location.origin}/${activeAccountId}/links/${link.link_id}`, "_blank", 'noopener,noreferrer')
                            setShowContext('')
                          }}
                        >
                          Open in new tab
                        </button>
                      </div>
                    }
                  </td>
                </tr>
              ))}
            </table>

            <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile',
              {
                __respectAsidePanel: navActive.is && !phoneCall,
                __phoneCall: phoneCall && !navActive.is,
                __bothOpen: navActive.is && phoneCall,
                __nonePanel: !navActive.is && !phoneCall
              }
            )}>
              <tr></tr>

              {reportData.links.map((link, i) => (
                <tr
                  key={i}
                  style={{ cursor: reportData.permissions.links_show && link.link_id ? 'pointer' : 'inherit', position: 'relative' }}
                  onClick={() =>
                    reportData.permissions.links_show &&
                    link.link_id &&
                    $router.router.navigate('links.item', {
                      companyId: activeAccountId,
                      linkId: link.link_id,
                      localInterface: localInterface,
                    }, { reload: true })}
                  onContextMenu={(event) =>
                    reportData.permissions.links_show &&
                    link.link_id &&
                    handleChangeMenu(event, link.link_id, 'links', i)}
                >
                  <td>
                    <div>{link.type}</div>
                    <div>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, link.created_at)}</div>
                  </td>
                  <td>
                    <div style={{ color: link?.status?.toLowerCase() === 'paid' ? '#219653' : 'inherit' }}>{link.status}</div>
                    <div>{link.invoice_name ? link.invoice_name : link.estimate_name ? link.estimate_name : link.job_name} </div>
                  </td>
                  <td>
                    <div> {formatPhoneNumberForItemTech(link.phone)} </div>
                    <div>Amount: {link.amount} </div>
                  </td>

                  <td>
                    <div>{link.service_resource}</div>
                    <div>Created By: {link.created_by_name} </div>
                    {
                      reportData.permissions.links_show &&
                      showContext === `links_${link.link_id}_${i}` &&
                      <div
                        className="redirect-menu"
                        ref={contextMenuRef}
                        style={{ left: `${screenX}px` }}
                      >
                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            $router.router.navigate('links.item', {
                              companyId: activeAccountId,
                              linkId: link.link_id,
                              localInterface: localInterface,
                            }, { reload: true })
                          }
                          }
                        >
                          Open
                        </button>

                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            window.open(`${window.location.origin}/${activeAccountId}/links/${link.link_id}`, "_blank", 'noopener,noreferrer')
                            setShowContext('')
                          }}
                        >
                          Open in new tab
                        </button>
                      </div>
                    }
                  </td>
                </tr>
              ))}
            </table>

            {/* Mobile table items */}
            <div className={classNames('mobile-table-items', '__show-on-mobile',
              {
                __respectAsidePanel: navActive.is && !phoneCall,
                __phoneCall: phoneCall && !navActive.is,
                __bothOpen: navActive.is && phoneCall,
                __nonePanel: !navActive.is && !phoneCall
              }
            )}>
              {reportData.links.map((link, i: number) => (
                <div
                  className="item"
                  key={i}
                  onClick={() =>
                    reportData.permissions.links_show &&
                    link.link_id &&
                    $router.router.navigate('links.item', {
                      companyId: activeAccountId,
                      linkId: link.link_id,
                      localInterface: localInterface,
                    }, { reload: true })}
                >
                  <div className="__top">
                    <div className="__left">
                      <div>
                        <strong>{link.type}</strong> (<span style={{ color: link?.status?.toLowerCase() === 'paid' ? '#219653' : 'inherit' }}>{link.status}</span>)
                      </div>
                      <div>{link.invoice_name ? link.invoice_name : link.estimate_name ? link.estimate_name : link.job_name}</div>
                    </div>

                    <div className="__right">
                      <div>
                        Amount: <b>{link.amount}</b>
                      </div>
                    </div>
                  </div>

                  <div className="__bottom">
                    <div className="__left">

                    </div>
                    <div className="__right small">
                      <div>
                        {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, link.created_at)}
                      </div>

                      <div>
                        Service Resource: {link.service_resource}
                      </div>

                      <div>
                        Created By: {link.created_by_name}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Table controls */}
          <ReportTableControls
            isUnder={true}

            cookie_reportsMaxRows={cookiesSetting?.links?.limit_rows}

            onMaxRowsChange={(value) => {
              setCookie('settings', { ...cookiesSetting, links: { ...cookiesSetting.links, limit_rows: value } })
              $setUpdater(Math.random())
            }}

            amount={{
              total: reportData.interface.rows_all,
              start: reportData.interface.rows_start,
              end: reportData.interface.rows_end
            }}

            page={localInterface.page}
            maxPages={reportData.interface.max_pages}
            onPagesStart={() => {
              setLocalInterface({ ...localInterface, page: 1 })
              $setUpdater(Math.random())
            }}
            onPrevPage={() => {
              setLocalInterface({ ...localInterface, page: localInterface.page - 1 })
              $setUpdater(Math.random())
            }}
            onNextPage={() => {
              setLocalInterface({ ...localInterface, page: localInterface.page + 1 })
              $setUpdater(Math.random())
            }}
            onPagesEnd={() => {
              setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })
              $setUpdater(Math.random())
            }}

            sort={localInterface.sort}
            sortFields={localInterface.sortFields}
            onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
            onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
            onSortFire={() => $setUpdater(Math.random())}
          />
        </div>
      }
    </>
  )
}
