import classNames from 'classnames'

import Icon from '../../components/Icon'

import { NavActive } from '../../store/reducer'

import { InvoicesProps, PaymentServicesProps } from './ItemTech'

interface PaymentOtherProps {
  navActive: NavActive
  phoneCall: boolean
  setPaymentPage: (value: string) => void
  invoices: InvoicesProps[]
  permission_search_show: boolean
  job_name: string
  payment_services: PaymentServicesProps[]
  setPaymentOtherName: (value: string) => void
}

export default function PaymentOther({
  navActive,
  phoneCall,
  setPaymentPage,
  payment_services,
  setPaymentOtherName,
}: PaymentOtherProps) {

  return (
    <>
      <div
        className={classNames('payment-page job-tech-customer time-tracking mobile', '__show-on-mobile',
          {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          }
        )}
      >
        <div className='content-title'>
          <div
            className='content-title-row'
            style={{ alignItems: 'center' }}
          >
            <div></div>

            <div className='mobile-text'>
              Payment
            </div>

            <div
              onClick={() => setPaymentPage('')}
              className="content-title-plus"
            >
              <Icon style={{ transform: 'rotate(45deg)' }} viewBox="0 0 60 60" icon="plus-job-tech-1" />
            </div>
          </div>
        </div>

        <div className='payment-methods'>
          <div className='payment-methods-title'>
            <Icon style={{ fill: '#fff' }} viewBox='0 0 96 66' icon="other-job-tech-1" />
            Payment Methods
          </div>
          {
            payment_services.map(item => (
              <div
                key={item.payment_services_id}
                className='payment-methods-item'
                onClick={() => setPaymentOtherName(item.name)}
              >
                {item.name}
              </div>
            ))
          }
        </div>

        <div className='empty'></div>
      </div>

      <div
        className={classNames('payment-page job-tech-customer time-tracking mobile', '__hide-on-mobile',
          {
            __respectAsidePanel: navActive.is && !phoneCall,
            __phoneCall: phoneCall && !navActive.is,
            __bothOpen: navActive.is && phoneCall,
            __nonePanel: !navActive.is && !phoneCall
          }
        )}
      >
        <div className='content-title'>
          <div
            className='content-title-row'
            style={{ alignItems: 'center' }}
          >
            <div></div>

            <div className='mobile-text'>
              Payment
            </div>

            <div
              onClick={() => setPaymentPage('')}
              className="content-title-plus"
            >
              <Icon style={{ transform: 'rotate(45deg)' }} viewBox="0 0 60 60" icon="plus-job-tech-1" />
            </div>
          </div>
        </div>

        <div className='payment-methods'>
          <div className='payment-methods-title'>
            <Icon style={{ fill: '#fff' }} viewBox='0 0 96 66' icon="other-job-tech-1" />
            Payment Methods
          </div>
          {
            payment_services.map(item => (
              <div
                key={item.payment_services_id}
                className='payment-methods-item'
                onClick={() => setPaymentOtherName(item.name)}
              >
                {item.name}
              </div>
            ))
          }
        </div>

        <div className='empty'></div>
      </div>
    </>
  )
}
