import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import { DateTime } from "luxon"
import classNames from "classnames"
import Datetime from 'react-datetime'
import moment from "moment";

import ReportFilters from "../../components/reports/Filters"
import ReportTableControls from "../../components/reports/TableControls";
import Icon from "../../components/Icon";
import SelectComponent from "../../components/Select";

import { useAppSelector } from "../../store/hooks"
import { getDateRangeByPreset } from "../../funcs/reports"
import { dateToInfoBlock, getDateTimeForInterface, httpClientUpdate, updatedDateToReqServer } from "../../funcs";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";
import { DateRangePreset } from "../../models/Misc"

import "../../styles/pages/common/report-list.sass"
import "./TimeTracking.sass"

export interface HttpReportProps {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: ReportProps
}

interface ReportProps {
  interface: {
    filter_words: {
      status: string[],
      type: string[],
      service: string[]
    },

    is_staff: boolean,
    max_pages: number,
    rows_start: number,
    rows_end: number,
    rows_all: number,

    min_date: Date,
    max_date: Date
  },

  permissions: {
    staff_tracker_add: boolean
    staff_tracker_add_own: boolean
    staff_tracker_report_show: boolean
    staff_tracker_report_show_own: boolean
  },

  edit: {
    staff: {
      available: boolean
      nickname: string
      staff_id: string
    }[]
  }

  account: {
    time_zone: string
  }

  payout_staff_trackers: PayoutStaffTrackersProps[]
}

interface PayoutStaffTrackersProps {
  created_at: string
  created_by: string
  date: string
  deleted_at: string
  is_manual: boolean
  is_updated_by_staff: boolean
  is_staff: boolean
  note: string
  payout_staff_id: string
  payout_staff_tracker_id: string
  staff_id: string
  type: string
  updated_at: string
  updated_by: string
}

interface AddTimeProps {
  date: string | Date
  type: string
  note: string
  staff_id: string
}

const initialAddTime = {
  date: DateTime.now().toJSDate(),
  type: '',
  note: '',
  staff_id: '',
}

export default function Reports_Page_List({ updated }: { updated: number }) {
  const $router = useRoute()

  const user = useAppSelector((store) => store.user)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)

  let timeZone = user?.time_zone;

  const [isLoaded, setIsLoaded] = useState(false)

  const [note, setNote] = useState('')
  const [reportData, setReportData] = useState<ReportProps | null>(null)
  const [selectedSearch, setSelectedSearch] = useState('')
  const [addTime, setAddTime] = useState<AddTimeProps | null>(null)

  const [reportIsDeprecated, setReportIsDeprecated] = useState(false)
  const [searchButtonActive, setSearchButtonActive] = useState(false)

  const [hover, setHover] = useState('')

  const [activeStatus, setActiveStatus] = useState<string>('')

  const [loading, setLoading] = useState(false)
  const [deleteTracker, setDeleteTracker] = useState('')

  const [$updater, $setUpdater] = useState<any>(Math.random())

  const [localInterface, setLocalInterface] = useState({
    search: '',
    dateRangeCalendarShown: false,
    dateRangeType: "schedule" as "created" | "schedule",
    dateRangePreset: "last_7_days" as DateRangePreset,

    ...(getDateRangeByPreset(user, "last_7_days", DateTime.now().setZone(timeZone).minus({ days: 6 }).startOf('day').toJSDate(), DateTime.now().setZone(timeZone).endOf('day').toJSDate())),

    page: 1,

    filter_words: {
      area: [] as string[],
      created_by: [] as string[],
      service_resource: [] as string[]
    },

    selectedSearchFilters: { type: [] as string[], direction: [] as string[] },

    sort: {
      field: 'created_at',
      direction: 'down' as 'up' | 'down'
    },
    sortFields: [{
      span: 'Date',
      value: 'created_at'
    }]
  })

  async function loadData() {
    setLoading(true)

    let start_date = moment(localInterface.min_date).format('YYYY-MM-DD')
    let start_date_now = moment(getDateRangeByPreset(user, localInterface.dateRangePreset, DateTime.now().setZone(timeZone).minus({ days: 6 }).startOf('day').toJSDate(), DateTime.now().setZone(timeZone).endOf('day').toJSDate()).min_date).format('YYYY-MM-DD')

    let filterParams = {}

    if ($router.router.getState().params) {
      if ($router.router.getState().params.localInterface) {
        filterParams = {
          account_id: activeAccountId,
          limit_rows: reportsMaxRows,
          page: $router.router.getState().params.localInterface.page,
          date_start: $router.router.getState().params.localInterface.min_date,
          date_end: $router.router.getState().params.localInterface.max_date,
          staff_id: selectedSearch ? selectedSearch : null,
        }
      } else {
        if (start_date !== start_date_now) {
          let dates = getDateRangeByPreset(user, localInterface.dateRangePreset, DateTime.now().setZone(timeZone).minus({ days: 6 }).startOf('day').toJSDate(), DateTime.now().setZone(timeZone).endOf('day').toJSDate())

          filterParams = {
            account_id: activeAccountId,
            limit_rows: reportsMaxRows,
            page: localInterface.page,
            staff_id: selectedSearch ? selectedSearch : null,
            date_start: dates.min_date,
            date_end: dates.max_date,
          }
          setLocalInterface({ ...localInterface, ...dates })
        } else {
          filterParams = {
            account_id: activeAccountId,
            limit_rows: reportsMaxRows,
            page: localInterface.page,
            staff_id: selectedSearch ? selectedSearch : null,
            date_start: localInterface.min_date,
            date_end: localInterface.max_date,
          }
        }
      }
    }

    try {
      // https://2022back4.artemiudintsev.com/api/payouts/staff/tracker/report
      const { data: { data: report, success } } = (await httpClientUpdate.get(`/payouts/staff/tracker/report`, {
        params: filterParams,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        },
      })) as { data: HttpReportProps }
      if (success) {
        const getReport = report.payout_staff_trackers

        if (getReport.length === 0) {
          setActiveStatus('')
        } else {
          let isFind = false
          getReport.forEach((item) => {
            if (item.type !== 'comment' && !isFind) {
              isFind = true
              setActiveStatus(item.type)
            }
          })

          if (!isFind) {
            setActiveStatus('')
          }
        }
        setIsLoaded(true)
        setReportData({
          ...report,
          interface: {
            ...report.interface,
            min_date: getDateTimeForInterface(report.interface.min_date),
            max_date: getDateTimeForInterface(report.interface.max_date),
          },
        })
        setLoading(false)

        setTimeout(() => {
          setReportIsDeprecated(false)
        }, 100)
      }
    } catch (error: Error | AxiosError | unknown) {
      setLoading(false)
    }
  }

  async function handleSendType(type: string) {
    try {
      setLoading(true)

      // https://2022back4.artemiudintsev.com/api/payouts/staff/tracker
      const response = await httpClientUpdate.post(`/payouts/staff/tracker`, {
        account_id: activeAccountId,
        note: note,
        type: type,
        staff_id: selectedSearch ? selectedSearch : null,
      })

      if (response.data.success) {
        setNote('')
        setLoading(false)
        loadData()
      } else {
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  }

  async function handleAddTime() {
    try {
      setLoading(true)

      if (addTime) {
        const date = moment(addTime.date).format('MM/DD/YYYY hh:mma')

        // https://2022back4.artemiudintsev.com/api/payouts/staff/tracker
        const response = await httpClientUpdate.post(`/payouts/staff/tracker`, {
          account_id: activeAccountId,
          note: addTime.note,
          type: addTime.type,
          date: updatedDateToReqServer('MM/dd/yyyy hh:mma', reportData?.account.time_zone, date),
          staff_id: addTime.staff_id,
        })

        if (response.data.success) {
          setAddTime(null)
          setLoading(false)
          loadData()
        } else {
          setLoading(false)
        }
      }
    } catch (error) {
      setLoading(false)
    }
  }

  async function handleRemoveTracker() {
    try {
      setLoading(true)

      // https://2022back4.artemiudintsev.com/api/payouts/staff/tracker/88888322c0nw67m8dp?account_id=88888
      const response = await httpClientUpdate.delete(`/payouts/staff/tracker/${deleteTracker}`, {
        params: {
          account_id: activeAccountId
        }
      })
      if (response.data.success) {
        setLoading(false)
        setDeleteTracker('')
        loadData()
      } else {
        setLoading(false)
        setDeleteTracker('')
      }
    } catch (error) {
      setLoading(false)
      setDeleteTracker('')
    }
  }

  // Watch for deprecated
  useEffect(() => {
    if (!reportData) return
    setReportIsDeprecated(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    localInterface.min_date,
    localInterface.max_date
  ])

  useEffect(() => {
    loadData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$updater])

  useEffect(() => {
    if (!!updated) {
      setLocalInterface({
        ...localInterface,
        search: '',
        dateRangeCalendarShown: false,
        dateRangeType: "schedule" as "created" | "schedule",
        dateRangePreset: "last_7_days" as DateRangePreset,
        ...(getDateRangeByPreset(user, "last_7_days", DateTime.now().setZone(timeZone).minus({ days: 6 }).startOf('day').toJSDate(), DateTime.now().setZone(timeZone).endOf('day').toJSDate())),
        page: 1,
        filter_words: {
          area: [] as string[],
          created_by: [] as string[],
          service_resource: [] as string[]
        },
        selectedSearchFilters: { type: [] as string[], direction: [] as string[] },
        sort: {
          field: 'created_at',
          direction: 'down' as 'up' | 'down'
        },
      })
      $setUpdater(updated)

      setTimeout(() => {
        setReportIsDeprecated(false)
        setSearchButtonActive(false)
      }, 100)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  // Update settings from back page
  useEffect(() => {
    if ($router.router.getState().params.localInterface) {

      setLocalInterface({
        ...$router.router.getState().params.localInterface
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$router.router.getState().params])

  useEffect(() => {
    if (selectedSearch && reportData) {
      setIsLoaded(false)
      setReportData({
        ...reportData,
        payout_staff_trackers: []
      })
      // setAddTime(null)
      loadData()
      setReportIsDeprecated(false)
      setSearchButtonActive(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSearch])

  // Watch date range preset
  useEffect(() => {

    if (!reportData) return

    if (localInterface.dateRangePreset === 'custom') return

    !updated && setLocalInterface({
      ...localInterface,
      ...(getDateRangeByPreset(user, localInterface.dateRangePreset, reportData.interface.min_date, reportData.interface.max_date))
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.dateRangePreset])

  function handleShowAddTimeFields() {
    setAddTime({
      ...initialAddTime,
      staff_id: selectedSearch
    })
  }

  return (
    <>
      {
        reportData ?
          <div className="ReportsPage_List">
            { /* Page header */}
            <div className="page-header">
              <h1>Time Tracking</h1>
            </div>

            { /* List filters */}
            <ReportFilters
              onSearchInputChange={(value) => setLocalInterface({ ...localInterface, search: value })}
              searchInput={localInterface.search}
              searchButtonActive={searchButtonActive}

              selectSearch={true}
              selectedSearch={selectedSearch}
              setSelectedSearch={setSelectedSearch}
              selectOptions={reportData.permissions.staff_tracker_report_show ?
                reportData.edit.staff.map(item => {
                  return {
                    span: item.nickname,
                    value: item.staff_id
                  }
                }) : undefined}

              dateRangePreset={localInterface.dateRangePreset}
              onDateRangePresetChange={(value) => setLocalInterface({ ...localInterface, dateRangePreset: value, page: 1 })}

              minDate={localInterface.min_date}
              onMinDateChange={(value) => setLocalInterface({ ...localInterface, min_date: value, dateRangePreset: 'custom', page: 1 })}

              maxDate={localInterface.max_date}
              onMaxDateChange={(value) => setLocalInterface({ ...localInterface, max_date: value, dateRangePreset: 'custom', page: 1 })}

              updateButtonActive={reportIsDeprecated}
              onUpdate={() => $setUpdater(Math.random())}

              phoneCall={phoneCall}
              navActive={navActive.is}
            />

            <ReportTableControls
              zIndex={5}

              onMaxRowsChange={() => {
                $setUpdater(Math.random());
              }}

              amount={{
                total: reportData && reportData.interface.rows_all ? reportData.interface.rows_all : 0,
                start: reportData && reportData.interface.rows_start ? reportData.interface.rows_start : 0,
                end: reportData && reportData.interface.rows_end ? reportData.interface.rows_end : 0
              }}

              page={localInterface.page}
              maxPages={reportData && reportData.interface.max_pages ? reportData.interface.max_pages : 0}
              onPagesStart={() => {
                setLocalInterface({ ...localInterface, page: 1 })
                $setUpdater(Math.random())
              }}
              onPrevPage={() => {
                setLocalInterface({ ...localInterface, page: localInterface.page - 1 })
                $setUpdater(Math.random())
              }}
              onNextPage={() => {
                setLocalInterface({ ...localInterface, page: localInterface.page + 1 })
                $setUpdater(Math.random())
              }}
              onPagesEnd={() => {
                setLocalInterface({ ...localInterface, page: reportData ? reportData.interface.max_pages : 0 })
                $setUpdater(Math.random())
              }}

              sort={localInterface.sort}
              sortFields={localInterface.sortFields}
              onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
              onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
              onSortFire={() => $setUpdater(Math.random())}

              isSort={true}

              addButton={
                ((!reportData.permissions.staff_tracker_add && !!reportData.permissions.staff_tracker_add_own) ||
                  (selectedSearch && !!reportData.permissions.staff_tracker_add)) ?
                  (
                    <div className="add-button-wrapper">
                      <button
                        className="_iconed _rounded add-button"
                        disabled={!!addTime}
                        onClick={() => handleShowAddTimeFields()}
                      >
                        <Icon icon="plus-thin" />
                        <span>Add Line</span>
                        <Icon icon="time-16" />
                      </button>
                    </div>
                  ) : <></>
              }
            />

            <div className="contents">
              {
                !!addTime &&
                <>
                  <div className="add-tracking">
                    <div className="__left">
                      <div className="field">
                        <span> Date: </span>

                        <Datetime
                          dateFormat="MM/DD/YYYY"
                          timeFormat="hh:mm A"
                          value={addTime.date}
                          onChange={(value) => setAddTime({ ...addTime, date: moment.isMoment(value) ? value.toDate() : addTime.date })}
                        />
                      </div>

                      <div className="field">
                        <span> Note: </span>
                        <input
                          type="text"
                          value={addTime.note}
                          onChange={(event) => setAddTime({ ...addTime, note: event.target.value })}
                        />
                      </div>
                    </div>

                    <div className="__right">
                      <div className="field">
                        <span> Type: </span>
                        <SelectComponent
                          options={['start', 'pause', 'stop', 'comment'].map((type) => ({
                            span: type,
                            value: type,
                          }))} selectedOption={addTime.type}
                          onChange={(value) => setAddTime({ ...addTime, type: value as string })}
                        />
                      </div>

                    </div>
                  </div>

                  <div className="add-time-btns">
                    <button
                      className="_bordered _red"
                      style={{ marginRight: '20px' }}
                      disabled={loading}
                      onClick={() => setAddTime(null)}
                    >
                      Cancel
                    </button>

                    <button
                      className="_bordered _green"
                      disabled={loading || addTime.type === ''}
                      onClick={() => handleAddTime()}
                    >
                      Save
                    </button>
                  </div>
                </>
              }
              {
                ((selectedSearch && reportData.permissions.staff_tracker_add) ||
                  (reportData?.permissions.staff_tracker_report_show_own && reportData.permissions.staff_tracker_add_own && !!reportData.interface.is_staff)) && isLoaded &&
                <div className="tracking-action">
                  <div className="note">
                    <input
                      type="text"
                      value={note}
                      onChange={(event) => setNote(event.target.value)}
                    />
                  </div>

                  <div className="btns">
                    <button
                      className="_bordered _green"
                      disabled={loading || activeStatus === 'start'}
                      onClick={() => handleSendType('start')}
                    >
                      Start
                    </button>

                    <button
                      className="_bordered _blue"
                      disabled={loading || activeStatus === '' || activeStatus === 'pause' || activeStatus === 'stop'}
                      onClick={() => handleSendType('pause')}
                    >
                      Pause
                    </button>

                    <button
                      className="_bordered _red"
                      disabled={loading || activeStatus === '' || activeStatus === 'stop'}
                      onClick={() => handleSendType('stop')}
                    >
                      Stop
                    </button>

                    <button
                      className="_bordered _blue"
                      disabled={loading}
                      onClick={() => handleSendType('comment')}
                    >
                      Comment
                    </button>
                  </div>
                </div>
              }

              <div className="tracking-content">
                {
                  (reportData?.permissions.staff_tracker_report_show ||
                    reportData?.permissions.staff_tracker_report_show_own) &&
                  reportData?.payout_staff_trackers.map((item, index) => (
                    <div
                      key={index}
                      className={classNames('tracking-content-row', {
                        _can_edit: (!reportData.permissions.staff_tracker_add && !!reportData.permissions.staff_tracker_add_own) ||
                          (!!reportData.permissions.staff_tracker_add),
                        _deleted: !!item.deleted_at
                      })}
                    >
                      <div
                        className="tracking-content-items"
                      >
                        <div
                          className="tracking-content-item"
                        >

                          <span
                            className={classNames('__date', { __red: !item.is_staff })}
                            onMouseEnter={() => !!item.deleted_at && setHover(`${item.payout_staff_tracker_id}_date`)}
                            onMouseLeave={() => setHover('')}
                          >
                            {
                              dateToInfoBlock('dd MMM yyyy ccc hh:mma', reportData.account.time_zone, item.date)
                            }

                            {
                              `${item.payout_staff_tracker_id}_date` === hover &&
                              <span className="hover-title">
                                {dateToInfoBlock('dd MMM yyyy ccc hh:mma', reportData.account.time_zone, item.deleted_at)}
                              </span>
                            }
                          </span>

                          {
                            !item.is_staff &&
                            <span
                              className="__red"
                              style={{ fontWeight: 'normal' }}
                              onMouseEnter={() => !!item.deleted_at && setHover(`${item.payout_staff_tracker_id}_status`)}
                              onMouseLeave={() => setHover('')}
                            >
                              (Administrator)

                              {
                                `${item.payout_staff_tracker_id}_status` === hover &&
                                <span className="hover-title">
                                  {dateToInfoBlock('dd MMM yyyy ccc hh:mma', reportData.account.time_zone, item.deleted_at)}
                                </span>
                              }
                            </span>
                          }
                          {' / '}
                          <span
                            className={classNames(
                              {
                                __green: item.type === 'start',
                                __yellow: item.type === 'pause',
                                __red: item.type === 'stop',
                                __blue: item.type === 'comment'
                              }
                            )}
                            onMouseEnter={() => !!item.deleted_at && setHover(`${item.payout_staff_tracker_id}_type`)}
                            onMouseLeave={() => setHover('')}
                          >
                            {item.type}

                            {
                              `${item.payout_staff_tracker_id}_type` === hover &&
                              <span className="hover-title">
                                {dateToInfoBlock('dd MMM yyyy ccc hh:mma', reportData.account.time_zone, item.deleted_at)}
                              </span>
                            }
                          </span>
                          {' / '}
                          <span
                            onMouseEnter={() => !!item.deleted_at && setHover(`${item.payout_staff_tracker_id}_note`)}
                            onMouseLeave={() => setHover('')}
                          >
                            {item.note}

                            {
                              `${item.payout_staff_tracker_id}_note` === hover &&
                              <span className="hover-title">
                                {dateToInfoBlock('dd MMM yyyy ccc hh:mma', reportData.account.time_zone, item.deleted_at)}
                              </span>
                            }
                          </span>

                          {
                            !!item.is_manual &&
                            <>
                              {' / '}
                              <span
                                className="__green"
                                style={{ position: 'relative' }}
                                onMouseEnter={() => !item.deleted_at && setHover(`${item.payout_staff_tracker_id}_manual`)}
                                onMouseLeave={() => setHover('')}
                              >
                                [Manual Added]

                                {
                                  `${item.payout_staff_tracker_id}_manual` === hover &&
                                  <span className="hover-title">
                                    {dateToInfoBlock('dd MMM yyyy ccc hh:mma', reportData.account.time_zone, item.created_at)}
                                  </span>
                                }
                              </span>
                            </>
                          }
                        </div>
                        {
                          !item.is_updated_by_staff && !!item.deleted_at &&
                          <div>
                            {' / '}
                            <span
                              className="__red"
                              style={{ fontWeight: 'normal' }}
                            >
                              (Administrator)
                            </span>
                          </div>
                        }

                      </div>

                      {
                        !item.payout_staff_id &&
                        !item.deleted_at &&
                        ((!reportData.permissions.staff_tracker_add && !!reportData.permissions.staff_tracker_add_own) ||
                          (!!reportData.permissions.staff_tracker_add)) &&
                        <button
                          className="_zeroed _iconed _red"
                          onClick={() => setDeleteTracker(item.payout_staff_tracker_id)}
                        >
                          <Icon icon="x-mark-1" />
                        </button>
                      }
                    </div>
                  ))
                }
              </div>
            </div>

            <ReportTableControls
              zIndex={5}

              onMaxRowsChange={() => {
                $setUpdater(Math.random());
              }}

              amount={{
                total: reportData.interface.rows_all ? reportData.interface.rows_all : 0,
                start: reportData.interface.rows_start ? reportData.interface.rows_start : 0,
                end: reportData.interface.rows_end ? reportData.interface.rows_end : 0
              }}

              page={localInterface.page}
              maxPages={reportData.interface.max_pages ? reportData.interface.max_pages : 0}
              onPagesStart={() => {
                setLocalInterface({ ...localInterface, page: 1 })
                $setUpdater(Math.random())
              }}
              onPrevPage={() => {
                setLocalInterface({ ...localInterface, page: localInterface.page - 1 })
                $setUpdater(Math.random())
              }}
              onNextPage={() => {
                setLocalInterface({ ...localInterface, page: localInterface.page + 1 })
                $setUpdater(Math.random())
              }}
              onPagesEnd={() => {
                setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })
                $setUpdater(Math.random())
              }}

              sort={localInterface.sort}
              sortFields={localInterface.sortFields}
              onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
              onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
              onSortFire={() => $setUpdater(Math.random())}

              isSort={true}
            />

            {!!deleteTracker && (
              <div className="popup item-delete-popup">

                <div style={{ maxWidth: '350px', padding: '20px' }} className="wrapper" onClick={(e) => e.stopPropagation()}>
                  <div
                    className="title"
                    style={{
                      background: '#EB5757',
                      color: '#fff',
                      fontSize: '16pt',
                      padding: '7px 12px',
                      margin: '0 -20px',
                      textAlign: 'center'
                    }}
                  >
                    <p style={{ margin: '0' }}>
                      Delete Line
                    </p>
                  </div>

                  <div
                    className="buttons"
                    style={{
                      display: 'flex',
                      gridGap: '10px',
                      gap: '10px',
                      marginTop: '30px',
                      justifyContent: 'space-around'
                    }}
                  >
                    <button
                      className="_wa _iconed _bordered _green"
                      onClick={() => setDeleteTracker('')}
                    >
                      <span>Cancel</span>
                    </button>

                    <button
                      className="_wa _iconed _bordered _red"
                      onClick={() => handleRemoveTracker()}
                    >
                      <span>Delete</span>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div> : null
      }
    </>
  )
}