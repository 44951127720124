import classNames from 'classnames'
import React from 'react'

import Icon from '../../components/Icon'
import { getSchuduleTime } from '../../funcs'

import { OtherAppointmentsProps } from '../Jobs/ItemTech'

interface PageProps {
  switchPage: (name: string) => void
  data: OtherAppointmentsProps[]
}

export default function OtherAppointments({ switchPage, data }: PageProps) {
  return (
    <div className="EstimatePage_Add entity-edit">
      <div className="wrapper flex-container sb">
        <div className='row'>
          <button
            style={{ background: 'inherit' }}
            className="_wa"
            onClick={() => switchPage('main')}
          >
            <Icon
              style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#005eb5' }}
              viewBox="0 0 24 24"
              icon="arrow-25"
            />
          </button>

          <h1 style={{ whiteSpace: 'nowrap', marginTop: '0', color: '#005eb5' }}>Other Appointments</h1>
        </div>
      </div>

      <div className='line-items-content service'>
        {
          data.map(item => (
            <div className='other-appointments-items'>
              <div
                className={classNames('type',
                  {
                    SC: item.appointment_type && item.appointment_type === 'SC',
                    RC: item.appointment_type && item.appointment_type === 'RC',
                    FU: item.appointment_type && item.appointment_type === 'FU',
                  }
                )}
              >
                {item.appointment_type}
              </div>

              <div className='time'>
                {getSchuduleTime(item.appointment_start, item.appointment_finish, item.time_zone)}
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}
