import React, { useRef, useEffect, useState } from "react";
// import Draggable, { DraggableCore } from 'react-draggable';
import moment from "moment";
import { DateTime } from "luxon";
import classNames from 'classnames'

import CancelAppointment from './CancelAppointment'
import RescheduleAppointPopup from './RescheduleAppoint'
import DragAppointment from './DragAppointment'
import ChangeAbsence from './ChangeAbsence'
import CancelAbsence from './CancelAbsence'
import ReserveBlock from './ReserveBlock'
import SendNotification from "./SendNotification";
import Icon from "../../components/Icon"
import WorkerInfo from "./WorkerInfo";
import PopupInfo from "./PopupInfo";

import CofeeSvg from '../../images/coffee-cup.svg'

import { dateToInfoBlock } from "../../funcs";

import { PermissionsProps, ScheduleDataProps, TimesProps } from "./Schedule";

export interface ChangeAbsenceProps {
  absence_end: string
  absence_start: string
  absences_id: string
  service_resource_nickname: string
  service_resource_code: number | null
}

export interface ChangeAppointProps {
  appointment_date_end: string
  appointment_date_start: string
  appointment_id: string
  appointment_job_number: string
  service_resource_nickname: string
  service_resource_code: number | null
}

export interface RescheduleAppoint {
  appointment_date_end: string
  appointment_date_start: string
  appointment_id: string
  appointment_job_number: string
  service_resource_nickname: string
  service_resource_code: number | null
  service_resource_id: string
  service_resource_select: {
    service_resource_nickname: string
    service_resource_code: number | null
    service_resource_id: string
  }[]
}

export interface sendNotificationAppoint {
  appointment_id: string
}

interface GantChartProps {
  dateRangeType: string,
  minDate: string,
  arrTimesGrid: TimesProps[],
  arrTimesDayGrid: string[],
  navActive: boolean
  scheduleData: ScheduleDataProps[]
  setScheduleData: (value: ScheduleDataProps[] | []) => void,
  activeAccountId: string | null,
  handleSend: (id: string, setId: (val: sendNotificationAppoint | null) => void) => void
  permissions: PermissionsProps
  isSending: boolean
}

export default function GantChart({
  dateRangeType,
  minDate,
  arrTimesGrid,
  arrTimesDayGrid,
  navActive,
  scheduleData,
  setScheduleData,
  activeAccountId,
  handleSend,
  permissions,
  isSending,
}: GantChartProps) {
  let tableRef = useRef<HTMLDivElement>(null)
  let cellRef = useRef<HTMLDivElement>()
  let asideRef = useRef<HTMLDivElement>(null)
  // const draggableRef = useRef<DraggableCore>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updatedTime, setUpdatedTime] = useState(0)
  const [buttonLeftOption, setButtonLeftOption] = useState(0)
  const [showDate, setShowDate] = useState<string[]>([])
  const [showAreas, setShowAreas] = useState({})
  const [showDropList, setShowDropList] = useState({})
  const [hoverAppointment, setHoverAppointment] = useState('')
  const [hoverPositionY, setHoverPositionY] = useState(0)
  const [hoverAbsence, setHoverAbsence] = useState('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cancelAppoint, setCancelAppoint] = useState<ChangeAppointProps | null>(null)
  const [rescheduleAppoint, setRescheduleAppoint] = useState<RescheduleAppoint | null>(null)
  const [sendNotificationAppoint, setSendNotificationAppoint] = useState<sendNotificationAppoint | null>(null)
  const [changeAbsence, setChangeAbsence] = useState<ChangeAbsenceProps | null>(null)
  const [cancelAbsence, setCancelAbsence] = useState<ChangeAbsenceProps | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [drag, setDrag] = useState({
    idTo: '',
    nameTo: '',
  })
  const [showPopup, setShowPopup] = useState('')
  const [dragAppoint, setDragAppoint] = useState({
    appointment_job_number: '',
    appointment_date_start: '',
    appointment_date_start_to: '',
    appointment_date_end: '',
    appointment_date_end_to: '',
    appointment_id: null,
    appointment_update_time: '',
    appointment_service_resource_id: '',
    nameFrom: '',
    nameTo: '',
    appointment_info: '',
    appointment_notification: false,
    appointment_type: "",
    appointment_zip: "",
    timeZone: '',
  })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [draging, setDraging] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [controlledPosition, setControlledPosition] = useState({})
  const [cellWidth, setCellWidth] = useState(0)

  useEffect(() => {
    let objShow = {}
    scheduleData.forEach((item, index) => {
      objShow[index] = true
    })
    setShowAreas(objShow)
  }, [scheduleData])

  useEffect(() => {
    let timeInterval = setInterval(() => {
      setUpdatedTime(Math.random())
    }, 10000)

    return () => clearInterval(timeInterval)
  }, [])

  const resizeCell = () => {
    let { width } = document.querySelector('.timegrid__cell')?.getBoundingClientRect() || {};
    width && setCellWidth(width)
  };

  const resizeNav = () => {
    let { width } = tableRef?.current?.querySelector('.timegrid__cell')?.getBoundingClientRect() || {};

    width && setCellWidth(width)
  };

  useEffect(() => {
    resizeCell()
  }, [arrTimesGrid, arrTimesDayGrid, scheduleData])

  useEffect(() => {
    resizeNav()
  }, [navActive])

  useEffect(() => {
    window.addEventListener("resize", resizeCell);
    resizeCell();
    return () => {
      window.removeEventListener("resize", resizeCell);
    };
  }, []);

  useEffect(() => {
    if (dateRangeType === 'one') {
      setShowDate([minDate])
    } else if (dateRangeType === 'two') {
      let nextDay = moment(minDate, "ddd, MMM DD YYYY").add(1, 'day').format("ddd, MMM DD YYYY")
      setShowDate([minDate, nextDay])
    } else {
      let nextDay = moment(minDate, "ddd, MMM DD YYYY").add(1, 'day').format("ddd, MMM DD YYYY")
      let lastDay = moment(minDate, "ddd, MMM DD YYYY").add(2, 'days').format("ddd, MMM DD YYYY")

      setShowDate([minDate, nextDay, lastDay])
    }
  }, [dateRangeType, minDate])

  function getClassForGrid(id: string, time_slot: string, idx: number) {
    let class_name = 'timegrid__cell'
    let indexDate = idx < arrTimesDayGrid.length - 1 ? 0 : idx < (arrTimesDayGrid.length - 1) * 2 ? 1 : 2
    scheduleData.forEach(area => {
      area.service_resources.forEach(service => {
        if (service.service_resource_id === id) {
          service.time_slots.forEach(item => {
            let start = DateTime.fromISO(item.time_slot_start, { setZone: true }).toFormat('HH:mm')
            let startDay = DateTime.fromISO(item.time_slot_start, { setZone: true }).toFormat('ccc, LLL dd yyyy')
            let end = DateTime.fromISO(item.time_slot_end, { setZone: true }).toFormat('HH:mm')
            let endDay = DateTime.fromISO(item.time_slot_end, { setZone: true }).toFormat('ccc, LLL dd yyyy')

            if (end === '00:00') {
              end = '24:00'
            }

            if (
              (showDate[indexDate] === startDay || showDate[indexDate] === endDay) &&
              start <= time_slot &&
              time_slot < end
            ) {
              class_name += ` timegrid__cell_${item.time_slot_type}`
            }
          })
          service.additional_times.forEach(item => {
            let start = DateTime.fromISO(item.additional_time_start, { setZone: true }).toFormat('HH:mm')
            let startDay = DateTime.fromISO(item.additional_time_start, { setZone: true }).toFormat('ccc, LLL dd yyyy')
            let end = DateTime.fromISO(item.additional_time_end, { setZone: true }).toFormat('HH:mm')
            let endDay = DateTime.fromISO(item.additional_time_end, { setZone: true }).toFormat('ccc, LLL dd yyyy')

            if (end === '00:00') {
              end = '24:00'
            }

            if (
              (showDate[indexDate] === startDay || showDate[indexDate] === endDay) &&
              start <= time_slot &&
              time_slot < end
            ) {
              class_name += ` timegrid__cell_additional_time`
            }
          })
        }
      })
    })
    return class_name
  }

  function getPositionAppUpdate(appointmentsOneDay: { start: string, end: string, id: string }[], is_height: boolean, appoint_id?: string) {
    let obj = {}
    let myObj = {}

    if (appointmentsOneDay.length > 1) {
      appointmentsOneDay.sort((a, b) => a.start.localeCompare(b.start))

      appointmentsOneDay.reduce((acc, item) => {
        if (acc === 0) {
          obj[0] = {
            startDate: item.start,
            endDate: item.end,
            floor: 0
          }
          myObj[0] = {
            startDate: item.start,
            endDate: item.end,
            floor: 0
          }
          acc = acc + 1
        } else {
          if (acc === 1) {
            if (item.start >= obj[0].endDate) {
              obj[acc] = {
                startDate: item.start,
                endDate: item.end,
                floor: obj[acc - 1].floor
              }
              myObj[acc] = {
                startDate: item.start,
                endDate: item.end,
                floor: obj[acc - 1].floor
              }
            } else {
              obj[acc] = {
                startDate: item.start,
                endDate: item.end,
                floor: obj[acc - 1].floor + 1
              }
              myObj[acc] = {
                startDate: item.start,
                endDate: item.end,
                floor: obj[acc - 1].floor + 1
              }
            }
          } else {
            const arrOneFloor: { startDate: string, endDate: string, floor: number }[] = []
            const checkArr: { startDate: string, endDate: string, floor: number }[] = Object.values(obj)
            const checkArrReverse = checkArr.reverse()

            checkArrReverse.forEach(check => {
              if (arrOneFloor.length === 0 || arrOneFloor.filter(it => it.floor === check.floor).length === 0) {
                arrOneFloor.push(check)
              }
            })

            const arr = arrOneFloor.sort((a, b) => a.floor < b.floor ? -1 : a.floor > b.floor ? 1 : 0)

            let addItem = false

            arr.forEach((objectItem, idx) => {
              if (!addItem && item.start >= objectItem.endDate) {
                addItem = true
                obj[acc] = {
                  startDate: item.start,
                  endDate: item.end,
                  floor: objectItem.floor
                }
                myObj[acc] = {
                  startDate: item.start,
                  endDate: item.end,
                  floor: objectItem.floor
                }
              } else if (!addItem && idx === arr.length - 1) {
                addItem = true
                obj[acc] = {
                  startDate: item.start,
                  endDate: item.end,
                  floor: objectItem.floor + 1
                }
                myObj[acc] = {
                  startDate: item.start,
                  endDate: item.end,
                  floor: objectItem.floor + 1
                }
              }
            })
          }
          acc = acc + 1
        }
        return acc
      }, 0)
    }
    let result = 0

    if (is_height) {
      let indexApp = appointmentsOneDay.findIndex(x => x.id === appoint_id)
      if (indexApp !== -1 && Object.keys(obj).length) {
        if (Object.keys(obj).length - 1 < indexApp) {
          result = 0
        } else {
          result = obj[indexApp].floor
        }
      }
    } else {
      Object.keys(obj).forEach(item => {
        if (obj[item].floor >= result) {
          result = obj[item].floor + 1
        }
      })
    }

    return result
  }

  function getpositionAppointment(areaId: string, id: string, day: string, appointId: string) {
    let points: { start: string, end: string, id: string }[] = []
    scheduleData.forEach((area, areaIndex) => {
      if (area.area_id === areaId) {
        area.service_resources.forEach(service => {
          if (service.service_resource_id === id) {

            service.absences.forEach(absence => {
              let startFormatDay = dateToInfoBlock('ccc, LLL dd yyyy', area.area_timezone, absence.absence_start)
              let endFormatDay = dateToInfoBlock('ccc, LLL dd yyyy', area.area_timezone, absence.absence_end)

              if (
                moment(day).isBetween(moment(startFormatDay), moment(endFormatDay))
                || moment(day).isSame(moment(startFormatDay), 'day')
                || moment(day).isSame(moment(endFormatDay), 'day')) {

                let isSameDaysStart = moment(day).diff(moment(startFormatDay).startOf('day')) >= 0
                let isSameDaysEnd = moment(day).diff(moment(endFormatDay).startOf('day')) <= 0
                if (
                  moment(day).isSame(moment(startFormatDay), 'day')
                  && moment(day).isSame(moment(endFormatDay), 'day')
                ) {
                  points.push({
                    start: dateToInfoBlock('HH:mm', area.area_timezone, absence.absence_start),
                    end: dateToInfoBlock('HH:mm', area.area_timezone, absence.absence_end),
                    id: absence.absence_id
                  })
                } else if (isSameDaysStart && moment(day).isSame(moment(endFormatDay), 'day')) {
                  points.push({
                    start: arrTimesDayGrid[0],
                    end: dateToInfoBlock('HH:mm', area.area_timezone, absence.absence_end),
                    id: absence.absence_id
                  })
                } else if (isSameDaysEnd && moment(day).isSame(moment(startFormatDay), 'day')) {
                  points.push({
                    start: dateToInfoBlock('HH:mm', area.area_timezone, absence.absence_start),
                    end: arrTimesDayGrid[arrTimesDayGrid.length - 1],
                    id: absence.absence_id
                  })
                } else {
                  points.push({
                    start: arrTimesDayGrid[0],
                    end: arrTimesDayGrid[arrTimesDayGrid.length - 1],
                    id: absence.absence_id
                  })
                }
              }
            })

            service.absence_time_slots.forEach((absence, absenceIndex) => {
              DateTime.fromISO(absence.time_slot_start, { setZone: true }).toFormat("HH:mm")
              let startFormatDay = DateTime.fromISO(absence.time_slot_start, { setZone: true }).toFormat("ccc, LLL dd yyyy")
              let endFormatDay = DateTime.fromISO(absence.time_slot_end, { setZone: true }).toFormat("ccc, LLL dd yyyy")

              if (
                moment(day).isBetween(moment(startFormatDay), moment(endFormatDay))
                || moment(day).isSame(moment(startFormatDay), 'day')
                || moment(day).isSame(moment(endFormatDay), 'day')) {

                let isSameDaysStart = moment(day).diff(moment(startFormatDay).startOf('day')) >= 0
                let isSameDaysEnd = moment(day).diff(moment(endFormatDay).startOf('day')) <= 0
                if (
                  moment(day).isSame(moment(startFormatDay), 'day')
                  && moment(day).isSame(moment(endFormatDay), 'day')
                ) {
                  points.push({
                    start: DateTime.fromISO(absence.time_slot_start, { setZone: true }).toFormat("HH:mm"),
                    end: DateTime.fromISO(absence.time_slot_end, { setZone: true }).toFormat("HH:mm"),
                    id: `${areaIndex}_${absenceIndex}_${absence.absence_id}`
                  })
                } else if (isSameDaysStart && moment(day).isSame(moment(endFormatDay), 'day')) {
                  points.push({
                    start: arrTimesDayGrid[0],
                    end: DateTime.fromISO(absence.time_slot_end, { setZone: true }).toFormat("HH:mm"),
                    id: `${areaIndex}_${absenceIndex}_${absence.absence_id}`
                  })
                } else if (isSameDaysEnd && moment(day).isSame(moment(startFormatDay), 'day')) {
                  points.push({
                    start: DateTime.fromISO(absence.time_slot_start, { setZone: true }).toFormat("HH:mm"),
                    end: arrTimesDayGrid[arrTimesDayGrid.length - 1],
                    id: `${areaIndex}_${absenceIndex}_${absence.absence_id}`
                  })
                } else {
                  points.push({
                    start: arrTimesDayGrid[0],
                    end: arrTimesDayGrid[arrTimesDayGrid.length - 1],
                    id: `${areaIndex}_${absenceIndex}_${absence.absence_id}`
                  })
                }
              }
            })

            service.appointments.forEach(appoint => {
              let startFormatDay = dateToInfoBlock('ccc, LLL dd yyyy', area.area_timezone, appoint.appointment_date_start)
              let endFormatDay = dateToInfoBlock('ccc, LLL dd yyyy', area.area_timezone, appoint.appointment_date_end)

              if (
                moment(day).isBetween(moment(startFormatDay), moment(endFormatDay))
                || moment(day).isSame(moment(startFormatDay), 'day')
                || moment(day).isSame(moment(endFormatDay), 'day')
              ) {

                let isSameDaysStart = moment(day).diff(moment(startFormatDay).startOf('day')) >= 0
                let isSameDaysEnd = moment(day).diff(moment(endFormatDay).startOf('day')) <= 0

                if (
                  moment(day).isSame(moment(startFormatDay), 'day')
                  && moment(day).isSame(moment(endFormatDay), 'day')
                ) {

                  points.push({
                    start: dateToInfoBlock('HH:mm', area.area_timezone, appoint.appointment_date_start),
                    end: dateToInfoBlock('HH:mm', area.area_timezone, appoint.appointment_date_end),
                    id: appoint.appointment_id
                  })
                } else if (isSameDaysStart && moment(day).isSame(moment(endFormatDay), 'day')) {
                  points.push({
                    start: arrTimesDayGrid[0],
                    end: dateToInfoBlock('HH:mm', area.area_timezone, appoint.appointment_date_end),
                    id: appoint.appointment_id
                  })
                } else if (isSameDaysEnd && moment(day).isSame(moment(startFormatDay), 'day')) {
                  points.push({
                    start: dateToInfoBlock('HH:mm', area.area_timezone, appoint.appointment_date_start),
                    end: arrTimesDayGrid[arrTimesDayGrid.length - 1],
                    id: appoint.appointment_id
                  })
                } else {
                  points.push({
                    start: arrTimesDayGrid[0],
                    end: arrTimesDayGrid[arrTimesDayGrid.length - 1],
                    id: appoint.appointment_id
                  })
                }
              }
            })

            service.reserves.forEach((reserv, index) => {
              let startFormatDay = dateToInfoBlock('ccc, LLL dd yyyy', area.area_timezone, reserv.start)
              let endFormatDay = dateToInfoBlock('ccc, LLL dd yyyy', area.area_timezone, reserv.end)

              if (
                moment(day).isBetween(moment(startFormatDay), moment(endFormatDay))
                || moment(day).isSame(moment(startFormatDay), 'day')
                || moment(day).isSame(moment(endFormatDay), 'day')
              ) {
                let isSameDaysStart = moment(day).diff(moment(startFormatDay).startOf('day')) >= 0
                let isSameDaysEnd = moment(day).diff(moment(endFormatDay).startOf('day')) <= 0
                if (
                  moment(day).isSame(moment(startFormatDay), 'day')
                  && moment(day).isSame(moment(endFormatDay), 'day')
                ) {
                  points.push({
                    start: dateToInfoBlock('HH:mm', area.area_timezone, reserv.start),
                    end: dateToInfoBlock('HH:mm', area.area_timezone, reserv.end),
                    id: `${index}_reserve`
                  })
                } else if (isSameDaysStart && moment(day).isSame(moment(endFormatDay), 'day')) {
                  points.push({
                    start: arrTimesDayGrid[0],
                    end: dateToInfoBlock('HH:mm', area.area_timezone, reserv.end),
                    id: `${index}_reserve`
                  })
                } else if (isSameDaysEnd && moment(day).isSame(moment(startFormatDay), 'day')) {
                  points.push({
                    start: dateToInfoBlock('HH:mm', area.area_timezone, reserv.start),
                    end: arrTimesDayGrid[arrTimesDayGrid.length - 1],
                    id: `${index}_reserve`
                  })
                } else {
                  points.push({
                    start: arrTimesDayGrid[0],
                    end: arrTimesDayGrid[arrTimesDayGrid.length - 1],
                    id: `${index}_reserve`
                  })
                }
              }
            })
          }
        })
      }
    })

    let number = points.length ? getPositionAppUpdate(points, true, appointId) : 0

    return number
  }

  function getHeigthRow(areaId: string, id: string) {
    let points: { start: string, end: string, id: string }[] = []
    let number = 0
    showDate.forEach(day => {
      points = []
      scheduleData.forEach((area, indexArea) => {
        if (area.area_id === areaId) {
          area.service_resources.forEach(service => {
            if (service.service_resource_id === id) {
              service.absences.forEach(absence => {
                let startFormatDay = dateToInfoBlock('ccc, LLL dd yyyy', area.area_timezone, absence.absence_start)
                let endFormatDay = dateToInfoBlock('ccc, LLL dd yyyy', area.area_timezone, absence.absence_end)

                if (
                  moment(day).isBetween(moment(startFormatDay), moment(endFormatDay))
                  || moment(day).isSame(moment(startFormatDay), 'day')
                  || moment(day).isSame(moment(endFormatDay), 'day')
                ) {
                  let isSameDaysStart = moment(day).diff(moment(startFormatDay).startOf('day')) >= 0
                  let isSameDaysEnd = moment(day).diff(moment(endFormatDay).startOf('day')) <= 0
                  if (
                    moment(day).isSame(moment(startFormatDay), 'day')
                    && moment(day).isSame(moment(endFormatDay), 'day')
                  ) {
                    points.push({
                      start: dateToInfoBlock('HH:mm', area.area_timezone, absence.absence_start),
                      end: dateToInfoBlock('HH:mm', area.area_timezone, absence.absence_end),
                      id: absence.absence_id
                    })
                  } else if (isSameDaysStart && moment(day).isSame(moment(endFormatDay), 'day')) {
                    points.push({
                      start: arrTimesDayGrid[0],
                      end: dateToInfoBlock('HH:mm', area.area_timezone, absence.absence_end),
                      id: absence.absence_id
                    })
                  } else if (isSameDaysEnd && moment(day).isSame(moment(startFormatDay), 'day')) {
                    points.push({
                      start: dateToInfoBlock('HH:mm', area.area_timezone, absence.absence_start),
                      end: arrTimesDayGrid[arrTimesDayGrid.length - 1],
                      id: absence.absence_id
                    })
                  } else {
                    points.push({
                      start: arrTimesDayGrid[0],
                      end: arrTimesDayGrid[arrTimesDayGrid.length - 1],
                      id: absence.absence_id
                    })
                  }
                }
              })

              service.appointments.forEach(appoint => {
                let startFormatDay = dateToInfoBlock('ccc, LLL dd yyyy', area.area_timezone, appoint.appointment_date_start)
                let endFormatDay = dateToInfoBlock('ccc, LLL dd yyyy', area.area_timezone, appoint.appointment_date_end)

                if (
                  moment(day).isBetween(moment(startFormatDay), moment(endFormatDay))
                  || moment(day).isSame(moment(startFormatDay), 'day')
                  || moment(day).isSame(moment(endFormatDay), 'day')
                ) {
                  let startHours = +dateToInfoBlock('HH', area.area_timezone, appoint.appointment_date_start)
                  let endHours = dateToInfoBlock('HH', area.area_timezone, appoint.appointment_date_end) === '00' ? 24 : +dateToInfoBlock('HH', area.area_timezone, appoint.appointment_date_end)
                  let startHoursGrid = +arrTimesDayGrid[0].split(':')[0]
                  let endHoursGrid = +arrTimesDayGrid[arrTimesDayGrid.length - 1].split(':')[0]

                  if (((startHoursGrid < endHours) && (endHours < endHoursGrid)) || ((startHoursGrid < startHours) && (startHours < endHoursGrid))) {

                    let isSameDaysStart = moment(day).diff(moment(startFormatDay).startOf('day')) >= 0
                    let isSameDaysEnd = moment(day).diff(moment(endFormatDay).startOf('day')) <= 0
                    if (
                      moment(day).isSame(moment(startFormatDay), 'day')
                      && moment(day).isSame(moment(endFormatDay), 'day')
                    ) {
                      points.push({
                        start: dateToInfoBlock('HH:mm', area.area_timezone, appoint.appointment_date_start),
                        end: dateToInfoBlock('HH:mm', area.area_timezone, appoint.appointment_date_end),
                        id: appoint.appointment_id
                      })
                    } else if (isSameDaysStart && moment(day).isSame(moment(endFormatDay), 'day')) {
                      points.push({
                        start: arrTimesDayGrid[0],
                        end: dateToInfoBlock('HH:mm', area.area_timezone, appoint.appointment_date_end),
                        id: appoint.appointment_id
                      })
                    } else if (isSameDaysEnd && moment(day).isSame(moment(startFormatDay), 'day')) {
                      points.push({
                        start: dateToInfoBlock('HH:mm', area.area_timezone, appoint.appointment_date_start),
                        end: arrTimesDayGrid[arrTimesDayGrid.length - 1],
                        id: appoint.appointment_id
                      })
                    } else {
                      points.push({
                        start: arrTimesDayGrid[0],
                        end: arrTimesDayGrid[arrTimesDayGrid.length - 1],
                        id: appoint.appointment_id
                      })
                    }
                  }
                }
              })

              service.absence_time_slots.forEach((absence, index) => {
                let startFormatDay = DateTime.fromISO(absence.time_slot_start, { setZone: true }).toFormat("MMM dd yyyy")
                let endFormatDay = DateTime.fromISO(absence.time_slot_end, { setZone: true }).toFormat("MMM dd yyyy")

                if (
                  moment(day).isBetween(moment(startFormatDay), moment(endFormatDay))
                  || moment(day).isSame(moment(startFormatDay), 'day')
                  || moment(day).isSame(moment(endFormatDay), 'day')
                ) {
                  let isSameDaysStart = moment(day).diff(moment(startFormatDay).startOf('day')) >= 0
                  let isSameDaysEnd = moment(day).diff(moment(endFormatDay).startOf('day')) <= 0
                  if (
                    moment(day).isSame(moment(startFormatDay), 'day')
                    && moment(day).isSame(moment(endFormatDay), 'day')
                  ) {
                    points.push({
                      start: DateTime.fromISO(absence.time_slot_start, { setZone: true }).toFormat("HH:mm"),
                      end: DateTime.fromISO(absence.time_slot_end, { setZone: true }).toFormat("HH:mm"),
                      id: `${indexArea}_${index}_${absence.absence_id}`
                    })
                  } else if (isSameDaysStart && moment(day).isSame(moment(endFormatDay), 'day')) {
                    points.push({
                      start: arrTimesDayGrid[0],
                      end: DateTime.fromISO(absence.time_slot_end, { setZone: true }).toFormat("HH:mm"),
                      id: `${indexArea}_${index}_${absence.absence_id}`
                    })
                  } else if (isSameDaysEnd && moment(day).isSame(moment(startFormatDay), 'day')) {
                    points.push({
                      start: DateTime.fromISO(absence.time_slot_start, { setZone: true }).toFormat("HH:mm"),
                      end: arrTimesDayGrid[arrTimesDayGrid.length - 1],
                      id: `${indexArea}_${index}_${absence.absence_id}`
                    })
                  } else {
                    points.push({
                      start: arrTimesDayGrid[0],
                      end: arrTimesDayGrid[arrTimesDayGrid.length - 1],
                      id: `${indexArea}_${index}_${absence.absence_id}`
                    })
                  }
                }
              })

              service.reserves.forEach((reserv, index) => {
                let startFormatDay = dateToInfoBlock('ccc, LLL dd yyyy', area.area_timezone, reserv.start)
                let endFormatDay = dateToInfoBlock('ccc, LLL dd yyyy', area.area_timezone, reserv.end)

                if (
                  moment(day).isBetween(moment(startFormatDay), moment(endFormatDay))
                  || moment(day).isSame(moment(startFormatDay), 'day')
                  || moment(day).isSame(moment(endFormatDay), 'day')
                ) {
                  let isSameDaysStart = moment(day).diff(moment(startFormatDay).startOf('day')) >= 0
                  let isSameDaysEnd = moment(day).diff(moment(endFormatDay).startOf('day')) <= 0
                  if (
                    moment(day).isSame(moment(startFormatDay), 'day')
                    && moment(day).isSame(moment(endFormatDay), 'day')
                  ) {
                    points.push({
                      start: dateToInfoBlock('HH:mm', area.area_timezone, reserv.start),
                      end: dateToInfoBlock('HH:mm', area.area_timezone, reserv.end),
                      id: `${index}_reserve`
                    })
                  } else if (isSameDaysStart && moment(day).isSame(moment(endFormatDay), 'day')) {
                    points.push({
                      start: arrTimesDayGrid[0],
                      end: dateToInfoBlock('HH:mm', area.area_timezone, reserv.end),
                      id: `${index}_reserve`
                    })
                  } else if (isSameDaysEnd && moment(day).isSame(moment(startFormatDay), 'day')) {
                    points.push({
                      start: dateToInfoBlock('HH:mm', area.area_timezone, reserv.start),
                      end: arrTimesDayGrid[arrTimesDayGrid.length - 1],
                      id: `${index}_reserve`
                    })
                  } else {
                    points.push({
                      start: arrTimesDayGrid[0],
                      end: arrTimesDayGrid[arrTimesDayGrid.length - 1],
                      id: `${index}_reserve`
                    })
                  }
                }
              })
            }
          })
        }
      })
      if (points.length) {
        let getHeiht = getPositionAppUpdate(points, false)

        number = getHeiht > number ? getHeiht : number
      }
    })

    return number
  }

  function handleShowAreas(index: number) {
    setShowAreas({
      ...showAreas,
      [index]: !showAreas[index]
    })
  }

  function handleChange(event: any, id: string) {
    if (event.button === 2) {
      setButtonLeftOption(event.nativeEvent.layerX)
      event.nativeEvent.stopImmediatePropagation();
      event.preventDefault()
      event.stopPropagation()
      setShowDropList({
        [id]: true
      })
      setHoverAppointment('')
    }
  }

  // function eventHandlerStop(event: any, position: any, appointment: any, name: string, timeZone: string) {
  //   let { x, y } = position
  //   setDragAppoint({
  //     appointment_job_number: appointment.appointment_job_number,
  //     appointment_date_start: appointment.appointment_date_start,
  //     appointment_date_start_to: getNewTime(appointment.appointment_date_start, appointment.appointment_date_end, x, y, timeZone).startTo,
  //     appointment_date_end_to: getNewTime(appointment.appointment_date_start, appointment.appointment_date_end, x, y, timeZone).endTo,
  //     appointment_date_end: appointment.appointment_date_end,
  //     appointment_id: appointment.appointment_id,
  //     appointment_service_resource_id: drag.idTo === '' ? appointment.appointment_id : drag.idTo,
  //     appointment_update_time: getNewTime(appointment.appointment_date_start, appointment.appointment_date_end, x, y, timeZone).text,
  //     nameFrom: name,
  //     nameTo: drag.nameTo === '' ? name : drag.nameTo,
  //     appointment_info: appointment.appointment_info,
  //     appointment_notification: appointment.appointment_notification,
  //     appointment_type: appointment.appointment_type,
  //     appointment_zip: appointment.appointment_zip,
  //     timeZone: timeZone,
  //   })

  //   setDraging(false)
  // }

  // function handleDrag(event: any, position: any, appointment: any, name: string) {
  //   let { x, y } = position

  //   if (event.target.closest('div.board__row')) {
  //     let id = event.target.closest('div.board__row').querySelector('.board__worker-nickname').id
  //     let nameTo = event.target.closest('div.board__row').querySelector('.board__worker-nickname').innerHTML || ''

  //     setControlledPosition({ [appointment.appointment_id]: { x, y } })
  //     setDrag({
  //       idTo: controlledPosition[appointment.appointment_id] && controlledPosition[appointment.appointment_id].y === y ? drag.idTo ? drag.idTo : id : id,
  //       nameTo: controlledPosition[appointment.appointment_id] && controlledPosition[appointment.appointment_id].y === y ? drag.nameTo : nameTo,
  //     })
  //   }
  // }

  // function getNewTime(timeStart: string, timeEnd: string, toX: number, toY: string, timeZone: string) {
  //   let startFrom = dateToInfoBlock('HH', timeZone, timeStart)
  //   let startFromMin = dateToInfoBlock('mm', timeZone, timeStart)
  //   let endFrom = dateToInfoBlock('HH', timeZone, timeEnd)
  //   let endFromMin = dateToInfoBlock('mm', timeZone, timeEnd)

  //   if (startFromMin < '30') {
  //     startFrom = `${dateToInfoBlock('HH', timeZone, timeStart)}:00`
  //   } else if (startFromMin > '30') {
  //     startFrom = `${dateToInfoBlock('HH', timeZone, timeStart)}:30`
  //   } else {
  //     startFrom = dateToInfoBlock('HH:mm', timeZone, timeStart)
  //   }

  //   if (endFromMin < '30') {
  //     endFrom = `${dateToInfoBlock('HH', timeZone, timeEnd)}:00`
  //   } else if (endFromMin > '30') {
  //     endFrom = `${moment(timeEnd).format('HH')}:30`
  //   } else {
  //     endFrom = dateToInfoBlock('HH:mm', timeZone, timeEnd)
  //   }

  //   let startDateIndex = showDate.indexOf(dateToInfoBlock('ccc, LLL dd yyyy', timeZone, timeStart))
  //   let endDateIndex = showDate.indexOf(dateToInfoBlock('ccc, LLL dd yyyy', timeZone, timeEnd))
  //   let indexDateStart = 0
  //   let indexDateEnd = 0

  //   let indexStartFrom = arrTimesDayGrid.indexOf(startFrom) + (startDateIndex * (arrTimesDayGrid.length - 1))
  //   let indexEndFrom = arrTimesDayGrid.indexOf(endFrom) + (endDateIndex * (arrTimesDayGrid.length - 1))

  //   let num = document.getElementsByClassName('timegrid__row')[0]?.children[0].getBoundingClientRect()?.width
  //   let evenNotEven = toX / num % 2 === 0

  //   let indexStartTo = Math.floor(indexStartFrom + (toX / num))
  //   let indexEndTo = Math.floor(indexEndFrom + (toX / num))
  //   let startTo = ''
  //   let endTo = ''
  //   let dateTo = ''
  //   let startAppointmentTo = ''
  //   let endAppointmentTo = ''

  //   if (dateRangeType === 'one') {
  //     startTo = arrTimesDayGrid[indexStartTo]
  //     endTo = arrTimesDayGrid[indexEndTo]
  //     dateTo = moment(showDate[indexDateEnd]).format('D MMM')

  //     if (startFromMin < '30') {
  //       startTo = `${startTo.substring(0, 2)}:${evenNotEven ? startFromMin : +startFromMin + 30}`
  //     } else if (startFromMin > '30') {
  //       startTo = `${startTo.substring(0, 2)}:${evenNotEven ? startFromMin : +startFromMin - 30}`
  //     } else {
  //       startTo = arrTimesDayGrid[indexStartTo]
  //     }

  //     if (endFromMin < '30') {
  //       endTo = `${endTo.substring(0, 2)}:${evenNotEven ? endFromMin : +endFromMin + 30}`
  //     } else if (endFromMin > '30') {
  //       endTo = `${endTo.substring(0, 2)}:${evenNotEven ? endFromMin : +endFromMin - 30}`
  //     } else {
  //       endTo = arrTimesDayGrid[indexEndTo]
  //     }

  //     startAppointmentTo = `${moment(showDate[indexDateStart]).format('MMMM D, YYYY')} ${startTo}:00`
  //     endAppointmentTo = `${moment(showDate[indexDateEnd]).format('MMMM D, YYYY')} ${endTo}:00`
  //   }
  //   if (dateRangeType === 'two') {
  //     if ((indexStartTo <= arrTimesDayGrid.length - 1) === (indexEndTo <= arrTimesDayGrid.length - 1)) {
  //       let endDateStart = indexStartTo < arrTimesDayGrid.length - 1 ? 0 : 1
  //       startTo = arrTimesDayGrid[indexStartTo <= arrTimesDayGrid.length - 1 ?
  //         indexStartTo + endDateStart :
  //         indexStartTo + 1 - arrTimesDayGrid.length]

  //       endTo = arrTimesDayGrid[indexEndTo <= arrTimesDayGrid.length - 1 ?
  //         indexEndTo :
  //         indexEndTo + 1 - arrTimesDayGrid.length]

  //       dateTo = moment(showDate[indexEndTo <= arrTimesDayGrid.length - 1 ? 0 : 1]).format('D MMM')
  //       startAppointmentTo = `${moment(showDate[indexStartTo < arrTimesDayGrid.length - 1 ? 0 : 1]).format('MMMM D, YYYY')} ${startTo ? startTo : arrTimesDayGrid[0]}:00`
  //       endAppointmentTo = `${moment(showDate[indexEndTo < arrTimesDayGrid.length - 1 ? 0 : 1]).format('MMMM D, YYYY')} ${endTo}:00`

  //     } else {
  //       if (toX < 0) {
  //         startTo = arrTimesDayGrid[indexStartTo <= arrTimesDayGrid.length - 1 ?
  //           0 :
  //           arrTimesDayGrid.length - 1]

  //         endTo = arrTimesDayGrid[indexEndTo <= arrTimesDayGrid.length - 1 ?
  //           indexEndTo :
  //           indexEndTo + 1 - arrTimesDayGrid.length]

  //         dateTo = moment(showDate[indexEndTo <= arrTimesDayGrid.length - 1 ? 0 : 1]).format('D MMM')
  //         startAppointmentTo = `${moment(showDate[indexEndTo < arrTimesDayGrid.length - 1 ? 0 : 1]).format('MMMM D, YYYY')} ${arrTimesDayGrid[0]}:00`
  //         endAppointmentTo = `${moment(showDate[indexEndTo < arrTimesDayGrid.length - 1 ? 0 : 1]).format('MMMM D, YYYY')} ${endTo}:00`
  //       } else {
  //         let endDateStart = indexStartTo < arrTimesDayGrid.length - 1 ? 0 : 1
  //         startTo = arrTimesDayGrid[indexStartTo <= arrTimesDayGrid.length - 1 ?
  //           indexStartTo + endDateStart :
  //           indexStartTo + 1 - arrTimesDayGrid.length]

  //         endTo = arrTimesDayGrid[indexEndTo <= arrTimesDayGrid.length - 1 ?
  //           arrTimesDayGrid.length - 1 :
  //           (arrTimesDayGrid.length * 2) - 1]

  //         dateTo = moment(showDate[indexStartTo <= arrTimesDayGrid.length - 1 ? 0 : 1]).format('D MMM')
  //         startAppointmentTo = `${moment(showDate[indexStartTo < arrTimesDayGrid.length - 1 ? 0 : 1]).format('MMMM D, YYYY')} ${startTo ? startTo : arrTimesDayGrid[0]}:00`
  //         endAppointmentTo = `${moment(showDate[indexStartTo < arrTimesDayGrid.length - 1 ? 0 : 1]).format('MMMM D, YYYY')} ${arrTimesDayGrid[arrTimesDayGrid.length - 1]}:00`
  //       }
  //     }

  //     if (startFromMin < '30') {
  //       startTo = `${startTo.substring(0, 2)}:${evenNotEven ? startFromMin : +startFromMin + 30}`
  //     } else if (startFromMin > '30') {
  //       startTo = `${startTo.substring(0, 2)}:${evenNotEven ? startFromMin : +startFromMin - 30}`
  //     } else {
  //       startTo = arrTimesDayGrid[indexStartTo]
  //     }

  //     if (endFromMin < '30') {
  //       endTo = `${endTo.substring(0, 2)}:${evenNotEven ? endFromMin : +endFromMin + 30}`
  //     } else if (endFromMin > '30') {
  //       endTo = `${endTo.substring(0, 2)}:${evenNotEven ? endFromMin : +endFromMin - 30}`
  //     } else {
  //       endTo = arrTimesDayGrid[indexEndTo]
  //     }
  //   }
  //   if (dateRangeType === 'three') {
  //     if (
  //       (indexStartTo < arrTimesDayGrid.length - 1 ? 0 : indexStartTo < arrTimesDayGrid.length * 2 - 2 ? 1 : 2)
  //       ===
  //       (indexEndTo < arrTimesDayGrid.length - 1 ? 0 : indexEndTo < arrTimesDayGrid.length * 2 - 2 ? 1 : 2)
  //     ) {
  //       let endDateStart = indexStartTo < arrTimesDayGrid.length - 1 ? 0 : indexStartTo < arrTimesDayGrid.length * 2 - 2 ? 1 : 2

  //       startTo = arrTimesDayGrid[indexStartTo <= arrTimesDayGrid.length - 1 ?
  //         indexStartTo + endDateStart :
  //         indexStartTo < (arrTimesDayGrid.length * 2) - 1 ?
  //           indexStartTo + 1 + endDateStart - arrTimesDayGrid.length :
  //           indexStartTo + 2 - arrTimesDayGrid.length * 2]

  //       endTo = arrTimesDayGrid[indexEndTo <= arrTimesDayGrid.length - 1 ?
  //         indexEndTo :
  //         indexEndTo < (arrTimesDayGrid.length * 2) - 1 ?
  //           indexEndTo + 1 - arrTimesDayGrid.length :
  //           indexEndTo + 2 - arrTimesDayGrid.length * 2]

  //       dateTo = moment(showDate[indexEndTo <= arrTimesDayGrid.length - 1 ? 0 : indexEndTo <= arrTimesDayGrid.length * 2 - 1 ? 1 : 2]).format('D MMM')
  //       startAppointmentTo = `${moment(showDate[indexStartTo < arrTimesDayGrid.length - 1 ? 0 : indexStartTo < arrTimesDayGrid.length * 2 - 2 ? 1 : 2]).format('MMMM D, YYYY')} ${startTo ? startTo : arrTimesDayGrid[0]}:00`
  //       endAppointmentTo = `${moment(showDate[indexEndTo < arrTimesDayGrid.length - 1 ? 0 : indexEndTo < arrTimesDayGrid.length * 2 - 2 ? 1 : 2]).format('MMMM D, YYYY')} ${endTo}:00`
  //     } else {
  //       if (toX < 0) {
  //         startTo = arrTimesDayGrid[indexStartTo <= arrTimesDayGrid.length - 1 ?
  //           0 :
  //           indexStartTo < (arrTimesDayGrid.length * 2) - 1 ?
  //             arrTimesDayGrid.length - 1 :
  //             (arrTimesDayGrid.length * 2) - 1]

  //         endTo = arrTimesDayGrid[indexEndTo <= arrTimesDayGrid.length - 1 ?
  //           indexEndTo :
  //           indexEndTo < (arrTimesDayGrid.length * 2) - 1 ?
  //             indexEndTo + 1 - arrTimesDayGrid.length :
  //             indexEndTo + 2 - arrTimesDayGrid.length * 2]

  //         dateTo = moment(showDate[indexEndTo <= arrTimesDayGrid.length - 1 ? 0 : indexEndTo <= arrTimesDayGrid.length * 2 - 1 ? 1 : 2]).format('D MMM')
  //         startAppointmentTo = `${moment(showDate[indexEndTo < arrTimesDayGrid.length - 1 ? 0 : indexEndTo < arrTimesDayGrid.length * 2 - 2 ? 1 : 2]).format('MMMM D, YYYY')} ${arrTimesDayGrid[0]}:00`
  //         endAppointmentTo = `${moment(showDate[indexEndTo < arrTimesDayGrid.length - 1 ? 0 : indexEndTo < arrTimesDayGrid.length * 2 - 2 ? 1 : 2]).format('MMMM D, YYYY')} ${endTo}:00`
  //       } else {
  //         let endDateStart = indexStartTo < arrTimesDayGrid.length - 1 ? 0 : indexStartTo < arrTimesDayGrid.length * 2 - 2 ? 1 : 2

  //         startTo = arrTimesDayGrid[indexStartTo <= arrTimesDayGrid.length - 1 ?
  //           indexStartTo + endDateStart :
  //           indexStartTo < (arrTimesDayGrid.length * 2) - 1 ?
  //             indexStartTo + endDateStart - arrTimesDayGrid.length :
  //             indexStartTo + 1 - arrTimesDayGrid.length * 2]

  //         endTo = arrTimesDayGrid[indexEndTo <= arrTimesDayGrid.length - 1 ?
  //           arrTimesDayGrid.length - 1 :
  //           indexEndTo < (arrTimesDayGrid.length * 2) - 1 ?
  //             arrTimesDayGrid.length * 2 - 1 :
  //             arrTimesDayGrid.length * 3 - 1]

  //         dateTo = moment(showDate[indexStartTo <= arrTimesDayGrid.length - 1 ? 0 : indexStartTo <= arrTimesDayGrid.length * 2 - 1 ? 1 : 2]).format('D MMM')
  //         startAppointmentTo = `${moment(showDate[indexStartTo < arrTimesDayGrid.length - 1 ? 0 : indexStartTo < arrTimesDayGrid.length * 2 - 2 ? 1 : 2]).format('MMMM D, YYYY')} ${startTo ? startTo : arrTimesDayGrid[0]}:00`
  //         endAppointmentTo = `${moment(showDate[indexStartTo < arrTimesDayGrid.length - 1 ? 0 : indexStartTo < arrTimesDayGrid.length * 2 - 2 ? 1 : 2]).format('MMMM D, YYYY')} ${arrTimesDayGrid[arrTimesDayGrid.length - 1]}:00`
  //       }
  //     }

  //     if (startFromMin < '30') {
  //       startTo = `${startTo.substring(0, 2)}:${evenNotEven ? startFromMin : +startFromMin + 30}`
  //     } else if (startFromMin > '30') {
  //       startTo = `${startTo.substring(0, 2)}:${evenNotEven ? startFromMin : +startFromMin - 30}`
  //     } else {
  //       startTo = arrTimesDayGrid[indexStartTo]
  //     }

  //     if (endFromMin < '30') {
  //       endTo = `${endTo.substring(0, 2)}:${evenNotEven ? endFromMin : +endFromMin + 30}`
  //     } else if (endFromMin > '30') {
  //       endTo = `${endTo.substring(0, 2)}:${evenNotEven ? endFromMin : +endFromMin - 30}`
  //     } else {
  //       endTo = arrTimesDayGrid[indexEndTo]
  //     }
  //   }

  //   return {
  //     text: `${startTo ? startTo : arrTimesDayGrid[0]} - ${endTo ? endTo : arrTimesDayGrid[arrTimesDayGrid.length - 1]} ${dateTo}`,
  //     startTo: startAppointmentTo,
  //     endTo: endAppointmentTo
  //   }
  // }

  // save drag appointment
  async function handleSaveDragAppointment() {

    // const response = await httpClientUpdate.put(`/technician/appointments/${dragAppoint.appointment_id}`, qs.stringify({
    //   account_id: activeAccountId,
    //   appointment_finish: dragAppoint.appointment_date_end_to,
    //   appointment_id: dragAppoint.appointment_id,
    //   appointment_start: dragAppoint.appointment_date_start_to,
    //   appointment_status_id: 1,
    //   contact_id: '88888202aaaaaaaaaa',
    //   job_id: '88888202aaaaaaaaaa',
    //   service_resource_id: dragAppoint.appointment_service_resource_id,
    //   // type: newData.type

    // }))
    // if (response.data.success) {
    //   setDragAppoint({
    //     appointment_job_number: '',
    //     appointment_date_start: '',
    //     appointment_date_start_to: '',
    //     appointment_date_end: '',
    //     appointment_date_end_to: '',
    //     appointment_id: null,
    //     appointment_update_time: '',
    //     appointment_service_resource_id: '',
    //     nameFrom: '',
    //     nameTo: '',
    //     appointment_info: '',
    //     appointment_notification: false,
    //     appointment_type: "",
    //     appointment_zip: ""
    //   })
    //   setDrag({
    //     idTo: '',
    //     nameTo: '',
    //   })
    // }
  }

  // cancel drag appointment
  function handleCancelDragAppoinyment() {
    dragAppoint.appointment_id && setControlledPosition({ [dragAppoint.appointment_id]: { x: 0, y: 0 } })

    setDragAppoint({
      appointment_job_number: '',
      appointment_date_start: '',
      appointment_date_start_to: '',
      appointment_date_end: '',
      appointment_date_end_to: '',
      appointment_id: null,
      appointment_update_time: '',
      appointment_service_resource_id: '',
      nameFrom: '',
      nameTo: '',
      appointment_info: '',
      appointment_notification: false,
      appointment_type: "",
      appointment_zip: "",
      timeZone: '',
    })

    setDrag({
      idTo: '',
      nameTo: '',
    })

    setScheduleData(scheduleData)
  }

  function getRealTimeLine(time: string, day: string) {
    let indexOfDate = showDate.indexOf(day)
    let timeline = 0
    let asideWidth = document.getElementsByClassName('board__aside') ? document.getElementsByClassName('board__aside')[0]?.getBoundingClientRect()?.width : 0
    let minutWidth = cellWidth / 30
    if (time.split(':')[1] < '30') {
      let indexStart = arrTimesDayGrid.indexOf(`${time.split(':')[0]}:00`)
      if (indexStart !== -1 && indexStart !== arrTimesDayGrid.length - 1) {
        timeline = cellWidth * indexStart + (+time.split(':')[1] * minutWidth) + ((arrTimesDayGrid.length - 1) * indexOfDate * cellWidth)
      } else {
        timeline = - 1
      }
    } else {
      let indexStart = arrTimesDayGrid.indexOf(`${time.split(':')[0]}:30`)
      if (indexStart !== -1) {
        timeline = cellWidth * indexStart + ((+time.split(':')[1] - 30) * minutWidth) + ((arrTimesDayGrid.length - 1) * indexOfDate * cellWidth)
      } else {
        timeline = - 1
      }
    }

    return timeline < 0 ? null : `${asideWidth + timeline}px`
  }

  function getPositionAbsence(start: string, end: string, dayStart: string, dayEnd: string, days: string[], timeZone: string) {

    let dayIndexStart = showDate.indexOf(days[0])
    let dayIndexEnd = showDate.indexOf(days[days.length - 1])
    let startDay = ''
    let startLine = 0
    let width = 0
    let minutWidth = cellWidth / 30
    let widthOfOneDay = moment(arrTimesDayGrid[arrTimesDayGrid.length - 1], 'HH:mm').diff(moment(arrTimesDayGrid[0], 'HH:mm'), 'minutes') * minutWidth

    let isSameDaysStart = moment(showDate[dayIndexStart]).diff(moment(dayStart).startOf('day')) > 0
    if (isSameDaysStart) {
      startLine = ((arrTimesDayGrid.length - 1) * dayIndexStart * cellWidth)
      startDay = arrTimesDayGrid[0]
    } else {
      if (start.split(':')[1] < '30') {
        let indexStart = arrTimesDayGrid.indexOf(`${start.split(':')[0]}:00`)
        if (indexStart !== -1) {
          startLine = cellWidth * indexStart + (+start.split(':')[1] * minutWidth) + ((arrTimesDayGrid.length - 1) * dayIndexStart * cellWidth)
        } else {
          if (dayIndexStart === 0) {
            startLine = - 1
          } else {
            startLine = cellWidth * indexStart + (+start.split(':')[1] * minutWidth) + ((arrTimesDayGrid.length) * dayIndexStart * cellWidth)
          }
        }
      } else {
        let indexStart = arrTimesDayGrid.indexOf(`${start.split(':')[0]}:30`)
        if (indexStart !== -1) {
          startLine = dayIndexStart * cellWidth * indexStart + ((+start.split(':')[1] - 30) * minutWidth)
        } else {
          startLine = - 1
        }
      }

      if (arrTimesDayGrid.indexOf(`${start.split(':')[0]}:00`) === -1) {
        startDay = arrTimesDayGrid[arrTimesDayGrid.length - 1]
      } else {
        startDay = start
      }
    }

    let isSameDaysEnd = moment(showDate[dayIndexEnd]).diff(moment(dayEnd).startOf('day')) >= 0

    if (isSameDaysEnd) {
      if (arrTimesDayGrid.indexOf(`${end.split(':')[0]}:00`) !== -1) {
        if (arrTimesDayGrid.indexOf(`${end.split(':')[0]}:00`) === arrTimesDayGrid.length - 1) {
          let duration = moment(`${end.split(':')[0]}:00`, 'HH:mm').diff(moment(startDay, 'HH:mm'), 'minutes')

          width = (duration * minutWidth) + (widthOfOneDay * (days.length - 1))
        } else {
          let duration = moment(end, 'HH:mm').diff(moment(startDay, 'HH:mm'), 'minutes')

          width = (duration * minutWidth) + (widthOfOneDay * (days.length - 1))
        }
      } else {
        if (+end.split(':')[0] < +arrTimesDayGrid[0].split(':')[0]) {
          width = 0 + (widthOfOneDay * (days.length - 1))
        } else {
          let duration = moment(arrTimesDayGrid[arrTimesDayGrid.length - 1], 'HH:mm').diff(moment(startDay, 'HH:mm'), 'minutes')

          width = (duration * minutWidth) + (widthOfOneDay * (days.length - 1))
        }
      }
    } else {
      if (isSameDaysStart) {
        let duration = moment(arrTimesDayGrid[arrTimesDayGrid.length - 1], 'HH:mm').diff(moment(arrTimesDayGrid[0], 'HH:mm'), 'minutes')

        width = (duration * minutWidth + (widthOfOneDay * (days.length - 1)))
      } else {
        let duration = moment(arrTimesDayGrid[arrTimesDayGrid.length - 1], 'HH:mm').diff(moment(startDay, 'HH:mm'), 'minutes')

        width = (duration * minutWidth) + (widthOfOneDay * (days.length - 1))
      }
    }
    let left = 0
    let right = 0

    if (showDate.length === 1) {
      left = startLine < 0 ? 0 : -startLine
      width = width < 0 || !width ? 0 : width
      right = (arrTimesDayGrid.length - 1) * cellWidth
    } else if (showDate.length === 2) {
      left = startLine < 0 ? 0 : -startLine - (dayIndexStart * widthOfOneDay)
      width = width < 0 || !width ? 0 : width
      right = (arrTimesDayGrid.length - 1) * cellWidth + ((1 - dayIndexEnd) * widthOfOneDay)
    } else {
      left = startLine < 0 ? 0 : -startLine - (dayIndexStart * widthOfOneDay)
      width = width < 0 || !width ? 0 : width
      right = (arrTimesDayGrid.length - 1) * cellWidth + ((2 - dayIndexEnd) * widthOfOneDay)
    }

    if (dayIndexStart === dayIndexEnd) {
      right = right + left - width
    } else {
      right = right + left
    }

    return {
      start: startLine < 0 ? null : `${startLine}px`,
      width,
      left,
      right: right
    }
  }

  function getPositionReserve(start: string, end: string, dayStart: string, dayEnd: string, days: string[]) {
    let dayIndexStart = showDate.indexOf(days[0])
    let dayIndexEnd = showDate.indexOf(days[days.length - 1])
    let startDay = ''
    let startLine = 0
    let width = 0
    let minutWidth = cellWidth / 30
    let widthOfOneDay = moment(arrTimesDayGrid[arrTimesDayGrid.length - 1], 'HH:mm').diff(moment(arrTimesDayGrid[0], 'HH:mm'), 'minutes') * minutWidth

    let isSameDaysStart = moment(showDate[dayIndexStart]).diff(moment(dayStart).startOf('day')) > 0
    if (isSameDaysStart) {
      startLine = ((arrTimesDayGrid.length - 1) * dayIndexStart * cellWidth)
      startDay = arrTimesDayGrid[0]
    } else {
      if (start.split(':')[1] < '30') {
        let indexStart = arrTimesDayGrid.indexOf(`${start.split(':')[0]}:00`)
        if (indexStart !== -1) {
          startLine = cellWidth * indexStart + (+start.split(':')[1] * minutWidth) + ((arrTimesDayGrid.length - 1) * dayIndexStart * cellWidth)
        } else {
          startLine = - 1
        }
      } else {
        let indexStart = arrTimesDayGrid.indexOf(`${start.split(':')[0]}:30`)
        if (indexStart !== -1) {
          startLine = cellWidth * indexStart + ((+start.split(':')[1] - 30) * minutWidth)
        } else {
          startLine = - 1
        }
      }

      if (arrTimesDayGrid.indexOf(`${start.split(':')[0]}:00`) === -1) {
        startDay = arrTimesDayGrid[0]
      } else {
        startDay = start
      }
    }

    let isSameDaysEnd = moment(showDate[dayIndexEnd]).diff(moment(dayEnd).startOf('day')) >= 0

    if (isSameDaysEnd) {
      if (arrTimesDayGrid.indexOf(`${end.split(':')[0]}:00`) !== -1) {
        if (arrTimesDayGrid.indexOf(`${end.split(':')[0]}:00`) === arrTimesDayGrid.length - 1) {
          let duration = moment(`${end.split(':')[0]}:00`, 'HH:mm').diff(moment(startDay, 'HH:mm'), 'minutes')

          width = (duration * minutWidth) + (widthOfOneDay * (days.length - 1))
        } else {
          let duration = moment(end, 'HH:mm').diff(moment(startDay, 'HH:mm'), 'minutes')

          width = (duration * minutWidth) + (widthOfOneDay * (days.length - 1))
        }
      } else {
        if (+end.split(':')[0] < +arrTimesDayGrid[0].split(':')[0]) {
          width = 0 + (widthOfOneDay * (days.length - 1))
        } else {
          let duration = moment(arrTimesDayGrid[arrTimesDayGrid.length - 1], 'HH:mm').diff(moment(startDay, 'HH:mm'), 'minutes')

          width = (duration * minutWidth) + (widthOfOneDay * (days.length - 1))
        }
      }
    } else {
      if (isSameDaysStart) {
        let duration = moment(arrTimesDayGrid[arrTimesDayGrid.length - 1], 'HH:mm').diff(moment(arrTimesDayGrid[0], 'HH:mm'), 'minutes')

        width = (duration * minutWidth + (widthOfOneDay * (days.length - 1)))
      } else {
        let duration = moment(arrTimesDayGrid[arrTimesDayGrid.length - 1], 'HH:mm').diff(moment(startDay, 'HH:mm'), 'minutes')

        width = (duration * minutWidth) + (widthOfOneDay * (days.length - 1))
      }
    }
    let left = 0
    let right = 0

    if (showDate.length === 1) {
      left = startLine < 0 ? 0 : -startLine
      width = width < 0 || !width ? 0 : width
      right = (arrTimesDayGrid.length - 1) * cellWidth
    } else if (showDate.length === 2) {
      left = startLine < 0 ? 0 : -startLine - (dayIndexStart * widthOfOneDay)
      width = width < 0 || !width ? 0 : width
      right = (arrTimesDayGrid.length - 1) * cellWidth + ((1 - dayIndexEnd) * widthOfOneDay)
    } else {
      left = startLine < 0 ? 0 : -startLine - (dayIndexStart * widthOfOneDay)
      width = width < 0 || !width ? 0 : width
      right = (arrTimesDayGrid.length - 1) * cellWidth + ((2 - dayIndexEnd) * widthOfOneDay)
    }

    if (dayIndexStart === dayIndexEnd) {
      right = right + left - width
    } else {
      right = right + left
    }

    return {
      start: startLine < 0 ? null : `${startLine}px`,
      width,
      left,
      right: right
    }
  }

  function getAsideWidth() {
    let width = 0
    if (asideRef && asideRef.current) {
      width = asideRef.current.offsetWidth
    }

    return width
  }

  function getPositionTop() {
    let top = 0
    let tableBottom = tableRef.current ? tableRef.current.getBoundingClientRect().bottom : 0

    if (tableBottom - hoverPositionY < 150) {
      top = -55
    } else {
      top = 0
    }
    return `${top}px`
  }

  return (
    <section
      onClick={() => { Object.keys(showDropList).length && setShowDropList({}) }}
      className="boards">
      <div className="boards__wrapper">
        <div className="boards__dates-wrapper">
          <div style={{ minWidth: '180px' }}></div>

          <div className="boards__dates">
            {
              showDate.map(day => (
                <div
                  key={day}
                  className="boards__dates-item"
                >
                  <p className="boards__dates-item-time">{day}</p>
                </div>
              ))
            }
          </div>
        </div>

        <div className="boards__timeline-wrapper">
          <div style={{ minWidth: '180px' }}></div>

          <div className="boards__timeline">
            {
              dateRangeType === "one" &&
              <div
                style={{ width: `calc(100% / ${(arrTimesGrid.length + 2)})` }}
                className="timeline__item"
              >
                <p className="timeline__item-time"></p>
              </div>
            }
            {dateRangeType === "one"
              ? arrTimesGrid.map((item, index) => (
                <div
                  key={index}
                  style={{ width: `calc(100% / ${(arrTimesGrid.length + 2) / 2})` }}
                  className="timeline__item"
                >
                  <p className="timeline__item-time">{item.span}</p>
                </div>
              ))
              : dateRangeType === "two" ?
                arrTimesGrid.concat(arrTimesGrid).map((item, index) => (
                  <React.Fragment key={index}>
                    {
                      (index === 0 || index === arrTimesGrid.length) &&
                      <div
                        style={{ width: `calc((100% / ((${arrTimesGrid.length} * 2) + 2)) / 2)` }}
                        className={index === arrTimesGrid.length ? "timeline__item start timeline__item_end" : "timeline__item start"}
                      >
                        <p className="timeline__item-time"></p>
                      </div>
                    }
                    <div
                      style={{ width: `calc(100% / ((${arrTimesGrid.length} * 2) + 2))` }}
                      className="timeline__item"
                    >
                      <p className="timeline__item-time">{item.span}</p>
                    </div>
                    {
                      (index === arrTimesGrid.length - 1 || index === arrTimesGrid.length * 2) &&
                      <div
                        style={{ width: `calc((100% / ((${arrTimesGrid.length} * 2) + 2)) / 2)` }}
                        className="timeline__item finish"
                      >
                        <p className="timeline__item-time"></p>
                      </div>
                    }
                  </React.Fragment>
                )) :
                arrTimesGrid.concat(arrTimesGrid).concat(arrTimesGrid).map((item, index) => (
                  <React.Fragment key={index}>
                    {
                      (index === 0 || index === arrTimesGrid.length || index === arrTimesGrid.length * 2) &&
                      <div
                        style={{ width: `calc((100% / ((${arrTimesGrid.length} * 3) + 3)) / 2)` }}
                        className={index === arrTimesGrid.length || index === arrTimesGrid.length * 2 ? "timeline__item start timeline__item_end" : "timeline__item start"}
                      >
                        <p className="timeline__item-time"></p>
                      </div>
                    }
                    <div
                      style={{ width: `calc(100% / ((${arrTimesGrid.length} * 2) + 2))` }}
                      className="timeline__item"
                    >
                      <p className="timeline__item-time">{item.span}</p>
                    </div>
                    {
                      (index === arrTimesGrid.length - 1 || index === (arrTimesGrid.length * 2) - 1 || index === (arrTimesGrid.length * 3) - 1) &&
                      <div
                        style={{ width: `calc((100% / ((${arrTimesGrid.length} * 3) + 3)) / 2)` }}
                        className="timeline__item finish"
                      >
                        <p className="timeline__item-time"></p>
                      </div>
                    }
                  </React.Fragment>
                ))
            }
            {
              dateRangeType === "one" &&
              <div
                style={{ width: `calc(100% / ${(arrTimesGrid.length + 2)})` }}
                className="timeline__item"
              >
                <p className="timeline__item-time"></p>
              </div>
            }
          </div>
        </div>

        <div
          ref={tableRef as React.RefObject<HTMLDivElement>}
          id="board__items"
          className="board__items"
        >
          {/* areas */}
          {
            scheduleData.map((area, indexAreas) => (
              <div key={area.area_id + indexAreas} className="boards__item">
                <div className="boards__item-area">
                  <div className="boards__item-area_left">
                    <div className="boards__item-area-btn-hide"
                      onClick={() => handleShowAreas(indexAreas)}
                    >
                      <Icon
                        viewBox="0 0 14 12"
                        icon="up-1"
                        style={{ transform: showAreas[indexAreas] ? '' : 'rotate(180deg)' }}
                      />
                    </div>
                    <p className="boards__item-area-town">{area.area_name}</p>
                  </div>

                  <div className="boards__item-area_right">
                    <p className="boards__item-area-time">
                      Now: {moment().tz(area.area_timezone).format('dddd, MMMM DD, YYYY hh:mma')}
                    </p>
                  </div>
                </div>

                {
                  showAreas[indexAreas] &&

                  <div className="boards__board board">
                    <div className="board__table">
                      {
                        showDate.filter(day => moment(day).isSame(moment(moment().tz(area.area_timezone).format('dddd, MMMM DD, YYYY HH:mm')), 'day')).length > 0 &&
                        <div
                          className="timegrid__realtime-line timegrid__realtime-line_active"
                          style={{
                            left: getRealTimeLine(moment().tz(area.area_timezone).format('HH:mm'), moment().tz(area.area_timezone).format('ddd, MMM DD YYYY'))
                              || 0,
                            display: getRealTimeLine(moment().tz(area.area_timezone).format('HH:mm'), moment().tz(area.area_timezone).format('ddd, MMM DD YYYY')) ? 'block' : 'none'
                          }}
                        ></div>
                      }
                      {
                        area.service_resources.map((item, indexArea) => (
                          <div
                            style={{ height: getHeigthRow(area.area_id, item.service_resource_id) === 0 || getHeigthRow(area.area_id, item.service_resource_id) === 1 ? `60px` : `${(getHeigthRow(area.area_id, item.service_resource_id) * 60)}px` }}
                            key={item.service_resource_id + indexArea}
                            className="board__row"
                            tabIndex={indexArea + 1}
                          >
                            <div
                              className="board__aside"
                              ref={asideRef}
                            >
                              <div className="board__worker">
                                <div style={{ display: 'flex', alignItems: 'center' }}>

                                  <div className="board__worker-img">
                                    {
                                      item.service_resource_image ?
                                        <img src={item.service_resource_image} alt="Pic" /> :
                                        <Icon icon="user-20" />
                                    }
                                  </div>

                                  <WorkerInfo
                                    service_resource_id={item.service_resource_id}
                                    getAsideWidth={getAsideWidth}
                                    available={item.available}
                                    service_resource_code={item.service_resource_code}
                                    service_resource_nickname={item.service_resource_nickname}
                                    service_resource_fullname={item.service_resource_fullname}
                                    service_resource_function={item.service_resource_function}
                                  />
                                </div>

                                <PopupInfo
                                  setShowPopup={setShowPopup}
                                  showPopup={showPopup}
                                  note_yes={item.note_yes}
                                  note_no={item.note_no}
                                  service_name={`${item.service_resource_id}_${indexArea}`}
                                  tableRef={tableRef}
                                />
                              </div>
                            </div>

                            <div className="board__main">
                              <div className="board__timegrid timegrid">
                                <div
                                  className="timegrid__row"
                                  ref={el => {
                                    let width = el?.querySelector('.timegrid__cell')?.getBoundingClientRect().width ? el?.querySelector('.timegrid__cell')?.getBoundingClientRect().width : 0
                                    width && width !== cellWidth && setCellWidth(width)
                                  }}
                                >
                                  {
                                    dateRangeType === 'one' ?
                                      arrTimesDayGrid.map((slot, index) => {
                                        return arrTimesDayGrid.length - 1 !== index &&
                                          <div
                                            ref={cellRef as React.RefObject<HTMLDivElement>}
                                            key={slot}
                                            style={{ width: `calc(100% / ${arrTimesDayGrid.length - 1})`, position: 'relative' }}
                                            className={`${getClassForGrid(item.service_resource_id, slot, 0)} ${arrTimesGrid.map(item => item.value).includes(slot) ? 'timegrid_cell_border' : ''}`}
                                          >
                                            {
                                              getClassForGrid(item.service_resource_id, slot, 0).includes('timegrid__cell_regular') &&
                                              !!item.limit[0].is_limit &&
                                              (arrTimesGrid.map(item => item.value).includes(slot) || index === 0) &&
                                              <div style={{
                                                position: 'absolute',
                                                transform: 'rotate(-40deg)',
                                                top: '25%',
                                                left: '25%',
                                                fontWeight: 'bold',
                                                opacity: '.2',
                                                fontSize: '24px',
                                                zIndex: '1',
                                              }}> Limit </div>
                                            }
                                          </div>
                                      }) :
                                      dateRangeType === 'two' ?
                                        arrTimesDayGrid.concat(arrTimesDayGrid).map((slot, idx) => {

                                          return (arrTimesDayGrid.length - 1 !== idx && (arrTimesDayGrid.length * 2) - 1 !== idx) &&
                                            <div
                                              key={idx}
                                              style={{ width: `calc(100% / (${arrTimesDayGrid.length - 1} * 2))`, position: 'relative' }}
                                              className={
                                                `${getClassForGrid(item.service_resource_id, slot, idx)}
                                                     ${idx !== 0 && slot === arrTimesDayGrid[0] ? 'timegrid__cell_end' : ''}
                                                     ${arrTimesGrid.map(item => item.value).includes(slot) ? 'timegrid_cell_border' : ''}
                                                     `}
                                            >
                                              {
                                                getClassForGrid(item.service_resource_id, slot, idx).includes('timegrid__cell_regular') &&
                                                ((idx <= arrTimesDayGrid.length - 1 && !!item.limit[0].is_limit) || (idx >= arrTimesDayGrid.length - 1 && !!item.limit[1].is_limit)) &&
                                                idx !== 0 && ((idx <= arrTimesDayGrid.length - 1 && idx % 2 === 0) || (idx >= arrTimesDayGrid.length - 1 && (idx - 1) % 2 === 0)) &&
                                                <div style={{
                                                  position: 'absolute',
                                                  transform: 'rotate(-40deg)',
                                                  top: '35%',
                                                  left: '25%',
                                                  fontWeight: 'bold',
                                                  opacity: '.2',
                                                  fontSize: '12px',
                                                  zIndex: '1',
                                                }}> Limit </div>
                                              }
                                            </div>
                                        }) :
                                        arrTimesDayGrid.concat(arrTimesDayGrid).concat(arrTimesDayGrid).map((slot, idx) => {

                                          return (arrTimesDayGrid.length - 1 !== idx &&
                                            (arrTimesDayGrid.length * 2) - 1 !== idx &&
                                            (arrTimesDayGrid.length * 3) - 1 !== idx) &&
                                            <div
                                              key={idx}
                                              style={{ width: `calc(100% / (${arrTimesDayGrid.length - 1} * 3))`, position: 'relative' }}
                                              className={
                                                `${getClassForGrid(item.service_resource_id, slot, idx)} 
                                                     ${idx !== 0 && slot === arrTimesDayGrid[0] ? 'timegrid__cell_end' : ''}
                                                     ${arrTimesGrid.map(item => item.value).includes(slot) ? 'timegrid_cell_border' : ''}
                                                    `}
                                            >
                                              {
                                                getClassForGrid(item.service_resource_id, slot, idx).includes('timegrid__cell_regular') &&
                                                ((idx <= arrTimesDayGrid.length - 1 && !!item.limit[0].is_limit) ||
                                                  (idx >= arrTimesDayGrid.length - 1 && idx <= (arrTimesDayGrid.length * 2) - 1 && !!item.limit[1].is_limit) ||
                                                  (idx >= (arrTimesDayGrid.length * 2) - 1 && !!item.limit[2].is_limit)) &&
                                                idx !== 0 &&
                                                ((idx <= arrTimesDayGrid.length - 1 && idx % 2 === 0)
                                                  || (idx >= arrTimesDayGrid.length - 1 && idx <= (arrTimesDayGrid.length * 2) - 1 && (idx - 1) % 2 === 0)
                                                  || (idx >= (arrTimesDayGrid.length * 2) - 1 && idx % 2 === 0)
                                                ) &&
                                                <div style={{
                                                  position: 'absolute',
                                                  transform: 'rotate(-75deg)',
                                                  top: '35%',
                                                  left: '-35%',
                                                  fontWeight: 'bold',
                                                  opacity: '.2',
                                                  fontSize: '12px',
                                                  zIndex: '1',
                                                }}> Limit </div>
                                              }
                                            </div>
                                        })
                                  }
                                </div>

                                <div className="schedule-items">
                                  <div
                                    style={{
                                      width: '100%',
                                      overflow: 'inherit',
                                      flexDirection: getHeigthRow(area.area_id, item.service_resource_id) < 2 ? 'row' : 'column'
                                    }}
                                    className="schedule-items__day-wrapper schedule-items__day-wrapper_1"
                                  >
                                    {
                                      item.appointments.map((appointment, index) => {
                                        let startApp = dateToInfoBlock('ccc, LLL dd yyyy', area.area_timezone, appointment.appointment_date_start)
                                        let endApp = dateToInfoBlock('ccc, LLL dd yyyy', area.area_timezone, appointment.appointment_date_end)

                                        let startHoursNumber = +dateToInfoBlock('HH', area.area_timezone, appointment.appointment_date_start)
                                        let endHoursNumber = dateToInfoBlock('HH', area.area_timezone, appointment.appointment_date_end) === '00' ? 24 : +dateToInfoBlock('HH', area.area_timezone, appointment.appointment_date_end)

                                        let startArrTimeDay = +arrTimesDayGrid[0].split(':')[0]
                                        let endArrTimeDay = +arrTimesDayGrid[arrTimesDayGrid.length - 1].split(':')[0]

                                        if (endHoursNumber === 24) {
                                          endApp = dateToInfoBlock('ccc, LLL dd yyyy', area.area_timezone, moment(appointment.appointment_date_end).subtract(1, 'days').toISOString())
                                        }

                                        let appointmentCheck: string[] = []

                                        showDate.forEach(date => {
                                          if ((startApp <= moment(date).format('DD-MM-YYYY') && moment(date).format('DD-MM-YYYY') <= endApp) || date === startApp || date === endApp) {
                                            if (((startArrTimeDay < endHoursNumber) && (endHoursNumber < endArrTimeDay)) || ((startArrTimeDay < startHoursNumber) && (startHoursNumber < endArrTimeDay))) {
                                              appointmentCheck.push(date)
                                            }
                                          }
                                        })

                                        if (!!appointmentCheck.length) {
                                          let getTime = getPositionAbsence(
                                            dateToInfoBlock('HH:mm', area.area_timezone, appointment.appointment_date_start),
                                            dateToInfoBlock('HH:mm', area.area_timezone, appointment.appointment_date_end) === '00:00' ? '24:00' : dateToInfoBlock('HH:mm', area.area_timezone, appointment.appointment_date_end),
                                            startApp,
                                            endApp,
                                            appointmentCheck, area.area_timezone)

                                          let number = 0
                                          showDate.forEach(date => {
                                            let getPosition = getpositionAppointment(area.area_id, item.service_resource_id, date, appointment.appointment_id)
                                            if (getPosition > number) {
                                              number = getPosition
                                            }
                                          })

                                          let classService = appointment.appointment_notification ? ' appointment_notification' : ''
                                          if (appointment.appointment_type && (appointment.appointment_type === 'FU' || appointment.appointment_type === 'Follow up')) {
                                            classService += ' appointment_follow'
                                          }
                                          if (appointment.appointment_type && (appointment.appointment_type === 'RC' || appointment.appointment_type === 'Recall')) {
                                            classService += ' appointment_recall'
                                          }
                                          if (appointment.appointment_type && (appointment.appointment_type === 'SC' || appointment.appointment_type === 'Service call')) {
                                            classService += ' appointment_service'
                                          }

                                          let heigth = 60

                                          let hoverPositionLeft = 0
                                          if (tableRef?.current?.getBoundingClientRect()) {
                                            hoverPositionLeft = tableRef?.current?.getBoundingClientRect().width - tableRef?.current?.getBoundingClientRect().left + getTime.left - getTime.width
                                          }

                                          return (
                                            <div
                                              key={appointment.appointment_id + indexAreas}
                                              style={{
                                                left: `${getTime.start ? getTime.start : 0}`,
                                                width: getTime.width,
                                                zIndex: showDropList[appointment.appointment_id + indexAreas] || hoverAppointment === appointment.appointment_id + indexAreas ? '3' : '2',
                                                top: number * heigth || 0,
                                                position: 'absolute',
                                                display: getTime.width === 0 ? 'none' : 'flex'
                                              }}
                                              id={appointment.appointment_id}
                                              className={`appointment ${classService}`}
                                              onMouseDown={(e) => {
                                                handleChange(e, appointment.appointment_id + indexAreas)
                                              }}
                                              onContextMenu={(e) => e.preventDefault()}
                                              onMouseOver={(e) => {
                                                setHoverPositionY(e.clientY)
                                                !showDropList[appointment.appointment_id + indexAreas] && setHoverAppointment(appointment.appointment_id + indexAreas)
                                              }}
                                              onMouseLeave={() => {
                                                setHoverPositionY(0)
                                                !showDropList[appointment.appointment_id + indexAreas] && setHoverAppointment('')
                                              }}
                                            >
                                              {
                                                hoverAppointment === appointment.appointment_id + indexAreas &&
                                                !draging &&
                                                <div
                                                  style={{
                                                    position: 'absolute',
                                                    width: '150px',
                                                    top: getPositionTop(),
                                                    left: hoverPositionLeft < 100 ? '-150px' : `${getTime.width}px`,
                                                    padding: '5px 10px',
                                                    border: '3px solid #6093DE',
                                                    borderRadius: '5px',
                                                    backgroundColor: 'white',
                                                    zIndex: '9'
                                                  }}
                                                >
                                                  <p
                                                    style={{ color: 'black', fontWeight: 'bold' }}
                                                    className="appointment__job-number"
                                                  >
                                                    {appointment.appointment_job_number}
                                                  </p>
                                                  <p
                                                    style={{ color: 'black', fontSize: '14px' }}
                                                    className="appointment__zip"
                                                  >
                                                    {
                                                      dateToInfoBlock('ha', area.area_timezone, appointment.appointment_date_start)
                                                    } {'-'}
                                                    {
                                                      dateToInfoBlock('ha', area.area_timezone, appointment.appointment_date_end)
                                                    }
                                                  </p>
                                                  {
                                                    appointment.units.map((item, i) => (
                                                      <p
                                                        key={i}
                                                        style={{ color: 'black', fontSize: '14px', margin: '1px' }}
                                                        className="appointment__job-info"
                                                      >
                                                        {item.unit_type}: {item.appliance === 'Other' ? item.other_appliance : item.appliance}, {item.appliance_brand === 'Other' ? item.other_brand : item.appliance_brand}
                                                      </p>
                                                    ))
                                                  }
                                                  <p
                                                    style={{ color: 'black', fontSize: '14px' }}
                                                    className="appointment__zip"
                                                  >
                                                    zip: {appointment.appointment_zip}
                                                  </p>
                                                </div>
                                              }
                                              <div className="appointment__job">
                                                <p className="appointment__job-number">
                                                  {appointment.appointment_job_number}
                                                </p>
                                                {
                                                  appointment.units.map((item, i) => (
                                                    <p
                                                      key={i}
                                                      className="appointment__job-info"
                                                    >
                                                      ({item.unit_type}: {item.appliance === 'Other' ? item.other_appliance : item.appliance}, {item.appliance_brand === 'Other' ? item.other_brand : item.appliance_brand}{i === 0 || i === appointment.units.length - 1 ? '' : ';'})
                                                    </p>
                                                  ))
                                                }
                                              </div>
                                              <p className="appointment__zip">
                                                {appointment.appointment_zip}
                                              </p>
                                              {
                                                showDropList[appointment.appointment_id + indexAreas] &&
                                                sendNotificationAppoint === null &&
                                                <div
                                                  style={{
                                                    top: '0px',
                                                    left: `${buttonLeftOption}px`,
                                                  }}
                                                  className="appointment-drop-list open visible" >
                                                  <p
                                                    className={classNames('appointment-drop-list__item', 'appointment-drop-list__item-open',
                                                      {
                                                        interdit: !permissions.appointment_show,
                                                      }
                                                    )}
                                                    onClick={(event) => {
                                                      event.preventDefault()
                                                      event.stopPropagation()
                                                      if (permissions.appointment_show) {
                                                        setShowDropList({})
                                                        window.open(`${window.location.origin}/${activeAccountId}/appointments/${appointment.appointment_id}`, "_blank", 'noopener,noreferrer')
                                                      }
                                                    }}
                                                  >
                                                    Open
                                                  </p>
                                                  {/* <p
                                                    onClick={() => {
                                                      setCancelAppoint({
                                                        appointment_date_start: appointment.appointment_date_start,
                                                        appointment_date_end: appointment.appointment_date_end,
                                                        appointment_id: appointment.appointment_id,
                                                        appointment_job_number: appointment.appointment_job_number,
                                                        service_resource_code: item.service_resource_code,
                                                        service_resource_nickname: item.service_resource_nickname
                                                      })
                                                    }}
                                                    className="appointment-drop-list__item appointment-drop-list__item-cancel"
                                                  >Cancel</p> */}
                                                  {/* <p
                                                    className="appointment-drop-list__item appointment-drop-list__item-reschedule"
                                                    onClick={() => setRescheduleAppoint({
                                                      appointment_date_start: appointment.appointment_date_start,
                                                      appointment_date_end: appointment.appointment_date_end,
                                                      appointment_id: appointment.appointment_id,
                                                      appointment_job_number: appointment.appointment_job_number,
                                                      service_resource_code: item.service_resource_code,
                                                      service_resource_nickname: item.service_resource_nickname,
                                                      service_resource_id: item.service_resource_id,
                                                      service_resource_select: area.service_resources.map(serv => {
                                                        return {
                                                          service_resource_code: serv.service_resource_code,
                                                          service_resource_id: serv.service_resource_id,
                                                          service_resource_nickname: serv.service_resource_nickname
                                                        }
                                                      })
                                                    })}
                                                  >
                                                    Reschedule
                                                  </p> */}
                                                  <p
                                                    // className="appointment-drop-list__item appointment-drop-list__item-send-notification"
                                                    className={classNames('appointment-drop-list__item', 'appointment-drop-list__item-send-notification',
                                                      {
                                                        is_sent: appointment.appointment_notification,
                                                        interdit: !permissions.appointment_send,
                                                      }
                                                    )}
                                                    onClick={(event) => {
                                                      event.preventDefault()
                                                      event.stopPropagation()
                                                      if (permissions.appointment_send) {
                                                        !appointment.appointment_notification && setSendNotificationAppoint({
                                                          appointment_id: appointment.appointment_id,
                                                        })
                                                      }
                                                    }}
                                                  >
                                                    Send to Technician
                                                  </p>
                                                </div>
                                              }
                                            </div>
                                          )
                                        } else {
                                          return null
                                        }
                                      })
                                    }
                                    {
                                      item.absences.sort((a, b) =>
                                        moment(a.absence_start).diff(moment(b.absence_start)))
                                        .map((absence, absenceIndex) => {
                                          let startAbsence = DateTime.fromISO(absence.absence_start).setZone(area.area_timezone).toFormat("MMM dd yyyy")
                                          let endAbsence = DateTime.fromISO(absence.absence_end).setZone(area.area_timezone).toFormat("MMM dd yyyy")

                                          let absenceCheck = showDate.filter(date => moment(date).isBetween(moment(startAbsence), moment(endAbsence))
                                            || moment(date).isSame(moment(startAbsence), 'day')
                                            || moment(date).isSame(moment(endAbsence), 'day'))

                                          if (!!absenceCheck.length) {
                                            let getTime = getPositionAbsence(
                                              dateToInfoBlock('HH:mm', area.area_timezone, absence.absence_start),
                                              dateToInfoBlock('HH:mm', area.area_timezone, absence.absence_end),
                                              startAbsence,
                                              endAbsence,
                                              absenceCheck, area.area_timezone)

                                            let number = 0
                                            showDate.forEach(date => {
                                              let getPosition = getpositionAppointment(area.area_id, item.service_resource_id, date, absence.absence_id)
                                              if (getPosition > number) {
                                                number = getPosition
                                              }
                                            })

                                            let hoverPositionLeft = 0
                                            if (tableRef?.current?.getBoundingClientRect()) {
                                              hoverPositionLeft = tableRef?.current?.getBoundingClientRect().width - tableRef?.current?.getBoundingClientRect().left + getTime.left - getTime.width
                                            }

                                            return (
                                              <div
                                                style={{
                                                  left: `${getTime.start}`,
                                                  width: getTime.width,
                                                  display: getTime.width === 0 ? 'none' : 'flex',
                                                  top: number * 60,
                                                  zIndex: showDropList[absence.absence_id + indexAreas + absenceIndex] ? 3 : 2,
                                                }}
                                                key={absence.absence_id + indexAreas + absenceIndex}
                                                className="absence"
                                                onMouseDown={(e) => permissions.absence_show && handleChange(e, absence.absence_id + indexAreas + absenceIndex)}
                                                onContextMenu={(e) => e.preventDefault()}
                                                onMouseOver={(e) => {
                                                  setHoverPositionY(e.clientY)
                                                  !showDropList[absence.absence_id + indexAreas + absenceIndex] && setHoverAbsence(absence.absence_id + indexAreas + absenceIndex)
                                                }}
                                                onMouseLeave={() => {
                                                  setHoverPositionY(0)
                                                  !showDropList[absence.absence_id + indexAreas + absenceIndex] && setHoverAbsence('')
                                                }}
                                              >
                                                <img src={CofeeSvg} alt="Coffee Break" />
                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    overflow: 'hidden',
                                                    marginLeft: '5px',
                                                    whiteSpace: 'nowrap',
                                                  }}
                                                >
                                                  <span style={{ cursor: 'inherit' }}> {absence.note} </span>
                                                </div>
                                                {
                                                  showDropList[absence.absence_id + indexAreas + absenceIndex] &&
                                                  <div
                                                    style={{
                                                      top: '0px',
                                                      left: `${buttonLeftOption}px`,
                                                      cursor: 'pointer',
                                                    }}
                                                    className="absence-drop-list open visible" >
                                                    <p
                                                      className="absence-drop-list__item"
                                                      onClick={(event) => {
                                                        event.preventDefault()
                                                        event.stopPropagation()
                                                        if (permissions.appointment_show) {
                                                          setShowDropList({})
                                                          window.open(`${window.location.origin}/${activeAccountId}/absences/${absence.absence_id}`, "_blank", 'noopener,noreferrer')
                                                        }
                                                      }}
                                                    >
                                                      Open
                                                    </p>
                                                  </div>
                                                }
                                                {
                                                  !showDropList[absence.absence_id + indexAreas + absenceIndex] &&
                                                  hoverAbsence === absence.absence_id + indexAreas + absenceIndex &&
                                                  absence.note &&
                                                  <div
                                                    style={{
                                                      position: 'absolute',
                                                      width: '150px',
                                                      top: getPositionTop(),
                                                      left: hoverPositionLeft < 100 ? '-150px' : `${getTime.width}px`,
                                                      padding: '5px 10px',
                                                      border: '3px solid #6093DE',
                                                      borderRadius: '5px',
                                                      backgroundColor: 'white',
                                                      zIndex: '9999',
                                                      minHeight: '100px',
                                                      maxHeight: '100px',
                                                      overflow: 'auto'
                                                    }}
                                                  >
                                                    <p
                                                      style={{ color: 'black', fontSize: '14px', margin: '1px', wordBreak: 'break-word' }}
                                                      className="appointment__job-info"
                                                    >
                                                      {absence.note}
                                                    </p>
                                                  </div>
                                                }
                                              </div>
                                            )
                                          } else {
                                            return null
                                          }
                                        })
                                    }

                                    {
                                      item.absence_time_slots.sort((a, b) =>
                                        moment(a.time_slot_start).diff(moment(b.time_slot_start)))
                                        .map((absence, absenceIndex) => {
                                          let startDate = DateTime.fromISO(absence.time_slot_start, { setZone: true }).toFormat("MMM dd yyyy")
                                          let endDate = DateTime.fromISO(absence.time_slot_end, { setZone: true }).toFormat("MMM dd yyyy")

                                          let reserveCheck = showDate.filter(date => moment(date).isBetween(moment(startDate), moment(endDate))
                                            || moment(date).isSame(moment(startDate), 'day')
                                            || moment(date).isSame(moment(endDate), 'day'))

                                          let number = 0
                                          showDate.forEach(date => {
                                            let getPosition = getpositionAppointment(area.area_id, item.service_resource_id, date, `${indexAreas}_${absenceIndex}_${absence.absence_id}`)
                                            if (getPosition > number) {
                                              number = getPosition
                                            }
                                          })

                                          if (!!reserveCheck.length) {
                                            let getTime = getPositionReserve(
                                              DateTime.fromISO(absence.time_slot_start, { setZone: true }).toFormat("HH:mm"),
                                              DateTime.fromISO(absence.time_slot_end, { setZone: true }).toFormat("HH:mm"),
                                              DateTime.fromISO(absence.time_slot_start, { setZone: true }).toFormat("MMM dd yyyy"),
                                              DateTime.fromISO(absence.time_slot_end, { setZone: true }).toFormat("MMM dd yyyy"),
                                              reserveCheck)

                                            let hoverPositionLeft = 0
                                            if (tableRef?.current?.getBoundingClientRect()) {
                                              hoverPositionLeft = tableRef?.current?.getBoundingClientRect().width - tableRef?.current?.getBoundingClientRect().left + getTime.left - getTime.width
                                            }

                                            return (
                                              <div
                                                style={{
                                                  left: `${getTime.start}`,
                                                  width: getTime.width,
                                                  display: getTime.width === 0 ? 'none' : 'flex',
                                                  top: number * 60,
                                                  zIndex: showDropList[`${indexAreas}_${absenceIndex}_${absence.absence_id}`] ? 3 : 2,
                                                }}
                                                key={`${indexAreas}_${absenceIndex}_${absence.absence_id}`}
                                                className="absence"
                                                onMouseDown={(e) => permissions.absence_show && handleChange(e, `${indexAreas}_${absenceIndex}_${absence.absence_id}`)}
                                                onContextMenu={(e) => e.preventDefault()}
                                                onMouseOver={(e) => {
                                                  setHoverPositionY(e.clientY)
                                                  !showDropList[`${indexAreas}_${absenceIndex}_${absence.absence_id}`] && setHoverAbsence(`${indexAreas}_${absenceIndex}_${absence.absence_id}`)
                                                }}
                                                onMouseLeave={() => {
                                                  setHoverPositionY(0)
                                                  !showDropList[`${indexAreas}_${absenceIndex}_${absence.absence_id}`] && setHoverAbsence('')
                                                }}
                                              >
                                                <img src={CofeeSvg} alt="Coffee Break" />
                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    overflow: 'hidden',
                                                    marginLeft: '5px'
                                                  }}
                                                >
                                                  <span style={{ cursor: 'inherit' }}> {absence.note} </span>
                                                </div>
                                                {
                                                  showDropList[`${indexAreas}_${absenceIndex}_${absence.absence_id}`] &&
                                                  <div
                                                    style={{
                                                      top: getPositionTop(),
                                                      left: `${buttonLeftOption}px`,
                                                    }}
                                                    className="absence-drop-list open visible" >
                                                    <p
                                                      className="absence-drop-list__item"
                                                      onClick={(event) => {
                                                        event.preventDefault()
                                                        event.stopPropagation()
                                                        if (permissions.appointment_show) {
                                                          setShowDropList({})
                                                          window.open(`${window.location.origin}/${activeAccountId}/absences/${absence.absence_id}`, "_blank", 'noopener,noreferrer')
                                                        }
                                                      }}
                                                    >
                                                      Open
                                                    </p>
                                                  </div>
                                                }
                                                {
                                                  !showDropList[`${indexAreas}_${absenceIndex}_${absence.absence_id}`] &&
                                                  hoverAbsence === `${indexAreas}_${absenceIndex}_${absence.absence_id}` &&
                                                  absence.note &&
                                                  <div
                                                    style={{
                                                      position: 'absolute',
                                                      width: '150px',
                                                      top: getPositionTop(),
                                                      left: hoverPositionLeft < 100 ? '-150px' : `${getTime.width}px`,
                                                      padding: '5px 10px',
                                                      border: '3px solid #6093DE',
                                                      borderRadius: '5px',
                                                      backgroundColor: 'white',
                                                      zIndex: '9999'
                                                    }}
                                                  >
                                                    <p
                                                      style={{ color: 'black', fontSize: '14px', margin: '1px', wordBreak: 'break-word' }}
                                                      className="appointment__job-info"
                                                    >
                                                      {absence.note}
                                                    </p>
                                                  </div>
                                                }
                                              </div>
                                            )
                                          } else {
                                            return null
                                          }
                                        })
                                    }

                                    {
                                      item.reserves.sort((a, b) =>
                                        moment(a.start).diff(moment(b.start)))
                                        .map((reserve, reserveIndex) => {
                                          let startDate = DateTime.fromISO(reserve.start).setZone(area.area_timezone).toFormat("MMM dd yyyy")
                                          let endDate = DateTime.fromISO(reserve.end).setZone(area.area_timezone).toFormat("MMM dd yyyy")

                                          let reserveCheck = showDate.filter(date => moment(date).isBetween(moment(startDate), moment(endDate))
                                            || moment(date).isSame(moment(startDate), 'day')
                                            || moment(date).isSame(moment(endDate), 'day'))

                                          let number = 0
                                          showDate.forEach(date => {
                                            let getPosition = getpositionAppointment(area.area_id, item.service_resource_id, date, `${reserveIndex}_reserve`)
                                            if (getPosition > number) {
                                              number = getPosition
                                            }
                                          })

                                          if (!!reserveCheck.length) {
                                            let getTime = getPositionReserve(
                                              DateTime.fromISO(reserve.start).setZone(area.area_timezone).toFormat("HH:mm"),
                                              DateTime.fromISO(reserve.end).setZone(area.area_timezone).toFormat("HH:mm"),
                                              DateTime.fromISO(reserve.start).setZone(area.area_timezone).toFormat("MMM dd yyyy"),
                                              DateTime.fromISO(reserve.end).setZone(area.area_timezone).toFormat("MMM dd yyyy"),
                                              reserveCheck)

                                            return (
                                              <ReserveBlock
                                                key={reserveIndex}
                                                start={`${getTime.start}`}
                                                width={getTime.width}
                                                number={number}
                                                duration={reserve.duration}
                                                created_by={reserve.created_by}
                                                created_at={reserve.created_at}
                                                time_zone={area.area_timezone}
                                              />
                                            )
                                          } else {
                                            return null
                                          }
                                        })
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>}
              </div>
            ))
          }
        </div>
      </div>
      {
        cancelAppoint &&
        <CancelAppointment
          appointment={cancelAppoint}
          setCancel={setCancelAppoint}
        />
      }
      {
        rescheduleAppoint &&
        <RescheduleAppointPopup
          appoint={rescheduleAppoint}
          setAppoint={setRescheduleAppoint}
        />
      }
      {
        sendNotificationAppoint &&
        <SendNotification
          appoint={sendNotificationAppoint}
          setAppoint={setSendNotificationAppoint}
          handleSend={handleSend}
          isSending={isSending}
        />
      }
      {
        !draging && dragAppoint.appointment_id &&
        <DragAppointment
          handleSaveDragAppointment={handleSaveDragAppointment}
          appointment={dragAppoint}
          handleCancelDragAppoinyment={handleCancelDragAppoinyment}
        />
      }
      {
        changeAbsence &&
        <ChangeAbsence
          absence={changeAbsence}
          setAbsence={setChangeAbsence}
        />
      }
      {
        cancelAbsence &&
        <CancelAbsence
          absence={cancelAbsence}
          setAbsence={setCancelAbsence}
        />
      }
    </section >
  );
}
