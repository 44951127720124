import { useState, useEffect, useCallback } from 'react'
import { useRoute } from "react-router5"
import classNames from 'classnames'
import ReactInputMask from 'react-input-mask'

import Icon from '../../components/Icon'
import ModalImage from './ModalImage'
import ErrorImage from '../../images/error_image.svg'

import { useAppSelector } from '../../store/hooks'
import { dateToInfoBlock, httpClientUpdate, nErrorUpdate } from '../../funcs'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

import '../../styles/pages/sms.sass'

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: {
    group: GroupProps
    messages: MessagesProps[]
    permissions: PermissionsProps
  }
}

interface GroupProps {
  caller_name: string
  client_id: string
  client_name: string
  friendly_name: string
  from: string
  to: string
  message_dialog_id: string
}

interface MessagesProps {
  created_at: string
  created_by: string
  direction: string
  message: string
  message_status: string
  message_id: string
  is_read: boolean
  items: {
    type: string
    url: string
  }[]
}

interface PermissionsProps {
  message_dialog_show: boolean
  message_report_show: boolean
  message_send_message: boolean
}

export default function Item() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  const [isSaving, setIsSaving] = useState(false)

  const [message, setMessage] = useState('')
  const [chat, setChat] = useState<MessagesProps[] | []>([])
  const [group, setGroup] = useState<Partial<GroupProps>>({})
  const [permissions, setPermissions] = useState<Partial<PermissionsProps>>({})

  const [openModal, setOpenModal] = useState(false)
  const [zoomImage, setZoomImage] = useState({ url: '', type: '' })
  const [uploadImage, setUploadImage] = useState<File[] | []>([])

  const [isSendMessage, setIsSendMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [draging, setDraging] = useState(false)

  const onDragEnter = useCallback((e) => {
    setDraging(true);
    e.stopPropagation();
    e.preventDefault();
    return false;
  }, []);

  const onDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }, []);

  const onDragLeave = useCallback((e) => {
    setDraging(false);
    e.stopPropagation();
    e.preventDefault();
    return false;
  }, []);

  const onDrop = useCallback((e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    let updateImages = uploadImage.map(item => item)
    updateImages.push(files[0])
    setUploadImage(updateImages)
    setDraging(false);
    return false;
  }, [uploadImage]);

  useEffect(() => {
    window.addEventListener('dragenter', onDragEnter);
    window.addEventListener('mouseup', onDragLeave);
    window.addEventListener('dragover', onDragOver);
    return () => {
      window.removeEventListener('dragenter', onDragEnter);
      window.removeEventListener('mouseup', onDragLeave);
      window.removeEventListener('dragover', onDragOver);
    };
  }, [onDragEnter, onDragLeave, onDragOver]);

  // Load info function
  async function loadInfo() {
    try {
      let { dialogId } = $router.router.getState().params

      const { data: { data: messageData, success, error } } = await httpClientUpdate.get(`/messages/dialog/${dialogId}`,
        {
          params: {
            account_id: activeAccountId,
          },
          headers: { 'Access-Control-Allow-Origin': '*' }
        }) as { data: HttpClientUpdateReport }
      if (success) {
        setGroup(messageData.group)
        setChat(messageData.messages)
        setPermissions(messageData.permissions)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function changeReadMessage(id: string, is_read: boolean) {
    try {
      // https://2022back4.artemiudintsev.com/api/messages/{MESSAGE_ID}/read
      const { data: { success, error } } = await httpClientUpdate.post(`/messages/${id}/read`,
        {
          account_id: activeAccountId,
          is_read: is_read ? 0 : 1
        })
      if (success) {
        loadInfo()
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load job data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const handleContextmenu = (e: any) => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }
  }, [])

  // Send message function
  async function handleSendMessage() {
    setIsSaving(true)
    let { from, to } = group
    const formData = new FormData();
    setIsSendMessage(true)

    try {
      uploadImage.length && uploadImage.forEach((item, index) => {
        formData.append(
          `media_content_${index}`,
          item,
          item.name
        );
      })
      activeAccountId && formData.append('account_id', activeAccountId);
      formData.append('message', message);
      from && formData.append('from', from);
      to && formData.append('to', to);
      formData.append('num_media', `${uploadImage.length}`)

      let response = await httpClientUpdate.post(`/messages/send`, formData);
      if (response.data.success) {
        setIsSaving(false)
        loadInfo()
        setMessage('')
        setUploadImage([])
        setIsSendMessage(false)
        setErrorMessage('')
        setDraging(false)
      } else {
        setIsSaving(false)
        setIsSendMessage(false)
        setDraging(false)
        setErrorMessage(response.statusText)
      }

    } catch (error: Error | AxiosError | unknown) {
      setIsSaving(false)
      setIsSendMessage(false)
      setDraging(false)
      let createdError = nErrorUpdate(error)
      setErrorMessage(createdError.content.errorText)
    }
  }

  function handleChangeMessage(event: any) {
    if (!event.shiftKey && event.code === 'Enter') {
      event.preventDefault()
      message !== '' && handleSendMessage()
    }
  }

  function handleOpenImage(url: string, type: string) {
    setZoomImage({ url, type })
    setOpenModal(true)
  }

  function handleUploadImages(image: File) {
    let updateImages = uploadImage.map(item => item)
    updateImages.push(image)
    setUploadImage(updateImages)
  }

  function handleRemoveImage(index: number) {
    let updateImages = uploadImage.filter((item, idx) => index !== idx)
    setUploadImage(updateImages)
  }

  const downloadImage = (url: string, type: string) => {
    const name = url.split('/')[url.split('/').length - 1]

    const img = new Image();
    img.crossOrigin = 'anonymous';  // This tells the browser to request cross-origin access when trying to download the image data.
    img.src = url;
    img.onload = () => {
      // create Canvas
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      ctx && ctx.drawImage(img, 0, 0);
      const a = document.createElement('a');
      a.download = name;
      a.href = canvas.toDataURL('image/png');
      a.click();
    };
  }

  function isValidURL(string: string) {
    var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g);
    return (res !== null)
  };

  return (
    <div className="SmsPage_Item entity-edit">
      <div className="wrapper flex-container sb">
        <div className="flex-container _gap-narrow">
          <h1>SMS:</h1>
          <div className="item-name">
            {group.caller_name}
          </div>
        </div>

        <button
          style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
          className="_wa"
          onClick={() =>
            $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'sms',
              {
                companyId: activeAccountId,
                [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                localInterface: $router.router.getState().params.localInterface,
                selectedCallCenter: $router.router.getState().params.selectedCallCenter,
              }, { reload: true })}
        >
          <Icon
            style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
            viewBox="0 0 24 24"
            icon="arrow-25"
          />
        </button>
      </div>


      <div className="fieldset">
        <div className="legend">Main</div>

        <div className="fields">
          <div className="__left">
            <div className="field">
              <span>Customer:</span>
              <ReactInputMask
                disabled={true}
                type="text"
                mask="+1 (999) 999-9999"
                value={group.from || ''}
              />
            </div>

            <div className="field">
              <span>Caller Name:</span>
              <input
                type="text"
                disabled={true}
                defaultValue={group.caller_name}
              />
            </div>

            <div className="field">
              <span>Client:</span>
              <input
                type="text"
                disabled={true}
                defaultValue={group.client_name}
              />
            </div>
          </div>

          <div className="__right">
            <div className="field">
              <span>Company:</span>
              <ReactInputMask
                disabled={true}
                type="text"
                mask="+1 (999) 999-9999"
                value={group.to || ''}
              />
            </div>

            <div className="field">
              <span>Friendly Name:</span>
              <input type="text" defaultValue={group.friendly_name} disabled={true} />
            </div>
          </div>
        </div>
      </div>

      <div className="fieldset">
        <div className="legend">
          Messages
          {
            errorMessage &&
            <span style={{ fontSize: '12px', color: '#FF0000' }}> {errorMessage}</span>
          }
        </div>

        {
          permissions.message_send_message ?
            draging && uploadImage.length < 10 ?
              <div
                style={{
                  justifyContent: 'space-between',
                  gridGap: 'inherit',
                  textAlign: 'center',
                  border: '1px solid #d0d3da',
                  backgroundColor: 'white',
                  position: 'relative',
                  borderRadius: '5px'
                }}
                className="fields"
              >
                <input
                  id="dragbox"
                  style={{ width: '100%', height: '120px', display: 'block', opacity: '0', zIndex: '2' }}
                  type="file"
                  name="myImage"
                  onDragLeave={onDragLeave}
                  onDrop={onDrop}
                  onDragOver={onDragOver}
                  onChange={(event) => {
                    event.target.files && handleUploadImages(event.target.files[0])
                    setDraging(false)
                  }}
                />
                <span
                  style={{ position: 'absolute', top: '60px', width: '100%' }}
                >
                  Upload an image or click for refresh field
                </span>
              </div> :
              <div
                style={{
                  justifyContent: 'space-between',
                  gridGap: 'inherit',
                  textAlign: 'center',
                  border: '1px solid #d0d3da',
                  backgroundColor: 'white',
                  borderRadius: '5px'
                }}
                className="fields"
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                    position: 'relative'
                  }}
                >
                  <textarea
                    style={{ width: '100%', border: 'none' }}
                    placeholder='Your Message'
                    value={message}
                    onChange={({ target: { value } }) => setMessage(value)}
                    onKeyDown={(event) => handleChangeMessage(event)}
                  />

                </div>

                {
                  !!uploadImage.length &&
                  <div
                    className='upload-image'
                    style={{
                      width: '100%',
                    }}
                  >
                    <div
                      className='show-images'
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        width: '100%',
                        background: 'white',
                        padding: '20px',
                      }}
                    >
                      {
                        uploadImage.map((item, index) => (
                          <div className='show-image' key={index} style={{ position: 'relative' }}>
                            <img
                              style={{ maxHeight: '100px', maxWidth: '100px', objectFit: 'cover' }}
                              alt="not found"
                              src={URL.createObjectURL(item)}
                            />
                            <span
                              onClick={() => handleRemoveImage(index)}
                            >
                              <Icon viewBox="0 0 24 24" icon="x-mark-1" />
                            </span>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                }

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    padding: '5px'
                  }}
                >
                  {
                    uploadImage.length < 10 ?
                      <label
                        htmlFor="filePicker"
                        className='add-image'
                        title='Upload a image'
                      >
                        <Icon style={{ transform: 'rotate(45deg)' }} viewBox="0 0 24 24" icon="x-mark-1" />
                      </label> :
                      <div></div>
                  }
                  <input
                    disabled={uploadImage.length > 9}
                    id="filePicker"
                    style={{ visibility: "hidden" }}
                    type="file"
                    name="myImage"
                    onChange={(event) => {
                      event.target.files && handleUploadImages(event.target.files[0])
                    }}
                  />
                  <button
                    className='send'
                    disabled={(message === '' && !uploadImage.length) || isSendMessage || isSaving}
                    onClick={() => handleSendMessage()}
                  >
                    Send
                  </button>

                </div>
              </div> : null
        }
        {/* show desktop */}
        {
          permissions.message_dialog_show &&
          <div className={classNames('chat', '__show-on-wide',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}
          >
            {chat.map((message, i) => {
              return (
                <div key={i} className='chat-row'>
                  <div className='chat-row-left'>
                    {message.direction === 'incoming' &&
                      <div
                        className={classNames('item', 'left',
                          {
                            unread: !message.is_read,
                          }
                        )}
                        style={{
                          cursor: 'pointer',
                          position: 'relative',
                        }}
                      >
                        <p style={{ margin: '20px 30px 16px 0' }}> {message.message} </p>

                        <div
                          className="read-icon-block"
                          onClick={() => changeReadMessage(message.message_id, message.is_read)}
                        >
                          {message.is_read ?
                            <Icon
                              viewBox="0 0 1024 1024"
                              icon="read-64"
                            /> :
                            <Icon
                              viewBox="0 0 1024 1024"
                              icon="unread-message-3"
                            />
                          }
                        </div>

                        {/* Image block */}
                        <div className='images-client'>
                          {
                            message.items.map((item, index) => (
                              isValidURL(item.url) ?
                                <div
                                  key={index}
                                  className='image-client'
                                >
                                  {
                                    item.type.includes('video') ?
                                      <video height={'100px'} controls>
                                        <source src={item.url} type={item.type} />
                                      </video> :
                                      <img
                                        src={item.url}
                                        alt="Client_img"
                                        onClick={() => handleOpenImage(item.url, item.type)}
                                      />
                                  }
                                  <div className='image-client-actions' >
                                    <button
                                      onClick={() => downloadImage(item.url, item.type)}
                                    >
                                      <Icon
                                        style={{ width: '32px', height: '32px', verticalAlign: 'middle', overflow: 'hidden' }}
                                        viewBox="0 0 1024 1024"
                                        icon="download-sms"
                                      />
                                    </button>
                                    <button
                                      style={{ padding: '0' }}
                                      onClick={() => handleOpenImage(item.url, item.type)}
                                    >
                                      <Icon
                                        style={{ width: '24px', height: '24px', verticalAlign: 'middle', overflow: 'hidden' }}
                                        viewBox="0 0 1024 1024"
                                        icon="zoom-in-1"
                                      />
                                    </button>
                                  </div>
                                </div> :
                                <div
                                  key={index}
                                  className='image-client'
                                >
                                  <img
                                    style={{ cursor: 'inherit', objectFit: 'contain' }}
                                    src={ErrorImage}
                                    alt="Client_img_error"
                                  />
                                </div>
                            ))
                          }
                        </div>


                        <span> {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, message.created_at)} </span>
                      </div>}
                  </div>

                  <div className='chat-row-center'></div>

                  <div className='chat-row-right'>
                    {message.direction === 'outgoing' &&
                      <div className={'item right'}>
                        {
                          message.message_status && message.message_status.toLocaleLowerCase() === 'undelivered' &&
                          <div className='undelivered_blok'>!</div>
                        }
                        <p> {message.message} </p>

                        {/* Image block */}
                        <div className='images-client'>
                          {
                            message.items.map((item, index) => (
                              isValidURL(item.url) ?
                                <div
                                  key={index}
                                  className='image-client'
                                >
                                  {
                                    item.type.includes('video') ?
                                      <video height={'100px'} controls>
                                        <source src={item.url} type={item.type} />
                                      </video> :
                                      <img
                                        src={item.url}
                                        alt="Client_img"
                                        onClick={() => handleOpenImage(item.url, item.type)}
                                      />
                                  }
                                  <div className='image-client-actions' >
                                    <button
                                      onClick={() => downloadImage(item.url, item.type)}
                                    >
                                      <Icon
                                        style={{ width: '32px', height: '32px', verticalAlign: 'middle', overflow: 'hidden' }}
                                        viewBox="0 0 1024 1024"
                                        icon="download-sms"
                                      />
                                    </button>
                                    <button
                                      style={{ padding: '0' }}
                                      onClick={() => handleOpenImage(item.url, item.type)}
                                    >
                                      <Icon
                                        style={{ width: '24px', height: '24px', verticalAlign: 'middle', overflow: 'hidden' }}
                                        viewBox="0 0 1024 1024"
                                        icon="zoom-in-1"
                                      />
                                    </button>
                                  </div>
                                </div> :
                                <div
                                  key={index}
                                  className='image-client'
                                >
                                  <img
                                    style={{ cursor: 'inherit', objectFit: 'contain' }}
                                    src={ErrorImage}
                                    alt="Client_img_error"
                                  />
                                </div>
                            ))
                          }
                        </div>

                        <span
                          className={classNames('message-status',
                            {
                              __red: message.message_status && message.message_status.toLocaleLowerCase() === 'undelivered',
                              __white: message.message_status && message.message_status.toLocaleLowerCase() === 'delivered',
                            }
                          )}
                        >
                          {message.message_status}
                        </span>
                        <span> {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, message.created_at)} from <strong>{message.created_by}</strong></span>
                      </div>}
                  </div>
                </div>
              )
            })
            }
          </div>
        }

        {/* show laptop */}
        {
          permissions.message_dialog_show &&
          <div className={classNames('chat', '__hide-on-wide', '__hide-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}
          >
            {
              permissions.message_dialog_show &&
              chat.map((message, i) => (
                <div key={i} className='chat-row'>
                  <div
                    className='chat-row-left'
                    style={{ width: '45%' }}
                  >
                    {
                      message.direction === 'incoming' &&
                      <div
                        className={classNames('item', 'left',
                          {
                            unread: !message.is_read,
                          }
                        )}
                        style={{
                          cursor: 'pointer'
                        }}
                      >
                        <div
                          className="read-icon-block"
                          onClick={() => changeReadMessage(message.message_id, message.is_read)}
                        >
                          {message.is_read ?
                            <Icon
                              viewBox="0 0 1024 1024"
                              icon="read-64"
                            /> :
                            <Icon
                              viewBox="0 0 1024 1024"
                              icon="unread-message-3"
                            />
                          }
                        </div>

                        <p style={{ margin: '20px 30px 16px 0' }}> {message.message} </p>

                        {/* Image block */}
                        <div className='images-client'>
                          {
                            message.items.map((item, index) => (
                              isValidURL(item.url) ?
                                <div
                                  key={index}
                                  className='image-client'
                                >
                                  {
                                    item.type.includes('video') ?
                                      <video height={'100px'} controls>
                                        <source src={item.url} type={item.type} />
                                      </video> :
                                      <img
                                        src={item.url}
                                        alt="Client_img"
                                        onClick={() => handleOpenImage(item.url, item.type)}
                                      />
                                  }
                                  <div className='image-client-actions' >
                                    <button
                                      onClick={() => downloadImage(item.url, item.type)}
                                    >
                                      <Icon
                                        style={{ width: '32px', height: '32px', verticalAlign: 'middle', overflow: 'hidden' }}
                                        viewBox="0 0 1024 1024"
                                        icon="download-sms"
                                      />
                                    </button>
                                    <button
                                      style={{ padding: '0' }}
                                      onClick={() => handleOpenImage(item.url, item.type)}
                                    >
                                      <Icon
                                        style={{ width: '24px', height: '24px', verticalAlign: 'middle', overflow: 'hidden' }}
                                        viewBox="0 0 1024 1024"
                                        icon="zoom-in-1"
                                      />
                                    </button>
                                  </div>
                                </div> :
                                <div
                                  key={index}
                                  className='image-client'
                                >
                                  <img
                                    style={{ cursor: 'inherit', objectFit: 'contain' }}
                                    src={ErrorImage}
                                    alt="Client_img_error"
                                  />
                                </div>
                            ))
                          }
                        </div>

                        <span> {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, message.created_at)} </span>
                      </div>
                    }
                  </div>

                  <div
                    style={{ width: '10%' }}
                    className='chat-row-center'
                  ></div>

                  <div
                    style={{ width: '45%' }}
                    className='chat-row-right'
                  >
                    {
                      message.direction === 'outgoing' &&
                      <div className={'item right'}>
                        {
                          message.message_status && message.message_status.toLocaleLowerCase() === 'undelivered' &&
                          <div className='undelivered_blok'>!</div>
                        }

                        <p> {message.message} </p>

                        {/* Image block */}
                        <div className='images-client'>
                          {
                            message.items.map((item, index) => (
                              isValidURL(item.url) ?
                                <div
                                  key={index}
                                  className='image-client'
                                >
                                  {
                                    item.type.includes('video') ?
                                      <video height={'100px'} controls>
                                        <source src={item.url} type={item.type} />
                                      </video> :
                                      <img
                                        src={item.url}
                                        alt="Client_img"
                                        onClick={() => handleOpenImage(item.url, item.type)}
                                      />
                                  }
                                  <div className='image-client-actions' >
                                    <button
                                      onClick={() => downloadImage(item.url, item.type)}
                                    >
                                      <Icon
                                        style={{ width: '32px', height: '32px', verticalAlign: 'middle', overflow: 'hidden' }}
                                        viewBox="0 0 1024 1024"
                                        icon="download-sms"
                                      />
                                    </button>
                                    <button
                                      style={{ padding: '0' }}
                                      onClick={() => handleOpenImage(item.url, item.type)}
                                    >
                                      <Icon
                                        style={{ width: '24px', height: '24px', verticalAlign: 'middle', overflow: 'hidden' }}
                                        viewBox="0 0 1024 1024"
                                        icon="zoom-in-1"
                                      />
                                    </button>
                                  </div>
                                </div> :
                                <div
                                  key={index}
                                  className='image-client'
                                >
                                  <img
                                    style={{ cursor: 'inherit', objectFit: 'contain' }}
                                    src={ErrorImage}
                                    alt="Client_img_error"
                                  />
                                </div>
                            ))
                          }
                        </div>

                        <span
                          className={classNames('message-status',
                            {
                              __red: message.message_status && message.message_status.toLocaleLowerCase() === 'undelivered',
                              __white: message.message_status && message.message_status.toLocaleLowerCase() === 'delivered',
                            }
                          )}
                        >
                          {message.message_status}
                        </span>

                        <span> {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, message.created_at)} from <strong>{message.created_by}</strong></span>
                      </div>
                    }
                  </div>
                </div>
              ))
            }
          </div>
        }

        {/* show mobile */}
        {
          permissions.message_dialog_show &&
          <div className={classNames('chat', 'mobile-table', '__show-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}
          >
            {
              permissions.message_dialog_show &&
              chat.map((message, i) => (
                <div key={i} className='chat-row'>
                  <div
                    className='chat-row-left'
                    style={{ width: message.direction === 'incoming' ? '80%' : '0' }}
                  >
                    {
                      message.direction === 'incoming' &&
                      <div
                        className={classNames('item', 'left',
                          {
                            unread: !message.is_read,
                          }
                        )}
                        style={{
                          cursor: 'pointer'
                        }}
                      >
                        <div
                          className="read-icon-block"
                          onClick={() => changeReadMessage(message.message_id, message.is_read)}
                        >
                          {message.is_read ?
                            <Icon
                              viewBox="0 0 1024 1024"
                              icon="read-64"
                            /> :
                            <Icon
                              viewBox="0 0 1024 1024"
                              icon="unread-message-3"
                            />
                          }
                        </div>

                        <p style={{ margin: '20px 30px 16px 0' }}> {message.message}</p>

                        {/* Image block */}
                        <div className='images-client'>
                          {
                            message.items.map((item, index) => (
                              isValidURL(item.url) ?
                                <div
                                  key={index}
                                  className='image-client'
                                >
                                  {
                                    item.type.includes('video') ?
                                      <video height={'100px'} controls>
                                        <source src={item.url} type={item.type} />
                                      </video> :
                                      <img
                                        src={item.url}
                                        alt="Client_img"
                                        onClick={() => handleOpenImage(item.url, item.type)}
                                      />
                                  }
                                  <div className='image-client-actions' >
                                    <button
                                      onClick={() => downloadImage(item.url, item.type)}
                                    >
                                      <Icon
                                        style={{ width: '32px', height: '32px', verticalAlign: 'middle', overflow: 'hidden' }}
                                        viewBox="0 0 1024 1024"
                                        icon="download-sms"
                                      />
                                    </button>
                                    <button
                                      style={{ padding: '0' }}
                                      onClick={() => handleOpenImage(item.url, item.type)}
                                    >
                                      <Icon
                                        style={{ width: '24px', height: '24px', verticalAlign: 'middle', overflow: 'hidden' }}
                                        viewBox="0 0 1024 1024"
                                        icon="zoom-in-1"
                                      />
                                    </button>
                                  </div>
                                </div> :
                                <div
                                  key={index}
                                  className='image-client'
                                >
                                  <img
                                    style={{ cursor: 'inherit', objectFit: 'contain' }}
                                    src={ErrorImage}
                                    alt="Client_img_error"
                                  />
                                </div>
                            ))
                          }
                        </div>

                        <span> {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, message.created_at)} </span>
                      </div>
                    }
                  </div>

                  <div
                    style={{ width: '10%' }}
                    className='chat-row-center'
                  ></div>

                  <div
                    style={{ width: message.direction === 'outgoing' ? '80%' : '0' }}
                    className='chat-row-right'
                  >
                    {
                      message.direction === 'outgoing' &&
                      <div className={'item right'}>
                        {
                          message.message_status && message.message_status.toLocaleLowerCase() === 'undelivered' &&
                          <div className='undelivered_blok'>!</div>
                        }

                        <p> {message.message} </p>

                        {/* Image block */}
                        <div className='images-client'>
                          {
                            message.items.map((item, index) => (
                              isValidURL(item.url) ?
                                <div
                                  key={index}
                                  className='image-client'
                                >
                                  {
                                    item.type.includes('video') ?
                                      <video height={'100px'} controls>
                                        <source src={item.url} type={item.type} />
                                      </video> :
                                      <img
                                        src={item.url}
                                        alt="Client_img"
                                        onClick={() => handleOpenImage(item.url, item.type)}
                                      />
                                  }
                                  <div className='image-client-actions' >
                                    <button
                                      onClick={() => downloadImage(item.url, item.type)}
                                    >
                                      <Icon
                                        style={{ width: '32px', height: '32px', verticalAlign: 'middle', overflow: 'hidden' }}
                                        viewBox="0 0 1024 1024"
                                        icon="download-sms"
                                      />
                                    </button>
                                    <button
                                      style={{ padding: '0' }}
                                      onClick={() => handleOpenImage(item.url, item.type)}
                                    >
                                      <Icon
                                        style={{ width: '24px', height: '24px', verticalAlign: 'middle', overflow: 'hidden' }}
                                        viewBox="0 0 1024 1024"
                                        icon="zoom-in-1"
                                      />
                                    </button>
                                  </div>
                                </div> :
                                <div
                                  key={index}
                                  className='image-client'
                                >
                                  <img
                                    style={{ cursor: 'inherit', objectFit: 'contain' }}
                                    src={ErrorImage}
                                    alt="Client_img_error"
                                  />
                                </div>
                            ))
                          }
                        </div>

                        <span
                          className={classNames('message-status',
                            {
                              __red: message.message_status && message.message_status.toLocaleLowerCase() === 'undelivered',
                              __white: message.message_status && message.message_status.toLocaleLowerCase() === 'delivered',
                            }
                          )}
                        >
                          {message.message_status}
                        </span>

                        <span> {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, message.created_at)} from <strong>{message.created_by}</strong></span>
                      </div>
                    }
                  </div>
                </div>
              ))
            }
          </div>
        }
      </div>

      {
        openModal &&
        <ModalImage
          image={zoomImage}
          setOpen={setOpenModal}
        />
      }
    </div>
  )
}
