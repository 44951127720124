import React, { useEffect, useRef, useState } from "react";

import Icon from "./Icon"

import { calculateFromPercent, calculatePercent, formatTime } from "../funcs"

import "../styles/components/audio-player.sass"

interface Props {
  audioSrc: string,
  progress?: number,
  playing?: boolean
}

function AudioPlayer({
  audioSrc,
  progress,
  playing
}: Props) {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [rerender, setRerender] = useState(0)

  const $player = useRef<HTMLAudioElement>(null)
  const $playerSeeker = useRef<HTMLDivElement>(null)

  const [progressState, setProgressState] = useState(0)

  const [playingState, setPlayingState] = useState<boolean>(!!playing)

  useEffect(() => {
    $player.current?.load()
  }, [audioSrc])

  // Set progress from prop
  useEffect(() => {
    setProgressState(progress || 0)
  }, [progress])

  // Start or stop
  useEffect(() => {

    if (playing === undefined) return

    if (playing) $player.current?.play()
    else $player.current?.pause()

    setPlayingState(playing)
  }, [playing])

  // On progress change
  function onProgressChange(event: React.SyntheticEvent<HTMLAudioElement, Event>) {
    setProgressState(calculatePercent($player.current?.currentTime || 0, $player.current?.duration || 0))
  }

  // Seek function
  function seek(event: any) {

    let w100 = parseFloat(window.getComputedStyle($playerSeeker.current as any).width)
    let w = event.nativeEvent.offsetX

    let p = calculatePercent(w, w100);

    ($player.current as any).currentTime = calculateFromPercent(p, ($player.current as any).currentTime, ($player.current as any).duration)

  }

  // Render function
  return (
    <div className="AudioPlayer">

      <audio
        ref={$player}
        onTimeUpdate={onProgressChange}
        onPlay={() => setPlayingState(true)}
        onPause={() => setPlayingState(false)}
        onDurationChange={() => setRerender(Math.random())}
        onTimeUpdateCapture={() => setRerender(Math.random())}
      >
        <source src={audioSrc} />
      </audio>

      {!playingState ? (
        <button className="_zeroed _iconed play-switcher" onClick={() => $player.current?.play()}>
          <Icon icon="video-15" />
        </button>
      ) : (
        <button className="_zeroed _iconed play-switcher" onClick={() => $player.current?.pause()}>
          <Icon icon="media-control-49" />
        </button>
      )}

      <div className="time">{formatTime($player.current?.currentTime || 0)}</div>

      <div className="player-seeker" onClick={seek} ref={$playerSeeker}>
        <div className="seeker-progress" style={{ width: `${progressState}%` }}></div>
        <div className="seeker-notch" style={{ left: `${progressState}%` }}></div>
      </div>

      <div className="time">{formatTime($player.current?.duration || 0)}</div>
    </div>
  )
}

export default AudioPlayer
