import './InputTextWithTitle.sass'

interface TextAreaWithTitleProps {
  value: string
  setValue: (value: string) => void
  label: string
  // width: number
}

export default function TextAreaWithTitle({ value, setValue, label }: TextAreaWithTitleProps) {
  return (
    <div
      className='input-text-title select'
      style={{ width: '100%' }}
    >
      <span className="label">{label}</span>

      <div style={{ position: 'relative', width: '100%' }}>
        <textarea
          style={{ resize: 'none', minHeight: '75px', paddingBottom: '15px' }}
          value={value}
          onChange={({ target: { value } }) => value.length < 3000 && setValue(value)}
        />
        <span style={{
          position: 'absolute',
          bottom: '1px',
          right: '5px',
          backgroundColor: 'white',
          zIndex: '2',
          color: value.length < 2950 ? 'rgba(0, 0, 0, .6)' : 'rgb(255, 0, 0, .6);',
          fontSize: '12px',
        }}>
          {value.length} / 3000
        </span>
      </div>
    </div>
  )
}
