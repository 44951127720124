import { useState, useEffect } from 'react'
import classNames from 'classnames'
import qs from 'qs'

import SelectComponent from '../../components/Select'
import Icon from '../../components/Icon'

import { useAppSelector } from "../../store/hooks"
import { httpClientUpdate } from '../../funcs'

import { Unit } from './UnitEdit'

import "../../styles/pages/common/entity-edit.sass"
import '../Appointments/UnitsBlock.sass'

interface UnitAddProps {
  switchPage: (name: string) => void
  edit: {
    unit_type: string[]
    appliances: {
      appliance_id: string
      name: string
    }[]
    appliance_brands: {
      brand_id: string
      name: string
    }[]
  }
  jobId: string
  client_id: string
  appointment_id: string
}

export default function UnitAdd({
  switchPage,
  edit,
  jobId,
  client_id,
  appointment_id,
}: UnitAddProps) {
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [readyToSave, setReadyToSave] = useState(false)

  const [newUnitData, setNewUnitData] = useState<Partial<Unit>>({

  })

  async function handleSave() {
    const response = await httpClientUpdate.post(`/technician/units`, qs.stringify({
      account_id: activeAccountId,
      unit_type: newUnitData.unit_type,
      appliance_id: newUnitData.appliance_id,
      other_appliance: newUnitData.other_appliance ? newUnitData.other_appliance : null,
      brand_id: newUnitData.appliance_brand_id,
      other_brand: newUnitData.other_brand ? newUnitData.other_brand : null,
      model_number: newUnitData.model_number,
      serial_number: newUnitData.serial_number,
      price: newUnitData.price,
      description: newUnitData.description,
      appointment_id: appointment_id,
      contact_id: client_id,
      job_id: jobId,
    }, { skipNulls: true }))
    if (response.data.success) {
      switchPage('main')
    }
  }

  useEffect(() => setReadyToSave(true), [
    newUnitData
  ])

  function checkOtherFieldAppliences() {
    let appliances = edit.appliances.filter(item => item.appliance_id === newUnitData.appliance_id)
    return appliances?.length === 1 ? appliances[0].name : ''
  }

  function checkOtherFieldBrand() {
    let brand = edit.appliance_brands.filter(item => item.brand_id === newUnitData.appliance_brand_id)
    return brand?.length === 1 ? brand[0].name : ''
  }

  function checkSave() {
    let check = false

    if (newUnitData.unit_type) {
      if (newUnitData.unit_type === '') {
        check = true
      }
    } else {
      check = true
    }

    if (newUnitData.appliance_id) {
      if (newUnitData.appliance_id === '') {
        check = true
      } else if (checkOtherFieldAppliences() === 'Other') {
        if (newUnitData.other_appliance === '') {
          check = true
        }
      }
    } else {
      check = true
    }

    if (newUnitData.appliance_brand_id) {
      if (newUnitData.appliance_brand_id === '') {
        check = true
      } else if (checkOtherFieldBrand() === 'Other') {
        if (newUnitData.other_brand === '') {
          check = true
        }
      }
    } else {
      check = true
    }

    if (newUnitData.description) {
      if (newUnitData.description === '') {
        check = true
      }
    } else {
      check = true
    }

    return check
  }

  return (
    <div className="UnitPage_Add entity-edit">
      < div className="wrapper flex-container sb" >
        <h1>Add new Unit</h1>

        <button
          style={{ background: '#d0d3da', color: 'white' }}
          className="_wa"
          onClick={() => switchPage('main')}
        >
          <Icon
            style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
            viewBox="0 0 24 24"
            icon="arrow-25"
          />
        </button>
      </div >

      <div className="fieldset">

        <div className={classNames('__show-on-wide', {
          __respectAsidePanel: navActive && !phoneCall,
          __phoneCall: phoneCall && !navActive,
          __bothOpen: navActive && phoneCall,
          __nonePanel: !navActive && !phoneCall
        })}>
          <div className="item">
            <div style={{ justifyContent: 'flex-start' }} className='row'>
              <div
                className='row unit_type'
                style={{ width: 'calc(30% - 20px)', marginRight: '20px' }}
              >
                <span>Unit type: <span className='requered_field'>*</span></span>

                <SelectComponent
                  options={edit.unit_type.sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1).map((type) => ({
                    span: type,
                    value: type
                  }))}
                  selectedOption={newUnitData.unit_type || ''}
                  onChange={(value: string) => setNewUnitData({ ...newUnitData, unit_type: value })}
                />
              </div>

              <div
                className='row unit_type'
                style={{ width: 'calc(30% - 20px)', marginRight: '20px' }}
              >
                <span>Unit:<span className='requered_field'>*</span></span>

                <SelectComponent
                  options={edit.appliances.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).sort((a, b) => a.name !== 'Other' ? 1 : -1).map((type) => ({
                    span: type.name,
                    value: type.appliance_id
                  }))}
                  selectedOption={newUnitData.appliance_id || ''}
                  onChange={(value: string) => setNewUnitData({ ...newUnitData, appliance_id: value, other_appliance: '' })}
                />
              </div>

              {
                checkOtherFieldAppliences() === 'Other' &&
                <div
                  className="row unit_type"
                  style={{ width: 'calc(30% - 20px)', marginRight: '20px' }}
                >
                  <span>Other Unit:<span className='requered_field'>*</span></span>
                  <input
                    className='model_number'
                    name='other_appliance'
                    type="text"
                    value={newUnitData.other_appliance}
                    onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, other_appliance: value })}
                  />
                </div>
              }
            </div>

            <div style={{ justifyContent: 'flex-start' }} className='row'>
              <div
                className='row unit_type'
                style={{ width: 'calc(30% - 20px)', marginRight: '20px' }}
              >
                <span>Brand:<span className='requered_field'>*</span></span>

                <SelectComponent
                  options={edit.appliance_brands.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).sort((a, b) => a.name !== 'Other' ? 1 : -1).map((type) => ({
                    span: type.name,
                    value: type.brand_id
                  }))}
                  selectedOption={newUnitData.appliance_brand_id || ''}
                  onChange={(value: string) => setNewUnitData({ ...newUnitData, appliance_brand_id: value, other_brand: '' })}
                />
              </div>

              {
                checkOtherFieldBrand() === '' &&
                <div
                  className="row unit_type"
                  style={{ width: 'calc(30% - 20px)', marginRight: '20px' }}
                >
                  <span>Other Brand:<span className='requered_field'>*</span></span>

                  <input
                    className='model_number'
                    name='other_brand'
                    type="text"
                    value={newUnitData.other_brand}
                    onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, other_brand: value })}
                  />
                </div>
              }
            </div>

            <div style={{ justifyContent: 'flex-start' }} className='row'>
              <div
                className="row unit_type"
                style={{ width: 'calc(30% - 20px)', marginRight: '20px' }}
              >
                <span>Model Number:</span>
                <input
                  className='model_number'
                  name='model_number'
                  type="text"
                  value={newUnitData.model_number}
                  onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, model_number: value })}
                />
              </div>

              <div
                className="row unit_type"
                style={{ width: 'calc(30% - 20px)', marginRight: '20px' }}
              >
                <span>Serial Number:</span>
                <input
                  className='serial_number'
                  name='serial_number'
                  type="text"
                  value={newUnitData.serial_number}
                  onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, serial_number: value })}
                />
              </div>
            </div>


            <div style={{ justifyContent: 'flex-start' }} className='row'>
              <span>Description:<span className='requered_field'>*</span></span>

              <textarea
                className='description'
                name='description'
                style={{ width: '100%' }}
                value={newUnitData.description}
                onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, description: value })}
              >

              </textarea>
            </div>
          </div>
        </div>

        {/* tablet */}
        <div className={classNames('__hide-on-wide', '__hide-on-mobile', {
          __respectAsidePanel: navActive && !phoneCall,
          __phoneCall: phoneCall && !navActive,
          __bothOpen: navActive && phoneCall,
          __nonePanel: !navActive && !phoneCall
        })}>
          <div className="item">
            <div className='row unit_type'>
              <span>Unit type: <span className='requered_field'>*</span></span>

              <SelectComponent
                options={edit.unit_type.sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1).map((type) => ({
                  span: type,
                  value: type
                }))}
                selectedOption={newUnitData.unit_type || ''}
                onChange={(value: string) => setNewUnitData({ ...newUnitData, unit_type: value })}
              />
            </div>

            <div
              className='row unit_type'
              style={{ marginBottom: checkOtherFieldAppliences() === 'Other' ? '10px' : '20px' }}
            >
              <span>Unit:<span className='requered_field'>*</span></span>

              <SelectComponent
                options={edit.appliances.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).sort((a, b) => a.name !== 'Other' ? 1 : -1).map((type) => ({
                  span: type.name,
                  value: type.appliance_id
                }))}
                selectedOption={newUnitData.appliance_id || ''}
                onChange={(value: string) => setNewUnitData({ ...newUnitData, appliance_id: value, other_appliance: '' })}
              />
            </div>

            {
              checkOtherFieldAppliences() === 'Other' &&
              <div className="row unit_type other">
                <span>Other Unit:<span className='requered_field'>*</span></span>
                <input
                  className='model_number'
                  name='other_appliance'
                  type="text"
                  value={newUnitData.other_appliance}
                  onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, other_appliance: value })}
                />
              </div>
            }

            <div
              className='row unit_type'
              style={{ marginBottom: checkOtherFieldBrand() === 'Other' ? '10px' : '20px' }}
            >
              <span>Brand:<span className='requered_field'>*</span></span>

              <SelectComponent
                options={edit.appliance_brands.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).sort((a, b) => a.name !== 'Other' ? 1 : -1).map((type) => ({
                  span: type.name,
                  value: type.brand_id
                }))}
                selectedOption={newUnitData.appliance_brand_id || ''}
                onChange={(value: string) => setNewUnitData({ ...newUnitData, appliance_brand_id: value, other_brand: '' })}
              />
            </div>

            {
              checkOtherFieldBrand() === 'Other' &&
              <div className="row unit_type other">
                <span>Other Brand:<span className='requered_field'>*</span></span>
                <input
                  className='model_number'
                  name='other_brand'
                  type="text"
                  value={newUnitData.other_brand}
                  onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, other_brand: value })}
                />
              </div>
            }

            <div className="row">
              <span>Model Number:</span>
              <input
                className='model_number'
                name='model_number'
                type="text"
                value={newUnitData.model_number}
                onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, model_number: value })}
              />
            </div>

            <div className="row">
              <span>Serial Number:</span>
              <input
                className='serial_number'
                name='serial_number'
                type="text"
                value={newUnitData.serial_number}
                onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, serial_number: value })}
              />
            </div>

            <div style={{ marginBottom: '0' }} className="row">
              <span>Description:<span className='requered_field'>*</span></span>

              <textarea
                className='description'
                name='description'
                value={newUnitData.description}
                onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, description: value })}
              >

              </textarea>
            </div>
          </div>
        </div>

        {/* mobile */}
        <div className={classNames('__show-on-mobile', {
          __respectAsidePanel: navActive && !phoneCall,
          __phoneCall: phoneCall && !navActive,
          __bothOpen: navActive && phoneCall,
          __nonePanel: !navActive && !phoneCall
        })}>
          <div className="item">
            <div className='row unit_type'>
              <span>Unit type: <span className='requered_field'>*</span></span>

              <SelectComponent
                options={edit.unit_type.sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1).map((type) => ({
                  span: type,
                  value: type
                }))}
                selectedOption={newUnitData.unit_type || ''}
                onChange={(value: string) => setNewUnitData({ ...newUnitData, unit_type: value })}
              />
            </div>

            <div
              className='row unit_type'
              style={{ marginBottom: checkOtherFieldAppliences() === 'Other' ? '10px' : '20px' }}
            >
              <span>Unit:<span className='requered_field'>*</span></span>

              <SelectComponent
                options={edit.appliances.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).sort((a, b) => a.name !== 'Other' ? 1 : -1).map((type) => ({
                  span: type.name,
                  value: type.appliance_id
                }))}
                selectedOption={newUnitData.appliance_id || ''}
                onChange={(value: string) => setNewUnitData({ ...newUnitData, appliance_id: value, other_appliance: '' })}
              />
            </div>

            {
              checkOtherFieldAppliences() === 'Other' &&
              <div className="row unit_type other">
                <span>Other Unit:<span className='requered_field'>*</span></span>
                <input
                  className='model_number'
                  name='other_appliance'
                  type="text"
                  value={newUnitData.other_appliance}
                  onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, other_appliance: value })}
                />
              </div>
            }

            <div
              className='row unit_type'
              style={{ marginBottom: checkOtherFieldBrand() === 'Other' ? '10px' : '20px' }}
            >
              <span>Brand:<span className='requered_field'>*</span></span>

              <SelectComponent
                options={edit.appliance_brands.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).sort((a, b) => a.name !== 'Other' ? 1 : -1).map((type) => ({
                  span: type.name,
                  value: type.brand_id
                }))}
                selectedOption={newUnitData.appliance_brand_id || ''}
                onChange={(value: string) => setNewUnitData({ ...newUnitData, appliance_brand_id: value, other_brand: '' })}
              />
            </div>

            {
              checkOtherFieldBrand() === 'Other' &&
              <div className="row unit_type other">
                <span>Other Brand:<span className='requered_field'>*</span></span>
                <input
                  className='model_number'
                  name='other_brand'
                  type="text"
                  value={newUnitData.other_brand}
                  onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, other_brand: value })}
                />
              </div>
            }

            <div className="row">
              <span>Model Number:</span>
              <input
                className='model_number'
                name='model_number'
                type="text"
                value={newUnitData.model_number}
                onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, model_number: value })}
              />
            </div>

            <div className="row">
              <span>Serial Number:</span>
              <input
                className='serial_number'
                name='serial_number'
                type="text"
                value={newUnitData.serial_number}
                onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, serial_number: value })}
              />
            </div>

            <div style={{ marginBottom: '0' }} className="row">
              <span>Description:<span className='requered_field'>*</span></span>

              <textarea
                className='description'
                name='description'
                value={newUnitData.description}
                onChange={({ target: { value } }) => setNewUnitData({ ...newUnitData, description: value })}
              >

              </textarea>
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper flex-container sb editing-buttons">
        <div className="buttons">
          <button
            className="_bordered _red"
            onClick={() => switchPage('main')}
          >
            Cancel
          </button>

          <button
            disabled={!readyToSave || checkSave()}
            className="_bordered _green"
            onClick={() => handleSave()}
          >
            Save
          </button>
        </div>
      </div>
    </div >
  )
}
