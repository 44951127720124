import { useEffect, useState } from 'react'
import classNames from 'classnames'

import Checkbox from '../../components/Checkbox'

import { httpClientUpdate } from '../../funcs'
import { useAppSelector } from '../../store/hooks'

interface EditDiscountProps {
  setPage: (value: string) => void
  service: { name: string, comment: string, amount: number, type: string, is_save: boolean, id: string }
  index: number
  handleSave: (name: string, comment: string, amount: number, type: string, is_save: boolean, id: string, index: number) => void
}

export default function EditDiscount({ setPage, service, index, handleSave }: EditDiscountProps) {
  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [data, setData] = useState({ name: '', comment: '', amount: 0, type: 'amount', is_save: false, id: '' })

  useEffect(() => {
    setData(service)
  }, [service])

  async function handleSaveService() {
    try {
      if (data.is_save) {
        // /api/technician/discounts
        const response = await httpClientUpdate.post(`/technician/discounts`, {
          account_id: activeAccountId,
          name: data.name,
          description: data.comment,
          price: Number(data.amount).toFixed(2),
        })

        if (response.data.success) {
          handleSave(data.name, data.comment, data.amount, data.type, data.is_save, data.id, index)
          setData({ name: '', comment: '', amount: 0, type: 'amount', is_save: false, id: '' })
        } else {
        }
      } else {
        handleSave(data.name, data.comment, data.amount, data.type, data.is_save, data.id, index)
        setData({ name: '', comment: '', amount: 0, type: 'amount', is_save: false, id: '' })
      }
    } catch (error) { }
  }

  function handleJobFocus(event: any) {
    const target = event.currentTarget;

    target.type = 'text';
    target.setSelectionRange(target.value.length, target.value.length);
    target.type = 'number';
    target && target.focus()
  }

  function handleJobChangeItem(event: any) {
    let price = event.target.value

    let numberWithoutDot = price.split('.')

    if (Number(price) >= 0) {
      if (numberWithoutDot[1] && numberWithoutDot[1].toString().length > 2) {
        let newNumber = `${numberWithoutDot[0]}${numberWithoutDot[1].toString()[0]}.${numberWithoutDot[1].toString()[1]}${price[price.length - 1]}`
        setData({ ...data, amount: Number(newNumber) })
      } else {
        let newNumber = price

        setData({ ...data, amount: Number(newNumber) })
      }
    }
  }

  function handleJobChangeItemBackspace(event: any) {
    if (event.keyCode === 8) {
      event.preventDefault()
      let price = event.target.value
      let numberWithoutDot = `${price}`.split('.')
      if (numberWithoutDot[0].length > 1) {
        let newNumber = `${numberWithoutDot[0].slice(0, -1)}.${numberWithoutDot[0][numberWithoutDot[0].length - 1]}${numberWithoutDot[1][0]}`
        setData({ ...data, amount: Number(newNumber) })
      } else {
        let newNumber = `0.${numberWithoutDot[0][0]}${numberWithoutDot[1][0]}`
        setData({ ...data, amount: Number(newNumber) })
      }
    }
  }

  function checkError() {
    let check = false

    if (data.type === 'percentage' && Number(data.amount) > 100) {
      check = true
    }

    return check
  }

  return (
    <div className="EstimatePage_Add entity-edit">
      <div className="wrapper flex-container sb editing">
        <div>
          <button
            onClick={() => setPage('editing')}
            className="button-cancel"
          >
            Cancel
          </button>
        </div>

        <div>
          <h1 style={{ whiteSpace: 'nowrap', marginTop: '0', color: '#393939' }}>Edit Discount</h1>
        </div>

        <div>
          <button
            className={classNames('button-save-disabled', {
              'button-save': data.amount !== 0 && data.name !== '' && !checkError()
            })}
            onClick={() => data.amount !== 0 && data.name !== '' && !checkError() && handleSaveService()}
          >
            Save
          </button>
        </div>
      </div>

      {
        (data.is_save || data.id) &&
        <div className='line-items-content service'>
          <div className='line-items-row'>
            <div className='name'>
              Name
            </div>

            <div className='name grey input-name'>
              <div className='input-view'>
                {data.name}
              </div>
            </div>
          </div>

          <div className='line-items-row'>
            <div className='name'>
              Description
            </div>

            <div className='name grey input-name'>
              <div className='input-view'>
                {data.comment}
              </div>
            </div>
          </div>

          <div className='line-items-row'>
            <div className='name'>
              Type
            </div>

            <div
              className='line-items-tabs'
              style={{ margin: '0' }}
            >
              <div
                className={classNames('line-items-tab', { active: data.type === 'amount' })}
                style={{ cursor: 'inherit' }}
              >
                Fixed Amount ($)
              </div>

              <div
                className={classNames('line-items-tab', { active: data.type === 'percentage' })}
                style={{ cursor: 'inherit' }}
              >
                Percentage (%)
              </div>
            </div>
          </div>

          <div className='line-items-row'>
            <div className='name'>
              Amount
            </div>

            <div className='name grey'>
              <input
                className="appearance"
                type='number'
                value={`${Number(data.amount).toFixed(2)}`}
                step={0.01}
                onFocus={(event) => handleJobFocus(event)}
                onChange={(event) => handleJobChangeItem(event)}
                onKeyDown={(event) => handleJobChangeItemBackspace(event)}
              />
            </div>
          </div>

          <span className='note'> Discount name appears on invoice </span>
        </div>
      }

      {
        !data.is_save && data.id === '' &&
        <div className='line-items-content service'>
          <div className='line-items-row'>
            <div className='name'>
              Name
            </div>

            <div className='name grey input-name'>
              <input
                type="text"
                placeholder='Service name'
                value={data.name}
                onChange={(event) => setData({ ...data, name: event.target.value })}
              />
            </div>
          </div>

          <div className='line-items-row'>
            <div className='name'>
              Description
            </div>

            <div className='name grey input-name'>
              <textarea
                placeholder='Description'
                rows={2}
                value={data.comment}
                onChange={(event) => setData({ ...data, comment: event.target.value })}
              />
            </div>
          </div>

          <div className='line-items-row'>
            <div className='name'>
              Type
            </div>

            <div
              className='line-items-tabs'
              style={{ margin: '0' }}
            >
              <div
                className={classNames('line-items-tab', { active: data.type === 'amount' })}
                onClick={() => setData({ ...data, type: 'amount' })}
              >
                Fixed Amount ($)
              </div>

              <div
                className={classNames('line-items-tab', { active: data.type === 'percentage' })}
                onClick={() => setData({ ...data, type: 'percentage' })}
              >
                Percentage (%)
              </div>
            </div>
          </div>

          <div className='line-items-row'>
            <div className='name'>
              Amount
            </div>

            <div className='name grey'>
            <input
                className={classNames('appearance',
                  { '_error': checkError() })}
                type='number'
                value={`${Number(data.amount).toFixed(2)}`}
                step={0.01}
                onFocus={(event) => handleJobFocus(event)}
                onChange={(event) => handleJobChangeItem(event)}
                onKeyDown={(event) => handleJobChangeItemBackspace(event)}
              />
            </div>
          </div>

          <div className='line-items-row'>
            <div className='name nowrap'>
              Save to price book
            </div>

            <Checkbox
              contents=""
              value={data.is_save}
              onChange={(value) => setData({ ...data, is_save: value })}
            />
          </div>

          <span className='note'> Discount name appears on invoice </span>
        </div>
      }
    </div>
  )
}