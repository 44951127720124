import './InputTextWithTitle.sass'

interface InputTextWithTitleProps {
  value: string
  setValue: (value: string) => void
  label: string
  width: number
}

export default function InputTextWithTitle({ value, setValue, label, width }: InputTextWithTitleProps) {
  return (
    <div
      className='input-text-title'
      style={{ width: `${width}px`, minWidth: `${width}px` }}
    >
      <span className="label">{label}</span>

      <input
        type="text"
        value={value}
        onChange={({ target: { value } }) => setValue(value)}
      />
    </div>
  )
}
