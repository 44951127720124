import { useState, useEffect } from 'react'
import { useRoute } from "react-router5"
import classNames from 'classnames'
import copy from 'copy-to-clipboard';

import Icon from '../../components/Icon'
import BlankPage from './BlankPage'
import TimeHasPassed from '../../components/TimeHasPassed'
import TimeLogBlock from './TimeLogBlock'
import Payment from './Payment'
import DefaultHouseImage from '../../images/house.png'
import MoreModal from './MoreModal';
import LinkModal from './LinkModal';

import ActiveWallet from '../../images/active_wallet.png'
import Wallet from '../../images/wallet.png'

import { useAppSelector } from '../../store/hooks'
import { dateToInfoBlock, formatPhoneNumberForItemTech, getSchuduleTime, getTimeHasPassed, httpClientUpdate, nErrorUpdate } from '../../funcs'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

import '../../styles/pages/jobItemTech.sass'
import ScheduleModal from './ScheduleModal';
import ImageModal from './ImageModal';
import ApprovalModal from './ApprovalModal';
import moment from 'moment';
import ItemsPaymentModal from './ItemsPaymentModal';
import ApprovalDocumentModal from './ApprovalDocumentModal';

interface HttpTimeLogReport {
  success: boolean
  error: {
    code: number,
    message: string
  },
  data: {
    time_slot_tasks: TimeSlotTasks[]
  }
}

export interface TimeSlotTasks {
  appointment_id: string
  created_by: string
  diff: string
  finish: string
  id: number
  job_id: string
  service_resource_id: string
  start: string
  time_slot_type: string
  updated_by: string
}

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: {
    job: JobDataProps
    permissions: PermissionsProps
  }
}

export interface PermissionsProps {
  technician_appointment_add: boolean
  technician_appointment_show: boolean
  technician_client_show: boolean
  technician_estimate_add: boolean
  technician_estimate_edit: boolean
  technician_estimate_show: boolean
  technician_invoice_add: boolean
  technician_invoice_show: boolean
  technician_map_show: boolean

  technician_part_add: boolean
  technician_part_edit: boolean
  technician_part_show: boolean

  technician_unit_add: boolean
  technician_unit_edit: boolean
  technician_unit_show: boolean

  job_search_show: boolean
  job_search_show_own: boolean

  payment_square_make: boolean
  payment_stripe_make: boolean

  payment_add: boolean
  payment_add_own: boolean

  payment_change_invoice: boolean
}

interface JobDataProps {
  job_id: string
  job_address: string
  job_name: string
  job_status: string
  contact_address_id: string
  contact_id: string
  company_id: string
  service_resource_id: string
  company_name: string
  is_need_payment: boolean
  appointment: {
    appointment_finish: string
    appointment_id: string
    appointment_start: string
    appointment_type: string
    appointment_note: string
    appointment_status: string
    invoice_status: string
    time_zone: string
    appointment_last_time_slot_task: {
      appointment_id: string
      start: string
      time_slot_task_id: string
      time_slot_type: string
    }
    appointment_phones: {
      laravel_through_key: string
      name: string
      phone: string
    }[]
  }
  other_appointments: OtherAppointmentsProps[]
  client: {
    address: string
    address_image: string
    client_id: string
    name: string
  }[]
  edit: EditProps
  estimates: {
    estimate_id: string
    estimate_total: string
    estimate_status: string | null
    name: string
  }[]
  invoices: InvoicesProps[]
  parts: {
    part_id: string
    part_name: string
    part_status: string
  }[]
  units: UnitProps[]
  payments: {
    company_name: string
    created_at: string
    deposit_id: string
    fee_compensation: string
    number: string
    payment_id: string
    service_name: string
    status: string
    total: string
    type: string
    items: {
      invoice_id: string
      job_id: string
      note: string
      payment_id: string
      total_item: string
    }[]
  }[]
  attachments: {
    document_id: string
    job_attachment_id: string
    type: string
    url: string
  }[]
  links: {
    amount: string
    created_at: string
    expired_date: string
    invoice_name: string
    link_id: string
    note: string
    payment_date_time: string
    status: string
    type: string
  }[]
  templates: {
    content: string
    type: string
  }[]
  approvals: {
    approval_id: string
    client_id: string
    created_at: string
    created_by: string
    email: string
    estimate_id: string
    invoice_id: string
    method: string
    phone: string
    service_resource_id: string
    type: string
    updated_at: string
    updated_by: string
    url_sign: string
    url_signed_document: string
  }[]
}

export interface OtherAppointmentsProps {
  appointment_finish: string
  appointment_id: string
  appointment_start: string
  appointment_type: string
  invoice_status: string
  time_zone: string
}

export interface UnitProps {
  appliance: string
  appliance_brand: string
  description: string
  model_number: string
  other_appliance: string
  other_brand: string
  serial_number: string
  unit_id: string
  unit_type: string
}

export interface InvoicesProps {
  invoice_id: string | null
  invoice_paid: string | null
  invoice_status: string | null
  invoice_total: string | null
  name: string
}

export interface EditProps {
  appliance_brands: {
    brand_id: string
    name: string
  }[]
  appliances: {
    appliance_id: string
    name: string
  }[]
  unit_type: string[]
  appointment_status: {
    appointment_status: string
    appointment_status_id: number
  }[]
  estimate_status: {
    estimate_status: string
    estimate_status_id: number
  }[]
  invoice_status: {
    invoice_status: string
    invoice_status_id: number
  }[]
  part_status: {
    part_status: string
    part_status_id: number
  }[]
  warehouses: {
    warehouse: string
    warehouse_id: string
  }[]
  payment_services: PaymentServicesProps[]
  job_status: {
    job_status: string
    job_status_id: number
  }[]
}

export interface PaymentServicesProps {
  name: string
  payment_services_id: string
  is_not_editable: boolean
}

export interface TimeObjectProps {
  start_hours: number
  start_minutes: number
  start_seconds: number
}

export interface DataProps {
  is_add_invoice: boolean
  job_id?: string
  invoice_id?: string
  contact_id?: string
  company_id?: string
  name: string
  note: string
  id: string | null
  total: number
  paid: number
  amount: number
  is_check: boolean
}

export default function ItemTech() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  const time_zone = user?.time_zone

  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)

  const [copied, setCopied] = useState(false)

  const [jobData, setJobData] = useState<JobDataProps | null>(null)
  const [permissions, setPermissions] = useState<Partial<PermissionsProps>>({})
  const [page, setPage] = useState('main')
  const [id, setId] = useState('')
  const [address, setAddress] = useState('')
  const [units, setUnits] = useState<UnitProps[]>([])

  const [approveText, setApproveText] = useState('')

  const [isStart, setIsStart] = useState('')
  const [isMyTyme, setIsMyTyme] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [timeSlotTaskId, setTimeSlotTaskId] = useState('')

  const [timeObject, setTimeObject] = useState<TimeObjectProps | null>(null)

  const [timeTrackingModal, setTimeTrackingModal] = useState(false)
  const [timeLogReport, setTimeLogReport] = useState<TimeSlotTasks[]>([])

  const [hover, setHover] = useState('')

  const [isCanceledAppointment, setIsCanceledAppointment] = useState(false)

  const [approvalModal, setApprovalModal] = useState(false)
  const [paymentModal, setPaymentModal] = useState(false)
  const [moreModal, setMoreModal] = useState(false)
  const [imageModal, setImageModal] = useState<{ url: string, id: string, is_delete: boolean } | null>(null)
  const [scheduleModal, setScheduleModal] = useState(false)
  const [linkModal, setLinkModal] = useState<{ status: string, id: string } | null>(null)
  const [itemPaymentModal, setItemPaymentModal] = useState<{ id: string, invoice_id: string } | null>(null)
  const [paymentPage, setPaymentPage] = useState('')
  const [paymentOtherName, setPaymentOtherName] = useState('')
  const [approveDocument, setApproveDocument] = useState('')

  const [isOpenScheduled, setIsOpenScheduled] = useState(true)

  // load Job
  async function loadJob() {
    try {
      const { data: { data: jobData, success, error } } = await httpClientUpdate.get('/technician/jobs/' + $router.router.getState().params.jobId, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpClientUpdateReport }
      if (success) {
        setJobData(jobData.job)
        setUnits(jobData.job.units)
        setPermissions(jobData.permissions)
        if (jobData.job.appointment.appointment_last_time_slot_task) {
          if (jobData.job.appointment.appointment_last_time_slot_task.time_slot_type === 'on_my_way') {
            let time = getTimeHasPassed(jobData.job.appointment.appointment_last_time_slot_task.start)

            setTimeObject({
              start_hours: time.hours,
              start_minutes: time.minutes,
              start_seconds: time.seconds,
            })
            setIsStart(jobData.job.appointment.appointment_last_time_slot_task.time_slot_task_id)
          } else if (jobData.job.appointment.appointment_last_time_slot_task.time_slot_type === 'start_my_time') {
            let time = getTimeHasPassed(jobData.job.appointment.appointment_last_time_slot_task.start)

            setTimeObject({
              start_hours: time.hours,
              start_minutes: time.minutes,
              start_seconds: time.seconds,
            })
            setIsMyTyme(true)
          }
        }
        if (jobData.job.templates) {
          jobData.job.templates.forEach(item => {
            if (item.type === 'text') {
              setApproveText(JSON.parse(item.content))
            }
          })
        }

        isSaving && setIsSaving(false)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      if (createdError.content.errorText === 'Appointment canceled') {
        setIsCanceledAppointment(true)
      } else {
        $router.router.navigate(`${createdError.content.code}`, {
          reload: true
        })
      }
    }
  }

  async function timeSlotTasks() {
    try {
      setIsSaving(true)
      if (!!isStart) {
        // https://2022back4.artemiudintsev.com/api/technician/time-slot-tasks/type-stop
        const response = await httpClientUpdate.post(`/technician/time-slot-tasks/type-stop`, {
          account_id: activeAccountId,
          appointment_id: jobData?.appointment.appointment_id,
          time_slot_task_id: isStart,
        })

        if (response.data.success) {
          setIsSaving(false)
          setIsStart('')
          setTimeObject(null)
        } else {
          setIsSaving(false)
        }
      } else {
        // https://2022back4.artemiudintsev.com/api/technician/time-slot-tasks/type-start
        const response = await httpClientUpdate.post(`/technician/time-slot-tasks/type-start`, {
          account_id: activeAccountId,
          appointment_id: jobData?.appointment.appointment_id,
          time_slot_type: 'on_my_way',
        })

        if (response.data.success) {
          setIsSaving(false)
          setIsStart(response.data.data.time_slot_task_id)
          isMyTyme && setIsMyTyme(false)

          if (timeObject) {
            setTimeObject(null)

            setTimeout(() => {
              setTimeObject({
                start_hours: 0,
                start_minutes: 0,
                start_seconds: 1,
              })
            }, 100)
          } else {
            setTimeObject({
              start_hours: 0,
              start_minutes: 0,
              start_seconds: 1,
            })
          }
        } else {
          setIsSaving(false)
        }
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  async function startMyTime() {
    try {
      setIsSaving(true)
      // https://2022back4.artemiudintsev.com/api/technician/time-slot-tasks/type-start
      const response = await httpClientUpdate.post(`/technician/time-slot-tasks/type-start`, {
        account_id: activeAccountId,
        appointment_id: jobData?.appointment.appointment_id,
        time_slot_type: 'start_my_time',
      })

      if (response.data.success) {
        if (jobData && jobData.appointment.appointment_status?.toLocaleLowerCase() === 'new') {
          handleAppointmentUpdate(jobData.appointment.appointment_id, jobData.appointment.appointment_status)
        }
        timeObject && setTimeObject(null)
        setIsSaving(false)
        setIsMyTyme(true)

        setTimeSlotTaskId(response.data.data.time_slot_task_id)

        !!isStart && setIsStart('')

        if (timeObject) {
          setTimeObject(null)

          setTimeout(() => {
            setTimeObject({
              start_hours: 0,
              start_minutes: 0,
              start_seconds: 1,
            })
          }, 100)
        } else {
          setTimeObject({
            start_hours: 0,
            start_minutes: 0,
            start_seconds: 1,
          })
        }
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  async function handleDeleteImage() {
    try {
      if (imageModal) {
        // api/technician/jobs/attachments/888882157iv5e6gtmc?account_id=88888
        const { data: { success } } = await httpClientUpdate.delete(`/technician/jobs/attachments/${imageModal.id}`, {
          params: {
            account_id: activeAccountId,
          }
        })
        if (success) {
          loadJob()
          setHover('')
          setImageModal(null)
        }
      }
    } catch (error) { }
  }

  async function stopMyTime() {
    try {
      setIsSaving(true)
      // https://2022back4.artemiudintsev.com/api/technician/time-slot-tasks/type-start
      const response = await httpClientUpdate.post(`/technician/time-slot-tasks/type-stop`, {
        account_id: activeAccountId,
        appointment_id: jobData?.appointment.appointment_id,
        time_slot_task_id: timeSlotTaskId,
      })

      if (response.data.success) {
        setTimeObject(null)
        setIsSaving(false)
        setIsMyTyme(false)

        !!isStart && setIsStart('')
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  async function getTimeLog() {
    try {
      setIsSaving(true)
      const { data: { data: report, success } } = await httpClientUpdate.get('/technician/time-slot-tasks/report', {
        params: {
          account_id: activeAccountId,
          job_id: jobData?.job_id,
          page: 1,
          limit_rows: 100,
        }
      }) as { data: HttpTimeLogReport }

      if (success) {
        setIsSaving(false)
        setTimeTrackingModal(true)
        setTimeLogReport(report.time_slot_tasks)
      }
    } catch (error) { }
  }

  async function handlePayment(type: string, data: DataProps[], total: number, check_number?: string) {
    try {
      setIsSaving(true)
      let payment_services_id = ''
      let type_name = ''

      if (type === 'Cash' || type === 'Check') {
        type_name = type
      } else {
        type_name = 'Other'
      }

      jobData?.edit.payment_services.forEach(item => {
        if (item.name === type) {
          payment_services_id = item.payment_services_id
        }
      })

      //  https://2022back4.artemiudintsev.com/api/payments
      const response = await httpClientUpdate.post(`/payments`, {
        account_id: activeAccountId,
        type: type_name,
        service_id: payment_services_id,
        number: check_number ? check_number : `${total}`,
        items: data.map(item => {
          if (item.is_add_invoice) {
            if (item.invoice_id) {
              return {
                service_resource_id: jobData?.service_resource_id,
                note: item.note,
                total_item: item.amount,
                invoice_id: item.invoice_id,
                job_id: item.job_id
              }
            } else {
              return {
                service_resource_id: jobData?.service_resource_id,
                note: item.note,
                total_item: item.amount,
                job_id: item.job_id
              }
            }
          } else {
            if (item.id) {
              return {
                service_resource_id: jobData?.service_resource_id,
                note: item.note,
                total_item: item.amount,
                invoice_id: item.id,
                job_id: jobData?.job_id
              }
            } else {
              return {
                service_resource_id: jobData?.service_resource_id,
                note: item.note,
                total_item: item.amount,
                job_id: jobData?.job_id
              }
            }
          }
        })
      }) as { data: { success: boolean } }

      if (response.data.success) {
        setPaymentModal(false)
        setPaymentOtherName('')
        setPaymentPage('')
        setIsSaving(false)
        loadJob()
      }
    } catch (error) { }
  }

  async function handleLink() {
    try {
      if (linkModal) {
        // api/links/888882131231/cancel-restore
        const response = await httpClientUpdate.post(`/links/${linkModal.id}/cancel-restore`, {
          account_id: activeAccountId,
          type: linkModal.status.toLocaleLowerCase() === 'canceled' ? 'restore' : 'cancel',
        })

        if (response.data.success) {
          setLinkModal(null)
          loadJob()
        }
      }
    } catch (error) { }
  }

  async function handleItemPayment() {
    try {
      if (itemPaymentModal) {
        // api/technician/payments/items/888883081tgkdz3nip/invoices
        const response = await httpClientUpdate.put(`/technician/payments/items/${itemPaymentModal.id}/invoices`, {
          account_id: activeAccountId,
          invoice_id: itemPaymentModal.invoice_id,
        })

        if (response.data.success) {
          setItemPaymentModal(null)
          loadJob()
        }
      }
    } catch (error) { }
  }

  async function handleSendLink(phone: string) {
    try {
      // api/links/approve
      const response = await httpClientUpdate.post(`/links/approve`, {
        account_id: activeAccountId,
        phone: phone,
        job_id: jobData?.job_id,
        service_resource_id: jobData?.service_resource_id,
      })

      if (response.data.success) {
        setApprovalModal(false)
        loadJob()
      }
    } catch (error) { }
  }

  async function handleApproveLink(sign: string, document: string) {
    try {
      let file = sign.replace('data:image/png;base64,', '').replace('data:image/jpeg;base64,', '')
      let document_base64 = document.replace('data:application/pdf;base64, ', '')

      // api/technician/approvals
      const response = await httpClientUpdate.post(`/technician/approvals`, {
        account_id: activeAccountId,
        url_sign: file,
        url_signed_document: document_base64,
        job_id: jobData?.job_id,
        service_resource_id: jobData?.service_resource_id,
      })

      if (response.data.success) {
        setApprovalModal(false)
        loadJob()
      }
    } catch (error) { }
  }

  async function handleAppointmentUpdate(appointment_id: string, status: string) {
    try {
      let status_id = 0
      let status_updated = ''

      if (status.toLowerCase() === 'new') {
        status_updated = 'open'
      } else if (status.toLowerCase() === 'open') {
        status_updated = 'closed'
      } else if (status.toLowerCase() === 'closed') {
        status_updated = 'open'
      }

      jobData?.edit.appointment_status.forEach(item => {
        if (item.appointment_status.toLowerCase() === status_updated) {
          status_id = item.appointment_status_id
        }
      })

      // /api/technician/appointments/{APPOINTMENT_ID}?account_id=88888
      const response = await httpClientUpdate.put(`/technician/appointments/${appointment_id}`, {
        account_id: activeAccountId,
        status: status_id,
      })

      if (response.data.success) {
        loadJob()
        setScheduleModal(false)
      }
    } catch (error) { }
  }

  async function handleJobUpdate(job_id: string, status: string) {
    try {
      setIsSaving(true)
      let status_id = 0
      let status_updated = ''

      if (status.toLowerCase() === 'new') {
        status_updated = 'open'
      } else if (status.toLowerCase() === 'open') {
        status_updated = 'closed'
      } else if (status.toLowerCase() === 'closed') {
        status_updated = 'open'
      }

      jobData?.edit.job_status.forEach(item => {
        if (item.job_status.toLowerCase() === status_updated) {
          status_id = item.job_status_id
        }
      })

      // /api/technician/jobs/{JOB_ID}?account_id=88888
      const response = await httpClientUpdate.put(`/technician/jobs/${job_id}`, {
        account_id: activeAccountId,
        status: status_id,
      })

      if (response.data.success) {
        loadJob()
      }
    } catch (error) { }
  }

  useEffect(() => {
    if (page === 'main') {
      loadJob()
      setId('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => {
    if (paymentModal) {
      setPaymentPage('')
    }
  }, [paymentModal])

  function handleCloseTimeLog() {
    setTimeTrackingModal(false)
    setTimeLogReport([])
  }

  function switchPage(name: string) {
    setPage(name)
  }

  function handleClose() {
    setPaymentModal(false)
    setPaymentPage('')
  }

  function handleCloseCardModal() {
    setPaymentModal(false)
    setPaymentPage('')
    loadJob()
  }

  function getInvoiceName(id: string, job_name: string) {
    let name = ''

    jobData?.invoices.forEach(item => {
      if (item.invoice_id === id) {
        name = item.name
      }
    })

    return name ? name : job_name
  }

  function getPaidSumForJob() {
    let sum = 0

    jobData?.payments.forEach(payment => {
      payment.items.forEach(item => {
        if (!item.invoice_id) {
          sum = sum + Number(item.total_item)
        }
      })
    })

    return sum
  }

  async function handleCopy(e: any, text: string) {
    setCopied(true)
    copy(text)
  }

  function getStatusId() {
    let id = 0

    if (jobData) {
      jobData.edit.job_status.forEach(item => {
        if (item.job_status === jobData.job_status) {
          id = item.job_status_id
        }
      })
    }

    return id
  }

  function checkExpiredDate(day: string) {
    let expired = false

    if (moment().format('YYYY-MM-DD') > day) {
      expired = true
    }

    return expired
  }

  function getFullAmount(payment_total: string, item_total: string) {
    let full_amount = ''
    if (payment_total && item_total) {
      if (payment_total.replace('$', '') !== item_total) {
        full_amount = `Full payment amount: ${payment_total}`
      }
    }

    return full_amount
  }

  return (
    <>
      {
        !isCanceledAppointment ?
          page === 'main' ?
            <div className='job-tech'>
              {
                jobData &&
                <>
                  {/* header for desctop and table */}
                  <div className={classNames('job-tech-header', '__hide-on-mobile',
                    {
                      __respectAsidePanel: navActive.is && !phoneCall,
                      __phoneCall: phoneCall && !navActive.is,
                      __bothOpen: navActive.is && phoneCall,
                      __nonePanel: !navActive.is && !phoneCall
                    }
                  )}
                  >
                    <button
                      onClick={() =>
                        // reportData.permissions.job_show &&
                        $router.router.navigate('appointment_calendar', {
                          companyId: activeAccountId
                        }, { reload: true })}
                    >
                      <Icon viewBox="0 0 24 24" icon="arrow-25" />
                    </button>
                    <div className='header-info-image' >
                      {
                        jobData.client.length ?
                          <img
                            style={{ objectFit: 'cover' }}
                            src={jobData.client[0].address_image ? jobData.client[0].address_image : DefaultHouseImage}
                            alt="House"
                          /> :
                          <img
                            style={{ objectFit: 'cover' }}
                            src={DefaultHouseImage}
                            alt="House"
                          />
                      }
                      <div className='header-info'>
                        <div className='header-info-customer'>
                          {!!jobData.client.length && jobData.client[0].name}
                        </div>

                        <div className='header-info-address'>
                          {!!jobData.client.length && jobData.client[0].address}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* header for mobile */}
                  <div className={classNames('job-tech-header mobile', '__show-on-mobile',
                    {
                      __respectAsidePanel: navActive.is && !phoneCall,
                      __phoneCall: phoneCall && !navActive.is,
                      __bothOpen: navActive.is && phoneCall,
                      __nonePanel: !navActive.is && !phoneCall
                    }
                  )}
                  >
                    <div
                      className='header-arrow-button'
                    >
                      <button
                        className={classNames({
                          unactive: (paymentPage !== '' && paymentPage !== 'other_list') || paymentOtherName !== ''
                        })}
                        onClick={() => {
                          paymentPage === 'other_list' && paymentOtherName === '' && paymentModal && setPaymentPage('')
                          paymentPage === '' && paymentModal && setPaymentModal(false)
                          paymentPage === '' && !paymentModal &&
                            $router.router.navigate('appointment_calendar', {
                              companyId: activeAccountId
                            }, { reload: true })
                        }}
                      >
                        <Icon style={{ width: '18px' }} viewBox="0 0 24 24" icon="arrow-25" />
                      </button>

                      <div className='header-arrow-info'>
                        <div title={jobData.job_name} className='header-arrow-info-text'>
                          Job: {jobData.job_name}
                        </div>
                      </div>

                      <div className='header-arrow-info-none'></div>
                    </div>
                  </div>

                  {/* Buttons block*/}
                  <div
                    className={classNames('job-buttons', '__hide-on-mobile',
                      {
                        __respectAsidePanel: navActive.is && !phoneCall,
                        __phoneCall: phoneCall && !navActive.is,
                        __bothOpen: navActive.is && phoneCall,
                        __nonePanel: !navActive.is && !phoneCall
                      }
                    )}
                  >
                    <button
                      className="unactive"
                    >
                      Messages
                    </button>

                    <button
                      className="unactive"
                    >
                      Calls
                    </button>

                    <button
                      className={classNames(
                        {
                          active: !!isStart
                        }
                      )}
                      onClick={() => !isSaving && timeSlotTasks()}
                    >
                      On my way
                    </button>

                    <button
                      onClick={() => !isSaving && !isMyTyme ? startMyTime() : stopMyTime()}
                    >
                      {isMyTyme ? 'Stop' : 'Start'}
                    </button>

                    <button
                      className={classNames({
                        unactive: paymentPage !== '' || !jobData.is_need_payment || (!permissions.payment_add && !permissions.payment_add_own)
                      })}
                      onClick={() => {
                        (permissions.payment_add || permissions.payment_add_own) && paymentPage === '' && jobData.is_need_payment && setPaymentModal(true)
                        moreModal && setMoreModal(false)
                      }}
                    >
                      Payment
                    </button>
                  </div>

                  <div
                    className={classNames('job-buttons mobile', '__show-on-mobile',
                      {
                        __respectAsidePanel: navActive.is && !phoneCall,
                        __phoneCall: phoneCall && !navActive.is,
                        __bothOpen: navActive.is && phoneCall,
                        __nonePanel: !navActive.is && !phoneCall
                      }
                    )}
                  >
                    <button
                      className="unactive stroke"
                    >
                      <Icon viewBox="0 0 87 83" icon="message-job-tech-1" />

                      <div className='title'>
                        Messages
                      </div>
                    </button>

                    <button
                      className="unactive stroke"
                    >
                      <Icon viewBox="0 0 80 80" icon="calls-job-tech-1" />

                      <div className='title'>
                        Calls
                      </div>
                    </button>

                    <button
                      className={classNames({
                        unactive: paymentPage !== '' || !jobData.is_need_payment || (!permissions.payment_add && !permissions.payment_add_own)
                      })}
                      onClick={() => {
                        (permissions.payment_add || permissions.payment_add_own) && paymentPage === '' && jobData.is_need_payment && setPaymentModal(true)
                        moreModal && setMoreModal(false)
                      }}
                    >
                      {
                        (paymentPage !== '' || !jobData.is_need_payment || (!permissions.payment_add && !permissions.payment_add_own)) ?
                          <img src={Wallet} alt="Wallet" /> :
                          <img src={ActiveWallet} alt="Wallet" />
                      }

                      <div className='title'>
                        Pay
                      </div>
                    </button>

                    <button
                      className={classNames('stroke', {
                        unactive: paymentPage !== ''
                      })}
                      onClick={() => setMoreModal(true)}
                    >
                      <Icon style={{ height: '28px' }} viewBox="0 0 18 76" icon="dots-job-tech-1" />

                      <div className='title'>
                        More
                      </div>
                    </button>
                  </div>

                  {/* Content block */}
                  {/* Customer block */}
                  <div
                    className={classNames('job-tech-content', '__hide-on-mobile',
                      {
                        __respectAsidePanel: navActive.is && !phoneCall,
                        __phoneCall: phoneCall && !navActive.is,
                        __bothOpen: navActive.is && phoneCall,
                        __nonePanel: !navActive.is && !phoneCall,
                      }
                    )}
                  >
                    <div className='content-title'>
                      Customer
                    </div>

                    <div
                      className='content-customer'
                      style={{ cursor: permissions.technician_client_show ? 'pointer' : 'inherit' }}
                      onClick={() => permissions.technician_client_show && setPage('client')}
                    >
                      <div className='content-customer-left'>
                        <Icon viewBox="3 0 22 20" icon="pin-1" />
                      </div>

                      <div className='content-customer-center'>
                        {!!jobData.client.length && jobData.client[0].name}
                      </div>

                      <div className='content-customer-right'>
                        {
                          permissions.technician_client_show &&
                          <button
                            onClick={() => permissions.technician_client_show && setPage('client')}
                          >
                            <Icon viewBox="0 0 24 24" icon="arrow-25" />
                          </button>
                        }
                      </div>
                    </div>

                    <div
                      className='content-customer'
                      style={{ cursor: permissions.technician_map_show ? 'pointer' : 'inherit' }}
                      onClick={() => {
                        permissions.technician_map_show && jobData.job_address && setPage('google_map')
                        permissions.technician_map_show && jobData.job_address && setAddress(jobData.job_address)
                      }}
                    >
                      <div className='content-customer-left'>
                        <Icon viewBox="0 0 11 11" icon="location-1" />
                      </div>

                      <div className='content-customer-center'>
                        {jobData.job_address}
                      </div>

                      <div className='content-customer-right'>
                        {
                          permissions.technician_map_show &&
                          jobData.job_address &&
                          <button
                            onClick={() => {
                              setPage('google_map')
                              setAddress(jobData.job_address)
                            }}
                          >
                            <Icon viewBox="0 0 24 24" icon="arrow-25" />
                          </button>
                        }
                      </div>
                    </div>
                  </div>

                  {
                    scheduleModal &&
                    <ScheduleModal
                      navActive={navActive}
                      phoneCall={phoneCall}
                      setModal={setScheduleModal}
                      appointment={jobData.appointment}
                      job_info={{
                        job_address: jobData.job_address,
                        job_id: jobData.job_id,
                        job_name: jobData.job_name,
                        job_status: jobData.job_status
                      }}
                      handleAppointmentUpdate={handleAppointmentUpdate}
                      handleJobUpdate={handleJobUpdate}
                      isSaving={isSaving}
                    />
                  }

                  {
                    !!imageModal &&
                    <ImageModal
                      navActive={navActive}
                      phoneCall={phoneCall}
                      setModal={setImageModal}
                      modal={imageModal}
                      handleDelete={handleDeleteImage}
                    />
                  }

                  {
                    !!approveDocument &&
                    <ApprovalDocumentModal
                      navActive={navActive}
                      phoneCall={phoneCall}
                      setModal={setApproveDocument}
                      url={approveDocument}
                    />
                  }

                  {
                    approvalModal &&
                    <ApprovalModal
                      navActive={navActive}
                      phoneCall={phoneCall}
                      setModal={setApprovalModal}
                      handleSendLink={handleSendLink}
                      approveText={approveText}
                      handleApproveLink={handleApproveLink}
                    />
                  }

                  {
                    !!linkModal &&
                    <LinkModal
                      navActive={navActive}
                      phoneCall={phoneCall}
                      setModal={setLinkModal}
                      handleSave={handleLink}
                      status={linkModal?.status ? linkModal.status : ''}
                    />
                  }

                  {
                    !!itemPaymentModal &&
                    <ItemsPaymentModal
                      navActive={navActive}
                      phoneCall={phoneCall}
                      setModal={setItemPaymentModal}
                      invoices={jobData.invoices}
                      data={itemPaymentModal}
                      handleSave={handleItemPayment}
                    />
                  }

                  {
                    moreModal &&
                    <MoreModal
                      navActive={navActive}
                      phoneCall={phoneCall}
                      setModal={setMoreModal}
                      unactive={paymentPage !== '' || !jobData.is_need_payment || (!permissions.payment_add && !permissions.payment_add_own)}
                      setPaymentModal={setPaymentModal}
                      setPage={setPage}
                      permissions={{
                        technician_unit_add: permissions.technician_unit_add ? permissions.technician_unit_add : false,
                        technician_estimate_add: permissions.technician_estimate_add ? permissions.technician_estimate_add : false,
                        technician_part_add: permissions.technician_part_add ? permissions.technician_part_add : false,
                      }}
                    />
                  }

                  {
                    timeTrackingModal &&
                    <TimeLogBlock
                      navActive={navActive}
                      phoneCall={phoneCall}
                      handleCloseTimeLog={handleCloseTimeLog}
                      data={timeLogReport}
                      isStart={isStart}
                      isMyTyme={isMyTyme}
                    />
                  }

                  {
                    paymentModal &&
                    <Payment
                      setPaymentModal={setPaymentModal}
                      navActive={navActive}
                      phoneCall={phoneCall}
                      paymentPage={paymentPage}
                      setPaymentPage={setPaymentPage}
                      invoices={jobData.invoices}
                      permission_search_show={!!permissions.job_search_show || !!permissions.job_search_show_own}
                      job_name={jobData.job_name}
                      permissions_payment_make={!!permissions.payment_square_make && !!permissions.payment_stripe_make}
                      payment_services={jobData.edit.payment_services.filter(item => !item.is_not_editable)}
                      payment_services_all={jobData.edit.payment_services}
                      paymentOtherName={paymentOtherName}
                      setPaymentOtherName={setPaymentOtherName}
                      payment_square_make={!!permissions.payment_square_make}
                      payment_stripe_make={!!permissions.payment_stripe_make}
                      handlePayment={handlePayment}
                      handleClose={handleClose}
                      isSaving={isSaving}
                      job_id={jobData.job_id}
                      handleCloseCardModal={handleCloseCardModal}
                      company_id={jobData.company_id}
                      contact_id={jobData.client[0].client_id}
                      paid_job={getPaidSumForJob()}
                      service_resource_id={jobData.service_resource_id}
                    />
                  }

                  <div
                    className={classNames('job-tech-content mobile', '__show-on-mobile',
                      {
                        __respectAsidePanel: navActive.is && !phoneCall,
                        __phoneCall: phoneCall && !navActive.is,
                        __bothOpen: navActive.is && phoneCall,
                        __nonePanel: !navActive.is && !phoneCall,
                        __disabled: timeTrackingModal || paymentModal
                      }
                    )}
                  >
                    <div style={{ padding: '5px 15px', marginTop: '10px', alignItems: 'flex-start' }} className='content-title'>
                      <div
                        className='content-title-row'
                      >
                        <div
                          onClick={() => setIsOpenScheduled(!isOpenScheduled)}
                        >
                          <Icon
                            className={classNames({ _flipped: !isOpenScheduled })}
                            style={{ width: '12px', minWidth: '12px' }}
                            icon="arrow-65"
                          />
                        </div>

                        <div
                          className='mobile-text status'
                          onClick={() => setScheduleModal(true)}
                        >
                          {jobData.appointment.appointment_status}
                        </div>
                      </div>

                      <div
                        className='content-title-row'
                        onClick={() => getTimeLog()}
                      >
                        <div className='mobile-text' >
                          {
                            timeObject ?
                              <TimeHasPassed
                                start_hours={timeObject.start_hours}
                                start_minutes={timeObject.start_minutes}
                                start_seconds={timeObject.start_seconds}
                              /> : 'Time log'
                          }
                        </div>

                        <Icon
                          style={{ width: '12px', minWidth: '12px' }}
                          viewBox="0 0 24 24"
                          icon="arrow-25"
                        />
                      </div>
                    </div>

                    {
                      isOpenScheduled &&
                      <div className='content-customer-mobile'>

                        <div className='content-customer-actions'>
                          <div
                            className='content-customer-actions-btn'
                            onClick={() => {
                              permissions.technician_map_show && jobData.job_address && setPage('google_map')
                              permissions.technician_map_show && jobData.job_address && setAddress(jobData.job_address)
                            }}
                          >
                            <div className='content-customer-actions-btn-pic'>
                              <Icon
                                style={{ marginRight: '5px' }}
                                viewBox="0 0 100 100"
                                icon="cursor-job-tech-1"
                              />
                            </div>

                            <div className='mobile-text'>
                              Navigate
                            </div>
                          </div>

                          <div
                            className={classNames('content-customer-actions-btn',
                              {
                                is_start: !!isStart,
                              }
                            )}
                            onClick={() => !isSaving && timeSlotTasks()}
                          >
                            <div className='content-customer-actions-btn-pic'>
                              <Icon
                                viewBox="0 0 136 92"
                                style={{ fill: '#fff', width: '28px' }}
                                icon="group-2"
                              />
                            </div>

                            <div className='mobile-text'>
                              On my way
                            </div>
                          </div>

                          <div
                            className='content-customer-actions-btn'
                            onClick={() => !isSaving && !isMyTyme ? startMyTime() : stopMyTime()}
                          >
                            <div className='content-customer-actions-btn-pic'>
                              {
                                isMyTyme ?
                                  <Icon
                                    icon="media-control-49"
                                  /> :
                                  <Icon
                                    style={{ marginLeft: '5px' }}
                                    viewBox="0 0 86 88"
                                    icon="play-job-tech-1"
                                  />
                              }
                            </div>

                            <div className='mobile-text'>
                              {isMyTyme ? 'Stop my time' : 'Start my time'}
                            </div>
                          </div>
                        </div>

                        <div className='content-customer-appointment'>
                          <div className='content-customer-appointment-row'>
                            <div className='mobile-text'>
                              Current appointment:
                            </div>

                            <div
                              className={classNames('content-customer-appointment-type',
                                {
                                  SC: jobData.appointment.appointment_type && jobData.appointment.appointment_type === 'SC',
                                  RC: jobData.appointment.appointment_type && jobData.appointment.appointment_type === 'RC',
                                  FU: jobData.appointment.appointment_type && jobData.appointment.appointment_type === 'FU',
                                }
                              )}
                            >
                              {jobData.appointment.appointment_type}
                            </div>
                          </div>

                          <div className='content-customer-appointment-row'>
                            <div className='content-customer-appointment-date'>
                              {getSchuduleTime(jobData.appointment.appointment_start, jobData.appointment.appointment_finish, jobData.appointment.time_zone)}
                            </div>
                          </div>

                          <div className='content-customer-appointment-row'>
                            <div className='mobile-text'>
                              Note:
                            </div>

                            <div className='content-customer-appointment-note'>
                              {jobData.appointment.appointment_note}
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>

                  <div
                    className={classNames('job-tech-customer mobile', '__show-on-mobile',
                      {
                        __respectAsidePanel: navActive.is && !phoneCall,
                        __phoneCall: phoneCall && !navActive.is,
                        __bothOpen: navActive.is && phoneCall,
                        __nonePanel: !navActive.is && !phoneCall,
                        __disabled: timeTrackingModal || paymentModal
                      }
                    )}
                  >
                    <div className='content-title'>
                      <div
                        className='content-title-row'
                        style={{ alignItems: 'center' }}
                      >
                        <Icon viewBox='0 0 85 85' icon="customer-job-tech-1" />

                        <div className='mobile-text'>
                          Customer
                        </div>
                      </div>
                    </div>

                    <div className='job-tech-customer-appointments'>
                      <div className='job-tech-customer-appointments-row'>
                        <div className='job-tech-customer-appointments-title'>
                          {!!jobData.client.length && jobData.client[0].name}
                        </div>

                        <div className='content-customer-right'>
                          <button
                            disabled={!permissions.technician_client_show}
                            onClick={() => permissions.technician_client_show && setPage('client')}
                          >
                            <Icon viewBox="0 0 24 24" icon="arrow-25" />
                          </button>
                        </div>
                      </div>

                      <div
                        className='job-tech-customer-appointments-row'
                        style={{ justifyContent: 'flex-start' }}
                      >
                        <div className='job-tech-customer-appointments-title'>
                          {jobData.job_address}
                        </div>
                        <button
                          style={{ marginLeft: '5px', width: 'fit-content', backgroundColor: 'inherit' }}
                          onClick={(e) => handleCopy(e, jobData.job_address)}
                          title="Copy address"
                        >
                          <Icon
                            style={{
                              width: '18px',
                              minWidth: '18px',
                              height: '18px',
                              verticalAlign: 'middle',
                              overflow: 'hidden',
                              fill: copied ? '#6093DE' : 'inherit'
                            }}
                            viewBox="0 0 1024 1024"
                            icon="copy-1"
                          />
                        </button>
                      </div>

                      {
                        jobData.appointment.appointment_phones.map((item, index) => (
                          <div key={index} className='job-tech-customer-appointments-row'>
                            <div className='job-tech-customer-appointments-title'>
                              <a href={`tel:${item.phone}`}>
                                {`${formatPhoneNumberForItemTech(item.phone)} (${item.name})`}
                              </a>
                            </div>
                          </div>
                        ))
                      }

                      {
                        !!jobData.other_appointments.length &&
                        <div className='job-tech-customer-appointments-row'>
                          <div></div>

                          <div
                            className='job-tech-customer-appointments-show'
                            style={{
                              opacity: '1',
                              cursor: 'pointer'
                            }}
                            onClick={() => setPage('other_appointments')}
                          >
                            <div className='mobile-text nowrap'>
                              All appointments for this job
                            </div>

                            <button style={{ cursor: 'inherit' }}>
                              <Icon viewBox="0 0 24 24" icon="arrow-25" />
                            </button>
                          </div>
                        </div>
                      }
                    </div>
                  </div>

                  {/* Units block */}
                  <div
                    className={classNames('job-tech-content', '__hide-on-mobile',
                      {
                        __respectAsidePanel: navActive.is && !phoneCall,
                        __phoneCall: phoneCall && !navActive.is,
                        __bothOpen: navActive.is && phoneCall,
                        __nonePanel: !navActive.is && !phoneCall
                      }
                    )}
                  >
                    <div className='content-title content-title-action'>
                      <span>Units</span>
                      {
                        permissions.technician_unit_add &&
                        <button
                          onClick={() => setPage('unit_add')}
                        >
                          <Icon viewBox="0 0 24 24" icon="x-mark-1" />
                        </button>
                      }
                    </div>

                    {
                      jobData.units.map((device, index) => (
                        <div
                          key={index}
                          className='content-customer'
                          style={{ cursor: permissions.technician_unit_show ? 'pointer' : 'inherit' }}
                          onClick={() => {
                            permissions.technician_unit_show && setPage('unit_edit')
                            permissions.technician_unit_show && setId(device.unit_id)
                          }
                          }
                        >
                          <div className='content-customer-left'>
                            <span className='device'></span>
                          </div>

                          <div className='content-customer-center'>
                            {device.unit_type}: {device.appliance === 'Other' ? device.other_appliance : device.appliance}, {device.appliance_brand === 'Other' ? device.other_brand : device.appliance_brand}
                          </div>

                          <div className='content-customer-right'>
                            {
                              permissions.technician_unit_show &&
                              <button
                                onClick={() => {
                                  setPage('unit_edit')
                                  setId(device.unit_id)
                                }
                                }
                              >
                                <Icon viewBox="0 0 24 24" icon="arrow-25" />
                              </button>
                            }
                          </div>
                        </div>
                      ))
                    }
                  </div>

                  <div
                    className={classNames('job-tech-content', '__hide-on-mobile',
                      {
                        __respectAsidePanel: navActive.is && !phoneCall,
                        __phoneCall: phoneCall && !navActive.is,
                        __bothOpen: navActive.is && phoneCall,
                        __nonePanel: !navActive.is && !phoneCall,
                        __disabled: timeTrackingModal || paymentModal
                      }
                    )}
                  >
                    <div className='content-title content-title-action'>
                      <span>Approval</span>

                      <button
                        onClick={() => setApprovalModal(true)}
                      >
                        <Icon viewBox="0 0 24 24" icon="x-mark-1" />
                      </button>
                    </div>

                    {
                      jobData.approvals.map((approv, index) => (
                        <div
                          key={index}
                          className='content-customer'
                          style={{ cursor: approv.url_signed_document ? 'pointer' : 'inherit' }}
                          onClick={() => {
                            approv.url_signed_document && setApproveDocument(approv.url_signed_document)
                          }}
                        >
                          <div className='content-customer-left'>
                          </div>

                          <div className='content-customer-center'>
                            {
                              approv.method === 'manual' ?
                                `Manual Approval for Job ${jobData.job_name}` :
                                `Remote Approval for Job ${jobData.job_name}`
                            }

                            <span style={{ marginLeft: '20px', fontSize: '12px', fontWeight: 'normal' }}>
                              {dateToInfoBlock('MM/dd/yyyy hh:mma', time_zone, approv.created_at)}
                            </span>


                            {
                              approv.method !== 'manual' &&
                              <span style={{ marginLeft: '20px', fontSize: '12px', fontWeight: 'normal' }}>
                                {formatPhoneNumberForItemTech(approv.phone)}
                              </span>
                            }

                            {
                              approv.method !== 'manual' &&
                              <span style={{ marginLeft: '20px', fontSize: '12px', fontWeight: 'normal' }}>
                                {approv.email}
                              </span>
                            }
                          </div>

                          <div className='content-customer-right'>
                            {
                              approv.url_signed_document &&
                              <button
                              >
                                <Icon viewBox="0 0 24 24" icon="arrow-25" />
                              </button>
                            }
                          </div>
                        </div>
                      ))
                    }
                  </div>

                  <div
                    className={classNames('job-tech-content mobile', '__show-on-mobile',
                      {
                        __respectAsidePanel: navActive.is && !phoneCall,
                        __phoneCall: phoneCall && !navActive.is,
                        __bothOpen: navActive.is && phoneCall,
                        __nonePanel: !navActive.is && !phoneCall,
                        __disabled: timeTrackingModal || paymentModal
                      }
                    )}
                  >
                    <div className='content-title'>
                      <div
                        className='content-title-row'
                        style={{ alignItems: 'center' }}
                      >
                        <Icon viewBox='0 0 70 77' icon="units-job-tech-1" />

                        <div className='mobile-text'>
                          Units
                        </div>
                      </div>

                      <div className='content-title-row'>
                        <button
                          onClick={() => setPage('unit_add')}
                        >
                          <Icon viewBox="0 0 60 60" icon="plus-job-tech-1" />
                        </button>
                      </div>
                    </div>

                    {
                      <div
                        className='job-tech-content-items'
                        style={{ padding: !!jobData.units.length ? '10px 5px' : '9px 10px' }}
                      >
                        {
                          jobData.units.map((device, index) =>
                            <div
                              key={index}
                              className='job-tech-content-item'
                              style={{ cursor: permissions.technician_unit_show ? 'pointer' : 'inherit' }}
                              onClick={() => {
                                permissions.technician_unit_show && setPage('unit_edit')
                                permissions.technician_unit_show && setId(device.unit_id)
                              }}
                            >
                              <div className='job-tech-content-item-text'>
                                <div className='main'>
                                  {`${device.appliance === 'Other' ? device.other_appliance : device.appliance} ${device.unit_type !== 'Residential' ? '(' + device.unit_type + ')' : ''}: ${device.appliance_brand === 'Other' ? device.other_brand : device.appliance_brand}`}
                                </div>

                                <div className='other'>
                                  {device.description}
                                </div>
                              </div>

                              <div className='job-tech-content-item-arrow'>
                                <button
                                  disabled={!permissions.technician_unit_show}
                                  onClick={() => {
                                    setPage('unit_edit')
                                    setId(device.unit_id)
                                  }
                                  }
                                >
                                  <Icon viewBox="0 0 24 24" icon="arrow-25" />
                                </button>
                              </div>
                            </div>
                          )
                        }
                      </div>
                    }
                  </div>

                  <div
                    className={classNames('job-tech-content mobile', '__show-on-mobile',
                      {
                        __respectAsidePanel: navActive.is && !phoneCall,
                        __phoneCall: phoneCall && !navActive.is,
                        __bothOpen: navActive.is && phoneCall,
                        __nonePanel: !navActive.is && !phoneCall,
                        __disabled: timeTrackingModal || paymentModal
                      }
                    )}
                  >
                    <div className='content-title'>
                      <div
                        className='content-title-row'
                        style={{ alignItems: 'center' }}
                      >
                        <Icon viewBox='0 0 1024 1024' icon="approval-5" />

                        <div className='mobile-text'>
                          Approval
                        </div>
                      </div>

                      <div className='content-title-row'>
                        <button
                          onClick={() => setApprovalModal(true)}
                        >
                          <Icon viewBox="0 0 60 60" icon="plus-job-tech-1" />
                        </button>
                      </div>
                    </div>

                    <div
                      className='job-tech-content-items'
                      style={{ padding: !!jobData.approvals.length ? '10px 5px' : '9px 10px' }}
                    >
                      {
                        jobData.approvals.map((approv, index) =>
                          <div
                            key={index}
                            className='job-tech-content-item'
                            style={{ cursor: approv.url_signed_document ? 'pointer' : 'inherit' }}
                            onClick={() => {
                              approv.url_signed_document && setApproveDocument(approv.url_signed_document)
                            }}
                          >
                            <div
                              className='job-tech-content-item-text'
                              style={{ width: '100%' }}
                            >
                              <div
                                className='main invoice'
                                style={{ justifyContent: 'flex-start', alignItems: 'center' }}
                              >
                                <div style={{ display: 'flex', fontSize: '13px' }}>
                                  {
                                    approv.method === 'manual' ?
                                      `Manual Approval for Job ${jobData.job_name}` :
                                      `Remote Approval for Job ${jobData.job_name}`
                                  }
                                </div>


                                <div
                                  className='invoice-info-total'
                                  style={{ fontWeight: 'normal', marginLeft: '10px', fontSize: '12px' }}
                                >
                                  {dateToInfoBlock('MM/dd/yyyy hh:mma', time_zone, approv.created_at)}
                                </div>

                                {
                                  approv.url_signed_document &&
                                  <div
                                    className='job-tech-content-item-arrow'
                                    style={{ marginLeft: 'auto' }}
                                  >
                                    <button>
                                      <Icon viewBox="0 0 24 24" icon="arrow-25" />
                                    </button>
                                  </div>
                                }
                              </div>

                              {
                                approv.method !== 'manual' &&
                                <div
                                  className='invoice-info'
                                  style={{ gap: '10px', justifyContent: 'flex-start' }}
                                >
                                  <div style={{ fontSize: '13px' }} className='invoice-info-total'>
                                    {formatPhoneNumberForItemTech(approv.phone)}
                                  </div>

                                  <div style={{ fontSize: '13px' }} className='invoice-info-paid'>
                                    {approv.email}
                                  </div>
                                </div>
                              }
                            </div>


                          </div>
                        )
                      }
                    </div>
                  </div>

                  {/* Invoices block */}
                  <div
                    className={classNames('job-tech-content', '__hide-on-mobile',
                      {
                        __respectAsidePanel: navActive.is && !phoneCall,
                        __phoneCall: phoneCall && !navActive.is,
                        __bothOpen: navActive.is && phoneCall,
                        __nonePanel: !navActive.is && !phoneCall
                      }
                    )}
                  >
                    <div className='content-title content-title-action'>
                      <span>Invoices</span>
                    </div>
                    {
                      jobData.invoices.map((invoice, index) => (
                        <div
                          key={index}
                          className='content-customer'
                          style={{ cursor: permissions.technician_invoice_show ? 'pointer' : 'inherit' }}
                          onClick={() => {
                            permissions.technician_invoice_show && setPage('invoice_edit')
                            permissions.technician_invoice_show && invoice.invoice_id && setId(invoice.invoice_id)
                          }}
                        >
                          <div className='content-customer-left estimate'>
                            0{index + 1}
                          </div>

                          <div className='content-customer-center estimate'>
                            <div>
                              {invoice.name}

                              <span style={{ marginLeft: '20px' }}>
                                Status: {invoice.invoice_status}
                              </span>
                            </div>

                            <div>
                              {
                                invoice.invoice_total &&
                                `$${invoice.invoice_paid ? invoice.invoice_paid : '0.00'}/${invoice.invoice_total ? invoice.invoice_total : '0.00'}`
                              }
                            </div>
                          </div>

                          <div className='content-customer-right'>
                            {
                              permissions.technician_invoice_show &&
                              <button
                                onClick={() => {
                                  setPage('invoice_edit')
                                  invoice.invoice_id && setId(invoice.invoice_id)
                                }}
                              >
                                <Icon viewBox="0 0 24 24" icon="arrow-25" />
                              </button>
                            }
                          </div>
                        </div>
                      ))
                    }
                  </div>

                  <div
                    className={classNames('job-tech-content mobile', '__show-on-mobile',
                      {
                        __respectAsidePanel: navActive.is && !phoneCall,
                        __phoneCall: phoneCall && !navActive.is,
                        __bothOpen: navActive.is && phoneCall,
                        __nonePanel: !navActive.is && !phoneCall,
                        __disabled: timeTrackingModal || paymentModal
                      }
                    )}
                  >
                    <div className='content-title'>
                      <div
                        className='content-title-row'
                        style={{ alignItems: 'center' }}
                      >
                        <Icon viewBox="0 0 70 77" icon="group-23" />

                        <div className='mobile-text'>
                          Invoices
                        </div>
                      </div>

                      <div className='content-title-row'>
                      </div>
                    </div>

                    {
                      <div
                        className='job-tech-content-items'
                        style={{ padding: !!jobData.invoices.length ? '10px 5px' : '9px 10px' }}
                      >
                        {
                          jobData.invoices.map((invoice, index) =>
                            <div
                              key={index}
                              className='job-tech-content-item'
                              style={{ cursor: permissions.technician_invoice_show ? 'pointer' : 'inherit' }}
                              onClick={() => {
                                permissions.technician_invoice_show && setPage('invoice_edit')
                                permissions.technician_invoice_show && invoice.invoice_id && setId(invoice.invoice_id)
                              }}
                            >
                              <div
                                className='job-tech-content-item-text'
                                style={{ width: '250px' }}
                              >
                                <div
                                  className='main invoice'
                                  style={{ justifyContent: 'space-between' }}
                                >
                                  <div style={{ display: 'flex' }}>
                                    {invoice.name}

                                    {
                                      invoice.invoice_total && invoice.invoice_paid && invoice.invoice_total === invoice.invoice_paid &&
                                      <div style={{ marginLeft: '10px' }} className='check-mark'>
                                        <Icon icon="check-mark-1" />
                                      </div>
                                    }
                                  </div>


                                  <div
                                    className='invoice-info-total'
                                    style={{ fontWeight: 'normal' }}
                                  >
                                    Total: {invoice.invoice_total ? invoice.invoice_total : '$0.00'}
                                  </div>
                                </div>

                                <div
                                  className='invoice-info'
                                  style={{ gap: '0', justifyContent: 'space-between' }}
                                >
                                  <div
                                    className='invoice-info-total'
                                  >
                                    Status: <span className={classNames(
                                      {
                                        canceled: invoice?.invoice_status?.toLocaleLowerCase() === 'canceled'
                                      }
                                    )}>{invoice.invoice_status}</span>
                                  </div>

                                  <div className='invoice-info-paid'>
                                    Paid: ${invoice.invoice_paid ? invoice.invoice_paid : '0.00'}
                                  </div>
                                </div>
                              </div>

                              <div className='job-tech-content-item-arrow'>
                                <button
                                  disabled={!permissions.technician_invoice_show}
                                  onClick={() => {
                                    setPage('invoice_edit')
                                    invoice.invoice_id && setId(invoice.invoice_id)
                                  }}
                                >
                                  <Icon viewBox="0 0 24 24" icon="arrow-25" />
                                </button>
                              </div>
                            </div>
                          )
                        }
                      </div>
                    }
                  </div>

                  {/* Estimates block */}
                  <div
                    className={classNames('job-tech-content', '__hide-on-mobile',
                      {
                        __respectAsidePanel: navActive.is && !phoneCall,
                        __phoneCall: phoneCall && !navActive.is,
                        __bothOpen: navActive.is && phoneCall,
                        __nonePanel: !navActive.is && !phoneCall
                      }
                    )}
                  >
                    <div className='content-title content-title-action'>
                      <span>Estimates</span>
                      {
                        permissions.technician_estimate_add &&
                        <button
                          onClick={() => setPage('estimate_add')}
                        >
                          <Icon viewBox="0 0 24 24" icon="x-mark-1" />
                        </button>
                      }
                    </div>
                    {
                      jobData.estimates.map((estimate, index) => (
                        <div
                          key={index}
                          className='content-customer'
                          style={{ cursor: permissions.technician_estimate_show ? 'pointer' : 'inherit' }}
                          onClick={() => {
                            permissions.technician_estimate_show && setPage('estimate_edit')
                            permissions.technician_estimate_show && setId(estimate.estimate_id)
                          }}
                        >
                          <div className='content-customer-left estimate'>
                            0{index + 1}
                          </div>

                          <div className='content-customer-center estimate'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                              {estimate.name}

                              <div>
                                Status:

                                <span
                                  style={{
                                    marginLeft: '5px',
                                    color: estimate?.estimate_status?.toLocaleLowerCase() === 'canceled' ? '#FF0000' : 'inherit'
                                  }}>
                                  {estimate.estimate_status}
                                </span>
                              </div>
                            </div>

                            <div>
                              {estimate.estimate_total}
                            </div>
                          </div>

                          <div className='content-customer-right'>
                            {
                              permissions.technician_estimate_show &&
                              <button
                                onClick={() => {
                                  setPage('estimate_edit')
                                  setId(estimate.estimate_id)
                                }}
                              >
                                <Icon viewBox="0 0 24 24" icon="arrow-25" />
                              </button>
                            }
                          </div>
                        </div>
                      ))
                    }
                  </div>

                  <div
                    className={classNames('job-tech-content mobile', '__show-on-mobile',
                      {
                        __respectAsidePanel: navActive.is && !phoneCall,
                        __phoneCall: phoneCall && !navActive.is,
                        __bothOpen: navActive.is && phoneCall,
                        __nonePanel: !navActive.is && !phoneCall,
                        __disabled: timeTrackingModal || paymentModal,
                      }
                    )}
                  >
                    <div className='content-title'>
                      <div
                        className='content-title-row'
                        style={{ alignItems: 'center' }}
                      >
                        <Icon viewBox="0 0 70 77" icon="group-22" />

                        <div className='mobile-text'>
                          Estimates
                        </div>
                      </div>

                      <div className='content-title-row'>
                        <button
                          disabled={!permissions.technician_estimate_add}
                          onClick={() => setPage('estimate_add')}
                        >
                          <Icon viewBox="0 0 60 60" icon="plus-job-tech-1" />
                        </button>
                      </div>
                    </div>

                    {
                      <div
                        className='job-tech-content-items'
                        style={{ padding: !!jobData.estimates.length ? '10px 5px' : '9px 10px' }}
                      >
                        {
                          jobData.estimates.map((estimate, index) =>
                            <div
                              key={index}
                              className='job-tech-content-item'
                              style={{ cursor: permissions.technician_estimate_show ? 'pointer' : 'inherit' }}
                              onClick={() => {
                                permissions.technician_estimate_show && setPage('estimate_edit')
                                permissions.technician_estimate_show && setId(estimate.estimate_id)
                              }}
                            >
                              <div
                                className='job-tech-content-item-text'
                                style={{ width: '250px' }}
                              >
                                <div
                                  className='main invoice'
                                  style={{ justifyContent: 'space-between' }}
                                >
                                  <div style={{ display: 'flex' }}>
                                    {estimate.name ? estimate.name : 'Name'}
                                  </div>


                                  <div
                                    className='invoice-info-total'
                                    style={{ fontWeight: 'normal' }}
                                  >
                                    Total: {estimate.estimate_total}
                                  </div>
                                </div>

                                <div
                                  className='invoice-info'
                                  style={{ gap: '0', justifyContent: 'space-between' }}
                                >
                                  <div className='invoice-info-total'>
                                    Status: <span className={classNames(
                                      {
                                        canceled: estimate?.estimate_status?.toLocaleLowerCase() === 'canceled'
                                      }
                                    )}>{estimate.estimate_status}</span>
                                  </div>
                                </div>
                              </div>

                              <div className='job-tech-content-item-arrow'>
                                <button
                                  disabled={!permissions.technician_estimate_show}
                                  onClick={() => {
                                    setPage('estimate_edit')
                                    setId(estimate.estimate_id)
                                  }
                                  }
                                >
                                  <Icon viewBox="0 0 24 24" icon="arrow-25" />
                                </button>
                              </div>
                            </div>
                          )
                        }
                      </div>
                    }
                  </div>

                  {/* Payments block */}
                  <div
                    className={classNames('job-tech-content mobile', '__show-on-mobile',
                      {
                        __respectAsidePanel: navActive.is && !phoneCall,
                        __phoneCall: phoneCall && !navActive.is,
                        __bothOpen: navActive.is && phoneCall,
                        __nonePanel: !navActive.is && !phoneCall,
                        __disabled: timeTrackingModal || paymentModal,
                      }
                    )}
                  >
                    <div className='content-title'>
                      <div
                        className='content-title-row'
                        style={{ alignItems: 'center' }}
                      >
                        <Icon viewBox="0 0 70 77" icon="payment-job-tech-9" />

                        <div className='mobile-text'>
                          Payments
                        </div>
                      </div>

                      <div className='content-title-row'>
                        <button
                          disabled={paymentPage !== '' || !jobData.is_need_payment || (!permissions.payment_add && !permissions.payment_add_own)}
                          onClick={() => (permissions.payment_add || permissions.payment_add_own) && paymentPage === '' && jobData.is_need_payment && setPaymentModal(true)}
                        >
                          <Icon viewBox="0 0 60 60" icon="plus-job-tech-1" />
                        </button>
                      </div>
                    </div>

                    {
                      <div
                        className='job-tech-content-items'
                        style={{ padding: !!jobData.payments.length ? '10px 5px' : '9px 10px' }}
                      >
                        <table>
                          <tbody>
                            {
                              jobData.payments.map((payment) =>
                                payment.items.map((payment_item, index) => (
                                  <tr key={index}>
                                    <td>
                                      <div>
                                        {dateToInfoBlock('MM/dd/yyyy hh:mma', time_zone, payment.created_at)}
                                      </div>

                                      <div className='bold center'>
                                        {payment_item.invoice_id ? getInvoiceName(payment_item.invoice_id, jobData.job_name) : jobData.job_name}
                                      </div>

                                      <div className='note'>
                                        {getFullAmount(payment.total, payment_item.total_item)}
                                      </div>
                                    </td>

                                    <td className='flex'>
                                      {
                                        payment.type === 'Card' &&
                                        <div className='bold'> {payment.type} {payment.number} </div>
                                      }

                                      {
                                        payment.type === 'Check' &&
                                        <div className='bold'> #{payment.number} </div>
                                      }
                                    </td>

                                    <td className='right'>
                                      <div className='other'>
                                        {payment.service_name}
                                      </div>

                                      <div className='bold'>
                                        ${payment_item.total_item}
                                      </div>

                                      <div className='note'>
                                        {payment_item.note}
                                      </div>
                                    </td>

                                    {
                                      permissions.payment_change_invoice &&
                                      <td style={{ width: '20px' }}>
                                        <div
                                          style={{
                                            display: 'flex',
                                            width: '100%',
                                            height: '100%',
                                            alignItems: 'center',
                                            justifyContent: 'right',
                                            cursor: 'pointer',
                                          }}
                                          onClick={() => setItemPaymentModal({ id: payment_item.payment_id, invoice_id: payment_item.invoice_id })}
                                        >
                                          <Icon style={{ height: '14px' }} viewBox="0 0 18 76" icon="dots-job-tech-1" />
                                        </div>
                                      </td>
                                    }
                                  </tr>
                                ))
                              )
                            }
                          </tbody>
                        </table>
                      </div>
                    }
                  </div>

                  <div
                    className={classNames('job-tech-content', '__hide-on-mobile',
                      {
                        __respectAsidePanel: navActive.is && !phoneCall,
                        __phoneCall: phoneCall && !navActive.is,
                        __bothOpen: navActive.is && phoneCall,
                        __nonePanel: !navActive.is && !phoneCall
                      }
                    )}
                  >
                    <div className='content-title content-title-action'>
                      <span>Payments</span>

                      {
                        (paymentPage !== '' || !jobData.is_need_payment || (!permissions.payment_add && !permissions.payment_add_own)) &&
                        <button
                          disabled={paymentPage !== '' || !jobData.is_need_payment || (!permissions.payment_add && !permissions.payment_add_own)}
                          onClick={() => (permissions.payment_add || permissions.payment_add_own) && paymentPage === '' && jobData.is_need_payment && setPaymentModal(true)}
                        >
                          <Icon viewBox="0 0 24 24" icon="x-mark-1" />
                        </button>
                      }
                    </div>

                    {
                      jobData.payments.map((payment) =>
                        payment.items.map((payment_item, index) => (
                          <div
                            key={index}
                            className='content-customer'
                            style={{ cursor: permissions.payment_change_invoice ? 'pointer' : 'inherit' }}
                            onClick={() => setItemPaymentModal({ id: payment_item.payment_id, invoice_id: payment_item.invoice_id })}
                          >
                            <div className='content-customer-left estimate appointment'>
                              <span>
                                0{index + 1}
                              </span>
                            </div>

                            <div className='content-customer-center estimate'>
                              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <span>{payment_item.invoice_id ? getInvoiceName(payment_item.invoice_id, jobData.job_name) : jobData.job_name}</span>

                                <span style={{ fontSize: '12px', fontWeight: 'normal' }}> {dateToInfoBlock('MM/dd/yyyy hh:mma', time_zone, payment.created_at)} </span>

                                <span style={{ fontSize: '10px', fontWeight: 'normal' }}> {getFullAmount(payment.total, payment_item.total_item)} </span>
                              </div>

                              <div>
                                {
                                  payment.type === 'Card' &&
                                  <span style={{ fontWeight: 'bold' }} className='bold'> {payment.type} {payment.number} </span>
                                }

                                {
                                  payment.type === 'Check' &&
                                  <span style={{ fontWeight: 'bold' }} className='bold'> #{payment.number} </span>
                                }
                              </div>

                              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <span style={{ fontSize: '12px', fontWeight: 'normal' }}> {payment.service_name} </span>

                                <span> ${payment_item.total_item} </span>

                                <span style={{ fontSize: '10px', fontWeight: 'normal' }}> {payment_item.note} </span>
                              </div>
                            </div>

                            <div className='content-customer-right'>
                              {
                                permissions.payment_change_invoice &&
                                <div
                                  style={{
                                    display: 'flex',
                                    width: '100%',
                                    height: '100%',
                                    alignItems: 'center',
                                    justifyContent: 'right',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => setItemPaymentModal({ id: payment_item.payment_id, invoice_id: payment_item.invoice_id })}
                                >
                                  <Icon style={{ height: '14px' }} viewBox="0 0 18 76" icon="dots-job-tech-1" />
                                </div>
                              }
                            </div>
                          </div>
                        ))
                      )}
                  </div>

                  <div
                    className={classNames('job-tech-content', '__hide-on-mobile',
                      {
                        __respectAsidePanel: navActive.is && !phoneCall,
                        __phoneCall: phoneCall && !navActive.is,
                        __bothOpen: navActive.is && phoneCall,
                        __nonePanel: !navActive.is && !phoneCall
                      }
                    )}
                  >
                    <div className='content-title content-title-action'>
                      <span>Customer Links </span>
                    </div>

                    {
                      jobData.links.map((link, index) => (
                        <div
                          key={link.link_id}
                          className='content-customer'
                          style={{ cursor: 'pointer' }}
                        >
                          <div className='content-customer-left estimate appointment'>
                            <span>
                              0{index + 1}
                            </span>
                          </div>

                          <div className='content-customer-center estimate'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                              <span style={{ width: '120px' }}>Type: {link.type}</span>

                              <div style={{ width: '220px' }}>
                                Status: <span
                                  style={{ color: link?.status?.toLocaleLowerCase() === 'canceled' ? '#FF0000' : 'inherit' }}>
                                  {link.status}

                                  {
                                    checkExpiredDate(link.expired_date) &&
                                    <span style={{ color: '#FF0000' }} className='expired'> (expired)</span>
                                  }
                                </span>
                              </div>

                              <span style={{ fontWeight: 'bold' }} className='bold'> Total: ${link.amount} </span>
                            </div>
                          </div>

                          <div className='content-customer-right'>
                            <button
                              onClick={() => setLinkModal({ status: link.status, id: link.link_id })}
                            >
                              <Icon style={{ height: '14px' }} viewBox="0 0 18 76" icon="dots-job-tech-1" />
                            </button>
                          </div>
                        </div>
                      ))
                    }
                  </div>

                  {/* Parts block */}
                  <div
                    className={classNames('job-tech-content', '__hide-on-mobile',
                      {
                        __respectAsidePanel: navActive.is && !phoneCall,
                        __phoneCall: phoneCall && !navActive.is,
                        __bothOpen: navActive.is && phoneCall,
                        __nonePanel: !navActive.is && !phoneCall
                      }
                    )}
                  >
                    <div className='content-title content-title-action'>
                      <span>Parts</span>
                      {
                        permissions.technician_part_add &&
                        <button
                          onClick={() => setPage('parts_add')}
                        >
                          <Icon viewBox="0 0 24 24" icon="x-mark-1" />
                        </button>
                      }
                    </div>

                    {
                      jobData.parts.map((part, index) => (
                        <div
                          key={index}
                          className='content-customer'
                          style={{ cursor: permissions.technician_part_show ? 'pointer' : 'inherit' }}
                          onClick={() => {
                            permissions.technician_part_show && setPage('parts_edit')
                            permissions.technician_part_show && setId(part.part_id)
                          }}
                        >
                          <div className='content-customer-left estimate'>
                            0{index + 1}
                          </div>

                          <div className='content-customer-center estimate'>
                            <div>
                              {part.part_status}
                            </div>

                            <div>
                              {part.part_name}
                            </div>
                          </div>

                          <div className='content-customer-right'>
                            {
                              permissions.technician_part_show &&
                              <button
                                onClick={() => {
                                  setPage('parts_edit')
                                  setId(part.part_id)
                                }}
                              >
                                <Icon viewBox="0 0 24 24" icon="arrow-25" />
                              </button>
                            }
                          </div>
                        </div>
                      ))
                    }
                  </div>

                  <div
                    className={classNames('job-tech-content', '__hide-on-mobile',
                      {
                        __respectAsidePanel: navActive.is && !phoneCall,
                        __phoneCall: phoneCall && !navActive.is,
                        __bothOpen: navActive.is && phoneCall,
                        __nonePanel: !navActive.is && !phoneCall
                      }
                    )}
                  >
                    <div className='content-title content-title-action'>
                      <span>Attachments </span>

                      <button
                        onClick={() => setPage('attachments_add')}
                      >
                        <Icon viewBox="0 0 24 24" icon="x-mark-1" />
                      </button>
                    </div>

                    <div
                      className='job-tech-attachments-items'
                      style={{ padding: !!jobData.attachments.length ? '10px 5px' : '9px 10px' }}
                    >
                      {
                        jobData.attachments.map(item => (
                          <div
                            className='attachments-image'
                            key={item.job_attachment_id}
                            onMouseEnter={() => setHover(item.job_attachment_id)}
                            onMouseLeave={() => setHover('')}
                            onClick={() => hover === item.job_attachment_id && setImageModal({ id: item.job_attachment_id, url: item.url, is_delete: false })}
                          >
                            <img src={item.url} alt="Attachment" />

                            {
                              hover === item.job_attachment_id &&
                              <div className='attachments-delete'>Open</div>
                            }
                          </div>
                        ))
                      }
                    </div>
                  </div>

                  <div
                    className={classNames('job-tech-content mobile', '__show-on-mobile',
                      {
                        __respectAsidePanel: navActive.is && !phoneCall,
                        __phoneCall: phoneCall && !navActive.is,
                        __bothOpen: navActive.is && phoneCall,
                        __nonePanel: !navActive.is && !phoneCall,
                        __disabled: timeTrackingModal || paymentModal,
                      }
                    )}
                  >
                    <div className='content-title'>
                      <div
                        className='content-title-row'
                        style={{ alignItems: 'center' }}
                      >
                        <Icon viewBox='0 0 1024 1024' icon="link-3" />

                        <div className='mobile-text'>
                          Customer Links
                        </div>
                      </div>
                    </div>

                    {
                      <div
                        className='job-tech-content-items'
                        style={{ padding: !!jobData.links.length ? '10px 5px' : '9px 10px' }}
                      >
                        {
                          jobData.links.map((item) => (
                            <div className='job-tech-content-item'>
                              <div
                                key={item.link_id}
                                className='job-tech-content-item-text'
                                style={{ width: '250px' }}
                              >
                                <div
                                  className='main invoice'
                                  style={{ justifyContent: 'space-between' }}
                                >
                                  <div style={{ display: 'flex' }}>
                                    <span>Type:</span>

                                    <span style={{ marginLeft: '10px' }}> {item.type} </span>
                                  </div>


                                  <div
                                    className='invoice-info-total'
                                    style={{ fontWeight: 'normal' }}
                                  >
                                    Total: ${item.amount}
                                  </div>
                                </div>

                                <div
                                  className='invoice-info'
                                  style={{ gap: '0', justifyContent: 'space-between' }}
                                >
                                  <div
                                    className='invoice-info-total'
                                  >
                                    Status: <span className={classNames(
                                      {
                                        canceled: item?.status?.toLocaleLowerCase() === 'canceled'
                                      }
                                    )}>
                                      {item.status}

                                      {
                                        checkExpiredDate(item.expired_date) &&
                                        <span className='expired'>(expired)</span>
                                      }
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div className='job-tech-content-item-arrow'>
                                <button
                                  onClick={() => setLinkModal({ status: item.status, id: item.link_id })}
                                >
                                  <Icon style={{ height: '14px' }} viewBox="0 0 18 76" icon="dots-job-tech-1" />
                                </button>
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    }
                  </div>

                  <div
                    className={classNames('job-tech-content mobile', '__show-on-mobile',
                      {
                        __respectAsidePanel: navActive.is && !phoneCall,
                        __phoneCall: phoneCall && !navActive.is,
                        __bothOpen: navActive.is && phoneCall,
                        __nonePanel: !navActive.is && !phoneCall,
                        __disabled: timeTrackingModal || paymentModal,
                      }
                    )}
                  >
                    <div className='content-title'>
                      <div
                        className='content-title-row'
                        style={{ alignItems: 'center' }}
                      >
                        <Icon viewBox='0 0 194 162' icon="part-3" />

                        <div className='mobile-text'>
                          Parts
                        </div>
                      </div>

                      {
                        permissions.technician_part_add &&
                        <div className='content-title-row'>
                          <button
                            disabled={!permissions.technician_part_add}
                            onClick={() => setPage('parts_add')}
                          >
                            <Icon viewBox="0 0 60 60" icon="plus-job-tech-1" />
                          </button>
                        </div>
                      }
                    </div>

                    {
                      <div
                        className='job-tech-content-items'
                        style={{ padding: !!jobData.parts.length ? '10px 5px' : '9px 10px' }}
                      >
                        {
                          jobData.parts.map((part, index) =>
                            <div
                              key={index}
                              className='job-tech-content-item'
                              style={{ cursor: permissions.technician_part_show ? 'pointer' : 'inherit' }}
                              onClick={() => {
                                permissions.technician_part_show && setPage('parts_edit')
                                permissions.technician_part_show && setId(part.part_id)
                              }}
                            >
                              <div
                                className='job-tech-content-item-text'
                                style={{ width: '250px' }}
                              >
                                <div
                                  className='main invoice'
                                  style={{ justifyContent: 'space-between' }}
                                >
                                  <div style={{ display: 'flex' }}>
                                    {part.part_name ? part.part_name : 'Name'}
                                  </div>
                                </div>

                                <div
                                  className='invoice-info'
                                  style={{ gap: '0', justifyContent: 'space-between' }}
                                >
                                  <div className='invoice-info-total'>
                                    Status: <span>{part.part_status}</span>
                                  </div>
                                </div>
                              </div>

                              <div className='job-tech-content-item-arrow'>
                                <button
                                  disabled={!permissions.technician_part_show}
                                  onClick={() => {
                                    setPage('parts_edit')
                                    setId(part.part_id)
                                  }}
                                >
                                  <Icon viewBox="0 0 24 24" icon="arrow-25" />
                                </button>
                              </div>
                            </div>
                          )
                        }
                      </div>
                    }
                  </div>

                  <div
                    className={classNames('job-tech-content mobile', '__show-on-mobile',
                      {
                        __respectAsidePanel: navActive.is && !phoneCall,
                        __phoneCall: phoneCall && !navActive.is,
                        __bothOpen: navActive.is && phoneCall,
                        __nonePanel: !navActive.is && !phoneCall,
                        __disabled: timeTrackingModal || paymentModal,
                      }
                    )}
                  >
                    <div className='content-title'>
                      <div
                        className='content-title-row'
                        style={{ alignItems: 'center' }}
                      >
                        <Icon viewBox='0 0 1024 1024' icon="part-f" />

                        <div className='mobile-text'>
                          Attachments
                        </div>
                      </div>

                      <div className='content-title-row'>
                        <button
                          onClick={() => setPage('attachments_add')}
                        >
                          <Icon viewBox="0 0 60 60" icon="plus-job-tech-1" />
                        </button>
                      </div>
                    </div>

                    <div
                      className='job-tech-attachments-items'
                      style={{ padding: !!jobData.attachments.length ? '10px 5px' : '9px 10px' }}
                    >
                      {
                        jobData.attachments.map(item => (
                          <div
                            className='attachments-image'
                            key={item.job_attachment_id}
                            onMouseEnter={() => setHover(item.job_attachment_id)}
                            onMouseLeave={() => setHover('')}
                            onClick={() => hover === item.job_attachment_id && setImageModal({ id: item.job_attachment_id, url: item.url, is_delete: false })}
                          >
                            <img src={item.url} alt="Attachment" />

                            {
                              hover === item.job_attachment_id &&
                              <div className='attachments-delete'>Open</div>
                            }
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </>
              }
            </div > :
            <BlankPage
              page={page}
              switchPage={switchPage}
              id={id}
              jobId={jobData?.job_id}
              edit={jobData?.edit}
              client_id={jobData?.client.length ? jobData?.client[0].client_id : null}
              address={address}
              jobAddress={jobData?.job_address}
              units={units}
              other_appointments={jobData?.other_appointments ? jobData.other_appointments : []}
              permissions={permissions}
              service_resource_id={jobData?.service_resource_id ? jobData.service_resource_id : ''}
              status_id={getStatusId()}
              appointment_id={jobData?.appointment?.appointment_id ? jobData.appointment.appointment_id : ''}
            /> :
          <div className='job-tech-header'>
            <div
              className={classNames('link-page payment-page job-tech-customer time-tracking mobile', '__show-on-mobile',
                {
                  __respectAsidePanel: navActive.is && !phoneCall,
                  __phoneCall: phoneCall && !navActive.is,
                  __bothOpen: navActive.is && phoneCall,
                  __nonePanel: !navActive.is && !phoneCall
                }
              )}
            >
              <div className='link-menu'>
                <div style={{ background: '#1b2f5f' }} className="title">
                  Appointment canceled
                </div>

                <div className="buttons">
                  <button
                    className="_bordered _green"
                    onClick={() =>
                      $router.router.navigate('appointment_calendar', {
                        companyId: activeAccountId
                      }, { reload: true })}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
      }
    </>
  )
}
