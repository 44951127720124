import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import classNames from "classnames"
import moment from "moment"
import qs from "qs";
import { DateTime } from "luxon";
import { Transition } from "react-transition-group"
import { useCookies } from 'react-cookie';

import AudioPlayer from "../../components/AudioPlayer"
import Checkbox from "../../components/Checkbox"
import Icon from "../../components/Icon"
import ReportFilters from "../../components/reports/Filters"
import ReportTableControls from "../../components/reports/TableControls"
import ReportTableField from "../../components/reports/TableField"
import Select from "../../components/Select"
import ContactMenu from "./ContactMenu";

import { getDateRangeByPreset } from "../../funcs/reports"
import { httpClientUpdate, nErrorUpdate, useOuterClick, formatPhoneNumber, dateToInfoBlock, formatPhoneNumberToServerString } from "../../funcs";
import { useAppSelector } from "../../store/hooks"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";
import { Call } from "../../models/Calls"
import { DateRangePreset } from "../../models/Misc"
import { CallCentersOptionsProps } from "../../App";

import "../../styles/pages/common/report-list.sass"

type FilterWord = 'dispatcher' | 'friendly_name' | 'status' | 'source' | 'area'

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: CallsReport
}

interface CallsReport {

  interface: {

    filter_words: {

      dispatcher: string[],
      friendly_name: string[],
      status: string[],
      source: string[],
      area: string[],
    },

    tag_words: string[],

    max_pages: number,

    rows_start: number,
    rows_end: number,
    rows_all: number,

    min_date: Date,
    max_date: Date
  },

  permissions: {
    appointment_show: boolean
    call_area_report: boolean
    call_call_record: boolean
    call_call_report: boolean
    call_call_show_dispatcher: boolean
    call_number_report: boolean
    call_source_report: boolean
    client_show: boolean
    call_call_report_extended: boolean
    use_private_contact_list: boolean
  },

  dashboard: {
    calls: string,
    callers: string,
    missed_calls: string,
    active_calls: string
  },

  calls: Call[]

  edit: {
    call_centers: {
      call_center_id: string
      name: string
    }[]
  }
}

interface CallsPage_ListProps {
  handleCallExtension: (value: string) => void
  softphone_call_extension: boolean | undefined
  updated: number
  call_center_id: string
  callCentersOptions: CallCentersOptionsProps[]
}

export interface CallsOptionsProps {
  span: string
  value: string
}

function CallsPage_List({
  handleCallExtension,
  softphone_call_extension,
  updated,
  call_center_id,
  callCentersOptions,
}: CallsPage_ListProps) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)
  const accessToken = useAppSelector((store) => store.accessToken)
  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)
  const phoneCallStatus = useAppSelector((store) => store.phoneCallStatus)

  let timeZone = user?.time_zone;

  const [cookies, setCookie] = useCookies();

  const cookiesSetting = cookies.settings ? cookies.settings : null

  const [$updater, $setUpdater] = useState<any>(Math.random())

  const [callsOptions, setCallsOptions] = useState<CallsOptionsProps[]>([])

  const [extensionCall, setExtensionCall] = useState('')
  const [addContact, setAddContact] = useState<null | { phone: string; friendly_name: string; error: string }>(null)
  const [editContact, setEditContact] = useState<null | { initial_name: string, friendly_name: string; id: string; error: string }>(null)

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)
  const [topFilterShown, setTopFilterShown] = useState(false)
  const [hashtagFilterShown, setHashtagFilterShown] = useState(false)

  const [selectedCallCenter, setSelectedCallCenter] = useState(call_center_id)

  const [reportIsDeprecated, setReportIsDeprecated] = useState(false)
  const [searchButtonActive, setSearchButtonActive] = useState(false)

  const [reportData, setReportData] = useState<CallsReport | null>(null)
  const [localInterface, setLocalInterface] = useState({
    search: '',
    callListenPopup: null as string | null,

    dateRangeCalendarShown: false,
    dateRangePreset: "today" as DateRangePreset,
    ...(getDateRangeByPreset(user, 'today', DateTime.now().setZone(timeZone).startOf('day').toJSDate(), DateTime.now().setZone(timeZone).endOf('day').toJSDate())),
    page: 1,

    filter_words: cookiesSetting && cookiesSetting?.calls_calls?.filter_field ? {
      dispatcher: [] as string[],
      friendly_name: [] as string[],
      status: [] as string[],
      source: [] as string[],
      area: [] as string[],
      ...cookiesSetting.calls_calls.filter_field,
    } : {
      dispatcher: [] as string[],
      friendly_name: [] as string[],
      status: [] as string[],
      source: [] as string[],
      area: [] as string[],
    },

    tag_words: [] as string[],

    searchFilters: [["source", "system"], ["inbound", "outbound"]],
    selectedSearchFilters: { type: ['source'] as string[], direction: [] as string[] },

    sort: {
      field: cookiesSetting && cookiesSetting?.calls_calls?.sort_field ? cookiesSetting.calls_calls.sort_field : 'created_at',
      direction: cookiesSetting && cookiesSetting?.calls_calls?.sort_type ? cookiesSetting.calls_calls.sort_type : 'down' as 'down' | 'up'
    },
    sortFields: [{
      span: 'Date',
      value: 'created_at'
    }, {
      span: 'Dispatcher',
      value: 'dispatcher'
    }, {
      span: 'Dispatcher Code',
      value: 'dispatcher_code',
    }, {
      span: 'Caller Name',
      value: 'caller_name'
    }, {
      span: 'Client',
      value: 'client'
    }, {
      span: 'From',
      value: 'call_from'
    }, {
      span: 'To',
      value: 'call_to'
    }, {
      span: 'Friendly Name',
      value: 'friendly_name'
    }, {
      span: 'Extension',
      value: 'extension'
    }, {
      span: 'Status',
      value: 'status'
    }, {
      span: 'Duration',
      value: 'duration'
    }, {
      span: 'Source',
      value: 'source'
    }, {
      span: 'Area',
      value: 'area'
    }, {
      span: 'Contact',
      value: 'contact'
    }]
  })

  useEffect(() => {
    updated && setLocalInterface({
      ...localInterface,
      search: '',
      callListenPopup: null as string | null,
      dateRangeCalendarShown: false,
      dateRangePreset: "today" as DateRangePreset,
      ...(getDateRangeByPreset(user, 'today', DateTime.now().setZone(timeZone).startOf('day').toJSDate(), DateTime.now().setZone(timeZone).endOf('day').toJSDate())),
      page: 1,
      filter_words: cookiesSetting && cookiesSetting?.calls_calls?.filter_field ? {
        dispatcher: [] as string[],
        friendly_name: [] as string[],
        status: [] as string[],
        source: [] as string[],
        area: [] as string[],
        ...cookiesSetting.calls_calls.filter_field,
      } : {
        dispatcher: [] as string[],
        friendly_name: [] as string[],
        status: [] as string[],
        source: [] as string[],
        area: [] as string[],
      },
      tag_words: [] as string[],
      selectedSearchFilters: { type: ['source'] as string[], direction: [] as string[] },
      sort: {
        field: cookiesSetting && cookiesSetting?.calls_calls?.sort_field ? cookiesSetting.calls_calls.sort_field : 'created_at',
        direction: cookiesSetting && cookiesSetting?.calls_calls?.sort_type ? cookiesSetting.calls_calls.sort_type : 'down' as 'down' | 'up'
      },
    })
    updated && $setUpdater(updated)

    setTimeout(() => {
      updated && setReportIsDeprecated(false)
      updated && setSearchButtonActive(false)
    }, 100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  // Watch date range preset
  useEffect(() => {

    if (!reportData) return

    if (localInterface.dateRangePreset === 'custom') return

    !updated && setLocalInterface({
      ...localInterface,
      ...(getDateRangeByPreset(user, localInterface.dateRangePreset, reportData?.interface.min_date, reportData?.interface.max_date))
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.dateRangePreset])

  // Load calls function
  async function loadCalls() {
    httpClientUpdate.defaults.headers['Authorization'] = `Bearer ${accessToken}`

    let activeFilters: any = { ...localInterface.filter_words }
    Object.keys(localInterface.filter_words).forEach((key: any) => !activeFilters[key].length && delete activeFilters[key]);

    let reqData: {
      account_id: string | null
      limit_rows: string
      page: string
      date_start: Date
      date_end: Date
      filter_tag: string
      main_filter: string
      sort_field: string
      sort_type: string
      filters: string
      call_center_id?: string
    } = {
      account_id: activeAccountId,
      limit_rows: cookiesSetting && cookiesSetting?.calls_calls?.limit_rows ? JSON.stringify(cookiesSetting?.calls_calls?.limit_rows) : JSON.stringify(reportsMaxRows),
      page: JSON.stringify(localInterface.page),
      date_start: localInterface.min_date,
      date_end: localInterface.max_date,
      filter_tag: JSON.stringify(localInterface.tag_words),
      main_filter: JSON.stringify(localInterface.selectedSearchFilters),
      sort_field: localInterface.sort.field,
      sort_type: localInterface.sort.direction === 'up' ? 'asc' : 'desc',
      filters: activeFilters,
      ...(localInterface.search ? { search: localInterface.search } : {})
    };

    if (selectedCallCenter) {
      reqData.call_center_id = selectedCallCenter
    }

    setCookie('settings', { ...cookiesSetting, calls_calls: { filter_field: activeFilters, sort_field: localInterface.sort.field, sort_type: localInterface.sort.direction, limit_rows: cookiesSetting && cookiesSetting?.calls_calls?.limit_rows ? cookiesSetting?.calls_calls.limit_rows : reportsMaxRows } })

    try {
      const { data: { data: calls, success, error } } = (await httpClientUpdate.post('/calls/report/calls', qs.stringify(reqData))) as { data: HttpClientUpdateReport }
      if (success) {
        if (calls.permissions.call_call_report) {
          setReportData({
            ...calls,
            interface: {
              ...calls.interface,
              min_date: moment(calls.interface.min_date).startOf('day').toDate(),
              max_date: moment(calls.interface.max_date).endOf('day').toDate(),
            }
          })

          let options: CallsOptionsProps[] = [{
            span: 'Calls',
            value: 'calls'
          }]

          if (calls.permissions.call_source_report) {
            options.push({
              span: 'Sources',
              value: 'calls.sources'
            })
          }

          if (calls.permissions.call_area_report) {
            options.push({
              span: 'Area',
              value: 'calls.areas'
            })
          }

          if (calls.permissions.call_number_report) {
            options.push({
              span: 'Numbers',
              value: 'calls.numbers'
            })
          }

          setCallsOptions(options)
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

      setTimeout(() => {
        setReportIsDeprecated(false)
        setSearchButtonActive(false)
      }, 100)
    }
    catch (error: Error | AxiosError | unknown) {
      setCookie('settings', { ...cookiesSetting, calls_calls: {} })

      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function handleAddContact() {
    try {
      if (addContact) {
        // https://2022back4.artemiudintsev.com/api/lists/contacts
        const response = await httpClientUpdate.post(`/lists/contacts`, {
          account_id: activeAccountId,
          friendly_name: addContact.friendly_name,
          phone: formatPhoneNumberToServerString(addContact.phone),
        })

        if (response.data.success) {
          setAddContact(null)
          loadCalls()
        }
      }
    } catch (error) {
      let createdError = nErrorUpdate(error)
      addContact && setAddContact({ ...addContact, error: createdError.content.errorText })
    }
  }

  async function handleUpdateContact() {
    try {
      if (editContact) {
        // https://2022back4.artemiudintsev.com/api/lists/contacts/88888050uwc9p6ietm
        const response = await httpClientUpdate.put(`/lists/contacts/${editContact.id}`, {
          account_id: activeAccountId,
          friendly_name: editContact.friendly_name,
          // phone: formatPhoneNumberToServerString(addContact.phone),
        })

        if (response.data.success) {
          setEditContact(null)
          loadCalls()
        }
      }
    } catch (error) {
      let createdError = nErrorUpdate(error)
      editContact && setEditContact({ ...editContact, error: createdError.content.errorText })
    }
  }

  // Watch for deprecated
  useEffect(() => {
    if (!reportData) return

    setReportIsDeprecated(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    localInterface.min_date,
    localInterface.max_date
  ])

  useEffect(() => {
    if (!reportData) return
    setSearchButtonActive(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.search])

  // Load calls on page mount
  useEffect(() => {
    loadCalls()

    // unmount page
    return () => {
      setReportData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$updater, selectedCallCenter])

  // Is filter selected function
  function isFilterSelected(field: FilterWord, value: string) {
    return localInterface.filter_words[field].includes(value)
  }

  // Is all filters selected function
  function isAllFiltersSelected(field: FilterWord) {
    return localInterface.filter_words[field].length === 0
  }

  // Filter switch function
  function switchFilter(field: FilterWord, value: string, toggle: boolean) {

    let _arr = [...localInterface.filter_words[field]]

    if (toggle && value === "All") { _arr = [] }

    else if (!toggle) {

      while (true) {
        let i = _arr.findIndex((filter) => filter === value)
        if (i === -1) break
        _arr.splice(i, 1)
      }
    }

    else {

      if (reportData?.interface.filter_words[field].every((option: string) => _arr.concat([value]).includes(option)))
        _arr = []

      else if (_arr.findIndex((filter) => filter === value) === -1)
        _arr.push(value)
    }

    setLocalInterface({ ...localInterface, filter_words: { ...localInterface.filter_words, [field]: _arr } })
  }

  // Is tag_word selected function
  function isTagWordSelected(value: string) {
    return localInterface.tag_words.includes(value)
  }

  // Is all tag words selected function
  function isAllTagWordsSelected() {
    return localInterface.tag_words.length === 0
  }

  // Toggle tagword function
  function switchTagWord(value: string, toggle: boolean) {

    let _arr = [...localInterface.tag_words]

    if (toggle && value === "All") {
      _arr = []
    }
    else if (!toggle) {

      while (true) {
        let i = _arr.findIndex((filter) => filter === value)
        if (i === -1) break
        _arr.splice(i, 1)
      }
    }
    else {

      if (reportData?.interface.tag_words.every((option) => _arr.concat([value]).includes(option))) {
        _arr = []
      }

      else if (_arr.findIndex((filter) => filter === value) === -1)
        _arr.push(value)
    }

    setLocalInterface({ ...localInterface, tag_words: _arr })
  }

  // Is search field selected function

  function isSearchFilterSelected(i: number, value: string, field: string) {

    if (field === 'type') {
      return localInterface.selectedSearchFilters.type.includes(value);
    }
    if (field === 'direction') {
      return localInterface.selectedSearchFilters.direction.includes(value);
    }
  }

  // Is all search fields selected function
  function isAllSearchFiltersSelected(i: number, field: string) {
    if (field === 'type') {
      return localInterface.selectedSearchFilters.type.length === 0
    } else if (field === 'direction') {
      return localInterface.selectedSearchFilters.direction.length === 0
    }
  }

  // Toggle search filter function
  function switchSearchFilter(i: number, value: string, toggle: boolean, field: string, $arr: string[]) {
    let $arrType = $arr;
    let $object = localInterface.selectedSearchFilters;
    if (toggle && value === "All") {
      $arrType = [];
    }
    else if (toggle === false) {
      for (let i = 0; i < $arrType.length; i++) {
        // eslint-disable-next-line no-loop-func
        $arrType.forEach((v, index) => {
          if (v === value) {
            $arrType.splice(index, 1)
          }
        })
      }
    } else {
      $arrType.push(value);
    }

    if (field === 'type') {
      if ($arrType.length === localInterface.searchFilters[0].length) {
        $arrType = [];
      }
      $object.type = $arrType;
    }
    if (field === 'direction') {
      if ($arrType.length === localInterface.searchFilters[1].length) {
        $arrType = [];
      }
      $object.direction = $arrType;
    }
    setLocalInterface({ ...localInterface, selectedSearchFilters: $object })
  }

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  function getDispatcherName(dispathcer: string, code: number, device: string) {
    let name = ''
    if (code) {
      if (device) {
        name = `${dispathcer} (${code}${device[0]})`
      } else {
        name = `${dispathcer} (${code})`
      }
    } else {
      if (device) {
        name = `${dispathcer} (${device})`
      } else {
        name = dispathcer
      }
    }
    return name
  }

  function handleCall() {
    handleCallExtension(extensionCall)
    setExtensionCall('')
  }

  function handleSortDirectionName(value: string) {
    if (localInterface.sort.field === 'dispatcher') {
      setLocalInterface({ ...localInterface, sort: { field: 'dispatcher_code', direction: localInterface.sort.direction } })
    } else {
      setLocalInterface({ ...localInterface, sort: { field: 'dispatcher', direction: value } })
    }
  }

  // Render function
  return (<>
    {reportData ? (
      <div className="CallsPage_List" >

        { /* Reports grid */}
        <div className="reports-grid">

          <div className="cell">

            <div className="amount">{reportData.dashboard.calls}</div>
            <div className="legend">Calls</div>
          </div>

          <div className="cell">

            <div className="amount">{reportData.dashboard.callers}</div>
            <div className="legend">Callers</div>
          </div>

          <div className="cell">

            <div className="amount">{reportData.dashboard.missed_calls}</div>
            <div className="legend">Missed Calls</div>
          </div>

          <div className="cell">

            <div className="amount">{reportData.dashboard.active_calls}</div>
            <div className="legend">Active Calls</div>
          </div>
        </div>

        { /* Page header */}
        <div className="page-header">
          <h1>Calls</h1>
        </div>

        { /* List filters */}
        <ReportFilters
          onSearchInputChange={(value) => {
            setLocalInterface({ ...localInterface, search: value })
          }}
          searchInput={localInterface.search}
          searchButtonActive={searchButtonActive}

          dateRangePreset={localInterface.dateRangePreset}
          onDateRangePresetChange={(value) => {
            setLocalInterface({ ...localInterface, dateRangePreset: value, page: 1 })
          }}

          minDate={localInterface.min_date}
          onMinDateChange={(value) => {
            setLocalInterface({ ...localInterface, min_date: value, dateRangePreset: 'custom', page: 1 })
          }}

          maxDate={localInterface.max_date}
          onMaxDateChange={(value) => {
            setLocalInterface({ ...localInterface, max_date: value, dateRangePreset: 'custom', page: 1 })
          }}

          updateButtonActive={reportIsDeprecated}
          onUpdate={() => {
            setReportIsDeprecated(false);
            $setUpdater(Math.random());
          }}

          phoneCall={phoneCall}
          navActive={navActive.is}

          childrenAfterSearch={(<>
            <div tabIndex={-1} className="filter-button" onFocus={() => setTopFilterShown(true)} onBlur={(e) => !e.currentTarget.contains(e.relatedTarget as any) && setTopFilterShown(false)}>
              <button className={classNames("_wa", "_rounded", "_iconed", {
                _blue: !isAllSearchFiltersSelected(0, 'type') || !isAllSearchFiltersSelected(1, 'direction')
              })}>
                <Icon icon="filter-8" />
              </button>

              <Transition in={topFilterShown} mountOnEnter={true} unmountOnExit={true} timeout={210}>
                {(state) => (
                  <div className={classNames("filter-wrapper", `transition-fade-${state}`)}>
                    <Checkbox contents="All" value={isAllSearchFiltersSelected(0, 'type')} onChange={(value) => switchSearchFilter(0, "All", value, 'type', localInterface.selectedSearchFilters.type)} />
                    {localInterface.searchFilters[0].map((option, index) => (
                      <Checkbox
                        key={index}
                        contents={option}
                        value={isSearchFilterSelected(0, option, 'type')}
                        onChange={(value) => switchSearchFilter(1, option, value, 'type', localInterface.selectedSearchFilters.type)}
                      />
                    ))}
                    <hr />
                    <Checkbox contents="All" value={isAllSearchFiltersSelected(1, 'direction')} onChange={(value) => switchSearchFilter(1, "All", value, 'direction', localInterface.selectedSearchFilters.direction)} />
                    {localInterface.searchFilters[1].map((option, index) => (
                      <Checkbox
                        key={index}
                        contents={option}
                        value={isSearchFilterSelected(1, option, 'direction')}
                        onChange={(value) => switchSearchFilter(1, option, value, 'direction', localInterface.selectedSearchFilters.direction)}
                      />
                    ))}
                    <button onClick={() => {
                      $setUpdater(Math.random());
                      setTopFilterShown(false)
                    }} className="_bordered _blue _wa">
                      Filter
                    </button>
                  </div>
                )}
              </Transition>
            </div>

            <div
              tabIndex={-1}
              className="filter-button"
              onFocus={() => setHashtagFilterShown(true)}
              onBlur={(e) => !e.currentTarget.contains(e.relatedTarget as any) && setHashtagFilterShown(false)}>
              <button className={classNames("_wa", "_rounded", "_iconed", {
                _blue: !isAllTagWordsSelected()
              })}>
                <Icon icon="hashtag-1" />
              </button>

              <Transition in={hashtagFilterShown} mountOnEnter={true} unmountOnExit={true} timeout={210}>
                {(state) => (
                  <div className={classNames("filter-wrapper", `transition-fade-${state}`)}>
                    <Checkbox contents="All" value={isAllTagWordsSelected()} onChange={(value) => switchTagWord("All", value)} />
                    {reportData.interface.tag_words.map((option, i) => (
                      <Checkbox contents={option} key={i} value={isTagWordSelected(option)} onChange={(value) => switchTagWord(option, value)} />
                    ))}
                    <button className="_bordered _blue _wa">
                      Filter
                    </button>
                  </div>
                )}
              </Transition>
            </div>

            {
              callCentersOptions.length > 1 &&
              <Select
                options={callCentersOptions.map((item) => ({
                  span: item.name,
                  value: item.call_center_id,
                }))}
                selectedOption={selectedCallCenter as string}
                onChange={(value: string) => setSelectedCallCenter(value)}
              />
            }

            {
              reportData.permissions.use_private_contact_list &&
              <ContactMenu setUpdater={$setUpdater} />
            }
          </>)}

          childrenBeforeDateType={(<>
            <div>
              {
                callsOptions.length > 1 ?
                  <Select
                    zeroed={true}
                    options={callsOptions}
                    selectedOption="calls"
                    disabled={callsOptions.length === 1}
                    onChange={(value) => $router.router.navigate(value as string, {
                      companyId: activeAccountId,
                      options: callsOptions,
                    }, { reload: true })} /> :
                  <span style={{ marginRight: '16px' }}> Calls </span>
              }

            </div>
          </>)}
        />

        {/* Table controls */}
        <ReportTableControls
          zIndex={5}

          cookie_reportsMaxRows={cookiesSetting?.calls_calls?.limit_rows}

          onMaxRowsChange={(value) => {
            $setUpdater(Math.random())
            setCookie('settings', { ...cookiesSetting, calls_calls: { ...cookiesSetting.calls_calls, limit_rows: value } })
          }}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}
          onPagesStart={() => {
            setLocalInterface({ ...localInterface, page: 1 })
            $setUpdater(Math.random())
          }}
          onPrevPage={() => {
            setLocalInterface({ ...localInterface, page: localInterface.page - 1 })
            $setUpdater(Math.random())
          }}
          onNextPage={() => {
            setLocalInterface({ ...localInterface, page: localInterface.page + 1 })
            $setUpdater(Math.random())
          }}
          onPagesEnd={() => {
            setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })
            $setUpdater(Math.random())
          }}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => {
            setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })
          }}
          onSortDirectionChange={(value) => {
            setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })
          }}
          onSortFire={() => $setUpdater(Math.random())}
        />

        <div className="contents">

          {/* Wide desktop table */}
          <table className={classNames('table', '__show-on-wide',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <thead>
              <tr>
                <ReportTableField
                  contents={(<span>Date</span>)}

                  sortDirection={localInterface.sort.field === 'created_at' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'created_at', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <th></th>
                {
                  reportData.permissions.call_call_show_dispatcher &&
                  <ReportTableField
                    contents={(<span>Dispatcher</span>)}

                    allFiltersSelected={isAllFiltersSelected("dispatcher")}
                    onAllFiltersChange={(value) => switchFilter("dispatcher", "All", value)}

                    filterWords={reportData.interface.filter_words.dispatcher.map((filterWord) => ({
                      word: filterWord,
                      selected: isFilterSelected("dispatcher", filterWord)
                    }))}
                    onFilterChange={(value) => switchFilter("dispatcher", value.word, value.selected)}

                    sortDirection={localInterface.sort.field === 'dispatcher' || localInterface.sort.field === 'dispatcher_code' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => handleSortDirectionName(value)}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />
                }
                <ReportTableField
                  contents={(<span>Caller Name</span>)}

                  sortDirection={localInterface.sort.field === 'caller_name' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'caller_name', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                {
                  reportData.permissions.use_private_contact_list &&
                  <ReportTableField
                    contents={(<span>Contact</span>)}

                    sortDirection={localInterface.sort.field === 'contact' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'contact', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />
                }
                <ReportTableField
                  contents={(<span>Client</span>)}

                  sortDirection={localInterface.sort.field === 'client' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'client', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>From</span>)}

                  sortDirection={localInterface.sort.field === 'call_from' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'call_from', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Friendly name</span>)}

                  allFiltersSelected={isAllFiltersSelected("friendly_name")}
                  onAllFiltersChange={(value) => switchFilter("friendly_name", "All", value)}

                  filterWords={reportData.interface.filter_words.friendly_name.map((filterWord) => ({
                    word: filterWord,
                    selected: isFilterSelected("friendly_name", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("friendly_name", value.word, value.selected)}

                  sortDirection={localInterface.sort.field === 'friendly_name' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'friendly_name', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />

                {
                  reportData.permissions.call_call_report_extended &&
                  <ReportTableField
                    contents={(<span>Source</span>)}

                    allFiltersSelected={isAllFiltersSelected("source")}
                    onAllFiltersChange={(value) => switchFilter("source", "All", value)}

                    filterWords={reportData.interface.filter_words.source.map((filterWord) => ({
                      word: filterWord,
                      selected: isFilterSelected("source", filterWord)
                    }))}
                    onFilterChange={(value) => switchFilter("source", value.word, value.selected)}

                    sortDirection={localInterface.sort.field === 'source' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'source', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />
                }

                {
                  reportData.permissions.call_call_report_extended &&
                  <ReportTableField
                    contents={(<span>Area</span>)}

                    allFiltersSelected={isAllFiltersSelected("area")}
                    onAllFiltersChange={(value) => switchFilter("area", "All", value)}

                    filterWords={reportData.interface.filter_words.area.map((filterWord) => ({
                      word: filterWord,
                      selected: isFilterSelected("area", filterWord)
                    }))}
                    onFilterChange={(value) => switchFilter("area", value.word, value.selected)}

                    sortDirection={localInterface.sort.field === 'area' ? localInterface.sort.direction : undefined}
                    onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'area', direction: value } })}

                    onFilterFire={() => $setUpdater(Math.random())}
                  />
                }
                <ReportTableField
                  contents={(<span>To</span>)}

                  sortDirection={localInterface.sort.field === 'call_to' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'call_to', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Extension</span>)}

                  sortDirection={localInterface.sort.field === 'extension' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'extension', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <th></th>
                <ReportTableField
                  contents={(<span>Status</span>)}

                  allFiltersSelected={isAllFiltersSelected("status")}
                  onAllFiltersChange={(value) => switchFilter("status", "All", value)}

                  filterWords={reportData.interface.filter_words.status.map((filterWord) => ({
                    word: filterWord,
                    selected: isFilterSelected("status", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("status", value.word, value.selected)}

                  sortDirection={localInterface.sort.field === 'status' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'status', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Duration</span>)}

                  sortDirection={localInterface.sort.field === 'duration' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'duration', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                {
                  reportData.permissions.call_call_record &&
                  <th></th>
                }
              </tr>
            </thead>

            {reportData.calls.map((call, i) => (
              <tr
                style={{ cursor: 'inherit', position: 'relative' }}
                key={i}
              >
                <td>{dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, call.created_at)}</td>
                <td>
                  <button
                    className={classNames('_zeroed', '_iconed', {
                      _red: ['no-answer', 'busy', 'canceled', 'failed'].includes(call.status),
                      _green: ['completed', 'ringing', 'in-progress'].includes(call.status) && call.direction === 'inbound',
                      _blue: call.direction === 'outbound' && call.status !== 'failed'
                    })}
                  >
                    <Icon
                      style={{ minWidth: '18px' }}
                      icon="arrow-20"
                      className={classNames({
                        '_rotated-180': call.direction === 'outbound'
                      })}
                    />
                  </button>
                </td>
                {
                  reportData.permissions.call_call_show_dispatcher &&
                  <td>{getDispatcherName(call.dispatcher, call.dispatcher_code, call.dispatcher_device)}</td>
                }
                <td>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <span>
                      {call.caller_name}
                    </span>
                    {
                      call.caller_name &&
                      <button
                        style={{
                          marginLeft: '5px',
                          width: '16px',
                          height: '16px',
                          minHeight: '16px',
                          minWidth: '16px',
                          backgroundColor: 'inherit',
                          padding: '0'
                        }}
                        onClick={() => {
                          localStorage.setItem('copy_name', call.caller_name)
                          localStorage.setItem('copy_phone', call.call_from)
                        }}
                        title={call.caller_name}
                      >
                        <Icon
                          style={{
                            width: '16px',
                            height: '16px',
                            minWidth: '16px',
                            verticalAlign: 'middle',
                            overflow: 'hidden',
                            fill: 'inherit'
                          }}
                          viewBox="0 0 1024 1024"
                          icon="copy-1"
                        />
                      </button>
                    }
                  </div>
                </td>
                {
                  reportData.permissions.use_private_contact_list &&
                  <td
                    style={{ cursor: call.contact_id ? 'pointer' : 'inherit' }}
                    onClick={() => call.contact_id && setEditContact({ initial_name: call.contact, friendly_name: call.contact, id: call.contact_id, error: '' })}
                  >
                    <span> {call.contact} </span>
                  </td>
                }
                <td>{call.client ? (
                  <div
                    className="flex-container"
                    style={{ gap: '5px', cursor: reportData.permissions.client_show && call.client_id ? 'pointer' : 'inherit' }}
                    onClick={() =>
                      reportData.permissions.client_show && call.client_id &&
                      $router.router.navigate('clients.item', {
                        companyId: activeAccountId, clientId: call.client_id
                      }, { reload: true })}
                    onContextMenu={(event) =>
                      reportData.permissions.client_show && call.client_id &&
                      handleChangeMenu(event, call.client_id, 'clients', i)}
                  >
                    <button
                      className="_zeroed _iconed _gray"
                      style={{ cursor: reportData.permissions.client_show && call.client_id ? 'pointer' : 'inherit' }}
                    >
                      <Icon style={{ minWidth: '18px' }} icon="user-32" />
                    </button>
                    <span>
                      {call.client}
                    </span>
                    {
                      reportData.permissions.client_show && call.client_id &&
                      showContext === `clients_${call.client_id}_${i}` &&
                      <div
                        className="redirect-menu"
                        ref={contextMenuRef}
                        style={{ left: `${screenX}px` }}
                      >
                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            $router.router.navigate('clients.item', {
                              companyId: activeAccountId, clientId: call.client_id
                            }, { reload: true })
                          }
                          }
                        >
                          Open
                        </button>

                        <button
                          onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            window.open(`${window.location.origin}/${activeAccountId}/clients/${call.client_id}`, "_blank", 'noopener,noreferrer')
                            setShowContext('')
                          }}
                        >
                          Open in new tab
                        </button>
                      </div>
                    }
                  </div>
                ) : null}</td>
                <td>
                  {
                    reportData.permissions.use_private_contact_list && !!!call.contact_id ?
                      <div
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <span>
                          {call.direction === 'outbound' ? formatPhoneNumber(call.call_to) : formatPhoneNumber(call.call_from)}
                        </span>

                        {
                          call.direction !== 'outbound' &&
                          <div
                            style={{
                              width: '16px',
                              height: '16px',
                              border: '1px solid #ccc',
                              borderRadius: '50%',
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              padding: '2px',
                              marginLeft: '5px',
                            }}
                            onClick={() => setAddContact({ phone: call.direction === 'outbound' ? call.call_to : call.call_from, friendly_name: '', error: '' })}
                          >
                            <Icon style={{ minWidth: '10px' }} icon="plus-thin" />
                          </div>
                        }
                      </div> :
                      <span>
                        {call.direction === 'outbound' ? formatPhoneNumber(call.call_to) : formatPhoneNumber(call.call_from)}
                      </span>
                  }
                </td>
                <td>{call.friendly_name}</td>
                {
                  reportData.permissions.call_call_report_extended &&
                  <td>
                    {call.source}
                  </td>
                }
                {
                  reportData.permissions.call_call_report_extended &&
                  <td>
                    {call.area}
                  </td>
                }

                <td>
                  {
                    reportData.permissions.use_private_contact_list && !!!call.contact_id ?
                      <div
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <span>
                          {call.direction === 'outbound' ? formatPhoneNumber(call.call_from) : formatPhoneNumber(call.call_to)}
                        </span>

                        {
                          call.direction === 'outbound' &&
                          <div
                            style={{
                              width: '16px',
                              height: '16px',
                              border: '1px solid #ccc',
                              borderRadius: '50%',
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              padding: '2px',
                              marginLeft: '5px',
                            }}
                            onClick={() => setAddContact({ phone: call.direction === 'outbound' ? call.call_from : call.call_to, friendly_name: '', error: '' })}
                          >
                            <Icon style={{ minWidth: '10px' }} icon="plus-thin" />
                          </div>
                        }
                      </div> :
                      <span>
                        {call.direction === 'outbound' ? formatPhoneNumber(call.call_from) : formatPhoneNumber(call.call_to)}
                      </span>
                  }
                </td>
                <td>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    onClick={() => softphone_call_extension && phoneCallStatus !== 'Not Available' && setExtensionCall(call.extension)}
                  >
                    <span>
                      {call.extension}
                    </span>
                    <Icon
                      style={{
                        width: '16px',
                        height: '16px',
                        minWidth: '16px',
                        marginBottom: '3px',
                        cursor: phoneCallStatus === 'Not Available' ? 'inherit' : 'pointer',
                        fill: phoneCallStatus === 'Not Available' ? '#8E8E93' : '#01B41F'
                      }}
                      icon="phone-1" />
                  </div>
                </td>
                <td
                  onClick={() =>
                    reportData.permissions.appointment_show &&
                    call.appointment_id &&
                    $router.router.navigate('appointments.item', {
                      companyId: activeAccountId, appointmentId: call.appointment_id
                    }, { reload: true })}
                  onContextMenu={(event) =>
                    reportData.permissions.appointment_show &&
                    call.appointment_id &&
                    handleChangeMenu(event, call.appointment_id, 'appointments', i)}
                >
                  {call.is_appointment ? (
                    <button
                      style={{ cursor: reportData.permissions.appointment_show ? 'pointer' : 'inherit' }}
                      className={classNames('_zeroed', '_iconed', {
                        _green: call.is_appointment === 'main',
                        _blue: call.is_appointment === 'lead'
                      })}>
                      <Icon style={{ minWidth: '18px' }} icon="clipboard-5" />
                    </button>
                  ) : null}
                  {
                    !!call.is_first ?
                      <div
                        style={{
                          backgroundColor: '#6093de',
                          borderRadius: '50%',
                          width: '16px',
                          height: '16px',
                          color: '#fff',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginTop: call.is_appointment ? '5px' : '0',
                          fontSize: '12px',
                        }}
                      >
                        <span>1</span>
                      </div> : null
                  }
                  {
                    reportData.permissions.appointment_show &&
                    showContext === `appointments_${call.appointment_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('appointments.item', {
                            companyId: activeAccountId, appointmentId: call.appointment_id
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/appointments/${call.appointment_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
                <td className={classNames({
                  'red-text': ['no-answer', 'busy', 'canceled', 'failed'].includes(call.status),
                  'green-text': call.status === 'completed',
                  'blue-text': !['no-answer', 'busy', 'completed', 'canceled', 'failed'].includes(call.status),
                })}>{call.status} {call.no_answered_reason ? `(${call.no_answered_reason})` : ''} </td>
                <td>{call.duration}</td>
                {
                  reportData.permissions.call_call_record &&
                  <td>
                    {call.call_url ? (
                      <button
                        className="_zeroed _iconed"
                        onClick={() => setLocalInterface({ ...localInterface, callListenPopup: call.call_url })}
                      >
                        <Icon style={{ minWidth: '18px' }} icon="media-control-48" />
                      </button>
                    ) : null}
                  </td>
                }
              </tr>
            ))}
          </table>

          {/* Medium screen table */}
          <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <tr></tr>
            {reportData.calls.map((call, i) => (
              <tr style={{ cursor: 'inherit', position: 'relative' }} key={i}>
                <td>
                  <div>
                    {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, call.created_at)}
                  </div>
                  <div className="flex-container _gap-narrow">
                    <button className={classNames('_zeroed', '_iconed', {
                      _red: ['no-answer', 'busy', 'canceled', 'failed'].includes(call.status),
                      _green: ['completed', 'ringing', 'in-progress'].includes(call.status) && call.direction === 'inbound',
                      _blue: call.direction === 'outbound' && call.status !== 'failed'
                    })}>
                      <Icon
                        style={{ minWidth: '18px' }}
                        icon="arrow-20"
                        className={classNames({
                          '_rotated-180': call.direction === 'outbound'
                        })}
                      />
                    </button>
                    {
                      reportData.permissions.call_call_show_dispatcher &&
                      <span>Dispatcher: {getDispatcherName(call.dispatcher, call.dispatcher_code, call.dispatcher_device)}</span>
                    }
                  </div>
                </td>
                <td>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <span>
                      {call.caller_name}
                    </span>
                    {
                      reportData.permissions.use_private_contact_list &&
                      <div
                        style={{ cursor: call.contact_id ? 'pointer' : 'inherit' }}
                        onClick={() => call.contact_id && setEditContact({ initial_name: call.contact, friendly_name: call.contact, id: call.contact_id, error: '' })}
                      >
                        <span> {call.contact} </span>
                      </div>
                    }
                    {
                      call.caller_name &&
                      <button
                        style={{
                          marginLeft: '5px',
                          width: '16px',
                          height: '16px',
                          minHeight: '16px',
                          minWidth: '16px',
                          backgroundColor: 'inherit',
                          padding: '0'
                        }}
                        onClick={() => {
                          localStorage.setItem('copy_name', call.caller_name)
                          localStorage.setItem('copy_phone', call.call_from)
                        }}
                        title={call.caller_name}
                      >
                        <Icon
                          style={{
                            width: '16px',
                            height: '16px',
                            minWidth: '16px',
                            verticalAlign: 'middle',
                            overflow: 'hidden',
                            fill: 'inherit'
                          }}
                          viewBox="0 0 1024 1024"
                          icon="copy-1"
                        />
                      </button>
                    }
                  </div>
                  {call.client ? (
                    <div
                      className="flex-container"
                      style={{ gap: '5px', cursor: reportData.permissions.client_show && call.client_id ? 'pointer' : 'inherit' }}
                      onClick={() =>
                        reportData.permissions.client_show && call.client_id &&
                        $router.router.navigate('clients.item', {
                          companyId: activeAccountId, clientId: call.client_id
                        }, { reload: true })}
                      onContextMenu={(event) =>
                        reportData.permissions.client_show && call.client_id &&
                        handleChangeMenu(event, call.client_id, 'clients', i)}
                    >
                      <button
                        style={{ gap: '5px', cursor: reportData.permissions.client_show && call.client_id ? 'pointer' : 'inherit' }}
                        className="_zeroed _iconed _gray">
                        <Icon style={{ minWidth: '18px' }} icon="user-32" />
                      </button>
                      <span>{call.client}</span>
                      {
                        reportData.permissions.client_show && call.client_id &&
                        showContext === `clients_${call.client_id}_${i}` &&
                        <div
                          className="redirect-menu"
                          ref={contextMenuRef}
                          style={{ left: `${screenX}px` }}
                        >
                          <button
                            onClick={(event) => {
                              event.preventDefault()
                              $router.router.navigate('clients.item', {
                                companyId: activeAccountId, clientId: call.client_id
                              }, { reload: true })
                            }
                            }
                          >
                            Open
                          </button>

                          <button
                            onClick={(event) => {
                              event.preventDefault()
                              event.stopPropagation()
                              window.open(`${window.location.origin}/${activeAccountId}/clients/${call.client_id}`, "_blank", 'noopener,noreferrer')
                              setShowContext('')
                            }}
                          >
                            Open in new tab
                          </button>
                        </div>
                      }
                    </div>
                  ) : <span>&nbsp;</span>}
                </td>
                <td>
                  {
                    reportData.permissions.use_private_contact_list && !!!call.contact_id ?
                      <div
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <span>
                          From: {call.direction === 'outbound' ? formatPhoneNumber(call.call_to) : formatPhoneNumber(call.call_from)}
                        </span>

                        {
                          call.direction !== 'outbound' &&
                          <div
                            style={{
                              width: '16px',
                              height: '16px',
                              border: '1px solid #ccc',
                              borderRadius: '50%',
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              padding: '2px',
                              marginLeft: '10px',
                            }}
                            onClick={() => setAddContact({ phone: call.direction === 'outbound' ? call.call_to : call.call_from, friendly_name: '', error: '' })}
                          >
                            <Icon style={{ minWidth: '10px' }} icon="plus-thin" />
                          </div>
                        }
                      </div> :
                      <div>
                        From: {call.direction === 'outbound' ? formatPhoneNumber(call.call_to) : formatPhoneNumber(call.call_from)}
                      </div>
                  }

                  {
                    reportData.permissions.use_private_contact_list && !!!call.contact_id ?
                      <div
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <span>
                          To: {call.direction === 'outbound' ? formatPhoneNumber(call.call_from) : formatPhoneNumber(call.call_to)}
                        </span>

                        {
                          call.direction === 'outbound' &&
                          <div
                            style={{
                              width: '16px',
                              height: '16px',
                              border: '1px solid #ccc',
                              borderRadius: '50%',
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              padding: '2px',
                              marginLeft: '10px',
                            }}
                            onClick={() => setAddContact({ phone: call.direction === 'outbound' ? call.call_from : call.call_to, friendly_name: '', error: '' })}
                          >
                            <Icon style={{ minWidth: '10px' }} icon="plus-thin" />
                          </div>
                        }
                      </div> :
                      <div>To: {call.direction === 'outbound' ? formatPhoneNumber(call.call_from) : formatPhoneNumber(call.call_to)}</div>
                  }
                </td>
                <td>
                  <div>{call.friendly_name}</div>
                  {
                    reportData.permissions.call_call_report_extended &&
                    <div> {call.source} </div>
                  }

                  {
                    reportData.permissions.call_call_report_extended &&
                    <div> {call.area} </div>
                  }
                  <div
                    style={{ display: 'flex', alignItems: 'flex-end' }}
                    onClick={() => softphone_call_extension && phoneCallStatus !== 'Not Available' && setExtensionCall(call.extension)}
                  >
                    <span>
                      Extension: {call.extension}
                    </span>
                    <span style={{ marginBottom: '1px' }} >
                      <Icon
                        style={{
                          width: '17px',
                          height: '17px',
                          minWidth: '17px',
                          marginLeft: '5px',
                          cursor: phoneCallStatus === 'Not Available' ? 'inherit' : 'pointer',
                          fill: phoneCallStatus === 'Not Available' ? '#8E8E93' : '#01B41F'
                        }}
                        icon="phone-1" />
                    </span>
                  </div>
                </td>
                <td>
                  {call.is_appointment ? (
                    <button
                      className={classNames('_zeroed', '_iconed', {
                        _green: call.is_appointment === 'main',
                        _blue: call.is_appointment === 'lead'
                      })}
                      style={{ cursor: reportData.permissions.appointment_show ? 'pointer' : 'inherit' }}
                      onClick={() =>
                        reportData.permissions.appointment_show &&
                        call.appointment_id &&
                        $router.router.navigate('appointments.item', {
                          companyId: activeAccountId, appointmentId: call.appointment_id
                        }, { reload: true })}
                      onContextMenu={(event) =>
                        reportData.permissions.appointment_show &&
                        call.appointment_id &&
                        handleChangeMenu(event, call.appointment_id, 'appointments', i)}
                    >
                      <Icon style={{ minWidth: '18px' }} icon="clipboard-5" />
                    </button>
                  ) : null}
                  {
                    !!call.is_first ?
                      <div
                        style={{
                          backgroundColor: '#6093de',
                          borderRadius: '50%',
                          width: '16px',
                          height: '16px',
                          color: '#fff',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          margin: '0 auto',
                          marginTop: call.is_appointment ? '5px' : '0',
                          fontSize: '12px',
                        }}
                      >
                        <span>1</span>
                      </div> : null
                  }
                  {
                    reportData.permissions.appointment_show &&
                    showContext === `appointments_${call.appointment_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('appointments.item', {
                            companyId: activeAccountId, appointmentId: call.appointment_id
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/appointments/${call.appointment_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
                <td>
                  <div className={classNames({
                    'red-text': ['no-answer', 'busy', 'canceled', 'failed'].includes(call.status),
                    'green-text': call.status === 'completed',
                    'blue-text': !['no-answer', 'busy', 'completed', 'canceled', 'failed'].includes(call.status),
                  })}>{call.status} {call.no_answered_reason ? `(${call.no_answered_reason})` : ''}</div>
                  <div className="flex-container _gap-narrow">
                    <div>{call.duration}</div>
                    {reportData.permissions.call_call_record && call.call_url ? (
                      <button className="_zeroed _iconed" onClick={() => setLocalInterface({ ...localInterface, callListenPopup: call.call_url })}>
                        <Icon style={{ minWidth: '18px' }} icon="media-control-48" />
                      </button>
                    ) : null}
                  </div>
                </td>
              </tr>
            ))}
          </table>

          {/* Mobile table items */}
          <div className={classNames('mobile-table-items', '__show-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>

            {reportData.calls.map((call, i: number) => (
              <div className="item" key={i}>
                <div className="__top">
                  <div className="__left">
                    <div className="flex-container">
                      <button className={classNames('_zeroed', '_iconed', {
                        _red: ['no-answer', 'busy', 'canceled', 'failed'].includes(call.status),
                        _green: ['completed', 'ringing', 'in-progress'].includes(call.status) && call.direction === 'inbound',
                        _blue: call.direction === 'outbound' && call.status !== 'failed'
                      })}>
                        <Icon
                          style={{ minWidth: '18px' }}
                          icon="arrow-20"
                          className={classNames({
                            '_rotated-180': call.direction === 'outbound'
                          })}
                        />
                      </button>
                      <strong>{call.friendly_name}</strong>
                    </div>
                    {
                      reportData.permissions.call_call_report_extended &&
                      <div> {call.source} </div>
                    }

                    {
                      reportData.permissions.call_call_report_extended &&
                      <div> {call.area} </div>
                    }
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <span>
                          <strong>{call.caller_name}</strong>
                        </span>
                        {
                          call.caller_name &&
                          <button
                            style={{
                              marginLeft: '5px',
                              width: '16px',
                              height: '16px',
                              minHeight: '16px',
                              minWidth: '16px',
                              backgroundColor: 'inherit',
                              padding: '0'
                            }}
                            onClick={() => {
                              localStorage.setItem('copy_name', call.caller_name)
                              localStorage.setItem('copy_phone', call.call_from)
                            }}
                            title={call.caller_name}
                          >
                            <Icon
                              style={{
                                width: '16px',
                                height: '16px',
                                minWidth: '16px',
                                verticalAlign: 'middle',
                                overflow: 'hidden',
                                fill: 'inherit'
                              }}
                              viewBox="0 0 1024 1024"
                              icon="copy-1"
                            />
                          </button>
                        }
                      </div>
                    </div>
                    {
                      reportData.permissions.use_private_contact_list &&
                      <div
                        style={{ cursor: call.contact_id ? 'pointer' : 'inherit' }}
                        onClick={() => call.contact_id && setEditContact({ initial_name: call.contact, friendly_name: call.contact, id: call.contact_id, error: '' })}
                      >
                        <span> {call.contact} </span>
                      </div>
                    }
                    {call.client ? (
                      <div
                        className="flex-container"
                        style={{ gap: '5px', cursor: reportData.permissions.client_show && call.client_id ? 'pointer' : 'inherit' }}
                        onClick={() =>
                          reportData.permissions.client_show && call.client_id &&
                          $router.router.navigate('clients.item', {
                            companyId: activeAccountId, clientId: call.client_id
                          }, { reload: true })}
                      >
                        <button
                          style={{ gap: '5px', cursor: reportData.permissions.client_show && call.client_id ? 'pointer' : 'inherit' }}
                          className="_zeroed _iconed _gray">
                          <Icon style={{ minWidth: '18px' }} icon="user-32" />
                        </button>
                        <span>{call.client}</span>
                      </div>
                    ) : (<span>&nbsp;</span>)}
                  </div>

                  <div className="__right">
                    <div className={classNames({
                      'red-text': ['no-answer', 'busy', 'canceled', 'failed'].includes(call.status),
                      'green-text': call.status === 'completed',
                      'blue-text': !['no-answer', 'busy', 'completed', 'canceled', 'failed'].includes(call.status),
                    })}>
                      {call.status} {call.no_answered_reason ? `(${call.no_answered_reason})` : ''}
                    </div>
                    <div className="flex-container">
                      <span>{call.duration}</span>
                      {reportData.permissions.call_call_record && call.call_url ? (
                        <button className="_zeroed _iconed" onClick={() => setLocalInterface({ ...localInterface, callListenPopup: call.call_url })}>
                          <Icon style={{ minWidth: '18px' }} icon="media-control-48" />
                        </button>
                      ) : null}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {call.is_appointment ? (
                        <button
                          style={{ cursor: reportData.permissions.appointment_show ? 'pointer' : 'inherit' }}
                          onClick={() =>
                            reportData.permissions.appointment_show &&
                            call.appointment_id &&
                            $router.router.navigate('appointments.item', {
                              companyId: activeAccountId, appointmentId: call.appointment_id
                            }, { reload: true })}
                          className={classNames('_zeroed', '_iconed', {
                            _green: call.is_appointment === 'main',
                            _blue: call.is_appointment === 'lead'
                          })}>
                          <Icon style={{ minWidth: '18px' }} icon="clipboard-5" />
                        </button>
                      ) : null}

                      {
                        !!call.is_first ?
                          <div
                            style={{
                              backgroundColor: '#6093de',
                              borderRadius: '50%',
                              width: '16px',
                              height: '16px',
                              color: '#fff',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              marginLeft: call.is_appointment ? '5px' : '0',
                              fontSize: '12px',
                            }}
                          >
                            <span>1</span>
                          </div> : null
                      }
                    </div>
                  </div>
                </div>

                <div className="__bottom">
                  <div className="__left">
                    {
                      reportData.permissions.use_private_contact_list && !!!call.contact_id ?
                        <div
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <div>
                            <span className="gray">From:</span>
                            <span>
                              {call.direction === 'outbound' ? formatPhoneNumber(call.call_to) : formatPhoneNumber(call.call_from)}
                            </span>
                          </div>

                          {
                            call.direction !== 'outbound' &&
                            <div
                              style={{
                                width: '16px',
                                height: '16px',
                                border: '1px solid #ccc',
                                borderRadius: '50%',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '2px',
                                marginLeft: '10px',
                              }}
                              onClick={() => setAddContact({ phone: call.direction === 'outbound' ? call.call_to : call.call_from, friendly_name: '', error: '' })}
                            >
                              <Icon style={{ minWidth: '10px' }} icon="plus-thin" />
                            </div>
                          }
                        </div> :
                        <div>
                          <span className="gray">From:</span>
                          <span>
                            {call.direction === 'outbound' ? formatPhoneNumber(call.call_to) : formatPhoneNumber(call.call_from)}
                          </span>
                        </div>
                    }

                    {
                      reportData.permissions.use_private_contact_list && !!!call.contact_id ?
                        <div
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <div>
                            <span className="gray">To:</span>
                            <span> {call.direction === 'outbound' ? formatPhoneNumber(call.call_from) : formatPhoneNumber(call.call_to)}</span>
                          </div>

                          {
                            call.direction === 'outbound' &&
                            <div
                              style={{
                                width: '16px',
                                height: '16px',
                                border: '1px solid #ccc',
                                borderRadius: '50%',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '2px',
                                marginLeft: '10px',
                              }}
                              onClick={() => setAddContact({ phone: call.direction === 'outbound' ? call.call_from : call.call_to, friendly_name: '', error: '' })}
                            >
                              <Icon style={{ minWidth: '10px' }} icon="plus-thin" />
                            </div>
                          }
                        </div> :
                        <div>
                          <span className="gray">To:</span>
                          <span> {call.direction === 'outbound' ? formatPhoneNumber(call.call_from) : formatPhoneNumber(call.call_to)}</span>
                        </div>
                    }

                    <div
                      style={{ display: 'flex' }}
                      onClick={() => softphone_call_extension && phoneCallStatus !== 'Not Available' && setExtensionCall(call.extension)}
                    >
                      <span className="gray">Extension:</span>
                      <span style={{ margin: '0 5px' }} > {call.extension}</span>
                      <span>
                        <Icon style={{
                          width: '16px',
                          height: '16px',
                          minWidth: '16px',
                          cursor: phoneCallStatus === 'Not Available' ? 'inherit' : 'pointer',
                          fill: phoneCallStatus === 'Not Available' ? '#8E8E93' : '#01B41F'
                        }} icon="phone-1" />
                      </span>
                    </div>
                  </div>

                  <div className="__right">
                    <div>
                      {dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, call.created_at)}
                    </div>
                    {
                      reportData.permissions.call_call_show_dispatcher &&
                      <div>
                        Dispatcher: {getDispatcherName(call.dispatcher, call.dispatcher_code, call.dispatcher_device)}
                      </div>
                    }
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Table controls */}
        <ReportTableControls
          isUnder={true}

          cookie_reportsMaxRows={cookiesSetting?.calls_calls?.limit_rows}

          onMaxRowsChange={(value) => {
            $setUpdater(Math.random())
            setCookie('settings', { ...cookiesSetting, calls_calls: { ...cookiesSetting.calls_calls, limit_rows: value } })
          }}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}
          onPagesStart={() => {
            setLocalInterface({ ...localInterface, page: 1 })
            $setUpdater(Math.random())
          }}
          onPrevPage={() => {
            setLocalInterface({ ...localInterface, page: localInterface.page - 1 })
            $setUpdater(Math.random())
          }}
          onNextPage={() => {
            setLocalInterface({ ...localInterface, page: localInterface.page + 1 })
            $setUpdater(Math.random())
          }}
          onPagesEnd={() => {
            setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })
            $setUpdater(Math.random())
          }}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => {
            setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })
          }}
          onSortDirectionChange={(value) => {
            setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })
          }}
          onSortFire={() => $setUpdater(Math.random())}
        />

        {/* Call listen popup */}
        {localInterface.callListenPopup ? (
          <div className="popup callListenPopup" onClick={() => setLocalInterface({ ...localInterface, callListenPopup: null })}>

            <div className="wrapper" onClick={(e) => e.stopPropagation()}>

              <div className="call-player">
                <AudioPlayer audioSrc={localInterface.callListenPopup} />
              </div>
            </div>
          </div>
        ) : null}

        {
          addContact &&
          <div className="popup item-delete-popup">
            <div
              style={{ maxWidth: '350px', padding: '20px' }}
              className="wrapper"
              onClick={(e) => e.stopPropagation()}
            >
              <div
                className="title"
                style={{
                  background: '#6093de',
                  color: '#fff',
                  fontSize: '16pt',
                  padding: '7px 12px',
                  margin: '0 -20px',
                  textAlign: 'center'
                }}
              >
                <p style={{ margin: '0' }}>
                  Add contact for number
                </p>

                <p style={{ margin: '10px 0 0 0' }}>
                  {formatPhoneNumber(addContact.phone)}
                </p>
              </div>

              <div style={{ marginTop: '20px' }}>
                <input
                  type="text"
                  placeholder='Friendly Name'
                  value={addContact.friendly_name}
                  onChange={(event) => setAddContact({ ...addContact, friendly_name: event.target.value })}
                />
              </div>

              {
                !!addContact.error &&
                <div style={{ marginTop: '20px', textAlign: 'center', color: '#FF0000' }}>
                  {addContact.error}
                </div>
              }

              <div
                className="buttons"
                style={{
                  display: 'flex',
                  gridGap: '10px',
                  gap: '10px',
                  marginTop: '30px',
                  justifyContent: 'space-around'
                }}
              >
                <button
                  className="_bordered _red"
                  onClick={() => setAddContact(null)}
                >
                  <span>Cancel</span>
                </button>

                <button
                  disabled={addContact.friendly_name === ''}
                  className="_bordered _green"
                  onClick={() => handleAddContact()}
                >
                  <span>Save</span>
                </button>
              </div>
            </div>
          </div>
        }

        {
          editContact &&
          <div className="popup item-delete-popup">
            <div
              style={{ maxWidth: '350px', padding: '20px' }}
              className="wrapper"
              onClick={(e) => e.stopPropagation()}
            >
              <div
                className="title"
                style={{
                  background: '#6093de',
                  color: '#fff',
                  fontSize: '16pt',
                  padding: '7px 12px',
                  margin: '0 -20px',
                  textAlign: 'center'
                }}
              >
                <p style={{ margin: '0' }}>
                  Update contact name
                </p>
              </div>

              <div style={{ marginTop: '20px' }}>
                <input
                  type="text"
                  placeholder='Friendly Name'
                  value={editContact.friendly_name}
                  onChange={(event) => setEditContact({ ...editContact, friendly_name: event.target.value })}
                />
              </div>

              {
                !!editContact.error &&
                <div style={{ marginTop: '20px', textAlign: 'center', color: '#FF0000' }}>
                  {editContact.error}
                </div>
              }

              <div
                className="buttons"
                style={{
                  display: 'flex',
                  gridGap: '10px',
                  gap: '10px',
                  marginTop: '30px',
                  justifyContent: 'space-around'
                }}
              >
                <button
                  className="_bordered _red"
                  onClick={() => setEditContact(null)}
                >
                  <span>Cancel</span>
                </button>

                <button
                  disabled={editContact.friendly_name === '' || editContact.friendly_name === editContact.initial_name}
                  className="_bordered _green"
                  onClick={() => handleUpdateContact()}
                >
                  <span>Save</span>
                </button>
              </div>
            </div>
          </div>
        }

        {/* Call extentions */}
        {extensionCall ? (
          <div className="popup item-delete-popup">

            <div style={{ maxWidth: '350px', padding: '20px' }} className="wrapper" onClick={(e) => e.stopPropagation()}>
              <div
                className="title"
                style={{
                  background: '#6093de',
                  color: '#fff',
                  fontSize: '16pt',
                  padding: '7px 12px',
                  margin: '0 -20px',
                  textAlign: 'center'
                }}
              >
                <p style={{ margin: '0' }}>
                  Are you sure you want to call to
                </p>
                <p style={{ margin: '0' }}>
                  ext {extensionCall}
                </p>
              </div>

              <div
                className="buttons"
                style={{
                  display: 'flex',
                  gridGap: '10px',
                  gap: '10px',
                  marginTop: '30px',
                  justifyContent: 'space-around'
                }}
              >
                <button
                  className="_wa _iconed _bordered _blue"
                  onClick={() => setExtensionCall('')}
                >
                  <span>No</span>
                </button>

                <button
                  className="_wa _iconed _bordered _blue"
                  onClick={() => handleCall()}
                >
                  <span>Yes</span>
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    ) : null}
  </>)
}

export default CallsPage_List
