import { memo } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';

import CheckoutForm from './CheckoutForm';

interface StripeComponentProps {
  cancel: () => void
  disabled: boolean
  setLoading: (value: boolean) => void
  tokenStripe: string
  disabledCancel: boolean
  handleSaveStripePay: (value: string) => void
  error_limit: boolean
  setErrorPaymentLimit: (value: boolean) => void
  error_amount: boolean
  setErrorPaymentAmount: (value: boolean) => void
}

function StripeComponent({
  cancel,
  disabled,
  setLoading,
  tokenStripe,
  disabledCancel,
  handleSaveStripePay,
  error_limit,
  setErrorPaymentLimit,
  error_amount,
  setErrorPaymentAmount,
}: StripeComponentProps) {
  loadStripe.setLoadParameters({ advancedFraudSignals: false });
  const stripePromise = loadStripe(tokenStripe);

  const options = {
    mode: 'setup',
    currency: 'usd',
  };

  return (
    // @ts-ignore
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm
        cancel={cancel}
        disabled={disabled}
        setLoading={setLoading}
        disabledCancel={disabledCancel}
        handleSaveStripePay={handleSaveStripePay}
        error_limit={error_limit}
        setErrorPaymentLimit={setErrorPaymentLimit}
        error_amount={error_amount}
        setErrorPaymentAmount={setErrorPaymentAmount}
      />
    </Elements>
  );
};

export default memo(StripeComponent)