import { useState, useEffect } from 'react'
import qs from 'qs'
import classNames from 'classnames'

import Select from '../../components/Select'
import Icon from '../../components/Icon'
import UnitTable from './UnitTable'

import { useAppSelector } from "../../store/hooks"
import { httpClientUpdate } from '../../funcs'

import { UnitProps } from '../Jobs/ItemTech'

import "../../styles/pages/common/entity-edit.sass"

interface Part {
  status_id: number
  name: string
  part_number: number | null
  price: number
  warehouse: string
  warehouse_id: string
  other_warehouse: string
  part_name: string
  part_owner: string
  quantity: number
  unit_id: string[]
}

interface PartsAddProps {
  switchPage: (name: string) => void
  jobId: string
  edit: {
    part_status: {
      part_status: string
      part_status_id: number
    }[]
    warehouses: {
      warehouse: string
      warehouse_id: string
    }[]
  }
  units: UnitProps[]
  status_id: number
}

export default function PartsAdd({ switchPage, jobId, edit, units, status_id }: PartsAddProps) {
  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)

  const [readyToSave, setReadyToSave] = useState(false)

  const [newData, setNewData] = useState<Part>({
    status_id: status_id,
    name: '',
    part_number: null,
    price: 0,
    quantity: 1,
    warehouse: '',
    warehouse_id: '',
    other_warehouse: '',
    part_name: '',
    part_owner: 'Technician',
    unit_id: []
  })

  useEffect(() => setReadyToSave(true), [
    newData
  ])

  function handleCancelEdit() {
    switchPage('main')
  }

  async function handleSave() {
    const response = await httpClientUpdate.post(`/technician/parts`, qs.stringify({
      account_id: activeAccountId,
      price: newData.price,
      units: newData.unit_id,
      part_number: newData.part_number,
      quantity: newData.quantity,
      name: newData.name,
      warehouse_id: newData.warehouse_id,
      other_warehouse: newData.other_warehouse ? newData.other_warehouse : null,
      owner: newData.part_owner,
      job_id: jobId,
      invoice_id: '88888292aaaaaaaaaa'
    }, { skipNulls: true }))
    if (response.data.success) {
      switchPage('main')
    }
  }

  function handleChangePrice(event: any) {
    let price = event.target.value
    let numberWithoutDot = price.split('.')
    if (numberWithoutDot[1].toString().length > 2) {
      let newNumber = `${numberWithoutDot[0]}${numberWithoutDot[1].toString()[0]}.${numberWithoutDot[1].toString()[1]}${price[price.length - 1]}`
      setNewData({
        ...newData,
        price: +newNumber
      })
    }
  }

  function handleChangePriceBackspace(event: any) {
    if (event.keyCode === 8) {
      event.preventDefault()
      let price = event.target.value
      let numberWithoutDot = `${price}`.split('.')
      if (numberWithoutDot[0].length > 1) {
        let newNumber = `${numberWithoutDot[0].slice(0, -1)}.${numberWithoutDot[0][numberWithoutDot[0].length - 1]}${numberWithoutDot[1][0]}`
        setNewData({
          ...newData,
          price: +newNumber
        })
      } else {
        let newNumber = `0.${numberWithoutDot[0][0]}${numberWithoutDot[1][0]}`
        setNewData({
          ...newData,
          price: +newNumber
        })
      }
    }
  }

  function handleFocus(event: any) {
    const target = event.currentTarget;

    target.type = 'text';
    target.setSelectionRange(target.value.length, target.value.length);
    target.type = 'number';
    target && target.focus()
  }

  function checkOtherField() {
    let warehouses = edit.warehouses.filter(item => item.warehouse_id === newData.warehouse_id)
    return warehouses?.length === 1 ? warehouses[0].warehouse : ''
  }

  function checkSave() {
    let check = false

    if (newData.name === '') {
      check = true
    }

    if (newData.part_number === null) {
      check = true
    }

    if (newData.price === 0) {
      check = true
    }

    if (newData.warehouse_id === '') {
      check = true
    } else {
      if (checkOtherField() === 'Other') {
        if (newData.other_warehouse === '') {
          check = true
        }
      }
    }

    if (newData.unit_id.length === 0) {
      check = true
    }

    return check
  }

  function getTotal() {
    let sum = 0

    sum = newData.price * newData.quantity

    return `$${sum.toFixed(2)}`
  }

  return (
    <div className="EstimatePage_Edit entity-edit item-job">
      <div className="wrapper flex-container sb">
        <h1>Add new Part</h1>

        <div style={{ display: 'flex' }} >
          <button
            style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
            className="_wa"
            onClick={() => switchPage('main')}
          >
            <Icon
              style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
              viewBox="0 0 24 24"
              icon="arrow-25"
            />
          </button>
        </div>
      </div>

      <div className="fieldset">
        <div className={classNames('__show-on-wide', {
          __respectAsidePanel: navActive && !phoneCall,
          __phoneCall: phoneCall && !navActive,
          __bothOpen: navActive && phoneCall,
          __nonePanel: !navActive && !phoneCall
        })}>
          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Name:<span className='requered_field'>*</span></span>
                <input
                  type="text"
                  defaultValue={newData.name}
                  onChange={({ target: { value } }) => setNewData({ ...newData, name: value })}
                />
              </div>

              <div className="field">
                <span>Part Number:<span className='requered_field'>*</span></span>
                <input
                  type="number"
                  defaultValue={newData.part_number || ''}
                  onChange={({ target: { value } }) => setNewData({ ...newData, part_number: +value })}
                />
              </div>
            </div>

            <div className="__right">
              <div className="field">
                <span>Price:<span className='requered_field'>*</span></span>
                <input
                  type="number"
                  pattern="[0-9]*"
                  style={{ textAlign: 'right' }}
                  value={newData.price.toFixed(2)}
                  onFocus={(event) => handleFocus(event)}
                  onChange={(event) => handleChangePrice(event)}
                  onKeyDown={handleChangePriceBackspace}
                />
              </div>

              <div className="field">
                <span>Warehouse:<span className='requered_field'>*</span></span>
                <Select
                  options={edit.warehouses.sort((a, b) => a.warehouse !== 'Other' ? a.warehouse.localeCompare(b.warehouse) : 0).map((option) => ({
                    span: option.warehouse,
                    value: option.warehouse_id
                  }))}
                  selectedOption={newData.warehouse_id as string}
                  onChange={(value) => setNewData({ ...newData, warehouse_id: value as string, other_warehouse: '' })}
                />
              </div>

              {
                checkOtherField() === 'Other' &&
                <div className="field">
                  <span>Other Warehouse:<span className='requered_field'>*</span></span>

                  <input
                    type="text"
                    defaultValue={newData.other_warehouse}
                    onChange={({ target: { value } }) => setNewData({ ...newData, other_warehouse: value })}
                  />
                </div>
              }

              <div className="field">
                <span>Part`s Owner:<span className='requered_field'>*</span></span>
                <Select
                  options={['Technician', 'Company'].map((option) => ({
                    span: option,
                    value: option
                  }))}
                  selectedOption={newData.part_owner as string}
                  onChange={(value) => setNewData({ ...newData, part_owner: value as string })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={classNames('__hide-on-wide', '__hide-on-mobile', {
          __respectAsidePanel: navActive && !phoneCall,
          __phoneCall: phoneCall && !navActive,
          __bothOpen: navActive && phoneCall,
          __nonePanel: !navActive && !phoneCall
        })}>
          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Name:<span className='requered_field'>*</span></span>
                <input
                  type="text"
                  defaultValue={newData.name}
                  onChange={({ target: { value } }) => setNewData({ ...newData, name: value })}
                />
              </div>

              <div className="field">
                <span>Part Number:<span className='requered_field'>*</span></span>
                <input
                  type="number"
                  defaultValue={newData.part_number || ''}
                  onChange={({ target: { value } }) => setNewData({ ...newData, part_number: +value })}
                />
              </div>

              <div className="field">
                <span>Price:<span className='requered_field'>*</span></span>
                <input
                  type="number"
                  pattern="[0-9]*"
                  style={{ textAlign: 'right' }}
                  value={newData.price.toFixed(2)}
                  onFocus={(event) => handleFocus(event)}
                  onChange={(event) => handleChangePrice(event)}
                  onKeyDown={handleChangePriceBackspace}
                />
              </div>

              <div className='field'>
                <span>Quantity:</span>

                <input
                  className='model_number'
                  type="number"
                  value={newData.quantity}
                  onChange={(event) => setNewData({ ...newData, quantity: +event.target.value })}
                />
              </div>
            </div>

            <div className="__right">
              <div className="field">
                <span>Warehouse:<span className='requered_field'>*</span></span>
                <Select
                  options={edit.warehouses.sort((a, b) => a.warehouse !== 'Other' ? a.warehouse.localeCompare(b.warehouse) : 0).map((option) => ({
                    span: option.warehouse,
                    value: option.warehouse_id
                  }))}
                  selectedOption={newData.warehouse_id as string}
                  onChange={(value) => setNewData({ ...newData, warehouse_id: value as string, other_warehouse: '' })}
                />
              </div>

              {
                checkOtherField() === 'Other' &&
                <div className="field">
                  <span>Other Warehouse:<span className='requered_field'>*</span></span>

                  <input
                    type="text"
                    defaultValue={newData.other_warehouse}
                    onChange={({ target: { value } }) => setNewData({ ...newData, other_warehouse: value })}
                  />
                </div>
              }

              <div className="field">
                <span>Part`s Owner:<span className='requered_field'>*</span></span>
                <Select
                  options={['Technician', 'Company'].map((option) => ({
                    span: option,
                    value: option
                  }))}
                  selectedOption={newData.part_owner as string}
                  onChange={(value) => setNewData({ ...newData, part_owner: value as string })}
                />
              </div>
            </div>
          </div>

          <div style={{ marginTop: '20px' }}>
            <UnitTable
              units={units}
              unit_id={newData.unit_id}
              addUnit={(value) => setNewData({ ...newData, unit_id: value })}
            />
          </div>

          <div
            className='row unit_type'
            style={{ marginTop: '40px' }}
          >
            <span style={{ fontWeight: 'bold' }}>Total:</span>

            <span style={{ fontWeight: 'bold' }}> {getTotal()} </span>
          </div>
        </div>

        <div className={classNames('__show-on-mobile', {
          __respectAsidePanel: navActive && !phoneCall,
          __phoneCall: phoneCall && !navActive,
          __bothOpen: navActive && phoneCall,
          __nonePanel: !navActive && !phoneCall
        })}>
          <div className="item">
            <div className='row unit_type'>
              <span>Name:<span className='requered_field'>*</span></span>

              <input
                type="text"
                className='model_number'
                defaultValue={newData.name}
                onChange={({ target: { value } }) => setNewData({ ...newData, name: value })}
              />
            </div>

            <div className='row unit_type'>
              <span>Part Number:<span className='requered_field'>*</span></span>

              <input
                type="text"
                className='model_number'
                defaultValue={newData.part_number || ''}
                onChange={({ target: { value } }) => setNewData({ ...newData, part_number: +value })}
              />
            </div>

            <div className='row unit_type'>
              <span>Price:<span className='requered_field'>*</span></span>

              <input
                className='model_number'
                type="number"
                pattern="[0-9]*"
                style={{ textAlign: 'right' }}
                value={newData.price.toFixed(2)}
                onFocus={(event) => handleFocus(event)}
                onChange={(event) => handleChangePrice(event)}
                onKeyDown={handleChangePriceBackspace}
              />
            </div>

            <div className='row unit_type'>
              <span>Warehouse:<span className='requered_field'>*</span></span>

              <Select
                options={edit.warehouses.sort((a, b) => a.warehouse !== 'Other' ? a.warehouse.localeCompare(b.warehouse) : 0).map((option) => ({
                  span: option.warehouse,
                  value: option.warehouse_id
                }))}
                selectedOption={newData.warehouse_id as string}
                onChange={(value) => setNewData({ ...newData, warehouse_id: value as string, other_warehouse: '' })}
              />
            </div>

            {
              checkOtherField() === 'Other' &&
              <div className="row unit_type other">
                <span>Other Warehouse:<span className='requered_field'>*</span></span>

                <input
                  className='model_number'
                  name='other_brand'
                  type="text"
                  defaultValue={newData.other_warehouse}
                  onChange={({ target: { value } }) => setNewData({ ...newData, other_warehouse: value })}
                />
              </div>
            }

            <div className='row unit_type'>
              <span>Part`s Owner:<span className='requered_field'>*</span></span>

              <Select
                options={['Technician', 'Company'].map((option) => ({
                  span: option,
                  value: option
                }))}
                selectedOption={newData.part_owner as string}
                onChange={(value) => setNewData({ ...newData, part_owner: value as string })}
              />
            </div>

            <div className='row unit_type'>
              <span>Quantity:</span>

              <input
                className='model_number'
                type="number"
                value={newData.quantity}
                onChange={(event) => setNewData({ ...newData, quantity: +event.target.value })}
              />
            </div>

            <div className='line-items-content service'>
              <UnitTable
                units={units}
                unit_id={newData.unit_id}
                addUnit={(value) => setNewData({ ...newData, unit_id: value })}
              />
            </div>

            <div
              className='row unit_type'
              style={{ marginTop: '40px' }}
            >
              <span style={{ fontWeight: 'bold' }}>Total:</span>

              <span style={{ fontWeight: 'bold' }}> {getTotal()} </span>
            </div>
          </div>


        </div>
      </div>

      <div className="wrapper flex-container sb editing-buttons">
        <div className="buttons">
          <button
            className="_bordered _red"
            onClick={() => handleCancelEdit()}
          >
            Cancel
          </button>
          <button
            disabled={!readyToSave || checkSave()}
            className="_bordered _green"
            onClick={() => handleSave()}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  )
}
