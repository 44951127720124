import { LegacyRef, useEffect, useState } from 'react'

// @ts-ignore
import Sound from '../assets/bell.mp3'

import User from '../models/User'

import './Audio.sass'

export default function Audio({ $player, user, name }: {
  $player: LegacyRef<HTMLAudioElement> | undefined
  user: User
  name: string
}) {
  const [src, setSrc] = useState(Sound)

  useEffect(() => {
    if (!!name) {
      setSrc(name)
    } else {
      setSrc(Sound as string)
    }
  }, [name])

  return (
    <div
      className='audio-controll'
      style={{ display: 'none' }}
    >
      <audio
        ref={$player}
        controls
        className='bell-audio'
      >
        <source src={src} type="audio/mpeg" />
      </audio>
    </div>
  )
}
