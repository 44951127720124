import { useState } from 'react'
import classNames from 'classnames'

import Icon from '../../components/Icon'
import Camera from '../Jobs/Camera'

import { httpClientUpdate } from '../../funcs'
import { useAppSelector } from '../../store/hooks'

import './LineItems.sass'

interface AttachmentsAddProps {
  switchPage: (name: string) => void
  jobId: string
}

export default function AttachmentsAdd({ switchPage, jobId }: AttachmentsAddProps) {
  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [mode, setMode] = useState(false)
  const [uploadImage, setUploadImage] = useState<File | null>(null)
  const [uploadImageBase64, setUploadImageBase64] = useState('')
  const [saving, setSaving] = useState(false)

  async function handleSaveImages() {
    setSaving(true)
    try {
      // api/technician/jobs/8888821108z6i3wmr7/attachments
      const { data: { success } } = await httpClientUpdate.post(`/technician/jobs/${jobId}/attachments`, {
        account_id: activeAccountId,
        photo: uploadImageBase64,
      })
      if (success) {
        switchPage('main')
        setSaving(false)
      }
    } catch (error) { }
  }

  function handleAddImage(file: File) {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      setUploadImageBase64(reader.result as string)
    });

    reader.readAsDataURL(file);

    setUploadImage(file)
  }

  return (
    <div className="EstimatePage_Add entity-edit">
      <div className="wrapper flex-container sb editing">
        <div className='row'>
          <Icon style={{ width: '24px', height: '24px', fill: '#393939' }} viewBox="0 0 1024 1024" icon="unordered-lists" />

          <h1 style={{ whiteSpace: 'nowrap', marginTop: '0', color: '#393939' }}>Attachments</h1>
        </div>

        <div style={{ gap: '30px' }} className='row'>
          <button
            className="_zeroed _iconed _blue"
            onClick={() => switchPage('main')}
          >
            <Icon
              style={{ width: '24px', height: '24px', transform: 'rotate(180deg)' }}
              viewBox="0 0 24 24"
              icon="arrow-25"
            />
          </button>

          <div>
            <button
              className={classNames('button-save-disabled', {
                'button-save': !!uploadImageBase64 && !saving
              })}

              onClick={() => handleSaveImages()}
            >
              Save
            </button>
          </div>
        </div>
      </div>

      {
        mode &&
        <Camera
          setTakePic={setMode}
        />
      }

      {
        !mode &&
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginTop: '20px' }}>
          <div className='add-gallary-photo'>
            <label
              htmlFor="filePicker"
              className='add-image'
              title='Upload a image'
            >
              Add From Gallery
            </label>
            <input
              id="filePicker"
              style={{ visibility: "hidden" }}
              type="file"
              name="myImage"
              onChange={(event) => {
                event.target.files && handleAddImage(event.target.files[0])
              }}
            />
          </div>

          <button
            style={{ width: 'fit-content', border: '1px solid #ccc', }}
            onClick={() => setMode(true)}
          >
            Photo
          </button>
        </div>
      }

      {
        !mode && uploadImage &&
        <div className='photo-view'>
          <img
            style={{ maxHeight: '400px', objectFit: 'cover' }}
            alt="not found"
            src={URL.createObjectURL(uploadImage)}
          />
        </div>
      }
    </div>
  )
}
