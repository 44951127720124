import { useEffect, useState } from "react"
import { useRoute } from "react-router5"
import qs from 'qs'
import moment from 'moment'

import logoImg from "../../images/logo.svg"
import SquareComponent from "./SquareComponent"
import Doc from "./Document"
import StripeComponent from "./StripeComponent"
import DocumentText from "./DocumentText"

import { httpClientUpdate, nErrorUpdate } from '../../funcs'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

import "../../styles/pages/auth.sass"
import './Index.sass'

interface HttpReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: {
    link: LinkProps
  }
}

interface LinkProps {
  amount: string
  company_id: string
  company_name: string
  estimate_id: string
  invoice_id: string
  invoice_name: string
  job_id: string
  job_name: string
  link_id: string
  note: string
  service_resource_id: string
  square_fee_compensation: string
  square_payment_application_id: string
  square_payment_location_name: string
  square_payment_location_id: string
  stripe_fee_compensation: string
  stripe_pk: string
  fee_compensation: string
  status: string
  type: string
  use_for_link_payment: string
  payment_date_time: string
  payment_card_number: string

  deposit_amount: string
  name: string
  items: ItemsProps[]

  templates: {
    content: string
    type: string
  }[]
}

export interface ItemsProps {
  description: string
  estimate_id: string
  estimate_item_id: string
  name: string
  price: string
  quantity: number
  sub_type: string
  type: string
  units: {
    laravel_through_key: string
    unit_id: string
  }[]
}

export default function Index() {
  const $router = useRoute()

  const [error, setError] = useState('')
  const [report, setReport] = useState<LinkProps | null>(null)
  const [reportCome, setReportCome] = useState(false)
  const [loading, setLoading] = useState(false)

  const [isPaySuc, setIsPaySuc] = useState(false)
  const [openPayment, setOpenPayment] = useState(false)

  const [template, setTemplate] = useState({
    width: 590,
    height: 300,
    type: 'template',
    blocks: []
  })
  const [text, setText] = useState('')

  useEffect(() => {
    loadInfo()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (report && report.type === 'payment' && report.status === 'paid') {
      setIsPaySuc(true)
    }
  }, [report])


  async function loadInfo() {
    try {

      // links/by-code?code1=12388888&code2=123

      const response = await httpClientUpdate.get(`/links/by-code`,
        {
          params: {
            code1: $router.router.getState().params.linkId,
            code2: $router.router.getState().params.otherId,
          },
          headers: { 'Access-Control-Allow-Origin': '*' }
        }) as { data: HttpReport }

      if (response.data.success) {
        if (response.data.data.link) {
          setReport({
            ...response.data.data.link,
            fee_compensation: response.data.data.link.fee_compensation ?
              response.data.data.link.fee_compensation :
              response.data.data.link.use_for_link_payment === 'stripe' ?
                response.data.data.link.stripe_fee_compensation ?
                  response.data.data.link.stripe_fee_compensation :
                  '0.00' :
                response.data.data.link.square_fee_compensation ?
                  response.data.data.link.square_fee_compensation : '0.00'
          })

          if (response.data.data.link.templates) {
            response.data.data.link.templates.forEach(item => {
              if (item.type === 'template') {
                let content = JSON.parse(item.content)
                if (content.width) {
                  setTemplate({
                    width: content.width,
                    height: content.height,
                    type: 'template',
                    blocks: content.content
                  })
                } else {
                  setTemplate({
                    ...template,
                    blocks: content
                  })
                }
              } else {
                let content = JSON.parse(item.content)

                setText(content)
              }
            })


          }
        }
        setReportCome(true)
      }
    } catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      if (createdError.content.code === 404) {
        setError('Document not found!')
      } else if (createdError.content.code === 400) {
        setError('Document has expired!')
      }
    }
  }

  function getAccountId() {
    return $router.router.getState().params.linkId.slice($router.router.getState().params.linkId.length - 5)
  }

  async function handlePaySquare(token: string) {
    try {
      if (report) {
        let items = []
        if (report.invoice_id) {
          items.push({
            service_resource_id: report.service_resource_id,
            note: report.note,
            total_item: report.amount,
            invoice_id: report.invoice_id,
            job_id: report.job_id
          })
        } else {
          items.push({
            service_resource_id: report.service_resource_id,
            note: report.note,
            total_item: report.amount,
            job_id: report.job_id
          })
        }
        // https://2022back4.artemiudintsev.com/api/external/payments/pay
        //@ts-ignore
        const { data: { success } } = await httpClientUpdate.post(`/external/payments/pay`, qs.stringify({
          account_id: getAccountId(),
          token: token,
          link_id: report.link_id,
          service_resource_id: report.service_resource_id,
          type: report.use_for_link_payment,
          company_id: report.company_id,
          fee_compensation: getFee(),
          location_name: report.square_payment_location_name,
          location_id: report.square_payment_location_id,
          items: items,
        }, { skipNulls: true })) as { data: any }

        if (success) {
          setIsPaySuc(true)
        }
      }
    } catch (error) { }
  }

  async function handleSaveStripePay(id: string) {
    try {
      if (report) {
        let items = []
        if (report.invoice_id) {
          items.push({
            service_resource_id: report.service_resource_id,
            note: report.note,
            total_item: report.amount,
            invoice_id: report.invoice_id,
            job_id: report.job_id
          })
        } else {
          items.push({
            service_resource_id: report.service_resource_id,
            note: report.note,
            total_item: report.amount,
            job_id: report.job_id
          })
        }
        // https://2022back4.artemiudintsev.com/api/external/payments/pay
        //@ts-ignore
        const { data: { success } } = await httpClientUpdate.post(`/external/payments/pay`, qs.stringify({
          account_id: getAccountId(),
          token: id,
          link_id: report.link_id,
          service_resource_id: report.service_resource_id,
          type: report.use_for_link_payment,
          company_id: report.company_id,
          fee_compensation: getFee(),
          items: items,
        }, { skipNulls: true })) as { data: any }

        if (success) {
          setIsPaySuc(true)
        }
      }
    } catch (error) { }
  }

  function getFee() {

    let sum = 0
    let compensation = 0
    let total = 0

    if (report) {
      sum = Number(report.amount)
      compensation = Number(report.fee_compensation)

      if (sum > 0) {
        total = ((sum / 100) * compensation)
      }
    }

    return total
  }

  function getSumWithFee() {
    let sum = 0
    let compensation = 0
    let total = 0

    if (report) {
      if (report.status !== 'paid') {
        sum = Number(report.amount)
        total = Number(report.fee_compensation)
      } else {
        sum = Number(report.amount)
        compensation = Number(report.fee_compensation)

        if (sum > 0) {
          total = ((sum / 100) * compensation)
        }
      }
    }

    return sum + total
  }

  return (
    <div className="AuthPage link-page-auth">
      <div className="ProjectHeaders">
        <img src={logoImg} alt="" />

        <div className="headers">
          <h1> {process.env.REACT_APP_TITLE} </h1>
          <h2>Manage your business!</h2>
        </div>
      </div>

      <div className="auth-wrapper">
        {
          error &&
          <div
            style={{
              background: '#f6f7fb',
              borderRadius: '20px',
              boxShadow: '.6rem .4rem .6rem #00000047',
              margin: 'auto',
              maxWidth: '400px',
              padding: '30px 50px',
              textAlign: 'center',
              width: '100%',
            }}
          >
            {error}
          </div>
        }

        {
          report && isPaySuc &&
          <div className="link-page-content">
            <div
              className="link-page-row center bb"
              onClick={() => setOpenPayment(true)}
            >
              {
                report.status?.toLowerCase() === 'paid' ?
                  <span className="title"> Payment Info  </span> :
                  <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
                    <span style={{ color: 'green', cursor: 'pointer' }} className="title"> The payment was successful.</span>
                    <div
                      style={{ textDecoration: 'underline', cursor: 'pointer', fontWeight: 'bold', marginTop: '10px' }}
                      onClick={(event) => {
                        event.stopPropagation()
                        window.location.reload();
                      }}
                    >
                      Payment Info
                    </div>
                  </div>
              }
            </div>

            {
              (openPayment || report.status?.toLowerCase() === 'paid') &&
              <div className="link-page-row">
                <span>{report.invoice_name ? report.invoice_name : report.job_name}  </span>

                <span>${report.amount}</span>
              </div>
            }

            {
              (openPayment || report.status?.toLowerCase() === 'paid') &&
              <div className="link-page-row">
                <span className="note">Note: {report.note}</span>
              </div>
            }

            {
              (openPayment || report.status?.toLowerCase() === 'paid') &&
              <div className="link-page-row right mt">
                <span>Payment:</span>

                <span> ${report.amount} </span>
              </div>
            }

            {
              (openPayment || report.status?.toLowerCase() === 'paid') &&
              !!Number(report.fee_compensation) &&
              <div className="link-page-row right">
                <span>Processing Fee ({report.fee_compensation}%):</span>

                <span>${getFee().toFixed(2)}</span>
              </div>
            }

            {
              (openPayment || report.status?.toLowerCase() === 'paid') &&
              <div className="link-page-row right">
                <span className="bold">Pay by Card (+Fee):</span>

                <span className="bold">${getSumWithFee().toFixed(2)}</span>
              </div>
            }

            {
              (openPayment || report.status?.toLowerCase() === 'paid') && report.status?.toLowerCase() === 'paid' &&
              <div className="link-page-row mt">
                <span style={{ fontSize: '12px' }} className="note">
                  Paid by card {report.payment_card_number}, {moment(report.payment_date_time).format('DD MMMM YYYY hh:mma')}
                </span>
              </div>
            }
          </div>
        }

        {
          report && !isPaySuc && report.type?.toLowerCase() === 'payment' &&
          <div className="link-page-content">
            <div className="link-page-row center bb">
              <span className="title"> {report.company_name} </span>
            </div>

            <div className="link-page-row">
              <span>{report.invoice_name ? report.invoice_name : report.job_name}  </span>

              <span>${report.amount}</span>
            </div>

            <div className="link-page-row">
              <span className="note">Note: {report.note}</span>
            </div>

            <div className="link-page-row right mt">
              <span>Payment:</span>

              <span> ${report.amount} </span>
            </div>

            {
              !!Number(report.fee_compensation) &&
              <div className="link-page-row right">
                <span>Processing Fee:</span>

                <span>${report.fee_compensation}</span>
              </div>
            }

            <div className="link-page-row right">
              <span className="bold">Pay by Card (+Fee):</span>

              <span className="bold">${getSumWithFee().toFixed(2)}</span>
            </div>

            {
              (openPayment || report.status?.toLowerCase() === 'paid') && report.status?.toLowerCase() === 'paid' &&
              <div className="link-page-row mt">
                <span style={{ fontSize: '12px' }} className="note">
                  Paid by card {report.payment_card_number}, {moment(report.payment_date_time).format('DD MMMM YYYY hh:mma')}
                </span>
              </div>
            }

            {
              report.use_for_link_payment === 'square' && report.status !== 'paid' &&
              <SquareComponent
                handlePaySquare={handlePaySquare}
                square_payment_application_id={report.square_payment_application_id}
                square_payment_location_id={report.square_payment_location_name}
              />
            }

            {
              report.use_for_link_payment === 'stripe' && report.status !== 'paid' &&
              <StripeComponent
                tokenStripe={report.stripe_pk}
                handleSaveStripePay={handleSaveStripePay}
                loading={loading}
                setLoading={setLoading}
              />
            }
          </div>
        }

        {
          !error && !isPaySuc && reportCome && report && report.type?.toLowerCase() === 'invoice' &&
          <div className="link-page-content">
            <div className="link-page-row center bb">
              <span className="title">Invoice {report?.name}</span>
            </div>

            {
              report &&
              <Doc
                deposit_amount={report.deposit_amount}
                items={report.items}
                name="Invoice"
                template={template}
                account_id={getAccountId()}
                link_id={report.link_id}
                job_id={report.job_id}
                service_resource_id={report.service_resource_id}
                estimate_id={report.estimate_id}
                invoice_id={report.invoice_id}
              />
            }
          </div>
        }

        {
          !error && !isPaySuc && reportCome && report && report.type?.toLowerCase() === 'estimate' &&
          <div className="link-page-content">
            <div className="link-page-row center bb">
              <span className="title">Estimate {report?.name}</span>
            </div>

            {
              report &&
              <Doc
                deposit_amount={report.deposit_amount}
                items={report.items}
                name="Estimate"
                template={template}
                account_id={getAccountId()}
                link_id={report.link_id}
                job_id={report.job_id}
                service_resource_id={report.service_resource_id}
                estimate_id={report.estimate_id}
                invoice_id={report.invoice_id}
              />
            }
          </div>
        }

        {
          !error && !isPaySuc && reportCome && report && report.type?.toLowerCase() === 'approve' &&
          <div className="link-page-content">
            <div className="link-page-row center bb">
              <span className="title">Approve {report?.name}</span>
            </div>

            {
              report &&
              <DocumentText
                account_id={getAccountId()}
                text={text}
                link_id={report.link_id}
              />
            }
          </div>
        }
      </div>
    </div>
  )
}
