import classNames from 'classnames'

import { NavActive } from '../../store/reducer'

interface LinkModalProps {
  navActive: NavActive
  phoneCall: boolean
  setModal: (value: null) => void
  handleSave: () => void
  status: string
}

export default function LinkModal({
  navActive,
  phoneCall,
  setModal,
  handleSave,
  status,
}: LinkModalProps) {
  return (
    <div
      className={classNames('link-page payment-page job-tech-customer time-tracking mobile',
        {
          __respectAsidePanel: navActive.is && !phoneCall,
          __phoneCall: phoneCall && !navActive.is,
          __bothOpen: navActive.is && phoneCall,
          __nonePanel: !navActive.is && !phoneCall
        }
      )}
      style={{ zIndex: '1', margin: '0' }}
    >
      <div className='link-menu'>
        <div style={{ background: '#1b2f5f' }} className="title">
          {status.toLocaleLowerCase() === 'canceled' ? 'Restore?' : 'Cancel?'}
        </div>

        <div className="buttons">
          <button
            className="_bordered _red"
            onClick={() => setModal(null)}
          >
            No
          </button>

          <button
            className="_bordered _green"
            onClick={() => handleSave()}
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  )
}
