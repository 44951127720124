import {
  useStripe,
  useElements,
  CardElement
} from '@stripe/react-stripe-js';

interface CheckoutFormProps {
  setApiToken: (value: string) => void
  cancel: () => void
  disabled: boolean
  setLoading: (value: boolean) => void
  disabledCancel: boolean
  checkServiceEmpty: boolean
  setErrorService: (value: boolean) => void
  errorService: boolean
  setErrorPaymentLimit: (value: boolean) => void
  checkPaymentLimit: boolean
  error_amount: boolean
  setErrorPaymentAmount: (value: boolean) => void
}

const CheckoutForm = ({
  setApiToken,
  cancel,
  disabled,
  setLoading,
  disabledCancel,
  checkServiceEmpty,
  setErrorService,
  errorService,
  setErrorPaymentLimit,
  checkPaymentLimit,
  error_amount,
  setErrorPaymentAmount,
}: CheckoutFormProps) => {
  const stripe = useStripe();
  const elements = useElements();


  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setLoading(true)

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (checkServiceEmpty) {
      setErrorService(true)
      setLoading(false)

      return
    }
    // const cardNumberElement = elements.getElement(CardNumberElement)
    const cardNumberElement = elements.getElement(CardElement)

    try {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { token, error } = await stripe.createToken(cardNumberElement)

      if (token) {
        setLoading(false)
        setApiToken(token.id)
      } else {
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  };

  const style = {
    base: {
      color: 'rgba(43, 43, 43, .7)',
      fontSize: '16px',
    }
  }

  function hamdleCancel(event: any) {
    event.preventDefault();
    cancel()
  }

  function handleLimit(event: any) {
    event.preventDefault();
    setErrorPaymentLimit(true)
  }

  function handleAmount(event: any) {
    event.preventDefault();
    setErrorPaymentAmount(true)
  }

  return (
    <form
      className='stripe-form'
    >
      <div className='stripe-row'>
        <CardElement options={{ style }} />
      </div>

      <div className="wrapper flex-container sb editing-buttons">
        <div />
        <div className="buttons">
          <button
            className="_bordered _red"
            onClick={hamdleCancel}
            disabled={disabledCancel}
          >
            Cancel
          </button>

          <button
            className="_bordered _green"
            style={{ whiteSpace: 'nowrap' }}
            disabled={disabled || (errorService && checkServiceEmpty)}
            onClick={(event) => {
              checkPaymentLimit ?
                handleLimit(event) :
                error_amount ?
                  handleAmount(event) :
                  handleSubmit(event)
            }}
          >
            Pay
          </button>
        </div>
      </div>
    </form>
  )
};

export default CheckoutForm;