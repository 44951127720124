import './InputTextWithTitle.sass'

interface InputNumberWithTitleProps {
  value: number
  setValue: (value: number) => void
  label: string
  width: number
}

export default function InputNumberWithTitle({ value, setValue, label, width }: InputNumberWithTitleProps) {
  function handleFocus(event: any) {
    const target = event.currentTarget;

    target.type = 'text';
    target.setSelectionRange(target.value.length, target.value.length);
    target.type = 'number';
    target && target.focus()
  }

  function handleChangeItem(event: any) {
    let price = event.target.value

    let numberWithoutDot = price.split('.')

    if (Number(price) >= 0) {
      if (numberWithoutDot[1] && numberWithoutDot[1].toString().length > 2) {
        let newNumber = `${numberWithoutDot[0]}${numberWithoutDot[1].toString()[0]}.${numberWithoutDot[1].toString()[1]}${price[price.length - 1]}`
        setValue(Number(newNumber))
      } else {
        let newNumber = price

        setValue(Number(newNumber))
      }
    }
  }

  function handleChangeItemBackspace(event: any) {
    if (event.keyCode === 8) {
      event.preventDefault()
      let price = event.target.value
      let numberWithoutDot = `${price}`.split('.')
      if (numberWithoutDot[0].length > 1) {
        let newNumber = `${numberWithoutDot[0].slice(0, -1)}.${numberWithoutDot[0][numberWithoutDot[0].length - 1]}${numberWithoutDot[1][0]}`
        setValue(Number(newNumber))
      } else {
        let newNumber = `0.${numberWithoutDot[0][0]}${numberWithoutDot[1][0]}`
        setValue(Number(newNumber))
      }
    }
  }

  return (
    <div
      className='input-text-title'
      style={{ width: `${width}px`, minWidth: `${width}px` }}
    >
      <span className="label">{label}</span>

      <input
        className="appearance"
        type='number'
        value={`${Number(value).toFixed(2)}`}
        step={0.01}
        onFocus={(event) => handleFocus(event)}
        onChange={(event) => handleChangeItem(event)}
        onKeyDown={(event) => handleChangeItemBackspace(event)}
      />
    </div>
  )
}
