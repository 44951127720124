import { useEffect, useState, useCallback, useRef } from "react";
import { useRoute } from "react-router5"
import moment, { Moment } from "moment";
import classNames from "classnames";
import ReactInputMask from "react-input-mask"
import ReactCrop, { Crop } from "react-image-crop"
import DateTimeComponent from "react-datetime"

import Icon from "../../components/Icon"
import Select from "../../components/Select"
import Checkbox from "../../components/Checkbox"
import Switcher from "../../components/Switcher"
import AudioPlayer from "../Profile/AudioPlayer";
import AudioPlayerModal from "../../components/AudioPlayer";

import {
  dateToInfoBlock,
  formatPhoneNumberToServerString,
  httpClientUpdate,
  isValidURL,
  nErrorUpdate,
  validateEmail
} from "../../funcs"
import { setAccounts } from "../../store/reducer";
import { useAppDispatch, useAppSelector } from "../../store/hooks"

import AccountCustom, { NotificationsSoundsProps } from "../../models/AccountCustom"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";


import "../../styles/pages/common/entity-edit.sass"

const weekdayOptions = [
  { name: '', id: 0 },
  { name: 'Monday', id: 1 },
  { name: 'Tuesday', id: 2 },
  { name: 'Wednesday', id: 3 },
  { name: 'Thursday', id: 4 },
  { name: 'Friday', id: 5 },
  { name: 'Saturday', id: 6 },
  { name: 'Sunday', id: 7 },
]

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: AccountData
}

interface AccountData {

  permissions: {
    account_add: boolean
    account_delete: boolean
    account_edit: boolean
    account_report_show: boolean
    account_show: boolean

    twilio_info_edit: boolean
    twilio_info_show: boolean

    payment_stripe_settings_show: boolean
    payment_stripe_settings_edit: boolean
    payment_square_settings_show: boolean
    payment_square_settings_edit: boolean

    profile_edit_notifications_sound: boolean
    profile_show_notifications_sound: boolean
  },

  only_account_permissions: {
    notifications_dispatcherpayout: boolean
    notifications_sms: boolean
    notifications_staffpayout: boolean
    notifications_websiterequest: boolean
    notifications_unsent_appointments: boolean
    notifications_unsent_appointments_all: boolean
  }

  account: AccountCustom[],

  edit: {
    status: {
      id: number
      name: string
    }[],
    owners: {
      user_id: string,
      user: string
    }[],
    source: {
      list_sources_id: string
      name: string
    }[],
    time_zone: {
      id: number
      name: string
    }[],
    permissions: {
      accounts_permissions_id: string
      name: string
    }[],
    state: {
      id: number
      name: string
    }[],
    accounts_menus: {
      accounts_menus_id: string
      name: string
    }[]
  }
}

function generateDownload(canvas: {
  toDataURL(arg0: string, arg1: number): any; toBlob: (arg0: (blob: Blob | MediaSource) => void, arg1: string, arg2: number) => void;
}, crop: any) {
  try {
    if (!crop || !canvas) {
      return;
    }

    canvas.toBlob(
      (blob: Blob | MediaSource) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let file = new File([blob as Blob], "fileName.jpg", { type: "image/jpeg" })
      },
      'image/png',
      1
    );
    return canvas.toDataURL("image/jpeg", 0.95)
  } catch (err) {

  }
}

function setCanvasImage(image: { naturalWidth: number; width: number; naturalHeight: number; height: number; }, canvas: { getContext: (arg0: string) => any; width: number; height: number; }, crop: { width: number; height: number; x: number; y: number; }) {
  if (!crop || !canvas || !image) {
    return;
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const ctx = canvas.getContext('2d');
  const pixelRatio = window.devicePixelRatio;

  canvas.width = crop.width * pixelRatio * scaleX;
  canvas.height = crop.height * pixelRatio * scaleY;

  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = 'high';

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * scaleX,
    crop.height * scaleY
  );
}

async function imageUrlToBase64(url: string) {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
    reader.onerror = reject;
  });
}

function AccountsPage_Item() {
  const $router = useRoute()

  const dispatch = useAppDispatch()

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);

  const [isSaving, setIsSaving] = useState(false)

  const [upImg, setUpImg] = useState<string>('');

  const [crop, setCrop] = useState<Partial<Crop>>({ unit: '%', width: 100, aspect: 1 });
  const [completedCrop, setCompletedCrop] = useState<Crop | null>(null);

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  let timeZone = user?.time_zone;

  const [readyToSave, setReadyToSave] = useState(false)

  const [updatedSounds, setUpdatedSounds] = useState<{ name: string, data: string, id: null | string }>({ name: '', data: '', id: null })
  const [dragingSound, setDragingSound] = useState(false)
  const [playing, setPlaying] = useState('')

  const [editing, setEditing] = useState(false)

  const [inputTypes, setInputTypes] = useState({
    twilio_account_sid: 'password' as 'text' | 'password',
    twilio_call_token: 'password' as 'text' | 'password',
    twilio_token: 'password' as 'text' | 'password',
    twilio_app_sid: 'password' as 'text' | 'password',
    measurement_id: 'password' as 'text' | 'password',
    api_secret: 'password' as 'text' | 'password',
    api_token: 'password' as 'text' | 'password',
    stripe_sk: 'password' as 'text' | 'password',
    stripe_pk: 'password' as 'text' | 'password',
    openai_api_key: 'password' as 'text' | 'password',
    square_payment_application_id: 'password' as 'text' | 'password',
    square_payment_access_token: 'password' as 'text' | 'password',
  })

  const [deleting, setDeleting] = useState(false)
  const [readyToDelete, setReadyToDelete] = useState(false)
  const [errors, setErrors] = useState<string[]>([])

  const [accountData, setAccountData] = useState<AccountData | null>(null)

  const [avatarPopup, setAvatarPopup] = useState(false)

  const [editions, setEditions] = useState<{
    time_zone: {
      id: number
      name: string
    }[],
    source: {
      list_sources_id: string
      name: string
    }[],
    status: {
      id: number
      name: string
    }[],
    owners: {
      user: string,
      user_id: string
    }[],
    permissions: {
      accounts_permissions_id: string
      name: string
    }[],
    state: {
      id: number
      name: string
    }[],
    accounts_menus: {
      accounts_menus_id: string
      name: string
    }[]
  } | null>(null)

  const [newAccountData, setNewAccountData] = useState<Partial<AccountCustom>>({

  })
  const [draging, setDraging] = useState(false)

  const [updatedImage, setUpdatedImage] = useState(false)
  const [ownerData, setOwnerData] = useState({
    first_name: '',
    last_name: '',
    phone: '+1',
    email: ''
  })
  const [saveNewImage, setSaveNewImage] = useState(false)

  async function handleCancelEdit() {
    let billing_state = accountData && accountData.account[0].billing_state ? accountData.edit.state.filter(item => item.name === accountData.account[0].billing_state)[0].id : ''
    let mailing_state = accountData && accountData.account[0].mailing_state ? accountData.edit.state.filter(item => item.name === accountData.account[0].mailing_state)[0].id : ''
    let permission = accountData && accountData.account[0].permission ? accountData.edit.permissions.filter(item => item.name === accountData.account[0].permission)[0].accounts_permissions_id : ''
    let use_for_link_payment = accountData && accountData.account[0].use_for_link_payment ? accountData.account[0].use_for_link_payment : 'stripe'

    accountData && setNewAccountData({
      ...accountData.account[0],
      billing_state,
      mailing_state,
      permission,
      use_for_link_payment,
    })
    if (accountData && accountData.account[0].avatar_url) {
      const imageBase64 = await imageUrlToBase64(accountData.account[0].avatar_url)

      setUpImg(imageBase64 as string)
    }
    setCrop({ unit: '%', width: 100, aspect: 1 })
    setEditing(false)
  }

  // remove document
  async function handleRemoveItem() {
    try {
      setIsSaving(true)
      const response = await httpClientUpdate.delete(`/accounts/${$router.router.getState().params.accountId}`, {
        data: {
          account_id: activeAccountId,
        }
      })

      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('accounts', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // update document
  async function handleSave() {
    try {
      setIsSaving(true)
      const {
        name, status_id, phone,
        slack_active, slack_url, billing_street, billing_unit,
        billing_state, billing_city, billing_zip,
        mailing_city, mailing_street, mailing_unit,
        mailing_zip, time_zone_id, source_id, permission,
        mailing_state, same_address, owner_id, accounts_menus_id,
        measurement_id, api_secret, twilio_app_sid, twilio_account_sid,
        twilio_token, twilio_call_token, voice, avatar_url, api_token,
        stripe_pk, stripe_sk, payment_slack_channel, send_payment_to_slack,
        automation_payout_dispatchers, automation_payout_dispatchers_time,
        automation_payout_dispatchers_weekday, automation_payout_staff,
        automation_payout_staff_time, automation_payout_staff_weekday,
        call_time_out, is_update_google_local_service_stat,
        time_for_update_google_local_service_stat, is_send_square_payment_to_slack,
        square_payment_access_token, square_payment_application_id, square_payment_location_id,
        square_payment_location_name, square_payment_slack_channel,
        stripe_fee_compensation, square_fee_compensation,
        openai_api_key, payment_square_limit, payment_stripe_limit,
        payment_square_amount_confirmation, payment_stripe_amount_confirmation,
        use_for_link_payment,
      } = newAccountData

      const automationPayoutDispatchersTime = automation_payout_dispatchers_time ? typeof automation_payout_dispatchers_time === 'string' ? automation_payout_dispatchers_time : moment(automation_payout_dispatchers_time).format("HH:mm:ss") : ''
      const automationPayoutStaffTime = automation_payout_staff_time ? typeof automation_payout_staff_time === 'string' ? automation_payout_staff_time : moment(automation_payout_staff_time).format("HH:mm:ss") : ''
      const updateTime = time_for_update_google_local_service_stat ? typeof time_for_update_google_local_service_stat === 'string' ? time_for_update_google_local_service_stat : moment(time_for_update_google_local_service_stat).format("HH:mm:ss") : ''

      let logo: string | undefined = ''
      let updatedLogo: any = {}

      if (updatedImage) {
        if (upImg) {
          logo = previewCanvasRef.current ? generateDownload(previewCanvasRef.current, crop) : avatar_url
        } else {
          logo = ''
        }
      } else {
        if (avatar_url === '') {
          updatedLogo = { avatar_url: '' }
        }
      }

      // saveNewImage
      const response = await httpClientUpdate.put(`/accounts/${$router.router.getState().params.accountId}`, saveNewImage ? {
        account_id: activeAccountId,
        name,
        logo: logo,
        phone: phone ? formatPhoneNumberToServerString(phone) : '',
        status: status_id ? status_id : null,
        slack_url: slack_url ? slack_url : "",
        time_zone_id: time_zone_id ? time_zone_id : null,
        owner_id: owner_id ? owner_id : null,
        source_id: source_id ? source_id : null,
        accounts_permissions_id: permission ? permission : null,
        accounts_menus_id: accounts_menus_id ? accounts_menus_id : null,
        is_slack: slack_active ? 1 : 0,
        address_street: mailing_street ? mailing_street : ' ',
        address_state_id: mailing_state ? mailing_state : null,
        address_unit: mailing_unit ? mailing_unit : ' ',
        address_zip: mailing_zip ? mailing_zip : ' ',
        address_city: mailing_city ? mailing_city : ' ',
        billing_address_state_id: billing_state ? billing_state : null,
        billing_address_street: billing_street ? billing_street : ' ',
        billing_address_unit: billing_unit ? billing_unit : ' ',
        billing_address_city: billing_city ? billing_city : ' ',
        billing_address_zip: billing_zip ? billing_zip : ' ',
        is_same_address: same_address ? 1 : 0,
        measurement_id: measurement_id,
        api_secret: api_secret,
        twilio_app_sid: twilio_app_sid,
        twilio_account_sid: twilio_account_sid,
        twilio_token: twilio_token,
        twilio_call_token: twilio_call_token,
        voice: voice,
        api_token,
        stripe_pk,
        stripe_sk,
        payment_slack_channel,
        send_payment_to_slack: send_payment_to_slack ? 1 : 0,
        automation_payout_dispatchers: automation_payout_dispatchers ? true : false,
        automation_payout_staff: automation_payout_staff ? true : false,
        automation_payout_dispatchers_time: automationPayoutDispatchersTime,
        automation_payout_staff_time: automationPayoutStaffTime,
        automation_payout_dispatchers_weekday: automation_payout_dispatchers_weekday ? automation_payout_dispatchers_weekday : 0,
        automation_payout_staff_weekday: automation_payout_staff_weekday ? automation_payout_staff_weekday : 0,
        call_time_out,
        is_update_google_local_service_stat: is_update_google_local_service_stat ? 1 : 0,
        time_for_update_google_local_service_stat: updateTime,
        is_send_square_payment_to_slack: is_send_square_payment_to_slack ? 1 : 0,
        square_payment_access_token,
        square_payment_application_id,
        square_payment_location_id,
        square_payment_location_name,
        square_payment_slack_channel,
        stripe_fee_compensation,
        square_fee_compensation,
        openai_api_key,
        payment_square_limit,
        payment_stripe_limit,
        payment_square_amount_confirmation,
        payment_stripe_amount_confirmation,
        use_for_link_payment,
      } :
        {
          account_id: activeAccountId,
          name,
          ...updatedLogo,
          phone: phone ? formatPhoneNumberToServerString(phone) : '',
          status: status_id ? status_id : null,
          slack_url: slack_url ? slack_url : "",
          time_zone_id: time_zone_id ? time_zone_id : null,
          owner_id: owner_id ? owner_id : null,
          source_id: source_id ? source_id : null,
          accounts_permissions_id: permission ? permission : null,
          accounts_menus_id: accounts_menus_id ? accounts_menus_id : null,
          is_slack: slack_active ? 1 : 0,
          address_street: mailing_street ? mailing_street : ' ',
          address_state_id: mailing_state ? mailing_state : null,
          address_unit: mailing_unit ? mailing_unit : ' ',
          address_zip: mailing_zip ? mailing_zip : ' ',
          address_city: mailing_city ? mailing_city : ' ',
          billing_address_state_id: billing_state ? billing_state : null,
          billing_address_street: billing_street ? billing_street : ' ',
          billing_address_unit: billing_unit ? billing_unit : ' ',
          billing_address_city: billing_city ? billing_city : ' ',
          billing_address_zip: billing_zip ? billing_zip : ' ',
          is_same_address: same_address ? 1 : 0,
          measurement_id: measurement_id,
          api_secret: api_secret,
          twilio_app_sid: twilio_app_sid,
          twilio_account_sid: twilio_account_sid,
          twilio_token: twilio_token,
          twilio_call_token: twilio_call_token,
          voice: voice,
          api_token,
          stripe_pk,
          stripe_sk,
          payment_slack_channel,
          send_payment_to_slack: send_payment_to_slack ? 1 : 0,
          automation_payout_dispatchers: automation_payout_dispatchers ? true : false,
          automation_payout_staff: automation_payout_staff ? true : false,
          automation_payout_dispatchers_time: automationPayoutDispatchersTime,
          automation_payout_staff_time: automationPayoutStaffTime,
          automation_payout_dispatchers_weekday: automation_payout_dispatchers_weekday ? automation_payout_dispatchers_weekday : 0,
          automation_payout_staff_weekday: automation_payout_staff_weekday ? automation_payout_staff_weekday : 0,
          call_time_out,
          is_update_google_local_service_stat: is_update_google_local_service_stat ? 1 : 0,
          time_for_update_google_local_service_stat: updateTime,
          is_send_square_payment_to_slack: is_send_square_payment_to_slack ? 1 : 0,
          square_payment_access_token,
          square_payment_application_id,
          square_payment_location_id,
          square_payment_location_name,
          square_payment_slack_channel,
          stripe_fee_compensation,
          square_fee_compensation,
          openai_api_key,
          payment_square_limit,
          payment_stripe_limit,
          payment_square_amount_confirmation,
          payment_stripe_amount_confirmation,
          use_for_link_payment,
        })
      if (response.data.success) {
        setIsSaving(false)
        setEditing(false)
        loadInfo()

        let { data: { data: { accounts }, success: successAccounts, error: errorAccounts } } = await httpClientUpdate.get('/accounts')

        if (!successAccounts) {
          $router.router.navigate(`${errorAccounts.code}`, {
            reload: true
          })
        }

        dispatch(
          setAccounts(accounts)
        )
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      const { data: { data: accountData, success, error } } = await httpClientUpdate.get('/accounts/' + $router.router.getState().params.accountId, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpClientUpdateReport }
      if (success) {
        if (accountData.permissions.account_show) {
          let billing_state = accountData.account[0].billing_state ? accountData.edit.state.filter(item => item.name === accountData.account[0].billing_state)[0]?.id : ''
          let mailing_state = accountData.account[0].mailing_state ? accountData.edit.state.filter(item => item.name === accountData.account[0].mailing_state)[0]?.id : ''
          let permission = accountData.account[0].permission ? accountData.edit.permissions.filter(item => item.name === accountData.account[0].permission)[0]?.accounts_permissions_id : ''
          let use_for_link_payment = accountData && accountData.account[0].use_for_link_payment ? accountData.account[0].use_for_link_payment : 'stripe'

          setAccountData(accountData)
          setEditions(accountData.edit)
          setNewAccountData({
            ...accountData.account[0],
            billing_state,
            mailing_state,
            permission,
            use_for_link_payment,
          })
          setOwnerData({
            first_name: accountData.account[0].owner_first_name,
            last_name: accountData.account[0].owner_last_name,
            email: accountData.account[0].owner_email,
            phone: accountData.account[0].owner_phone
          })

          if (accountData.account[0].avatar_url) {
            const imageBase64 = await imageUrlToBase64(accountData.account[0].avatar_url)

            setUpImg(imageBase64 as string)
          }

        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function handleSaveSound(name: string) {
    try {
      setIsSaving(true)
      let audio_file = updatedSounds.data.replace('data:audio/mpeg;base64,', '')

      // https://2022back4.artemiudintsev.com/api/notification-sounds/accounts
      const { data: { success, error } } = await httpClientUpdate.post('/notification-sounds/accounts', {
        account_id: activeAccountId,
        audio_file: audio_file,
        type: name,
        use: 1,
      })
      if (success) {
        setUpdatedSounds({ name: '', data: '', id: null })
        loadInfo()
        setIsSaving(false)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }


  async function handleRemoveSound(id: string) {
    try {
      setIsSaving(true)
      // https://2022back4.artemiudintsev.com/api/notification-sounds/88888610dl13geijq8/accounts
      const response = await httpClientUpdate.delete(`/notification-sounds/${id}/accounts`, {
        data: {
          account_id: activeAccountId,
        }
      })
      if (response.data.success) {
        setUpdatedSounds({ data: '', name: '', id: null })
        setIsSaving(false)
        loadInfo()
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // Load account data
  useEffect(() => {
    loadInfo()

    // unmount page
    return () => {
      setAccountData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // On select file function
  function onSelectFile(e: any) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        setUpImg(reader.result as string)
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onSelectSoundFile(e: any) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setUpdatedSounds({ ...updatedSounds, data: reader.result as string, id: null })
      }
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const onDragOver = useCallback((e) => {
    setDraging(true);

    e.preventDefault();
    e.stopPropagation();
    return false;
  }, []);

  const onDragSoundOver = useCallback((e) => {
    setDragingSound(true)
    e.preventDefault();
    e.stopPropagation();
    return false;
  }, []);

  const onDragLeave = useCallback((e) => {
    setDraging(false);
    e.stopPropagation();
    e.preventDefault();
    return false;
  }, []);

  const onDragSoundLeave = useCallback((e) => {
    setDragingSound(false);
    e.stopPropagation();
    e.preventDefault();
    return false;
  }, []);

  const onDrop = useCallback((e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    const reader = new FileReader();
    reader.addEventListener('load', () =>
      setUpImg(reader.result as string)
    );
    reader.readAsDataURL(files[0]);
    setDraging(false);
    return false;
  }, []);

  const onDropSound = useCallback((e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    const reader = new FileReader();
    reader.addEventListener('load', () =>
      setUpdatedSounds({ ...updatedSounds, data: reader.result as string, id: null })
    );
    reader.readAsDataURL(files[0]);
    setDragingSound(false);
    return false;
  }, [updatedSounds]);

  useEffect(() => setReadyToSave(true), [
    newAccountData, upImg, saveNewImage
  ])

  function checkSave() {
    let errors = []
    if (newAccountData.slack_active) {
      if (newAccountData.slack_url === '') {
        errors.push('slack_url')
      } else {
        let isValid = newAccountData.slack_url && isValidURL(newAccountData.slack_url)
        !isValid && errors.push('slack_url')
      }
    }
    let emailValid = newAccountData.email && validateEmail(newAccountData.email)
    !emailValid && errors.push('email_invalid')
    return errors
  }

  function preSaveFunction() {
    if (checkSave().length) {
      setErrors(checkSave())
      setReadyToSave(false)
    } else {
      handleSave()
      setErrors([])
    }
  }

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (imgRef.current &&
      previewCanvasRef.current &&
      completedCrop) {

      setCanvasImage(imgRef.current, previewCanvasRef.current, completedCrop);
    }

  }, [completedCrop]);

  function handleSaveImage() {
    if (completedCrop) {
      setSaveNewImage(true)
    }
    setAvatarPopup(false)
  }

  function handleFocus(event: any) {
    const target = event.currentTarget;

    target.type = 'text';
    target.setSelectionRange(target.value.length, target.value.length);
    target.type = 'number';
    target && target.focus()
  }

  function handleChangeSquare(event: any) {
    let price = event.target.value

    let numberWithoutDot = price.split('.')

    if (numberWithoutDot[1].toString().length > 2) {
      let newNumber = `${numberWithoutDot[0]}${numberWithoutDot[1].toString()[0]}.${numberWithoutDot[1].toString()[1]}${price[price.length - 1]}`
      setNewAccountData({ ...newAccountData, square_fee_compensation: newNumber })
    } else {
      let newNumber = price
      setNewAccountData({ ...newAccountData, square_fee_compensation: newNumber })
    }
  }

  function handleChangeStripe(event: any) {
    let price = event.target.value

    let numberWithoutDot = price.split('.')

    if (numberWithoutDot[1].toString().length > 2) {
      let newNumber = `${numberWithoutDot[0]}${numberWithoutDot[1].toString()[0]}.${numberWithoutDot[1].toString()[1]}${price[price.length - 1]}`
      setNewAccountData({ ...newAccountData, stripe_fee_compensation: newNumber })
    } else {
      let newNumber = price
      setNewAccountData({ ...newAccountData, stripe_fee_compensation: newNumber })
    }
  }

  function handleChangeStripeBackspace(event: any) {
    if (event.keyCode === 8) {
      event.preventDefault()
      let price = event.target.value
      let numberWithoutDot = `${price}`.split('.')
      if (numberWithoutDot[0].length > 1) {
        let newNumber = `${numberWithoutDot[0].slice(0, -1)}.${numberWithoutDot[0][numberWithoutDot[0].length - 1]}${numberWithoutDot[1][0]}`
        setNewAccountData({ ...newAccountData, stripe_fee_compensation: newNumber })
      } else {
        let newNumber = `0.${numberWithoutDot[0][0]}${numberWithoutDot[1][0]}`
        setNewAccountData({ ...newAccountData, stripe_fee_compensation: newNumber })
      }
    }
  }

  function handleChangeSquareBackspace(event: any) {
    if (event.keyCode === 8) {
      event.preventDefault()
      let price = event.target.value
      let numberWithoutDot = `${price}`.split('.')
      if (numberWithoutDot[0].length > 1) {
        let newNumber = `${numberWithoutDot[0].slice(0, -1)}.${numberWithoutDot[0][numberWithoutDot[0].length - 1]}${numberWithoutDot[1][0]}`
        setNewAccountData({ ...newAccountData, square_fee_compensation: newNumber })
      } else {
        let newNumber = `0.${numberWithoutDot[0][0]}${numberWithoutDot[1][0]}`
        setNewAccountData({ ...newAccountData, square_fee_compensation: newNumber })
      }
    }
  }

  async function handleCancelUploadImage() {
    if (accountData && accountData.account[0].avatar_url) {
      const imageBase64 = await imageUrlToBase64(accountData.account[0].avatar_url)

      setUpImg(imageBase64 as string)
    }
    setCrop({ unit: '%', width: 100, aspect: 1 })
    setDraging(false)
    setUpdatedImage(false)
    setAvatarPopup(false)
  }

  function handleDeleteImage() {
    setUpImg('')
    setCrop({ unit: '%', width: 100, aspect: 1 })
    setDraging(false)
    setUpdatedImage(true)
    setAvatarPopup(false)
    setSaveNewImage(true)
  }

  function getNotificationSound(name: string) {
    let sound: NotificationsSoundsProps | null = {
      notifications_sound_id: '',
      type: '',
      url: '',
    }
    if (accountData && accountData.account[0].notifications_sounds) {
      if (name === 'websiterequest') {
        let arr = accountData.account[0].notifications_sounds.filter(item => item.type === 'websiterequest')

        if (arr.length) {
          sound = {
            notifications_sound_id: arr[0].notifications_sound_id,
            type: arr[0].type,
            url: arr[0].url,
          }
        }
      } else if (name === 'sms') {
        let arr = accountData.account[0].notifications_sounds.filter(item => item.type === 'sms')

        if (arr.length) {
          sound = {
            notifications_sound_id: arr[0].notifications_sound_id,
            type: arr[0].type,
            url: arr[0].url,
          }
        }
      } else if (name === 'dispatcherpayout') {
        let arr = accountData.account[0].notifications_sounds.filter(item => item.type === 'dispatcherpayout')

        if (arr.length) {
          sound = {
            notifications_sound_id: arr[0].notifications_sound_id,
            type: arr[0].type,
            url: arr[0].url,
          }
        }
      } else if (name === 'staffpayout') {
        let arr = accountData.account[0].notifications_sounds.filter(item => item.type === 'staffpayout')

        if (arr.length) {
          sound = {
            notifications_sound_id: arr[0].notifications_sound_id,
            type: arr[0].type,
            url: arr[0].url,
          }
        }
      } else if (name === 'unsentappointment') {
        let arr = accountData.account[0].notifications_sounds.filter(item => item.type === 'unsentappointment')

        if (arr.length) {
          sound = {
            notifications_sound_id: arr[0].notifications_sound_id,
            type: arr[0].type,
            url: arr[0].url,
          }
        }
      }
    }

    return sound
  }

  // Render function
  return (<>
    {accountData && editions && newAccountData ? (
      <div className="AccountsPage_Item entity-edit">

        <div className="wrapper flex-container sb">
          <div className="flex-container _gap-narrow">
            <h1>Account:</h1>
            <div className="item-name">
              {accountData.account[0].name}
            </div>
          </div>

          <div style={{ display: 'flex' }} >
            <button
              disabled={editing}
              style={{ background: '#d0d3da', color: 'white', marginRight: '10px' }}
              className="_wa"
              onClick={() =>
                $router.router.navigate($router.router.getState().params.fromPage ? $router.router.getState().params.fromPage : 'accounts',
                  {
                    companyId: activeAccountId,
                    [$router.router.getState().params.nameId]: $router.router.getState().params.id,
                    localInterface: $router.router.getState().params.localInterface
                  }, { reload: true })}
            >
              <Icon
                style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                viewBox="0 0 24 24"
                icon="arrow-25"
              />
            </button>

            {
              !editing &&
                accountData?.permissions.account_edit ? (
                <button className="_wa _green" onClick={() => setEditing(true)}>
                  Edit
                </button>
              ) : null
            }
          </div>
        </div>

        <div className="fieldset">

          <div className="legend">Owner</div>

          <div className="fields">

            <div className="__left">
              <div className="field">
                <span>First Name:</span>

                <input
                  type="text"
                  disabled={true}
                  defaultValue={ownerData.first_name}
                  onChange={({ target: { value } }) => setOwnerData({ ...ownerData, first_name: value })}
                />
              </div>

              <div className="field">
                <span>Last Name:</span>

                <input
                  type="text"
                  disabled={true}
                  defaultValue={ownerData.last_name}
                  onChange={({ target: { value } }) => setOwnerData({ ...ownerData, last_name: value })}
                />
              </div>
            </div>


            <div className="__right">

              <div className="field">
                <span>Phone:</span>

                <ReactInputMask
                  type="text"
                  disabled={true}
                  mask="+1 (999) 999-9999"
                  value={ownerData.phone}
                  onChange={({ target: { value } }) => setOwnerData({ ...ownerData, phone: value })}
                />
              </div>

              <div className="field">
                <span>Email:</span>

                <input
                  type="text"
                  disabled={true}
                  defaultValue={ownerData.email}
                  onChange={({ target: { value } }) => setOwnerData({ ...ownerData, email: value })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Main</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Name:</span>
                <input
                  type="text"
                  disabled={!editing}
                  defaultValue={newAccountData.name || ''}
                  onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, name: value })}
                />
              </div>

              <div className="field">
                <span>Phone:</span>
                <ReactInputMask
                  disabled={!editing}
                  type="text"
                  mask="+1 (999) 999-9999"
                  value={newAccountData.phone || ''}
                  onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, phone: value })}
                />
              </div>
            </div>

            <div className="__right">
              <div className="field" style={{ zIndex: 5 }}>
                <span>Status:</span>
                <Select
                  disabled={!editing}
                  options={editions.status.map((status) => ({
                    span: status.name,
                    value: status.id
                  }))}
                  selectedOption={typeof newAccountData.status_id === 'string' ? +newAccountData.status_id : newAccountData.status_id as number}
                  onChange={(value: string, span: string) => setNewAccountData({ ...newAccountData, status_id: value, status: span })}
                />
              </div>

              <div className="field">
                <span>Email:</span>
                <input
                  type="text"
                  value={newAccountData.email || ''}
                  disabled={!editing}
                  onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, email: value })}
                />
              </div>

              <div className="field">
                <span>Source:</span>
                <Select
                  disabled={!editing}
                  options={[{ name: '', list_sources_id: '' }].concat(editions.source).map((source) => ({
                    span: source.name,
                    value: source.list_sources_id
                  }))}
                  selectedOption={newAccountData.source_id as string}
                  onChange={(value: string, span: string) => setNewAccountData({ ...newAccountData, source_id: value, source: span })}
                />
              </div>
            </div>

            <div className="avatar-editing">
              <button
                className="_zeroed _iconed"
                style={{ cursor: editing ? 'pointer' : 'inherit' }}
                onClick={() => {
                  editing && setAvatarPopup(true)
                  editing && setUpdatedImage(true)
                }}
              >
                {
                  updatedImage ?
                    upImg ?
                      <canvas
                        ref={previewCanvasRef}
                        style={{
                          width: '90px',
                          height: '90px',
                        }}
                      /> :
                      <Icon
                        icon="house-1"
                        viewBox="0 0 576 512"
                        style={{ padding: '10px', border: '6px solid #000', borderRadius: '50%' }}
                      />
                    :
                    newAccountData.avatar_url ?
                      <img id='imageid' src={newAccountData.avatar_url} alt="Account avatar" /> :
                      <Icon
                        icon="house-1"
                        viewBox="0 0 576 512"
                        style={{ padding: '10px', border: '6px solid #000', borderRadius: '50%' }}
                      />
                }
              </button>
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Statistics</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Users:</span>
                <input
                  type="text"
                  disabled={true}
                  defaultValue={newAccountData.users || ''}
                  onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, users: value })} />
              </div>
            </div>

            <div className="__right">
              <div className="field">
                <span>Phone Numbers:</span>
                <input
                  type="text"
                  disabled={true}
                  defaultValue={newAccountData.phone_numbers || ''}
                  onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, phone_numbers: value })}
                />
              </div>
            </div>
          </div>
        </div>

        {
          editing ?
            accountData.permissions.twilio_info_edit &&
            <div className="fieldset">
              <div className="legend">Twilio</div>

              <div className="fields">
                <div className="__left">
                  <div className="field">
                    <span>Account SID:</span>
                    <div className="form-field _iconed">
                      <input
                        disabled={!editing}
                        type={inputTypes.twilio_account_sid}
                        // readOnly={inputTypes.twilio_account_sid !== 'text'}
                        defaultValue={newAccountData.twilio_account_sid || ''}
                        onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, twilio_account_sid: value })}
                      />
                      <button className="_zeroed _iconed" onClick={() => setInputTypes({ ...inputTypes, twilio_account_sid: inputTypes.twilio_account_sid === 'text' ? 'password' : 'text' })}>
                        {
                          inputTypes.twilio_account_sid === 'text' ?
                            <Icon icon="eye-4" /> :
                            <Icon
                              style={{ width: '18px', height: '18px', verticalAlign: 'middle', overflow: 'hidden' }}
                              viewBox="0 0 1024 1024"
                              icon="eye-block"
                            />
                        }
                      </button>
                    </div>
                  </div>

                  <div className="field">
                    <span>App SID:</span>
                    <div className="form-field _iconed">
                      <input
                        disabled={!editing}
                        type={inputTypes.twilio_app_sid}
                        // readOnly={inputTypes.twilio_app_sid !== 'text'}
                        defaultValue={newAccountData.twilio_app_sid || ''}
                        onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, twilio_app_sid: value })}
                      />
                      <button className="_zeroed _iconed" onClick={() => setInputTypes({ ...inputTypes, twilio_app_sid: inputTypes.twilio_app_sid === 'text' ? 'password' : 'text' })}>
                        {
                          inputTypes.twilio_app_sid === 'text' ?
                            <Icon icon="eye-4" /> :
                            <Icon
                              style={{ width: '18px', height: '18px', verticalAlign: 'middle', overflow: 'hidden' }}
                              viewBox="0 0 1024 1024"
                              icon="eye-block"
                            />
                        }
                      </button>
                    </div>
                  </div>

                  <div className="field">
                    <span>Voice:</span>

                    <input
                      type="text"
                      disabled={!editing}
                      defaultValue={newAccountData.voice}
                      onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, voice: value })}
                    />
                  </div>
                </div>


                <div className="__right">
                  <div className="field">
                    <span>Token:</span>
                    <div className="form-field _iconed">
                      <input
                        disabled={!editing}
                        type={inputTypes.twilio_token}
                        // readOnly={inputTypes.twilio_token !== 'text'}
                        defaultValue={newAccountData.twilio_token || ''}
                        onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, twilio_token: value })}
                      />
                      <button className="_zeroed _iconed" onClick={() => setInputTypes({ ...inputTypes, twilio_token: inputTypes.twilio_token === 'text' ? 'password' : 'text' })}>
                        {
                          inputTypes.twilio_token === 'text' ?
                            <Icon icon="eye-4" /> :
                            <Icon
                              style={{ width: '18px', height: '18px', verticalAlign: 'middle', overflow: 'hidden' }}
                              viewBox="0 0 1024 1024"
                              icon="eye-block"
                            />
                        }
                      </button>
                    </div>
                  </div>

                  <div className="field">
                    <span>Call Token:</span>
                    <div className="form-field _iconed">
                      <input
                        disabled={!editing}
                        type={inputTypes.twilio_call_token}
                        // readOnly={inputTypes.twilio_call_token !== 'text'}
                        defaultValue={newAccountData.twilio_call_token || ''}
                        onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, twilio_call_token: value })}
                      />
                      <button className="_zeroed _iconed" onClick={() => setInputTypes({ ...inputTypes, twilio_call_token: inputTypes.twilio_call_token === 'text' ? 'password' : 'text' })}>
                        {
                          inputTypes.twilio_call_token === 'text' ?
                            <Icon icon="eye-4" /> :
                            <Icon
                              style={{ width: '18px', height: '18px', verticalAlign: 'middle', overflow: 'hidden' }}
                              viewBox="0 0 1024 1024"
                              icon="eye-block"
                            />
                        }
                      </button>
                    </div>
                  </div>

                  <div className="field">
                    <span>Call Time Out:</span>

                    <input
                      type="number"
                      className="appearance"
                      disabled={!editing}
                      defaultValue={newAccountData.call_time_out || ''}
                      onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, call_time_out: +value })}
                    />
                  </div>
                </div>
              </div>
            </div> :
            accountData.permissions.twilio_info_show &&
            <div className="fieldset">
              <div className="legend">Twilio</div>

              <div className="fields">
                <div className="__left">
                  <div className="field">
                    <span>Account SID:</span>
                    <div className="form-field _iconed">
                      <input
                        disabled={!editing}
                        type={inputTypes.twilio_account_sid}
                        // readOnly={inputTypes.twilio_account_sid !== 'text'}
                        defaultValue={newAccountData.twilio_account_sid || ''}
                        onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, twilio_account_sid: value })}
                      />
                      <button className="_zeroed _iconed" onClick={() => setInputTypes({ ...inputTypes, twilio_account_sid: inputTypes.twilio_account_sid === 'text' ? 'password' : 'text' })}>
                        {
                          inputTypes.twilio_account_sid === 'text' ?
                            <Icon icon="eye-4" /> :
                            <Icon
                              style={{ width: '18px', height: '18px', verticalAlign: 'middle', overflow: 'hidden' }}
                              viewBox="0 0 1024 1024"
                              icon="eye-block"
                            />
                        }
                      </button>
                    </div>
                  </div>

                  <div className="field">
                    <span>App SID:</span>
                    <div className="form-field _iconed">
                      <input
                        disabled={!editing}
                        type={inputTypes.twilio_app_sid}
                        // readOnly={inputTypes.twilio_app_sid !== 'text'}
                        defaultValue={newAccountData.twilio_app_sid || ''}
                        onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, twilio_app_sid: value })}
                      />
                      <button className="_zeroed _iconed" onClick={() => setInputTypes({ ...inputTypes, twilio_app_sid: inputTypes.twilio_app_sid === 'text' ? 'password' : 'text' })}>
                        {
                          inputTypes.twilio_app_sid === 'text' ?
                            <Icon icon="eye-4" /> :
                            <Icon
                              style={{ width: '18px', height: '18px', verticalAlign: 'middle', overflow: 'hidden' }}
                              viewBox="0 0 1024 1024"
                              icon="eye-block"
                            />
                        }
                      </button>
                    </div>
                  </div>

                  <div className="field">
                    <span>Voice:</span>

                    <input
                      type="text"
                      disabled={!editing}
                      defaultValue={newAccountData.voice}
                      onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, voice: value })}
                    />
                  </div>
                </div>

                <div className="__right">
                  <div className="field">
                    <span>Token:</span>
                    <div className="form-field _iconed">
                      <input
                        disabled={!editing}
                        type={inputTypes.twilio_token}
                        // readOnly={inputTypes.twilio_token !== 'text'}
                        defaultValue={newAccountData.twilio_token || ''}
                        onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, twilio_token: value })}
                      />
                      <button className="_zeroed _iconed" onClick={() => setInputTypes({ ...inputTypes, twilio_token: inputTypes.twilio_token === 'text' ? 'password' : 'text' })}>
                        {
                          inputTypes.twilio_token === 'text' ?
                            <Icon icon="eye-4" /> :
                            <Icon
                              style={{ width: '18px', height: '18px', verticalAlign: 'middle', overflow: 'hidden' }}
                              viewBox="0 0 1024 1024"
                              icon="eye-block"
                            />
                        }
                      </button>
                    </div>
                  </div>

                  <div className="field">
                    <span>Call Token:</span>
                    <div className="form-field _iconed">
                      <input
                        disabled={!editing}
                        type={inputTypes.twilio_call_token}
                        // readOnly={inputTypes.twilio_call_token !== 'text'}
                        defaultValue={newAccountData.twilio_call_token || ''}
                        onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, twilio_call_token: value })}
                      />
                      <button className="_zeroed _iconed" onClick={() => setInputTypes({ ...inputTypes, twilio_call_token: inputTypes.twilio_call_token === 'text' ? 'password' : 'text' })}>
                        {
                          inputTypes.twilio_call_token === 'text' ?
                            <Icon icon="eye-4" /> :
                            <Icon
                              style={{ width: '18px', height: '18px', verticalAlign: 'middle', overflow: 'hidden' }}
                              viewBox="0 0 1024 1024"
                              icon="eye-block"
                            />
                        }
                      </button>
                    </div>
                  </div>

                  <div className="field">
                    <span>Call Time Out:</span>

                    <input
                      type="number"
                      className="appearance"
                      disabled={!editing}
                      defaultValue={newAccountData.call_time_out || ''}
                      onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, call_time_out: +value })}
                    />
                  </div>
                </div>
              </div>
            </div>
        }

        <div className="fieldset">
          <div className="legend">Conversions</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Measurement Id:</span>
                <div className="form-field _iconed">
                  <input
                    disabled={!editing}
                    type={inputTypes.measurement_id}
                    defaultValue={newAccountData.measurement_id || ''}
                    onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, measurement_id: value })}
                  />
                  <button className="_zeroed _iconed" onClick={() => setInputTypes({ ...inputTypes, measurement_id: inputTypes.measurement_id === 'text' ? 'password' : 'text' })}>
                    {
                      inputTypes.measurement_id === 'text' ?
                        <Icon icon="eye-4" /> :
                        <Icon
                          style={{ width: '18px', height: '18px', verticalAlign: 'middle', overflow: 'hidden' }}
                          viewBox="0 0 1024 1024"
                          icon="eye-block"
                        />
                    }
                  </button>
                </div>
              </div>
            </div>

            <div className="__right">
              <div className="field">
                <span>Api Secret:</span>
                <div className="form-field _iconed">
                  <input
                    disabled={!editing}
                    type={inputTypes.api_secret}
                    defaultValue={newAccountData.api_secret || ''}
                    onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, api_secret: value })}
                  />
                  <button className="_zeroed _iconed" onClick={() => setInputTypes({ ...inputTypes, api_secret: inputTypes.api_secret === 'text' ? 'password' : 'text' })}>
                    {
                      inputTypes.api_secret === 'text' ?
                        <Icon icon="eye-4" /> :
                        <Icon
                          style={{ width: '18px', height: '18px', verticalAlign: 'middle', overflow: 'hidden' }}
                          viewBox="0 0 1024 1024"
                          icon="eye-block"
                        />
                    }
                  </button>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className="fieldset">
          <div className="legend">AI</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>ChatGPT API KEY:</span>

                <div className="form-field _iconed">
                  <input
                    disabled={!editing}
                    type={inputTypes.openai_api_key}
                    defaultValue={newAccountData.openai_api_key || ''}
                    onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, openai_api_key: value })}
                  />
                  <button
                    className="_zeroed _iconed"
                    onClick={() => setInputTypes({ ...inputTypes, openai_api_key: inputTypes.openai_api_key === 'text' ? 'password' : 'text' })}
                  >
                    {
                      inputTypes.openai_api_key === 'text' ?
                        <Icon icon="eye-4" /> :
                        <Icon
                          style={{ width: '18px', height: '18px', verticalAlign: 'middle', overflow: 'hidden' }}
                          viewBox="0 0 1024 1024"
                          icon="eye-block"
                        />
                    }
                  </button>
                </div>
              </div>
            </div>

            <div className="__right"></div>
          </div>
        </div>

        {
          !!accountData.permissions.payment_stripe_settings_show &&
          <div className="fieldset">
            <div className="legend">Stripe Payment Settings</div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>Stripe SK:</span>

                  <div className="form-field _iconed">
                    <input
                      disabled={!accountData.permissions.payment_stripe_settings_edit || !editing}
                      type={inputTypes.stripe_sk}
                      defaultValue={newAccountData.stripe_sk || ''}
                      onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, stripe_sk: value })}
                    />
                    <button
                      className="_zeroed _iconed"
                      onClick={() => setInputTypes({ ...inputTypes, stripe_sk: inputTypes.stripe_sk === 'text' ? 'password' : 'text' })}
                    >
                      {
                        inputTypes.stripe_sk === 'text' ?
                          <Icon icon="eye-4" /> :
                          <Icon
                            style={{ width: '18px', height: '18px', verticalAlign: 'middle', overflow: 'hidden' }}
                            viewBox="0 0 1024 1024"
                            icon="eye-block"
                          />
                      }
                    </button>
                  </div>
                </div>

                <div className="field">
                  <span>Stripe PK:</span>

                  <div className="form-field _iconed">
                    <input
                      disabled={!accountData.permissions.payment_stripe_settings_edit || !editing}
                      type={inputTypes.stripe_pk}
                      defaultValue={newAccountData.stripe_pk || ''}
                      onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, stripe_pk: value })}
                    />
                    <button
                      className="_zeroed _iconed"
                      onClick={() => setInputTypes({ ...inputTypes, stripe_pk: inputTypes.stripe_pk === 'text' ? 'password' : 'text' })}
                    >
                      {
                        inputTypes.stripe_pk === 'text' ?
                          <Icon icon="eye-4" /> :
                          <Icon
                            style={{ width: '18px', height: '18px', verticalAlign: 'middle', overflow: 'hidden' }}
                            viewBox="0 0 1024 1024"
                            icon="eye-block"
                          />
                      }
                    </button>
                  </div>
                </div>

                <div className="field" style={{ position: 'relative' }}>
                  <span>Fee Compensation:</span>

                  <input
                    className="appearance"
                    type='number'
                    disabled={!accountData.permissions.payment_stripe_settings_edit || !editing}
                    value={`${Number(newAccountData.stripe_fee_compensation || 0).toFixed(2)}`}
                    step={0.01}
                    onFocus={(event) => handleFocus(event)}
                    onChange={(event) => handleChangeStripe(event)}
                    onKeyDown={(event) => handleChangeStripeBackspace(event)}
                  />

                  <div
                    style={{
                      position: 'absolute',
                      right: '10px',
                      opacity: !accountData.permissions.payment_stripe_settings_edit || !editing ? '.5' : '1'
                    }}
                  >
                    %
                  </div>
                </div>

                <div className="field">
                  <span>Payment Limit:</span>

                  <div className="form-field _iconed">

                    <input
                      className="appearance"
                      type='number'
                      min={0}
                      disabled={!accountData.permissions.payment_stripe_settings_edit || !editing}
                      value={newAccountData.payment_stripe_limit}
                      step={1}
                      onChange={(event) => setNewAccountData({ ...newAccountData, payment_stripe_limit: +event.target.value })}
                    />
                  </div>
                </div>

                <div className="field">
                  <span>Confirmation Limit:</span>

                  <div className="form-field _iconed">

                    <input
                      className="appearance"
                      type='number'
                      min={0}
                      disabled={!accountData.permissions.payment_stripe_settings_edit || !editing}
                      value={newAccountData.payment_stripe_amount_confirmation}
                      step={1}
                      onChange={(event) => setNewAccountData({ ...newAccountData, payment_stripe_amount_confirmation: +event.target.value })}
                    />
                  </div>
                </div>
              </div>

              <div className="__right">
                <div className="field">
                  <span>Slack Payment Channel:</span>

                  <input
                    type="text"
                    disabled={!accountData.permissions.payment_stripe_settings_edit || !editing}
                    defaultValue={newAccountData.payment_slack_channel || ''}
                    onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, payment_slack_channel: value })}
                  />
                </div>

                <div className="field">
                  <span>Send To Slack:</span>

                  <Switcher
                    disabled={!accountData.permissions.payment_stripe_settings_edit || !editing}
                    checked={newAccountData.send_payment_to_slack}
                    onChange={(value) => setNewAccountData({ ...newAccountData, send_payment_to_slack: value })}
                  />
                </div>

                <div className="field">
                  <span>Use for Link Payment:</span>

                  <Switcher
                    disabled={!accountData.permissions.payment_stripe_settings_edit || !editing}
                    checked={newAccountData.use_for_link_payment === 'stripe'}
                    onChange={(value) => setNewAccountData({ ...newAccountData, use_for_link_payment: value ? 'stripe' : 'square' })}
                  />
                </div>
              </div>
            </div>
          </div>
        }

        {
          !!accountData.permissions.payment_square_settings_show &&
          <div className="fieldset">
            <div className="legend">Square Payment Settings</div>

            <div className="fields">
              <div className="__left">
                <div className="field">
                  <span>Application ID:</span>

                  <div className="form-field _iconed">
                    <input
                      disabled={!accountData.permissions.payment_square_settings_edit || !editing}
                      type={inputTypes.square_payment_application_id}
                      defaultValue={newAccountData.square_payment_application_id || ''}
                      onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, square_payment_application_id: value })}
                    />
                    <button
                      className="_zeroed _iconed"
                      onClick={() => setInputTypes({ ...inputTypes, square_payment_application_id: inputTypes.square_payment_application_id === 'text' ? 'password' : 'text' })}
                    >
                      {
                        inputTypes.square_payment_application_id === 'text' ?
                          <Icon icon="eye-4" /> :
                          <Icon
                            style={{ width: '18px', height: '18px', verticalAlign: 'middle', overflow: 'hidden' }}
                            viewBox="0 0 1024 1024"
                            icon="eye-block"
                          />
                      }
                    </button>
                  </div>
                </div>

                <div className="field">
                  <span>Access Token:</span>

                  <div className="form-field _iconed">
                    <input
                      disabled={!accountData.permissions.payment_square_settings_edit || !editing}
                      type={inputTypes.square_payment_access_token}
                      defaultValue={newAccountData.square_payment_access_token || ''}
                      onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, square_payment_access_token: value })}
                    />
                    <button
                      className="_zeroed _iconed"
                      onClick={() => setInputTypes({ ...inputTypes, square_payment_access_token: inputTypes.square_payment_access_token === 'text' ? 'password' : 'text' })}
                    >
                      {
                        inputTypes.square_payment_access_token === 'text' ?
                          <Icon icon="eye-4" /> :
                          <Icon
                            style={{ width: '18px', height: '18px', verticalAlign: 'middle', overflow: 'hidden' }}
                            viewBox="0 0 1024 1024"
                            icon="eye-block"
                          />
                      }
                    </button>
                  </div>
                </div>

                <div className="field" style={{ position: 'relative' }}>
                  <span>Fee Compensation:</span>

                  <input
                    className="appearance"
                    type='number'
                    disabled={!accountData.permissions.payment_square_settings_edit || !editing}
                    value={`${Number(newAccountData.square_fee_compensation || 0).toFixed(2)}`}
                    step={0.01}
                    onFocus={(event) => handleFocus(event)}
                    onChange={(event) => handleChangeSquare(event)}
                    onKeyDown={(event) => handleChangeSquareBackspace(event)}
                  />

                  <div
                    style={{
                      position: 'absolute',
                      right: '10px',
                      opacity: !accountData.permissions.payment_square_settings_edit || !editing ? '.5' : '1'
                    }}
                  >
                    %
                  </div>
                </div>

                <div className="field">
                  <span>Payment Limit:</span>

                  <div className="form-field _iconed">

                    <input
                      className="appearance"
                      type='number'
                      min={0}
                      disabled={!accountData.permissions.payment_square_settings_edit || !editing}
                      value={newAccountData.payment_square_limit}
                      step={1}
                      onChange={(event) => setNewAccountData({ ...newAccountData, payment_square_limit: +event.target.value })}
                    />
                  </div>
                </div>

                <div className="field">
                  <span>Confirmation Limit:</span>

                  <div className="form-field _iconed">

                    <input
                      className="appearance"
                      type='number'
                      min={0}
                      disabled={!accountData.permissions.payment_square_settings_edit || !editing}
                      value={newAccountData.payment_square_amount_confirmation}
                      step={1}
                      onChange={(event) => setNewAccountData({ ...newAccountData, payment_square_amount_confirmation: +event.target.value })}
                    />
                  </div>
                </div>
              </div>

              <div className="__right">
                <div className="field">
                  <span>Location:</span>

                  <input
                    type="text"
                    disabled={!accountData.permissions.payment_square_settings_edit || !editing}
                    defaultValue={newAccountData.square_payment_location_name || ''}
                    onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, square_payment_location_name: value })}
                  />
                </div>

                <div className="field">
                  <span>Location ID:</span>

                  <input
                    type="text"
                    disabled={!accountData.permissions.payment_square_settings_edit || !editing}
                    defaultValue={newAccountData.square_payment_location_id || ''}
                    onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, square_payment_location_id: value })}
                  />
                </div>

                <div className="field">
                  <span>Slack Payment Channel:</span>

                  <input
                    type="text"
                    disabled={!accountData.permissions.payment_square_settings_edit || !editing}
                    defaultValue={newAccountData.square_payment_slack_channel || ''}
                    onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, square_payment_slack_channel: value })}
                  />
                </div>

                <div className="field">
                  <span>Send To Slack:</span>

                  <Switcher
                    disabled={!accountData.permissions.payment_square_settings_edit || !editing}
                    checked={newAccountData.is_send_square_payment_to_slack}
                    onChange={(value) => setNewAccountData({ ...newAccountData, is_send_square_payment_to_slack: value })}
                  />
                </div>

                <div className="field">
                  <span>Use for Link Payment:</span>

                  <Switcher
                    disabled={!accountData.permissions.payment_square_settings_edit || !editing}
                    checked={newAccountData.use_for_link_payment === 'square'}
                    onChange={(value) => setNewAccountData({ ...newAccountData, use_for_link_payment: value ? 'square' : 'stripe' })}
                  />
                </div>
              </div>
            </div>
          </div>
        }

        <div className="fieldset">
          <div className="legend">Settings</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Permission:</span>

                <Select
                  disabled={!editing}
                  options={[{ name: '', accounts_permissions_id: '' }].concat(editions.permissions).map((permission) => ({
                    span: permission.name,
                    value: permission.accounts_permissions_id
                  }))}
                  selectedOption={newAccountData.permission as string}
                  onChange={(value: string) => setNewAccountData({ ...newAccountData, permission: value })}
                />
              </div>

              <div className="field">
                <span>Menu:</span>
                <Select
                  disabled={!editing}
                  options={[{ name: '', accounts_menus_id: '' }].concat(editions.accounts_menus).map((menu) => ({
                    span: menu.name,
                    value: menu.accounts_menus_id
                  }))}
                  selectedOption={newAccountData.accounts_menus_id as string}
                  onChange={(value: string, span: string) =>
                    setNewAccountData({ ...newAccountData, account_menus: span, accounts_menus_id: value })}
                />
              </div>

              <div className="field">
                <span>Slack Url:</span>
                <input
                  type="text"
                  disabled={!editing}
                  className={classNames({
                    error: errors.includes('slack_url'),
                  })}
                  defaultValue={newAccountData.slack_url || ''}
                  onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, slack_url: value })}
                />
              </div>
            </div>

            <div className="__right">

              <div className="field">
                <span>Time Zone:</span>
                <Select
                  disabled={!editing}
                  options={[{ name: '', id: 0 }].concat(editions.time_zone).map((time_zone) => ({
                    span: time_zone.name,
                    value: time_zone.id
                  }))}
                  selectedOption={newAccountData.time_zone_id as string}
                  onChange={(value: string, span: string) => setNewAccountData({ ...newAccountData, time_zone_id: value, time_zone: span })}
                />
              </div>

              <div className="field">
                <span>Api token:</span>
                <div className="form-field _iconed">
                  <input
                    disabled={!editing}
                    type={inputTypes.api_token}
                    autoComplete='new-password'
                    defaultValue={newAccountData.api_token || ''}
                    onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, api_token: value })}
                  />
                  <button className="_zeroed _iconed" onClick={() => setInputTypes({ ...inputTypes, api_token: inputTypes.api_token === 'text' ? 'password' : 'text' })}>
                    {
                      inputTypes.api_token === 'text' ?
                        <Icon icon="eye-4" /> :
                        <Icon
                          style={{ width: '18px', height: '18px', verticalAlign: 'middle', overflow: 'hidden' }}
                          viewBox="0 0 1024 1024"
                          icon="eye-block"
                        />
                    }
                  </button>
                </div>
              </div>

              <div className="field">
                <span>Slack:</span>
                <Switcher
                  disabled={!editing}
                  checked={newAccountData.slack_active}
                  onChange={(value) => setNewAccountData({ ...newAccountData, slack_active: value })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Automation</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Payout Dispatcher:</span>

                <Switcher
                  disabled={!editing}
                  checked={newAccountData.automation_payout_dispatchers}
                  onChange={(value) => setNewAccountData({ ...newAccountData, automation_payout_dispatchers: value })}
                />
              </div>

              <div className="field">
                <span>Payout Dispatcher Weekday:</span>

                <Select
                  disabled={!editing}
                  options={weekdayOptions.map((week) => ({
                    span: week.name,
                    value: week.id
                  }))}
                  selectedOption={newAccountData.automation_payout_dispatchers_weekday as number}
                  onChange={(value: number) => setNewAccountData({ ...newAccountData, automation_payout_dispatchers_weekday: value as number })}
                />
              </div>

              <div className="field">
                <span>Payout Dispatcher Time:</span>

                <DateTimeComponent
                  inputProps={{ disabled: !editing }}
                  dateFormat={false}
                  timeFormat={true}
                  value={moment(newAccountData.automation_payout_dispatchers_time, "HH:mm:ss") as Moment}
                  onChange={(value) => setNewAccountData({ ...newAccountData, automation_payout_dispatchers_time: moment.isMoment(value) ? value.toDate() : newAccountData.automation_payout_dispatchers_time })}
                />
              </div>
            </div>

            <div className="__right">
              <div className="field">
                <span>Payout Staff:</span>

                <Switcher
                  disabled={!editing}
                  checked={newAccountData.automation_payout_staff}
                  onChange={(value) => setNewAccountData({ ...newAccountData, automation_payout_staff: value })}
                />
              </div>

              <div className="field">
                <span>Payout Staff Weekday:</span>

                <Select
                  disabled={!editing}
                  options={weekdayOptions.map((week) => ({
                    span: week.name,
                    value: week.id
                  }))}
                  selectedOption={newAccountData.automation_payout_staff_weekday as number}
                  onChange={(value: number) => setNewAccountData({ ...newAccountData, automation_payout_staff_weekday: value as number })}
                />
              </div>

              <div className="field">
                <span>Payout Staff Time:</span>

                <DateTimeComponent
                  inputProps={{ disabled: !editing }}
                  dateFormat={false}
                  timeFormat={true}
                  value={moment(newAccountData.automation_payout_staff_time, "HH:mm:ss") as Moment}
                  onChange={(value) => setNewAccountData({ ...newAccountData, automation_payout_staff_time: moment.isMoment(value) ? value.toDate() : newAccountData.automation_payout_staff_time })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Advertising</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Update Google Local Service Statistics:</span>

                <Switcher
                  disabled={!editing}
                  checked={newAccountData.is_update_google_local_service_stat}
                  onChange={(value) => setNewAccountData({ ...newAccountData, is_update_google_local_service_stat: value })}
                />
              </div>

              <div className="field">
                <span>Update Time:</span>

                <DateTimeComponent
                  inputProps={{ disabled: !editing }}
                  dateFormat={false}
                  timeFormat={true}
                  value={moment(newAccountData.time_for_update_google_local_service_stat, "HH:mm:ss") as Moment}
                  onChange={(value) => setNewAccountData({ ...newAccountData, time_for_update_google_local_service_stat: moment.isMoment(value) ? value.toDate() : newAccountData.time_for_update_google_local_service_stat })}
                />
              </div>
            </div>

            <div className="__right">
            </div>
          </div>
        </div>

        {
          accountData.permissions.profile_show_notifications_sound &&
          <div className="fieldset">
            <div className="legend">Notifications</div>

            {
              accountData.only_account_permissions.notifications_websiterequest &&
              accountData.account[0].notifications_sounds &&
              <div className="fields">
                <div
                  className="field"
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '20px',
                    flexWrap: 'wrap',
                  }}
                >
                  <span style={{ width: 'fit-content' }}>
                    Website Request:
                  </span>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      gap: '20px',
                    }}
                  >
                    <button
                      className="_zeroed _iconed _blue"
                      disabled={!accountData.permissions.profile_edit_notifications_sound}
                      onClick={() =>
                        setUpdatedSounds({
                          name: 'websiterequest',
                          data: getNotificationSound('websiterequest').url,
                          id: getNotificationSound('websiterequest').notifications_sound_id
                        })}
                    >
                      <Icon style={{ minWidth: '24px' }} icon="pencil-14" />
                    </button>

                    <AudioPlayer
                      src={getNotificationSound('websiterequest').url}
                      disabled={!getNotificationSound('websiterequest').notifications_sound_id}
                      is_play={playing === 'websiterequest'}
                      name="websiterequest"
                      setPlaying={setPlaying}
                    />
                  </div>
                </div>
              </div>
            }

            {
              accountData.only_account_permissions.notifications_sms &&
              accountData.account[0].notifications_sounds &&
              <div className="fields">
                <div
                  className="field"
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '20px',
                    flexWrap: 'wrap',
                  }}
                >
                  <span style={{ width: 'fit-content' }}>
                    SMS:
                  </span>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      gap: '20px',
                    }}
                  >
                    <button
                      className="_zeroed _iconed _blue"
                      disabled={!accountData.permissions.profile_edit_notifications_sound}
                      onClick={() =>
                        setUpdatedSounds({
                          name: 'sms',
                          data: getNotificationSound('sms').url,
                          id: getNotificationSound('sms').notifications_sound_id
                        })}
                    >
                      <Icon style={{ minWidth: '24px' }} icon="pencil-14" />
                    </button>

                    <AudioPlayer
                      src={getNotificationSound('sms').url}
                      disabled={!getNotificationSound('sms').notifications_sound_id}
                      is_play={playing === 'sms'}
                      name="sms"
                      setPlaying={setPlaying}
                    />
                  </div>
                </div>
              </div>
            }

            {
              accountData.only_account_permissions.notifications_dispatcherpayout &&
              accountData.account[0].notifications_sounds &&
              <div className="fields">
                <div
                  className="field"
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '20px',
                    flexWrap: 'wrap',
                  }}
                >
                  <span style={{ width: 'fit-content' }}>
                    Dispatcher Payout:
                  </span>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      gap: '20px',
                    }}
                  >
                    <button
                      className="_zeroed _iconed _blue"
                      disabled={!accountData.permissions.profile_edit_notifications_sound}
                      onClick={() =>
                        setUpdatedSounds({
                          name: 'dispatcherpayout',
                          data: getNotificationSound('dispatcherpayout').url,
                          id: getNotificationSound('dispatcherpayout').notifications_sound_id
                        })}
                    >
                      <Icon style={{ minWidth: '24px' }} icon="pencil-14" />
                    </button>

                    <AudioPlayer
                      src={getNotificationSound('dispatcherpayout').url}
                      disabled={!getNotificationSound('dispatcherpayout').notifications_sound_id}
                      is_play={playing === 'dispatcherpayout'}
                      name="dispatcherpayout"
                      setPlaying={setPlaying}
                    />
                  </div>
                </div>
              </div>
            }

            {
              accountData.only_account_permissions.notifications_staffpayout &&
              accountData.account[0].notifications_sounds &&
              <div className="fields">
                <div
                  className="field"
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '20px',
                    flexWrap: 'wrap',
                  }}
                >
                  <span style={{ width: 'fit-content' }}>
                    Staff Payout:
                  </span>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      gap: '20px',
                    }}
                  >
                    <button
                      className="_zeroed _iconed _blue"
                      disabled={!accountData.permissions.profile_edit_notifications_sound}
                      onClick={() =>
                        setUpdatedSounds({
                          name: 'staffpayout',
                          data: getNotificationSound('staffpayout').url,
                          id: getNotificationSound('staffpayout').notifications_sound_id
                        })}
                    >
                      <Icon style={{ minWidth: '24px' }} icon="pencil-14" />
                    </button>

                    <AudioPlayer
                      src={getNotificationSound('staffpayout').url}
                      disabled={!getNotificationSound('staffpayout').notifications_sound_id}
                      is_play={playing === 'staffpayout'}
                      name="staffpayout"
                      setPlaying={setPlaying}
                    />
                  </div>
                </div>
              </div>
            }

            {
              (accountData.only_account_permissions.notifications_unsent_appointments || accountData.only_account_permissions.notifications_unsent_appointments_all) &&
              accountData.account[0].notifications_sounds &&
              <div className="fields">
                <div
                  className="field"
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '20px',
                    flexWrap: 'wrap',
                  }}
                >
                  <span style={{ width: 'fit-content' }}>
                    Unsent Appointments:
                  </span>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      gap: '20px',
                    }}
                  >
                    <button
                      className="_zeroed _iconed _blue"
                      disabled={!accountData.permissions.profile_edit_notifications_sound}
                      onClick={() =>
                        setUpdatedSounds({
                          name: 'unsentappointment',
                          data: getNotificationSound('unsentappointment').url,
                          id: getNotificationSound('unsentappointment').notifications_sound_id
                        })}
                    >
                      <Icon style={{ minWidth: '24px' }} icon="pencil-14" />
                    </button>

                    <AudioPlayer
                      src={getNotificationSound('unsentappointment').url}
                      disabled={!getNotificationSound('unsentappointment').notifications_sound_id}
                      is_play={playing === 'unsentappointment'}
                      name="unsentappointment"
                      setPlaying={setPlaying}
                    />
                  </div>
                </div>
              </div>
            }
          </div>
        }

        <div className="fieldset">
          <div className="legend">Mailing Address</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Street:</span>
                <input
                  type="text"
                  disabled={!editing}
                  defaultValue={newAccountData.mailing_street || ''}
                  onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, mailing_street: value })}
                />
              </div>

              <div className="field">
                <span>Unit/Apt:</span>
                <input
                  type="text"
                  disabled={!editing}
                  defaultValue={newAccountData.mailing_unit || ''}
                  onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, mailing_unit: value })}
                />
              </div>

              <div className="field">
                <span>City:</span>
                <input
                  type="text"
                  disabled={!editing}
                  defaultValue={newAccountData.mailing_city || ''}
                  onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, mailing_city: value })}
                />
              </div>
            </div>

            <div className="__right">
              <div className="field">
                <span>State:</span>
                <Select
                  disabled={!editing}
                  options={[{ name: '', id: 0 }].concat(editions.state).map((option) => ({
                    span: option.name,
                    value: option.id
                  }))}
                  selectedOption={newAccountData.mailing_state as string}
                  onChange={(value: string) => setNewAccountData({ ...newAccountData, mailing_state: value })}
                />
              </div>

              <div className="field">
                <span>Zip:</span>
                <input
                  type="text"
                  disabled={!editing} defaultValue={newAccountData.mailing_zip || ''}
                  onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, mailing_zip: value })}
                />
              </div>

            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="flex-container sb wrap">
            <div className="legend">Billing Address</div>

            {/* {editing ? ( */}
            <div className="legend-action-wrapper">
              <label>Same as Mailing Address:</label>

              <div className="input-wrapper">
                <Checkbox
                  disabled={!editing}
                  contents=''
                  value={newAccountData.same_address}
                  onChange={(value) => setNewAccountData({ ...newAccountData, same_address: value })}
                />
              </div>
            </div>
            {/* ) : null} */}
          </div>

          {!newAccountData.same_address ? (
            <div className="fields">

              <div className="__left">
                <div className="field">
                  <span>Street:</span>
                  <input
                    type="text"
                    disabled={!editing}
                    defaultValue={newAccountData.billing_street || ''}
                    onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, billing_street: value })}
                  />
                </div>

                <div className="field">
                  <span>Unit/Apt:</span>
                  <input type="text"
                    disabled={!editing}
                    defaultValue={newAccountData.billing_unit || ''}
                    onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, billing_unit: value })}
                  />
                </div>


                <div className="field">
                  <span>City:</span>
                  <input
                    type="text"
                    disabled={!editing}
                    defaultValue={newAccountData.billing_city || ''}
                    onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, billing_city: value })}
                  />
                </div>
              </div>

              <div className="__right">
                <div className="field">
                  <span>State:</span>
                  <Select
                    disabled={!editing}
                    options={[{ name: '', id: 0 }].concat(editions.state).map((option) => ({
                      span: option.name,
                      value: option.id
                    }))}
                    selectedOption={newAccountData.billing_state as string}
                    onChange={(value: string) => setNewAccountData({ ...newAccountData, billing_state: value })}
                  />
                </div>

                <div className="field">
                  <span>Zip:</span>
                  <input
                    type="text"
                    disabled={!editing}
                    defaultValue={newAccountData.billing_zip || ''}
                    onChange={({ target: { value } }) => setNewAccountData({ ...newAccountData, billing_zip: value })} />
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <div className="fieldset">
          <div className="legend">Info</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Date Created:</span>
                <input
                  type="text"
                  defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, newAccountData.created_at)}
                  disabled={true}
                />
              </div>

              <div className="field">
                <span>Created by:</span>
                <input
                  type="text"
                  defaultValue={newAccountData.created_by}
                  disabled={true}
                />
              </div>
            </div>


            <div className="__right">
              <div className="field">
                <span>Last Edited:</span>
                <input
                  type="text"
                  defaultValue={dateToInfoBlock('MM/dd/yyyy hh:mma', timeZone, newAccountData.updated_at)}
                  disabled={true}
                />
              </div>

              <div className="field">
                <span>Last Edited by:</span>
                <input
                  type="text"
                  defaultValue={newAccountData.updated_by}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>

        {editing ? (
          <div className="wrapper flex-container sb editing-buttons">
            <div>
              {
                accountData.permissions.account_delete &&
                <button className="_red" onClick={() => setDeleting(true)}>Delete Account</button>
              }
            </div>
            <div className="buttons">
              <button
                className="_bordered _red"
                onClick={() => handleCancelEdit()}
              >
                Cancel
              </button>
              <button
                disabled={!readyToSave || isSaving}
                className="_bordered _green"
                onClick={() => preSaveFunction()}
              >
                Save
              </button>
            </div>
          </div>
        ) : null}

        {avatarPopup ? (
          <div
            className="popup imageCrop"
            onClick={() => setAvatarPopup(false)}
            onDragLeave={onDragLeave}
            onDragOver={onDragOver}
            onDrop={(event) => {
              draging && onDrop(event)
            }}
          >
            <div
              className="wrapper"
              onClick={(e) => e.stopPropagation()}
            >

              {
                draging ?
                  <div
                    style={{
                      border: '2px solid rgb(96, 147, 222)',
                      borderRadius: '5px',
                      position: 'relative'
                    }}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      style={{ width: '100%', height: '120px', display: 'block', opacity: '0', zIndex: '2' }}
                      name="myImage"
                      // onDrop={onDrop}
                      onChange={onSelectFile}
                      id="file-uploader"
                    />
                    <span
                      style={{ position: 'absolute', top: '50px', width: '100%', textAlign: 'center', color: '#6093de' }}
                    >
                      Upload a File or click for refresh field
                    </span>
                  </div> :
                  <div>
                    <input type="file" accept="image/*" onChange={onSelectFile} id="file-uploader" />
                    <button
                      className="_wa _iconed _bordered _blue"
                      style={{ marginTop: upImg ? '' : '40px' }}
                      onClick={() => document.getElementById('file-uploader')?.click()}
                    >
                      <span>Upload a File</span>
                    </button>
                  </div>
              }

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  padding: '10px'
                }}
              >
                {
                  upImg &&
                  <ReactCrop
                    src={upImg}
                    onImageLoaded={onLoad}
                    crop={crop}
                    onChange={(c) => setCrop(c)}
                    onComplete={(c) => setCompletedCrop(c)}
                  />
                }
              </div>

              {
                upImg &&
                <div
                  style={{ display: 'flex', marginTop: '10px' }}
                >
                  <button
                    className="_wa _iconed _bordered _blue"
                    onClick={() => {
                      handleCancelUploadImage()
                    }}
                  >
                    <span>Cancel</span>
                  </button>

                  <button
                    className="_wa _iconed _bordered _red"
                    onClick={() => handleDeleteImage()}
                  >
                    Delete
                  </button>

                  <button
                    className="_wa _iconed _bordered _blue"
                    onClick={() => {
                      setDraging(false)
                      handleSaveImage()
                    }}
                  >
                    <span>Save</span>
                  </button>
                </div>
              }
            </div>
          </div>
        ) : null}

        {
          updatedSounds.name &&
          <div
            className="popup imageCrop"
            onClick={() => setUpdatedSounds({ name: '', data: '', id: null })}
            onDragLeave={onDragSoundLeave}
            onDragOver={onDragSoundOver}
            onDrop={(event) => {
              dragingSound && onDropSound(event)
            }}
          >

            <div
              className="wrapper"
              onClick={(e) => e.stopPropagation()}
            >

              {
                updatedSounds.data &&
                <AudioPlayerModal
                  audioSrc={updatedSounds.data}
                />
              }

              {
                updatedSounds.data &&
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    padding: '10px'
                  }}
                >
                </div>
              }

              {
                dragingSound ?
                  <div
                    style={{
                      border: '2px solid rgb(96, 147, 222)',
                      borderRadius: '5px',
                      position: 'relative'
                    }}
                  >
                    <input
                      type="file"
                      accept="audio/*"
                      style={{ width: '100%', height: '120px', display: 'block', opacity: '0', zIndex: '2' }}
                      onChange={onSelectSoundFile}
                      id="file-uploader-sound"
                    />
                    <span
                      style={{ position: 'absolute', top: '50px', width: '100%', textAlign: 'center', color: '#6093de' }}
                    >
                      Upload a File or click for refresh field
                    </span>
                  </div> :
                  <div>
                    <input type="file" accept="audio/*" onChange={onSelectSoundFile} id="file-uploader-sound" />
                    <button
                      className="_wa _iconed _bordered _blue"
                      style={{ marginTop: upImg ? '' : '40px' }}
                      onClick={() => document.getElementById('file-uploader-sound')?.click()}
                    >
                      <span>Upload a File</span>
                    </button>
                  </div>
              }


              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  padding: '10px'
                }}
              >
              </div>

              {
                updatedSounds.name &&
                <div
                  style={{ display: 'flex', marginTop: '10px' }}
                >
                  <button
                    className="_wa _iconed _bordered _blue"
                    onClick={() => setUpdatedSounds({ name: '', data: '', id: null })}
                  >
                    <span>Cancel</span>
                  </button>

                  <button
                    className="_wa _iconed _bordered _red"
                    disabled={!updatedSounds.id && !updatedSounds.data}
                    onClick={() => updatedSounds.id ?
                      handleRemoveSound(updatedSounds.id) :
                      setUpdatedSounds({ data: '', name: '', id: null })}
                  >
                    Delete
                  </button>

                  <button
                    className="_wa _iconed _bordered _blue"
                    disabled={isSaving}
                    onClick={() => {
                      setDragingSound(false)
                      handleSaveSound(updatedSounds.name)
                    }}
                  >
                    <span>Save</span>
                  </button>
                </div>
              }
            </div>
          </div>
        }

        {/* Item delete popup */}
        {accountData.permissions.account_delete && deleting ? (
          <div className="item-delete-popup" onClick={() => setDeleting(false)}>
            <div className="wrapper" onClick={(e) => e.stopPropagation()}>
              <div className="title">
                Delete Account
              </div>

              <div className="checkbox-wrapper">
                <Checkbox contents="I understand that after deleting the account it will not be possible to recover." value={readyToDelete} onChange={(value) => setReadyToDelete(value)} />
              </div>

              <div className="buttons">
                <button
                  className="_bordered _green"
                  onClick={() => setDeleting(false)}
                >
                  Cancel
                </button>

                <button
                  disabled={!readyToDelete || isSaving}
                  className="_bordered _red"
                  onClick={() => handleRemoveItem()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div >
    ) :
      null
    }
  </>)
}

export default AccountsPage_Item
