import { PDFDownloadLink } from '@react-pdf/renderer'
import { useEffect, useRef, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'

import ApproveDocument from '../Jobs/ApproveDocument'
import Checkbox from '../../components/Checkbox'

import { httpClientUpdate } from '../../funcs'

interface DocumentTextProps {
  account_id: string
  text: string
  link_id: string
}

export default function DocumentText({
  account_id,
  text,
  link_id,
}: DocumentTextProps) {
  const sigCanvas = useRef(null)

  const [approve, setApprove] = useState(false)
  const [isSignEnd, setIsSignEnd] = useState(false)
  const [base64Sign, setBase64Sign] = useState('')
  const [blobDoc, setBlobDoc] = useState<Blob | null>(null)

  const [isSend, setIsSend] = useState(false)

  useEffect(() => {
    if (blobDoc) {
      let reader = new FileReader();
      reader.readAsDataURL(blobDoc);
      reader.onloadend = function () {
        var base64data = reader.result;
        handleApproveLink(base64Sign, base64data as string)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blobDoc])

  function handleAgree() {
    if (sigCanvas && sigCanvas.current) {

      //@ts-ignore
      let base64 = sigCanvas.current.toDataURL()
      setBase64Sign(base64)
    }
  }

  async function handleApproveLink(sign: string, blobDoc: string) {
    setIsSend(true)
    try {
      let file = sign.replace('data:image/png;base64,', '').replace('data:image/jpeg;base64,', '')
      let document_base64 = blobDoc.replace('data:application/pdf;base64, ', '')

      // external/approvals
      const response = await httpClientUpdate.post(`/external/approvals`, {
        account_id: account_id,
        link_id: link_id,
        url_sign: file,
        url_signed_document: document_base64,
      })

      if (response.data.success) { }
    } catch (error) { }
  }

  return (
    <div className='document-approve-page'>
      {
        base64Sign &&
        <PDFDownloadLink document={<ApproveDocument approveText={text} sign={base64Sign} />} >
          {({ blob, url, loading, error }) => {
            blob && setBlobDoc(blob)
          }}
        </PDFDownloadLink>
      }
      <div className='document-approve'>
        <div className="document-title">
          Approve for Job
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            marginTop: '20px',
            alignSelf: 'flex-start',
          }}
        >
          <div>
            {
              text.split('\n').map((item, index) => (
                <p key={index} style={{ margin: '2px', whiteSpace: 'break-spaces', wordBreak: 'break-all' }}>{item}</p>
              ))
            }
          </div>
        </div>

        <div className='document-sign'>
          <div className='link-row-approve'>
            <Checkbox
              contents=""
              value={approve}
              onChange={(value) => setApprove(value)}
            />

            <span>Approve</span>
          </div>
          <div className='link-row-approve sign'>
            <SignatureCanvas
              ref={sigCanvas}
              penColor='black'
              canvasProps={{ width: 200, height: 100 }}
              onEnd={() => setIsSignEnd(true)}
            />
          </div>
        </div>
      </div>

      <div className="buttons">
        <button
          className="_bordered _green"
          disabled={!approve || !isSignEnd || isSend}
          onClick={() => handleAgree()}
        >
          Agree
        </button>
      </div>
    </div>
  )
}
