import classNames from 'classnames'
import Checkbox from '../../components/Checkbox'

import { NavActive } from '../../store/reducer'
import { InvoicesProps } from './ItemTech'

interface ItemsPaymentProps {
  navActive: NavActive
  phoneCall: boolean
  setModal: (value: { id: string, invoice_id: string } | null) => void
  invoices: InvoicesProps[]
  data: { id: string, invoice_id: string }
  handleSave: () => void
}

export default function ItemsPaymentModal({
  navActive,
  phoneCall,
  setModal,
  invoices,
  data,
  handleSave,
}: ItemsPaymentProps) {
  return (
    <div
      className={classNames('link-page payment-page job-tech-customer time-tracking mobile',
        {
          __respectAsidePanel: navActive.is && !phoneCall,
          __phoneCall: phoneCall && !navActive.is,
          __bothOpen: navActive.is && phoneCall,
          __nonePanel: !navActive.is && !phoneCall
        }
      )}
      style={{ zIndex: '1', margin: '0' }}
    >
      <div className='link-menu'>
        <div style={{ background: '#1b2f5f' }} className="title">
          Payment Change Invoice
        </div>

        <div style={{ margin: '20px' }}>
          <table className='table'>
            <tr>
              <th></th>
              <th>Name</th>
            </tr>

            {
              invoices.map(item => (
                <tr key={item.invoice_id}>
                  <td>
                    <Checkbox
                      contents=''
                      value={item.invoice_id === data.invoice_id}
                      onChange={() => setModal({ ...data, invoice_id: item.invoice_id as string })}
                    />
                  </td>

                  <td> {item.name} </td>
                </tr>
              ))
            }
          </table>
        </div>

        <div className="buttons">
          <button
            className="_bordered _red"
            onClick={() => setModal(null)}
          >
            No
          </button>

          <button
            className="_bordered _green"
            onClick={() => handleSave()}
          >
            Change
          </button>
        </div>
      </div>
    </div>
  )
}
