import { useEffect, useRef, useState } from 'react'

import Icon from '../../components/Icon'

interface AudioPlayerProps {
  src: string
  disabled?: boolean
  is_play?: boolean
  setPlaying: (value: string) => void
  name: string
}

export default function AudioPlayer({ src, disabled, is_play, setPlaying, name }: AudioPlayerProps) {
  const $player = useRef<HTMLAudioElement>(null)

  const [playingState, setPlayingState] = useState<boolean>(false)

  useEffect(() => {
    if (playingState) {
      setPlaying(name)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playingState])

  useEffect(() => {
    if (!is_play) {
      setPlayingState(false)
      $player.current?.pause()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is_play])


  return (
    <div className="player">
      <audio
        ref={$player}
        onPlay={() => setPlayingState(true)}
        onPause={() => setPlayingState(false)}
      >
        <source src={src} />
      </audio>

      {!playingState ? (
        <button
          className="_zeroed _iconed play-switcher"
          style={{ opacity: !!disabled ? .2 : 1 }}
          disabled={!!disabled}
          onClick={() => {
            !!!disabled && $player.current?.load()
            !!!disabled && $player.current?.play()
          }}
        >
          <Icon icon="media-control-48" style={{ fill: '#7b7b7d', minWidth: '24px' }} />
        </button>
      ) : (
        <button className="_zeroed _iconed play-switcher" onClick={() => $player.current?.pause()}>
          <Icon icon="media-control-49" style={{ fill: '#7b7b7d', minWidth: '24px' }} />
        </button>
      )}
    </div>
  )
}
