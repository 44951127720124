import React, { useState, useEffect } from "react";
import { BaseLink, useRoute } from "react-router5"
import qs from "qs";
import classNames from "classnames"

import Icon from "../../components/Icon"
import InputTextWithTitle from "../../components/InputTextWithTitle";

import { httpClientUpdate, nErrorUpdate } from "../../funcs";
import { useAppSelector } from "../../store/hooks"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/lists.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: ListPaymentServices
}

interface PermissionsProps {
  list_payment_service_add: boolean
  list_payment_service_delete: boolean
  list_payment_service_edit: boolean
  list_payment_service_show: boolean
}

interface ServicesItem {
  name: string
  payment_services_id: string
  is_not_editable: boolean
  editing?: boolean
}

interface ListPaymentServices {
  payment_services: ServicesItem[]
  permissions: PermissionsProps
}

export default function PaymentsSevices() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const accessToken = useAppSelector((store) => store.accessToken)

  const [isSaving, setIsSaving] = useState(false)
  const [errorAdd, setErrorAdd] = useState(false)
  const [errorEdit, setErrorEdit] = useState(false)

  const [newItemForm, setNewItemForm] = useState({
    name: ''
  })

  const [editItemForm, setEditItemForm] = useState({
    name: ''
  })

  const [items, setItems] = useState<ServicesItem[] | []>([])
  const [permissions, setPermissions] = useState({
    list_payment_service_add: false,
    list_payment_service_delete: false,
    list_payment_service_edit: false,
    list_payment_service_show: false,
  })

  // Load Service Resource function
  async function loadService() {
    try {
      httpClientUpdate.defaults.headers['Authorization'] = `Bearer ${accessToken}`

      const { data: { data: paymentsList, success, error } } = (await httpClientUpdate.get(`/lists/payment-services`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        },
        params: {
          account_id: activeAccountId
        }
      })) as { data: HttpClientUpdateReport }
      if (success) {
        setPermissions(paymentsList.permissions)
        setItems(paymentsList.payment_services.map(item => {
          return { ...item, editing: false }
        }))
        errorEdit && setErrorEdit(false)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // edit function
  async function editServiceResources(id: string) {
    setIsSaving(true)
    try {
      const response = await httpClientUpdate.put(`/lists/payment-services/${id}`, qs.stringify({
        account_id: activeAccountId,
        name: editItemForm.name
      }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        }
      })
      if (response.data.success) {
        setIsSaving(false)
        setEditItemForm({
          name: ''
        })
        loadService()
      } else {
        setIsSaving(false)
      }
    } catch (error: Error | AxiosError | unknown) {
      setIsSaving(false)
      let createdError = nErrorUpdate(error)
      if (createdError.content.errorText === "This name is already taken") {
        setErrorEdit(true)
      }
    }
  }

  // add function
  async function addServiceResurces() {
    setIsSaving(true)
    try {
      const response = await httpClientUpdate.post(`/lists/payment-services`, qs.stringify({
        account_id: activeAccountId,
        name: newItemForm.name
      }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        }
      })
      if (response.data.success) {
        setIsSaving(false)
        loadService()
        setNewItemForm({
          name: ''
        })
      } else {
        setIsSaving(false)
      }
    } catch (error: Error | AxiosError | unknown) {
      setIsSaving(false)
      let createdError = nErrorUpdate(error)
      if (createdError.content.errorText === "This name is already taken") {
        setErrorAdd(true)
      }
    }
  }

  // remove function
  async function removeService(id: string) {
    setIsSaving(true)
    try {
      const response = await httpClientUpdate.delete(`/lists/payment-services/${id}`, {
        data: {
          account_id: activeAccountId,
        }
      })
      if (response.data.success) {
        setIsSaving(false)
        return loadService()
      } else {
        setIsSaving(false)
      }
    } catch (error: Error | AxiosError | unknown) {
      setIsSaving(false)
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  useEffect(() => {
    loadService()

    // unmount page
    return () => {
      setItems([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAccountId])

  useEffect(() => {
    if (errorAdd && newItemForm.name) {
      setErrorAdd(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newItemForm.name])

  useEffect(() => {
    if (errorEdit && editItemForm.name) {
      setErrorEdit(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editItemForm.name])

  // Set editing function
  function setEditing(i: number, flag: boolean) {

    let _items = [...items]
    _items = _items.map((item) => ({ ...item, editing: false }))
    _items[i].editing = flag
    setItems(_items)

    setEditItemForm({
      ...editItemForm,
      name: flag ? _items[i].name : ''
    })
  }

  return (
    <div className="ListsPage_Sources">

      {/* Top navigation */}
      <div className="top-nav top-nav-list">
        <div className="top-nav-list-item">
          <BaseLink
            router={$router.router}
            routeName="lists.appliances"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.appliances" })}
          >
            <Icon icon="task-1" />
            <span>Appliances</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.brands"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.brands" })}
          >
            <Icon icon="task-1" />
            <span>Brands</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.areas"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.areas" })}
          >
            <Icon icon="task-1" />
            <span>Areas</span>
          </BaseLink>
        </div>

        <div className="top-nav-list-item">
          <BaseLink
            router={$router.router}
            routeName="lists.sources"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.sources" })}
          >
            <Icon icon="task-1" />
            <span>Sources</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.spam_filters"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.spam_filters" })}
          >
            <Icon icon="task-1" />
            <span>Spam Filters</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.campaigns"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.campaigns" })}
          >
            <Icon icon="task-1" />
            <span>Campaigns</span>
          </BaseLink>
        </div>

        <div className="top-nav-list-item">
          <BaseLink
            router={$router.router}
            routeName="lists.companies"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.companies" })}
          >
            <Icon icon="task-1" />
            <span>Companies</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.payments_sevices"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.payments_sevices" })}
          >
            <Icon icon="task-1" />
            <span>Payment Services</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.warehouses"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.warehouses" })}
          >
            <Icon icon="task-1" />
            <span>Warehouses</span>
          </BaseLink>
        </div>

        <div className="top-nav-list-item">
          <BaseLink
            router={$router.router}
            routeName="lists.services"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.services" })}
          >
            <Icon icon="task-1" />
            <span>Services</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.materials"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.materials" })}
          >
            <Icon icon="task-1" />
            <span>Materials</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="lists.discounts"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.discounts" })}
          >
            <Icon icon="task-1" />
            <span>Discounts</span>
          </BaseLink>
        </div>

        <div className="top-nav-list-item">
          <BaseLink
            router={$router.router}
            routeName="lists.ticket_templates"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "tasks.ticket_templates" })}
          >
            <Icon icon="task-1" />
            <span>Ticket Templates</span>
          </BaseLink>
        </div>
      </div>

      {/* Contents */}
      <div className="wrapper">
        <div className="flex-container sb header">
          <h1>List of Payment Services</h1>

          {
            (errorAdd || errorEdit) &&
            <span style={{ color: '#FF0000' }}>This name is already taken</span>
          }

          {
            permissions.list_payment_service_add &&
            <form onSubmit={(e) => { e.preventDefault(); addServiceResurces() }}>
              <InputTextWithTitle
                width={150}
                label='Name'
                value={newItemForm.name}
                setValue={(value) => setNewItemForm({ ...newItemForm, name: value })}
              />

              <button
                className="_wa _green"
                disabled={newItemForm.name.length < 1 || isSaving || errorAdd}
              >
                Add
              </button>
            </form>
          }
        </div>

        {/* Table */}
        {
          !!items.length
          && permissions.list_payment_service_show &&
          <table className="table">
            <thead>
              <tr>
                <th style={{ width: '100%' }}>Name</th>
                <th></th>
                <th></th>
              </tr>
            </thead>

            {items.map((item, i) => (<React.Fragment key={i}>
              {item.editing && permissions.list_payment_service_edit ? (
                <tr>
                  <td>
                    <div className="editing-form">
                      <input
                        className="_zeroed"
                        type="text"
                        defaultValue={editItemForm.name}
                        onChange={({ target: { value } }) => setEditItemForm({ ...editItemForm, name: value })}
                      />

                      <button
                        className="_zeroed _iconed _red"
                        onClick={() => setEditing(i, false)}
                      >
                        <Icon icon="pencil-16" />
                      </button>
                    </div>
                  </td>
                  <td>
                    <button
                      className="_zeroed _iconed _green"
                      disabled={isSaving || errorEdit}
                      onClick={() => editServiceResources(item.payment_services_id)}
                    >
                      <Icon icon="check-mark-1" />
                    </button>
                  </td>
                  <td>
                    <button
                      className="_zeroed _iconed _red"
                      disabled={!permissions.list_payment_service_delete || isSaving}
                      onClick={() => removeService(item.payment_services_id)}
                    >
                      <Icon icon="x-mark-1" />
                    </button>
                  </td>
                </tr>
              ) : (
                <tr key={i}>
                  <td>{item.name}</td>
                  <td>
                    {
                      !item.is_not_editable &&
                      <button
                        className="_zeroed _iconed _blue"
                        disabled={!permissions.list_payment_service_edit}
                        onClick={() => setEditing(i, true)}
                      >
                        <Icon icon="pencil-14" />
                      </button>
                    }
                  </td>
                  <td>
                    {
                      !item.is_not_editable &&
                      <button
                        className="_zeroed _iconed _red"
                        disabled={!permissions.list_payment_service_delete || isSaving}
                        onClick={() => removeService(item.payment_services_id)}
                      >
                        <Icon icon="x-mark-1" />
                      </button>
                    }
                  </td>
                </tr>
              )}
            </React.Fragment>))}
          </table>
        }
      </div>
    </div>
  )
}
