import React, { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import Draggable from "react-draggable";
import classNames from "classnames"
import { HexColorPicker } from "react-colorful";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Iframe from "react-iframe";

import Icon from "../../components/Icon";
import SelectComponent from "../../components/Select";
import DocumentPdf from "./DocumentPdf";

import { useAppSelector } from "../../store/hooks"
import { capitalizeFirstLetter, httpClientUpdate, nErrorUpdate, useOuterClick } from '../../funcs'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";
import { CompaniesProps, Report } from "./List";
import { HttpClientUpdateReport } from "./Item";

import "../../styles/pages/common/report-list.sass"
import './Index.sass'

interface HttpNotifyReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: Report
}

export interface BlockProps {
  activeDrags: number,
  deltaPosition: {
    x: number
    y: number
  },
  type: string
  value: string,
  editing: boolean,
  is_bold: boolean,
  font_size: number,
  is_underline: boolean,
  is_italic: boolean,
  color: string,
  align: 'left' | 'center' | 'right',
  image: File | null,
  image_base64: string,
  image_url: null | string,
  image_id: null | string,
  width: number
  height: number
  object_fit: ObjectFit
}

type ObjectFit = 'contain' | 'cover' | 'fill' | 'none' | 'scale-down'

const objectFitOptions = ['contain', 'cover', 'fill', 'none', 'scale-down']

const initialBlock = {
  activeDrags: 0,
  deltaPosition: {
    x: 0, y: 0
  },
  type: 'Label',
  value: 'Block',
  editing: true,
  is_bold: false,
  font_size: 16,
  is_underline: false,
  is_italic: false,
  color: '#000000',
  align: 'left' as 'left' | 'center' | 'right',
  image: null,
  image_base64: '',
  image_url: '',
  image_id: '',
  width: 100,
  height: 100,
  object_fit: 'contain' as ObjectFit
}

export default function Index() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)

  const [permissions, setPermissions] = useState({
    template_add: false,
    template_show: false,
    template_attachment_add: false,
  })

  const [edit, setEdit] = useState<CompaniesProps[]>([])
  const [selectedCompany, setSelectedCompany] = useState('')

  const [blocks, setBlocks] = useState<BlockProps[]>([])
  const [text, setText] = useState('')
  const [openPicker, setOpenPicker] = useState(false)

  const [loaded, setLoaded] = useState(false)

  const [isSaving, setIsSaving] = useState(false)

  const [options, setOptions] = useState({
    document: 'Invoice',
    type: 'Template',
  })

  const [canvasOptions, setCanvasOptions] = useState({
    width: 590,
    height: 300
  })

  const [mode, setMode] = useState('')
  const [url, setUrl] = useState('')

  useEffect(() => {
    setText('')
    setBlocks([])
  }, [options.type])

  useEffect(() => {
    let id = $router.router.getState().params.templatesId

    if (!!id) {
      loadInfoWithId(id)
    } else {
      loadStore()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function loadStore() {

    try {
      // https://2022back4.artemiudintsev.com/api/templates/report
      const { data: { data: report, success } } = await httpClientUpdate.get('/templates/report',
        {
          params: {
            account_id: activeAccountId,
            limit_rows: reportsMaxRows,
            page: 1,
          }
        }) as { data: HttpNotifyReport }

      if (success) {
        setPermissions(report.permissions)
        setEdit(report.edit.companies)
        setLoaded(true)
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function loadInfoWithId(id: string) {
    try {
      // api/templates/88888620yb704q31eu?account_id=88888
      const { data: { data: loadData, success, error } } = await httpClientUpdate.get('/templates/' + id, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpClientUpdateReport }
      if (success) {
        if (loadData.template.type === 'text') {
          setText(JSON.parse(loadData.template.content))
        } else {
          let content = JSON.parse(loadData.template.content)
          if (content.width) {
            setCanvasOptions({
              width: content.width,
              height: content.height,
            })
            setBlocks(content.content)
          } else {
            setBlocks(JSON.parse(loadData.template.content))
          }
        }

        setPermissions({
          ...permissions,
          template_attachment_add: loadData.permissions.template_attachment_add
        })
        setEdit(loadData.edit.companies)
        setSelectedCompany(loadData.template.company_id)
        setOptions({
          document: capitalizeFirstLetter(loadData.template.document),
          type: capitalizeFirstLetter(loadData.template.type),
        })
        setLoaded(true)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function handleSave() {
    setIsSaving(true)
    try {
      if (options.type === 'Text') {
        const response = await httpClientUpdate.post(`/templates`, {
          account_id: activeAccountId,
          company_id: selectedCompany,
          content: text,
          document: options.document.toLowerCase(),
          type: options.type.toLowerCase(),
        })
        if (response.data.success) {
          $router.router.navigate('templates', {
            companyId: activeAccountId,
            localInterface: $router.router.getState().params.localInterface
          }, { reload: true })
        }
      } else {
        let saveData: BlockProps[] = []

        for (const item of blocks) {
          if (item.type === 'Image') {
            let file = item.image_base64.replace('data:image/png;base64,', '').replace('data:image/jpeg;base64,', '')

            // api/templates/attachments
            const response = await httpClientUpdate.post(`/templates/attachments`, {
              account_id: activeAccountId,
              type: 'image/jpeg',
              file,
            })
            if (response.data.success) {
              saveData.push({
                ...item,
                image_url: response.data.data.url,
                image_base64: response.data.data.template_attachment_id,
                editing: false,
              })
            }
          } else {
            saveData.push({
              ...item,
              editing: false,
            })
          }
        }

        const response = await httpClientUpdate.post(`/templates`, {
          account_id: activeAccountId,
          company_id: selectedCompany,
          content: JSON.stringify({
            width: canvasOptions.width,
            height: canvasOptions.height,
            content: saveData
          }),
          document: options.document.toLowerCase(),
          type: options.type.toLowerCase(),
        })
        if (response.data.success) {
          $router.router.navigate('templates', {
            companyId: activeAccountId,
            localInterface: $router.router.getState().params.localInterface
          }, { reload: true })
        }
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  function handlePdf() {
    setBlocks(blocks.map(item => {
      return {
        ...item,
        editing: false
      }
    }))
    setMode('pdf')
  }

  function handleAddBlock(type: string) {
    let updated = blocks.map(item => {
      return {
        ...item,
        editing: false
      }
    })

    updated.push({
      ...initialBlock,
      width: type === 'Text' ? 200 : 100,
      type
    })
    setBlocks(updated)
  }

  function handleDrag(event: any, position: any, index: number) {
    const { x, y } = position

    setBlocks(blocks.map((item, idx) => {
      if (index === idx) {
        return {
          ...item,
          deltaPosition: {
            x,
            y,
          }
        }
      } else {
        return { ...item }
      }
    }))
  }

  function getValue() {
    let value = ''

    blocks.forEach(item => {
      if (item.editing) {
        value = item.value
      }
    })

    return value
  }

  function getType() {
    let type = ''

    blocks.forEach(item => {
      if (item.editing) {
        type = item.type
      }
    })

    return type
  }

  function getBase64() {
    let image_base64 = ''

    blocks.forEach(item => {
      if (item.editing) {
        image_base64 = item.image_base64
      }
    })

    return image_base64
  }

  function getWidth() {
    let width = 0

    blocks.forEach(item => {
      if (item.editing) {
        width = item.width
      }
    })

    return width
  }

  function getHeight() {
    let height = 0

    blocks.forEach(item => {
      if (item.editing) {
        height = item.height
      }
    })

    return height
  }

  function getObjectFit() {
    let object_fit = ''

    blocks.forEach(item => {
      if (item.editing) {
        object_fit = item.object_fit
      }
    })

    return object_fit
  }

  function getAlign() {
    let align = ''

    blocks.forEach(item => {
      if (item.editing) {
        align = item.align
      }
    })

    return align
  }

  function isBold() {
    let is_bold = false

    blocks.forEach(item => {
      if (item.editing) {
        is_bold = item.is_bold
      }
    })

    return is_bold
  }

  function isUnderline() {
    let is_underline = false

    blocks.forEach(item => {
      if (item.editing) {
        is_underline = item.is_underline
      }
    })

    return is_underline
  }

  function isItalic() {
    let is_italic = false

    blocks.forEach(item => {
      if (item.editing) {
        is_italic = item.is_italic
      }
    })

    return is_italic
  }

  function getFontSize() {
    let font_size = 0

    blocks.forEach(item => {
      if (item.editing) {
        font_size = item.font_size
      }
    })

    return font_size
  }

  function getColor() {
    let color = ''

    blocks.forEach(item => {
      if (item.editing) {
        color = item.color
      }
    })

    return color
  }

  function handleChangeValue(value: string) {
    setBlocks(blocks.map(item => {
      if (item.editing) {
        return {
          ...item,
          value
        }
      } else {
        return { ...item }
      }
    }))
  }

  function handleChangeWidth(width: number) {
    setBlocks(blocks.map(item => {
      if (item.editing) {
        return {
          ...item,
          width
        }
      } else {
        return { ...item }
      }
    }))
  }

  function handleChangeHeight(height: number) {
    setBlocks(blocks.map(item => {
      if (item.editing) {
        return {
          ...item,
          height
        }
      } else {
        return { ...item }
      }
    }))
  }

  function handleChangeObjectFit(object_fit: ObjectFit) {
    setBlocks(blocks.map(item => {
      if (item.editing) {
        return {
          ...item,
          object_fit
        }
      } else {
        return { ...item }
      }
    }))
  }

  function handleAddImage(file: File) {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      setBlocks(blocks.map(item => {
        if (item.editing) {
          return {
            ...item,
            image: file,
            image_base64: reader.result as string
          }
        } else {
          return { ...item }
        }
      }))
    });

    reader.readAsDataURL(file);
  }

  function handleChangeFontSize(font_size: number) {
    setBlocks(blocks.map(item => {
      if (item.editing) {
        return {
          ...item,
          font_size
        }
      } else {
        return { ...item }
      }
    }))
  }

  function handleChangeFontSizeFromButton(name: string) {
    setBlocks(blocks.map(item => {
      if (item.editing) {
        let font_size = item.font_size
        if (name === 'minus') {
          font_size = font_size - 1
        } else {
          font_size = font_size + 1
        }
        return {
          ...item,
          font_size
        }
      } else {
        return { ...item }
      }
    }))
  }

  function handleChangeColor(color: string) {
    setBlocks(blocks.map(item => {
      if (item.editing) {
        return {
          ...item,
          color
        }
      } else {
        return { ...item }
      }
    }))
  }

  function handleBold() {
    setBlocks(blocks.map(item => {
      if (item.editing) {
        return {
          ...item,
          is_bold: !item.is_bold
        }
      } else {
        return { ...item }
      }
    }))
  }

  function handleItalic() {
    setBlocks(blocks.map(item => {
      if (item.editing) {
        return {
          ...item,
          is_italic: !item.is_italic
        }
      } else {
        return { ...item }
      }
    }))
  }

  function handleUnderline() {
    setBlocks(blocks.map(item => {
      if (item.editing) {
        return {
          ...item,
          is_underline: !item.is_underline
        }
      } else {
        return { ...item }
      }
    }))
  }

  function handleAlign(align: 'left' | 'center' | 'right') {
    setBlocks(blocks.map(item => {
      if (item.editing) {
        return {
          ...item,
          align
        }
      } else {
        return { ...item }
      }
    }))
  }

  function handleDelete() {
    setBlocks(blocks.filter(item => !item.editing))
  }

  function handleEditBlock(index: number) {
    setBlocks(blocks.map((item, idx) => {
      if (index === idx) {
        return {
          ...item,
          editing: true
        }
      } else {
        return {
          ...item,
          editing: false
        }
      }
    }))
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setOpenPicker(false)
  });

  return (
    <>
      {
        loaded &&
        <div className="Templates_Item entity-edit">
          <div className="wrapper flex-container sb">
            <div className="flex-container _gap-narrow">
              <h1>Create Template</h1>
            </div>

            <button
              style={{ background: '#d0d3da', color: 'white' }}
              className="_wa"
              onClick={() => $router.router.navigate('templates', {
                companyId: activeAccountId,
                localInterface: $router.router.getState().params.localInterface
              }, { reload: true })}
            >
              <Icon
                style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
                viewBox="0 0 24 24"
                icon="arrow-25"
              />
            </button>
          </div>

          {
            options.type === 'Text' ?
              <div className="contents">
                <div className="document-options">
                  <div className="select-row company">
                    <span className="company">Company</span>

                    <SelectComponent
                      selectedOption={selectedCompany}
                      options={edit.map(item => {
                        return {
                          span: item.name,
                          value: item.company_id,
                        }
                      })}
                      onChange={(value) => setSelectedCompany(value as string)}
                    />
                  </div>
                </div>

                <div className="document-options">
                  <div className="select-row">
                    <span>Document</span>

                    <SelectComponent
                      selectedOption={options.document}
                      options={['Invoice', 'Estimate', 'Approval'].map(item => {
                        return {
                          span: item,
                          value: item,
                        }
                      })}
                      onChange={(value) => setOptions({ ...options, document: value as string })}
                    />
                  </div>

                  <div className="select-row">
                    <span>Type</span>

                    <SelectComponent
                      selectedOption={options.type}
                      options={['Template', 'Text'].map(item => {
                        return {
                          span: item,
                          value: item,
                        }
                      })}
                      onChange={(value) => setOptions({ ...options, type: value as string })}
                    />
                  </div>
                </div>

                <div className="document-options">
                  <textarea
                    style={{ minHeight: '400px', resize: 'none' }}
                    value={text}
                    onChange={(event) => setText(event.target.value)}
                  ></textarea>
                </div>
              </div> :
              <div className="contents">
                {
                  mode === '' &&
                  <div className="document-options">
                    <div className="select-row company">
                      <span className="company">Company</span>

                      <SelectComponent
                        selectedOption={selectedCompany}
                        options={edit.map(item => {
                          return {
                            span: item.name,
                            value: item.company_id,
                          }
                        })}
                        onChange={(value) => setSelectedCompany(value as string)}
                      />
                    </div>
                  </div>
                }

                {
                  mode === '' &&
                  <div className="document-options">
                    <div className="select-row">
                      <span>Document</span>

                      <SelectComponent
                        selectedOption={options.document}
                        options={['Invoice', 'Estimate', 'Approval'].map(item => {
                          return {
                            span: item,
                            value: item,
                          }
                        })}
                        onChange={(value) => setOptions({ ...options, document: value as string })}
                      />
                    </div>

                    <div className="select-row">
                      <span>Type</span>

                      <SelectComponent
                        selectedOption={options.type}
                        options={['Template', 'Text'].map(item => {
                          return {
                            span: item,
                            value: item,
                          }
                        })}
                        onChange={(value) => setOptions({ ...options, type: value as string })}
                      />
                    </div>

                    <span className="border"></span>

                    <div className="select-row">
                      <span> Width: </span>

                      <input
                        type="number"
                        className="appearance"
                        value={canvasOptions.width}
                        onChange={(event) => setCanvasOptions({ ...canvasOptions, width: +event.target.value })}
                      />
                    </div>

                    <div className="select-row">
                      <span> Height: </span>

                      <input
                        type="number"
                        className="appearance"
                        value={canvasOptions.height}
                        onChange={(event) => setCanvasOptions({ ...canvasOptions, height: +event.target.value })}
                      />
                    </div>
                  </div>
                }

                {
                  mode === '' &&
                  <div className="document-options">
                    <button
                      className="_wa _green"
                      disabled={isSaving}
                      onClick={() => handleAddBlock('Label')}
                    >
                      Add Label
                    </button>

                    <button
                      className="_wa _green"
                      disabled={isSaving}
                      onClick={() => handleAddBlock('Text')}
                    >
                      Add Text
                    </button>

                    {
                      permissions.template_attachment_add &&
                      <button
                        className="_wa _green"
                        disabled={isSaving}
                        onClick={() => handleAddBlock('Image')}
                      >
                        Add Image
                      </button>
                    }

                    <button
                      className="_wa _green"
                      disabled={blocks.length === 0 || isSaving}
                      onClick={() => handlePdf()}
                    >
                      View Pdf
                    </button>
                  </div>
                }

                {
                  mode === 'pdf' &&
                  <div className="document-options">
                    <button
                      className="_wa _green"
                      disabled={blocks.length === 0 || isSaving}
                      onClick={() => setMode('')}
                    >
                      To Edit
                    </button>
                  </div>
                }

                {
                  !!blocks.filter(item => item.editing && item.type === 'Image').length &&
                  <div className="document-options">
                    <span>Edit Block:</span>

                    <div className='add-gallary-photo'>
                      <label
                        htmlFor="filePicker"
                        className='add-image'
                        title='Upload a image'
                      >
                        {getBase64() === '' ? 'Add Image From Gallery' : 'Change Image From Gallery'}
                      </label>
                      <input
                        id="filePicker"
                        style={{ visibility: "hidden" }}
                        type="file"
                        name="myImage"
                        onChange={(event) => {
                          event.target.files && handleAddImage(event.target.files[0])
                        }}
                      />
                    </div>

                    {
                      getBase64() !== '' &&
                      <div className="image-block">
                        <img src={getBase64()} alt="Attachment" />
                      </div>
                    }

                    <span className="border"></span>

                    <div className="change-width">
                      <span style={{ marginRight: 0 }}>Width: </span>

                      <input
                        type="number"
                        className="appearance"
                        value={getWidth()}
                        onChange={(event) => handleChangeWidth(+event.target.value)}
                      />
                    </div>

                    <div className="change-width">
                      <span>Height: </span>

                      <input
                        type="number"
                        className="appearance"
                        value={getHeight()}
                        onChange={(event) => handleChangeHeight(+event.target.value)}
                      />
                    </div>

                    <span className="border"></span>

                    <SelectComponent
                      selectedOption={getObjectFit()}
                      options={objectFitOptions.map(item => {
                        return {
                          span: item,
                          value: item,
                        }
                      })}
                      onChange={(value) => handleChangeObjectFit(value as ObjectFit)}
                    />

                    <span className="border"></span>

                    <button
                      className="_wa _iconed _bordered _red"
                      onClick={() => handleDelete()}
                    >
                      Delete
                    </button>
                  </div>
                }

                {
                  !!blocks.filter(item => item.editing && (item.type === 'Label' || item.type === 'Text')).length &&
                  <div className="document-options">
                    <span>Edit Block:</span>

                    {
                      getType() === 'Label' &&
                      <input
                        className="value"
                        type="text"
                        value={getValue()}
                        onChange={(event) => handleChangeValue(event.target.value)}
                      />
                    }

                    {
                      getType() === 'Text' &&
                      <textarea
                        className="text-area"
                        style={{ width: `${getWidth()}px` }}
                        value={getValue()}
                        onChange={(event) => handleChangeValue(event.target.value)}
                      ></textarea>
                    }

                    {
                      getType() === 'Text' &&

                      <div className="change-width">
                        <span>Width: </span>

                        <input
                          type="number"
                          className="appearance"
                          value={getWidth()}
                          onChange={(event) => handleChangeWidth(+event.target.value)}
                        />
                      </div>
                    }

                    <span className="border"></span>

                    <button
                      className={classNames('settings', { _active: isBold() })}
                      onClick={() => handleBold()}
                    >
                      B
                    </button>

                    <button
                      className={classNames('italic settings', { _active: isItalic() })}
                      onClick={() => handleItalic()}
                    >
                      I
                    </button>

                    <button
                      className={classNames('underline settings', { _active: isUnderline() })}
                      onClick={() => handleUnderline()}
                    >
                      U
                    </button>

                    <span className="border"></span>

                    {
                      getType() === 'Text' &&
                      <div className="align-text-change">
                        <button
                          className={classNames('align', { _active: getAlign() === 'left' })}
                          onClick={() => handleAlign('left')}
                        >
                          <Icon
                            viewBox="0 0 1024 1024"
                            icon="left-28"
                          />
                        </button>

                        <button
                          className={classNames('align', { _active: getAlign() === 'center' })}
                          onClick={() => handleAlign('center')}
                        >
                          <Icon
                            viewBox="0 0 1024 1024"
                            icon="center-1"
                          />
                        </button>

                        <button
                          className={classNames('align', { _active: getAlign() === 'right' })}
                          onClick={() => handleAlign('right')}
                        >
                          <Icon
                            viewBox="0 0 1024 1024"
                            icon="right-34"
                          />
                        </button>
                      </div>
                    }

                    {
                      getType() === 'Text' &&
                      <span className="border"></span>
                    }

                    <div className="font-size-change">
                      <div
                        className="action"
                        onClick={() => handleChangeFontSizeFromButton('minus')}
                      >
                        <Icon
                          viewBox="0 0 1024 1024"
                          icon="minus-20"
                        />
                      </div>

                      <input
                        type="number"
                        className="appearance"
                        value={getFontSize()}
                        onChange={(event) => handleChangeFontSize(+event.target.value)}
                      />

                      <div
                        className="action"
                        onClick={() => handleChangeFontSizeFromButton('plus')}
                      >
                        <Icon
                          viewBox="0 0 1024 1024"
                          icon="plus-34"
                        />
                      </div>
                    </div>

                    <span className="border"></span>

                    <div
                      className="color-block"
                      ref={contextMenuRef}
                    >
                      <div
                        style={{ background: getColor() }}
                        className="color-block-view"
                        onClick={() => setOpenPicker(true)}
                      ></div>

                      {
                        openPicker &&
                        <HexColorPicker color={getColor()} onChange={(value) => handleChangeColor(value)} />
                      }
                    </div>

                    <span className="border"></span>

                    <button
                      className="_wa _iconed _bordered _red"
                      onClick={() => handleDelete()}
                    >
                      Delete
                    </button>
                  </div>
                }

                {
                  mode === '' &&
                  <div className="document-page-wrapper">
                    <div
                      className="document-page"
                      style={{ width: `${canvasOptions.width}px`, height: `${canvasOptions.height}px` }}
                    >
                      {
                        blocks
                          .map((item, index) => (
                            <React.Fragment key={index}>
                              {
                                item.type === 'Label' &&
                                <Draggable
                                  key={index}
                                  bounds="parent"
                                  position={{ x: item.deltaPosition.x, y: item.deltaPosition.y }}
                                  disabled={!item.editing}
                                  onDrag={(event, position) => handleDrag(event, position, index)}
                                >
                                  <div
                                    className={classNames('block',
                                      {
                                        editing: item.editing,
                                        is_bold: item.is_bold,
                                        is_underline: item.is_underline,
                                        is_italic: item.is_italic
                                      })}
                                    style={{ fontSize: `${item.font_size}px`, color: item.color }}
                                    onClick={() => handleEditBlock(index)}
                                  >
                                    {item.value}
                                  </div>
                                </Draggable>
                              }

                              {
                                item.type === 'Text' &&
                                <Draggable
                                  key={index}
                                  bounds="parent"
                                  position={{ x: item.deltaPosition.x, y: item.deltaPosition.y }}
                                  disabled={!item.editing}
                                  onDrag={(event, position) => handleDrag(event, position, index)}
                                >
                                  <div
                                    className={classNames('block text',
                                      {
                                        editing: item.editing,
                                        is_bold: item.is_bold,
                                        is_underline: item.is_underline,
                                        is_italic: item.is_italic
                                      })}
                                    style={{
                                      fontSize: `${item.font_size}px`,
                                      color: item.color,
                                      textAlign: item.align,
                                      width: `${item.width}px`
                                    }}
                                    onClick={() => handleEditBlock(index)}
                                    dangerouslySetInnerHTML={{ __html: item.value }}
                                  ></div>
                                </Draggable>
                              }

                              {
                                item.type === 'Image' &&
                                <Draggable
                                  key={index}
                                  bounds="parent"
                                  position={{ x: item.deltaPosition.x, y: item.deltaPosition.y }}
                                  disabled={!item.editing}
                                  onDrag={(event, position) => handleDrag(event, position, index)}
                                >
                                  <div
                                    className={classNames('block image',
                                      {
                                        editing: item.editing,
                                      })}
                                    onClick={() => handleEditBlock(index)}
                                  >
                                    <img
                                      style={{ width: `${item.width}px`, height: `${item.height}px`, objectFit: item.object_fit }}
                                      src={item.image_url ? item.image_url : item.image_base64}
                                      alt="Attachment"
                                    />

                                    <div className="image-block"></div>
                                  </div>
                                </Draggable>
                              }
                            </React.Fragment>
                          ))
                      }
                    </div>
                  </div>
                }

                {
                  mode === 'pdf' &&
                  <div className="pdf-block">
                    <PDFDownloadLink document={<DocumentPdf data={blocks} width={canvasOptions.width} />} >
                      {({ blob, url, loading, error }) => {
                        url && setUrl(url)
                      }}
                    </PDFDownloadLink>

                    <Iframe url={url} />
                  </div>
                }
              </div>
          }

          <div className="wrapper flex-container sb editing-buttons">
            <div />
            <div className="buttons">
              <button
                className="_bordered _red"
                disabled={isSaving}
                onClick={() => $router.router.navigate('templates', {
                  companyId: activeAccountId,
                  localInterface: $router.router.getState().params.localInterface
                }, { reload: true })}
              >
                Cancel
              </button>

              {
                options.type === 'Text' &&
                <button
                  className="_wa _green"
                  disabled={text.length === 0 || isSaving || selectedCompany === ''}
                  onClick={() => handleSave()}
                >
                  Create
                </button>
              }

              {
                options.type === 'Template' &&
                <button
                  className="_bordered _green"
                  disabled={blocks.length === 0 || isSaving || selectedCompany === ''}
                  onClick={() => handleSave()}
                >
                  Create
                </button>
              }
            </div>
          </div>
        </div>
      }
    </>
  )
}
