import { useEffect, useState } from "react";
import { BaseLink, useRoute } from "react-router5"
import ReactInputMask from "react-input-mask"
import qs from "qs";
import classNames from "classnames"
import Datetime from 'react-datetime'
import moment from "moment";

import SelectWithSearchComponent from "../../../components/SelectWithSearch";
import Switcher from "../../../components/Switcher"
import Checkbox from "../../../components/Checkbox"
import Icon from "../../../components/Icon"

import { formatPhoneNumberToServerString, httpClientUpdate, nErrorUpdate } from "../../../funcs"
import { useAppSelector } from '../../../store/hooks'

import { CallCenter_Dispatcher } from "../../../models/CallCenter"
import { DispatcherData } from "./Item";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../../styles/pages/common/entity-edit.sass"

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: DispatcherData
}

function CallCenter_DispatchersPage_New() {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [isSaving, setIsSaving] = useState(false)

  const [readyToSave, setReadyToSave] = useState(false)
  const [errorFields, setErrorFields] = useState<string[]>([])

  const [dispatchersData, setDispatchersData] = useState<DispatcherData | null>(null)

  const [newDispatcherData, setNewDispatcherData] = useState<Partial<CallCenter_Dispatcher> | null>(null)

  // save document
  async function handleSave() {
    setIsSaving(true)
    try {
      let salaryData: {
        salary_per_hour?: string
        salary_per_order?: string;
        start_from_date?: string;
        is_auto_calculation?: number;
        mentor_salary_per_order?: string;
      } = {}
      if (dispatchersData?.permissions.dispatcher_salary_show) {
        salaryData.salary_per_hour = newDispatcherData?.salary_per_hour?.toFixed(2)
        salaryData.salary_per_order = newDispatcherData?.salary_per_order?.toFixed(2)
        salaryData.start_from_date = moment(newDispatcherData?.start_from_date).format('YYYY-MM-DD')
        salaryData.is_auto_calculation = newDispatcherData?.is_auto_calculation ? 1 : 0
        salaryData.mentor_salary_per_order = newDispatcherData?.mentor_salary_per_order?.toFixed(2)
      }
      const response = await httpClientUpdate.post(`/callcenter/dispatchers`, {
        account_id: activeAccountId,
        name: newDispatcherData?.name,
        phone: newDispatcherData?.phone && formatPhoneNumberToServerString(newDispatcherData?.phone),
        active: newDispatcherData?.active ? 1 : 0,
        is_phone: newDispatcherData?.is_phone ? 1 : 0,
        is_softphone: newDispatcherData?.is_softphone ? 1 : 0,
        code: newDispatcherData?.dispatcher_code,
        user_id: newDispatcherData?.user ? newDispatcherData?.user : null,
        mentor_id: newDispatcherData?.mentor_id ? newDispatcherData?.mentor_id : '',
        priority: newDispatcherData?.priority ? newDispatcherData?.priority : 0,
        hide_stat: newDispatcherData?.hide_stat ? 1 : 0,
        ...salaryData,
      })
      if (response.data.success) {
        setIsSaving(false)
        $router.router.navigate('call_center.dispatchers', {
          companyId: activeAccountId,
        }, { reload: true })
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
      let createdError = nErrorUpdate(error)
      createdError.content.errorFields && setErrorFields(createdError.content.errorFields)
    }
  }

  // Load info function
  async function loadInfo() {
    try {
      const { data: { data: dispatchersData, success, error } } = (await httpClientUpdate.post('/callcenter/dispatchers/report', qs.stringify({
        account_id: activeAccountId,
        limit_rows: 1,
        page: 1,
        date_type: 'created',
        sort_field: 'service_resource',
        sort_type: 'asc',
        filter_field: JSON.stringify({})
      }))) as { data: HttpClientUpdateReport }
      if (success) {
        if (dispatchersData.permissions.dispatcher_add) {
          setDispatchersData(dispatchersData)

          if (dispatchersData.permissions.dispatcher_salary_show) {
            setNewDispatcherData({
              ...newDispatcherData,

              name: '',
              active: 'Active',

              user: dispatchersData.edit.users[0][0].user_id,

              is_phone: false,
              is_softphone: false,
              dispatcher_code: 0,
              salary_per_hour: 0.00,
              salary_per_order: 0.00,
              start_from_date: moment.utc().format('MM/DD/YYYY'),
              is_auto_calculation: false,
              mentor_salary_per_order: 0.00,
              priority: 0,
              hide_stat: false,
            })
          } else {
            setNewDispatcherData({
              ...newDispatcherData,

              name: '',
              active: 'Active',

              user: dispatchersData.edit.users[0][0].user_id,

              is_phone: false,
              is_softphone: false,
              dispatcher_code: 0,
              priority: 0,
              hide_stat: false,
            })
          }
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }

      setTimeout(() => setReadyToSave(false), 100)
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load dispatcher data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Watch changes
  useEffect(() => {
    setReadyToSave(true)
  }, [
    newDispatcherData
  ])

  function handleFocus(event: any) {
    const target = event.currentTarget;

    target.type = 'text';
    target.setSelectionRange(target.value.length, target.value.length);
    target.type = 'number';
    target && target.focus()
  }

  function handleChangePrice(event: any, name: string) {
    let price = event.target.value
    let numberWithoutDot = price.split('.')

    if (numberWithoutDot[1] && numberWithoutDot[1].toString().length > 2) {
      let newNumber = `${numberWithoutDot[0]}${numberWithoutDot[1].toString()[0]}.${numberWithoutDot[1].toString()[1]}${price[price.length - 1]}`
      setNewDispatcherData({
        ...newDispatcherData,
        [name]: +newNumber
      })
    } else if (price.length === 1) {
      let newNumber = `0.0${price}`
      setNewDispatcherData({
        ...newDispatcherData,
        [name]: +newNumber
      })
    }
  }

  function handleChangePriceBackspace(event: any, name: string) {
    if (event.keyCode === 8) {
      event.preventDefault()
      let price = event.target.value
      let numberWithoutDot = `${price}`.split('.')
      if (numberWithoutDot[0].length > 1) {

        let newNumber = `${numberWithoutDot[0].slice(0, -1)}.${numberWithoutDot[0][numberWithoutDot[0].length - 1]}${numberWithoutDot[1][0]}`
        setNewDispatcherData({
          ...newDispatcherData,
          [name]: +newNumber
        })
      } else {
        let newNumber = `0.${numberWithoutDot[0][0]}${numberWithoutDot[1][0]}`
        setNewDispatcherData({
          ...newDispatcherData,
          [name]: +newNumber
        })
      }
    }
  }

  // Render function
  return (<>
    {newDispatcherData && dispatchersData ? (
      <div className="CallCenter_DispatchersPage_New entity-edit">

        {/* Top navigation */}
        <div className="top-nav">
          <BaseLink
            router={$router.router}
            routeName="call_center.phoneNumbers"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.phoneNumbers" })}
          >
            <Icon icon="phone-1" />
            <span>Phone Numbers</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.dispatchers"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.dispatchers" })}
          >
            <Icon icon="user-1" />
            <span>Dispatchers</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.additionalTime"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.additionalTime" })}
          >
            <Icon viewBox="0 0 1024 1024" icon="time-22" />
            <span>Additional Time</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.absence"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.absence" })}
          >
            <Icon icon="time-16" />
            <span>Absence</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.groups"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.groups" })}
          >
            <Icon icon="user-29" />
            <span>Groups</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.callFlows"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.callFlows" })}
          >
            <Icon icon="share-7" />
            <span>Call Flows</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.caller_groups"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.caller_groups" })}
          >
            <Icon icon="user-29" />
            <span>Caller Groups</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.temporaryClosed"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.temporaryClosed" })}
          >
            <Icon viewBox="0 0 1024 1024" icon="close-664" />
            <span>Temporary Closed</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.callCenters"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.callCenters" })}
          >
            <Icon icon="delivery-8" />
            <span>Call Centers</span>
          </BaseLink>

          <BaseLink
            router={$router.router}
            routeName="call_center.audioLibrary"
            routeParams={{ companyId: activeAccountId }}
            className={classNames({ _active: $router.route.name === "call_center.audioLibrary" })}
          >
            <Icon viewBox="0 0 1024 1024" icon="audio-15" />
            <span>Audio Library</span>
          </BaseLink>
        </div>

        <div className="wrapper flex-container sb">
          <h1>New Dispatcher</h1>

          <button
            style={{ background: '#d0d3da', color: 'white' }}
            className="_wa"
            onClick={() => $router.router.navigate('call_center.dispatchers', {
              companyId: activeAccountId,
              localInterface: $router.router.getState().params.localInterface
            }, { reload: true })}
          >
            <Icon
              style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#fff' }}
              viewBox="0 0 24 24"
              icon="arrow-25"
            />
          </button>
        </div>

        <div className="fieldset">
          <div className="legend">Main</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>Name:</span>

                <input
                  className={classNames({
                    _error: errorFields.includes('name')
                  })}
                  type="text"
                  onChange={({ target: { value } }) => {
                    setNewDispatcherData({ ...newDispatcherData, name: value })
                    errorFields.includes('name') && setErrorFields(errorFields.filter(item => item !== 'name'))
                  }}
                />
              </div>

              <div className="field">
                <span>Code:</span>

                <input
                  className={classNames('appearance', {
                    _error: errorFields.includes('code')
                  })}
                  type="number"
                  value={newDispatcherData.dispatcher_code}
                  onChange={({ target: { value } }) => {
                    setNewDispatcherData({ ...newDispatcherData, dispatcher_code: +value })
                    errorFields.includes('phone') && setErrorFields(errorFields.filter(item => item !== 'phone'))
                  }}
                />
              </div>
            </div>

            <div className="__right">
              <div className="field">
                <span>Active:</span>

                <Switcher
                  checked={newDispatcherData.active === 'Active'}
                  onChange={(value) => setNewDispatcherData({ ...newDispatcherData, active: value ? 'Active' : 'Inactive' })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Settings</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>User:</span>

                <div className="input-wrapper">
                  <SelectWithSearchComponent
                    error={(errorFields as string[]).includes('user_id')}
                    options={[{ user: '', user_id: '' }].concat(dispatchersData?.edit.users[0]).map((user) => ({
                      span: user.user,
                      value: user.user_id
                    }))}
                    selectedOption={newDispatcherData.user as string}
                    onChange={(value: string) => {
                      setNewDispatcherData({ ...newDispatcherData, user: value, mentor_id: '' });
                      errorFields.includes('user_id') && setErrorFields(errorFields.filter(item => item !== 'user_id'))
                    }}
                  />
                </div>
              </div>

              <div className="field">
                <span>Phone:</span>

                <ReactInputMask
                  className={classNames({
                    _error: errorFields.includes('phone')
                  })}
                  type="text"
                  mask="+1 (999) 999-9999"
                  value={newDispatcherData.phone}
                  onChange={({ target: { value } }) => {
                    setNewDispatcherData({ ...newDispatcherData, phone: value })
                    errorFields.includes('phone') && setErrorFields(errorFields.filter(item => item !== 'phone'))
                  }}
                />
              </div>

              <div className="field">
                <span>Priority:</span>

                <input
                  className="appearance"
                  type="number"
                  value={newDispatcherData.priority || 0}
                  onChange={({ target: { value } }) => setNewDispatcherData({ ...newDispatcherData, priority: +value })}
                />
              </div>

              <div className="field">
                <span>Mentor:</span>

                <div className="input-wrapper">
                  <SelectWithSearchComponent
                    error={(errorFields as string[]).includes('mentor_id')}
                    options={
                      [{ user_name: '', user_id: '', dispatcher: '' }]
                        .concat(dispatchersData?.edit.mentors.filter(item => item.user_id !== newDispatcherData.user))
                        .map((mentor) => ({
                          span: mentor.dispatcher ? mentor.dispatcher : mentor.user_name,
                          value: mentor.user_id
                        }))}
                    selectedOption={newDispatcherData.mentor_id as string}
                    onChange={(value: string) => {
                      setNewDispatcherData({ ...newDispatcherData, mentor_id: value });
                      errorFields.includes('mentor_id') && setErrorFields(errorFields.filter(item => item !== 'mentor_id'))
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="__right">
              <div className="field">
                <span>Softphone:</span>

                <Checkbox
                  contents=''
                  value={newDispatcherData.is_softphone}
                  onChange={(value) => setNewDispatcherData({ ...newDispatcherData, is_softphone: value })}
                />
              </div>

              <div className="field">
                <span>Phone:</span>

                <Checkbox
                  contents=''
                  value={newDispatcherData.is_phone}
                  onChange={(value) => setNewDispatcherData({ ...newDispatcherData, is_phone: value })}
                />
              </div>

              <div className="field">
                <span>Hide Statistics:</span>

                <Checkbox
                  contents=''
                  value={!!newDispatcherData.hide_stat}
                  onChange={(value) => setNewDispatcherData({ ...newDispatcherData, hide_stat: value })}
                />
              </div>
            </div>
          </div>
        </div>

        {
          dispatchersData.permissions.dispatcher_salary_show &&
          <div className="fieldset">
            <div className="legend">Salary</div>

            <div className="fields">
              <div className="__left">

                <div className="field">
                  <span>Salary Per Hour:</span>

                  <input
                    className="appearance"
                    type="number"
                    value={newDispatcherData.salary_per_hour ? newDispatcherData.salary_per_hour.toFixed(2) : Number().toFixed(2)}
                    onFocus={(event) => handleFocus(event)}
                    onChange={(event) => handleChangePrice(event, 'salary_per_hour')}
                    onKeyDown={(event) => handleChangePriceBackspace(event, 'salary_per_hour')}
                  />
                </div>

                <div className="field">
                  <span>Salary Per Order:</span>

                  <input
                    className="appearance"
                    type="number"
                    value={newDispatcherData.salary_per_order ? newDispatcherData.salary_per_order.toFixed(2) : Number().toFixed(2)}
                    onFocus={(event) => handleFocus(event)}
                    onChange={(event) => handleChangePrice(event, 'salary_per_order')}
                    onKeyDown={(event) => handleChangePriceBackspace(event, 'salary_per_order')}
                  />
                </div>

                <div className="field">
                  <span>Mentor Salary Per Order:</span>

                  <input
                    className="appearance"
                    type="number"
                    value={newDispatcherData.mentor_salary_per_order ? newDispatcherData.mentor_salary_per_order.toFixed(2) : Number().toFixed(2)}
                    onFocus={(event) => handleFocus(event)}
                    onChange={(event) => handleChangePrice(event, 'mentor_salary_per_order')}
                    onKeyDown={(event) => handleChangePriceBackspace(event, 'mentor_salary_per_order')}
                  />
                </div>
              </div>

              <div className="__right">
                <div className="field">
                  <span>Start Date:</span>

                  <Datetime
                    dateFormat={true}
                    timeFormat={false}
                    value={newDispatcherData.start_from_date as Date}
                    onChange={(value) => setNewDispatcherData({ ...newDispatcherData, start_from_date: moment.isMoment(value) ? value.toDate() : newDispatcherData.start_from_date })}
                  />
                </div>

                <div className="field">
                  <span>Auto Calculation:</span>

                  <Switcher
                    checked={newDispatcherData.is_auto_calculation}
                    onChange={(value) => setNewDispatcherData({ ...newDispatcherData, is_auto_calculation: value })}
                  />
                </div>
              </div>
            </div>
          </div>
        }

        <div className="wrapper flex-container sb editing-buttons">
          <div />
          <div className="buttons">
            <button
              className="_bordered _red"
              onClick={() => $router.router.navigate('call_center.dispatchers', {
                companyId: activeAccountId
              }, { reload: true })}
            >
              Cancel
            </button>
            <button
              className="_bordered _green"
              disabled={!readyToSave || !!errorFields.length || isSaving}
              onClick={() => handleSave()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    ) : null
    }
  </>)
}

export default CallCenter_DispatchersPage_New
