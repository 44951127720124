import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';

import { TemplateProps } from '../BlankPagesForTech/InvoiceEdit';

import RobotoRegular from '../../fonts/Roboto.ttf'
import RobotoBold from '../../fonts/Roboto-Bold.ttf'
import RobotoItalic from '../../fonts/Roboto-Italic.ttf'
import RobotoBoldItalic from '../../fonts/Roboto-BoldItalic.ttf'

import { ItemsProps } from './Index';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RobotoRegular,
      fontWeight: 400,
    },
    {
      src: RobotoBold,
      fontWeight: 700,
    },
    {
      src: RobotoItalic,
      fontStyle: 'italic',
    },
    {
      src: RobotoBoldItalic,
      fontWeight: 700,
      fontStyle: 'italic'
    },
  ]
})

const styles = StyleSheet.create({
  page: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: '#fff',
    position: 'relative',
    padding: '10px',
    fontFamily: 'Roboto',
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  header_col: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%'
  },
  header_block_items: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #000',
    marginBottom: '50px',
  },
  header_block_item: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    padding: '10px',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#000',
    fontSize: '14px',
    borderBottom: '1px solid #000'
  },
  header_block_item_last: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    padding: '10px',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#000',
    fontSize: '14px',
  },
  header_title_contact: {
    paddingBottom: '2px',
    width: '100%',
    color: '#ccc',
    borderBottom: '1px solid #ccc',
    fontSize: '12px',
  },
  header_contact_col: {
    width: '100%',
    display: 'flex',
  },
  header_text_h1: {
    fontSize: '18pt',
    fontWeight: '500',
    letterSpacing: '.3px',
    color: '#fff',
    margin: '0',
  },
  header_text_h3: {
    fontSize: '10pt',
    fontWeight: '300',
    color: '#fff',
    margin: '0',
  },
  image: {
    width: '30px',
  },
  section: {
    width: '100%',
  },
  line_items_col_show: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: '0',
    borderBottom: '1px solid #d9d9d9',
  },
  services_item: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: '20px',
    gap: '20px',
    padding: '10px 20px',
  },
  services_item_info: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  service_title: {
    padding: '10px 20px',
    borderTop: '1px solid #d9d9d9',
    borderBottom: '1px solid #d9d9d9',
    textTransform: 'uppercase',
    color: '#989898',
    fontWeight: 'bold',
    fontSize: '16px',
  },
  head_name: {
    fontSize: '24px',
    fontWeight: 'bold',
    padding: '10px 20px',
  },
  services_item_info_comment: {
    color: '#838383',
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  services_item_info_price: {
    color: '#838383',
    whiteSpace: 'nowrap',
    fontWeight: '400',
  },
  line_items_subtotal_show: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '0',
    padding: '10px 20px',
  },
  subtotal_text: {
    fontWeight: '600',
    fontSize: '20px',
  },
  image_block: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  }
});

interface PdfDocumentProps {
  data: ItemsProps[]
  name: string
  template?: TemplateProps
  deposit_amount: string
  sign: string
}

export default function PdfDocument({
  data,
  name,
  template,
  deposit_amount,
  sign,
}: PdfDocumentProps) {
  function getSubTotal() {
    let total = 0

    data.forEach(item => {
      if (item.type === 'service') {
        total = total + (Number(item.quantity) * Number(item.price))
      }
    })

    data.forEach(item => {
      if (item.type === 'material') {
        total = total + (Number(item.quantity) * Number(item.price))
      }
    })

    return `$${total.toFixed(2)}`
  }

  function getTotalWithDiscount() {
    let total = 0
    let discount = 0

    data.forEach(item => {
      if (item.type === 'service') {
        total = total + (Number(item.quantity) * Number(item.price))
      }
    })

    data.forEach(item => {
      if (item.type === 'material') {
        total = total + (Number(item.quantity) * Number(item.price))
      }
    })

    data.forEach(item => {
      if (item.type === 'discount') {
        discount = discount + (Number(item.quantity) * Number(item.price))
      }
    })

    return `$${(total - discount).toFixed(2)}`
  }

  function getSubTotalDiscount() {
    let discount = 0

    data.forEach(item => {
      if (item.type === 'discount') {
        discount = discount + (Number(item.quantity) * Number(item.price))
      }
    })

    return `$${(discount).toFixed(2)}`
  }

  function getTotalService() {
    let total = 0

    data.forEach(item => {
      if (item.type === 'service') {
        total = total + (Number(item.quantity) * Number(item.price))
      }
    })

    return total
  }

  function getTotalMaterials() {
    let total = 0

    data.forEach(item => {
      if (item.type === 'material') {
        total = total + (Number(item.quantity) * Number(item.price))
      }
    })

    return total
  }

  function getPosition(num: number) {
    let width = template?.width ? template.width : 590
    let result = 0

    result = (590 * num) / width

    return Number(result)
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {
          template && template.type === 'template' &&
          template.blocks
            .filter(item => item.type === 'Label')
            .map((item, index) => (
              <View
                key={index}
                style={{ position: 'absolute', left: `${getPosition(item.deltaPosition.x)}px`, top: `${item.deltaPosition.y}px` }}
              >
                <Text
                  style={{
                    fontSize: `${item.font_size}px`,
                    color: item.color,
                    fontWeight: item.is_bold ? 'bold' : 'normal',
                    fontStyle: item.is_italic ? 'italic' : 'normal',
                    textDecoration: item.is_underline ? 'underline' : 'none',
                  }}
                > {item.value} </Text>
              </View>
            ))
        }

        {
          template && template.type === 'template' &&
          template.blocks
            .filter(item => item.type === 'Text')
            .map((item, index) => (
              <View
                key={index}
                style={{
                  position: 'absolute',
                  left: `${getPosition(item.deltaPosition.x)}px`,
                  top: `${item.deltaPosition.y}px`,
                }}
              >
                {
                  item.value.split('\n').map(item_value => (
                    <Text
                      style={{
                        textAlign: item.align,
                        width: item.width,
                        fontSize: `${item.font_size}px`,
                        color: item.color,
                        fontWeight: item.is_bold ? 'bold' : 'normal',
                        fontStyle: item.is_italic ? 'italic' : 'normal',
                        textDecoration: item.is_underline ? 'underline' : 'none'
                      }}
                    > {item_value} </Text>
                  ))
                }
              </View>
            ))
        }

        {
          template && template.type === 'template' &&
          template.blocks
            .filter(item => item.type === 'Image')
            .map((item, index) => (
              <View
                key={index}
                style={{
                  position: 'absolute',
                  left: `${getPosition(item.deltaPosition.x)}px`,
                  top: `${item.deltaPosition.y}px`,
                }}
              >
                <Image
                  style={{
                    width: `${item.width}px`,
                    height: `${item.height}px`,
                    objectFit: item.object_fit,
                  }}
                  src={item.image_url ? item.image_url : item.image_base64}
                ></Image>
              </View>
            ))
        }

        {
          template && template.type === 'template' &&
          <View
            style={{ height: template.height }}
          ></View>
        }

        <View
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            gap: '5px',
            fontSize: '12px',
          }}
        >
          <Text style={{ fontSize: '14px', color: '#000' }} > {name.toUpperCase()} </Text>

          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              padding: '10px',
              border: '1px solid #ccc',
              gap: '5px',
            }}
          >
            {
              !!data.filter(item => item.type === 'service').length &&
              <View
                style={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  borderBottom: '1px solid #ccc',
                  padding: '2.5px',
                }}
              >
                <Text style={{ width: '55%', color: '#838383' }}>Services</Text>
                <Text style={{ width: '10%', color: '#838383' }}>qty</Text>
                <Text style={{ width: '15%', color: '#838383' }}>unit price</Text>
                <Text style={{ width: '20%', textAlign: 'right', color: '#838383' }}>amount</Text>
              </View>
            }

            {
              data
                .filter(item => item.type === 'service')
                .map((item, index) => (
                  <View
                    style={{
                      display: 'flex',
                      width: '100%',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      borderBottom: '1px solid #ccc',
                      padding: '2.5px',
                    }}
                    key={index}
                  >
                    <View style={{ display: 'flex', flexDirection: 'column', width: '55%', maxWidth: '55%' }} >
                      <Text> {item.name} </Text>
                      <Text wrap style={styles.services_item_info_comment}> {item.description} </Text>
                    </View>

                    <Text style={{ width: '10%' }}> {item.quantity} </Text>
                    <Text style={{ width: '15%' }}>${item.price}</Text>
                    <Text style={{ width: '20%', textAlign: 'right' }}>$ {(Number(item.price) * Number(item.quantity)).toFixed(2)}</Text>
                  </View>
                ))
            }

            {
              !!data.filter(item => item.type === 'service').length &&
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  margin: '10px 0',
                  justifyContent: 'flex-end',
                }}
              >
                <Text>Services subtotal: ${getTotalService().toFixed(2)} </Text>
              </View>
            }

            {
              !!data.filter(item => item.type === 'material').length &&
              <View
                style={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  borderBottom: '1px solid #ccc',
                  padding: '2.5px',
                }}
              >
                <Text style={{ width: '55%', color: '#838383' }}>Materials</Text>
                <Text style={{ width: '10%', color: '#838383' }}>qty</Text>
                <Text style={{ width: '15%', color: '#838383' }}>unit price</Text>
                <Text style={{ width: '20%', textAlign: 'right', color: '#838383' }}>amount</Text>
              </View>
            }

            {
              data
                .filter(item => item.type === 'material')
                .map((item, index) => (
                  <View
                    style={{
                      display: 'flex',
                      width: '100%',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      borderBottom: '1px solid #ccc',
                      padding: '2.5px',
                    }}
                    key={index}
                  >
                    <View style={{ display: 'flex', flexDirection: 'column', width: '55%', maxWidth: '55%' }} >
                      <Text> {item.name} </Text>
                      <Text wrap style={styles.services_item_info_comment}> {item.description} </Text>
                    </View>

                    <Text style={{ width: '10%' }}> {item.quantity} </Text>
                    <Text style={{ width: '15%' }}>${item.price}</Text>
                    <Text style={{ width: '20%', textAlign: 'right' }}>$ {(Number(item.price) * Number(item.quantity)).toFixed(2)}</Text>
                  </View>
                ))
            }

            {
              !!data.filter(item => item.type === 'material').length &&
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  margin: '10px 0',
                  justifyContent: 'flex-end'
                }}
              >
                <Text>Materials subtotal: ${getTotalMaterials().toFixed(2)} </Text>
              </View>
            }

            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'flex-end',
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  width: '40%',
                }}
              >
                <View
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingBottom: '10px',
                    color: '#838383',
                    borderBottom: '1px solid #838383'
                  }}
                >
                  <Text> Subtotal </Text>

                  <Text> {getSubTotal()} </Text>
                </View>

                <View
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingBottom: '10px',
                    color: '#000',
                    borderBottom: '1px solid #000'
                  }}
                >
                  <Text> Discount </Text>

                  <Text> {getSubTotalDiscount()} </Text>
                </View>

                <View
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    color: '#000',
                    margin: '10px 0',
                    fontSize: '18px'
                  }}
                >
                  <Text> Total </Text>

                  <Text> {getTotalWithDiscount()} </Text>
                </View>

                <View
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    color: '#000',
                    margin: '10px 0',
                    fontSize: '18px'
                  }}
                >
                  <Text> Deposite </Text>

                  <Text> ${deposit_amount} </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.image_block}>
          <Image
            style={{
              width: '200px',
              height: '100px',
              margin: '20px 50px',
              borderBottom: '1px solid #000'
            }}
            src={sign}
          ></Image>
        </View>
      </Page>
    </Document>
  )
}