import { useEffect, useState } from "react";
import { useRoute } from "react-router5"
import qs from "qs";
import classNames from "classnames"
import { useCookies } from "react-cookie";

import Icon from "../../components/Icon"
import ReportFilters from "../../components/reports/Filters"
import ReportTableControls from "../../components/reports/TableControls"
import ReportTableField from "../../components/reports/TableField"

import { formatPhoneNumber, httpClientUpdate, nErrorUpdate, useOuterClick } from "../../funcs"
import { useAppSelector } from '../../store/hooks'

import Client from "../../models/Client"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/report-list.sass"

type FilterWord = 'source'

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: ClientsReport
}

interface ClientsReport {

  interface: {

    filter_words: {

      source: string[],
    },

    max_pages: number,
    rows_start: number,
    rows_end: number,
    rows_all: number,
  },

  permissions: {
    client_add: boolean
    client_delete: boolean
    client_edit: boolean
    client_report_show: boolean
    client_show: boolean
  },

  dashboard: {

    all: string,
    unpaid: string,
    new_this_month: string,
    new_last_month: string
  },

  clients: Client[]
}

function ClientsPage_List({ updated }: { updated: number }) {
  const $router = useRoute();

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const reportsMaxRows = useAppSelector((store) => store.reportsMaxRows)
  const navActive = useAppSelector((store) => store.navActive)
  const phoneCall = useAppSelector((store) => store.phoneCall)

  const [cookies, setCookie] = useCookies();

  const cookiesSetting = cookies.settings ? cookies.settings : null

  const [$updater, $setUpdater] = useState<any>(Math.random())

  const [searchButtonActive, setSearchButtonActive] = useState(false)

  const [showContext, setShowContext] = useState('')
  const [screenX, setScreenX] = useState(0)

  const [reportData, setReportData] = useState<ClientsReport | null>(null)
  const [localInterface, setLocalInterface] = useState({
    search: '',
    page: 1,
    max_rows: 100 as 50 | 100 | 250 | 500,
    filter_words: cookiesSetting && cookiesSetting?.clients?.filter_field ? {
      source: [] as string[],
      ...cookiesSetting.clients.filter_field,
    } : {
      source: [] as string[],
    },
    sort: {
      field: cookiesSetting && cookiesSetting?.clients?.sort_field ? cookiesSetting.clients.sort_field : 'name',
      direction: cookiesSetting && cookiesSetting?.clients?.sort_type ? cookiesSetting.clients.sort_type : 'up' as 'down' | 'up'
    },
    sortFields: [{
      span: 'Name',
      value: 'name'
    },
    {
      span: 'Date',
      value: 'created_at'
    }, {
      span: 'Main Phone',
      value: 'phone'
    }, {
      span: 'Source',
      value: 'source'
    }, {
      span: 'Jobs',
      value: 'jobs'
    }, {
      span: 'Appointments',
      value: 'appointments'
    }, {
      span: 'Recalls',
      value: 'recalls'
    }, {
      span: 'Total',
      value: 'total'
    }, {
      span: 'Paid',
      value: 'paid'
    }, {
      span: 'Unpaid',
      value: 'unpaid'
    }]
  })

  useEffect(() => {
    updated && setLocalInterface({
      ...localInterface,
      search: '',
      page: 1,
      max_rows: 100 as 50 | 100 | 250 | 500,
      filter_words: cookiesSetting && cookiesSetting?.clients?.filter_field ? {
        source: [] as string[],
        ...cookiesSetting.clients.filter_field,
      } : {
        source: [] as string[],
      },
      sort: {
        field: cookiesSetting && cookiesSetting?.clients?.sort_field ? cookiesSetting.clients.sort_field : 'name',
        direction: cookiesSetting && cookiesSetting?.clients?.sort_type ? cookiesSetting.clients.sort_type : 'up' as 'down' | 'up'
      },
    })
    updated && $setUpdater(updated)
    updated && setSearchButtonActive(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated])

  // Load clients function
  async function loadClients() {
    let activeFilters: any = { ...localInterface.filter_words }
    Object.keys(localInterface.filter_words).forEach((key: any) => !activeFilters[key].length && delete activeFilters[key])

    let filterParams = {}

    if ($router.router.getState().params) {
      if ($router.router.getState().params.localInterface) {
        filterParams = {
          account_id: activeAccountId,
          limit_rows: cookiesSetting && cookiesSetting?.clients?.limit_rows ? cookiesSetting?.clients?.limit_rows : reportsMaxRows,
          page: $router.router.getState().params.localInterface.page,
          sort_field: $router.router.getState().params.localInterface.sort.field,
          sort_type: $router.router.getState().params.localInterface.sort.direction === 'up' ? 'asc' : 'desc',
          filters: activeFilters,
          ...($router.router.getState().params.localInterface.search ? { search: $router.router.getState().params.localInterface.search } : {})
        }
      } else {
        filterParams = {
          account_id: activeAccountId,
          limit_rows: cookiesSetting && cookiesSetting?.clients?.limit_rows ? cookiesSetting?.clients?.limit_rows : reportsMaxRows,
          page: localInterface.page,
          sort_field: localInterface.sort.field,
          sort_type: localInterface.sort.direction === 'up' ? 'asc' : 'desc',
          filters: activeFilters,
          ...(localInterface.search ? { search: localInterface.search } : {})
        }

        setCookie('settings', { ...cookiesSetting, clients: { filter_field: activeFilters, sort_field: localInterface.sort.field, sort_type: localInterface.sort.direction, limit_rows: cookiesSetting && cookiesSetting?.clients?.limit_rows ? cookiesSetting?.clients.limit_rows : reportsMaxRows } })
      }
    }
    try {
      const { data: { data: clients, success, error } } = (await httpClientUpdate.post('/clients/report', qs.stringify(filterParams))) as { data: HttpClientUpdateReport }
      if (success) {
        if (clients.permissions.client_report_show) {
          setReportData(clients)
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
      setTimeout(() => {
        setSearchButtonActive(false)
      }, 100)
    }
    catch (error: Error | AxiosError | unknown) {
      setCookie('settings', { ...cookiesSetting, clients: {} })

      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  // Load clients on page mount
  useEffect(() => {
    loadClients()

    // unmount page
    return () => {
      setReportData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    $updater,
    localInterface.page
  ])

  useEffect(() => {
    if (!reportData) return
    setSearchButtonActive(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInterface.search])

  // Update settings from back page
  useEffect(() => {
    if ($router.router.getState().params.localInterface) {
      setLocalInterface({
        ...$router.router.getState().params.localInterface
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$router.router.getState().params])

  // Is filter selected function
  function isFilterSelected(field: FilterWord, value: string) {
    return localInterface.filter_words[field].includes(value)
  }

  function isAllFiltersSelected(field: "source") {
    return localInterface.filter_words[field].length === 0
  }

  function switchFilter(field: FilterWord, value: string, toggle: boolean) {

    let _arr = [...localInterface.filter_words[field]]

    if (toggle && value === "All") { _arr = [] }

    else if (!toggle) {

      while (true) {
        let i = _arr.findIndex((filter) => filter === value)
        if (i === -1) break
        _arr.splice(i, 1)
      }
    }

    else {

      if (reportData?.interface.filter_words[field].every((option) => _arr.concat([value]).includes(option)))
        _arr = []

      else if (_arr.findIndex((filter) => filter === value) === -1)
        _arr.push(value)
    }

    setLocalInterface({ ...localInterface, filter_words: { ...localInterface.filter_words, [field]: _arr } })
  }

  function handleChangeMenu(event: any, id: string, name: string, index: number) {
    event.preventDefault()
    event.stopPropagation()
    if (event.nativeEvent.button === 2) {
      let leftScreen = event.pageX
      let isNavOpen = navActive.is ? 280 : 0
      setScreenX(leftScreen - isNavOpen - 50)
      setShowContext(`${name}_${id}_${index}`)
    }
  }

  const contextMenuRef = useOuterClick((ev: any) => {
    setShowContext('')
    setScreenX(0)
  });

  // Render function
  return (<>
    {reportData && reportData.permissions.client_report_show ? (
      <div className="ClientsPage_List" >

        { /* Reports grid */}
        <div className="reports-grid">

          <div className="cell">

            <div className="amount">{reportData.dashboard.all}</div>
            <div className="legend">All Clients</div>
          </div>

          <div className="cell">

            <div className="amount">{Number(reportData.dashboard.unpaid).toFixed(2)}</div>
            <div className="legend">Unpaid</div>
          </div>

          <div className="cell">

            <div className="amount">{reportData.dashboard.new_this_month}</div>
            <div className="legend">New This Month</div>
          </div>

          <div className="cell">

            <div className="amount">{reportData.dashboard.new_last_month}</div>
            <div className="legend">New Last Month</div>
          </div>
        </div>

        { /* Page header */}
        <div className="page-header">
          <h1>Clients</h1>
        </div>

        { /* List filters */}
        <ReportFilters
          onSearchInputChange={(value) => setLocalInterface({ ...localInterface, search: value })}
          searchInput={localInterface.search}
          searchButtonActive={searchButtonActive}

          onUpdate={() => {
            $setUpdater(Math.random());
          }}

          phoneCall={phoneCall}
          navActive={navActive.is}
        />

        {/* Table controls */}
        <ReportTableControls
          zIndex={5}

          cookie_reportsMaxRows={cookiesSetting?.clients?.limit_rows}

          onMaxRowsChange={(value) => {
            setCookie('settings', { ...cookiesSetting, clients: { ...cookiesSetting.clients, limit_rows: value } })
            $setUpdater(Math.random())
          }}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}
          onPagesStart={() => setLocalInterface({ ...localInterface, page: 1 })}
          onPrevPage={() => setLocalInterface({ ...localInterface, page: localInterface.page - 1 })}
          onNextPage={() => setLocalInterface({ ...localInterface, page: localInterface.page + 1 })}
          onPagesEnd={() => setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
          onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
          onSortFire={() => $setUpdater(Math.random())}

          addButton={
            reportData.permissions.client_add ?
              (
                <div className="add-button-wrapper">
                  <button
                    className="_iconed _rounded add-button"
                    onClick={() => $router.router.navigate('clients.new', {
                      companyId: activeAccountId,
                      localInterface: localInterface,
                    }, { reload: true })}
                  >
                    <Icon icon="plus-thin" />
                    <span>Add Client</span>
                    <Icon icon="user-29" />
                  </button>
                </div>
              ) : (
                <></>
              )
          }
        />

        <div className="contents">

          {/* Wide desktop table */}
          <table className={classNames('table', '__show-on-wide',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <thead>
              <tr>
                <ReportTableField
                  contents={(<span>Name</span>)}

                  sortDirection={localInterface.sort.field === 'name' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'name', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Main phone</span>)}

                  sortDirection={localInterface.sort.field === 'phone' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'phone', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Source</span>)}

                  allFiltersSelected={isAllFiltersSelected("source")}
                  onAllFiltersChange={(value) => switchFilter("source", "All", value)}

                  filterWords={reportData.interface.filter_words.source.map((filterWord) => ({
                    word: filterWord,
                    selected: isFilterSelected("source", filterWord)
                  }))}
                  onFilterChange={(value) => switchFilter("source", value.word, value.selected)}

                  sortDirection={localInterface.sort.field === 'source' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'source', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Jobs</span>)}

                  sortDirection={localInterface.sort.field === 'jobs' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'jobs', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Appointments</span>)}

                  sortDirection={localInterface.sort.field === 'appointments' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'appointments', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Recalls</span>)}

                  sortDirection={localInterface.sort.field === 'recalls' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'recalls', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Total</span>)}

                  sortDirection={localInterface.sort.field === 'total' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'total', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Paid</span>)}

                  sortDirection={localInterface.sort.field === 'paid' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'paid', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
                <ReportTableField
                  contents={(<span>Unpaid</span>)}

                  sortDirection={localInterface.sort.field === 'unpaid' ? localInterface.sort.direction : undefined}
                  onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { field: 'unpaid', direction: value } })}

                  onFilterFire={() => $setUpdater(Math.random())}
                />
              </tr>
            </thead>

            {reportData.clients.map((client, i) => (
              <tr
                key={i}
                style={{ position: 'relative' }}
                className={reportData.permissions.client_show && client.client_id ? "" : "permission-show"}
                onClick={() =>
                  reportData.permissions.client_show &&
                  client.client_id &&
                  $router.router.navigate('clients.item', {
                    companyId: activeAccountId,
                    clientId: client.client_id,
                    localInterface: localInterface,
                  }, { reload: true })}
                onContextMenu={(event) =>
                  reportData.permissions.client_show &&
                  client.client_id &&
                  handleChangeMenu(event, client.client_id, 'clients', i)}
              >
                <td>{client.name}</td>
                <td>{formatPhoneNumber(client.main_phone)}</td>
                <td>{client.source}</td>
                <td>{client.jobs}</td>
                <td>{client.appointments}</td>
                <td>{client.recalls}</td>
                <td>{client.total}</td>
                <td>{client.paid}</td>
                <td>
                  {client.unpaid}
                  {
                    reportData.permissions.client_show &&
                    showContext === `clients_${client.client_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('clients.item', {
                            companyId: activeAccountId,
                            clientId: client.client_id,
                            localInterface: localInterface,
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/clients/${client.client_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          {/* Medium screen table */}
          <table className={classNames('table', '__hide-on-wide', '__hide-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            <tr></tr>
            {reportData.clients.map((client, i) => (
              <tr
                key={i}
                style={{ position: 'relative' }}
                className={reportData.permissions.client_show && client.client_id ? "" : "permission-show"}
                onClick={() =>
                  reportData.permissions.client_show &&
                  client.client_id &&
                  $router.router.navigate('clients.item', {
                    companyId: activeAccountId,
                    clientId: client.client_id,
                    localInterface: localInterface,
                  }, { reload: true })}
                onContextMenu={(event) =>
                  reportData.permissions.client_show &&
                  client.client_id &&
                  handleChangeMenu(event, client.client_id, 'clients', i)}
              >
                <td>
                  <div>{client.name}</div>
                  <div>{formatPhoneNumber(client.main_phone)}</div>
                </td>
                <td>
                  <div>{client.source}</div>
                  <div>Jobs: {client.jobs}</div>
                </td>
                <td>
                  <div>Appointments: {client.appointments}</div>
                  <div>Recalls: {client.recalls}</div>
                </td>
                <td>
                  <div>Total: {client.total}</div>
                  <div>Paid: {client.paid}</div>
                </td>
                <td>
                  <div>Unpaid: {client.unpaid}</div>
                  <div>&nbsp;</div>
                  {
                    reportData.permissions.client_show &&
                    showContext === `clients_${client.client_id}_${i}` &&
                    <div
                      className="redirect-menu"
                      ref={contextMenuRef}
                      style={{ left: `${screenX}px` }}
                    >
                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          $router.router.navigate('clients.item', {
                            companyId: activeAccountId,
                            clientId: client.client_id,
                            localInterface: localInterface,
                          }, { reload: true })
                        }
                        }
                      >
                        Open
                      </button>

                      <button
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          window.open(`${window.location.origin}/${activeAccountId}/clients/${client.client_id}`, "_blank", 'noopener,noreferrer')
                          setShowContext('')
                        }}
                      >
                        Open in new tab
                      </button>
                    </div>
                  }
                </td>
              </tr>
            ))}
          </table>

          {/* Mobile table items */}
          <div className={classNames('mobile-table-items', '__show-on-mobile',
            {
              __respectAsidePanel: navActive.is && !phoneCall,
              __phoneCall: phoneCall && !navActive.is,
              __bothOpen: navActive.is && phoneCall,
              __nonePanel: !navActive.is && !phoneCall
            }
          )}>
            {reportData.clients.map((client, i: number) => (
              <div
                className="item"
                key={i}
                onClick={() =>
                  reportData.permissions.client_show &&
                  client.client_id &&
                  $router.router.navigate('clients.item', {
                    companyId: activeAccountId,
                    clientId: client.client_id,
                    localInterface: localInterface,
                  }, { reload: true })}
              >
                <div className="__top">
                  <div className="__left">
                    <div><b>{client.name}</b></div>
                    <div>
                      {formatPhoneNumber(client.main_phone)}
                    </div>
                  </div>

                  <div className="__right">
                    <div>
                      Total: <b>{client.total}</b>
                    </div>
                    <div>
                      Paid: <b>{client.paid}</b>
                    </div>
                    <div>
                      Unpaid: <b>{client.unpaid}</b>
                    </div>
                  </div>
                </div>

                <div className="__bottom">
                  <div className="__left">
                    <div>
                      <span className="gray">Jobs:</span>
                      <span> {client.jobs}</span>
                    </div>
                    <div>
                      <span className="gray">Recalls:</span>
                      <span> {client.recalls}</span>
                    </div>
                    <div>
                      <span className="gray">Appointments:</span>
                      <span> {client.appointments}</span>
                    </div>
                  </div>

                  <div className="__right small">
                    <div>
                      <b>{client.source}</b>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Table controls */}
        <ReportTableControls
          isUnder={true}

          cookie_reportsMaxRows={cookiesSetting?.clients?.limit_rows}

          onMaxRowsChange={(value) => {
            setCookie('settings', { ...cookiesSetting, clients: { ...cookiesSetting.clients, limit_rows: value } })
            $setUpdater(Math.random())
          }}

          amount={{
            total: reportData.interface.rows_all,
            start: reportData.interface.rows_start,
            end: reportData.interface.rows_end
          }}

          page={localInterface.page}
          maxPages={reportData.interface.max_pages}
          onPagesStart={() => setLocalInterface({ ...localInterface, page: 1 })}
          onPrevPage={() => setLocalInterface({ ...localInterface, page: localInterface.page - 1 })}
          onNextPage={() => setLocalInterface({ ...localInterface, page: localInterface.page + 1 })}
          onPagesEnd={() => setLocalInterface({ ...localInterface, page: reportData.interface.max_pages })}

          sort={localInterface.sort}
          sortFields={localInterface.sortFields}
          onSortFieldChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, field: value } })}
          onSortDirectionChange={(value) => setLocalInterface({ ...localInterface, sort: { ...localInterface.sort, direction: value } })}
          onSortFire={() => $setUpdater(Math.random())}
        />
      </div>
    ) : null}
  </>)
}

export default ClientsPage_List
