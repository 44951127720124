import classNames from 'classnames'

import { NavActive } from '../../store/reducer'

import './ImageModal.sass'

interface ImageModalProps {
  navActive: NavActive
  phoneCall: boolean
  setModal: (value: { url: string, id: string, is_delete: boolean } | null) => void
  modal: { url: string, id: string, is_delete: boolean }
  handleDelete: () => void
}

export default function ImageModal({
  navActive,
  phoneCall,
  setModal,
  modal,
  handleDelete,
}: ImageModalProps) {
  return (
    <div
      className={classNames('link-page payment-page job-tech-customer time-tracking mobile',
        {
          __respectAsidePanel: navActive.is && !phoneCall,
          __phoneCall: phoneCall && !navActive.is,
          __bothOpen: navActive.is && phoneCall,
          __nonePanel: !navActive.is && !phoneCall
        }
      )}
      style={{ zIndex: 1, margin: 0 }}
    >
      {
        modal.is_delete &&
        <div className='link-menu'>
          <div style={{ background: '#1b2f5f' }} className="title">
            Approve delete image
          </div>

          <div className="buttons">
            <button
              className="_bordered _green"
              onClick={() => setModal({ ...modal, is_delete: false })}
            >
              No
            </button>

            <button
              className="_bordered _red"
              onClick={() => handleDelete()}
            >
              Yes
            </button>
          </div>
        </div>
      }

      {
        !modal.is_delete &&
        <div
          className='link-menu'
          style={{ maxWidth: '100%' }}
        >
          <div className='attachment-view'>
            <img src={modal.url} alt="Attachment" />
          </div>

          <div className="buttons">
            <button
              className="_bordered _red"
              onClick={() => setModal({ ...modal, is_delete: true })}
            >
              Delete
            </button>

            <button
              className="_bordered _green"
              onClick={() => setModal(null)}
            >
              Close
            </button>
          </div>
        </div>
      }
    </div>
  )
}
